import { PoiModelMap } from './fuelStations';
import mapboxgl from 'mapbox-gl';
import { SELECTED_POI_LAYER_SOURCE } from '../layers/SelectedPoiLayer';

export class SelectedPoiMapController {
    private _mapBox?: mapboxgl.Map;
    private _marker?: GeoJSON.Feature;
    private _selectedPoi?: PoiModelMap;

    constructor(mapBox?: mapboxgl.Map) {
        this._mapBox = mapBox;
    }

    get detail(): PoiModelMap | undefined {
        return this._selectedPoi;
    }

    hide(): void {
        const source = this._mapBox?.getSource(SELECTED_POI_LAYER_SOURCE) as mapboxgl.GeoJSONSource;
        if (source) {
            source.setData({
                type: 'FeatureCollection',
                features: []
            });

            this._selectedPoi = undefined;
        }
    }

    show(poi: PoiModelMap): void {
        this._selectedPoi = poi;

        this._marker = {
            type: 'Feature',
            properties: {
                id: poi.id
            },
            geometry: {
                type: 'Point',
                coordinates: [poi.position.lng, poi.position.lat]
            }
        };

        const source = this._mapBox?.getSource(SELECTED_POI_LAYER_SOURCE) as mapboxgl.GeoJSONSource;
        if (source) {
            source.setData({
                type: 'FeatureCollection',
                features: [this._marker]
            });
        }
    }
}
