export function SplashLoading() {
    return (
        <div className="rl-splash-loading">
            <div className="rl-splash-loading-logo">
                <img src="/img/rl-icon.svg" height="200" alt="RoadLords Dispatcher" />
                <div className="rl-splash-loading-progress" />
            </div>
        </div>
    );
}
