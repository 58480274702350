import { Draggable } from '@hello-pangea/dnd';
import { Col, Row } from 'antd';
import { Popover } from 'components/base/controls';
import { TrackingModel } from 'modules/TrackingModule/TrackingModule';
import { TransportStatusIndicator } from 'modules/TrackingModule/ui/TransportStatusIndicator';
import moment from 'moment';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ShareEtaCode, ShareEtaCodeState } from 'services/api/domains/ShareEtaApi';
import { TIME_DATE } from 'utils/constants/domain-constants';
import { remainingTimeDurationFormat } from 'utils/helpers/formatters';
import { TrackingTableColSize } from '../../TrackingTable';

interface Props {
    index: number;
    tracking: TrackingModel;
    shareCode?: ShareEtaCode;
    onAssignDriverClick: (vehicleId: string) => void;
    onAddDestination: (vehicleId: string) => void;
    onTransportClick: (transportId: string) => void;
    onShareEtaClick: (vehicle: TrackingModel) => void;
    onStopShareEtaClick: (shareCode?: ShareEtaCode) => void;
    onTransportDbClick: (vehicle: TrackingModel) => void;
    onSelectVehicle: (vehicle: TrackingModel) => void;
}

const TrackingTableRow = (props: Props) => {
    const { t } = useTranslation();
    const [shareEtaPopoverVisible, setShareEtaPopoverVisible] = useState<boolean>();

    const eta = props.tracking?.navigationRoute.eta;
    const remainingDistance = props.tracking?.navigationRoute.remainingDistance;

    const _popoverShareEtaClick = (tracking: TrackingModel) => {
        setShareEtaPopoverVisible(false);

        if (!tracking.driverId) {
            props.onAssignDriverClick(tracking.id);
        } else if (!tracking.destination) {
            props.onAddDestination(tracking.id);
        }
    };

    const _isShareEtaActive = () =>
        props.shareCode &&
        props.shareCode.state === ShareEtaCodeState.Active &&
        moment(props.shareCode.validUntil) >= moment();

    return (
        <Draggable key={props.tracking.id} draggableId={props.tracking.id} index={props.index}>
            {provided => (
                <div
                    className="rl-tracking-table-row"
                    key={props.tracking.id}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    onDoubleClick={() => props.tracking.driverId && props.onTransportDbClick(props.tracking)}
                >
                    <div className="rl-tracking-table-row-drag" {...provided.dragHandleProps}>
                        <i className="rl-icon-drag-handler" />
                    </div>
                    <Row gutter={[16, 0]} align={'middle'} className="rl-tracking-table-row-content">
                        <Col lg={TrackingTableColSize['vehicle']}>
                            <div className="rl-tracking-table-row-vehicle">
                                <b>{props.tracking.RN}</b>
                                {props.tracking.driverId ? (
                                    <span>
                                        {props.tracking.driverName}
                                        <i
                                            className="rl-icon-map-pin"
                                            onClick={() => props.onSelectVehicle(props.tracking)}
                                        />
                                    </span>
                                ) : (
                                    <div
                                        className="rl-tracking-table-row-link"
                                        onClick={() => {
                                            props.onAssignDriverClick(props.tracking.id);
                                        }}
                                    >
                                        {t('TrackingTable.assignDriver')}
                                    </div>
                                )}
                            </div>
                        </Col>

                        <Col lg={TrackingTableColSize['location']}>
                            <div className="rl-tracking-table-row-location">
                                {props.tracking.location ?? '-'}
                                <div className="rl-tracking-table-row-location-seen">
                                    {props.tracking.lastUpdate &&
                                        t('TrackingTable.lastSeen', {
                                            time: moment(props.tracking.lastUpdate).fromNow()
                                        })}
                                </div>
                            </div>
                        </Col>

                        <Col lg={TrackingTableColSize['destination']}>
                            {props.tracking.driverId && !props.tracking.navigationRoute.destination ? (
                                <div
                                    className="rl-tracking-table-row-link"
                                    onClick={() => {
                                        props.onAddDestination(props.tracking.id);
                                    }}
                                >
                                    {t('TrackingTable.addDestination')}
                                </div>
                            ) : (
                                props.tracking.navigationRoute.destination ?? '-'
                            )}
                        </Col>

                        <Col lg={TrackingTableColSize['eta']}>
                            <>
                                {props.tracking.navigationRoute.eta &&
                                    moment(props.tracking.navigationRoute.eta).diff(moment()) > 0 && (
                                        <Row gutter={[8, 0]} className="rl-tracking-table-row-eta">
                                            <Col className="rl-tracking-table-row-eta-time">
                                                {moment(props.tracking.navigationRoute.eta).format(TIME_DATE)}
                                            </Col>
                                            {props.tracking.trackingTransport.delayed && (
                                                <Col className="rl-tracking-table-row-eta-delayed">
                                                    {`(${props.tracking.trackingTransport.delayed})`}
                                                </Col>
                                            )}
                                        </Row>
                                    )}
                                <Row gutter={[9, 0]} className="rl-tracking-table-row-eta-share">
                                    <Col>
                                        <Popover
                                            key={props.tracking.id}
                                            content={
                                                <div className="rl-tracking-table-row-eta-popover">
                                                    <div className="rl-tracking-table-row-eta-popover-content">
                                                        {t('TrackingTable.shareEtaPopover.content')}
                                                    </div>
                                                    <div
                                                        className="rl-tracking-table-row-popover-link"
                                                        onClick={() => {
                                                            _popoverShareEtaClick(props.tracking);
                                                        }}
                                                    >
                                                        {!props.tracking.driverId &&
                                                            t('TrackingTable.shareEtaPopover.linkDriver')}
                                                    </div>
                                                </div>
                                            }
                                            placement={'top'}
                                            color={'white'}
                                            visible={
                                                (!props.tracking.driverId ||
                                                    !props.tracking.navigationRoute.destination) &&
                                                shareEtaPopoverVisible?.[props.tracking.id]?.visible
                                            }
                                            onVisibleChange={setShareEtaPopoverVisible}
                                        >
                                            <div
                                                className="rl-tracking-table-row-eta-share-start"
                                                onClick={() => {
                                                    if (
                                                        !props.tracking.driverId ||
                                                        !props.tracking.navigationRoute.destination
                                                    ) {
                                                        setShareEtaPopoverVisible(true);
                                                    } else {
                                                        props.onShareEtaClick(props.tracking);
                                                    }
                                                }}
                                            >
                                                {t('TrackingTable.shareEtaButton')}
                                            </div>
                                        </Popover>
                                    </Col>
                                    {_isShareEtaActive() && (
                                        <Col>
                                            <div
                                                className="rl-tracking-table-row-eta-share-stop"
                                                onClick={() => {
                                                    props.onStopShareEtaClick(props.shareCode);
                                                }}
                                            >
                                                {t('TrackingTable.stopShareEtaButton')}
                                            </div>
                                        </Col>
                                    )}
                                </Row>
                            </>
                        </Col>

                        <Col lg={TrackingTableColSize['kmLeft']}>{remainingTimeDurationFormat(eta) || '-'}</Col>

                        <Col lg={TrackingTableColSize['timeLeft']}>
                            {remainingDistance ? `${(remainingDistance / 1000).toFixed(0)} km` : '-'}
                        </Col>

                        <Col lg={TrackingTableColSize['transportInfo']}>
                            <div className="rl-tracking-table-row-transport">
                                {props.tracking.trackingTransport.id ? (
                                    <>
                                        <div
                                            className="rl-tracking-table-row-transport-name"
                                            onClick={() => props.onTransportClick(props.tracking.trackingTransport.id!)}
                                            style={{ whiteSpace: 'normal' }}
                                        >
                                            {props.tracking.trackingTransport.name}
                                        </div>
                                        <div className="rl-tracking-table-row-transport-rta">
                                            {props.tracking.trackingTransport.rta
                                                ? `${t('TrackingTable.rta')}: ${moment(
                                                      props.tracking.trackingTransport.rta
                                                  ).format(TIME_DATE)}`
                                                : '-'}
                                        </div>
                                    </>
                                ) : (
                                    '-'
                                )}
                            </div>
                        </Col>

                        <Col lg={TrackingTableColSize['transportStatus']}>
                            <TransportStatusIndicator model={props.tracking} extended={true} />
                        </Col>
                    </Row>
                </div>
            )}
        </Draggable>
    );
};

export default TrackingTableRow;
