/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReadOnlyBundleSerializer
 */
export interface ReadOnlyBundleSerializer {
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyBundleSerializer
     */
    id?: string;
    /**
     * 
     * @type {Date}
     * @memberof ReadOnlyBundleSerializer
     */
    readonly deleted?: Date;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyBundleSerializer
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyBundleSerializer
     */
    oneTimeFee?: string;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyBundleSerializer
     */
    monthlyFee?: string;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyBundleSerializer
     */
    description?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReadOnlyBundleSerializer
     */
    trial?: number;
    /**
     * 
     * @type {Date}
     * @memberof ReadOnlyBundleSerializer
     */
    readonly createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ReadOnlyBundleSerializer
     */
    readonly updatedAt?: Date;
    /**
     * 
     * @type {number}
     * @memberof ReadOnlyBundleSerializer
     */
    currency: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ReadOnlyBundleSerializer
     */
    services: Array<string>;
}

export function ReadOnlyBundleSerializerFromJSON(json: any): ReadOnlyBundleSerializer {
    return ReadOnlyBundleSerializerFromJSONTyped(json, false);
}

export function ReadOnlyBundleSerializerFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReadOnlyBundleSerializer {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'deleted': !exists(json, 'deleted') ? undefined : (new Date(json['deleted'])),
        'name': json['name'],
        'oneTimeFee': !exists(json, 'one_time_fee') ? undefined : json['one_time_fee'],
        'monthlyFee': !exists(json, 'monthly_fee') ? undefined : json['monthly_fee'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'trial': !exists(json, 'trial') ? undefined : json['trial'],
        'createdAt': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
        'updatedAt': !exists(json, 'updated_at') ? undefined : (new Date(json['updated_at'])),
        'currency': json['currency'],
        'services': json['services'],
    };
}

export function ReadOnlyBundleSerializerToJSON(value?: ReadOnlyBundleSerializer | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'one_time_fee': value.oneTimeFee,
        'monthly_fee': value.monthlyFee,
        'description': value.description,
        'trial': value.trial,
        'currency': value.currency,
        'services': value.services,
    };
}


