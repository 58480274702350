/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Costs,
    CostsFromJSON,
    CostsToJSON,
    HTTPValidationError,
    HTTPValidationErrorFromJSON,
    HTTPValidationErrorToJSON,
} from '../models';

export interface ComputeCostsV1CostsComputeCostsGetRequest {
    dateFrom?: Date;
    dateTo?: Date;
}

/**
 * no description
 */
export class CostsApi extends runtime.BaseAPI {

    /**
     * Compute Costs
     */
    async computeCostsV1CostsComputeCostsGetRaw(requestParameters: ComputeCostsV1CostsComputeCostsGetRequest): Promise<runtime.ApiResponse<Costs>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.dateFrom !== undefined) {
            queryParameters['date_from'] = (requestParameters.dateFrom as any).toISOString();
        }

        if (requestParameters.dateTo !== undefined) {
            queryParameters['date_to'] = (requestParameters.dateTo as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/costs/compute_costs`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CostsFromJSON(jsonValue));
    }

    /**
     * Compute Costs
     */
    async computeCostsV1CostsComputeCostsGet(requestParameters: ComputeCostsV1CostsComputeCostsGetRequest): Promise<Costs> {
        const response = await this.computeCostsV1CostsComputeCostsGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Market Average Cost
     */
    async marketAverageCostV1CostsMarketAverageCostGetRaw(): Promise<runtime.ApiResponse<Costs>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/costs/market_average_cost`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CostsFromJSON(jsonValue));
    }

    /**
     * Market Average Cost
     */
    async marketAverageCostV1CostsMarketAverageCostGet(): Promise<Costs> {
        const response = await this.marketAverageCostV1CostsMarketAverageCostGetRaw();
        return await response.value();
    }

}
