import { Subject } from 'rxjs';
import { Company } from 'services/api/domains/CompanyApi';
import { Logic } from './logic';

// TODO: Refactor, this class should not rely on getOrCreateCompany to be called before others
export class CompanyLogic {
    private _company: Company | null = null;

    companyUpdated$: Subject<void>;

    constructor(private _logic: Logic) {
        this.companyUpdated$ = new Subject<void>();
    }

    async updateCompany(data: { path: keyof Omit<Company & { countryIso2: string }, 'country'>; value: any }[]) {
        await this._logic
            .apiService()
            .company()
            .patchCompany(
                this._company?.companyId!,
                data.map(item => {
                    return {
                        Op: 'replace',
                        Path: item.path,
                        Value: item.value
                    };
                })
            );

        await this.fetchCompany();

        this.companyUpdated$.next();
    }

    getCompany() {
        return this._company!;
    }

    clearCompany() {
        this._company = null;
        this._logic.settings().setProp('companyId', null);
    }

    async fetchCompany() {
        const companyId = await this._logic.apiService().company().getCompanyId();

        if (!companyId) {
            throw new Error('No companyId found');
        }

        this._company = await this._logic.apiService().company().getCompany(companyId);
        this._logic.settings().setProp('companyId', this._company.companyId);
        return this._company;
    }
}
