export enum Role {
    /** driver management read */
    D_R = 'D_R',
    /** driver management write */
    D_W = 'D_W',
    /** driver management import/export */
    D_IE = 'D_IE',
    /** vehicle management read */
    V_R = 'V_R',
    /** vehicle management write */
    V_W = 'V_W',
    /** vehicle management import/export */
    V_IE = 'V_IE',
    /** poi management read */
    POI_R = 'POI_R',
    /** poi management write */
    POI_W = 'POI_W',
    /** poi management import/export */
    POI_IE = 'POI_IE',
    /** user management read */
    USR_R = 'USR_R',
    /** user management write */
    USR_W = 'USR_W',
    /** user management import/export */
    USR_IE = 'USR_IE',
    /** live map read */
    LM_R = 'LM_R',
    /** live tracking table view  */
    LT_R = 'LT_R',
    /** fuel station read */
    FST_R = 'FST_R',
    /** telematic dashboard read */
    DASH_R = 'DASH_R',
    /** telematic dashboard write */
    DASH_W = 'DASH_W',
    /** company profile edit */
    CP_R = 'CP_R',
    /** transport planer read - used for planing transports */
    PLN_R = 'PLN_R',
    /** route overview read */
    ROW_R = 'ROW_R',
    /** eta and rta parameter read - used for controling if eta and rta will be shown in transport */
    ETA_R = 'ETA_R',
    /**vehicle profile read */
    VP_R = 'VP_R',
    /** vehicle profile write - used for editing vehicle profiles */
    VR_W = 'VR_W',
    /** dispatcher board */
    BRD_R = 'BRD_R',
    /** online prices at fuel stations */
    OP_R = 'OP_R',
    /** Journeys Activity (0 zobrazovanie ) */
    JA_R = 'JA_R',
    /** Journeys Activity (1 pouzivat) */

    JA_W = 'JA_W',
    /** alarms and notifications - Alarm &Notifications(0 nezobrazovat,  1 Zobrazovat) */
    AN_R = 'AN_R',
    /** journeys activity read - Journeys Activity(enhanced Report)(1 pouzivat) */
    JAE_R = 'JAE_R',
    /** Fuel consumption(Report)(0 nezobr,)*/
    FC_R = 'FC_R',
    /** Fuel consumption(Report)(1 pouzivat) */
    FC_W = 'FC_W',
    /** Expenses (00 zobr) */
    EX_R = 'EX_R',
    /** Expenses (, 10write,) */
    EX_W = 'EX_W',
    /** Expenses ( 11 I/E) */
    EX_IE = 'EX_IE',
    /** aetr info read
     * pravdepodobne invo v pravom bocnom vozidle po kliku na vozidlo na mape
     * AETR info (0 nezobr,1 zobraz) */
    AEI_R = 'AEI_R',
    /** aetr report read -
     * pravdepodobne sa o report AETR ktory ma samostatnu stranku
     *  AETR report (0 nezobr,1 pouzivat) */
    AER_R = 'AER_R',
    /** driver info read */
    DRE_R = 'DRE_R',
    /** Tacho remote download(1 pouzivaj(default)) */
    TRD_R = 'TRD_R',
    /**  Driver card download remote (0 nezobr, j) */
    CRD_R = 'CRD_R',
    /** Driver card download remote (1 pouzivaj) */
    CRD_W = 'CRD_W',
    /** live messaging text messages */
    LMM_R = 'LMM_R',
    /** live messaging voice messages */
    LMT_R = 'LMT_R',
    /** instant Alarms (read(default)) */
    IA_R = 'IA_R',
    /** customer API (read(default)*/
    CA_R = 'CA_R',
    /** partner Module (read(default) */
    PM_R = 'PM_R',
    /** driver behaviour (read(default) */
    DBH_R = 'DBH_R',
    /** border crossing (read(default) */
    BC_R = 'BC_R'
}

export enum RegistrationType {
    PASSWORD_USER = 'PasswordUser',
    GOOGLE_USER = 'GoogleUser',
    FACEBOOK_USER = 'FacebookUser',
    UNREGISTERED_USER = 'Unregistered'
}

export interface User {
    id: string;
    name: string;
    email: string;
    roles: Role[];
    registerBy?: RegistrationType;
}
