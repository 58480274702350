import LoginModule from 'modules/LoginModule/LoginModule';
import RegisterModule from 'modules/RegisterModule/RegisterModule';
import { RegisterSuccessModal } from 'components/RegisterSuccessModal';
import { Logic } from 'logic/logic';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import ResetPasswordModule, { ResetPasswordModalType } from '../ResetPasswordModule/ResetPasswordModule';
import { AboutCompanyModule } from 'modules/AboutCompanyModule/AboutCompanyModule';
import { AuthStore } from 'stores/auth/AuthStore';
import { InviteDriverModule } from 'modules/InviteDriverModule/InviteDriverModule';

interface RouteParams {
    redirectFrom: string;
}

export function ModalsModule(props: { logic: Logic; authStore?: AuthStore }) {
    const location = useLocation<RouteParams>();
    const history = useHistory();
    const [userEmail, setUserEmail] = useState('');
    const [resetToken, setResetToken] = useState('');

    const params = new URLSearchParams(location.search);

    useEffect(() => {
        setResetTokenState();

        if (props.logic.settings().getProp('initializedAfterRegistration')) {
            props.logic.settings().setProp('initializedAfterRegistration', false);
            setUserEmail(props.authStore?.user?.email ?? '');
            history.push({ search: '?registerSuccess=true' });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const resetState = () => {
        setUserEmail('');
        setResetToken('');
    };

    const goBack = (paramToDelete?: string) => {
        resetState();
        const searchParams = new URLSearchParams(history.location.search);
        paramToDelete && searchParams.delete(paramToDelete);
        history.push({ search: searchParams.toString() });
    };

    const afterRegister = (email?: string) => {
        setUserEmail(email ?? '');
        history.push({ search: '' });
    };

    const afterAboutCompany = () => {
        props.logic.userEvents().companyRegistration();
        history.push({ search: '?registerSuccess=true' });
    };

    function setResetTokenState() {
        const path = location.pathname;
        if (path.startsWith('/reset-password/')) {
            const token = path.split('/')[2];
            if (token && token !== resetToken) {
                setResetToken(token);
            }
        }
    }

    function getResetPasswordType() {
        if (resetToken) {
            return ResetPasswordModalType.CREATE_NEW_PASSWORD;
        }
        return !!params.get('resetPassword') && ResetPasswordModalType.RESET_PASSWORD;
    }

    function afterLogin() {
        const redirectFrom = location.state?.redirectFrom;
        if (redirectFrom) {
            history.push({ pathname: redirectFrom });
        } else {
            goBack('login');
        }
    }

    return (
        <>
            <LoginModule
                logic={props.logic}
                visible={!!params.get('login')}
                onCancel={() => goBack('login')}
                afterLogin={afterLogin}
            />
            <RegisterModule
                logic={props.logic}
                visible={!!params.get('register')}
                onCancel={() => goBack('register')}
                afterRegister={afterRegister}
            />
            <RegisterSuccessModal
                visible={!!params.get('registerSuccess')}
                email={userEmail}
                onCancel={() => goBack('registerSuccess')}
                onSendAgainClick={() => {}}
            />
            <ResetPasswordModule
                logic={props.logic}
                token={resetToken}
                visible={getResetPasswordType()}
                onCancel={() => goBack('resetPassword')}
            />
            <AboutCompanyModule
                logic={props.logic}
                visible={!!params.get('aboutCompany')}
                email={userEmail}
                onAfterAboutCompany={afterAboutCompany}
            />
            <InviteDriverModule
                logic={props.logic}
                visible={!!params.get('inviteDriver')}
                onCancel={() => {
                    goBack('inviteDriver');
                }}
            />
        </>
    );
}
