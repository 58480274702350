import { useTranslation } from 'react-i18next';
import { RlDateRange, TableViewFilterModel, TableViewFilterSelected } from '../../TableViewModule';
import { DatePicker } from 'components/base/controls/DatePicker';
import cn from 'classnames';
import moment from 'moment';
import { DATE_FORMAT } from 'utils/constants/domain-constants';
import TableViewFilter from '../TableViewFilter/TableViewFilter';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import { Button } from 'components/base/controls';

interface Props {
    dateRange: RlDateRange;
    filter: TableViewFilterModel;
    onDateRangeChange?: (dateRange: RlDateRange) => void;
    onFilterChange?: (filter: TableViewFilterSelected) => void;
    onOpenFilterDrawer?: () => void;
}

const TableViewFiltersBar = (props: Props) => {
    const { t } = useTranslation();
    const { isMobileWidth } = useWindowDimensions();

    return (
        <div className="rl-table-view-filters-bar">
            {!isMobileWidth ? (
                <TableViewFilter filter={props.filter} onFilterChange={props.onFilterChange} />
            ) : (
                <Button
                    type="iconDark"
                    icon={<i className="rl-icon-funnel" />}
                    onClick={() => props.onOpenFilterDrawer?.()}
                />
            )}
            <div className="rl-table-view-filters-bar-date">
                <DatePicker.RangePicker
                    getPopupContainer={() =>
                        document.getElementsByClassName('rl-table-view-filters-bar-date')[0] as HTMLElement
                    }
                    format={DATE_FORMAT}
                    value={[moment(props.dateRange.start, DATE_FORMAT), moment(props.dateRange.end, DATE_FORMAT)]}
                    showSecond={false}
                    bordered={false}
                    showTime={false}
                    onChange={value =>
                        value?.length &&
                        props.onDateRangeChange?.({
                            start: value[0]!.format(DATE_FORMAT),
                            end: value[1]!.format(DATE_FORMAT)
                        })
                    }
                    ranges={{
                        Today: [moment(), moment()],
                        'This Week': [moment().startOf('week'), moment().endOf('week')],
                        'This Month': [moment().startOf('month'), moment().endOf('month')]
                    }}
                    separator={<i className={cn(['fa fa-calendar'])} title={t('common.calendar')} />}
                />
            </div>
        </div>
    );
};

export default TableViewFiltersBar;
