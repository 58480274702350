import { Col, Row, Space } from 'antd';
import { Popover } from 'components/base/controls';
import { useTranslation } from 'react-i18next';
import { TrackingTableColSize } from '../../TrackingTable';

const TrackingTableHeader = () => {
    const { t } = useTranslation();

    return (
        <div className="rl-tracking-table-header">
            <div className="rl-tracking-table-header-offset"></div>
            <Row gutter={[16, 0]} className="rl-tracking-table-header-title" align={'middle'}>
                <Col lg={TrackingTableColSize['vehicle']}>{t('TrackingTable.vehicle')}</Col>
                <Col lg={TrackingTableColSize['location']}>{t('TrackingTable.location')}</Col>
                <Col lg={TrackingTableColSize['destination']}>{t('TrackingTable.destination')}</Col>
                <Col lg={TrackingTableColSize['eta']}>
                    <Popover
                        content={
                            <div className="rl-tracking-table-header-eta-popover">
                                <div className="rl-tracking-table-header-eta-popover-title">
                                    {t('TrackingTable.etaPopover.title')}
                                </div>
                                <div className="rl-tracking-table-header-eta-popover-content">
                                    {t('TrackingTable.etaPopover.content')}
                                </div>
                                {/* <div className="rl-tracking-table-header-eta-popover-link">
                                    {t('TrackingTable.etaPopover.link')}
                                </div> */}
                            </div>
                        }
                        placement={'right'}
                        color={'white'}
                    >
                        <Space size={5}>
                            {t('TrackingTable.eta')}
                            <div className="rl-tracking-table-header-eta-badge">
                                <div className="rl-tracking-table-header-eta-badge-new">
                                    {t('TrackingTable.etaBagde')}
                                </div>
                            </div>
                        </Space>
                    </Popover>
                </Col>
                <Col lg={TrackingTableColSize['timeLeft']}>{t('TrackingTable.timeLeft')}</Col>
                <Col lg={TrackingTableColSize['kmLeft']}>{t('TrackingTable.kmLeft')}</Col>
                <Col lg={TrackingTableColSize['transportInfo']}>{t('TrackingTable.transport')}</Col>
                <Col lg={TrackingTableColSize['transportStatus']}>{t('TrackingTable.status')}</Col>
            </Row>
        </div>
    );
};

export default TrackingTableHeader;
