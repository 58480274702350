import { createRoot } from 'react-dom/client';
import { Conf, confDefault, Env } from './conf';
import i18nInit from './i18n';
import { Logic } from 'logic/logic';
import App from 'App';
import { SplashLoading } from 'components/SplashLoading/SplashLoading';
import moment from 'moment';
import { Notification } from 'components/base/layout';
import { BrowserRouter } from 'react-router-dom';
import { Modal } from 'components/base/layout';
import { RegistrationType } from 'stores/auth/types';
import { apiService, ApiServiceProvider } from 'services/api/ApiService';
import { store } from 'stores/RootStore';
import { Provider as StoreProvider } from 'mobx-react';
import { LOCAL_STORAGE_REDIRECT_FLAG } from 'stores/auth/AuthStore';
import { CONF } from 'config/conf';
import { StrictMode } from 'react';
import MaintenancePage from 'components/MaintenancePage/MaintenancePage';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';

declare const createCookieModal: any;

async function main() {
    const root = createRoot(document.getElementById('root')!);
    root.render(<SplashLoading />);

    const conf: Conf = { ...confDefault, ...(CONF as any) };

    await store.userSettings.init();
    const i18n = await i18nInit(store.userSettings.lang);
    store.auth.setUserLanguage(store.userSettings.lang);

    try {
        await store.auth.init();
    } catch (err) {
        console.error('Auth initialization failed', err);

        if (err.response?.status === 503) {
            root.render(<MaintenancePage />);
        } else {
            Notification.somethingWentWrong();
        }

        throw err;
    }

    const logic = new Logic(conf, apiService, store);

    const authToken = async (): Promise<string> => {
        await store.auth.updateToken();
        return store.auth.token;
    };

    const _initCompany = async (email: string, lang: string) => {
        try {
            await logic.company().fetchCompany();
        } catch (err) {
            if (err.response?.status === 403) {
                // Dispatcher entity probably does not exists, it can happened after google/fb redirect.
                // Create new dispatcher and company (without auth account)
                await logic.apiService().users().registerUserWithoutAuthAccount(email, lang);
                logic.userEvents().registration();

                await logic.company().fetchCompany();
            } else {
                throw err;
            }
        }
    };

    logic.api().init(store.auth, authToken);

    if (store.auth.isLoggedIn) {
        const email = store.auth.user?.email;

        if (!email) {
            throw new Error('User is loggedIn but user email missing');
        }

        try {
            await _initCompany(email, store.userSettings.lang);
            store.appStore.setIsLoggedIn(true);
            _pushRedirectLoginEvent();
        } catch (err) {
            console.error('Company init failed', err);

            if (err.response?.status === 503) {
                root.render(<MaintenancePage />);
            } else {
                Notification.somethingWentWrong();
            }

            throw err;
        }

        await store.userSettings.fetchUserSettings();
        i18n.changeLanguage(store.userSettings.lang);
        store.auth.setUserLanguage(store.userSettings.lang);
    }

    store.userSettings.autoSave();

    const _getConfirm = (message: string, callback: (ok: boolean) => void) => {
        Modal.confirm({
            title: <>{i18n.t('common.confirm')}</>,
            content: message,
            onOk: () => {
                callback(true);
            },
            onCancel: () => {
                callback(false);
            }
        });
    };

    function _pushRedirectLoginEvent() {
        if (
            localStorage.getItem(LOCAL_STORAGE_REDIRECT_FLAG) &&
            [RegistrationType.FACEBOOK_USER, RegistrationType.GOOGLE_USER].includes(store.auth.user?.registerBy!)
        ) {
            logic.userEvents().login();
        }
        localStorage.removeItem(LOCAL_STORAGE_REDIRECT_FLAG);
    }

    if (conf.env !== Env.DEV) {
        createCookieModal(store.userSettings.lang);
    }

    (window as any).app = {
        version: process.env.REACT_APP_VERSION,
        conf,
        i18n,
        logic,
        moment
    };

    root.render(
        <StrictMode>
            <ApiServiceProvider>
                <StoreProvider {...store.getStores()}>
                    <BrowserRouter getUserConfirmation={_getConfirm}>
                        <ErrorBoundary>
                            <App logic={logic} />
                        </ErrorBoundary>
                    </BrowserRouter>
                </StoreProvider>
            </ApiServiceProvider>
        </StrictMode>
    );
}

main();
