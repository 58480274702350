/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WriteOnlyUserTokenNested
 */
export interface WriteOnlyUserTokenNested {
    /**
     * 
     * @type {number}
     * @memberof WriteOnlyUserTokenNested
     */
    clientId?: number;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyUserTokenNested
     */
    token: string;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyUserTokenNested
     */
    tokenType: string;
    /**
     * 
     * @type {Date}
     * @memberof WriteOnlyUserTokenNested
     */
    startTime: Date;
    /**
     * 
     * @type {Date}
     * @memberof WriteOnlyUserTokenNested
     */
    endTime?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof WriteOnlyUserTokenNested
     */
    deleted: number;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyUserTokenNested
     */
    holder: string;
}

export function WriteOnlyUserTokenNestedFromJSON(json: any): WriteOnlyUserTokenNested {
    return WriteOnlyUserTokenNestedFromJSONTyped(json, false);
}

export function WriteOnlyUserTokenNestedFromJSONTyped(json: any, ignoreDiscriminator: boolean): WriteOnlyUserTokenNested {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'clientId': !exists(json, 'client_id') ? undefined : json['client_id'],
        'token': json['token'],
        'tokenType': json['token_type'],
        'startTime': (new Date(json['start_time'])),
        'endTime': !exists(json, 'end_time') ? undefined : (json['end_time'] === null ? null : new Date(json['end_time'])),
        'deleted': json['deleted'],
        'holder': json['holder'],
    };
}

export function WriteOnlyUserTokenNestedToJSON(value?: WriteOnlyUserTokenNested | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'client_id': value.clientId,
        'token': value.token,
        'token_type': value.tokenType,
        'start_time': (value.startTime.toISOString()),
        'end_time': value.endTime === undefined ? undefined : (value.endTime === null ? null : value.endTime.toISOString()),
        'deleted': value.deleted,
        'holder': value.holder,
    };
}


