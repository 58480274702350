import { Select } from 'components/base/controls';

export const CurrencySelect = (props: {
    currency?: string;
    currencies?: string[];
    onCurrencyChange?: (currency: string) => void;
}) => {
    return (
        <Select
            value={props.currency}
            onChange={props.onCurrencyChange}
            dropdownMatchSelectWidth={false}
            optionLabelProp="label"
            showSearch={false}
            className="navbar-currency-select"
            listHeight={240}
            bordered={false}
            dropdownAlign={{
                points: ['tr', 'br'],
                offset: [0, 13],
                overflow: {
                    adjustX: true,
                    adjustY: true
                }
            }}
            dropdownClassName="navbar-currency-select-dropdown"
        >
            {props.currencies?.map((currency, index) => (
                <Select.Option key={index} value={currency} label={currency.toUpperCase()}>
                    {currency}
                </Select.Option>
            ))}
        </Select>
    );
};
