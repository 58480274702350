/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Client,
    ClientFromJSON,
    ClientToJSON,
    ClientBLEConfig,
    ClientBLEConfigFromJSON,
    ClientBLEConfigToJSON,
    ClientCreatePriceLists,
    ClientCreatePriceListsFromJSON,
    ClientCreatePriceListsToJSON,
    ClientSSOIdentities,
    ClientSSOIdentitiesFromJSON,
    ClientSSOIdentitiesToJSON,
    InlineResponse2004,
    InlineResponse2004FromJSON,
    InlineResponse2004ToJSON,
    PriceList,
    PriceListFromJSON,
    PriceListToJSON,
    ReadOnlyClient,
    ReadOnlyClientFromJSON,
    ReadOnlyClientToJSON,
    WriteOnlyClient,
    WriteOnlyClientFromJSON,
    WriteOnlyClientToJSON,
} from '../models';

export interface ClientCreateRequest {
    data: WriteOnlyClient;
}

export interface ClientCreateCrmRequest {
    id: number;
    data: Client;
}

export interface ClientCreatePriceListsRequest {
    id: number;
    data: ClientCreatePriceLists;
}

export interface ClientDeleteRequest {
    id: number;
}

export interface ClientGetBleConfigRequest {
    id: number;
}

export interface ClientGetClientPriceListsRequest {
    id: number;
}

export interface ClientListRequest {
    name?: string;
    nameContains?: string;
    nameIcontains?: string;
    isPartner?: string;
    externalId?: string;
    referrer?: string;
    clientOrReferrer?: string;
    limit?: number;
    offset?: number;
}

export interface ClientPartialUpdateRequest {
    id: number;
    data: WriteOnlyClient;
}

export interface ClientReadRequest {
    id: number;
}

export interface ClientSsoIdentitiesRequest {
    id: number;
}

export interface ClientUpdateRequest {
    id: number;
    data: WriteOnlyClient;
}

/**
 * no description
 */
export class ClientApi extends runtime.BaseAPI {

    /**
     */
    async clientCreateRaw(requestParameters: ClientCreateRequest): Promise<runtime.ApiResponse<ReadOnlyClient>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling clientCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/client/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyClientToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyClientFromJSON(jsonValue));
    }

    /**
     */
    async clientCreate(requestParameters: ClientCreateRequest): Promise<ReadOnlyClient> {
        const response = await this.clientCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async clientCreateCrmRaw(requestParameters: ClientCreateCrmRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling clientCreateCrm.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling clientCreateCrm.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/client/{id}/create_crm/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ClientToJSON(requestParameters.data),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async clientCreateCrm(requestParameters: ClientCreateCrmRequest): Promise<void> {
        await this.clientCreateCrmRaw(requestParameters);
    }

    /**
     */
    async clientCreatePriceListsRaw(requestParameters: ClientCreatePriceListsRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling clientCreatePriceLists.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling clientCreatePriceLists.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/client/{id}/create_price_lists/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ClientCreatePriceListsToJSON(requestParameters.data),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async clientCreatePriceLists(requestParameters: ClientCreatePriceListsRequest): Promise<void> {
        await this.clientCreatePriceListsRaw(requestParameters);
    }

    /**
     */
    async clientDeleteRaw(requestParameters: ClientDeleteRequest): Promise<runtime.ApiResponse<ReadOnlyClient>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling clientDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/client/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyClientFromJSON(jsonValue));
    }

    /**
     */
    async clientDelete(requestParameters: ClientDeleteRequest): Promise<ReadOnlyClient> {
        const response = await this.clientDeleteRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async clientGetBleConfigRaw(requestParameters: ClientGetBleConfigRequest): Promise<runtime.ApiResponse<ClientBLEConfig>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling clientGetBleConfig.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/client/{id}/bleConfig/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ClientBLEConfigFromJSON(jsonValue));
    }

    /**
     */
    async clientGetBleConfig(requestParameters: ClientGetBleConfigRequest): Promise<ClientBLEConfig> {
        const response = await this.clientGetBleConfigRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async clientGetClientPriceListsRaw(requestParameters: ClientGetClientPriceListsRequest): Promise<runtime.ApiResponse<PriceList>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling clientGetClientPriceLists.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/client/{id}/price_lists/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PriceListFromJSON(jsonValue));
    }

    /**
     */
    async clientGetClientPriceLists(requestParameters: ClientGetClientPriceListsRequest): Promise<PriceList> {
        const response = await this.clientGetClientPriceListsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async clientListRaw(requestParameters: ClientListRequest): Promise<runtime.ApiResponse<InlineResponse2004>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.nameContains !== undefined) {
            queryParameters['name__contains'] = requestParameters.nameContains;
        }

        if (requestParameters.nameIcontains !== undefined) {
            queryParameters['name__icontains'] = requestParameters.nameIcontains;
        }

        if (requestParameters.isPartner !== undefined) {
            queryParameters['is_partner'] = requestParameters.isPartner;
        }

        if (requestParameters.externalId !== undefined) {
            queryParameters['external_id'] = requestParameters.externalId;
        }

        if (requestParameters.referrer !== undefined) {
            queryParameters['referrer'] = requestParameters.referrer;
        }

        if (requestParameters.clientOrReferrer !== undefined) {
            queryParameters['client_or_referrer'] = requestParameters.clientOrReferrer;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/client/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse2004FromJSON(jsonValue));
    }

    /**
     */
    async clientList(requestParameters: ClientListRequest): Promise<InlineResponse2004> {
        const response = await this.clientListRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async clientPartialUpdateRaw(requestParameters: ClientPartialUpdateRequest): Promise<runtime.ApiResponse<ReadOnlyClient>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling clientPartialUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling clientPartialUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/client/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyClientToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyClientFromJSON(jsonValue));
    }

    /**
     */
    async clientPartialUpdate(requestParameters: ClientPartialUpdateRequest): Promise<ReadOnlyClient> {
        const response = await this.clientPartialUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async clientReadRaw(requestParameters: ClientReadRequest): Promise<runtime.ApiResponse<ReadOnlyClient>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling clientRead.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/client/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyClientFromJSON(jsonValue));
    }

    /**
     */
    async clientRead(requestParameters: ClientReadRequest): Promise<ReadOnlyClient> {
        const response = await this.clientReadRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async clientSsoIdentitiesRaw(requestParameters: ClientSsoIdentitiesRequest): Promise<runtime.ApiResponse<Array<ClientSSOIdentities>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling clientSsoIdentities.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/client/{id}/sso_identities/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ClientSSOIdentitiesFromJSON));
    }

    /**
     */
    async clientSsoIdentities(requestParameters: ClientSsoIdentitiesRequest): Promise<Array<ClientSSOIdentities>> {
        const response = await this.clientSsoIdentitiesRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async clientUpdateRaw(requestParameters: ClientUpdateRequest): Promise<runtime.ApiResponse<ReadOnlyClient>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling clientUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling clientUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/client/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyClientToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyClientFromJSON(jsonValue));
    }

    /**
     */
    async clientUpdate(requestParameters: ClientUpdateRequest): Promise<ReadOnlyClient> {
        const response = await this.clientUpdateRaw(requestParameters);
        return await response.value();
    }

}
