/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WearData
 */
export interface WearData {
    /**
     * 
     * @type {number}
     * @memberof WearData
     */
    parkingBrakeCount: number;
    /**
     * 
     * @type {number}
     * @memberof WearData
     */
    avgParkingBreakCount: number;
    /**
     * 
     * @type {number}
     * @memberof WearData
     */
    maxSpeedWithParkingBrake: number;
    /**
     * 
     * @type {number}
     * @memberof WearData
     */
    maxRpm: number;
    /**
     * 
     * @type {number}
     * @memberof WearData
     */
    maxRpmCity: number;
    /**
     * 
     * @type {number}
     * @memberof WearData
     */
    maxRpmOutOfCity: number;
    /**
     * 
     * @type {number}
     * @memberof WearData
     */
    maxRpmHighway: number;
    /**
     * 
     * @type {number}
     * @memberof WearData
     */
    retarderCount: number;
    /**
     * 
     * @type {number}
     * @memberof WearData
     */
    avgRetarderCount: number;
    /**
     * 
     * @type {number}
     * @memberof WearData
     */
    retarderTime: number;
    /**
     * 
     * @type {number}
     * @memberof WearData
     */
    retarderTimePercents: number;
    /**
     * 
     * @type {number}
     * @memberof WearData
     */
    distanceWithRetarder: number;
    /**
     * 
     * @type {number}
     * @memberof WearData
     */
    distanceWithRetarderPercentage: number;
    /**
     * 
     * @type {number}
     * @memberof WearData
     */
    maxRpmWithRetarder: number;
    /**
     * 
     * @type {number}
     * @memberof WearData
     */
    serviceBrakeCount: number;
    /**
     * 
     * @type {number}
     * @memberof WearData
     */
    avgServiceBrakeCount: number;
    /**
     * 
     * @type {number}
     * @memberof WearData
     */
    distanceWithServiceBrake: number;
    /**
     * 
     * @type {number}
     * @memberof WearData
     */
    distanceWithServiceBrakePercentage: number;
    /**
     * 
     * @type {number}
     * @memberof WearData
     */
    serviceBrakeVsRetarderPercentage: number;
}

export function WearDataFromJSON(json: any): WearData {
    return WearDataFromJSONTyped(json, false);
}

export function WearDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): WearData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'parkingBrakeCount': json['parking_brake_count'],
        'avgParkingBreakCount': json['avg_parking_break_count'],
        'maxSpeedWithParkingBrake': json['max_speed_with_parking_brake'],
        'maxRpm': json['max_rpm'],
        'maxRpmCity': json['max_rpm_city'],
        'maxRpmOutOfCity': json['max_rpm_out_of_city'],
        'maxRpmHighway': json['max_rpm_highway'],
        'retarderCount': json['retarder_count'],
        'avgRetarderCount': json['avg_retarder_count'],
        'retarderTime': json['retarder_time'],
        'retarderTimePercents': json['retarder_time_percents'],
        'distanceWithRetarder': json['distance_with_retarder'],
        'distanceWithRetarderPercentage': json['distance_with_retarder_percentage'],
        'maxRpmWithRetarder': json['max_rpm_with_retarder'],
        'serviceBrakeCount': json['service_brake_count'],
        'avgServiceBrakeCount': json['avg_service_brake_count'],
        'distanceWithServiceBrake': json['distance_with_service_brake'],
        'distanceWithServiceBrakePercentage': json['distance_with_service_brake_percentage'],
        'serviceBrakeVsRetarderPercentage': json['service_brake_vs_retarder_percentage'],
    };
}

export function WearDataToJSON(value?: WearData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'parking_brake_count': value.parkingBrakeCount,
        'avg_parking_break_count': value.avgParkingBreakCount,
        'max_speed_with_parking_brake': value.maxSpeedWithParkingBrake,
        'max_rpm': value.maxRpm,
        'max_rpm_city': value.maxRpmCity,
        'max_rpm_out_of_city': value.maxRpmOutOfCity,
        'max_rpm_highway': value.maxRpmHighway,
        'retarder_count': value.retarderCount,
        'avg_retarder_count': value.avgRetarderCount,
        'retarder_time': value.retarderTime,
        'retarder_time_percents': value.retarderTimePercents,
        'distance_with_retarder': value.distanceWithRetarder,
        'distance_with_retarder_percentage': value.distanceWithRetarderPercentage,
        'max_rpm_with_retarder': value.maxRpmWithRetarder,
        'service_brake_count': value.serviceBrakeCount,
        'avg_service_brake_count': value.avgServiceBrakeCount,
        'distance_with_service_brake': value.distanceWithServiceBrake,
        'distance_with_service_brake_percentage': value.distanceWithServiceBrakePercentage,
        'service_brake_vs_retarder_percentage': value.serviceBrakeVsRetarderPercentage,
    };
}


