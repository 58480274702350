import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { RouteNames } from 'router/routes';

export const DispatcherBoardNavbarMobile = () => {
    const { t } = useTranslation();

    return (
        <div className="rl-dispatcher-board-navbar">
            <NavLink className="rl-dispatcher-board-navbar-item" to={RouteNames.SCHEDULING_DISPATCHER_BOARD_CALENDAR}>
                <i className="rl-dispatcher-board-navbar-item-icon rl-icon-calendar" />
                {t('NavBar.calendarView')}
            </NavLink>
            <NavLink className="rl-dispatcher-board-navbar-item" to={RouteNames.SCHEDULING_DISPATCHER_BOARD_TABLE}>
                <i className="rl-dispatcher-board-navbar-item-icon rl-icon-list" />
                {t('NavBar.tableView')}
            </NavLink>
        </div>
    );
};
