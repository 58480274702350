import { ColumnProps } from 'antd/lib/table';
import { Dropdown, Menu } from 'components/base/controls';
import { Table } from 'components/base/layout';
import { useTranslation } from 'react-i18next';
import { DispatcherModel } from 'services/api/domains/UsersApi';

interface Props {
    data: DispatcherModel[];
    isLoading: boolean;
    dispatcherId?: number;
    onEditClick: (user: DispatcherModel) => void;
    onDeleteClick: (userId: number) => void;
    onToggleAdminRole: (userId: number, isAdmin: boolean) => void;
    onRowDoubleClick: (user: DispatcherModel) => void;
}

export default function DispatcherTable(props: Props) {
    const { t } = useTranslation();

    const adminCount = props.data.filter(dispatcher => dispatcher.isAdmin).length;

    const columns: ColumnProps<DispatcherModel>[] = [
        {
            title: t('DispatcherTable.name'),
            dataIndex: 'name',
            key: 'name'
        },
        //TODO Backend doesnt provide email prop
        // {
        //     title: t('common.email'),
        //     dataIndex: 'email',
        //     key: 'email'
        // },
        {
            title: t('DispatcherTable.userType'),
            dataIndex: 'userType',
            key: 'userType',
            responsive: ['md'],
            render: (dispatchers, record) => (
                <span>
                    {t('DispatcherTable.dispatcher')}
                    {record.isAdmin ? '/' + t('DispatcherTable.admin') : ''}
                </span>
            )
        },
        {
            key: 'actions',
            align: 'right',
            render: (row: DispatcherModel) =>
                (props.dispatcherId === row.profileId || !row.isAdmin || (row.isAdmin && adminCount > 1)) && (
                    <Dropdown
                        overlay={
                            <Menu>
                                {!row.isAdmin && (
                                    <Menu.Item key="0" onClick={() => props.onToggleAdminRole(row.profileId, true)}>
                                        {t('DispatcherTable.makeAdmin')}
                                    </Menu.Item>
                                )}

                                {row.isAdmin && adminCount > 2 && (
                                    <Menu.Item key="1" onClick={() => props.onToggleAdminRole(row.profileId, false)}>
                                        {t('DispatcherTable.deleteAdmin')}
                                    </Menu.Item>
                                )}

                                {props.dispatcherId === row.profileId ? (
                                    <Menu.Item key="2" onClick={() => props.onEditClick(row)}>
                                        {t('DispatcherTable.edit')}
                                    </Menu.Item>
                                ) : (
                                    <>
                                        {/* <Menu.Item key="2" onClick={() => props.onDeleteClick(row.profileId)}>
                                        {t('DispatcherTable.delete')}
                                    </Menu.Item> */}
                                    </>
                                )}
                            </Menu>
                        }
                        trigger={['click']}
                        arrow
                        placement="bottom"
                    >
                        <i className="fas fa-ellipsis-h" />
                    </Dropdown>
                )
        }
    ];
    return (
        <Table
            className="rl-settings-table"
            columns={columns}
            dataSource={props.data}
            rowKey="profileId"
            loading={props.isLoading}
            onRow={row => {
                return {
                    onDoubleClick: () => {
                        props.dispatcherId === row.profileId && props.onRowDoubleClick(row);
                    }
                };
            }}
        />
    );
}
