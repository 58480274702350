import { Tooltip } from 'antd';
import { TooltipPropsWithTitle } from 'antd/lib/tooltip';
import * as React from 'react';

interface Props extends TooltipPropsWithTitle {
    qa?: string;
    title: string | React.ReactNode;
}

function EnhancedTooltip({ qa, ...props }: Props) {
    return (
        <Tooltip
            data-qa={qa}
            {...props}
            overlayClassName={props.className ? 'rl-tooltip ' + props.className : 'rl-tooltip'}
            title={props.title}
            mouseEnterDelay={0.5}
            trigger={['hover', 'click']}
        />
    );
}

EnhancedTooltip.Icon = ({ qa, ...props }: Props) => {
    return (
        <EnhancedTooltip data-qa={qa} {...props}>
            <i className="rl-tooltip-icon rl-icon-info" />
        </EnhancedTooltip>
    );
};

export default EnhancedTooltip;
