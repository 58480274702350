/* tslint:disable */
/* eslint-disable */
/**
 * Telematics API
 * Telematics services API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TLangCodesISO6391,
    TLangCodesISO6391FromJSON,
    TLangCodesISO6391FromJSONTyped,
    TLangCodesISO6391ToJSON,
} from '.';

/**
 * 
 * @export
 * @interface EWClient
 */
export interface EWClient {
    /**
     * 
     * @type {number}
     * @memberof EWClient
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof EWClient
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EWClient
     */
    externalId?: string | null;
    /**
     * 
     * @type {TLangCodesISO6391}
     * @memberof EWClient
     */
    language?: TLangCodesISO6391;
    /**
     * 
     * @type {Array<TLangCodesISO6391>}
     * @memberof EWClient
     */
    geocodingLanguages?: Array<TLangCodesISO6391> | null;
}

export function EWClientFromJSON(json: any): EWClient {
    return EWClientFromJSONTyped(json, false);
}

export function EWClientFromJSONTyped(json: any, ignoreDiscriminator: boolean): EWClient {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'externalId': !exists(json, 'externalId') ? undefined : json['externalId'],
        'language': !exists(json, 'language') ? undefined : TLangCodesISO6391FromJSON(json['language']),
        'geocodingLanguages': !exists(json, 'geocodingLanguages') ? undefined : (json['geocodingLanguages'] === null ? null : (json['geocodingLanguages'] as Array<any>).map(TLangCodesISO6391FromJSON)),
    };
}

export function EWClientToJSON(value?: EWClient | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'externalId': value.externalId,
        'language': TLangCodesISO6391ToJSON(value.language),
        'geocodingLanguages': value.geocodingLanguages === undefined ? undefined : (value.geocodingLanguages === null ? null : (value.geocodingLanguages as Array<any>).map(TLangCodesISO6391ToJSON)),
    };
}


