import { Switch } from 'antd';
import { SwitchProps } from 'antd/lib/switch';

interface Props extends SwitchProps {
    qa?: string;
}

export default function EnhancedSwitch({ qa, ...props }: Props) {
    return (
        <Switch data-qa={qa} {...props} className={props.className ? 'rl-switch ' + props.className : 'rl-switch'} />
    );
}
