/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InlineResponse20024,
    InlineResponse20024FromJSON,
    InlineResponse20024ToJSON,
    InlineResponse20025,
    InlineResponse20025FromJSON,
    InlineResponse20025ToJSON,
    ReadOnlyOBU,
    ReadOnlyOBUFromJSON,
    ReadOnlyOBUToJSON,
    ReadOnlyOBUNested,
    ReadOnlyOBUNestedFromJSON,
    ReadOnlyOBUNestedToJSON,
    WriteOnlyOBU,
    WriteOnlyOBUFromJSON,
    WriteOnlyOBUToJSON,
} from '../models';

export interface ObuCreateRequest {
    data: WriteOnlyOBU;
}

export interface ObuDeleteRequest {
    id: string;
}

export interface ObuListRequest {
    typeName?: string;
    identificationNumber?: string;
    serialNumber?: string;
    vehicleBrand?: string;
    imei?: string;
    clientName?: string;
    client?: string;
    limit?: number;
    offset?: number;
}

export interface ObuNestedRequest {
    typeName?: string;
    identificationNumber?: string;
    serialNumber?: string;
    vehicleBrand?: string;
    imei?: string;
    clientName?: string;
    client?: string;
    limit?: number;
    offset?: number;
}

export interface ObuNestedSingleRequest {
    id: string;
}

export interface ObuPartialUpdateRequest {
    id: string;
    data: WriteOnlyOBU;
}

export interface ObuReadRequest {
    id: string;
}

export interface ObuUpdateRequest {
    id: string;
    data: WriteOnlyOBU;
}

/**
 * no description
 */
export class ObuApi extends runtime.BaseAPI {

    /**
     */
    async obuCreateRaw(requestParameters: ObuCreateRequest): Promise<runtime.ApiResponse<ReadOnlyOBU>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling obuCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/obu/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyOBUToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyOBUFromJSON(jsonValue));
    }

    /**
     */
    async obuCreate(requestParameters: ObuCreateRequest): Promise<ReadOnlyOBU> {
        const response = await this.obuCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async obuDeleteRaw(requestParameters: ObuDeleteRequest): Promise<runtime.ApiResponse<ReadOnlyOBU>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling obuDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/obu/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyOBUFromJSON(jsonValue));
    }

    /**
     */
    async obuDelete(requestParameters: ObuDeleteRequest): Promise<ReadOnlyOBU> {
        const response = await this.obuDeleteRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async obuListRaw(requestParameters: ObuListRequest): Promise<runtime.ApiResponse<InlineResponse20024>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.typeName !== undefined) {
            queryParameters['type__name'] = requestParameters.typeName;
        }

        if (requestParameters.identificationNumber !== undefined) {
            queryParameters['identification_number'] = requestParameters.identificationNumber;
        }

        if (requestParameters.serialNumber !== undefined) {
            queryParameters['serial_number'] = requestParameters.serialNumber;
        }

        if (requestParameters.vehicleBrand !== undefined) {
            queryParameters['vehicle_brand'] = requestParameters.vehicleBrand;
        }

        if (requestParameters.imei !== undefined) {
            queryParameters['imei'] = requestParameters.imei;
        }

        if (requestParameters.clientName !== undefined) {
            queryParameters['client__name'] = requestParameters.clientName;
        }

        if (requestParameters.client !== undefined) {
            queryParameters['client'] = requestParameters.client;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/obu/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20024FromJSON(jsonValue));
    }

    /**
     */
    async obuList(requestParameters: ObuListRequest): Promise<InlineResponse20024> {
        const response = await this.obuListRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async obuNestedRaw(requestParameters: ObuNestedRequest): Promise<runtime.ApiResponse<InlineResponse20025>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.typeName !== undefined) {
            queryParameters['type__name'] = requestParameters.typeName;
        }

        if (requestParameters.identificationNumber !== undefined) {
            queryParameters['identification_number'] = requestParameters.identificationNumber;
        }

        if (requestParameters.serialNumber !== undefined) {
            queryParameters['serial_number'] = requestParameters.serialNumber;
        }

        if (requestParameters.vehicleBrand !== undefined) {
            queryParameters['vehicle_brand'] = requestParameters.vehicleBrand;
        }

        if (requestParameters.imei !== undefined) {
            queryParameters['imei'] = requestParameters.imei;
        }

        if (requestParameters.clientName !== undefined) {
            queryParameters['client__name'] = requestParameters.clientName;
        }

        if (requestParameters.client !== undefined) {
            queryParameters['client'] = requestParameters.client;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/obu/nested/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20025FromJSON(jsonValue));
    }

    /**
     */
    async obuNested(requestParameters: ObuNestedRequest): Promise<InlineResponse20025> {
        const response = await this.obuNestedRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async obuNestedSingleRaw(requestParameters: ObuNestedSingleRequest): Promise<runtime.ApiResponse<ReadOnlyOBUNested>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling obuNestedSingle.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/obu/{id}/nested/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyOBUNestedFromJSON(jsonValue));
    }

    /**
     */
    async obuNestedSingle(requestParameters: ObuNestedSingleRequest): Promise<ReadOnlyOBUNested> {
        const response = await this.obuNestedSingleRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async obuPartialUpdateRaw(requestParameters: ObuPartialUpdateRequest): Promise<runtime.ApiResponse<ReadOnlyOBU>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling obuPartialUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling obuPartialUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/obu/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyOBUToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyOBUFromJSON(jsonValue));
    }

    /**
     */
    async obuPartialUpdate(requestParameters: ObuPartialUpdateRequest): Promise<ReadOnlyOBU> {
        const response = await this.obuPartialUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async obuReadRaw(requestParameters: ObuReadRequest): Promise<runtime.ApiResponse<ReadOnlyOBU>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling obuRead.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/obu/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyOBUFromJSON(jsonValue));
    }

    /**
     */
    async obuRead(requestParameters: ObuReadRequest): Promise<ReadOnlyOBU> {
        const response = await this.obuReadRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async obuUpdateRaw(requestParameters: ObuUpdateRequest): Promise<runtime.ApiResponse<ReadOnlyOBU>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling obuUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling obuUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/obu/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyOBUToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyOBUFromJSON(jsonValue));
    }

    /**
     */
    async obuUpdate(requestParameters: ObuUpdateRequest): Promise<ReadOnlyOBU> {
        const response = await this.obuUpdateRaw(requestParameters);
        return await response.value();
    }

}
