import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { RouteNames } from 'router/routes';
import cn from 'classnames';

export function Footer() {
    const { t } = useTranslation();
    const location = useLocation();
    const isMapVisible = [RouteNames.SCHEDULING_PLANNER, RouteNames.TRACKING_MAP].includes(
        location.pathname as RouteNames
    );
    const isFooterVisible = !location.pathname.includes(RouteNames.SCHEDULING_DISPATCHER_BOARD);

    return (
        <>
            {isFooterVisible && (
                <div className={cn('rl-footer', { 'rl-footer-map': isMapVisible })}>
                    <div className="rl-footer-copyright">© 2022 ROAD LORDS</div>

                    <a href="mailto:dispatcher@roadlords.com">{t('Footer.contactUs')}</a>

                    <a href="https://roadlords.com/privacy-policy" target="_blank" rel="noopener noreferrer">
                        {t('Footer.policy')}
                    </a>

                    <a href="https://roadlords.com/eula" target="_blank" rel="noopener noreferrer">
                        EULA
                    </a>

                    <a href="https://roadlords.com/terms-conditions" target="_blank" rel="noopener noreferrer">
                        {t('Footer.terms')}
                    </a>
                </div>
            )}
        </>
    );
}
