import { convertVehicleValues } from 'utils/helpers/converters';
import { CompanyVehicle } from 'services/api/domains/VehiclesApi';
import { Logic } from './logic';
import { VehicleIdentification } from 'conf';
import { Subject } from 'rxjs';

export interface FuelCard {
    id: number;
    number: string;
    fuelCompanyId: number | string;
}

export interface FuelCardInputModel {
    number: string;
    fuelCompanyId: number | string;
}

export class VehiclesLogic {
    private _vehicleIdentification?: VehicleIdentification;

    vehiclesChanged$: Subject<void>;

    constructor(private _logic: Logic) {
        this._vehicleIdentification = this._logic.settings().getProp('vehicleIdentification');
        this.vehiclesChanged$ = new Subject<void>();
    }

    changeVehicleIdentification(vehicleIdentification: VehicleIdentification) {
        this._vehicleIdentification = vehicleIdentification;
    }

    getVehicles = async () => {
        const companyId = this._logic.company().getCompany().companyId;
        return this._logic.apiService().vehicles().getVehicles(companyId);
    };

    getVehicle = async (vehicleId: number) => {
        const companyId = this._logic.company().getCompany().companyId;
        return this._logic.apiService().vehicles().getVehicle(vehicleId, companyId);
    };

    createVehicle = async (vehicle: CompanyVehicle) => {
        const companyId = this._logic.company().getCompany().companyId;
        const result = await this._logic.apiService().vehicles().createVehicle(vehicle, companyId);

        this.vehiclesChanged$.next();

        return result;
    };

    deleteVehicle = async (vehicleId: number) => {
        const companyId = this._logic.company().getCompany().companyId;
        await this._logic.apiService().vehicles().deleteVehicle(vehicleId, companyId);

        this.vehiclesChanged$.next();
    };

    updateVehicle = async (vehicle: CompanyVehicle) => {
        const companyId = this._logic.company().getCompany().companyId;
        const result = this._logic
            .apiService()
            .vehicles()
            .updateVehicle(convertVehicleValues(vehicle, 1000), companyId);

        this.vehiclesChanged$.next();

        return result;
    };
}
