import { Input, InputNumber, InputNumberProps } from 'antd';
import { InputProps } from 'antd/lib/input';
import { TextAreaProps } from 'antd/es/input';

interface Props extends InputProps {
    qa?: string;
}

function EnhancedInput({ qa, ...props }: Props) {
    return <Input data-qa={qa} {...props} className={props.className ? 'rl-input ' + props.className : 'rl-input'} />;
}

EnhancedInput.Number = function EnhancedInputNumber(props: InputNumberProps) {
    return <InputNumber {...props} className={props.className ? 'rl-input ' + props.className : 'rl-input'} />;
};

EnhancedInput.TextArea = function EnhancedTextArea(props: TextAreaProps) {
    return (
        <Input.TextArea
            {...props}
            className={props.className ? 'rl-input-text-area ' + props.className : 'rl-input-text-area'}
        />
    );
};

export default EnhancedInput;
