/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BorderCrossingEntry,
    BorderCrossingEntryFromJSON,
    BorderCrossingEntryToJSON,
    HTTPValidationError,
    HTTPValidationErrorFromJSON,
    HTTPValidationErrorToJSON,
} from '../models';

export interface GetBorderCrossingsV1BordercrossingsGetRequest {
    monitoredObjectId?: string;
    driverId?: number;
    clientId?: number;
    dateFrom?: Date;
    dateTo?: Date;
    countries?: Array<string>;
    wrapArray?: boolean;
}

/**
 * no description
 */
export class BorderCrossingApi extends runtime.BaseAPI {

    /**
     * Get Border Crossings
     */
    async getBorderCrossingsV1BordercrossingsGetRaw(requestParameters: GetBorderCrossingsV1BordercrossingsGetRequest): Promise<runtime.ApiResponse<Array<BorderCrossingEntry>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.monitoredObjectId !== undefined) {
            queryParameters['monitored_object_id'] = requestParameters.monitoredObjectId;
        }

        if (requestParameters.driverId !== undefined) {
            queryParameters['driver_id'] = requestParameters.driverId;
        }

        if (requestParameters.clientId !== undefined) {
            queryParameters['client_id'] = requestParameters.clientId;
        }

        if (requestParameters.dateFrom !== undefined) {
            queryParameters['date_from'] = (requestParameters.dateFrom as any).toISOString();
        }

        if (requestParameters.dateTo !== undefined) {
            queryParameters['date_to'] = (requestParameters.dateTo as any).toISOString();
        }

        if (requestParameters.countries) {
            queryParameters['countries'] = requestParameters.countries;
        }

        if (requestParameters.wrapArray !== undefined) {
            queryParameters['wrap_array'] = requestParameters.wrapArray;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/bordercrossings/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(BorderCrossingEntryFromJSON));
    }

    /**
     * Get Border Crossings
     */
    async getBorderCrossingsV1BordercrossingsGet(requestParameters: GetBorderCrossingsV1BordercrossingsGetRequest): Promise<Array<BorderCrossingEntry>> {
        const response = await this.getBorderCrossingsV1BordercrossingsGetRaw(requestParameters);
        return await response.value();
    }

}
