/* tslint:disable */
/* eslint-disable */
/**
 * Geocoding API
 * Telematics services geocoding API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GeoStatsResponse
 */
export interface GeoStatsResponse {
    /**
     * 
     * @type {number}
     * @memberof GeoStatsResponse
     */
    googleHits: number;
    /**
     * 
     * @type {number}
     * @memberof GeoStatsResponse
     */
    gridHits: number;
    /**
     * 
     * @type {number}
     * @memberof GeoStatsResponse
     */
    placeCount: number;
    /**
     * 
     * @type {number}
     * @memberof GeoStatsResponse
     */
    gridCount: number;
}

export function GeoStatsResponseFromJSON(json: any): GeoStatsResponse {
    return GeoStatsResponseFromJSONTyped(json, false);
}

export function GeoStatsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GeoStatsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'googleHits': json['googleHits'],
        'gridHits': json['gridHits'],
        'placeCount': json['placeCount'],
        'gridCount': json['gridCount'],
    };
}

export function GeoStatsResponseToJSON(value?: GeoStatsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'googleHits': value.googleHits,
        'gridHits': value.gridHits,
        'placeCount': value.placeCount,
        'gridCount': value.gridCount,
    };
}


