import { Popover } from 'antd';
import { PopoverProps } from 'antd/lib/popover';

interface Props extends PopoverProps {
    qa?: string;
}

export default function EnhancedPopover({ qa, ...props }: Props) {
    return (
        <Popover
            data-qa={qa}
            {...props}
            color={props.color ? props.color : '#202c3c'}
            overlayClassName={props.className ? props.className + ' rl-popover' : 'rl-popover'}
        />
    );
}
