/* tslint:disable */
/* eslint-disable */
/**
 * Telematics API
 * Telematics services API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ClientApplicationCreateBody,
    ClientApplicationCreateBodyFromJSON,
    ClientApplicationCreateBodyToJSON,
    MainDataResClientApplication,
    MainDataResClientApplicationFromJSON,
    MainDataResClientApplicationToJSON,
    MainDataResClientApplicationArray,
    MainDataResClientApplicationArrayFromJSON,
    MainDataResClientApplicationArrayToJSON,
    MainDataResvoid,
    MainDataResvoidFromJSON,
    MainDataResvoidToJSON,
} from '../models';

export interface DeleteRequest {
    appId: number;
}

export interface CreateRequest {
    clientApplicationCreateBody?: ClientApplicationCreateBody;
}

export interface EditRequest {
    appId: number;
    clientApplicationCreateBody?: ClientApplicationCreateBody;
}

export interface GetAPIConfigurationRequest {
    apiKey: string;
}

export interface GetOneRequest {
    appId: string;
}

/**
 * no description
 */
export class ApplicationsApi extends runtime.BaseAPI {

    /**
     */
    async _deleteRaw(requestParameters: DeleteRequest): Promise<runtime.ApiResponse<MainDataResClientApplication>> {
        if (requestParameters.appId === null || requestParameters.appId === undefined) {
            throw new runtime.RequiredError('appId','Required parameter requestParameters.appId was null or undefined when calling _delete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("jwt", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/applications/{appId}`.replace(`{${"appId"}}`, encodeURIComponent(String(requestParameters.appId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MainDataResClientApplicationFromJSON(jsonValue));
    }

    /**
     */
    async _delete(requestParameters: DeleteRequest): Promise<MainDataResClientApplication> {
        const response = await this._deleteRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async createRaw(requestParameters: CreateRequest): Promise<runtime.ApiResponse<MainDataResClientApplication>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("jwt", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/applications`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ClientApplicationCreateBodyToJSON(requestParameters.clientApplicationCreateBody),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MainDataResClientApplicationFromJSON(jsonValue));
    }

    /**
     */
    async create(requestParameters: CreateRequest): Promise<MainDataResClientApplication> {
        const response = await this.createRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async editRaw(requestParameters: EditRequest): Promise<runtime.ApiResponse<MainDataResClientApplication>> {
        if (requestParameters.appId === null || requestParameters.appId === undefined) {
            throw new runtime.RequiredError('appId','Required parameter requestParameters.appId was null or undefined when calling edit.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("jwt", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/applications/{appId}`.replace(`{${"appId"}}`, encodeURIComponent(String(requestParameters.appId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ClientApplicationCreateBodyToJSON(requestParameters.clientApplicationCreateBody),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MainDataResClientApplicationFromJSON(jsonValue));
    }

    /**
     */
    async edit(requestParameters: EditRequest): Promise<MainDataResClientApplication> {
        const response = await this.editRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getAPIConfigurationRaw(requestParameters: GetAPIConfigurationRequest): Promise<runtime.ApiResponse<MainDataResClientApplication>> {
        if (requestParameters.apiKey === null || requestParameters.apiKey === undefined) {
            throw new runtime.RequiredError('apiKey','Required parameter requestParameters.apiKey was null or undefined when calling getAPIConfiguration.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/v1/applications/api-key/{apiKey}`.replace(`{${"apiKey"}}`, encodeURIComponent(String(requestParameters.apiKey))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MainDataResClientApplicationFromJSON(jsonValue));
    }

    /**
     */
    async getAPIConfiguration(requestParameters: GetAPIConfigurationRequest): Promise<MainDataResClientApplication> {
        const response = await this.getAPIConfigurationRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getAllRaw(): Promise<runtime.ApiResponse<MainDataResClientApplicationArray>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("jwt", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/applications`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MainDataResClientApplicationArrayFromJSON(jsonValue));
    }

    /**
     */
    async getAll(): Promise<MainDataResClientApplicationArray> {
        const response = await this.getAllRaw();
        return await response.value();
    }

    /**
     */
    async getOneRaw(requestParameters: GetOneRequest): Promise<runtime.ApiResponse<MainDataResClientApplication>> {
        if (requestParameters.appId === null || requestParameters.appId === undefined) {
            throw new runtime.RequiredError('appId','Required parameter requestParameters.appId was null or undefined when calling getOne.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("jwt", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/applications/{appId}`.replace(`{${"appId"}}`, encodeURIComponent(String(requestParameters.appId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MainDataResClientApplicationFromJSON(jsonValue));
    }

    /**
     */
    async getOne(requestParameters: GetOneRequest): Promise<MainDataResClientApplication> {
        const response = await this.getOneRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async versionRaw(): Promise<runtime.ApiResponse<MainDataResvoid>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/applications/version`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MainDataResvoidFromJSON(jsonValue));
    }

    /**
     */
    async version(): Promise<MainDataResvoid> {
        const response = await this.versionRaw();
        return await response.value();
    }

}
