import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import { Button } from 'components/base/controls';

interface Props {
    onCancel?: () => void;
}

export function ResetPasswordSuccess(props: Props) {
    const { t } = useTranslation();

    return (
        <>
            <div>
                <p>{t('ResetPasswordSuccess.infoText')}</p>
            </div>

            <Row>
                <Col>
                    <Button type="link" onClick={props.onCancel}>
                        {t('common.close')}
                    </Button>
                </Col>
            </Row>
        </>
    );
}
