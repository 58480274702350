/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DriverInfoDriver,
    DriverInfoDriverFromJSON,
    DriverInfoDriverFromJSONTyped,
    DriverInfoDriverToJSON,
    Edit,
    EditFromJSON,
    EditFromJSONTyped,
    EditToJSON,
} from '.';

/**
 * 
 * @export
 * @interface ActivityInterval
 */
export interface ActivityInterval {
    /**
     * 
     * @type {Edit}
     * @memberof ActivityInterval
     */
    edit?: Edit;
    /**
     * 
     * @type {Array<Edit>}
     * @memberof ActivityInterval
     */
    edits?: Array<Edit>;
    /**
     * 
     * @type {string}
     * @memberof ActivityInterval
     */
    monitoredObjectId: string;
    /**
     * 
     * @type {number}
     * @memberof ActivityInterval
     */
    clientId?: number;
    /**
     * 
     * @type {Date}
     * @memberof ActivityInterval
     */
    start: Date;
    /**
     * 
     * @type {Date}
     * @memberof ActivityInterval
     */
    stop?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ActivityInterval
     */
    lastUpdate: Date;
    /**
     * 
     * @type {boolean}
     * @memberof ActivityInterval
     */
    _private?: boolean;
    /**
     * 
     * @type {DriverInfoDriver}
     * @memberof ActivityInterval
     */
    driver?: DriverInfoDriver;
}

export function ActivityIntervalFromJSON(json: any): ActivityInterval {
    return ActivityIntervalFromJSONTyped(json, false);
}

export function ActivityIntervalFromJSONTyped(json: any, ignoreDiscriminator: boolean): ActivityInterval {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'edit': !exists(json, 'edit') ? undefined : EditFromJSON(json['edit']),
        'edits': !exists(json, 'edits') ? undefined : ((json['edits'] as Array<any>).map(EditFromJSON)),
        'monitoredObjectId': json['monitored_object_id'],
        'clientId': !exists(json, 'client_id') ? undefined : json['client_id'],
        'start': (new Date(json['start'])),
        'stop': !exists(json, 'stop') ? undefined : (new Date(json['stop'])),
        'lastUpdate': (new Date(json['last_update'])),
        '_private': !exists(json, 'private') ? undefined : json['private'],
        'driver': !exists(json, 'driver') ? undefined : DriverInfoDriverFromJSON(json['driver']),
    };
}

export function ActivityIntervalToJSON(value?: ActivityInterval | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'edit': EditToJSON(value.edit),
        'edits': value.edits === undefined ? undefined : ((value.edits as Array<any>).map(EditToJSON)),
        'monitored_object_id': value.monitoredObjectId,
        'client_id': value.clientId,
        'start': (value.start.toISOString()),
        'stop': value.stop === undefined ? undefined : (value.stop.toISOString()),
        'last_update': (value.lastUpdate.toISOString()),
        'private': value._private,
        'driver': DriverInfoDriverToJSON(value.driver),
    };
}


