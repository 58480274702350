import { Drawer } from 'components/base/layout';
import { useTranslation } from 'react-i18next';
import { FleetCreateEditForm } from '../FleetCreateEditForm';
import { FleetCreateEditProps, FleetFormModel } from '../FleetCreateEditForm/FleetCreateEditForm';

interface Props extends FleetCreateEditProps {
    visible?: boolean;
    onChange?: (values: FleetFormModel) => void;
    onClose: () => void;
}

export function VehicleCreateEditSidebar(props: Props) {
    const { t } = useTranslation();

    return (
        <Drawer
            width={580}
            className="rl-vehicle-create-drawer"
            title={
                props.type === 'EDIT'
                    ? `${t('SettingsFleet.editVehicle')} - ${props.initValues?.registrationNumber}`
                    : t('SettingsFleet.addVehicle')
            }
            visible={props.visible}
            destroyOnClose={true}
            placement="right"
            onClose={props.onClose}
        >
            <FleetCreateEditForm
                type={props.type || 'CREATE'}
                initValues={props.initValues}
                loading={props.loading}
                onSubmit={props.onSubmit}
                onChange={props.onChange}
                onShowOnMapClick={props.onShowOnMapClick}
                onDeleteClick={props.onDeleteClick}
                onAssignDriverClick={props.onAssignDriverClick}
                onUnassignDriverClick={props.onUnassignDriverClick}
            />
        </Drawer>
    );
}
