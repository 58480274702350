import { ShareEtaCodes } from 'logic/shareEta';
import { InitiatedFrom } from 'logic/userEventsProviders/GoogleTagManager';
import { TrackingModel } from 'modules/TrackingModule/TrackingModule';
import { useTranslation } from 'react-i18next';
import { ShareEtaCode } from 'services/api/domains/ShareEtaApi';
import { Button, Spin } from 'components/base/controls';
import { useHistory } from 'react-router';
import { RouteNames } from 'router/routes';
import TrackingTableBlank from './ui/TrackingTableBlank/TrackingTableBlank';
import TrackingTableHeader from './ui/TrackingTableHeader/TrackingTableHeader';
import TrackingTableRow from './ui/TrackingTableRow/TrackingTableRow';
import { DropResult, DragDropContext, Droppable } from '@hello-pangea/dnd';

export enum TrackingStatus {
    NO_TRANSPORT,
    ASSIGNED,
    ACTIVE,
    DELAYED
}

export interface TrackingTransport {
    id?: string;
    name?: string;
    eta?: string;
    rta?: string;
    delayed?: string;
    status: TrackingStatus;
}

export const TrackingTableColSize = {
    vehicle: 3,
    location: 4,
    destination: 4,
    eta: 3,
    timeLeft: 2,
    kmLeft: 2,
    transportInfo: 4,
    transportStatus: 2
} as const;

interface Props {
    data: TrackingModel[];
    shareCodes?: ShareEtaCodes;
    isLoading: boolean;
    onAssignDriverClick: (vehicleId: string) => void;
    onAddDestination: (vehicleId: string) => void;
    onTransportClick: (transportId: string) => void;
    onShareEtaClick: (vehicle: TrackingModel) => void;
    onStopShareEtaClick: (shareCode?: ShareEtaCode) => void;
    onTransportDbClick: (vehicle: TrackingModel) => void;
    onAddVehicle: (initiatedFrom: InitiatedFrom) => void;
    onDragVehicleEnd: (result: DropResult) => void;
    onSelectVehicle: (vehicle: TrackingModel) => void;
}

const TrackingTable = (props: Props) => {
    const { t } = useTranslation();
    const history = useHistory();

    const _onSelectVehicle = (vehicle: TrackingModel) => {
        history.push(RouteNames.TRACKING_MAP);
        props.onSelectVehicle(vehicle);
    };

    return (
        <div className="rl-tracking-table">
            <Spin loading={props.isLoading}>
                {props.data?.length === 0 ? (
                    <TrackingTableBlank addVehicle={props.onAddVehicle} />
                ) : (
                    <>
                        <TrackingTableHeader />
                        <DragDropContext onDragEnd={props.onDragVehicleEnd}>
                            <Droppable key={'tracking-table-droppable'} droppableId={'tracking-table-droppable'}>
                                {provided => (
                                    <div ref={provided.innerRef} {...provided.droppableProps}>
                                        {props.data?.map((value, index) => (
                                            <TrackingTableRow
                                                key={index}
                                                index={index}
                                                tracking={value}
                                                shareCode={props.shareCodes?.[value.driverId]}
                                                onAssignDriverClick={props.onAssignDriverClick}
                                                onAddDestination={props.onAddDestination}
                                                onSelectVehicle={_onSelectVehicle}
                                                onTransportClick={props.onTransportClick}
                                                onShareEtaClick={props.onShareEtaClick}
                                                onStopShareEtaClick={props.onStopShareEtaClick}
                                                onTransportDbClick={props.onTransportDbClick}
                                            />
                                        ))}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>

                        {!props.isLoading && (
                            <Button
                                type="link"
                                size="middle"
                                onClick={() => props.onAddVehicle(InitiatedFrom.TRACKING_ADD_VEHICLE_BUTTON)}
                            >
                                {t('TrackingModule.addVehicle')}
                            </Button>
                        )}
                    </>
                )}
            </Spin>
        </div>
    );
};

export default TrackingTable;
