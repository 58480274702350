import moment from 'moment';
import cn from 'classnames';
import { DaysSegmentType } from '../../../common/types/DaySegment';

interface Props {
    startDate: string;
    daysAfter: number;
    daysBefore: number;
    daySegment: number;
}

const CalendarViewGridDateTime = (props: Props) => {
    const currentDateIndex = moment().startOf('day').diff(moment(props.startDate).startOf('day'), 'days');
    const getDays = (): number[] => {
        return [...Array(props.daysBefore + props.daysAfter + 1)];
    };

    return (
        <>
            {getDays().map((_, index) => {
                const day = moment(props.startDate).add(index, 'days');
                const segment = props.daySegment === DaysSegmentType.day5 ? 3 : props.daySegment;

                return (
                    <div className="rl-calendar-view-grid-datetime" key={index}>
                        <div
                            className={cn('rl-calendar-view-grid-datetime-day', {
                                'rl-calendar-view-grid-datetime-day-current': currentDateIndex === index,
                                'rl-calendar-view-grid-datetime-day-beffore': index < currentDateIndex
                            })}
                        >
                            <h5 title={day.format('dddd')}>{day.format('dddd')}</h5>
                            <span title={day.format('L')}>{day.format('L')}</span>
                        </div>
                        <div
                            className={cn(`rl-calendar-view-grid-datetime-time day-${props.daySegment}`, {
                                'rl-calendar-view-grid-datetime-time-current': currentDateIndex === index,
                                'rl-calendar-view-grid-datetime-time-beffore': index < currentDateIndex
                            })}
                        >
                            {[...Array(24)].map((_, index) => {
                                if (index % segment === 0) {
                                    if (index === 0 && props.daySegment === DaysSegmentType.day5) {
                                        return undefined;
                                    }

                                    return <span key={index}>{moment.utc(index * 3600 * 1000).format('HH:mm')}</span>;
                                }

                                return undefined;
                            })}
                        </div>
                    </div>
                );
            })}
        </>
    );
};

export default CalendarViewGridDateTime;
