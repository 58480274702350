import moment from 'moment';
import { AvailableCurrencies } from 'utils/constants/currencies';

const CLIENT_MOCK: any = {
    id: 'string',
    name: 'string',
    billingCode: 'string',
    identificationNumber: 'string',
    vatNumber: 'string',
    ssoId: 'string',
    creationDate: moment(),
    terminationDate: moment(),
    companyType: 1,
    vehiclesNo: 4,
    billingCycle: 1,
    currency: 'eur',
    discount: 0,
    isPartner: false,
    language: 'sk',
    geocodingLanguages: ['sk'],
    address: [],
    contactPerson: [],
    links: [],
    logo: 'string',
    styles: {
        color: 'red',
        font: 'arial'
    },
    costPerKm: { currency: AvailableCurrencies.EUR, cost: 1.234 },
    services: []
};

/**
 * @deprecated
 */
export function client() {
    return CLIENT_MOCK;
}
