import { useTranslation } from 'react-i18next';
import { Modal } from 'components/base/layout';
import { Button } from 'components/base/controls';

interface Props {
    visible: boolean;
    onCancel: () => void;
    onCreateVehicleClick: () => void;
}

export function NoVehicleModal(props: Props) {
    const { t } = useTranslation();

    return (
        <Modal
            className="rl-assign-vehicle-no-vehicle-modal"
            visible={props.visible}
            onCancel={props.onCancel}
            destroyOnClose
        >
            <div className="rl-assign-vehicle-no-vehicle-modal-content">
                <h3>{t('AssignVehicleModal.title')}</h3>
                <p>
                    {t('AssignVehicleModal.noVehicleInCompany')}
                    <br />
                    {t('AssignVehicleModal.createVehicleFirst')}
                </p>

                <Button
                    type="primary"
                    onClick={() => {
                        props.onCreateVehicleClick();
                    }}
                >
                    {t('AssignVehicleModal.createVehicle')}
                </Button>
            </div>
        </Modal>
    );
}
