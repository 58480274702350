import { Button, Tag } from 'components/base/controls';
import { useTranslation } from 'react-i18next';
import { TableViewFilterModel, TableViewFilterSelected } from '../../TableViewModule';

interface Props {
    filter: TableViewFilterModel;
    onFilterClear?: () => void;
    onFilterChange?: (filter: TableViewFilterSelected) => void;
}

const TableViewFilterTags = (props: Props) => {
    const { t } = useTranslation();

    const removeDriver = (id: string) => {
        props.onFilterChange?.({
            ...props.filter.selected,
            drivers: props.filter.selected.drivers.filter(item => item !== id.toString())
        });
    };

    const removeVehicle = (id: string) => {
        props.onFilterChange?.({
            ...props.filter.selected,
            vehicles: props.filter.selected.vehicles.filter(item => item !== id.toString())
        });
    };

    const removeState = (id: string) => {
        props.onFilterChange?.({
            ...props.filter.selected,
            states: props.filter.selected.states.filter(item => item !== id.toString())
        });
    };

    return (
        <div className="rl-table-view-filter-tags">
            {props.filter.selected.drivers.map(d => {
                const driver = props.filter.data.drivers[d];
                return <Tag text={driver.label} key={driver.id} onClose={() => removeDriver(driver.id)} />;
            })}

            {props.filter.selected.vehicles.map(v => {
                const vehicle = props.filter.data.vehicles[v];
                return <Tag text={vehicle.label} key={vehicle.id} onClose={() => removeVehicle(vehicle.id)} />;
            })}

            {props.filter.selected.states.map(s => {
                const state = props.filter.data.states[s];
                return (
                    <Tag
                        text={t(`common.transportStates.${state.id}`)}
                        key={state.id}
                        onClose={() => removeState(state.id)}
                    />
                );
            })}

            {(!!props.filter.selected.drivers.length ||
                !!props.filter.selected.vehicles.length ||
                !!props.filter.selected.states.length) && (
                <Button type="link" className="rl-table-view-filter-tags-clear" onClick={props.onFilterClear}>
                    {t('common.clearAll')}
                </Button>
            )}
        </div>
    );
};

export default TableViewFilterTags;
