import { useTranslation } from 'react-i18next';
import { Modal } from 'components/base/layout';
import { Button, Spin } from 'components/base/controls';
import Search from 'antd/es/input/Search';
import { useEffect, useState } from 'react';
import { CompanyVehicle } from '../../../../services/api/domains/VehiclesApi';
import cn from 'classnames';
import { NoVehicleModal } from '../NoVehicleModal';
import { useWindowDimensions } from 'hooks/useWindowDimensions';

interface Props {
    visible: boolean;
    vehicles?: CompanyVehicle[];
    assigningToTransport?: boolean;
    onCancel: () => void;
    onConfirm: (vehicleId: string) => void;
    onCreateVehicleClick: () => void;
}

export function AssignVehicleModal(props: Props) {
    const { t } = useTranslation();
    const { isMobileWidth } = useWindowDimensions();
    const [searchInput, setSearchInput] = useState('');
    const [selectedVehicleId, setSelectedVehicleId] = useState<number>();

    useEffect(() => {
        if (!props.visible) {
            resetState();
        }
    }, [props.visible]);

    function resetState() {
        setSelectedVehicleId(undefined);
        setSearchInput('');
    }

    return (
        <>
            {props.vehicles?.length === 0 ? (
                <NoVehicleModal
                    visible={props.visible}
                    onCancel={props.onCancel}
                    onCreateVehicleClick={props.onCreateVehicleClick}
                />
            ) : (
                <Modal
                    className="rl-assign-vehicle-modal"
                    visible={props.visible}
                    onCancel={props.onCancel}
                    destroyOnClose
                >
                    <div className="rl-assign-vehicle-modal-header">
                        {isMobileWidth && (
                            <Button
                                type="action"
                                icon={<i className="rl-icon-chevron-left" />}
                                onClick={props.onCancel}
                            />
                        )}
                        <h3>{t('AssignVehicleModal.title')}</h3>
                    </div>

                    <div className="rl-assign-vehicle-modal-content">
                        <Search
                            placeholder={t('AssignVehicleModal.searchPlaceholder')}
                            onChange={value => {
                                setSearchInput(value.target.value);
                            }}
                        />

                        <div className="rl-assign-vehicle-modal-list">
                            {!props.vehicles ? (
                                <Spin delay={200} loading={true} />
                            ) : (
                                props.vehicles
                                    ?.filter(
                                        vehicle =>
                                            vehicle.registrationNumber
                                                .toLowerCase()
                                                .includes(searchInput.toLowerCase()) ||
                                            vehicle.name?.toLowerCase().includes(searchInput.toLowerCase())
                                    )
                                    .map(vehicle => (
                                        <div
                                            key={vehicle.vehicleId}
                                            className={cn('rl-assign-vehicle-modal-card', {
                                                'rl-assign-vehicle-modal-card-selected':
                                                    selectedVehicleId === vehicle.vehicleId,
                                                'row-disabled': !!vehicle.drivers?.[0] && !props.assigningToTransport
                                            })}
                                            onClick={() => {
                                                if (vehicle.drivers?.[0] && !props.assigningToTransport) return;
                                                setSelectedVehicleId(vehicle.vehicleId);
                                            }}
                                        >
                                            <div className="rl-assign-vehicle-modal-card-flex-group">
                                                <div className="rl-assign-vehicle-modal-card-number">
                                                    <strong>{vehicle.registrationNumber}</strong>
                                                </div>
                                                <div>{vehicle.name}</div>
                                            </div>
                                            <div>
                                                {vehicle.drivers?.[0] ? (
                                                    vehicle.drivers[0].name
                                                ) : (
                                                    <>
                                                        {!props.assigningToTransport && (
                                                            <span className="status-label available">
                                                                {t('common.available')}
                                                            </span>
                                                        )}
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    ))
                            )}
                        </div>

                        <Button
                            className="rl-assign-vehicle-modal-assign-btn"
                            type="primary"
                            disabled={!selectedVehicleId}
                            onClick={() => {
                                props.onConfirm(selectedVehicleId!.toString());
                            }}
                        >
                            {t('AssignVehicleModal.assign')}
                        </Button>
                    </div>
                </Modal>
            )}
        </>
    );
}
