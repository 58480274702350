import { useTranslation } from 'react-i18next';
import { Checkbox } from 'components/base/controls';
import { Layers } from 'modules/MapModule/MapModule';
import qa from 'qa-selectors';
import { PlaceType } from 'services/api/domains/PlacesApi';

interface Props {
    values: Layers;
    fuelStationsLayerVisible: boolean;
    parkingsLayerVisible: boolean;
    onTrafficChange: (show: boolean) => void;
    onPostalCodesChange: (show: boolean) => void;
    onFavoritesChange: (show: boolean) => void;
    onRestrictionsChange: (checked: boolean) => void;
    onPlacesChange: (category: PlaceType) => void;
    onFuelStationsToggle: () => void;
    onParkingsToggle: () => void;
}

export function MapSettings(props: Props) {
    const { t } = useTranslation();

    return (
        <div className="rl-map-settings">
            <section>
                <div className="rl-map-settings-label">
                    <label>{t('MapControls.Map.generalTitle')}</label>
                </div>

                <div className="place-controls">
                    <Checkbox
                        data-qa={qa.mapControls.displayTrafficButton}
                        onChange={e => props.onTrafficChange(e.target.checked)}
                        checked={props.values.traffic}
                        className="rl-map-settings-checkbox"
                    >
                        {t('MapControls.Map.trafficTitle')}
                        <div className="traffic-legend">
                            <span className="traffic-legend-fast">{t('MapControls.Map.trafficFast')}</span>
                            <div className="traffic-legend-item">
                                <span className="traffic-box green-box" />
                            </div>
                            <div className="traffic-legend-item">
                                <span className="traffic-box orange-box" />
                            </div>
                            <div className="traffic-legend-item">
                                <span className="traffic-box medium-box" />
                            </div>
                            <div className="traffic-legend-item">
                                <span className="traffic-box red-box" />
                            </div>
                            <span className="traffic-legend-slow">{t('MapControls.Map.trafficSlow')}</span>
                        </div>
                    </Checkbox>

                    <Checkbox
                        data-qa={qa.mapControls.displayPostalCodesButton}
                        onChange={e => props.onPostalCodesChange(e.target.checked)}
                        checked={props.values.postalCodes}
                        className="rl-map-settings-checkbox"
                    >
                        {t('MapControls.Map.postalCodesTitle')}
                    </Checkbox>

                    <Checkbox
                        data-qa={qa.mapControls.restrictionsButton}
                        onChange={e => props.onRestrictionsChange(e.target.checked)}
                        checked={props.values.restrictions}
                        className="rl-map-settings-checkbox"
                    >
                        {t('MapControls.Map.restrictionsTitle')}
                    </Checkbox>
                </div>
            </section>

            <section>
                <div className="rl-map-settings-label">
                    <label>{t('MapControls.Map.placesTitle')}</label>
                </div>

                <div className="place-controls">
                    <Checkbox
                        data-qa={qa.mapControls.favoritesButton}
                        onChange={e => props.onFavoritesChange(e.target.checked)}
                        checked={props.values.favorites}
                        className="rl-map-settings-checkbox"
                    >
                        <div className="place-controls-item">
                            {t('MapControls.Map.favoritesTitle')}
                            <img src="/img/poi-detail/favorite-icon.svg" width={25} alt="Favorite" />
                        </div>
                    </Checkbox>
                    <Checkbox
                        data-qa={qa.mapControls.favoritesButton}
                        onChange={props.onParkingsToggle}
                        defaultChecked={props.parkingsLayerVisible}
                        className="rl-map-settings-checkbox"
                    >
                        <div className="place-controls-item">
                            {t('MapControls.Map.parkings')}
                            <img src="/img/poi-detail/parking-icon.svg" width={25} alt="Parkings" />
                        </div>
                    </Checkbox>
                    <Checkbox
                        data-qa={qa.mapControls.favoritesButton}
                        onChange={props.onFuelStationsToggle}
                        defaultChecked={props.fuelStationsLayerVisible}
                        className="rl-map-settings-checkbox"
                    >
                        <div className="place-controls-item">
                            {t('MapControls.Map.fuelStations')}
                            <img src="/img/poi-detail/fuelstation-icon.svg" width={25} alt="Fuelstations" />
                        </div>
                    </Checkbox>
                    <Checkbox
                        checked={props.values.places.company}
                        onChange={() => {
                            props.onPlacesChange?.(PlaceType.COMPANY);
                        }}
                        className="rl-map-settings-checkbox"
                    >
                        <div className="place-controls-item">
                            {t('MapControls.Map.company')}
                            <img src="/img/poi-detail/company-icon.svg" width={25} alt="Company" />
                        </div>
                    </Checkbox>
                    <Checkbox
                        checked={props.values.places.services}
                        onChange={() => {
                            props.onPlacesChange?.(PlaceType.FOOD_AND_DRINK);
                        }}
                        className="rl-map-settings-checkbox"
                    >
                        <div className="place-controls-item">
                            {t('MapControls.Map.services')}
                            <img src="/img/poi-detail/services-icon.svg" width={25} alt="Service" />
                        </div>
                    </Checkbox>
                    <Checkbox
                        checked={props.values.places.shop}
                        onChange={() => {
                            props.onPlacesChange?.(PlaceType.SHOP);
                        }}
                        className="rl-map-settings-checkbox"
                    >
                        <div className="place-controls-item">
                            {t('MapControls.Map.shop')}
                            <img src="/img/poi-detail/shop-icon.svg" width={25} alt="Shop" />
                        </div>
                    </Checkbox>
                </div>
            </section>
        </div>
    );
}
