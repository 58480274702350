import { Rate } from 'antd';
import moment from 'moment';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PlaceReviewItem, PlaceReviews } from 'services/api/domains/PlacesApi';

interface Props {
    reviews: PlaceReviews;
}

enum OrderState {
    NEWEST,
    TOP_RATED
}

export function PoiDetailRatings(props: Props) {
    const { t } = useTranslation();
    const { reviews } = props;
    const [orderButtonActive, setOrderButtonActive] = useState(OrderState.NEWEST);
    const [sortedReviews, setSortedReviews] = useState<PlaceReviewItem[]>(getSortedReviews(OrderState.NEWEST));

    function getActiveOrderButtonClass(state: OrderState) {
        return orderButtonActive === state && 'rl-poi-detail-ratings-header-right-button-active';
    }

    function getSortedReviews(state: OrderState) {
        let sortedReviews: PlaceReviewItem[] = [];
        if (state === OrderState.NEWEST) {
            sortedReviews =
                reviews.items?.sort((a, b) => {
                    return moment(a.createdOn).unix() - moment(b.createdOn).unix();
                }) || [];
        } else {
            sortedReviews =
                reviews.items?.sort((a, b) => {
                    return b.rating! - a.rating!;
                }) || [];
        }

        return sortedReviews;
    }

    function setOrderReviews(state: OrderState) {
        setOrderButtonActive(state);
        setSortedReviews(getSortedReviews(state));
    }

    return (
        <div className="rl-poi-detail-section rl-poi-detail-border-top">
            <div className="rl-poi-detail-ratings-header">
                <div className="rl-poi-detail-ratings-header-title">
                    {`${t('PoiDetail.ratings')} (${reviews.totalCount})`}
                </div>

                <div className="rl-poi-detail-ratings-header-right">
                    <div
                        className={`rl-poi-detail-ratings-header-right-button ${getActiveOrderButtonClass(
                            OrderState.NEWEST
                        )}`}
                        onClick={() => setOrderReviews(OrderState.NEWEST)}
                    >
                        {t('PoiDetail.newest')}
                    </div>
                    <div
                        className={`rl-poi-detail-ratings-header-right-button ${getActiveOrderButtonClass(
                            OrderState.TOP_RATED
                        )}`}
                        onClick={() => setOrderReviews(OrderState.TOP_RATED)}
                    >
                        {t('PoiDetail.topRated')}
                    </div>
                </div>
            </div>

            <div className="rl-poi-detail-ratings-list">
                {sortedReviews.map((review, index) => (
                    <div className="rl-poi-detail-ratings-list-rating" key={index}>
                        <div className="rl-poi-detail-ratings-list-rating-header">
                            {review.profile?.avatarUrl ? (
                                <img src={review.profile?.avatarUrl} alt="avatar" />
                            ) : (
                                <i className="rl-icon-user" />
                            )}
                            <div className="rl-poi-detail-ratings-list-rating-header-title">
                                <div className="rl-poi-detail-ratings-list-rating-header-title-name">
                                    {review.profile?.nick}
                                </div>
                                <div className="rl-poi-detail-ratings-list-rating-header-rating">
                                    <Rate
                                        allowHalf
                                        defaultValue={0}
                                        value={review.rating}
                                        style={{ fontSize: 13 }}
                                        disabled
                                    />
                                    <div className="rl-poi-detail-ratings-list-rating-header-title-date">
                                        {moment(review.createdOn).fromNow()}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="rl-poi-detail-ratings-list-rating-message">{review.message}</div>
                    </div>
                ))}
            </div>
        </div>
    );
}
