import cn from 'classnames';

import { EditorState } from 'draft-js';
import { MouseEvent } from 'react';
import { InlineStyles } from '../../RichEditor';

interface Props {
    editorState: EditorState;
    onToggleBold: () => void;
}

const RichEditorFonts = (props: Props) => {
    const currentStyle = props.editorState.getCurrentInlineStyle();

    const _onToggleBold = (e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        e.preventDefault();
        props.onToggleBold();
    };

    return (
        <div className="rl-rich-editor-fonts">
            <span
                className={cn('rl-rich-editor-fonts-bold', {
                    active: currentStyle.has(InlineStyles.Bold)
                })}
                onMouseDown={_onToggleBold}
            >
                <i className="fas fa-bold" />
            </span>
        </div>
    );
};

export default RichEditorFonts;
