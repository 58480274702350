interface Props {
    children: React.ReactNode;
}

export function Badge(props: Props) {
    return (
        <span className="rl-autocomplete-badge">
            <span className="pulse-circle">{props.children}</span>
        </span>
    );
}
