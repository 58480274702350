/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Cost,
    CostFromJSON,
    CostFromJSONTyped,
    CostToJSON,
} from '.';

/**
 * 
 * @export
 * @interface MonitoredObjectOperationalCosts
 */
export interface MonitoredObjectOperationalCosts {
    /**
     * 
     * @type {Cost}
     * @memberof MonitoredObjectOperationalCosts
     */
    fuel: Cost;
    /**
     * 
     * @type {Cost}
     * @memberof MonitoredObjectOperationalCosts
     */
    additives: Cost;
    /**
     * 
     * @type {Cost}
     * @memberof MonitoredObjectOperationalCosts
     */
    oil: Cost;
    /**
     * 
     * @type {Cost}
     * @memberof MonitoredObjectOperationalCosts
     */
    tires: Cost;
    /**
     * 
     * @type {Cost}
     * @memberof MonitoredObjectOperationalCosts
     */
    maintenance: Cost;
    /**
     * 
     * @type {Cost}
     * @memberof MonitoredObjectOperationalCosts
     */
    liabilityInsurance: Cost;
    /**
     * 
     * @type {Cost}
     * @memberof MonitoredObjectOperationalCosts
     */
    collisionInsurance: Cost;
    /**
     * 
     * @type {Cost}
     * @memberof MonitoredObjectOperationalCosts
     */
    personalInjuryInsurance: Cost;
    /**
     * 
     * @type {Cost}
     * @memberof MonitoredObjectOperationalCosts
     */
    parking: Cost;
    /**
     * 
     * @type {Cost}
     * @memberof MonitoredObjectOperationalCosts
     */
    cabinHeating: Cost;
    /**
     * 
     * @type {Cost}
     * @memberof MonitoredObjectOperationalCosts
     */
    telecomunicationsAndMonitoring: Cost;
    /**
     * 
     * @type {Cost}
     * @memberof MonitoredObjectOperationalCosts
     */
    washing: Cost;
}

export function MonitoredObjectOperationalCostsFromJSON(json: any): MonitoredObjectOperationalCosts {
    return MonitoredObjectOperationalCostsFromJSONTyped(json, false);
}

export function MonitoredObjectOperationalCostsFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitoredObjectOperationalCosts {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fuel': CostFromJSON(json['fuel']),
        'additives': CostFromJSON(json['additives']),
        'oil': CostFromJSON(json['oil']),
        'tires': CostFromJSON(json['tires']),
        'maintenance': CostFromJSON(json['maintenance']),
        'liabilityInsurance': CostFromJSON(json['liability_insurance']),
        'collisionInsurance': CostFromJSON(json['collision_insurance']),
        'personalInjuryInsurance': CostFromJSON(json['personal_injury_insurance']),
        'parking': CostFromJSON(json['parking']),
        'cabinHeating': CostFromJSON(json['cabin_heating']),
        'telecomunicationsAndMonitoring': CostFromJSON(json['telecomunications_and_monitoring']),
        'washing': CostFromJSON(json['washing']),
    };
}

export function MonitoredObjectOperationalCostsToJSON(value?: MonitoredObjectOperationalCosts | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fuel': CostToJSON(value.fuel),
        'additives': CostToJSON(value.additives),
        'oil': CostToJSON(value.oil),
        'tires': CostToJSON(value.tires),
        'maintenance': CostToJSON(value.maintenance),
        'liability_insurance': CostToJSON(value.liabilityInsurance),
        'collision_insurance': CostToJSON(value.collisionInsurance),
        'personal_injury_insurance': CostToJSON(value.personalInjuryInsurance),
        'parking': CostToJSON(value.parking),
        'cabin_heating': CostToJSON(value.cabinHeating),
        'telecomunications_and_monitoring': CostToJSON(value.telecomunicationsAndMonitoring),
        'washing': CostToJSON(value.washing),
    };
}


