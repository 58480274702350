/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InlineResponse20023,
    InlineResponse20023FromJSON,
    InlineResponse20023ToJSON,
    ReadOnlyOBUType,
    ReadOnlyOBUTypeFromJSON,
    ReadOnlyOBUTypeToJSON,
    WriteOnlyOBUType,
    WriteOnlyOBUTypeFromJSON,
    WriteOnlyOBUTypeToJSON,
} from '../models';

export interface ObuTypeCreateRequest {
    data: WriteOnlyOBUType;
}

export interface ObuTypeDeleteRequest {
    id: string;
}

export interface ObuTypeListRequest {
    nameIcontains?: string;
    nameIexact?: string;
    limit?: number;
    offset?: number;
}

export interface ObuTypePartialUpdateRequest {
    id: string;
    data: WriteOnlyOBUType;
}

export interface ObuTypeReadRequest {
    id: string;
}

export interface ObuTypeUpdateRequest {
    id: string;
    data: WriteOnlyOBUType;
}

/**
 * no description
 */
export class ObuTypeApi extends runtime.BaseAPI {

    /**
     */
    async obuTypeCreateRaw(requestParameters: ObuTypeCreateRequest): Promise<runtime.ApiResponse<ReadOnlyOBUType>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling obuTypeCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/obu-type/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyOBUTypeToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyOBUTypeFromJSON(jsonValue));
    }

    /**
     */
    async obuTypeCreate(requestParameters: ObuTypeCreateRequest): Promise<ReadOnlyOBUType> {
        const response = await this.obuTypeCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async obuTypeDeleteRaw(requestParameters: ObuTypeDeleteRequest): Promise<runtime.ApiResponse<ReadOnlyOBUType>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling obuTypeDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/obu-type/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyOBUTypeFromJSON(jsonValue));
    }

    /**
     */
    async obuTypeDelete(requestParameters: ObuTypeDeleteRequest): Promise<ReadOnlyOBUType> {
        const response = await this.obuTypeDeleteRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async obuTypeListRaw(requestParameters: ObuTypeListRequest): Promise<runtime.ApiResponse<InlineResponse20023>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.nameIcontains !== undefined) {
            queryParameters['name__icontains'] = requestParameters.nameIcontains;
        }

        if (requestParameters.nameIexact !== undefined) {
            queryParameters['name__iexact'] = requestParameters.nameIexact;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/obu-type/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20023FromJSON(jsonValue));
    }

    /**
     */
    async obuTypeList(requestParameters: ObuTypeListRequest): Promise<InlineResponse20023> {
        const response = await this.obuTypeListRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async obuTypePartialUpdateRaw(requestParameters: ObuTypePartialUpdateRequest): Promise<runtime.ApiResponse<ReadOnlyOBUType>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling obuTypePartialUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling obuTypePartialUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/obu-type/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyOBUTypeToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyOBUTypeFromJSON(jsonValue));
    }

    /**
     */
    async obuTypePartialUpdate(requestParameters: ObuTypePartialUpdateRequest): Promise<ReadOnlyOBUType> {
        const response = await this.obuTypePartialUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async obuTypeReadRaw(requestParameters: ObuTypeReadRequest): Promise<runtime.ApiResponse<ReadOnlyOBUType>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling obuTypeRead.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/obu-type/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyOBUTypeFromJSON(jsonValue));
    }

    /**
     */
    async obuTypeRead(requestParameters: ObuTypeReadRequest): Promise<ReadOnlyOBUType> {
        const response = await this.obuTypeReadRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async obuTypeUpdateRaw(requestParameters: ObuTypeUpdateRequest): Promise<runtime.ApiResponse<ReadOnlyOBUType>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling obuTypeUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling obuTypeUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/obu-type/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyOBUTypeToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyOBUTypeFromJSON(jsonValue));
    }

    /**
     */
    async obuTypeUpdate(requestParameters: ObuTypeUpdateRequest): Promise<ReadOnlyOBUType> {
        const response = await this.obuTypeUpdateRaw(requestParameters);
        return await response.value();
    }

}
