import { TollCosts } from 'services/api/domains/TollsApi';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Avoids, TransportModel } from '../../PlannerModule';
import { Button, Switch, Tag, Tooltip } from 'components/base/controls';

interface Props {
    loading?: boolean;
    transport?: TransportModel;
    avoids?: Avoids;
    tollCosts?: TollCosts;
    countries: { [code: string]: { name: string; code: string } };
    includeTollCosts: boolean;
    isLoggedIn?: boolean;
    onAvoidsChange?: (countryCode: string, avoidType: string, value: boolean) => void;
    onRouteAvoids?: () => void;
    onTollCostsClick?: (checked: boolean) => void;
}

export default function PlannerRouteOptions(props: Props) {
    const { t } = useTranslation();
    const [alertOnVisible, setAlertOnVisible] = useState(true);
    const [alertOffVisible, setAlertOffVisible] = useState(false);
    const [alertOnClosed, setAlertOnClosed] = useState(false);
    const [alertOffClosed, setAlertOffClosed] = useState(false);

    const _renderAvoids = (): any => {
        return Object.keys(props.avoids || {}).map(iso3 => {
            const avoids = [];

            for (const avoidType in props.avoids![iso3]) {
                if (props.avoids![iso3][avoidType]) {
                    avoids.push(
                        <Tag
                            text={t('AvoidsConfigurator.' + avoidType)}
                            key={iso3 + avoidType}
                            onClose={() => _onAvoidsChange(iso3, avoidType, false)}
                        />
                    );
                }
            }

            if (avoids.length) {
                return (
                    <div key={iso3} className="rl-route-options-avoids-item">
                        <div className="rl-route-options-avoids-item-country">
                            {props.countries[iso3.toLowerCase()].name}
                        </div>
                        <div className="rl-route-options-avoids-item-type">{avoids}</div>
                    </div>
                );
            }

            return false;
        });
    };

    const _onRouteAvoids = () => {
        props.onRouteAvoids?.();
    };

    const _onIncludeTollCostsClick = (checked: boolean) => {
        if (props.transport?.routePolyline) {
            props.onTollCostsClick?.(checked);

            if (checked) {
                setAlertOnVisible(!alertOnClosed);
                setAlertOffVisible(!checked);
            } else {
                setAlertOnVisible(checked);
                setAlertOffVisible(!alertOffClosed);
            }
        }
    };

    const _onAvoidsChange = (countryCode: string, avoidType: string, value: boolean) => {
        props.onAvoidsChange?.(countryCode, avoidType, value);
    };

    return (
        <div className="rl-route-options">
            <div className="rl-route-options-header">
                <div className="rl-route-options-header-title">
                    <h5>{t('RouteOptionsAndCost.header.title')}</h5>
                    <Tooltip.Icon title={t('RouteOptionsAndCost.header.tooltip')} />
                </div>
                <div className="rl-route-options-header-options">
                    <div className="rl-route-options-header-options-toll">
                        <Switch
                            checked={props.includeTollCosts}
                            onChange={_onIncludeTollCostsClick}
                            disabled={props.loading || !props.transport?.routePolyline}
                        />
                        <span>{t('RouteOptionsAndCost.header.toll')}</span>
                    </div>
                    <Button type="link" onClick={_onRouteAvoids}>
                        <span>{t('RouteOptionsAndCost.header.route')}</span>
                        <i className="fas fa-chevron-right" />
                    </Button>
                </div>
            </div>
            <div className="rl-route-options-avoids">{_renderAvoids()}</div>
            {!alertOnClosed && alertOnVisible && (
                <div className="rl-route-options-alert">
                    <h6>{t('RouteOptionsAndCost.alertOn')}</h6>
                    <Button type="icon" onClick={() => setAlertOnClosed(true)}>
                        <i className="rl-icon-close" />
                    </Button>
                </div>
            )}
            {!alertOffClosed && alertOffVisible && (
                <div className="rl-route-options-alert">
                    <h6>{t('RouteOptionsAndCost.alertOff')}</h6>
                    <Button type="icon" onClick={() => setAlertOffClosed(true)}>
                        <i className="rl-icon-close" />
                    </Button>
                </div>
            )}
        </div>
    );
}
