import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import { InitiatedFrom } from 'logic/userEventsProviders/GoogleTagManager';
import { Button } from 'components/base/controls';

interface Props {
    addVehicle: (initiatedFrom: InitiatedFrom) => void;
}

const TrackingTableBlank = (props: Props) => {
    const { t } = useTranslation();

    return (
        <div className="rl-tracking-table-blank">
            <img src="/img/tracking/tracking-blank-icon.svg" alt="Vehicle icon" />

            <h4 className="rl-tracking-table-blank-title">{t('TrackingBlank.title')}</h4>

            <p>{t('TrackingBlank.description')}</p>

            <Button
                type="primary"
                size="middle"
                icon={<i className="rl-button-icon fas fa-plus" />}
                onClick={() => {
                    props.addVehicle(InitiatedFrom.TRACKING_BLANK_ADD_VEHICLE_BUTTON);
                }}
            >
                {t('TrackingBlank.addVehicle')}
            </Button>

            <h4>{t('TrackingBlank.addVehicleTitle')}</h4>

            <Row>
                <Col span={12}>
                    <div className="rl-tracking-table-blank-feature">
                        <img src="/img/tracking/routing-profiles.svg" alt="Routing profiles icon" />
                        <span className="rl-tracking-table-blank-feature-title">
                            {t('TrackingBlank.profilesTitle')}
                        </span>
                        <span className="rl-tracking-table-blank-feature-text">{t('TrackingBlank.profilesText')}</span>
                    </div>
                </Col>
                <Col span={12}>
                    <div className="rl-tracking-table-blank-feature">
                        <img src="/img/tracking/connect-drivers.svg" alt="Connect drivers icon" />
                        <span className="rl-tracking-table-blank-feature-title">
                            {t('TrackingBlank.connectDriversTitle')}
                        </span>
                        <span className="rl-tracking-table-blank-feature-text">
                            {t('TrackingBlank.connectDriversText')}
                        </span>
                    </div>
                </Col>
            </Row>

            <Row>
                <Col span={12}>
                    <div className="rl-tracking-table-blank-feature">
                        <img src="/img/tracking/assign-vehicles.svg" alt="Assign vehicles icon" />
                        <span className="rl-tracking-table-blank-feature-title">
                            {t('TrackingBlank.assignVehicleTitle')}
                        </span>
                        <span className="rl-tracking-table-blank-feature-text">
                            {t('TrackingBlank.assignVehicleText')}
                        </span>
                    </div>
                </Col>
                <Col span={12}>
                    <div className="rl-tracking-table-blank-feature">
                        <img src="/img/tracking/vehicles-on-map.svg" alt="Vehicles on map icon" />
                        <span className="rl-tracking-table-blank-feature-title">
                            {t('TrackingBlank.scheduleTitle')}
                        </span>
                        <span className="rl-tracking-table-blank-feature-text">{t('TrackingBlank.scheduleText')}</span>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default TrackingTableBlank;
