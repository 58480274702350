import { UserEvent } from 'logic/userEventsProviders/GoogleTagManager';
import { Logic } from 'logic/logic';
import { Droppable } from '@hello-pangea/dnd';
import { useTranslation } from 'react-i18next';
import { CalendarViewBar, CalendarViewDragType } from '../../CalendarViewModule';
import CalendarViewTransport from '../CalendarViewTransport/CalendarViewTransport';
import cn from 'classnames';
import { DragEvent, useRef, useState } from 'react';
import { TransportModel } from 'logic/dispatcher-board';
import { Country } from 'services/api/domains/CountryApi';

interface Props {
    logic: Logic;
    countryList: Country[];
    newTransports: TransportModel[];
    startDate: string;
    bar: CalendarViewBar;
    vehiclesExist: boolean;
    transportDetailId?: string;
    firstTransportPopoverVisible: boolean;
    onTransportEditClick: (transportId: string, vehicleId?: string) => void;
    onTransportDetailClick: (transport: TransportModel) => void;
    onOpenDeleteTransportModal: (transport: TransportModel) => void;
    onOpenAssignVehicleModal: (transport: TransportModel) => void;
    onUnassignTransport: (transportId: string) => void;
    onTransportNoteChange: (transportId: string, note: string, cb: VoidFunction) => void;
    onTransportNoteEvent: (event: UserEvent, transportId: string, vehicleId?: number) => void;
    onFirstTransportPopoverClose?: () => void;
}

const CalendarViewGridUnassigned = (props: Props) => {
    const { t } = useTranslation();
    const unassignedRef = useRef<HTMLDivElement>(null);
    const [initialPos, setInitialPos] = useState<number | undefined>();
    const [initialHeight, setInitialHeight] = useState(unassignedRef.current?.offsetHeight);

    const initial = (e: DragEvent<HTMLDivElement> | null) => {
        setInitialPos(e?.clientY);
        if (unassignedRef.current) {
            setInitialHeight(unassignedRef.current.offsetHeight);
        }
    };

    const resize = (e: DragEvent<HTMLDivElement> | null) => {
        if (e && unassignedRef.current && initialHeight && initialPos) {
            const height = initialHeight + (e?.clientY - initialPos);

            if (height >= 145 && height <= 425) {
                unassignedRef.current.style.minHeight = `${height}px`;
            }
        }
    };

    return (
        <>
            <div className="rl-calendar-view-grid-unassigned" ref={unassignedRef}>
                <div className="rl-calendar-view-grid-unassigned-title">
                    <h5>{t('DispatcherBoard.unassignedTransports')}</h5>
                </div>
                <Droppable droppableId="unassigned-transports" type={CalendarViewDragType.Transports}>
                    {(provided, snapshot) => (
                        <div
                            className={cn('rl-calendar-view-grid-unassigned-transports', {
                                'rl-calendar-view-grid-unassigned-transports-drag': snapshot.isDraggingOver
                            })}
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                        >
                            {props.logic
                                .dispatcherBoard()
                                .transportBoardModel(props.newTransports)
                                .map((transports, index) => (
                                    <div className="rl-calendar-view-grid-unassigned-transport" key={index}>
                                        {transports.map(transport => (
                                            <CalendarViewTransport
                                                countryList={props.countryList}
                                                key={transport.id}
                                                bar={props.bar}
                                                startDate={props.startDate}
                                                transport={transport}
                                                firstTransportPopoverVisible={props.firstTransportPopoverVisible}
                                                transportDetailId={props.transportDetailId}
                                                onTransportEditClick={props.onTransportEditClick}
                                                onOpenDeleteTransportModal={props.onOpenDeleteTransportModal}
                                                onOpenAssignVehicleModal={props.onOpenAssignVehicleModal}
                                                onUnassignTransport={props.onUnassignTransport}
                                                onTransportNoteChange={props.onTransportNoteChange}
                                                onTransportNoteEvent={props.onTransportNoteEvent}
                                                onTransportDetailClick={props.onTransportDetailClick}
                                                onFirstTransportPopoverClose={props.onFirstTransportPopoverClose}
                                            />
                                        ))}
                                    </div>
                                ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </div>
            {props.vehiclesExist && (
                <div
                    className="rl-calendar-view-grid-unassigned-resize"
                    draggable="true"
                    onDragStart={initial}
                    onDrag={resize}
                >
                    <i className="fas fa-grip-lines" />
                </div>
            )}
        </>
    );
};

export default CalendarViewGridUnassigned;
