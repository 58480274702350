import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import qs from 'qs';
import { RouteNames } from 'router/routes';
import { FleetTable } from './ui/FleetTable/FleetTable';
import FleetBlank from './ui/FleetBlank/FleetBlank';
import { Modal, Notification } from 'components/base/layout';
import { Logic } from 'logic/logic';
import { Button } from 'components/base/controls';
import AssignDriverModule from 'modules/AssignDriverModule/AssignDriverModule';
import { CompanyVehicle } from 'services/api/domains/VehiclesApi';
import { VehicleCreateEditModule } from 'modules/VehicleCreateEditModule';
import { FleetFormModel } from 'modules/VehicleCreateEditModule/ui/FleetCreateEditForm';
import selectors from 'qa-selectors';
import { InitiatedFrom } from 'logic/userEventsProviders/GoogleTagManager';
import FleetList from './ui/FleetList/FleetList';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import { CompanyTransportStatus } from 'services/api/domains/TransportsApi';

interface Props {
    logic: Logic;
}

interface SettingsFleetTable {
    data?: CompanyVehicle[];
    loading?: boolean;
}

interface SettingsFleetAssignModal {
    visible: boolean;
    vehicleId: number;
}

interface SettingsFleetSidebar {
    visible?: boolean;
    formValues?: FleetFormModel;
    type?: 'CREATE' | 'EDIT';
}

export default function FleetModule(props: Props) {
    const { t } = useTranslation();
    const history = useHistory();
    const { isMobileWidth } = useWindowDimensions();

    const [table, setTable] = useState<SettingsFleetTable>();
    const [assignModal, setAssignModal] = useState<SettingsFleetAssignModal>();
    const [sidebar, setSidebar] = useState<SettingsFleetSidebar>();

    useEffect(() => {
        loadTableData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loadTableData = async () => {
        setTable({ loading: true, data: table?.data });

        props.logic
            .vehicles()
            .getVehicles()
            .then(data => setTable({ data: data.sort((a, b) => b.vehicleId! - a.vehicleId!), loading: false }));
    };

    const onAddVehicle = () => {
        setSidebar({
            ...sidebar,
            visible: true,
            type: 'CREATE'
        });
    };

    const closeSidebar = () => {
        setSidebar(undefined);
    };

    const onRemove = async (vehicleId: number) => {
        const companyId = props.logic.company().getCompany().companyId;
        const activeTransports = await props.logic
            .apiService()
            .transports()
            .getTransports(companyId, { statuses: [CompanyTransportStatus.Active] });

        const isVehicleOnActiveTransport = activeTransports.some(
            transport => transport.vehicle?.vehicleId === vehicleId
        );

        Modal.confirm({
            title: t('SettingsFleet.deleteHeader'),
            content: t(
                `SettingsFleet.${isVehicleOnActiveTransport ? 'deleteOnActiveTransportConfirm' : 'deleteConfirm'}`
            ),
            onOk: () => onVehicleRemoveConfirm(vehicleId)
        });
    };

    const onVehicleRemoveConfirm = async (vehicleId: number) => {
        try {
            await props.logic.vehicles().deleteVehicle(vehicleId);

            Notification.success({
                message: t('SettingsFleet.deleteSuccess')
            });
            closeSidebar();
            loadTableData();
        } catch (err) {
            console.error(err);
            Notification.error({
                message: err.message
            });
        }
    };

    const onUnassignDriver = (vehicleId: number, driverId: number) => {
        Modal.confirm({
            title: t('common.confirm'),
            content: t('SettingsFleet.unassignDriver'),
            onOk: () => onUnassignDriverConfirm(vehicleId, driverId)
        });
    };

    const onUnassignDriverConfirm = async (vehicleId: number, driverId: number) => {
        try {
            const companyId = props.logic.company().getCompany().companyId;
            await props.logic.apiService().vehicles().unassignDriver(driverId, companyId, vehicleId);
            props.logic.userEvents().unassignDriver(driverId, vehicleId, companyId);
            Notification.success({
                message: t('SettingsFleet.unassignSuccess')
            });
            loadTableData();
        } catch (err) {
            console.error(err);
            Notification.error({
                message: err.message
            });
        }
    };

    const locateOnMap = (vehicleId: number) => {
        history.push({
            pathname: RouteNames.TRACKING,
            search: qs.stringify({
                vehicleId,
                fromFleet: true
            })
        });
    };

    const onAssignDriverClick = (vehicleId: number) => {
        setAssignModal({
            visible: true,
            vehicleId
        });
    };

    const hideAssignDriverModal = () => {
        setAssignModal(undefined);
    };

    const afterAssign = () => {
        hideAssignDriverModal();
        loadTableData();
    };

    const onEditClick = (model: CompanyVehicle) => {
        let formValues = {
            ...(model as FleetFormModel)
        };

        const driver = model.drivers?.[0];
        if (driver) {
            formValues = {
                ...formValues,
                driver: {
                    driverId: driver?.profileId,
                    name: driver?.name || driver?.alias,
                    avatarUrl: driver?.avatarUrl
                }
            };
        }

        setSidebar({ visible: true, formValues, type: 'EDIT' });
    };

    const afterCreateVehicle = (vehicle: CompanyVehicle) => {
        props.logic.userEvents().addVehicle(vehicle, InitiatedFrom.SETTINGS_FLEET_ADD_VEHICLE_BUTTON);
    };

    return (
        <>
            <AssignDriverModule
                visible={!!assignModal?.visible}
                logic={props.logic}
                vehicleId={assignModal?.vehicleId!}
                onCancel={hideAssignDriverModal}
                afterAssign={afterAssign}
            />

            <div className="rl-settings-module rl-fleet-module">
                <div className="rl-settings-module-header">
                    {isMobileWidth && (
                        <Button
                            className="rl-settings-module-header-back"
                            type="action"
                            icon={<i className="rl-icon-chevron-left" />}
                            onClick={() => history.push(RouteNames.SETTINGS)}
                        />
                    )}

                    <h3 className="rl-settings-module-header-title">{t('common.fleet')}</h3>

                    {table?.data?.length !== 0 && (
                        <Button type="link" onClick={onAddVehicle} data-qa={selectors.fleet.addVehicleButton}>
                            {t('common.addVehicle')}
                        </Button>
                    )}
                </div>

                <div className="rl-settings-module-content">
                    {table?.data?.length === 0 ? (
                        <FleetBlank onAddVehicleClick={onAddVehicle} />
                    ) : isMobileWidth ? (
                        <FleetList
                            loading={table?.loading}
                            data={table?.data || []}
                            onAssignClick={onAssignDriverClick}
                            onUnassignClick={onUnassignDriver}
                            onChangeClick={onAssignDriverClick}
                            onEditClick={onEditClick}
                            onRemoveClick={onRemove}
                            onShowOnMapClick={locateOnMap}
                        />
                    ) : (
                        <FleetTable
                            loading={table?.loading}
                            data={table?.data || []}
                            onAssignClick={onAssignDriverClick}
                            onUnassignClick={onUnassignDriver}
                            onChangeClick={onAssignDriverClick}
                            onEditClick={onEditClick}
                            onRemoveClick={onRemove}
                            onShowOnMapClick={locateOnMap}
                            onRowDoubleClick={onEditClick}
                        />
                    )}
                </div>

                <VehicleCreateEditModule
                    logic={props.logic}
                    visible={sidebar?.visible}
                    type={sidebar?.type}
                    initValues={sidebar?.formValues}
                    onCancel={closeSidebar}
                    afterSubmit={() => {
                        closeSidebar();
                        loadTableData();
                    }}
                    afterCreate={afterCreateVehicle}
                />
            </div>
        </>
    );
}
