export type GeocodingGridLatLng = {
    lat: number;
    lng: number;
};

export type GeocodingGridXY = [number, number];

export interface GeocodingGridBounds {
    ne: {
        lat: number;
        lng: number;
    };
    sw: {
        lat: number;
        lng: number;
    };
}

export class GeocodingGrid {
    readonly southwest: GeocodingGridLatLng;
    readonly northeast: GeocodingGridLatLng;
    readonly latScale: number;
    readonly lngScale: number;

    private _dx: number;
    private _dy: number;

    constructor(
        northEast?: GeocodingGridLatLng,
        southWest?: GeocodingGridLatLng,
        latScale?: number,
        lngScale?: number
    ) {
        this.northeast = northEast || { lat: 90, lng: 180 };
        this.southwest = southWest || { lat: -90, lng: -180 };
        this.latScale = latScale || 180e4;
        this.lngScale = lngScale || 360e4;

        this._dx = (this.northeast.lng - this.southwest.lng) / this.lngScale;
        this._dy = (this.northeast.lat - this.southwest.lat) / this.latScale;
    }

    toXY(lat: number, lng: number): GeocodingGridXY {
        const x = Math.trunc((lng - this.southwest.lng) / this._dx);
        const y = Math.trunc((lat - this.southwest.lat) / this._dy);
        return [x, y];
    }

    bounds(x: number, y: number, distance = 0): GeocodingGridBounds {
        const west = (x - distance) * this._dx + this.southwest.lng;
        const east = (x + distance + 1) * this._dx + this.southwest.lng;

        const south = (y - distance) * this._dy + this.southwest.lat;
        const north = (y + distance + 1) * this._dy + this.southwest.lat;

        return {
            ne: {
                lat: north,
                lng: east
            },
            sw: {
                lat: south,
                lng: west
            }
        };
    }

    center(x: number, y: number): GeocodingGridLatLng {
        const bounds = this.bounds(x, y);
        return {
            lat: (bounds.sw.lat + bounds.ne.lat) / 2,
            lng: (bounds.sw.lng + bounds.ne.lng) / 2
        };
    }
}
