/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TimestampedLocation
 */
export interface TimestampedLocation {
    /**
     * 
     * @type {Date}
     * @memberof TimestampedLocation
     */
    time?: Date;
    /**
     * 
     * @type {number}
     * @memberof TimestampedLocation
     */
    lat: number;
    /**
     * 
     * @type {number}
     * @memberof TimestampedLocation
     */
    lng: number;
}

export function TimestampedLocationFromJSON(json: any): TimestampedLocation {
    return TimestampedLocationFromJSONTyped(json, false);
}

export function TimestampedLocationFromJSONTyped(json: any, ignoreDiscriminator: boolean): TimestampedLocation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'time': !exists(json, 'time') ? undefined : (new Date(json['time'])),
        'lat': json['lat'],
        'lng': json['lng'],
    };
}

export function TimestampedLocationToJSON(value?: TimestampedLocation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'time': value.time === undefined ? undefined : (value.time.toISOString()),
        'lat': value.lat,
        'lng': value.lng,
    };
}


