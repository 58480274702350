import { Logic } from 'logic/logic';
import qs from 'qs';
import { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { RouteNames } from 'router/routes';
import { FleetFormModel } from './ui/FleetCreateEditForm';
import { Notification } from 'components/base/layout/Notification';
import { Modal } from 'components/base/layout';
import AssignDriverModule from 'modules/AssignDriverModule/AssignDriverModule';
import { VehicleCreateEditSidebar } from './ui/VehicleCreateEditSidebar';
import { CompanyVehicle } from 'services/api/domains/VehiclesApi';
import { FirstVehicleModal } from './ui/FirstVehicleModal/FirstVehicleModal';
import { convertVehicleValues } from 'utils/helpers/converters';
import { CompanyTransportStatus } from 'services/api/domains/TransportsApi';

interface State {
    loading?: boolean;
    formValues?: Partial<FleetFormModel>;
    assignModal?: {
        visible: boolean;
        vehicleId?: number;
    };
    visibleFirstVehicleModal: boolean;
    firstVehicleId?: number;
}

interface Props extends WithTranslation, RouteComponentProps {
    logic: Logic;
    visible?: boolean;
    initValues?: FleetFormModel;
    type?: 'CREATE' | 'EDIT';
    onCancel: () => void;
    afterSubmit: () => void;
    afterCreate?: (vehicle: CompanyVehicle) => void;
}

class VehicleCreateEditModule extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            formValues: this.props.initValues && convertVehicleValues(this.props.initValues, 0.001),
            visibleFirstVehicleModal: false
        };
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.initValues !== this.props.initValues) {
            this.setState({ formValues: this.props.initValues && convertVehicleValues(this.props.initValues, 0.001) });
        }
    }

    render() {
        return (
            <>
                <AssignDriverModule
                    visible={!!this.state.assignModal?.visible}
                    logic={this.props.logic}
                    vehicleId={this.state.assignModal?.vehicleId!}
                    onCancel={this._hideAssignDriverModal}
                    onAssignClick={this._onDriverToAssignSelect}
                />

                <VehicleCreateEditSidebar
                    visible={this.props.visible}
                    type={this.props.type || 'CREATE'}
                    initValues={this.state.formValues}
                    loading={this.state.loading}
                    onClose={this.props.onCancel}
                    onSubmit={this._onVehicleFormSubmit}
                    onChange={this._onFormValuesChange}
                    onShowOnMapClick={this._locateOnMap}
                    onDeleteClick={this._onVehicleRemove}
                    onAssignDriverClick={this._onAssignDriverClick}
                    onUnassignDriverClick={this._onUnassignDriverClick}
                />

                <FirstVehicleModal
                    visible={this.state.visibleFirstVehicleModal}
                    onPlanRouteClick={this._onPlanRoute}
                    onCancel={this._onCancelFirstVehicleModal}
                />
            </>
        );
    }

    private _onPlanRoute = () => {
        this.props.history.push({
            pathname: RouteNames.SCHEDULING_PLANNER,
            search: qs.stringify({
                vehicleId: this.state.firstVehicleId
            })
        });
        this._onCancelFirstVehicleModal();
    };

    private _onFormValuesChange = (formValues: FleetFormModel) => {
        this.setState({ formValues });
    };

    private _onVehicleRemove = async (vehicleId: number) => {
        const companyId = this.props.logic.company().getCompany().companyId;
        const activeTransports = await this.props.logic
            .apiService()
            .transports()
            .getTransports(companyId, { statuses: [CompanyTransportStatus.Active] });

        const isVehicleOnActiveTransport = activeTransports.some(
            transport => transport.vehicle?.vehicleId === vehicleId
        );

        Modal.confirm({
            title: this.props.t('SettingsFleet.deleteHeader'),
            content: this.props.t(
                `SettingsFleet.${isVehicleOnActiveTransport ? 'deleteOnActiveTransportConfirm' : 'deleteConfirm'}`
            ),
            onOk: () => this._onVehicleRemoveConfirm(vehicleId)
        });
    };

    private _onVehicleRemoveConfirm = async (vehicleId: number) => {
        try {
            await this.props.logic.vehicles().deleteVehicle(vehicleId);

            Notification.success({
                message: this.props.t('SettingsFleet.deleteSuccess')
            });

            this.props.afterSubmit();

            this.props.logic.userEvents().deleteVehicle(vehicleId);
        } catch (err) {
            console.error(err);
            Notification.error({
                message: err.message
            });
        }
    };

    private _onAssignDriverClick = () => {
        this.setState({
            assignModal: {
                visible: true
            }
        });
    };

    private _onUnassignDriverClick = () => {
        this.setState(state => ({
            formValues: {
                ...state.formValues,
                driver: undefined
            }
        }));
    };

    private _hideAssignDriverModal = () => {
        this.setState({ assignModal: undefined });
    };

    private _onDriverToAssignSelect = async (driverId: number) => {
        const companyId = this.props.logic.company().getCompany().companyId;
        const drivers = await this.props.logic.apiService().company().getCompanyDrivers(companyId);
        const driver = drivers.find(driver => driver.profileId === driverId);

        if (!driver) throw new Error('Driver not found');

        this.setState(state => ({
            formValues: {
                ...state.formValues,
                driver: {
                    driverId: driver.profileId,
                    avatarUrl: driver.avatarUrl,
                    name: driver.name || driver.alias
                }
            }
        }));

        this._hideAssignDriverModal();
    };

    private _assignDriverToVehicle = async (driverId: number, vehicleId: number) => {
        const companyId = this.props.logic.company().getCompany().companyId;
        await this.props.logic.apiService().vehicles().assignDriver(driverId, companyId, vehicleId);
        this.props.logic.userEvents().assignDriver(driverId, vehicleId);
    };

    private _unassignDriverFromVehicle = async (driverId: number, vehicleId: number) => {
        const companyId = this.props.logic.company().getCompany().companyId;
        await this.props.logic.apiService().vehicles().unassignDriver(driverId, companyId, vehicleId);
        this.props.logic.userEvents().unassignDriver(driverId, vehicleId, companyId);
    };

    private _locateOnMap = (vehicleId: number) => {
        this.props.history.push({
            pathname: RouteNames.TRACKING,
            search: qs.stringify({
                vehicleId,
                fromFleet: true
            })
        });
    };

    private _onVehicleFormSubmit = async (formValues: FleetFormModel) => {
        const { driver, ...values } = formValues;
        try {
            this.setState({ loading: true });

            if (!values.vehicleId) {
                // Create
                const newVehicle = await this.props.logic.vehicles().createVehicle(convertVehicleValues(values, 1000));
                this.props.logic
                    .vehicles()
                    .getVehicles()
                    .then(vehicles => {
                        vehicles.length === 1 &&
                            this.setState({ visibleFirstVehicleModal: true, firstVehicleId: vehicles[0].vehicleId });
                    });

                if (driver?.driverId && newVehicle.vehicleId) {
                    await this._assignDriverToVehicle(driver.driverId, newVehicle.vehicleId);
                }

                this.props.afterCreate?.(newVehicle);
                Notification.success({ message: this.props.t('SettingsFleet.createSuccess') });
            } else {
                // Update
                await this.props.logic.vehicles().updateVehicle(values);

                const updatedVehicle = await this.props.logic.vehicles().getVehicle(values.vehicleId);

                if (driver?.driverId) {
                    if (updatedVehicle.drivers?.[0]) {
                        // unassign already assigned driver
                        await this._unassignDriverFromVehicle(updatedVehicle.drivers[0].profileId, values.vehicleId);
                    }
                    // assign new driver
                    await this._assignDriverToVehicle(driver.driverId, values.vehicleId);
                } else {
                    if (updatedVehicle.drivers?.[0]) {
                        // unassign already assigned driver
                        await this._unassignDriverFromVehicle(updatedVehicle.drivers[0].profileId, values.vehicleId);
                    }
                }

                Notification.success({ message: this.props.t('SettingsFleet.updateSuccess') });

                this.props.logic.userEvents().updateVehicle(updatedVehicle);
            }
            this.props.afterSubmit();
        } catch (err) {
            console.error(err);
            Notification.error({
                message: err.message,
                placement: 'bottomRight'
            });
        } finally {
            this.setState({ loading: false, formValues: {} });
        }
    };

    private _onCancelFirstVehicleModal = () => {
        this.setState({ visibleFirstVehicleModal: false });
    };
}

export default withRouter(withTranslation()(VehicleCreateEditModule));
