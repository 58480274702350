import { Button } from 'components/base/controls';
import { Drawer } from 'components/base/layout';
import { useTranslation } from 'react-i18next';
import { TableViewFilterModel, TableViewFilterSelected } from '../../TableViewModule';
import TableViewFilter from '../TableViewFilter/TableViewFilter';

interface Props {
    filter: TableViewFilterModel;
    visible: boolean;
    onFilterChange: (filter: TableViewFilterSelected) => void;
    onFilterClear: () => void;
    onCloseFilterSidebar: () => void;
}

const TableViewFilterSidebar = (props: Props) => {
    const { t } = useTranslation();

    return (
        <Drawer
            className="rl-table-view-filter-sidebar"
            title={t('common.filter')}
            visible={props.visible}
            placement="right"
            mask={false}
            width={'100%'}
            closable={true}
            onClose={props.onCloseFilterSidebar}
        >
            <div className="rl-table-view-filter-sidebar-form">
                <div className="rl-table-view-filter-sidebar-content">
                    <TableViewFilter filter={props.filter} onFilterChange={props.onFilterChange} />
                </div>
                <div className="rl-table-view-filter-sidebar-actions">
                    <Button type="default" onClick={props.onFilterClear}>
                        {t('common.clearAll')}
                    </Button>
                    <Button type="primary" onClick={props.onCloseFilterSidebar}>
                        {t('common.confirm')}
                    </Button>
                </div>
            </div>
        </Drawer>
    );
};

export default TableViewFilterSidebar;
