import { Conf } from 'conf';
import { HttpClient } from '../common/HttpClient';
const FUEL_CARDS_API_VERSION = '7';

export interface FuelCardsBrandsModel {
    brandId: number;
    name: string;
}

class FuelCardsApi {
    constructor(private _conf: Conf['api'], private _httpClient: HttpClient) {}

    async getBrands(): Promise<FuelCardsBrandsModel[]> {
        const { brands } = await this._httpClient.get<{ brands: any }>(`${this._conf.coreUrl}/FuelCard/brands`, {
            apiVersion: FUEL_CARDS_API_VERSION
        });

        return brands;
    }
}

export { FuelCardsApi };
