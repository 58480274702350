import { Subject } from 'rxjs';
import { FuelStationPriceList } from 'generated/backend-api';
import { ReadOnlyCurrency } from 'generated/new-main';
import { Logic } from './logic';
import {
    FuelStationServicesTranslations,
    fuelToFuelTypeMap,
    FuelTypes,
    PoiModelMap,
    serviceToTypeMap
} from './map/logic/fuelStations';
import { Fuels, Services } from '../modules/MapModule/MapModule';
import { centerToLatLngObject } from './common/geo-utils';
import { Favorite, FavoriteType } from '../services/api/domains/FavoritesApi';
import { PlaceType } from '../services/api/domains/PlacesApi';
import { PLACE_VISIBLE_MIN_ZOOM } from './map/logic/places';
import { RootStore } from 'stores/RootStore';
import { IReactionDisposer, reaction, toJS } from 'mobx';
import { LatLng } from 'common/model/geo';
import { fsBestPriceInFuelTypes } from './common/best-price';
import { Location } from 'types';
import moment from 'moment';
import { Env } from 'conf';
import { FuelCardModel } from 'services/api/domains/CompanyApi';

export interface PoiModel {
    id?: string;
    name?: string;
    type?: PlaceType;
    forbidden?: boolean;
    address?: string;
    notes?: string;
    center?: LatLng;
    polygon?: LatLng[];
    parkingSize?: number;
    brandName?: string;
    countryIso?: string;
    city?: string;
}

export class PoiLogic {
    private _store: RootStore;
    private _logic: Logic;
    private _mobxDisposeFunctions: IReactionDisposer[] = [];
    private _fuelStations?: PoiModelMap[];
    private _parkings?: PoiModelMap[];
    private _favorites: PoiModelMap[] = [];
    private _places: PoiModelMap[] = [];
    private _fuelStationsByIndex: { [id: string]: PoiModelMap } = {};
    private _parkingsByIndex: { [id: string]: PoiModelMap } = {};
    private _placesByIndex: { [id: string]: PoiModelMap } = {};

    fuelStationsLoaded$: Subject<boolean>;
    parkingsLoaded$: Subject<boolean>;
    favoritesLoaded$: Subject<boolean>;
    currencies?: ReadOnlyCurrency[];

    constructor(logic: Logic, store: RootStore) {
        this._store = store;
        this._logic = logic;

        this.fuelStationsLoaded$ = new Subject<boolean>();
        this.parkingsLoaded$ = new Subject<boolean>();
        this.favoritesLoaded$ = new Subject();
    }

    async init() {
        this.exchangeRate();

        await Promise.all([this.parkings(), this.fuelStations()]);
        await this.setFavoritesOnPois();

        this._mobxDisposeFunctions.push(
            reaction(
                () => this._store.auth.isLoggedIn,
                isLoggedIn => {
                    if (isLoggedIn) {
                        this.setFavoritesOnPois();
                    } else {
                        this._resetFavorites();
                        this._logic
                            .map()
                            .fuelStations()
                            .setData(this._fuelStations ?? []);
                        this._logic
                            .map()
                            .parkings()
                            .setData(this._parkings ?? []);
                    }
                }
            )
        );
    }

    destroy() {
        this._mobxDisposeFunctions.forEach(disposer => disposer());
        this._mobxDisposeFunctions = [];
    }

    unselect(poiId: string, placeType?: PlaceType): void {
        if (placeType === PlaceType.PARKING_LOT) {
            const parking = this._parkingsByIndex?.[poiId];
            if (parking) {
                parking.selected = false;
                this._logic.map().parkings().showMarker(parking);
            }
        } else if (placeType === PlaceType.GAS_STATION) {
            const fuelStation = this._fuelStationsByIndex?.[poiId];
            if (fuelStation) {
                fuelStation.selected = false;
                this._logic.map().fuelStations().showMarker(fuelStation);
            }
        } else {
            const place = this._placesByIndex?.[poiId];
            if (place) {
                place.selected = false;
                this._logic.map().places().showMarker(place);
            }
        }
    }

    unselectFuelStations(): void {
        if (this._fuelStations?.length) {
            this._fuelStations = this._fuelStations.map(fuelStation => {
                const fs = {
                    ...fuelStation,
                    selected: false
                };
                this._fuelStationsByIndex[fs.id] = fs;
                return fs;
            });
        }
    }

    fuelStations(): Promise<PoiModelMap[]> {
        return new Promise<PoiModelMap[]>(resolve => {
            if (this._fuelStations) {
                this._fuelStations = this._fuelStations.map(f => {
                    const fs = {
                        ...f,
                        selected: false,
                        isAlongRoute: null
                    };
                    this._fuelStationsByIndex[fs.id] = fs;
                    return fs;
                });

                resolve(this._fuelStations);
            } else {
                this._logic
                    .apiService()
                    .places()
                    .getFuelStations()
                    .then(r => {
                        const fuelstations = (r.data.fuelStations || []).map<PoiModelMap>(fs => {
                            const fuelStation = {
                                id: fs._id,
                                externalId: String(fs.externalId),
                                name: fs.name || '',
                                selected: false,
                                services: (fs.services || []).map(code => ({
                                    code,
                                    name: FuelStationServicesTranslations[code]
                                })),
                                position: centerToLatLngObject(fs.center.coordinates),
                                fuelCardIds: fs.fuelCardIds,
                                fuelTypes: (fs.fuelTypes || []).map(code => ({
                                    code,
                                    name: FuelTypes[code]
                                })),
                                category: PlaceType.GAS_STATION,
                                isAlongRoute: null
                            } as PoiModelMap;

                            this._fuelStationsByIndex[fs._id] = fuelStation;

                            return fuelStation;
                        });

                        this._fuelStations = fuelstations;
                        this.fuelStationsLoaded$.next(true);
                        resolve(fuelstations);
                    });
            }
        });
    }

    fuelStationDetail(id: string): PoiModelMap | null {
        const fuelStationDetail = this._fuelStationsByIndex[id];
        if (fuelStationDetail) {
            fuelStationDetail.category = PlaceType.GAS_STATION;
            fuelStationDetail.selected = true;

            this._logic
                .map()
                .selectedPoi()
                .show({ ...fuelStationDetail });

            this._logic.map().fuelStations().deleteMarker(fuelStationDetail.id);

            return fuelStationDetail;
        }

        return null;
    }

    getParkings() {
        return this._parkings;
    }

    parkings(): Promise<PoiModelMap[]> {
        return new Promise(resolve => {
            if (this._parkings) {
                this._parkings = this._parkings.map(pk => {
                    const p = { ...pk, selected: false, isAlongRoute: null };
                    this._parkingsByIndex[p.id] = p;
                    return p;
                });
                resolve(this._parkings);
            } else {
                this._logic
                    .apiService()
                    .places()
                    .getParkings()
                    .then(r => {
                        const parkingLots = (r.data.parkingLots || []).map<PoiModelMap>(pk => {
                            const parking = {
                                id: pk._id || '',
                                name: pk.name || '',
                                selected: false,
                                position: centerToLatLngObject(pk.center.coordinates),
                                category: PlaceType.PARKING_LOT,
                                isAlongRoute: null
                            } as PoiModelMap;

                            this._parkingsByIndex[pk._id] = parking;

                            return parking;
                        });

                        this._parkings = parkingLots;
                        this.parkingsLoaded$.next(true);
                        resolve(parkingLots);
                    });
            }
        });
    }

    parkingDetail(id: string): PoiModelMap | null {
        const parkingDetail = this._parkingsByIndex?.[id];
        if (parkingDetail) {
            parkingDetail.category = PlaceType.PARKING_LOT;
            parkingDetail.selected = true;

            this._logic
                .map()
                .selectedPoi()
                .show({ ...parkingDetail });

            this._logic.map().parkings().deleteMarker(parkingDetail.id);

            return parkingDetail;
        }

        return null;
    }

    placeDetail(id: string): PoiModelMap | null {
        const placeDetail = this._placesByIndex?.[id];
        if (placeDetail) {
            placeDetail.selected = true;

            this._logic
                .map()
                .selectedPoi()
                .show({ ...placeDetail });

            this._logic.map().places().deleteMarker(placeDetail.id);

            return placeDetail;
        }

        return null;
    }

    exchangeRate() {
        this._logic
            .api()
            .currencyApi.currencyList({ code: this._store.userSettings.currency })
            .then(resp => {
                this.currencies = resp.results;
                this._logic.map().fuelStations().setCurrencies(this.currencies);
            });
    }

    filterFuelStations = (fuels: Fuels, services: Services): PoiModelMap[] => {
        let data: PoiModelMap[] = this._fuelStations ?? [];

        const fuelFilter = Object.values(fuels).some(e => e);
        const serviceFilter = Object.values(services).some(e => e);

        // Filtering logic based on serviceToTypeMap & fuelToFuelTypeMap maps
        if (fuelFilter) {
            const fsKeys = Object.entries(fuels).reduce<string[][]>((acc, [key, value]) => {
                if (value) {
                    acc.push(fuelToFuelTypeMap[key]);
                }
                return acc;
            }, []);

            data = data.filter(fs => {
                return fsKeys.some(keySet => {
                    const included = keySet.some(key => (fs.fuelTypes || []).map(e => e.code).includes(key));
                    return included;
                });
            });
        }

        if (serviceFilter) {
            const svKeys = Object.entries(services).reduce<string[][]>((acc, [key, value]) => {
                if (value) {
                    acc.push(serviceToTypeMap[key]);
                }
                return acc;
            }, []);

            data = data.filter(fs => {
                return svKeys.every(keySet => {
                    const included = keySet.some(key => (fs.services || []).map(e => e.code).includes(key));
                    return included;
                });
            });
        }

        return data;
    };

    updateFuelStationFuelPrices(fuelStationId: string, fuelPrices: FuelStationPriceList[], fuelCards: FuelCardModel[]) {
        const fuelStationToUpdate = this._fuelStationsByIndex[fuelStationId];

        if (fuelStationToUpdate) {
            fuelStationToUpdate.fuelTypes = fuelPrices.map(toFuelType);

            if (fuelStationToUpdate.fuelTypes?.length) {
                const bestPrice = fsBestPriceInFuelTypes(fuelStationToUpdate.fuelTypes, this.currencies);

                if (bestPrice) {
                    this._addFuelDiscounts([fuelStationToUpdate], fuelCards);

                    const price = bestPrice.price?.discountedPrice
                        ? Number(bestPrice.price?.discountedPrice)
                        : Number(bestPrice.price?.price);

                    fuelStationToUpdate.bestPrice = { value: price, label: price + ' ' + bestPrice.price?.currency };
                }
            }
        }

        return fuelStationToUpdate;
    }

    private async _addFuelDiscounts(fuelStations: PoiModelMap[], fuelCards: FuelCardModel[]) {
        if (fuelCards?.length) {
            fuelStations.forEach(fuelStation => {
                const supportedCards = fuelCards
                    ?.filter(fuelCard => fuelStation.fuelCardIds?.includes(fuelCard.brandId))
                    .sort((a, b) => Number(b.discount) - Number(a.discount));

                const discountCard = supportedCards[0]; // fuel card with the highest discount

                if (discountCard?.discount) {
                    fuelStation.fuelTypes?.forEach(fuelType => {
                        if (fuelType.price) {
                            fuelType.price.discountedPrice = (
                                Number(fuelType.price.price) *
                                (1 - Number(discountCard.discount) / 100)
                            ).toFixed(2);
                        }
                    });

                    const bestPriceFuel = fsBestPriceInFuelTypes(fuelStation.fuelTypes!);

                    if (bestPriceFuel) {
                        this._logic?.poi().updateFuelStationDiscount(fuelStation.id, {
                            bestDiscount: bestPriceFuel,
                            discountCard: discountCard
                        });
                    }
                } else {
                    this._logic?.poi().updateFuelStationDiscount(fuelStation.id, undefined);
                }
            });
        } else {
            fuelStations.forEach(fuelStation => {
                this._logic?.poi().updateFuelStationDiscount(fuelStation.id, undefined);
            });
        }

        return fuelStations;
    }

    updateFuelStationDiscount(fuelStationId: string, discount?: PoiModelMap['discount']) {
        const fuelStationToUpdate = this._fuelStationsByIndex[fuelStationId];
        if (fuelStationToUpdate) {
            fuelStationToUpdate.discount = discount;
        }
    }

    addPoiToCollectionAndShowOnMap(model: PoiModelMap, type: PlaceType) {
        if (type === PlaceType.PARKING_LOT) {
            this._parkings?.push(model);
            this._parkingsByIndex[model.id] = model;
            this._logic
                .map()
                .parkings()
                .setData(this._parkings ?? []);
            this._logic.map().parkings().show();
        } else if (type === PlaceType.GAS_STATION) {
            this._fuelStations?.push(model);
            this._fuelStationsByIndex[model.id] = model;
            this._logic
                .map()
                .fuelStations()
                .setData(this._fuelStations ?? []);
            this._logic.map().fuelStations().show();
        } else {
            this._logic.map().jumpTo({ lat: model.position.lat, lng: model.position.lng }, PLACE_VISIBLE_MIN_ZOOM);
            this._logic.map().places().refreshPlaces();
        }
    }

    async setFavoritesOnPois() {
        if (!this._store.auth.isLoggedIn) {
            return;
        }

        this._resetFavorites();

        const favorites = toJS(await this._store.favoritesStore.getFavoritesWithAddress());

        favorites.forEach(favorite => {
            const poi =
                this._fuelStationsByIndex[favorite.id] ||
                this._parkingsByIndex[favorite.id] ||
                this._placesByIndex[favorite.id];

            if (poi) {
                poi.isFavorite = true;
                this._favorites.push(poi);
            } else if (Number(favorite.version) >= 5) {
                // create poi from favorite
                const favoritePoi: PoiModelMap = {
                    id: favorite.id,
                    externalId: favorite.id,
                    name: favorite.name!,
                    position: { lat: favorite.center?.lat!, lng: favorite.center?.lng! },
                    category: favorite.type as unknown as PlaceType,
                    selected: false,
                    isFavorite: true,
                    isAlongRoute: null
                };

                this._favorites.push(favoritePoi);

                switch (Number(favoritePoi.category)) {
                    case PlaceType.PARKING_LOT:
                        this._parkings?.push(favoritePoi);
                        this._parkingsByIndex[favoritePoi.id] = favoritePoi;
                        break;
                    case PlaceType.GAS_STATION:
                        this._fuelStations?.push(favoritePoi);
                        this._fuelStationsByIndex[favoritePoi.id] = favoritePoi;
                        break;
                    default:
                        this._places?.push(favoritePoi);
                        this._placesByIndex[favoritePoi.id] = favoritePoi;
                }
            }
        });

        this.favoritesLoaded$.next(true);
    }

    private _resetFavorites() {
        this._favorites.forEach(favorite => {
            const poi =
                this._fuelStationsByIndex[favorite.id] ||
                this._parkingsByIndex[favorite.id] ||
                this._placesByIndex[favorite.id];
            if (poi) {
                poi.isFavorite = false;
            }
        });
    }

    setFavoriteOnPoi(placeId: string, type: FavoriteType, favoriteFlag: boolean) {
        if (type === FavoriteType.GAS_STATION) {
            const poi = this._fuelStationsByIndex[placeId];
            if (poi) {
                poi.isFavorite = favoriteFlag;
                this._favorites.push(poi);
                if (this._logic.map().fuelStations().visible) {
                    this._logic
                        .map()
                        .fuelStations()
                        .setData(this._fuelStations ?? []);
                }
            }
        } else if (type === FavoriteType.PARKING_LOT) {
            const poi = this._parkingsByIndex[placeId];

            if (poi) {
                poi.isFavorite = favoriteFlag;
                this._favorites.push(poi);
                if (this._logic.map().parkings().visible) {
                    this._logic
                        .map()
                        .parkings()
                        .setData(this._parkings ?? []);
                }
            }
        } else {
            this._logic.map().places().refreshPlaces();
        }
    }

    async getPlacesInArea(bounds: string): Promise<PoiModelMap[]> {
        const places = await this._logic.apiService().places().getPlacesInArea(bounds);
        let favorites: Favorite[] = [];

        if (this._store.auth.isLoggedIn) {
            favorites = await this._store.favoritesStore.getFavoritesWithAddress();

            places.forEach((poi: any) => {
                if (favorites.some(fav => fav.id === poi.id)) {
                    poi.isFavorite = true;
                }
            });
        }

        places.forEach((p: any) => {
            this._placesByIndex[p.id] = p;
        });

        this._places = Object.values(this._placesByIndex);

        return this._places;
    }

    getPlaces(): PoiModelMap[] {
        return this._places;
    }

    async addToFavorite(id: string, category: FavoriteType, name: string, location: Location) {
        await this._store.favoritesStore.addToFavorite(id, category, name, location);
        this._logic.poi().setFavoriteOnPoi(id, category, true);
    }

    async deleteFavorite(id: string, category: FavoriteType) {
        await this._store.favoritesStore.deleteFavorite(id, category);
        this._logic.poi().setFavoriteOnPoi(id, category, false);
        this._logic.userEvents().deleteFavourite(category);
    }

    getPoi(poiId: string) {
        return this._fuelStationsByIndex?.[poiId] || this._parkingsByIndex?.[poiId] || this._placesByIndex?.[poiId];
    }

    async getFuelPrices(boundingBox: string) {
        const fuelStations = await this._logic?.apiService().fuel().pricesInArea(boundingBox);

        if (this._logic.conf.env === Env.PROD) {
            fuelStations.forEach(fuelStation => {
                // filter fuel prices older than 7 days
                fuelStation.fuelPrices = fuelStation.fuelPrices.filter(price =>
                    moment(price.created).isAfter(moment().subtract(7, 'days'))
                );
            });
        }

        return fuelStations;
    }
}

export function toFuelType(price: FuelStationPriceList) {
    return {
        code: price.productId!,
        name: FuelTypes[price.productId!],
        price: {
            price: price.price || '',
            source: price.source || '',
            currency: price.currency || '',
            type: price.type || '',
            productId: price.productId || '',
            sumExtraFees: price.sumExtraFees || '',
            created: price.date || ''
        }
    };
}
