/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ClientBLEConfig
 */
export interface ClientBLEConfig {
    /**
     * 
     * @type {string}
     * @memberof ClientBLEConfig
     */
    groupKEY: string;
    /**
     * 
     * @type {string}
     * @memberof ClientBLEConfig
     */
    groupComKEY: string;
    /**
     * 
     * @type {string}
     * @memberof ClientBLEConfig
     */
    realmID: string;
    /**
     * 
     * @type {string}
     * @memberof ClientBLEConfig
     */
    muCommand: string;
}

export function ClientBLEConfigFromJSON(json: any): ClientBLEConfig {
    return ClientBLEConfigFromJSONTyped(json, false);
}

export function ClientBLEConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClientBLEConfig {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'groupKEY': json['groupKEY'],
        'groupComKEY': json['groupComKEY'],
        'realmID': json['realmID'],
        'muCommand': json['muCommand'],
    };
}

export function ClientBLEConfigToJSON(value?: ClientBLEConfig | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'groupKEY': value.groupKEY,
        'groupComKEY': value.groupComKEY,
        'realmID': value.realmID,
        'muCommand': value.muCommand,
    };
}


