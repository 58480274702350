/* tslint:disable */
/* eslint-disable */
/**
 * Telematics API
 * Telematics services API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TagAssignment
 */
export interface TagAssignment {
    /**
     * 
     * @type {string}
     * @memberof TagAssignment
     */
    name: string;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof TagAssignment
     */
    settings: { [key: string]: object; };
    /**
     * 
     * @type {number}
     * @memberof TagAssignment
     */
    clientId: number;
    /**
     * 
     * @type {number}
     * @memberof TagAssignment
     */
    tagCategoryId: number;
    /**
     * 
     * @type {number}
     * @memberof TagAssignment
     */
    assetTypeId: number;
    /**
     * 
     * @type {number}
     * @memberof TagAssignment
     */
    assetId: number;
    /**
     * 
     * @type {number}
     * @memberof TagAssignment
     */
    tagId: number;
}

export function TagAssignmentFromJSON(json: any): TagAssignment {
    return TagAssignmentFromJSONTyped(json, false);
}

export function TagAssignmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): TagAssignment {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'settings': json['settings'],
        'clientId': json['client_id'],
        'tagCategoryId': json['tag_category_id'],
        'assetTypeId': json['asset_type_id'],
        'assetId': json['asset_id'],
        'tagId': json['tag_id'],
    };
}

export function TagAssignmentToJSON(value?: TagAssignment | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'settings': value.settings,
        'client_id': value.clientId,
        'tag_category_id': value.tagCategoryId,
        'asset_type_id': value.assetTypeId,
        'asset_id': value.assetId,
        'tag_id': value.tagId,
    };
}


