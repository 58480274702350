import { Conf } from 'conf';
import { LatLng } from '../../../common/model/geo';
import { HttpClient } from '../common/HttpClient';
import { FuelStationBrandModel } from './PlacesApi';

const FAVORITES_API_VERSION = '7';

export enum FavoriteType {
    PARKING_LOT = '231',
    GAS_STATION = '234',
    COMPANY = '230',
    SHOP = '232',
    FOOD_AND_DRINK = '233'
}

interface FavoriteBase {
    id: string;
    content: string;
    type: FavoriteType;
    version: string;
}

export interface Favorite extends FavoriteBase {
    address?: string;
    rating?: number;
    center?: LatLng;
    name?: string;
    brand?: FuelStationBrandModel;
}

class FavoritesApi {
    constructor(private _conf: Conf['api'], private _httpClient: HttpClient) {}

    async getFavorites() {
        return this._httpClient
            .get<{ favorites: FavoriteBase[] }>(`${this._conf.coreUrl}/favorite`, {
                apiVersion: FAVORITES_API_VERSION
            })
            .then(data => data.favorites);
    }

    async getFavoritesWithAddress(): Promise<Favorite[]> {
        return this._httpClient
            .get<{ favorites: Favorite[] }>(`${this._conf.middlewareUrl}/favorites`, {
                apiVersion: FAVORITES_API_VERSION
            })
            .then(data => data.favorites);
    }

    async updateFavorite(data: FavoriteBase) {
        return this._httpClient.put(
            `${this._conf.coreUrl}/favorite`,
            {
                favorites: [data]
            },
            {
                apiVersion: FAVORITES_API_VERSION
            }
        );
    }

    async deleteFavorite(id: string, type: FavoriteType) {
        return this._httpClient.delete(
            `${this._conf.coreUrl}/favorite`,
            { id, type },
            {
                apiVersion: FAVORITES_API_VERSION
            }
        );
    }
}

export { FavoritesApi };
