import { Row, Col } from 'antd';
import cn from 'classnames';
import { CountryTollCost, TollCosts } from 'services/api/domains/TollsApi';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Checkbox, Spin } from 'components/base/controls';
import { Avoids, AvoidsTypes, TransportModel } from '../../PlannerModule';
import { useWindowDimensions } from 'hooks/useWindowDimensions';

const TollTypeIcon = {
    3: 'bridge',
    4: 'tunnel',
    5: 'ferry'
};

interface Props {
    loading?: boolean;
    transport?: TransportModel;
    avoids?: Avoids;
    tollCosts?: TollCosts;
    countries: { [code: string]: { name: string; code: string } };
    tollCostsDetailVisible?: boolean;
    onAvoidsChange?: (countryCode: string, avoidType: string, value: boolean) => void;
    onClose?: () => void;
}

export default function PlannerRouteAvoids(props: Props) {
    const { t } = useTranslation();
    const [possibleAvoids] = useState(props.transport?.possibleAvoids || {});
    const [itemsState, setItemState] = useState(_getUpdateItemsState());
    const _avoidTypes = [AvoidsTypes.HIGHWAYS, AvoidsTypes.FERRIES, AvoidsTypes.UNPAVED];
    const { isMobileWidth } = useWindowDimensions();

    const getCountry = (countryCode: string) =>
        props.tollCosts?.countries.filter(
            tollCost => tollCost.countryCode.toLowerCase() === props.countries[countryCode.toLowerCase()].code
        ) || [];

    const _onClose = () => {
        props.onClose?.();
    };

    const _onExpand = (countryCode: string) => {
        setItemState({
            ...itemsState,
            [countryCode]: {
                expanded: !itemsState[countryCode]?.expanded,
                showMore: itemsState[countryCode]?.showMore
            }
        });
    };

    const _onShowMore = (countryCode: string) => {
        setItemState({
            ...itemsState,
            [countryCode]: {
                expanded: itemsState[countryCode]?.expanded,
                showMore: !itemsState[countryCode]?.showMore
            }
        });
    };

    async function _onAvoidsChange(countryCode: string, avoidType: string, value: boolean) {
        props.onAvoidsChange?.(countryCode, avoidType, value);
    }

    function _isCountryStartOrDestination(countryCode: string) {
        return props.transport?.places.some(
            place => place.addressStructured[0].countryCode.toUpperCase() === countryCode.toUpperCase()
        );
    }

    function _isAvoidDisabled(countryCode: string, avoidType: string): boolean {
        return (
            !possibleAvoids[countryCode].includes(avoidType) && props.avoids?.[countryCode]?.[avoidType] === undefined
        );
    }

    function _convertToKm(meters: number) {
        return (meters / 1000).toFixed(1) + ' km';
    }

    function _getUpdateItemsState() {
        const itemsState = {};
        Object.keys(props.transport?.possibleAvoids || {}).forEach(countryCode => {
            itemsState[countryCode] = { expanded: true, showMore: true };
        });

        return itemsState;
    }

    function _renderAvoidsSegmentsSection(countryCode: string, tollCost: CountryTollCost) {
        const total =
            tollCost.summary.cost.amount > 0
                ? `${tollCost.summary.cost.amount.toFixed(2)} ${tollCost.summary.cost.currency}`
                : '-';
        const distance = tollCost.summary.distance > 0 ? _convertToKm(tollCost.summary.distance) : '-';

        return (
            <div key={`country-${countryCode}`}>
                <div className="rl-planner-route-avoids-segments-country">
                    {isMobileWidth ? (
                        <Row>
                            <Col xs={1} />
                            <Col xs={7}>{t('TollCostsDetail.segment')}</Col>
                            <Col xs={4} style={{ textAlign: 'right' }}>
                                {t('TollCostsDetail.total')}
                            </Col>
                            <Col xs={12} style={{ textAlign: 'right' }}>
                                {total + ' · ' + distance}
                            </Col>
                        </Row>
                    ) : (
                        <Row>
                            <Col xs={1} />
                            <Col xs={15}>{t('TollCostsDetail.total')}</Col>
                            <Col xs={4}>{total}</Col>
                            <Col xs={4}>{distance}</Col>
                        </Row>
                    )}
                </div>
                {tollCost?.sections
                    .filter((_, index) => (itemsState[countryCode].showMore ? index < 3 : true))
                    .map((section, index) => {
                        const amount =
                            section.cost.amount > 0
                                ? `${section.cost.amount.toFixed(2)} ${section.cost.currency}`
                                : '-';
                        const distance = section.distance > 0 ? _convertToKm(section.distance) : '-';

                        return (
                            <div className="rl-planner-route-avoids-segments-section" key={`section-${index}`}>
                                <Row>
                                    <Col xs={1}>
                                        {TollTypeIcon[section.tollRoadType] ? (
                                            <img
                                                className="icon"
                                                src={`/img/toll-costs/${TollTypeIcon[section.tollRoadType]}.svg`}
                                                alt={TollTypeIcon[section.tollRoadType]}
                                            />
                                        ) : null}
                                    </Col>
                                    <Col xs={isMobileWidth ? 22 : 15}>{section.displayName}</Col>
                                    {!isMobileWidth && (
                                        <>
                                            <Col xs={4}>{amount}</Col>
                                            <Col xs={4}>{distance}</Col>
                                        </>
                                    )}
                                </Row>

                                {isMobileWidth && (
                                    <Row className="rl-planner-route-avoids-segments-section-amount-distance">
                                        <Col xs={1} />
                                        <Col xs={23}> {amount + ' · ' + distance}</Col>
                                    </Row>
                                )}
                            </div>
                        );
                    })}
                {tollCost?.sections.length > 3 && (
                    <div className="rl-planner-route-avoids-segments-showmore">
                        <Row>
                            <Col xs={1} />
                            <Col onClick={() => _onShowMore(countryCode)}>
                                {itemsState[countryCode].showMore
                                    ? t('TollCostsDetail.showMore')
                                    : t('TollCostsDetail.showLess')}
                            </Col>
                        </Row>
                    </div>
                )}
            </div>
        );
    }

    return (
        <div className="rl-planner-route-avoids" style={{ overflowY: props.loading ? 'hidden' : 'auto' }}>
            <div className="rl-planner-route-avoids-header">
                <h4>{t('RouteOptionsAndCost.avoids.title')}</h4>
                <Button type="action" icon={<i className="rl-icon-chevron-left" />} onClick={_onClose} />
            </div>
            <Spin loading={!!props.loading} tip={t('common.loading')}>
                <div className="rl-planner-route-avoids-content">
                    {Object.keys(possibleAvoids).map(countryCode => {
                        return (
                            <div key={countryCode} className="rl-planner-route-avoids-content-item">
                                <div className="rl-planner-route-avoids-content-item-header">
                                    <Row align="middle">
                                        <Col>
                                            <span
                                                className={cn(
                                                    `fi fis fi-${props.countries[countryCode.toLowerCase()].code}`
                                                )}
                                            />
                                        </Col>
                                        <Col>
                                            <span>{props.countries[countryCode.toLowerCase()].name}</span>
                                        </Col>
                                        <Col flex="auto">
                                            {!_isCountryStartOrDestination(countryCode) &&
                                                (props.avoids?.[countryCode]?.country ? (
                                                    <Button
                                                        type="link"
                                                        onClick={() =>
                                                            _onAvoidsChange(countryCode, AvoidsTypes.COUNTRY, false)
                                                        }
                                                    >
                                                        {t('AvoidsConfigurator.includeCountry')}
                                                    </Button>
                                                ) : (
                                                    <Button
                                                        type="link"
                                                        onClick={() =>
                                                            _onAvoidsChange(countryCode, AvoidsTypes.COUNTRY, true)
                                                        }
                                                    >
                                                        {t('AvoidsConfigurator.avoidCountry')}
                                                    </Button>
                                                ))}
                                        </Col>
                                        <Col>
                                            {!!props.tollCostsDetailVisible && getCountry(countryCode).length > 0 && (
                                                <i
                                                    onClick={() => _onExpand(countryCode)}
                                                    className={cn('fas', {
                                                        'fa-chevron-up': itemsState[countryCode]?.expanded,
                                                        'fa-chevron-down': !itemsState[countryCode]?.expanded
                                                    })}
                                                />
                                            )}
                                        </Col>
                                    </Row>
                                </div>
                                {!props.avoids?.[countryCode]?.country && (
                                    <div className="rl-planner-route-avoids-content-item-checkboxes">
                                        <Row align="middle">
                                            <Col>{t('AvoidsConfigurator.avoid')}</Col>
                                            <Col>
                                                {_avoidTypes.map(avoidType => {
                                                    return (
                                                        <Checkbox
                                                            key={avoidType}
                                                            checked={
                                                                props.avoids?.[countryCode]?.[avoidType] ||
                                                                (avoidType === AvoidsTypes.UNPAVED &&
                                                                    !_isAvoidDisabled(countryCode, avoidType))
                                                            }
                                                            onChange={e =>
                                                                _onAvoidsChange(
                                                                    countryCode,
                                                                    avoidType,
                                                                    e.target.checked
                                                                )
                                                            }
                                                            disabled={_isAvoidDisabled(countryCode, avoidType)}
                                                        >
                                                            {t('AvoidsConfigurator.' + avoidType)}
                                                        </Checkbox>
                                                    );
                                                })}
                                            </Col>
                                        </Row>
                                    </div>
                                )}
                                {itemsState[countryCode]?.expanded &&
                                    !!props.tollCostsDetailVisible &&
                                    props.tollCosts &&
                                    getCountry(countryCode).length > 0 && (
                                        <div className="rl-planner-route-avoids-segments">
                                            {!isMobileWidth && (
                                                <div className="rl-planner-route-avoids-segments-head">
                                                    <Row>
                                                        <Col xs={1} />
                                                        <Col xs={15}>{t('TollCostsDetail.segment')}</Col>
                                                        <Col xs={4}>{t('TollCostsDetail.toll')}</Col>
                                                        <Col xs={4}>{t('TollCostsDetail.distance')}</Col>
                                                    </Row>
                                                </div>
                                            )}

                                            {getCountry(countryCode).map(tollCost =>
                                                _renderAvoidsSegmentsSection(countryCode, tollCost)
                                            )}
                                        </div>
                                    )}
                            </div>
                        );
                    })}
                </div>
                {!!props.tollCostsDetailVisible && props.tollCosts && (
                    <div className="rl-planner-route-avoids-summary">
                        <Row align="middle">
                            <Col xs={16}>{t('TollCostsDetail.totalCosts')}</Col>
                            <Col xs={8}>{`${props.tollCosts?.totalCost} ${props.tollCosts?.currency}`}</Col>
                            {/* <Col xs={4}>{PlannerRouteAvoids._convertToKm(tollCosts?.totalDistance || 0)}</Col> */}
                        </Row>
                    </div>
                )}
            </Spin>
        </div>
    );
}
