import { MouseEvent } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { TableViewData } from '../../TableViewModule';
import cn from 'classnames';
import moment from 'moment';
import { Menu } from 'antd';
import { Button } from 'components/base/controls/Button';
import { Dropdown } from 'components/base/controls/Dropdown';
import { Spin } from 'components/base/controls';
import RouteNoVehicles from '../../../common/DispatcherBoardNoVehicles/DispatcherBoardNoVehicles';
import { CompanyVehicle } from 'services/api/domains/VehiclesApi';
import { TransportState } from 'services/api/domains/TransportsApi';

interface Props {
    data?: TableViewData;
    selectedId?: string;
    loading: boolean;
    processing: boolean;
    vehicles?: CompanyVehicle[];
    onTransportEditClick?: (transportId: string, vehicleId: string) => void;
    onTransportDetailClick?: (transportId: string) => void;
    onClickRowExpand?: (key: keyof TableViewData) => void;
    onAssignVehicleClick?: (transportId: string) => void;
    onSetStateClick?: (transportId: string, state: TransportState) => void;
    onRemoveTransportClick?: (transportId: string, state: TransportState) => void;
    onTrackOnMapClick?: (vehicleRN: string) => void;
    onAddVehicleClick?: () => void;
}

const orderSection: Array<keyof TableViewData> = [
    'routesNew',
    'routesAssigned',
    'routesActive',
    'routesDelayed',
    'routesFinished'
];

const backgrounds = {
    routesNew: 't-transport-new',
    routesAssigned: 't-transport-assigned',
    routesDelayed: 't-transport-delayed',
    routesActive: 't-transport-active',
    routesFinished: 't-transport-finished'
};

const TableViewTable = ({ data, loading, processing, vehicles, ...props }: Props) => {
    const { t } = useTranslation();

    const head: [[string, number][]] = [
        [
            // [Name, Colspan]
            [t('common.route'), 1],
            [t('common.start'), 1],
            [t('common.finish'), 1],
            [t('common.status'), 1],
            [t('RouteOverviewTable.vehicleRN'), 1],
            [t('common.driver'), 1],
            [t('shorthand.ETA'), 1]
        ]
    ];

    const rowColSpan = head[0].map(col => col[1]).reduce((total, actual) => total + actual);

    const onClickRowExpand = (key: keyof TableViewData) => {
        props.onClickRowExpand?.(key);
    };

    const onAddVehicle = () => {
        return (e: MouseEvent<HTMLButtonElement>) => {
            e.stopPropagation();
            props.onAddVehicleClick?.();
        };
    };

    const onAssignVehicle = (id: string) => {
        return (e: MouseEvent<HTMLButtonElement>) => {
            e.stopPropagation();
            props.onAssignVehicleClick?.(id);
        };
    };

    const onSetStatusClick = (id: string, state: TransportState) => {
        return (e: MouseEvent<HTMLDivElement>) => {
            e.stopPropagation();
            props.onSetStateClick?.(id, state);
        };
    };

    const onRemoveTransportClick = (id: string, state: TransportState) => {
        return (e: MouseEvent<HTMLDivElement>) => {
            e.stopPropagation();
            props.onRemoveTransportClick?.(id, state);
        };
    };

    const onTrackOnMapClick = (vehicleId: string) => {
        return (e: MouseEvent<HTMLDivElement>) => {
            e.stopPropagation();
            props.onTrackOnMapClick?.(vehicleId);
        };
    };

    return (
        <div className="rl-table-view-table">
            <Spin loading={loading || processing} tip={t('common.loading')} opacity={false}>
                <table>
                    <thead>
                        <tr>
                            {head[0].map((col, i) => (
                                <th key={i} colSpan={col[1]} title={col[0]}>
                                    {col[0]}
                                </th>
                            ))}
                            <th />
                        </tr>
                    </thead>
                    {data ? (
                        orderSection.map(key => {
                            const routeOverview = data[key];
                            return (
                                <tbody key={key}>
                                    <tr key="colapsable" onClick={onClickRowExpand.bind(undefined, key)}>
                                        <td className="t-row-section" colSpan={rowColSpan}>
                                            {t(`RouteOverviewTable.${key}`)}
                                            <i
                                                className={`rl-icon-chevron-${routeOverview ? 'down' : 'right'}`}
                                                aria-hidden="true"
                                            />
                                        </td>
                                    </tr>
                                    {routeOverview?.length ? (
                                        routeOverview.map(row => (
                                            <tr
                                                key={row.id}
                                                className={cn(backgrounds[key], {
                                                    pointer: row.state !== TransportState.Finished
                                                })}
                                                onClick={() => props.onTransportDetailClick?.(row.id)}
                                                onDoubleClick={() =>
                                                    props.onTransportEditClick?.(
                                                        row.id,
                                                        row.vehicleId?.toString() || row.vehicleRN
                                                    )
                                                }
                                            >
                                                <td className="rl-table-view-table-name-col">
                                                    <div className="rl-table-view-table-name-col-name" title={row.name}>
                                                        {row.vehicleRN && (
                                                            <div className="rl-table-view-table-name-col-vehicle">
                                                                <div className="rl-table-view-table-name-col-vehicle-rn">
                                                                    {row.vehicleRN}
                                                                </div>
                                                                <div className="rl-table-view-table-name-col-vehicle-driver">
                                                                    (
                                                                    {row.driver === '-'
                                                                        ? t('RouteOverviewTable.noDriver')
                                                                        : row.driver}
                                                                    )
                                                                </div>
                                                            </div>
                                                        )}

                                                        {row.name}
                                                    </div>

                                                    <Button
                                                        type="action"
                                                        icon={<i className="rl-icon-chevron-right" />}
                                                        onClick={() => {
                                                            props.onTransportDetailClick?.(row.id);
                                                        }}
                                                    />
                                                </td>
                                                <td>
                                                    <b>
                                                        {row.dateAndTimeStart
                                                            ? moment(row.dateAndTimeStart).format('lll')
                                                            : '-'}
                                                    </b>
                                                    <br />
                                                    {row.firstPointOnTheRoute}
                                                </td>
                                                <td>
                                                    <b>
                                                        {row.dateAndTimeFinish
                                                            ? moment(row.dateAndTimeFinish).format('lll')
                                                            : '-'}
                                                    </b>
                                                    <br />
                                                    {row.lastPointOnTheRoute}
                                                </td>
                                                <td className="rl-table-view-table-status">
                                                    {t(`RouteOverviewTable.${row.state}`)}
                                                </td>
                                                <td>
                                                    {!vehicles?.length && (
                                                        <Button type="primary" size="small" onClick={onAddVehicle()}>
                                                            {t('RouteOverviewTable.addVehicle')}
                                                        </Button>
                                                    )}
                                                    {vehicles &&
                                                        vehicles.length > 0 &&
                                                        (row.vehicleRN ? (
                                                            <>
                                                                {key !== 'routesFinished' ? (
                                                                    <Button
                                                                        type="primary"
                                                                        size="small"
                                                                        onClick={onAssignVehicle(row.id)}
                                                                        disabled={row.state === TransportState.Active}
                                                                    >
                                                                        {row.vehicleRN}
                                                                    </Button>
                                                                ) : (
                                                                    row.vehicleRN || 'X'
                                                                )}
                                                            </>
                                                        ) : key === 'routesNew' ? (
                                                            <Button
                                                                type="primary"
                                                                size="small"
                                                                onClick={onAssignVehicle(row.id)}
                                                            >
                                                                {t('RouteOverviewTable.assignVehicle')}
                                                            </Button>
                                                        ) : (
                                                            'Y'
                                                        ))}
                                                </td>
                                                <td>
                                                    <div title={row.driver}>{row.driver}</div>
                                                </td>
                                                <td>{row.ETA ? moment(row.ETA).format('lll') : '-'}</td>
                                                <td>
                                                    <Dropdown
                                                        overlay={
                                                            <Menu>
                                                                {['routesActive'].includes(key) && row.vehicleRN && (
                                                                    <Menu.Item key={key}>
                                                                        <div onClick={onTrackOnMapClick(row.vehicleRN)}>
                                                                            {t('RouteOverviewTable.trackVehicleOnMap')}
                                                                        </div>
                                                                    </Menu.Item>
                                                                )}
                                                                {['routesFinished'].includes(key) && (
                                                                    <>
                                                                        {row.driver && row.vehicleId ? (
                                                                            <Menu.Item key={key}>
                                                                                <div
                                                                                    onClick={onSetStatusClick(
                                                                                        row.id,
                                                                                        TransportState.Assigned
                                                                                    )}
                                                                                >
                                                                                    {t(
                                                                                        'RouteOverviewTable.setAsAssigned'
                                                                                    )}
                                                                                </div>
                                                                            </Menu.Item>
                                                                        ) : (
                                                                            <Menu.Item key={key}>
                                                                                <div
                                                                                    onClick={onSetStatusClick(
                                                                                        row.id,
                                                                                        TransportState.New
                                                                                    )}
                                                                                >
                                                                                    {t('RouteOverviewTable.setAsNew')}
                                                                                </div>
                                                                            </Menu.Item>
                                                                        )}
                                                                    </>
                                                                )}
                                                                {[
                                                                    'routesNew',
                                                                    'routesDelayed',
                                                                    'routesActive'
                                                                ].includes(key) && (
                                                                    <Menu.Item key={key}>
                                                                        <div
                                                                            onClick={onSetStatusClick(
                                                                                row.id,
                                                                                TransportState.Finished
                                                                            )}
                                                                        >
                                                                            {t('RouteOverviewTable.setAsComplete')}
                                                                        </div>
                                                                    </Menu.Item>
                                                                )}
                                                                <Menu.Item
                                                                    key="remove"
                                                                    disabled={row.state === TransportState.Active}
                                                                >
                                                                    <div
                                                                        onClick={onRemoveTransportClick(
                                                                            row.id,
                                                                            row.state
                                                                        )}
                                                                    >
                                                                        {t('RouteOverviewTable.remove')}
                                                                    </div>
                                                                </Menu.Item>
                                                                <Menu.Item key="detail">
                                                                    <div
                                                                        onClick={() =>
                                                                            props.onTransportDetailClick?.(row.id)
                                                                        }
                                                                    >
                                                                        {t('common.detail')}
                                                                    </div>
                                                                </Menu.Item>
                                                            </Menu>
                                                        }
                                                        placement="bottomRight"
                                                    >
                                                        <Button type="icon" onClick={e => e.stopPropagation()}>
                                                            <i className="rl-icon-more" />
                                                        </Button>
                                                    </Dropdown>
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr key="no-data-available">
                                            {key.toLocaleLowerCase() === `routes${TransportState.Assigned}` ? (
                                                <td
                                                    className="rl-table-view-table-no-assigned-transports"
                                                    colSpan={rowColSpan + 1}
                                                >
                                                    <i className="fas fa-info-circle" />

                                                    <Trans i18nKey="RouteOverviewTable.routesAssignedNoData">
                                                        You have no assigned transports yet. To assign transport go to
                                                        calendar view and drag unnasigned transport to desired vehicle
                                                        or
                                                        <Button type="link" onClick={() => props.onAddVehicleClick?.()}>
                                                            add new vehicle
                                                        </Button>
                                                        .
                                                    </Trans>
                                                </td>
                                            ) : (
                                                <td colSpan={rowColSpan}>{t(`RouteOverviewTable.${key}NoData`)}</td>
                                            )}
                                        </tr>
                                    )}
                                </tbody>
                            );
                        })
                    ) : (
                        <tbody>
                            <tr>
                                <td className="t-center" colSpan={rowColSpan}>
                                    <RouteNoVehicles onShowCreateVehicleModal={() => props.onAddVehicleClick?.()} />
                                </td>
                            </tr>
                        </tbody>
                    )}
                </table>
            </Spin>
        </div>
    );
};

export default TableViewTable;
