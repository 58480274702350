import moment from 'moment';
import cn from 'classnames';
import RichEditor from 'components/RichEditor/RichEditor';
import { TransportModel } from 'logic/dispatcher-board';
import { getLastPlaceIso2Code } from 'modules/DispatcherBoardModule/common/utils/place';
import { Country } from 'services/api/domains/CountryApi';
import { TransportState } from 'services/api/domains/TransportsApi';
import { useTranslation } from 'react-i18next';
import { DATE_TIME_FORMAT_SHORT } from 'utils/constants/domain-constants';

interface Props {
    transport: TransportModel;
    countryList: Country[];
    date: string;
    heightPercentage: number;
    onClick: (transport: TransportModel) => void;
}

export const CalendarViewMobileTransport = ({ transport, countryList, heightPercentage, date, onClick }: Props) => {
    const transportDuration = moment(transport.lastPlaceRta).diff(transport.firstPlaceRta, 'seconds');
    const etaDuration = moment(transport.eta).diff(transport.firstPlaceRta, 'seconds');
    const etaLineHeightRatio = etaDuration / transportDuration;
    const { t } = useTranslation();

    const diffToStartOfDay =
        (moment(date).startOf('day').diff(moment(transport.firstPlaceRta), 'seconds') / (24 * 3600)) * 100;
    const topMarginStartLabel = diffToStartOfDay > 0 && (diffToStartOfDay / heightPercentage) * 100;

    const diffToEndOfDay =
        (moment(date).endOf('day').diff(moment(transport.firstPlaceRta), 'seconds') / (24 * 3600)) * 100;
    const topMarginEndLabel = (diffToEndOfDay / heightPercentage) * 100;

    const TransportInfo = () => (
        <div className="rl-calendar-view-mobile-transport-header">
            <div>
                <div className="rl-calendar-view-mobile-transport-header-distance">{getDistance(transport)}</div>
                <div className="rl-calendar-view-mobile-transport-header-name">{transport.name}</div>
            </div>
            <span className="rl-calendar-view-mobile-transport-header-flag">
                <i className={`fi fis fi-${getLastPlaceIso2Code(transport, countryList)}`} />
            </span>
        </div>
    );

    return (
        <div className="rl-calendar-view-mobile-transport" onClick={() => onClick(transport)}>
            {topMarginStartLabel && (
                <div className="rl-calendar-view-mobile-transport-label" style={{ top: `${topMarginStartLabel}%` }}>
                    <div className="rl-calendar-view-mobile-transport-label-start-day">
                        {`${t('CalendarViewMobileTransport.start')} ${moment(transport.firstPlaceRta).format(
                            DATE_TIME_FORMAT_SHORT
                        )}`}
                    </div>

                    <TransportInfo />
                </div>
            )}

            <TransportInfo />

            <div className="rl-calendar-view-mobile-transport-body">
                {transport.note && (
                    <RichEditor text={transport.note} readonly={true} onSave={() => {}} onCancel={() => {}} />
                )}
            </div>

            {transport.state === TransportState.Active && etaLineHeightRatio && etaLineHeightRatio > 0 && (
                <div
                    className={cn('rl-calendar-view-mobile-transport-eta-line', {
                        'on-time': etaLineHeightRatio <= 1,
                        delayed: etaLineHeightRatio > 1
                    })}
                    style={{ height: `${etaLineHeightRatio * 100}%` }}
                />
            )}

            <div
                className="rl-calendar-view-mobile-transport-label"
                style={{ top: `calc(${topMarginEndLabel}% - 40px)` }}
            >
                <div className="rl-calendar-view-mobile-transport-label-end-day">
                    {`${t('CalendarViewMobileTransport.finish')} ${moment(transport.lastPlaceRta).format(
                        DATE_TIME_FORMAT_SHORT
                    )}`}
                </div>
            </div>
        </div>
    );
};

const getDistance = (transport: TransportModel) =>
    `${transport.distance ? (transport.distance / 1000).toFixed(0) : 0} km`;
