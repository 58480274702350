interface coordinates {
    lat: number;
    lng: number;
}

export class MapLogicUtils {
    public static getDistanceInMeters(from: coordinates, to: coordinates) {
        const toRad = (deg: number) => {
            return deg * (Math.PI / 180);
        };

        const R = 6371;
        const dLat = toRad(to.lng - from.lng);
        const dLon = toRad(to.lat - from.lat);
        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(toRad(from.lng)) * Math.cos(toRad(to.lng)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);

        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

        return R * c * 1000;
    }
}
