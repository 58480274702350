import { Collapse } from 'antd';
import { Spin } from 'components/base/controls';
import { Modal } from 'components/base/layout';
import { ShareButtons } from 'components/ShareButtons';
import { ShareEtaRouteInfo } from 'modules/ShareEtaModule/ShareEtaModule';
import { useTranslation } from 'react-i18next';
import { HowShareEtaWorks } from '../HowShareEtaWorks';
import { ShareEtaInfo } from '../ShareEtaInfo';
import { ShareEtaLink } from '../ShareEtaLink';

interface Props {
    visible?: boolean;
    shareEtaLink: string;
    companyName: string;
    loading: boolean;
    shareInfo?: ShareEtaRouteInfo;
    onCloseShareEtaModal?: () => void;
}

const ShareEtaModal = (props: Props) => {
    const { t } = useTranslation();

    return (
        <Modal
            className="rl-share-eta-modal"
            visible={props.visible}
            onCancel={props.onCloseShareEtaModal}
            width={752}
            destroyOnClose={true}
        >
            <Spin loading={props.loading} tip={t('common.loading')} opacity={true}>
                <div className="rl-share-eta-modal-content">
                    <h3>{t('ShareEtaModal.title')}</h3>
                    <div className="rl-share-eta-modal-content-subtitle">{t('ShareEtaModal.subtitle')}</div>
                    <ShareEtaLink shareEtaLink={props.shareEtaLink} />
                    <ShareButtons
                        inviteLink={props.shareEtaLink}
                        mailMessage={t('common.shareEtaMailText', {
                            link: props.shareEtaLink,
                            companyName: props.companyName
                        })}
                        whatsappMessage={t('common.shareEtaMailText', {
                            link: props.shareEtaLink,
                            companyName: props.companyName
                        })}
                    />
                    <b className="rl-share-eta-modal-content-share-info">{t('ShareEtaModal.shareInfo')}</b>
                    <ShareEtaInfo
                        destination={props.shareInfo?.destination}
                        eta={props.shareInfo?.eta}
                        remainingDistance={props.shareInfo?.remainingDistance}
                    />

                    <Collapse
                        ghost
                        expandIcon={({ isActive }) =>
                            isActive ? <i className="rl-icon-chevron-up" /> : <i className="rl-icon-chevron-down" />
                        }
                    >
                        <Collapse.Panel header={t('HowShareEtaWork.title')} key="1">
                            <HowShareEtaWorks />
                        </Collapse.Panel>
                    </Collapse>
                </div>
            </Spin>
        </Modal>
    );
};

export default ShareEtaModal;
