import { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Logic } from 'logic/logic';
import { DriverWithVehicle } from 'modules/SettingsModule/modules/DriversModule/DriversModule';
import { Modal, Notification } from 'components/base/layout';
import { AlarmType } from 'generated/backend-api';
import { Subject, takeUntil } from 'rxjs';
import { ProfileRole } from 'logic/alarms';
import { CompanyTransportStatus } from 'services/api/domains/TransportsApi';
import { AssignDriver } from './ui/AssignDriver';

interface Props extends WithTranslation {
    logic: Logic;
    visible: boolean;
    vehicleId: number;
    onCancel?: () => void;
    afterAssign?: () => void;
    /** Overrides default assign behaviour */
    onAssignClick?: (driverId: number) => void;
}
interface State {
    loading?: boolean;
    table: {
        data?: DriverWithVehicle[];
        loading?: boolean;
    };
}

class AssignDriverModule extends Component<Props, State> {
    private _destroySignal$ = new Subject<void>();

    constructor(props: Props) {
        super(props);
        this.state = {
            table: {
                loading: false
            }
        };
    }

    componentDidMount() {
        if (this.props.visible) {
            this._init();
        }
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.visible !== prevProps.visible) {
            if (this.props.visible) {
                this._init();
            } else {
                this._destroySignal$.next();
            }
        }
    }

    componentWillUnmount() {
        this._destroySignal$.next();
    }

    render() {
        return (
            <Modal
                className="rl-assign-driver-modal"
                visible={this.props.visible}
                onCancel={this.props.onCancel}
                width={944}
                destroyOnClose={true}
            >
                <AssignDriver
                    loading={this.state.loading}
                    table={this.state.table}
                    onAssignClick={this.props.onAssignClick ? this.props.onAssignClick : this._assignDriver}
                    onCancel={this.props.onCancel}
                    onclickToInvite={() => {
                        this.props.logic.userEvents().inviteDriver();
                    }}
                />
            </Modal>
        );
    }

    private _init() {
        this._loadTableData();
        this._subscribeNotifications();
    }

    private async _loadTableData() {
        const companyId = this.props.logic.company().getCompany().companyId;
        const vehicles = this.props.logic.vehicles().getVehicles();
        const drivers = this.props.logic.apiService().company().getCompanyDrivers(companyId);

        this.setState(state => ({
            table: { loading: true, data: state.table.data }
        }));

        return Promise.all([drivers, vehicles])
            .then(([drivers, vehicles]) =>
                this.setState(() => {
                    const data = drivers.map(driver => ({
                        ...driver,
                        vehicle: vehicles.find(v => v.drivers?.[0]?.profileId === driver.profileId)
                    }));
                    return { table: { data } };
                })
            )
            .finally(() =>
                this.setState(state => ({
                    table: { loading: false, data: state.table.data }
                }))
            );
    }

    private _assignDriver = async (driverId: number) => {
        try {
            this.setState({ loading: true });
            const companyId = this.props.logic.company().getCompany().companyId;

            const assignedDriver = this.state.table.data?.find(d => d.vehicle?.vehicleId === this.props.vehicleId);
            if (assignedDriver) {
                await this.props.logic
                    .apiService()
                    .vehicles()
                    .unassignDriver(Number(assignedDriver.profileId), companyId, this.props.vehicleId);
            }

            await this.props.logic
                .apiService()
                .vehicles()
                .assignDriver(Number(driverId), companyId, this.props.vehicleId);

            this.props.logic.userEvents().assignDriver(+driverId, this.props.vehicleId);

            const transportsToAssignDriver = await this.props.logic
                .apiService()
                .transports()
                .getTransports(companyId, {
                    statuses: []
                })
                .then(transports =>
                    transports.filter(
                        transport =>
                            transport.status !== CompanyTransportStatus.Completed &&
                            transport.status !== CompanyTransportStatus.Active &&
                            transport.vehicle?.vehicleId === this.props.vehicleId
                    )
                );

            // assign driver to all transports with selected vehicle
            await Promise.all(
                transportsToAssignDriver.map(transport =>
                    this.props.logic.apiService().transports().patchTransport(companyId, transport.transportId!, {
                        driverId
                    })
                )
            );

            Notification.success({
                message: this.props.t('AssignDriverModal.driverAssigned')
            });
            this._loadTableData();
            this.props.afterAssign?.();
        } catch (err) {
            console.error(err);
            Notification.error({
                message: err.message
            });
        } finally {
            this.setState({ loading: false });
        }
    };

    private _subscribeNotifications() {
        this.props.logic
            .alarms()
            .alarmsUpdates$.pipe(takeUntil(this._destroySignal$))
            .subscribe(alarms => {
                const driverJoined = alarms.some(
                    a => a.alarmType === AlarmType.Joined && a.uniqueData?.['profileRole'] === ProfileRole.Driver
                );

                if (driverJoined) {
                    this._loadTableData();
                }
            });
    }
}

export default withTranslation()(AssignDriverModule);
