import { NavLink, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { RouteNames } from 'router/routes';
import { Button, Dropdown } from '../../controls';
import selectors from 'qa-selectors';
import { useEffect, useState } from 'react';
import { Col, Menu, Row } from 'antd';
import { Language } from 'utils/constants/constants';
import { LanguageSelect } from './ui/LanguageSelect';
import { CurrencySelect } from './ui/CurrencySelect';
import { BurgerButton } from './ui/BurgerButton';
import { NavBarItem } from './ui/NavBarItem';

interface Props {
    language?: string;
    languages: Language[];
    currency?: string;
    currencies?: string[];
    isLoggedIn?: boolean;
    newAlarms?: number;
    companyName?: string;
    userName?: string;
    appVersion?: string;
    onLogoutClick?: () => void;
    onAlarmClick?: () => void;
    onLanguageChange?: (lang: string) => void;
    onCurrencyChange?: (currency: string) => void;
}

const ROUTE_PATH_LANG_KEY = {
    [RouteNames.SCHEDULING_PLANNER]: 'NavBar.planner',
    [RouteNames.SCHEDULING_DISPATCHER_BOARD_CALENDAR]: 'NavBar.dispatcherBoard',
    [RouteNames.TRACKING]: 'NavBar.tracking',
    [RouteNames.TRACKING_MAP]: 'NavBar.tracking',
    [RouteNames.TRACKING_TABLE]: 'NavBar.tracking',
    [RouteNames.SETTINGS]: 'common.settings',
    [RouteNames.SETTINGS_FLEET]: 'common.settings',
    [RouteNames.SETTINGS_DRIVERS]: 'common.settings',
    [RouteNames.SETTINGS_DISPATCHERS]: 'common.settings',
    [RouteNames.SETTINGS_FUEL_CARDS]: 'common.settings',
    [RouteNames.SETTINGS_FAVORITES]: 'common.settings',
    [RouteNames.SETTINGS_COMPANY]: 'common.settings',
    [RouteNames.SETTINGS_PREFERENCES]: 'common.settings'
};

function NavBar(props: Props) {
    const { t } = useTranslation();
    const history = useHistory();
    const [menuOpened, setMenuOpened] = useState(false);

    const onLoginClick = () => {
        setMenuOpened(false);
        const searchParams = new URLSearchParams(history.location.search);
        searchParams.delete('register');
        searchParams.delete('login');
        searchParams.append('login', 'true');
        history.push({ search: searchParams.toString() });
    };

    const onLogoutClick = () => {
        setMenuOpened(false);
        props.onLogoutClick?.();
    };

    const onRegisterClick = () => {
        setMenuOpened(false);
        const searchParams = new URLSearchParams(history.location.search);
        searchParams.delete('register');
        searchParams.delete('login');
        searchParams.append('register', 'true');
        history.push({ search: searchParams.toString() });
    };

    const onConnectDriversClick = () => {
        goTo(RouteNames.SETTINGS_DRIVERS);
    };

    const goTo = (route: RouteNames) => {
        const searchParams = new URLSearchParams(history.location.search);
        searchParams.append('login', 'true');
        history.push(
            !props.isLoggedIn
                ? {
                      search: searchParams.toString()
                  }
                : { pathname: route }
        );
    };

    useEffect(() => {
        const languageSelectionItem = document.querySelectorAll(
            '.navbar-language-select .ant-select-selection-item'
        )?.[0] as HTMLElement;

        if (languageSelectionItem) {
            languageSelectionItem.title = t('common.language');
        }

        const currencySelectionItem = document.querySelectorAll(
            '.navbar-currency-select .ant-select-selection-item'
        )?.[0] as HTMLElement;

        if (currencySelectionItem) {
            currencySelectionItem.title = t('common.currency');
        }
    });

    return (
        <>
            <nav className="rl-navbar">
                <NavLink className="rl-navbar-logo" to={RouteNames.SCHEDULING_PLANNER}>
                    <img src="/img/rl-dispatcher-logo.svg" alt="RoadLords Dispatcher" />
                </NavLink>

                <NavBarItem
                    label={t('NavBar.planner')}
                    linkTo={RouteNames.SCHEDULING_PLANNER}
                    qa={selectors.navbar.routePlannerLink}
                    id="navbar-link-route-planner"
                />

                <NavBarItem
                    label={t('NavBar.dispatcherBoard')}
                    linkTo={RouteNames.SCHEDULING_DISPATCHER_BOARD_CALENDAR}
                    qa={selectors.navbar.dispatcherBoardLink}
                    id="navbar-link-dispatcher-board"
                />

                <NavBarItem
                    label={t('NavBar.tracking')}
                    linkTo={RouteNames.TRACKING}
                    qa={selectors.navbar.liveTrackingLink}
                    id="navbar-link-live-tracking"
                />

                <div className="right-items">
                    <Button id="connect-drivers-button" type="secondary" outline={true} onClick={onConnectDriversClick}>
                        {t('NavBar.getApp')}
                    </Button>

                    {props.isLoggedIn ? (
                        <>
                            <NavBarItem
                                iconClass="rl-icon-settings"
                                label={t('common.settings')}
                                linkTo={RouteNames.SETTINGS}
                                qa={selectors.navbar.settingsLink}
                            />
                            <span className="separator" />
                            <NavBarItem
                                id="notifications-button"
                                iconClass="rl-icon-notification"
                                notificationsCount={props.newAlarms}
                                title={t('NavBar.notifications')}
                                onClick={props.onAlarmClick}
                            />

                            <Dropdown
                                overlayClassName="rl-navbar-actions"
                                trigger={['click']}
                                align={{ offset: [21, -2] }}
                                overlay={
                                    <Menu className="rl-navbar-actions-menu">
                                        <Menu.Item
                                            key={0}
                                            onClick={() => goTo(RouteNames.SETTINGS_COMPANY)}
                                            className="rl-navbar-actions-menu-company"
                                        >
                                            {props.companyName}
                                        </Menu.Item>
                                        <Menu.Item
                                            key={1}
                                            className="rl-navbar-actions-menu-user"
                                            onClick={() => goTo(RouteNames.SETTINGS_DISPATCHERS)}
                                        >
                                            {props.userName}
                                        </Menu.Item>
                                        <Menu.Divider />
                                        <Menu.Item
                                            key={2}
                                            onClick={() => window.open('https://roadlords.com/faq', '_blank')?.focus()}
                                        >
                                            {t('NavBar.actionMenu.faq')}
                                        </Menu.Item>
                                        <Menu.Item
                                            key={3}
                                            onClick={() =>
                                                window.open('https://youtu.be/9aLkIDq2zFY', '_blank')?.focus()
                                            }
                                        >
                                            {t('NavBar.actionMenu.help')}
                                        </Menu.Item>
                                        <Menu.Item
                                            key={4}
                                            className="rl-navbar-actions-menu-news"
                                            onClick={() =>
                                                window.open('https://roadlords.com/en/dispatcher', '_blank')?.focus()
                                            }
                                        >
                                            <Row justify="space-between">
                                                <Col>{t('NavBar.actionMenu.news')}</Col>
                                                <Col>
                                                    {props.appVersion && (
                                                        <div className="rl-navbar-actions-menu-version">
                                                            {props.appVersion}
                                                        </div>
                                                    )}
                                                </Col>
                                            </Row>
                                        </Menu.Item>
                                        <Menu.Divider />
                                        <Menu.Item
                                            key={5}
                                            onClick={() => onLogoutClick()}
                                            className="rl-navbar-actions-menu-signout"
                                        >
                                            {t('NavBar.actionMenu.signout')}
                                        </Menu.Item>
                                    </Menu>
                                }
                                placement="bottomRight"
                                arrow
                            >
                                <NavBarItem iconClass="rl-icon-more-circle" />
                            </Dropdown>
                        </>
                    ) : (
                        <>
                            <Button onClick={onLoginClick}>{t('common.signIn')}</Button>
                            <Button type="primary" onClick={onRegisterClick}>
                                {t('NavBar.register')}
                            </Button>

                            <span className="separator" />

                            <LanguageSelect
                                languages={props.languages}
                                language={props.language}
                                onLanguageChange={props.onLanguageChange}
                            />
                            <CurrencySelect
                                currencies={props.currencies}
                                currency={props.currency}
                                onCurrencyChange={props.onCurrencyChange}
                            />
                        </>
                    )}
                </div>
            </nav>

            <nav className="rl-navbar-m">
                <NavLink
                    className="rl-navbar-m-logo"
                    to={RouteNames.SCHEDULING_PLANNER}
                    onClick={() => setMenuOpened(false)}
                >
                    <img src="/img/rl-icon.svg" alt="RoadLords Dispatcher" />
                </NavLink>

                <div className="rl-navbar-m-title">{t(ROUTE_PATH_LANG_KEY[history.location.pathname])}</div>

                <BurgerButton menuOpened={menuOpened} onClick={() => setMenuOpened(!menuOpened)} />
            </nav>

            {menuOpened && (
                <nav className="rl-navbar-m-burger-menu">
                    <div className="rl-navbar-m-burger-menu-content">
                        <div className="rl-navbar-m-burger-button-close">
                            <BurgerButton menuOpened={menuOpened} onClick={() => setMenuOpened(!menuOpened)} />
                        </div>
                        <NavLink
                            className="rl-navbar-m-burger-menu-item primary"
                            to={RouteNames.SCHEDULING_PLANNER}
                            onClick={() => setMenuOpened(false)}
                            title={t('NavBar.planner')}
                            data-qa={selectors.navbar.routePlannerLink}
                            exact={true}
                        >
                            {t('NavBar.planner')}
                        </NavLink>
                        <NavLink
                            className="rl-navbar-m-burger-menu-item primary"
                            to={RouteNames.SCHEDULING_DISPATCHER_BOARD_CALENDAR}
                            onClick={() => setMenuOpened(false)}
                            title={t('NavBar.dispatcherBoard')}
                            data-qa={selectors.navbar.dispatcherBoardLink}
                            exact={true}
                        >
                            {t('NavBar.dispatcherBoard')}
                        </NavLink>
                        <NavLink
                            className="rl-navbar-m-burger-menu-item primary"
                            to={RouteNames.TRACKING}
                            onClick={() => setMenuOpened(false)}
                            title={t('NavBar.tracking')}
                            data-qa={selectors.navbar.liveTrackingLink}
                            exact={true}
                        >
                            {t('NavBar.tracking')}
                        </NavLink>
                        {props.isLoggedIn && (
                            <NavLink
                                className="rl-navbar-m-burger-menu-item primary"
                                to={RouteNames.SETTINGS}
                                onClick={() => setMenuOpened(false)}
                                title={t('common.settings')}
                                data-qa={selectors.navbar.settingsLink}
                                exact={true}
                            >
                                {t('common.settings')}
                            </NavLink>
                        )}
                        <NavLink
                            className="rl-navbar-m-burger-menu-item secondary"
                            title={t('NavBar.getApp')}
                            exact={true}
                            to={RouteNames.SETTINGS_DRIVERS}
                            onClick={() => setMenuOpened(false)}
                        >
                            {t('NavBar.getApp')}
                        </NavLink>
                    </div>
                    <div className="rl-navbar-m-burger-menu-footer">
                        {!props.isLoggedIn ? (
                            <>
                                <Button onClick={onLoginClick}>{t('common.signIn')}</Button>
                                <Button type="primary" onClick={onRegisterClick}>
                                    {t('NavBar.register')}
                                </Button>
                                <hr className="solid" />
                                <div className="rl-navbar-m-burger-menu-selectors">
                                    <div className="rl-navbar-m-burger-menu-selectors-title">
                                        <div className="column">{t('common.language')}</div>
                                        <div className="column">{t('common.currency')}</div>
                                    </div>
                                    <div className="rl-navbar-m-burger-menu-selectors-items">
                                        <div className="column">
                                            <LanguageSelect
                                                languages={props.languages}
                                                language={props.language}
                                                onLanguageChange={props.onLanguageChange}
                                            />
                                        </div>
                                        <div className="column">
                                            <CurrencySelect
                                                currencies={props.currencies}
                                                currency={props.currency}
                                                onCurrencyChange={props.onCurrencyChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <Button onClick={onLogoutClick}>{t('NavBar.actionMenu.signout')}</Button>
                        )}
                    </div>
                </nav>
            )}
        </>
    );
}

export default NavBar;
