/* tslint:disable */
/* eslint-disable */
/**
 * Telematics API
 * Telematics services API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Tag
 */
export interface Tag {
    /**
     * 
     * @type {number}
     * @memberof Tag
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof Tag
     */
    name: string;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof Tag
     */
    settings: { [key: string]: object; };
    /**
     * 
     * @type {number}
     * @memberof Tag
     */
    clientId: number;
    /**
     * 
     * @type {number}
     * @memberof Tag
     */
    tagCategoryId: number;
}

export function TagFromJSON(json: any): Tag {
    return TagFromJSONTyped(json, false);
}

export function TagFromJSONTyped(json: any, ignoreDiscriminator: boolean): Tag {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'settings': json['settings'],
        'clientId': json['client_id'],
        'tagCategoryId': json['tag_category_id'],
    };
}

export function TagToJSON(value?: Tag | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'settings': value.settings,
        'client_id': value.clientId,
        'tag_category_id': value.tagCategoryId,
    };
}


