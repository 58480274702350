import { useTranslation } from 'react-i18next';
import FavoritesCard from '../FavoritesCard/FavoritesCard';
import { useState } from 'react';
import { Favorite } from 'services/api/domains/FavoritesApi';
import { Input } from 'components/base/controls';
import { observer } from 'mobx-react';

interface Props {
    data: Favorite[];
    onClickCard: (favorite: Favorite) => void;
    onDelete: (favorite: Favorite) => void;
    onRename: (favorite: Favorite) => void;
}

function FavoritesList(props: Props) {
    const { t } = useTranslation();

    const [searchInput, setSearchInput] = useState('');

    return (
        <div className="rl-favorites-list">
            <Input
                className="search-input"
                placeholder={t('common.search')}
                suffix={<i className="fas fa-search" />}
                onChange={event => {
                    setSearchInput(event.target.value);
                }}
            />

            <div className="rl-favorites-list-content">
                {props.data
                    .filter(favorite => favorite.name?.toLocaleLowerCase()?.includes(searchInput.toLocaleLowerCase()))
                    .map(favorite => (
                        <FavoritesCard
                            favorite={favorite}
                            key={favorite.id}
                            onClickCard={props.onClickCard}
                            onDelete={props.onDelete}
                            onRename={props.onRename}
                        />
                    ))}
            </div>
        </div>
    );
}

export default observer(FavoritesList);
