/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReadOnlyProductSerializer
 */
export interface ReadOnlyProductSerializer {
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyProductSerializer
     */
    id?: string;
    /**
     * 
     * @type {Date}
     * @memberof ReadOnlyProductSerializer
     */
    readonly deleted?: Date;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyProductSerializer
     */
    type: ReadOnlyProductSerializerTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyProductSerializer
     */
    oneTimeFee?: string;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyProductSerializer
     */
    monthlyFee?: string;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyProductSerializer
     */
    currency?: ReadOnlyProductSerializerCurrencyEnum;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyProductSerializer
     */
    description?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReadOnlyProductSerializer
     */
    trial?: number;
    /**
     * 
     * @type {Date}
     * @memberof ReadOnlyProductSerializer
     */
    readonly createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ReadOnlyProductSerializer
     */
    readonly updatedAt?: Date;
}

export function ReadOnlyProductSerializerFromJSON(json: any): ReadOnlyProductSerializer {
    return ReadOnlyProductSerializerFromJSONTyped(json, false);
}

export function ReadOnlyProductSerializerFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReadOnlyProductSerializer {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'deleted': !exists(json, 'deleted') ? undefined : (new Date(json['deleted'])),
        'type': json['type'],
        'oneTimeFee': !exists(json, 'one_time_fee') ? undefined : json['one_time_fee'],
        'monthlyFee': !exists(json, 'monthly_fee') ? undefined : json['monthly_fee'],
        'currency': !exists(json, 'currency') ? undefined : json['currency'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'trial': !exists(json, 'trial') ? undefined : json['trial'],
        'createdAt': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
        'updatedAt': !exists(json, 'updated_at') ? undefined : (new Date(json['updated_at'])),
    };
}

export function ReadOnlyProductSerializerToJSON(value?: ReadOnlyProductSerializer | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'type': value.type,
        'one_time_fee': value.oneTimeFee,
        'monthly_fee': value.monthlyFee,
        'currency': value.currency,
        'description': value.description,
        'trial': value.trial,
    };
}

/**
* @export
* @enum {string}
*/
export enum ReadOnlyProductSerializerTypeEnum {
    _64307d0cE97a4a83Ad9f47aeef4a7132 = '64307d0c-e97a-4a83-ad9f-47aeef4a7132',
    _6709ae25Ce904963B4c211191fa6c26c = '6709ae25-ce90-4963-b4c2-11191fa6c26c'
}
/**
* @export
* @enum {string}
*/
export enum ReadOnlyProductSerializerCurrencyEnum {
    B007faaa46fe49608a8aAdacff6b4291 = 'b007faaa-46fe-4960-8a8a-adacff6b4291',
    D968ebc7Ae914681Ad42398c0321ce4c = 'd968ebc7-ae91-4681-ad42-398c0321ce4c'
}


