import { useTranslation } from 'react-i18next';

export default function FavoritesBlank() {
    const { t } = useTranslation();

    return (
        <div className="rl-favorites-blank">
            <img src="/img/settings/favorites/favorites-blank-icon.svg" alt="blank" />

            <h5>{t('FavoritesBlank.title')}</h5>

            <p>{t('FavoritesBlank.text')}</p>
        </div>
    );
}
