import { useTranslation } from 'react-i18next';
import { Modal, Notification } from 'components/base/layout';
import { Logic } from 'logic/logic';
import { DriversTable } from './ui/DriversTable/DriversTable';
import { Driver, UserRole } from 'services/api/domains/CompanyApi';
import DriversBlank from './ui/DriversBlank/DriversBlank';
import InviteDriverBanner from './ui/InviteDriverBanner/InviteDriverBanner';
import { getInviteLink } from 'utils/helpers/invite-driver';
import AssignVehicleModule from 'modules/AssignVehicleModule/AssignVehicleModule';
import { CompanyVehicle } from 'services/api/domains/VehiclesApi';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { RouteNames } from 'router/routes';
import { Button } from 'components/base/controls';

export interface DriverWithVehicle extends Driver {
    vehicle?: CompanyVehicle;
}

interface Props {
    logic: Logic;
}

interface DriversTableData {
    data?: DriverWithVehicle[];
    isLoading?: boolean;
}

interface AssignVehicleModal {
    visible?: boolean;
    loading?: boolean;
    selectedDriverId?: Driver['profileId'];
}

const DriversModule = (props: Props) => {
    const { t } = useTranslation();
    const history = useHistory();
    const { isMobileWidth } = useWindowDimensions();

    const [inviteLink, setInviteLink] = useState('');
    const [driversTable, setDriversTable] = useState<DriversTableData>();
    const [assignVehicleModal, setAssignVehicleModal] = useState<AssignVehicleModal>();

    useEffect(() => {
        _getInviteLink();
        _loadTableData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const _getInviteLink = async () => {
        try {
            const companyId = props.logic.company().getCompany().companyId;
            const code = await props.logic.apiService().company().getCode(companyId, UserRole.driver);
            setInviteLink(getInviteLink(code.code, companyId, props.logic.conf.api.inviteUrl));
        } catch (err) {
            console.error(err);
        }
    };

    const _createNewInviteLink = async () => {
        try {
            const companyId = props.logic.company().getCompany().companyId;
            const code = await props.logic.apiService().company().createCode(companyId, UserRole.driver);
            setInviteLink(getInviteLink(code.code, companyId, props.logic.conf.api.inviteUrl));
        } catch (err) {
            console.error(err);
        }
    };

    const _disableInviteLink = async () => {
        try {
            const companyId = props.logic.company().getCompany().companyId;
            await props.logic.apiService().company().deleteCode(companyId, UserRole.driver);
            setInviteLink('');
        } catch (err) {
            console.error(err);
        }
    };

    const _loadTableData = async () => {
        setDriversTable({ isLoading: true });

        const companyId = props.logic.company().getCompany().companyId;
        const vehicles = await props.logic.vehicles().getVehicles();
        const drivers = await props.logic.apiService().company().getCompanyDrivers(companyId);

        const data = drivers.map(driver => ({
            ...driver,
            vehicle: vehicles.find(v => v.drivers?.[0]?.profileId === driver.profileId)
        }));

        setDriversTable({ isLoading: false, data: data });
    };

    const _onRemoveFromCompany = (driverId: number) => {
        Modal.confirm({
            title: t('SettingsDrivers.deleteHeader'),
            content: t('SettingsDrivers.deleteConfirm'),
            onOk: () => _onRemoveFromCompanyConfirm(driverId)
        });
    };

    const _onRemoveFromCompanyConfirm = async (driverId: number) => {
        try {
            const companyId = props.logic.company().getCompany().companyId;
            await props.logic.apiService().users().deleteUser(companyId, String(driverId), 'driver');

            Notification.success({
                message: t('SettingsDrivers.deleteSuccess')
            });

            _loadTableData();
        } catch (err) {
            console.error(err);
            Notification.error({
                message: err.message
            });
        }
    };

    const _onUnassignVehicle = (vehicleId: number, driverId: number) => {
        Modal.confirm({
            title: t('common.confirm'),
            content: t('SettingsDrivers.unassignVehicle'),
            onOk: () => _onUnassignVehicleConfirm(vehicleId, driverId)
        });
    };

    const _onUnassignVehicleConfirm = async (vehicleId: number, driverId: number) => {
        try {
            const companyId = props.logic.company().getCompany().companyId;
            await props.logic.apiService().vehicles().unassignDriver(driverId, companyId, vehicleId);
            props.logic.userEvents().unassignDriver(driverId, vehicleId, companyId);

            Notification.success({
                message: t('SettingsDrivers.unassignSuccess')
            });

            _loadTableData();
        } catch (err) {
            console.error(err);
            Notification.error({
                message: err.message
            });
        }
    };

    const _closeVehicleAssignModal = () => {
        setAssignVehicleModal({});
    };

    const _openVehicleAssignModal = (driverId: number) => {
        setAssignVehicleModal({
            visible: true,
            selectedDriverId: driverId
        });
    };

    const _onVehicleAssignConfirm = async (vehicleId: string) => {
        try {
            if (!assignVehicleModal?.selectedDriverId) {
                throw new Error('No driver selected');
            }

            setAssignVehicleModal({ loading: true });
            const companyId = props.logic.company().getCompany().companyId;

            await props.logic
                .apiService()
                .vehicles()
                .assignDriver(assignVehicleModal.selectedDriverId, companyId, Number(vehicleId));

            props.logic.userEvents().assignDriver(assignVehicleModal.selectedDriverId, +vehicleId);

            Notification.success({
                message: t('SettingsDrivers.assignVehicleSuccess')
            });

            _closeVehicleAssignModal();
            _loadTableData();
        } catch (err) {
            console.error(err);
            Notification.error({
                message: err.message
            });
        } finally {
            setAssignVehicleModal({ loading: false });
        }
    };

    return (
        <div className="rl-settings-module rl-drivers-module">
            <div className="rl-settings-module-header">
                {isMobileWidth && (
                    <Button
                        className="rl-settings-module-header-back"
                        type="action"
                        icon={<i className="rl-icon-chevron-left" />}
                        onClick={() => history.push(RouteNames.SETTINGS)}
                    />
                )}

                <h3 className="rl-settings-module-header-title">{t('common.drivers')}</h3>
            </div>
            <div className="rl-settings-module-content">
                <InviteDriverBanner
                    inviteLink={inviteLink}
                    onCreateNewLinkClick={_createNewInviteLink}
                    onDisableLinkClick={_disableInviteLink}
                    onClickUserEvent={initiatedFrom => props.logic.userEvents().inviteDriverSentBy(initiatedFrom)}
                />

                {driversTable?.data?.length === 0 ? (
                    <DriversBlank />
                ) : (
                    <DriversTable
                        isLoading={driversTable?.isLoading}
                        data={driversTable?.data || []}
                        onAssignClick={_openVehicleAssignModal}
                        onUnassignClick={_onUnassignVehicle}
                        onRemoveClick={_onRemoveFromCompany}
                    />
                )}

                <AssignVehicleModule
                    logic={props.logic}
                    visible={!!assignVehicleModal?.visible}
                    onConfirm={_onVehicleAssignConfirm}
                    onCancel={_closeVehicleAssignModal}
                />
            </div>
        </div>
    );
};

export default DriversModule;
