import { AddressIdentification, VehicleIdentification } from '../conf';
import { MapLogic } from './map/map';
import { VehicleModelMap, Alarm } from './map/logic/vehicles';
import moment from 'moment';
import { Logic } from './logic';
import { ControlPanel } from 'modules/MapModule/components/MapControlsBar';
import { toAddress } from './common/address';
import { AlarmInDatabaseWithGPSInfo } from '../generated/backend-api';
import { RootStore } from 'stores/RootStore';
import { VehicleStateObject, VehicleTransportObject } from 'services/api/domains/VehiclesApi';
import { TrackingModel } from 'modules/TrackingModule/TrackingModule';
import { TrackingStatus } from 'modules/TrackingModule/ui/TrackingTable/TrackingTable';

export class TrackingLogic {
    private _active: boolean;
    private _map: MapLogic;
    private _vehicles: TrackingModel[];
    private _selectedVehicle?: TrackingModel;
    private _routeOverviewMode = false;
    private _addressIdentification?: AddressIdentification;
    private _vehicleIdentification?: VehicleIdentification;

    private _onData?: (data: TrackingModel[]) => void;

    constructor(map: MapLogic, private _logic: Logic, private _store: RootStore) {
        const settings = this._logic.settings().getProps();
        this._active = false;
        this._vehicles = [];
        this._map = map;
        this._addressIdentification = _store.userSettings.addressIdentification;
        this._vehicleIdentification = settings.vehicleIdentification;
    }

    async init(): Promise<void> {
        this._active = true;

        this._addressIdentification = this._store.userSettings.addressIdentification;
        this._vehicleIdentification = this._logic.settings().getProp('vehicleIdentification');

        this._logic.settings().onChange(async prop => {
            if (prop.vehicleIdentification) {
                this._vehicleIdentification = prop.vehicleIdentification;
            }
        });

        await this.loadVehicles();

        this._initMap();

        this._map.vehicles().fitVehicles();

        this._logic.map().onVehicleDetailToggle(() => {
            this._logic.map().sideBarControlsOff(ControlPanel.VEHICLE);
        });

        this._logic
            .notification()
            .onConnect()
            .subscribe(async () => {
                // chceck if user is loggedIn, need to read from local storage because of multiple app instances sync
                if (!this._store.auth.getAuthStateFromLocalStorage()?.isLoggedIn) {
                    return;
                }

                const vd = await this._logic.vehiclesState().data();

                this._vehicles = this._vehicles
                    .map(v => {
                        const update: VehicleStateObject | undefined = vd.find(u => u.monitoredObjectId === v.id);
                        if (update) {
                            const vehicle: TrackingModel = {
                                ...this.toTrackingModel(update),
                                selected: v.selected,
                                route: v.route,
                                centered: v.centered,
                                RN:
                                    this._vehicleIdentification === VehicleIdentification.RegistrationNumber
                                        ? v.RN
                                        : v.customLabel || v.RN
                            };
                            return vehicle;
                        } else {
                            return v;
                        }
                    })
                    .sort((a, b) => (a.RN > b.RN ? 1 : -1))
                    .sort((a, b) => (a.destination || !b.destination ? 1 : -1))
                    .sort((a, b) => (!(a.GPS?.lat && a.GPS.lng) || (b.GPS?.lat && b.GPS.lng) ? 1 : -1));

                this.updateMap();
            });

        this._logic.notification().on('actual-vehicle-state', message => {
            const updates = Object.values(message.data) as VehicleStateObject[];
            this._vehicles = this._vehicles
                .map(v => {
                    const update: VehicleStateObject | undefined = updates.find(u => u.monitoredObjectId === v.id);
                    if (update) {
                        const vehicle: TrackingModel = {
                            ...this.toTrackingModel(update),
                            selected: v.selected,
                            route: v.route,
                            centered: v.centered,
                            RN:
                                this._vehicleIdentification === VehicleIdentification.RegistrationNumber
                                    ? v.RN
                                    : v.customLabel || v.RN
                        };
                        return vehicle;
                    } else {
                        return v;
                    }
                })
                .sort((a, b) => (a.RN > b.RN ? 1 : -1))
                .sort((a, b) => (a.destination || !b.destination ? 1 : -1))
                .sort((a, b) => (!(a.GPS?.lat && a.GPS.lng) || (b.GPS?.lat && b.GPS.lng) ? 1 : -1));

            this.updateMap();
        });

        this._logic.alarms().alarmsUpdates$.subscribe(() => {
            if (this._logic.alarms().instantAlarms().length === 0) {
                return;
            }

            this._vehicles = this._vehicles.map(v => {
                return {
                    ...v,
                    alarms: this._logic
                        .alarms()
                        .instantAlarms()
                        .filter(a => a.monitoredObjectId === v.id)
                        .map(a => this._toAlarmUIModel(a))
                };
            });

            this.updateMap();
        });

        this._logic.notification().on('alarms', n => {
            if (n.data?.alarms && Array.isArray(n.data.alarms)) {
                const alarmsUpdates = n.data.alarms as AlarmInDatabaseWithGPSInfo[];

                if (alarmsUpdates.length === 0) {
                    return;
                }
            }
        });
    }

    destroy(): void {
        this._active = false;
        this._onData = undefined;
        this._map.vehicles().destroy();
        this._map.routing().destroy();
    }

    centerOffVehicles() {
        this._vehicles = this._vehicles.map(v => ({
            ...v,
            centered: false
        }));
        this._initMap();
    }

    unselectVehicle(fitVehicles = true) {
        this._vehicles = this._vehicles.map(v => ({
            ...v,
            selected: false,
            centered: false,
            route: false
        }));
        this._map.routing().destroy();
        this._logic.map().sideBarControlsOff(ControlPanel.VEHICLE);
        this.updateMap();
        this._initMap();
        fitVehicles && this._map.vehicles().fitVehicles();
        this._selectedVehicle = undefined;
    }

    isVehicleGPSInValid(id: string) {
        const vehicle = this._vehicles.find(v => v.id === id);
        return !vehicle?.GPS?.lat || !vehicle?.GPS?.lng;
    }

    selectVehicle(id: string): void {
        if (this.isVehicleGPSInValid(id)) {
            return;
        }

        this._vehicles = this._vehicles.map(v => ({
            ...v,
            selected: v.id === id,
            centered: v.id === id,
            route: false
        }));

        this._selectedVehicle = this._vehicles.find(v => v.id === id);
        this._map.routing().destroy();
        this._logic.map().vehicleOn();

        if (this._selectedVehicle?.navigationRoute.polyline) {
            this._logic
                .map()
                .routing()
                .renderRoute([], [], [this._selectedVehicle.navigationRoute.polyline], true, true);
        }

        this._initMap();
        this._map.vehicles().fitVehicle(id);
    }

    toggleEnterVehicle(id?: string) {
        this._vehicles = this._vehicles.map(v => ({
            ...v,
            hovered: v.id === id
        }));

        this._initMap();
    }

    onData(cb: (data: TrackingModel[]) => void): void {
        this._onData = cb;
    }

    getMapData(): TrackingModel[] {
        return this._vehicles.filter(v => v.GPS?.lat && v.GPS?.lng && v.visible);
    }

    loadVehicle(id: string) {
        return this._vehicles.find(v => v.id === id);
    }

    toMapModel(item: TrackingModel): VehicleModelMap {
        return {
            id: item.id,
            name: item.RN,
            selected: item.selected,
            centered: item.centered,
            route: item.route,
            angle: item.GPS?.angle,
            position: {
                lat: item.GPS?.lat,
                lng: item.GPS?.lng
            },
            driverName: item.driverName,
            registrationNumber: item.RN,
            eta: item.ETA,
            rta: item.arrivalTimes.rta,
            lastUpdate: item.lastUpdate ?? '',
            remainingDistance: item.navigationRoute.remainingDistance,
            trackingTransport: item.trackingTransport,
            navigationRoute: item.navigationRoute,
            hovered: item.hovered
        };
    }

    toTrackingModel(vs: VehicleStateObject): TrackingModel {
        const obj: TrackingModel = {
            id: String(vs.monitoredObjectId),
            route: false,
            centered: false,
            RN:
                this._vehicleIdentification === VehicleIdentification.RegistrationNumber
                    ? (vs.rn as string)
                    : (vs.customLabel as string) ?? (vs.rn as string),
            location: toAddress(
                this._store.userSettings.lang,
                vs.address_structured,
                this._addressIdentification!,
                vs.address
            ),
            ETA: '',
            RTA: '',
            firstRTA: '',
            lastUpdate: vs.gpsData?.time ?? undefined,
            arrivalTimes: {
                eta: undefined,
                rta: undefined
            },
            driverName: vs.driver?.name ?? '',
            driverId: vs.driver?.id ?? '',
            driverAvatarUrl: vs.driver?.avatarUrl ?? '',
            name: vs.name ?? '',
            selected: false,
            GPS: vs.gpsData
                ? {
                      lat: vs.gpsData.lat ? Number(vs.gpsData.lat) : undefined,
                      lng: vs.gpsData.lon ? Number(vs.gpsData.lon) : undefined,
                      angle: vs.gpsData.angle ? Number(vs.gpsData.angle) : undefined,
                      speed: vs.gpsData.speed ? Number(vs.gpsData.speed) : undefined
                  }
                : undefined,
            activeTransports:
                vs.activeTransports?.map(
                    t =>
                        ({
                            firstRta: t.firstRta ?? '',
                            id: t.id ?? '',
                            name: t.name ?? '',
                            nextWaypoint: t.nextWaypoint ?? {}
                        } as VehicleTransportObject)
                ) ?? [],
            navigationRoute: vs.navigationRoute,
            trackingTransport: this._getTrackingTransport(vs),
            visible: !this._store.userSettings.trackingVehiclesHidden.some(id => String(id) === vs.monitoredObjectId)
        };

        const actualTransport: VehicleTransportObject =
            vs.activeTransports && vs.activeTransports.length > 0
                ? vs.activeTransports[vs.activeTransports.length - 1]
                : {};

        if (actualTransport.nextWaypoint) {
            obj.destination = actualTransport.nextWaypoint?.addressStructured?.[0]?.address?.toString();
            obj.ETA = actualTransport.nextWaypoint.eta ?? '';
            obj.RTA = actualTransport.nextWaypoint.rta ?? '';
            obj.arrivalTimes.eta = actualTransport.nextWaypoint.eta ?? '';
            obj.arrivalTimes.rta = actualTransport.nextWaypoint.rta ?? '';
            obj.firstRTA = actualTransport.firstRta ?? '';
        }

        return obj;
    }

    private _toAlarmUIModel(alarm: AlarmInDatabaseWithGPSInfo): Alarm {
        return {
            id: alarm.alarmId || (alarm as any).alarm_id,
            timestamp: new Date(alarm.start).toISOString(),
            seen: !!alarm.acknowledged,
            type: alarm.alarmType
        };
    }

    private _updateData() {
        this._onData?.(this._vehicles);
    }

    private _initMap() {
        if (this._active) {
            this._updateData();
            const vehicleData = this.getMapData().map(this.toMapModel);
            this._map.vehicles().setData(vehicleData);
        }
    }

    updateMap() {
        if (this._active) {
            const selectedVehicle = this._vehicles.find(v => v.id === this._selectedVehicle?.id);

            if (this.getMapData().length) {
                let data = this.getMapData().map(this.toMapModel);
                if (selectedVehicle) {
                    data = [this.toMapModel(selectedVehicle), ...data];
                }
                this._map.vehicles().setData(data, !this._routeOverviewMode);
            } else if (selectedVehicle) {
                const data = [this.toMapModel(selectedVehicle)];
                this._map.vehicles().setData(data, !this._routeOverviewMode);
            }
        }
    }

    async loadVehicles() {
        this._vehicles = (await this._logic.vehiclesState().data())
            .map(v => this.toTrackingModel(v))
            .sort((a, b) => (a.RN > b.RN ? 1 : -1))
            .sort((a, b) => (a.destination || !b.destination ? 1 : -1))
            .sort((a, b) => (!(a.GPS?.lat && a.GPS.lng) || (b.GPS?.lat && b.GPS.lng) ? 1 : -1));

        this._updateData();

        return this._vehicles;
    }

    private _getTrackingTransport(vs: VehicleStateObject) {
        let transport,
            delayed = '',
            status = TrackingStatus.NO_TRANSPORT;

        if (vs.activeTransports?.length) {
            transport = vs.activeTransports[vs.activeTransports.length - 1];
            status = TrackingStatus.ACTIVE;

            const duration = moment.duration(moment(transport?.nextWaypoint?.eta!).diff(transport?.nextWaypoint?.rta!));
            if (duration.asMinutes() >= 1) {
                status = TrackingStatus.DELAYED;
                delayed = `+ ${moment.duration(duration).format('h[h] mm')}min`;
            }
        } else if (vs.assignedTransports?.length) {
            transport = vs.assignedTransports[0];
            status = TrackingStatus.ASSIGNED;
        }

        return {
            status: status,
            id: transport?.id!,
            name: transport?.name!,
            eta: transport?.nextWaypoint?.eta!,
            rta: transport?.nextWaypoint?.rta!,
            delayed: delayed!
        };
    }

    setRouteOverviewMode(value: boolean) {
        this._routeOverviewMode = value;
    }

    toggleVehicleVisibleOnMap(id?: string) {
        const vehiclesNotVisibleIds: number[] = [];
        this._vehicles = this._vehicles.map(v => {
            const visible = v.id === id ? !v.visible : v.visible;

            !visible && vehiclesNotVisibleIds.push(+v.id!);

            return {
                ...v,
                visible
            };
        });

        this._initMap();

        return vehiclesNotVisibleIds;
    }
}
