import { Conf } from 'conf';
import { AddressStructured } from 'generated/backend-api';
import { HttpClient } from '../common/HttpClient';
import { Driver } from './CompanyApi';

export enum TunnelType {
    Unknown = 999,
    A = 1,
    B,
    C,
    D,
    E
}

export enum VehicleType {
    Car = 1,
    Truck,
    Taxi,
    Van,
    Bus
}

export enum HazardLoadType {
    None = 1,
    General,
    Water
}

export enum EmissionClassType {
    Euro0,
    Euro1 = 1,
    Euro2,
    Euro3,
    Euro4,
    Euro5,
    Euro6,
    Euro7,
    Euro_EEV,
    Euro6c
}

export enum FuelType {
    Gasoline = 1,
    Diesel,
    CompressedNaturalGas,
    LiquefiedPetroleumGas
}

export enum TrailerType {
    Tent,
    Isotherm,
    BoxTruck,
    Spacious,
    Other,
    CarTransporter,
    Trailer,
    Van,
    Mega,
    Coilmulde,
    WalkingFloor,
    LowSuspension,
    Flatbed,
    ChemicalTanker,
    FoodTanker,
    PetroleumTanker,
    GasTanker,
    LogTrailer,
    OversizedCargo,
    HookLift,
    Container2040,
    DumpTruck,
    Koffer,
    SwapBodySystem,
    Jumbo,
    Cooler,
    Curtainsider,
    Tanker,
    Silos,
    RemovalTruck
}

export enum TrailerWayOfLoadingType {
    Top,
    Back,
    Side,
    Any
}

export enum TrailerAccessoriesType {
    Lift,
    TruckCrane,
    TirCable,
    TrackingSystem
}

export interface VehicleTrailer {
    type: TrailerType;
    name?: string;
    cmrNumber?: string;
    length: number;
    height: number;
    width: number;
    weight: number;
    volumeFluids: number;
    wayOfLoading: TrailerWayOfLoadingType;
    accessories: TrailerAccessoriesType;
}

export interface Vehicle {
    name?: string;
    vehicleType: VehicleType;
    tunnelCode: TunnelType;
    width: number;
    height: number;
    totalLength: number;
    totalWeight: number;
    axleWeight: number;
    emissionClass?: EmissionClassType;
    fuelType?: FuelType;
    trailersCount?: number;
    vehicleAxles?: number;
    trailerAxles?: number;
    costPerKm?: number;
    maxSpeed?: number;
}

export interface CompanyVehicle extends Vehicle {
    vehicleId?: number;
    registrationNumber: string;
    hazardousLoadType: HazardLoadType;
    trailer?: VehicleTrailer;
    drivers?: Driver[];
}

export interface VehicleProfile extends Vehicle {
    vehicleProfileId?: number;
    order: number;
    iconId: number;
    isDefault: boolean;
    isVisible: boolean;
    loadType?: HazardLoadType;
}

export interface VehicleStateObject {
    activeTransports?: VehicleTransportObject[];
    address?: string;
    address_structured?: AddressStructured[];
    assignedTransports?: VehicleTransportObject[];
    customLabel?: string;
    driver?: VehicleDriverObject;
    gpsData?: VehicleGpsDataObject;
    monitoredObjectId?: string;
    name?: string;
    rn?: string;
    stateData?: VehicleStateDataObject;
    navigationRoute: {
        polyline?: string;
        destination?: string;
        eta?: string;
        remainingDistance?: number;
    };
}

export interface VehicleTransportObject {
    firstRta?: string;
    id?: string;
    name?: string;
    nextWaypoint?: VehicleTransportNextWayPointObject;
}

interface VehicleTransportNextWayPointObject {
    addressStructured?: AddressStructured;
    eta?: string;
    lat?: number;
    lon?: number;
    name?: string;
    remainingDistance?: number;
    rta?: string;
}

interface VehicleGpsDataObject {
    angle?: number;
    lat?: number;
    lon?: number;
    speed?: number;
    time?: string;
}

interface VehicleDriverObject {
    id?: string;
    name?: string;
    avatarUrl?: string;
}

interface VehicleStateDataObject {
    ignition?: number;
    time?: string;
}

const VEHICLE_API_VERSION = '7';

class VehiclesApi {
    constructor(private _conf: Conf['api'], private _httpClient: HttpClient) {}

    async getVehicles(companyId: number): Promise<CompanyVehicle[]> {
        return this._httpClient.get(`${this._conf.coreUrl}/company/${companyId}/vehicles`, {
            apiVersion: VEHICLE_API_VERSION
        });
    }

    async getVehicle(vehicleId: number, companyId: number): Promise<CompanyVehicle> {
        return this._httpClient.get(`${this._conf.coreUrl}/company/${companyId}/vehicle/${vehicleId}`, {
            apiVersion: VEHICLE_API_VERSION
        });
    }

    async createVehicle(data: CompanyVehicle, companyId: number) {
        return this._httpClient.post<CompanyVehicle>(`${this._conf.coreUrl}/company/${companyId}/vehicle`, data, {
            apiVersion: VEHICLE_API_VERSION
        });
    }

    async updateVehicle(data: CompanyVehicle, companyId: number): Promise<any> {
        return this._httpClient.put(`${this._conf.coreUrl}/company/${companyId}/vehicle/${data.vehicleId}`, data, {
            apiVersion: VEHICLE_API_VERSION
        });
    }

    async deleteVehicle(vehicleId: number, companyId: number): Promise<any> {
        return this._httpClient.delete(`${this._conf.coreUrl}/company/${companyId}/vehicle/${vehicleId}`, null, {
            apiVersion: VEHICLE_API_VERSION
        });
    }

    async assignDriver(driverId: number, companyId: number, vehicleId: number) {
        return this._httpClient.put(
            `${this._conf.coreUrl}/company/${companyId}/vehicle/${vehicleId}/driver/${driverId}`,
            null,
            {
                apiVersion: VEHICLE_API_VERSION
            }
        );
    }

    async unassignDriver(driverId: number, companyId: number, vehicleId: number) {
        return this._httpClient.delete(
            `${this._conf.coreUrl}/company/${companyId}/vehicle/${vehicleId}/driver/${driverId}`,
            null,
            {
                apiVersion: VEHICLE_API_VERSION
            }
        );
    }

    async getDefaultVehicleProfiles() {
        return this._httpClient
            .get<{ vehicleProfiles: VehicleProfile[] }>(`${this._conf.coreUrl}/vehicleProfile/default`, {
                apiVersion: VEHICLE_API_VERSION
            })
            .then(data => data.vehicleProfiles);
    }

    async getCompanyDefaultVehicleProfiles(companyId: number) {
        return this._httpClient
            .get<{ vehicleProfiles: VehicleProfile[] }>(`${this._conf.coreUrl}/company/${companyId}/vehicleProfiles`, {
                apiVersion: VEHICLE_API_VERSION
            })
            .then(data => data.vehicleProfiles);
    }

    async getVehiclesTracking() {
        return this._httpClient.get<VehicleStateObject[]>(`${this._conf.middlewareUrl}/vehicles/tracking`);
    }
}

export { VehiclesApi };
