import { MapPlaceType } from 'logic/map/map';
import { AvoidsTypes, TaskType } from 'modules/PlannerModule/PlannerModule';
import { AlarmType } from 'modules/PlannerModule/ui/PlannerAlarms';
import { Country } from 'services/api/domains/CountryApi';
import {
    CompanyTransportActivityTypeEnum,
    CompanyTransportPointTypeEnum,
    CompanyTransportStatus,
    TransportAvoidModel,
    CompanyTransportAvoidTypeEnum,
    TransportNotificationEnum,
    TransportState
} from 'services/api/domains/TransportsApi';

export function mapNotificationToAlarmType(notification: TransportNotificationEnum) {
    const mapNotificationToAlarmEnum = {
        [TransportNotificationEnum.Arrival]: AlarmType.TransportArrival,
        [TransportNotificationEnum.PassingWaypoint]: AlarmType.CloseToPoi,
        [TransportNotificationEnum.DeviationFromRoute]: AlarmType.CorridorLeave
    };

    return mapNotificationToAlarmEnum[notification];
}

export function mapActivityToTaskType(instruction: CompanyTransportActivityTypeEnum) {
    const mapInstructionsToTaskTypeEnum = {
        [CompanyTransportActivityTypeEnum.None]: '',
        [CompanyTransportActivityTypeEnum.Parking]: TaskType.Parking,
        [CompanyTransportActivityTypeEnum.Fuel]: TaskType.Refueling,
        [CompanyTransportActivityTypeEnum.Load]: TaskType.Loading,
        [CompanyTransportActivityTypeEnum.Unload]: TaskType.Unloading
    };

    return mapInstructionsToTaskTypeEnum[instruction];
}

export function mapPointTypeToPlaceType(poinType: CompanyTransportPointTypeEnum) {
    const mapPointTypeToPlaceTypeEnum = {
        [CompanyTransportPointTypeEnum.Start]: MapPlaceType.Start,
        [CompanyTransportPointTypeEnum.End]: MapPlaceType.End,
        [CompanyTransportPointTypeEnum.Waypoint]: MapPlaceType.Waypoint,
        [CompanyTransportPointTypeEnum.Via]: MapPlaceType.Viapoint
    };

    return mapPointTypeToPlaceTypeEnum[poinType];
}

export function mapTransportStatusToTransportState(status: CompanyTransportStatus) {
    const statusToState = {
        [CompanyTransportStatus.Active]: TransportState.Active,
        [CompanyTransportStatus.Assigned]: TransportState.Assigned,
        [CompanyTransportStatus.Completed]: TransportState.Finished,
        [CompanyTransportStatus.New]: TransportState.New
    };

    return statusToState[status];
}

export function mapTransportStateToCompanyTransportStatus(placeType: TransportState) {
    const stateToStatus = {
        [TransportState.Active]: CompanyTransportStatus.Active,
        [TransportState.Assigned]: CompanyTransportStatus.Assigned,
        [TransportState.Finished]: CompanyTransportStatus.Completed,
        [TransportState.New]: CompanyTransportStatus.New
    };

    return stateToStatus[placeType];
}

export function mapPlaceTypeToCompanyTransportPointType(placeType: MapPlaceType) {
    const placeTypeToPointTypeEnum = {
        [MapPlaceType.Start]: CompanyTransportPointTypeEnum.Start,
        [MapPlaceType.End]: CompanyTransportPointTypeEnum.End,
        [MapPlaceType.Waypoint]: CompanyTransportPointTypeEnum.Waypoint,
        [MapPlaceType.Viapoint]: CompanyTransportPointTypeEnum.Via
    };

    return placeTypeToPointTypeEnum[placeType];
}

export function mapAlarmTypeToCompanyTransportNotificationType(alarmType: AlarmType) {
    const alarmTypeToNotification = {
        [AlarmType.TransportArrival]: TransportNotificationEnum.Arrival,
        [AlarmType.CloseToPoi]: TransportNotificationEnum.PassingWaypoint,
        [AlarmType.CorridorLeave]: TransportNotificationEnum.DeviationFromRoute
    };

    return alarmTypeToNotification[alarmType];
}

export function mapTaskTypeToCompanyTransportActivityType(taskType: TaskType) {
    const taskTypeToActivityType = {
        [TaskType.Parking]: CompanyTransportActivityTypeEnum.Parking,
        [TaskType.Refueling]: CompanyTransportActivityTypeEnum.Fuel,
        [TaskType.Loading]: CompanyTransportActivityTypeEnum.Load,
        [TaskType.Unloading]: CompanyTransportActivityTypeEnum.Unload
    };

    return taskType ? taskTypeToActivityType[taskType] : CompanyTransportActivityTypeEnum.None;
}

export function mapToCompanyTransportAvoids(avoids: string, countryList: Country[]): TransportAvoidModel[] {
    const transportAvoidType = {
        [AvoidsTypes.HIGHWAYS]: CompanyTransportAvoidTypeEnum.Highway,
        [AvoidsTypes.TOLLS]: CompanyTransportAvoidTypeEnum.TollRoads,
        [AvoidsTypes.UNPAVED]: CompanyTransportAvoidTypeEnum.UnpavedRoads,
        [AvoidsTypes.FERRIES]: CompanyTransportAvoidTypeEnum.Ferry,
        [AvoidsTypes.COUNTRY]: CompanyTransportAvoidTypeEnum.Country
    };

    //Input ex.: cze:country|svk:tolls|aut:highways
    if (avoids?.length) {
        return avoids.split('|').map(avoid => {
            const avoidModel = avoid.split(':');
            return {
                countryIso: countryIso3ToIso2(countryList, avoidModel[0]),
                type: transportAvoidType[avoidModel[1]]
            } as TransportAvoidModel;
        });
    } else {
        return [];
    }
}

export function mapFromAvoids(avoids: TransportAvoidModel[], countryList: any) {
    const transportAvoidType = {
        [CompanyTransportAvoidTypeEnum.Highway]: AvoidsTypes.HIGHWAYS,
        [CompanyTransportAvoidTypeEnum.TollRoads]: AvoidsTypes.TOLLS,
        [CompanyTransportAvoidTypeEnum.UnpavedRoads]: AvoidsTypes.UNPAVED,
        [CompanyTransportAvoidTypeEnum.Ferry]: AvoidsTypes.FERRIES,
        [CompanyTransportAvoidTypeEnum.Country]: AvoidsTypes.COUNTRY
    };

    return avoids
        ?.map(
            avoid =>
                `${avoid.countryIso ? countryIso2ToIso3(countryList, avoid.countryIso) : null}:${
                    transportAvoidType[avoid.type!]
                }`
        )
        .join('|');
}

function countryIso3ToIso2(countryList: Country[], iso3: string) {
    return countryList.find(country => country.iso3?.toLowerCase() === iso3.toLowerCase())?.code || null;
}

function countryIso2ToIso3(countryList: Country[], iso2: string) {
    return countryList.find(country => country.code?.toLowerCase() === iso2.toLowerCase())?.iso3 || null;
}
