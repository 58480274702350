import { Conf } from 'conf';
import { HttpClient } from '../common/HttpClient';

const FUEL_API_VERSION = '7';

export interface FuelPriceFuelStation {
    id: number;
    brand: string;
    coordinate: {
        lon: number;
        lat: number;
    };
    providerType: number;
    fuelPrices: {
        price: number;
        currency: string;
        fuel: {
            id: number;
            name: string;
        };
        created: string;
    }[];
}

export class FuelApi {
    constructor(private _conf: Conf['api'], private _httpClient: HttpClient) {}

    async pricesInArea(boudingBoxParam: any) {
        const { fuelStations } = await this._httpClient.get<{ fuelStations: FuelPriceFuelStation[] }>(
            `${this._conf.coreUrl}/FuelPrice/StationsInArea?boundingBox=` + boudingBoxParam,
            {
                apiVersion: FUEL_API_VERSION
            }
        );

        return fuelStations;
    }
}
