/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InlineResponse2002,
    InlineResponse2002FromJSON,
    InlineResponse2002ToJSON,
    ReadOnlyBundleSerializer,
    ReadOnlyBundleSerializerFromJSON,
    ReadOnlyBundleSerializerToJSON,
    WriteOnlyBundleSerializer,
    WriteOnlyBundleSerializerFromJSON,
    WriteOnlyBundleSerializerToJSON,
} from '../models';

export interface BundleCreateRequest {
    data: WriteOnlyBundleSerializer;
}

export interface BundleDeleteRequest {
    id: string;
}

export interface BundleListRequest {
    createdAtIexact?: string;
    createdAtGte?: string;
    createdAtLte?: string;
    limit?: number;
    offset?: number;
}

export interface BundlePartialUpdateRequest {
    id: string;
    data: WriteOnlyBundleSerializer;
}

export interface BundleReadRequest {
    id: string;
}

export interface BundleUpdateRequest {
    id: string;
    data: WriteOnlyBundleSerializer;
}

/**
 * no description
 */
export class BundleApi extends runtime.BaseAPI {

    /**
     */
    async bundleCreateRaw(requestParameters: BundleCreateRequest): Promise<runtime.ApiResponse<ReadOnlyBundleSerializer>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling bundleCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/bundle/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyBundleSerializerToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyBundleSerializerFromJSON(jsonValue));
    }

    /**
     */
    async bundleCreate(requestParameters: BundleCreateRequest): Promise<ReadOnlyBundleSerializer> {
        const response = await this.bundleCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async bundleDeleteRaw(requestParameters: BundleDeleteRequest): Promise<runtime.ApiResponse<ReadOnlyBundleSerializer>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling bundleDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/bundle/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyBundleSerializerFromJSON(jsonValue));
    }

    /**
     */
    async bundleDelete(requestParameters: BundleDeleteRequest): Promise<ReadOnlyBundleSerializer> {
        const response = await this.bundleDeleteRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async bundleListRaw(requestParameters: BundleListRequest): Promise<runtime.ApiResponse<InlineResponse2002>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.createdAtIexact !== undefined) {
            queryParameters['created_at__iexact'] = requestParameters.createdAtIexact;
        }

        if (requestParameters.createdAtGte !== undefined) {
            queryParameters['created_at__gte'] = requestParameters.createdAtGte;
        }

        if (requestParameters.createdAtLte !== undefined) {
            queryParameters['created_at__lte'] = requestParameters.createdAtLte;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/bundle/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse2002FromJSON(jsonValue));
    }

    /**
     */
    async bundleList(requestParameters: BundleListRequest): Promise<InlineResponse2002> {
        const response = await this.bundleListRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async bundlePartialUpdateRaw(requestParameters: BundlePartialUpdateRequest): Promise<runtime.ApiResponse<ReadOnlyBundleSerializer>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling bundlePartialUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling bundlePartialUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/bundle/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyBundleSerializerToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyBundleSerializerFromJSON(jsonValue));
    }

    /**
     */
    async bundlePartialUpdate(requestParameters: BundlePartialUpdateRequest): Promise<ReadOnlyBundleSerializer> {
        const response = await this.bundlePartialUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async bundleReadRaw(requestParameters: BundleReadRequest): Promise<runtime.ApiResponse<ReadOnlyBundleSerializer>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling bundleRead.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/bundle/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyBundleSerializerFromJSON(jsonValue));
    }

    /**
     */
    async bundleRead(requestParameters: BundleReadRequest): Promise<ReadOnlyBundleSerializer> {
        const response = await this.bundleReadRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async bundleUpdateRaw(requestParameters: BundleUpdateRequest): Promise<runtime.ApiResponse<ReadOnlyBundleSerializer>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling bundleUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling bundleUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/bundle/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyBundleSerializerToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyBundleSerializerFromJSON(jsonValue));
    }

    /**
     */
    async bundleUpdate(requestParameters: BundleUpdateRequest): Promise<ReadOnlyBundleSerializer> {
        const response = await this.bundleUpdateRaw(requestParameters);
        return await response.value();
    }

}
