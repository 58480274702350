import mapboxgl, { LngLatBounds } from 'mapbox-gl';
import { Logic } from '../../logic';
import { debounce } from '../../../utils/helpers/debounce';
import { PoiModelMap } from './fuelStations';
import { PlaceType } from '../../../services/api/domains/PlacesApi';
import { PlaceLayers, PlaceTypeLayer } from '../layers/PlacesLayer';

export const PLACE_VISIBLE_MIN_ZOOM = 12;

export class PlacesMapController {
    visible: boolean;

    private readonly _logic?: Logic;
    private _mapBox?: mapboxgl.Map;
    private _markers: GeoJSON.Feature<GeoJSON.Point>[];
    private _markersById: { [id: string]: GeoJSON.Feature<GeoJSON.Point> } = {};
    private SQUARE_SIZE_DEC = 10;
    private squareCache = new Set();
    private newBoundingBoxesLimit = 50;
    private poisLimit = 1500;
    private _onPlaceClick?: (place: PoiModelMap) => void;

    constructor(mapBox?: mapboxgl.Map, logic?: Logic) {
        this._mapBox = mapBox;
        this._markers = [];
        this.visible = false;
        this._logic = logic;
        this._addSourceAndLayers();
    }

    setData(data: PoiModelMap[]): void {
        this._markers = data.map(place => {
            const marker = this._createPlaceMarker(place);
            this._markersById[marker.properties?.id] = marker;
            return marker;
        });

        this._renderFeatures(this._markers);
    }

    deleteMarker(placeId: string): void {
        if (this._markersById[placeId]) {
            delete this._markersById[placeId];
            this._markers = Object.values(this._markersById);
            this._renderFeatures(this._markers);
        }
    }

    showMarker(place: PoiModelMap): void {
        this._markersById[place.id] = this._createPlaceMarker(place);
        this._markers = Object.values(this._markersById);
        this._renderFeatures(this._markers);
    }

    onClick(cb?: (parking: PoiModelMap) => void): void {
        this._onPlaceClick = cb;
    }

    private _renderFeatures(features: GeoJSON.Feature<GeoJSON.Point>[]) {
        const sourceIds = [...new Set(features.map(feature => feature.properties?.category))];
        sourceIds.forEach(category => {
            const sourceId = this._getSourceId(category);
            const source = this._mapBox?.getSource(sourceId!) as mapboxgl.GeoJSONSource;
            source?.setData({
                type: 'FeatureCollection',
                features: features.filter(feature => feature.properties?.category === category)
            });
        });
    }

    private _getSourceId(category: number) {
        if (category === PlaceType.COMPANY) {
            return PlaceTypeLayer.COMPANY;
        } else if (category === PlaceType.SHOP) {
            return PlaceTypeLayer.SHOP;
        } else {
            return PlaceTypeLayer.SERVICES;
        }
    }

    private _createPlaceMarker(place: any) {
        const geoJsonFeature: GeoJSON.Feature<GeoJSON.Point> = {
            type: 'Feature',
            properties: {
                id: place.id,
                name: place.name,
                isFavorite: place.isFavorite,
                routeIndex: place.routeIndex,
                isSelected: place.selected,
                category: place.category,
                isAlongRoute: place.isAlongRoute
            },
            geometry: {
                type: 'Point',
                coordinates: [place.position.lng, place.position.lat]
            }
        };

        return geoJsonFeature;
    }

    private _addSourceAndLayers(): void {
        this._mapBox?.on('moveend', async () => {
            this._showPlaces();
        });

        Object.values(PlaceTypeLayer).forEach(category => {
            this._mapBox?.on('click', PlaceLayers['PLACE_UNCLUSTERED_' + category], e => {
                if (e.originalEvent.defaultPrevented) {
                    return;
                }
                e.preventDefault();
                const place = e.features?.[0].properties as PoiModelMap;
                const [lng, lat] = (e.features?.[0].geometry as any).coordinates;
                if (place) {
                    place.position = { lng, lat };
                    this._logic?.schedulingRoutePlanner().updatePlaceOnRoute(place);
                    this._onPlaceClick?.(place);
                }
            });
        });
    }

    _showPlaces = debounce(async (clearCache = false) => {
        if (clearCache) {
            this.clearCache();
        }

        if (this._mapBox!.getZoom() >= PLACE_VISIBLE_MIN_ZOOM) {
            const bounds = this._mapBox!.getBounds();
            const boundList = this._getVisibleBoxes(bounds);
            let newBoundingBoxes = boundList.filter(e => !this.squareCache.has(e.id));
            const places = this._logic?.poi().getPlaces()!;

            if (
                this.squareCache.size + newBoundingBoxes.length > this.newBoundingBoxesLimit ||
                Object.keys(places).length > this.poisLimit
            ) {
                this._clearBoundsCache(bounds, places!);
                newBoundingBoxes = boundList;
            }

            newBoundingBoxes.forEach(square => {
                const boundingBox = square.boundingBox;
                const bounds = `${boundingBox.getSouthWest().lat},${boundingBox.getSouthWest().lng}|${
                    boundingBox.getNorthEast().lat
                },${boundingBox.getNorthEast().lng}`;

                this._logic
                    ?.poi()
                    .getPlacesInArea(bounds)
                    .then(places => {
                        this.setData(places!);
                        this.squareCache.add(square.id);
                    });
            });
        }
    }, 500);

    _getPoiSquareId({ lng, lat }: { lng: number; lat: number }) {
        return `${Math.floor(lng * this.SQUARE_SIZE_DEC)}_${Math.floor(lat * this.SQUARE_SIZE_DEC)}`;
    }

    _getVisibleBoxes(boundingBox: LngLatBounds) {
        const startLng = Math.floor(boundingBox.getSouthWest().lng * this.SQUARE_SIZE_DEC);
        const endLng = Math.ceil(boundingBox.getNorthEast().lng * this.SQUARE_SIZE_DEC);
        const startLat = Math.floor(boundingBox.getSouthWest().lat * this.SQUARE_SIZE_DEC);
        const endLat = Math.ceil(boundingBox.getNorthEast().lat * this.SQUARE_SIZE_DEC);
        const squares = [];

        for (let lng = startLng; lng <= endLng; lng++) {
            for (let lat = startLat; lat <= endLat; lat++) {
                squares.push({
                    id: this._getPoiSquareId({ lng, lat }),
                    boundingBox: new mapboxgl.LngLatBounds(
                        [lng / this.SQUARE_SIZE_DEC, lat / this.SQUARE_SIZE_DEC],
                        [(lng + 1) / this.SQUARE_SIZE_DEC, (lat + 1) / this.SQUARE_SIZE_DEC]
                    )
                });
            }
        }

        return squares;
    }

    _clearBoundsCache(boundingBox: LngLatBounds, pois: PoiModelMap[]) {
        const center = this._getBoundsCenter(boundingBox);
        const sortedPois = Object.values(pois)
            .map(poi => {
                const lnglat = new mapboxgl.LngLat(poi.position.lng, poi.position.lat);
                return {
                    id: poi.id,
                    position: lnglat,
                    distance: center.distanceTo(lnglat)
                };
            })
            .sort((a, b) => b.distance - a.distance);

        const impactedSquares = new Set();
        for (let index = 0; index < Math.min(1000, sortedPois.length); index++) {
            const poi = sortedPois[index];

            if (boundingBox.contains(poi.position)) {
                break;
            }

            delete pois[poi.id];
            impactedSquares.add(this._getPoiSquareId(poi.position));
        }

        if (impactedSquares) {
            impactedSquares.forEach(squareSet => {
                this.squareCache.delete(squareSet);
            });
        } else {
            this.squareCache = new Set();
        }
    }

    _getBoundsCenter(boundingBox: LngLatBounds) {
        return new mapboxgl.LngLat(
            (boundingBox.getSouthWest().lng + boundingBox.getNorthEast().lng) / 2,
            (boundingBox.getSouthWest().lat + boundingBox.getNorthEast().lat) / 2
        );
    }

    clearCache() {
        this.squareCache.clear();
    }

    refreshPlaces() {
        this._showPlaces(true);
    }
}
