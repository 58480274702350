import { Conf } from 'conf';
import { HttpClient } from '../common/HttpClient';

export enum ShareEtaCodeState {
    Active = 'active',
    Cancelled = 'cancelled',
    Completed = 'completed'
}

export interface ShareEtaCode {
    driverId: string;
    code: string;
    state: ShareEtaCodeState;
    etaShareLink: string;
    createdOn: string;
    validUntil: string;
}

export interface ShareEtaPatch {
    code: string;
    state: ShareEtaCodeState;
    validUntil?: string;
}

class ShareEtaApi {
    constructor(private _conf: Conf['api'], private _httpClient: HttpClient) {}

    async createShareEtaCode(driverId: string, validUntil?: string) {
        return this._httpClient.post<ShareEtaCode>(
            `${this._conf.shareEtaUrl}/dispatcher/code`,
            { validUntil, driverId },
            {
                headers: { 'Content-Type': 'application/json' }
            }
        );
    }

    async fetchShareEtaCodes() {
        return this._httpClient.get<ShareEtaCode[]>(`${this._conf.shareEtaUrl}/dispatcher/code`);
    }

    async patchShareEtaCode(patchModel: ShareEtaPatch) {
        return this._httpClient.patch(
            `${this._conf.shareEtaUrl}/dispatcher/code/${patchModel.code}`,
            {
                validUntil: patchModel.validUntil,
                state: patchModel.state
            },
            {
                headers: { 'Content-Type': 'application/json' }
            }
        );
    }
}

export { ShareEtaApi };
