/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Client,
    ClientFromJSON,
    ClientFromJSONTyped,
    ClientToJSON,
} from '.';

/**
 * 
 * @export
 * @interface ReadOnlyAddressNested
 */
export interface ReadOnlyAddressNested {
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyAddressNested
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof ReadOnlyAddressNested
     */
    country: number;
    /**
     * 
     * @type {Client}
     * @memberof ReadOnlyAddressNested
     */
    client?: Client;
    /**
     * 
     * @type {number}
     * @memberof ReadOnlyAddressNested
     */
    readonly deleted?: number;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyAddressNested
     */
    city?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyAddressNested
     */
    street?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyAddressNested
     */
    houseNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyAddressNested
     */
    zip?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyAddressNested
     */
    type?: ReadOnlyAddressNestedTypeEnum;
}

export function ReadOnlyAddressNestedFromJSON(json: any): ReadOnlyAddressNested {
    return ReadOnlyAddressNestedFromJSONTyped(json, false);
}

export function ReadOnlyAddressNestedFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReadOnlyAddressNested {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'country': json['country'],
        'client': !exists(json, 'client') ? undefined : ClientFromJSON(json['client']),
        'deleted': !exists(json, 'deleted') ? undefined : json['deleted'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'street': !exists(json, 'street') ? undefined : json['street'],
        'houseNumber': !exists(json, 'house_number') ? undefined : json['house_number'],
        'zip': !exists(json, 'zip') ? undefined : json['zip'],
        'type': !exists(json, 'type') ? undefined : json['type'],
    };
}

export function ReadOnlyAddressNestedToJSON(value?: ReadOnlyAddressNested | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'country': value.country,
        'client': ClientToJSON(value.client),
        'city': value.city,
        'street': value.street,
        'house_number': value.houseNumber,
        'zip': value.zip,
        'type': value.type,
    };
}

/**
* @export
* @enum {string}
*/
export enum ReadOnlyAddressNestedTypeEnum {
    Correspondence = 'correspondence',
    Delivery = 'delivery'
}


