import { Row, Col } from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { remainingTimeDurationFormat } from 'utils/helpers/formatters';
import { DATE_FORMAT, TIME_FORMAT_SHORT } from '../../../../utils/constants/domain-constants';

interface Props {
    destination?: string;
    eta?: string;
    remainingDistance?: number;
}

const EtaShareInfo = (props: Props) => {
    const { t } = useTranslation();

    return (
        <div className="rl-share-eta-info">
            <Row style={{ paddingBottom: '16px' }}>
                <Col span={24}>
                    <p className="rl-share-eta-info-destination">{t('ShareEtaInfo.destination')}</p>
                    <b className="rl-share-eta-info-address">{props.destination || 'N/A'}</b>
                </Col>
            </Row>

            <Row style={{ paddingBottom: '16px' }}>
                <Col span={10}>
                    <p className="rl-share-eta-info-arrival">{t('ShareEtaInfo.arrival')}</p>
                    <b className="rl-share-eta-info-time">
                        {moment(props.eta).isValid() ? moment(props.eta).format(TIME_FORMAT_SHORT) : 0}*
                    </b>
                    <span className="rl-share-eta-info-date">
                        {moment(props.eta).isValid() && `(${moment(props.eta).format(DATE_FORMAT)})`}
                    </span>
                </Col>
                <Col span={7}>
                    <p className="rl-share-eta-info-km">{t('ShareEtaInfo.km')}</p>
                    <b className="rl-share-eta-info-distance">
                        {props.remainingDistance ? (props.remainingDistance / 1000).toFixed(0) : 0}
                    </b>
                </Col>
                <Col span={7}>
                    <p className="rl-share-eta-info-timeleft">{t('ShareEtaInfo.timeLeft')}</p>
                    <b className="rl-share-eta-info-duration">{remainingTimeDurationFormat(props.eta)}</b>
                </Col>
            </Row>

            <Row>
                <Col span={24}>
                    <p className="rl-share-eta-info-note">{t('ShareEtaInfo.note')}</p>
                </Col>
            </Row>
        </div>
    );
};

export default EtaShareInfo;
