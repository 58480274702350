import { ReactNode } from 'react';
import { DatePicker } from 'antd';
import cn from 'classnames';
import { DatePickerProps } from 'antd/lib/date-picker';

interface Props {
    trigger?: ReactNode;
}

export default function EnhancedDatePicker({ trigger, ...props }: Props & DatePickerProps) {
    if (trigger) {
        return (
            <div className={cn('ant-picker-wrapper', { 'ant-picker-wrapper-disabled': props.disabled })}>
                <DatePicker {...props} />
                {trigger}
            </div>
        );
    }

    return <DatePicker {...props} />;
}

EnhancedDatePicker.RangePicker = DatePicker.RangePicker;
