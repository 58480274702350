import { createIcon } from '../utils';

export enum ParkingMarkerIcon {
    ParkingCluster = '/img/map-markers/rl-parking-cluster.svg',
    Parking = '/img/map-markers/rl-parking-poi.svg'
}

export enum ParkingLayers {
    PARKING_CLUSTER = 'parking-cluster',
    PARKING_CLUSTER_COUNT = 'parking-cluster-count',
    PARKING_UNCLUSTERED = 'parking-unclustered'
}

export const PARKING_SOURCE_ID = 'parking';

export class ParkingsLayer {
    public static async init(map: mapboxgl.Map) {
        await Promise.all([
            createIcon('parkingClusterIcon', ParkingMarkerIcon.ParkingCluster, map),
            createIcon('parkingIcon', ParkingMarkerIcon.Parking, map)
        ]);

        map.addSource(PARKING_SOURCE_ID, {
            type: 'geojson',
            data: {
                type: 'FeatureCollection',
                features: []
            },
            cluster: true,
            clusterMaxZoom: 18,
            clusterRadius: 150,
            clusterProperties: {
                hasFavorites: ['any', ['boolean', ['get', 'isFavorite'], false]],
                isAlongRouteActive: ['all', ['!=', ['get', 'isAlongRoute'], null]],
                existsPoiAlongRoute: ['any', ['==', ['get', 'isAlongRoute'], true]]
            }
        });

        map.addLayer({
            id: ParkingLayers.PARKING_UNCLUSTERED,
            type: 'symbol',
            source: PARKING_SOURCE_ID,
            filter: ['!', ['has', 'point_count']],
            layout: {
                'icon-image': 'parkingIcon',
                'icon-allow-overlap': true,
                'icon-offset': [0, 11],
                'icon-anchor': 'bottom'
            },
            paint: {
                'icon-opacity': [
                    'case',
                    ['==', ['get', 'isAlongRoute'], null],
                    1,
                    [
                        'case',
                        ['boolean', ['feature-state', 'hover'], false],
                        1,
                        ['case', ['==', ['get', 'isAlongRoute'], true], 1, 0.6]
                    ]
                ]
            }
        });

        map.addLayer({
            id: ParkingLayers.PARKING_CLUSTER,
            type: 'symbol',
            source: PARKING_SOURCE_ID,
            filter: ['has', 'point_count'],
            layout: {
                'icon-image': 'parkingClusterIcon',
                'icon-allow-overlap': true,
                'icon-anchor': 'center'
            },
            paint: {
                'icon-opacity': [
                    'case',
                    ['==', ['get', 'isAlongRouteActive'], true],
                    [
                        'case',
                        [
                            'any',
                            ['==', ['get', 'existsPoiAlongRoute'], true],
                            ['boolean', ['feature-state', 'hover'], false]
                        ],
                        1,
                        0.6
                    ],
                    1
                ]
            }
        });

        map.addLayer({
            id: ParkingLayers.PARKING_CLUSTER_COUNT,
            type: 'symbol',
            source: PARKING_SOURCE_ID,
            filter: ['has', 'point_count'],
            layout: {
                'text-field': '{point_count_abbreviated}',
                'text-font': ['Open Sans Bold'],
                'text-size': 13,
                'text-offset': [0, -1],
                'text-allow-overlap': true
            },
            paint: {
                'text-color': 'white'
            }
        });

        map.on('mouseenter', ParkingLayers.PARKING_UNCLUSTERED, () => {
            map.getCanvas().style.cursor = 'pointer';
        });

        map.on('mouseleave', ParkingLayers.PARKING_UNCLUSTERED, () => {
            map.getCanvas().style.cursor = '';
        });

        map.on('mouseenter', ParkingLayers.PARKING_CLUSTER, () => {
            map.getCanvas().style.cursor = 'pointer';
        });

        map.on('mouseleave', ParkingLayers.PARKING_CLUSTER, () => {
            map.getCanvas().style.cursor = '';
        });
    }
}
