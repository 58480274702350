import { Col, Row } from 'antd';
import { Select } from 'components/base/controls/Select';
import { Input } from 'components/base/controls/Input';
import 'moment-duration-format';
import qa from 'qa-selectors';
import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { CompanyVehicle, VehicleProfile } from '../../../../services/api/domains/VehiclesApi';
import { CostPerKm, PlannerVehicleProfile, VehicleProfileType } from 'modules/PlannerModule/PlannerModule';
import { Button, Tooltip } from '../../../../components/base/controls';
import { DEFAULT_VEHICLE_PROFILE, TOLL_COST_DEFAULT_VALUE } from 'logic/scheduling-route-planner';
import { AvailableCurrencies } from 'utils/constants/currencies';
import { VehicleTooltip } from './ui/VehicleTooltip/VehicleTooltip';

interface Props extends WithTranslation {
    vehicles: CompanyVehicle[];
    profiles: VehicleProfile[];
    vehicleProfile?: PlannerVehicleProfile;
    loading?: boolean;
    currency: string;
    isMobileWidth?: boolean;
    onCreateProfileClick?: () => void;
    onCostPerKmChange?: (costPerKm: CostPerKm) => void;
    onVehicleChange?: (vehicleProfile: PlannerVehicleProfile) => void;
}

interface State {
    selectValue: string | 'newProfile';
    costPerKm: CostPerKm;
    dropdownOpen: boolean;
}

class PlannerVehicle extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            costPerKm: this.getCostPerKm(),
            selectValue: this.getSelectValue(),
            dropdownOpen: false
        };
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.vehicleProfile?.id !== this.props.vehicleProfile?.id) {
            this.setState({
                selectValue: this.getSelectValue()
            });
        }

        if (prevProps.vehicleProfile?.costPerKm?.cost !== this.props.vehicleProfile?.costPerKm?.cost) {
            this.setState({
                costPerKm: this.getCostPerKm()
            });
        }
    }

    getSelectValue = () => {
        if (this.props.profiles.length || this.props.vehicles.length) {
            return `${this.props.vehicleProfile?.type === VehicleProfileType.Custom ? 'vehicle-' : 'profile-'}${
                this.props.vehicleProfile?.id
            }`;
        }

        return DEFAULT_VEHICLE_PROFILE;
    };

    getCostPerKm = () =>
        this.props.vehicleProfile?.costPerKm || {
            cost: TOLL_COST_DEFAULT_VALUE,
            currency: AvailableCurrencies.EUR
        };

    render() {
        const { t, vehicles, profiles, currency } = this.props;

        return (
            <div className="rl-planner-vehicle">
                <Row align="middle" gutter={[16, 8]} className="rl-planner-vehicle-header">
                    <Col span={16}>
                        <label htmlFor={'rl-vehicle'}>{t('PlannerVehicle.title')}</label>
                        <Tooltip.Icon title={t('PlannerVehicle.vehicleTooltip')} placement="right" />
                    </Col>
                    <Col span={8}>
                        <label htmlFor={'rl-cost'}>{t('PlannerVehicle.cost', { currency })}</label>
                        <Tooltip.Icon title={t('PlannerVehicle.costTooltip')} placement="right" />
                    </Col>
                </Row>

                <Row align="middle" gutter={[16, 8]}>
                    <Col span={16}>
                        <Select<State['selectValue']>
                            value={this.state.selectValue}
                            onSelect={this._onSelect}
                            dropdownClassName="rl-select-dropdown"
                            disabled={this.props.loading}
                            open={this.state.dropdownOpen}
                            listHeight={this.props.isMobileWidth ? 0 : 256}
                            onDropdownVisibleChange={open => {
                                this.setState({ dropdownOpen: open });
                            }}
                            dropdownRender={dropdownNode => (
                                <div className="rl-select-dropdown-node">
                                    <div className="rl-select-dropdown-node-header">
                                        <Button
                                            onClick={() => this.setState({ dropdownOpen: false })}
                                            type="action"
                                            icon={<i className="rl-icon-chevron-left" />}
                                        />

                                        <h4>{t('PlannerVehicle.selectTitle')}</h4>
                                    </div>
                                    {dropdownNode}
                                </div>
                            )}
                        >
                            {vehicles.length > 0 && (
                                <Select.OptGroup label={t('routing.configurator.select.vehicles')}>
                                    {vehicles.map(vehicle => (
                                        <Select.Option
                                            className="rl-planner-vehicle-option"
                                            title={t('routing.configurator.vehicleAvailability.available')}
                                            key={`vehicle-${String(vehicle?.vehicleId)}`}
                                            value={`vehicle-${String(vehicle?.vehicleId)}`}
                                        >
                                            <span>{vehicle.registrationNumber}</span>
                                        </Select.Option>
                                    ))}
                                </Select.OptGroup>
                            )}
                            <Select.Option
                                value="newProfile"
                                className="rl-vehicle-new"
                                data-qa={qa.schedulingPlanning.vehicleProfile.createProfile}
                            >
                                <span>{t('routing.configurator.select.newProfile')}</span>
                            </Select.Option>
                            {profiles.length > 0 && (
                                <Select.OptGroup label={t('routing.configurator.select.profiles')}>
                                    {profiles.map(profile => (
                                        <Select.Option
                                            className="rl-planner-vehicle-option"
                                            key={`profile-${String(profile.vehicleProfileId)}`}
                                            value={`profile-${String(profile.vehicleProfileId)}`}
                                            data-qa={qa.schedulingPlanning.vehicleProfile.profile}
                                        >
                                            <span>{profile.name}</span>
                                            <div
                                                onClick={e => {
                                                    e.stopPropagation();
                                                }}
                                            >
                                                <Tooltip.Icon title={<VehicleTooltip vehicle={profile} />} />
                                            </div>
                                        </Select.Option>
                                    ))}
                                </Select.OptGroup>
                            )}
                        </Select>
                    </Col>
                    <Col span={8}>
                        <Input.Number
                            min={0}
                            step={0.1}
                            precision={2}
                            className={'rl-input'}
                            onChange={this._onCostPerKmChange}
                            value={this.state.costPerKm.cost}
                            disabled={this.props.loading}
                            onKeyDown={PlannerVehicle._onBlurAfterPressEnter}
                        />
                    </Col>
                </Row>
            </div>
        );
    }

    private _onCostPerKmChange = (value: number | string | null) => {
        const valueValid = !isNaN(Number(value)) && Number(value) > 0;
        this.setState(
            state => ({
                costPerKm: {
                    ...state.costPerKm,
                    cost: valueValid ? Number(value) : state.costPerKm.cost
                }
            }),
            () => {
                this.props.onCostPerKmChange?.(this.state.costPerKm);
            }
        );
    };

    private _onSelect = (value: string) => {
        if (value.startsWith('profile-')) {
            const profile = this.props.profiles.find(p => String(p.vehicleProfileId) === value.replace('profile-', ''));
            this.setState(
                state => ({
                    selectValue: value,
                    costPerKm: {
                        ...state.costPerKm,
                        cost: Number(profile?.costPerKm ?? TOLL_COST_DEFAULT_VALUE)
                    }
                }),
                () => {
                    this.props.onVehicleChange?.({
                        id: Number(value.replace('profile-', '')),
                        name: profile?.name,
                        type: VehicleProfileType.Default,
                        costPerKm: this.state.costPerKm
                    });
                }
            );
        } else {
            const vehicle = this.props.vehicles.find(p => String(p?.vehicleId) === value.replace('vehicle-', ''));
            if (value.startsWith('vehicle-')) {
                this.setState(
                    state => ({
                        selectValue: value,
                        costPerKm: {
                            ...state.costPerKm,
                            cost: Number(vehicle?.costPerKm ?? TOLL_COST_DEFAULT_VALUE)
                        }
                    }),
                    () => {
                        this.props.onVehicleChange?.({
                            id: Number(value.replace('vehicle-', '')),
                            name: vehicle?.registrationNumber,
                            type: VehicleProfileType.Custom,
                            costPerKm: this.state.costPerKm
                        });
                    }
                );
            } else {
                if (value === 'newProfile') {
                    this.props.onCreateProfileClick?.();
                }
            }
        }
    };

    private static _onBlurAfterPressEnter(e: React.KeyboardEvent<HTMLInputElement>) {
        if (e.key === 'Enter') {
            e.currentTarget.blur();
        }
    }
}

export default withTranslation()(PlannerVehicle);
