import cn from 'classnames';
import { PoiModel } from 'logic/poi';
import { PlacesModel } from 'modules/PlannerModule/PlannerModule';
import { TrackingModel } from 'modules/TrackingModule/TrackingModule';
import { PoiModelMap } from 'logic/map/logic/fuelStations';
import { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Logic } from 'logic/logic';
import ContextMenu from './components/contextMenu/ContextMenu';
import { ControlPanel } from './components/MapControlsBar';
import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from 'mapbox-gl';
import { withTranslation, WithTranslation } from 'react-i18next';
import Drawer from 'components/base/layout/Drawer/Drawer';
import { Notification } from 'components/base/layout';
import { RouteNames } from 'router/routes';
import { PoiDetail, PoiDetailModel } from './ui/PoiDetail/PoiDetail';
import { Button, Spin } from '../../components/base/controls';
import { MapControls } from './ui/MapControls';
import CreateNewPlaceModule from '../CreateNewPlaceModule/CreateNewPlaceModule';
import { Subject, takeUntil } from 'rxjs';
import { BarItems } from './ui/MapControls/ui/MapControlsBar';
import { FavoriteType } from 'services/api/domains/FavoritesApi';
import { InitiatedFrom } from 'logic/userEventsProviders/GoogleTagManager';
import { inject, observer } from 'mobx-react';
import { AuthStore } from 'stores/auth/AuthStore';
import { Stores } from 'stores/RootStore';
import { PlaceType } from 'services/api/domains/PlacesApi';
import { MapPlaceType } from 'logic/map/map';
import { TransportModel } from 'logic/dispatcher-board';
import { Location } from 'types';
import PoiOverview from './ui/PoiOverview/PoiOverview';
import { isMobileDevice } from 'utils/helpers/navigator';

export enum DisplayTypes {
    DO_NOT_DISPLAY = 'doNotDisplay',
    ON_THE_ENTIRE_MAP = 'onTheEntireMap',
    ON_ROUTE = 'onRoute'
}

export enum DataSourceTypes {
    ALL = 'all',
    EUROWAG = 'eurowag',
    IMPORTED = 'imported'
}

export enum LayerTypes {
    ROADMAP = 'roadmap',
    HYBRID = 'hybrid',
    TERRAIN = 'terrain'
}

export interface Layers {
    roadmap: boolean;
    hybrid: boolean;
    terrain: boolean;
    traffic: boolean;
    postalCodes: boolean;
    restrictions: boolean;
    favorites: boolean;
    places: {
        company: boolean;
        services: boolean;
        shop: boolean;
    };
}

export interface Services {
    // toll?: boolean;
    // wifi?: boolean;
    // market?: boolean;
    // bar?: boolean;
    wc?: boolean;
    shower?: boolean;
    food?: boolean;
    carWash?: boolean;
    special?: boolean;
}

export interface Displayed {
    doNotDisplay?: boolean;
    onTheEntireMap?: boolean;
    onRoute?: boolean;
}

export interface Fuels {
    // diesel1?: boolean;
    // diesel2?: boolean;

    diesel?: boolean;
    biodiesel?: boolean;
    adblue?: boolean;
    natural95?: boolean;
    natural98?: boolean;
    cng?: boolean;
    electro?: boolean;
}

export interface FuelControlsForm {
    subOptionsData?: DataSourceTypes;
    serviceData: Services;
    fuelData: Fuels;
}

export interface ParkingControlsForm {
    subOptionsData?: DataSourceTypes;
    serviceData: Services;
}

export const defaultFuelServiceFilter: Services = {
    // adBlue: false,
    // bar: false,
    // market: false,
    // toll: false,
    // wifi: false
    wc: false,
    shower: false,
    food: false,
    carWash: false,
    special: false
};

const defaultDisplay: Layers = {
    roadmap: false,
    hybrid: false,
    terrain: false,
    favorites: true,
    traffic: false,
    postalCodes: false,
    restrictions: true,
    places: {
        company: true,
        services: true,
        shop: true
    }
};

export const defaultFuelsFilter: Fuels = {
    // diesel1: false,
    // diesel2: false,
    diesel: false,
    biodiesel: false,
    adblue: false,
    natural95: false,
    natural98: false,
    cng: false,
    electro: false
};

interface Props extends RouteComponentProps<any>, WithTranslation {
    authStore?: AuthStore;
    logic: Logic;
    isLoggedIn?: boolean;
}

interface State {
    vehicle?: TrackingModel;
    transport?: TransportModel;
    toggle: {
        contextMenu?: boolean;
    };
    parking: {
        optionsData: Displayed;
        subOptionsData?: DataSourceTypes;
        serviceData: Services;
        parkings: PoiModelMap[];
        filterOpen: boolean;
        loading: boolean;
    };
    fuel: {
        optionsData: Displayed;
        subOptionsData?: DataSourceTypes;
        serviceData: Services;
        fuelData: Fuels;
        fuelStations: PoiModelMap[];
        filterOpen: boolean;
        loading: boolean;
    };
    places: {
        optionsData: Displayed;
        subOptionsData?: DataSourceTypes;
        serviceData: Services;
        places: PoiModelMap[];
        filterOpen: boolean;
        loading: boolean;
    };
    barMenu?: {
        panel: ControlPanel;
    };
    controlOnRoute: boolean;
    layer: { display: Layers };
    poiBarVisible: boolean;
    poiCoords?: {
        lat: number;
        lng: number;
    };
    poi?: {
        model: PoiModel;
        modelEdited?: PoiModel;
    };
    contextMenu: {
        position?: {
            x: number;
            y: number;
        };
    };
    mapBoxContextMenu?: {
        isVisible: boolean;
        position?: {
            x: number;
            y: number;
        };
        point?: {
            lng: number;
            lat: number;
        };
        feature?: GeoJSON.Feature;
        favoriteFormVisible?: boolean;
    };
    layersInitialized: boolean;
    isRouteCalculated: boolean;
    clickedPois: PoiDetailModel[];
    poiOverviewVisible: boolean;
    selectedMapControl: BarItems | null;
    poiDetailLoading: boolean;
}

@inject(Stores.AUTH_STORE)
@observer
class MapModule extends Component<Props, State> {
    private _logic: Logic;
    private _destroySignal$ = new Subject<void>();

    constructor(props: Props) {
        super(props);

        this._logic = props.logic;

        this.state = {
            toggle: {
                contextMenu: false
            },
            fuel: {
                fuelData: defaultFuelsFilter,
                optionsData: {
                    doNotDisplay: true,
                    onTheEntireMap: true,
                    onRoute: false
                },
                serviceData: defaultFuelServiceFilter,
                fuelStations: [],
                filterOpen: true,
                loading: true
            },
            parking: {
                optionsData: {
                    doNotDisplay: true,
                    onTheEntireMap: true,
                    onRoute: false
                },
                parkings: [],
                serviceData: defaultFuelServiceFilter,
                filterOpen: true,
                loading: true
            },
            places: {
                optionsData: {
                    doNotDisplay: true,
                    onTheEntireMap: true,
                    onRoute: false
                },
                places: [],
                serviceData: defaultFuelServiceFilter,
                filterOpen: true,
                loading: true
            },
            controlOnRoute: false,
            poiBarVisible: false,
            layer: {
                display: {
                    roadmap: true,
                    hybrid: false,
                    terrain: false,
                    favorites: !!this.props.authStore?.isLoggedIn,
                    traffic: false,
                    postalCodes: false,
                    restrictions: true,
                    places: {
                        company: true,
                        services: true,
                        shop: true
                    }
                }
            },
            contextMenu: {},
            layersInitialized: false,
            isRouteCalculated: false,
            clickedPois: [],
            selectedMapControl: null,
            poiDetailLoading: false,
            poiOverviewVisible: false
        };
    }

    componentDidMount() {
        this._logic.map().onLoading(value => {
            this.setState(state => ({
                fuel: {
                    ...state.fuel,
                    loading: value
                },
                parking: {
                    ...state.parking,
                    loading: value
                }
            }));
        });

        this._logic.map().onLayersInit(() => {
            this.setState(
                {
                    layersInitialized: true
                },
                () => {
                    this._onFuelOptionsConfirm();
                    this._onParkingOptionsConfirm();
                    this._logic.tracking().updateMap();
                }
            );
        });

        this._logic
            .map()
            .sidebarsVisible$.pipe(takeUntil(this._destroySignal$))
            .subscribe(visible => {
                if (!visible) {
                    if (this.state.poiBarVisible) {
                        this._onPoiFormClose();
                    }

                    if (this.state.clickedPois?.length) {
                        this._closePoiDetail();
                    }
                }
            });

        this._logic.schedulingRoutePlanner().onRouteCancel(() => {
            const parkingsVisible = this._logic.map().parkings().visible;
            const fuelStationsVisible = this._logic.map().fuelStations().visible;

            this.setState(
                state => ({
                    parking: {
                        ...state.parking,
                        optionsData: {
                            doNotDisplay: !parkingsVisible,
                            onTheEntireMap: parkingsVisible,
                            onRoute: false
                        }
                    },
                    fuel: {
                        ...state.fuel,
                        optionsData: {
                            doNotDisplay: !fuelStationsVisible,
                            onTheEntireMap: fuelStationsVisible,
                            onRoute: false
                        }
                    }
                }),
                () => {
                    this._onFuelOptionsConfirm();
                    this._onParkingOptionsConfirm();
                }
            );
        });

        this._logic.map().onPoiToggle(value => {
            this.setState(state => ({
                poiBarVisible: value ? value : false,
                poi: value ? state.poi : undefined
            }));
        });

        this._logic.map().onFuelControlToggle(value => {
            if (value) {
                this._logic.map().setCurrentSideBarControl(ControlPanel.FUEL);
                this.setState({
                    barMenu: { panel: ControlPanel.FUEL }
                });
            }
        });

        this._logic.map().onParkingControlToggle(value => {
            if (value) {
                this._logic.map().setCurrentSideBarControl(ControlPanel.PARKING);
                this.setState({
                    barMenu: { panel: ControlPanel.PARKING }
                });
            }
        });

        this._logic.map().onLayerControlToggle(value => {
            if (value) {
                this._logic.map().setCurrentSideBarControl(ControlPanel.LAYER);
                this.setState({
                    barMenu: { panel: ControlPanel.LAYER }
                });
            }
        });

        this._logic.map().onControlsOff((control?: ControlPanel) => {
            if (!control || control === this.state.barMenu?.panel) {
                this.setState({ barMenu: undefined });
            }
        });

        this._logic.map().onControlsOffResetState(() => {
            this.setState(state => ({
                controlOnRoute: state.controlOnRoute,
                vehicle: undefined,
                parking: {
                    ...state.parking,
                    optionsData: {
                        doNotDisplay: state.controlOnRoute ? false : true,
                        onTheEntireMap: true,
                        onRoute: false
                    },
                    subOptionsData: undefined,
                    parkings: []
                },
                fuel: {
                    ...state.fuel,
                    optionsData: {
                        doNotDisplay: state.controlOnRoute ? false : true,
                        onTheEntireMap: true,
                        onRoute: false
                    },
                    subOptionsData: undefined,
                    fuelStations: []
                },
                poiBarVisible: false,
                poi: undefined
            }));
        });

        this._logic.map().onResetPoisData(() => {
            this.setState(state => ({
                parking: {
                    ...state.parking,
                    parkings: []
                },
                fuel: {
                    ...state.fuel,
                    fuelStations: []
                }
            }));
        });

        this._logic.map().onRoutePlanning(value => {
            const parkingsVisible = this._logic.map().parkings().visible;
            const fuelStationsVisible = this._logic.map().fuelStations().visible;

            if (value !== undefined) {
                this.setState(
                    state => ({
                        controlOnRoute: value,
                        parking: {
                            ...state.parking,
                            optionsData: {
                                doNotDisplay: !parkingsVisible,
                                onTheEntireMap: parkingsVisible,
                                onRoute: value
                            },
                            subOptionsData: value ? DataSourceTypes.EUROWAG : undefined,
                            parkings: []
                        },
                        fuel: {
                            ...state.fuel,
                            optionsData: {
                                doNotDisplay: !fuelStationsVisible,
                                onTheEntireMap: fuelStationsVisible,
                                onRoute: value
                            },
                            subOptionsData: value ? DataSourceTypes.EUROWAG : undefined,
                            fuelStations: []
                        }
                    }),
                    () => {
                        if (this.state.layersInitialized) {
                            this._onFuelOptionsConfirm();
                            this._onParkingOptionsConfirm();
                        }
                    }
                );
            } else {
                this.setState(
                    state => ({
                        controlOnRoute: !state.controlOnRoute,
                        parking: {
                            ...state.parking,
                            optionsData: {
                                doNotDisplay: true,
                                onTheEntireMap: false,
                                onRoute: false
                            },
                            subOptionsData: undefined,
                            parkings: []
                        },
                        fuel: {
                            ...state.fuel,
                            optionsData: {
                                doNotDisplay: true,
                                onTheEntireMap: false,
                                onRoute: false
                            },
                            subOptionsData: undefined,
                            fuelStations: []
                        }
                    }),
                    () => {
                        if (this.state.layersInitialized) {
                            this._onFuelOptionsConfirm();
                            this._onParkingOptionsConfirm();
                        }
                    }
                );
            }
        });

        this._logic.schedulingRoutePlanner().onRemovePlaceFromTransport((place: PlacesModel) => {
            this.setState(state => {
                const parking = state.parking.parkings.filter(
                    p => p.position?.lat === place.center?.lat && p.position?.lng === place.center?.lng
                )[0];
                const fuelStation = state.fuel.fuelStations.filter(
                    f => f.position?.lat === place.center?.lat && f.position?.lng === place.center?.lng
                )[0];
                return {
                    parking: {
                        ...state.parking,
                        parkings: parking
                            ? [
                                  ...state.parking.parkings.filter(
                                      p =>
                                          p.position?.lat !== place.center?.lat || p.position?.lng !== place.center?.lng
                                  ),
                                  {
                                      ...parking,
                                      inTransport: false,
                                      selected: true
                                  }
                              ]
                            : state.parking.parkings
                    },
                    fuel: {
                        ...state.fuel,
                        fuelStations: fuelStation
                            ? [
                                  ...state.fuel.fuelStations.filter(
                                      f => f.position.lat !== place.center.lat || f.position.lng !== place.center.lng
                                  ),
                                  {
                                      ...fuelStation,
                                      inTransport: false,
                                      selected: true
                                  }
                              ]
                            : state.fuel.fuelStations
                    }
                };
            });
        });

        this._logic.map().init(document.getElementById('map')!);

        this._logic.map().onContextMenu(this._onMapboxContextMenu);

        this._logic
            .map()
            .fuelStations()
            .visibleChanged$.subscribe(isVisible => {
                this.setState(state => ({
                    //subOptionsData is flag in ControlsFuel.tsx
                    fuel: { ...state.fuel, subOptionsData: isVisible ? DataSourceTypes.EUROWAG : undefined }
                }));
            });

        this._logic
            .map()
            .parkings()
            .visibleChanged$.subscribe(isVisible => {
                this.setState(state => ({
                    //subOptionsData is flag in ControlsParking.tsx
                    parking: { ...state.parking, subOptionsData: isVisible ? DataSourceTypes.EUROWAG : undefined }
                }));
            });

        this._logic
            .poi()
            .parkingsLoaded$.pipe(takeUntil(this._destroySignal$))
            .subscribe(() => {
                this._onParkingOptionsConfirm();
            });

        this._logic
            .poi()
            .fuelStationsLoaded$.pipe(takeUntil(this._destroySignal$))
            .subscribe(() => {
                this._onFuelOptionsConfirm();
            });

        this._logic
            .poi()
            .favoritesLoaded$.pipe(takeUntil(this._destroySignal$))
            .subscribe(() => {
                this._onFuelOptionsConfirm();
                this._onParkingOptionsConfirm();
            });

        this._logic.schedulingRoutePlanner().routeOnMap$.subscribe(value => {
            const parkingsVisible = this._logic.map().parkings().visible;
            const fuelStationsVisible = this._logic.map().fuelStations().visible;

            this.setState(state => ({
                isRouteCalculated: value,
                parking: {
                    ...state.parking,
                    optionsData: {
                        doNotDisplay: !parkingsVisible,
                        onTheEntireMap: !value && parkingsVisible,
                        onRoute: value
                    }
                },
                fuel: {
                    ...state.fuel,
                    optionsData: {
                        doNotDisplay: !fuelStationsVisible,
                        onTheEntireMap: !value && fuelStationsVisible,
                        onRoute: value
                    }
                }
            }));
        });

        this._logic.map().places().onClick(this._onPlaceClick);

        this._logic
            .map()
            .showPoiDetail$.pipe(takeUntil(this._destroySignal$))
            .subscribe(poiId => {
                this._onShowDetailClick(poiId);
            });
    }

    componentDidUpdate(prevProps: Readonly<Props>) {
        if (prevProps.isLoggedIn !== this.props.isLoggedIn) {
            if (!this.props.isLoggedIn) {
                this.setState(
                    state => ({
                        layer: {
                            ...state.layer,
                            display: {
                                ...defaultDisplay,
                                favorites: false
                            }
                        }
                    }),
                    () => {
                        this._logic.map().places().clearCache();
                        this._logic.map().places().refreshPlaces();
                    }
                );
            } else {
                this.setState(
                    state => ({
                        layer: {
                            ...state.layer,
                            display: {
                                ...state.layer.display,
                                favorites: !!this.props.isLoggedIn
                            }
                        }
                    }),
                    () => {
                        this._logic.map().showFavoritesLayer(!!this.props.isLoggedIn);
                        this._logic.map().places().clearCache();
                        this._logic.map().places().refreshPlaces();
                    }
                );
            }
        }

        if (this.props.location !== prevProps.location) {
            // reset states
            this._closePoiDetail();
            this._logic.map().poiOff();
            this._logic.map().poi().destroy();
        }
    }

    componentWillUnmount() {
        this._destroySignal$.next();
        this._logic.map().destroyRoute();
    }

    render() {
        const mapWithPanel = [ControlPanel.VEHICLE, ControlPanel.FUEL, ControlPanel.LAYER, ControlPanel.PARKING].some(
            c => c === this.state.barMenu?.panel
        );
        const mapWithPoiPanel = this.state.poiBarVisible;

        return (
            <>
                <div
                    id="map"
                    className={cn({
                        mapWithPanel: mapWithPanel,
                        mapWithoutPanel: !mapWithPanel,
                        mapWithPoiPanel
                    })}
                    onClick={this._onMapClick}
                />

                {this.state.mapBoxContextMenu?.isVisible && (
                    <ContextMenu
                        position={this.state.mapBoxContextMenu.position}
                        isFavoritePlace={!!this.state.mapBoxContextMenu?.feature?.properties?.isFavorite}
                        showPlanningItems={this.props.location.pathname === RouteNames.SCHEDULING_PLANNER}
                        isClickToFeature={!!this.state.mapBoxContextMenu.feature}
                        isRouteCalculated={this.state.isRouteCalculated}
                        onClickOutside={this.clickOutside}
                        onCreatePoi={this._onMapContextMenuCreatePlace}
                        onAddStart={this._onMapContextMenuAddStart}
                        onAddDestination={this._onMapContextMenuAddDestination}
                        onAddFavorite={this._onMapContextMenuAddFavorite}
                        onDeleteFavorite={this._onMapContextMenuDeleteFavorite}
                        onAddToRoute={this._onMapContextMenuAddToRoute}
                    />
                )}

                <CreateNewPlaceModule
                    logic={this.props.logic}
                    visible={this.state.poiBarVisible}
                    mask={false}
                    coords={this.state.poiCoords}
                    onClose={this._onPoiFormClose}
                    onAfterSubmit={this._onPoiFormClose}
                    onAfterCreate={this._afterNewPoiCreate}
                />

                <MapControls
                    selectedControl={this.state.selectedMapControl}
                    fuelStationsLayerVisible={this.state.fuel.subOptionsData === DataSourceTypes.EUROWAG}
                    parkingsLayerVisible={this.state.parking.subOptionsData === DataSourceTypes.EUROWAG}
                    mapSettingsValues={this.state.layer}
                    onTrafficChange={this._onLayersTrafficChange}
                    onFavoritesChange={this._onFavoritesChange}
                    onPostalCodesChange={this._onPostalCodesChange}
                    onRestrictionsChange={this._onRestrictionsChange}
                    onPlacesChange={this._onPlacesChange}
                    onParkingsToggle={this._toggleParkings}
                    onFuelStationsToggle={this._toggleFuelStations}
                    onBarItemClick={this._onMapControlsItemClick}
                    onSidebarClose={this._closeMapControlsSidebar}
                />

                <Drawer
                    visible={!this.state.poiOverviewVisible && this.state.clickedPois.length > 0}
                    mask={false}
                    destroyOnClose
                    className="rl-map-drawer rl-map-drawer-poi-detail"
                    onClose={this._closePoiDetail}
                    title={this.props.t('common.poiDetail')}
                >
                    <Spin
                        loading={this.state.poiDetailLoading}
                        tip={this.props.t('common.loading')}
                        opacity={this.state.clickedPois.length > 0}
                    >
                        <PoiDetail
                            pois={this.state.clickedPois}
                            onRecentPoiClick={this._onRecentPoiClick}
                            onDeleteRecentPoi={this._onDeleteRecentPoi}
                            onAddToRoute={this._onAddToRoute}
                            onAddFavorite={this._onPoiDetailAddFavorite}
                            onDeleteFavorite={this._onPoiDetailDeleteFavorite}
                            onClose={this._closePoiDetail}
                        />
                    </Spin>
                </Drawer>

                {this.state.poiOverviewVisible && this.state.clickedPois.length > 0 && (
                    <Spin loading={this.state.poiDetailLoading} tip={this.props.t('common.loading')}>
                        <PoiOverview
                            address={this.state.clickedPois[0].address}
                            category={this.state.clickedPois[0].category!}
                            isFavorite={this.state.clickedPois[0].isFavorite}
                            name={this.state.clickedPois[0].name}
                            poiId={this.state.clickedPois[0].id}
                            logoUrl={this.state.clickedPois[0].brand?.logoUrl}
                            rating={this.state.clickedPois[0].rating}
                            onPoiDetailClick={this._onShowDetailClick}
                            onClose={this._onClosePoiOverview}
                        />
                    </Spin>
                )}
            </>
        );
    }

    private _onMapControlsItemClick = (clickedItem: BarItems) => {
        this.setState({ selectedMapControl: clickedItem });
    };

    private _closeMapControlsSidebar = () => {
        this.setState({
            selectedMapControl: null
        });
    };

    private _onPoiFormClose = () => {
        this.setState({
            poiBarVisible: false,
            poiCoords: undefined,
            poi: undefined
        });

        this._logic.map().poiOff();
        this._logic.map().poi().destroy();
    };

    private _afterNewPoiCreate = (category: number, brand: string) => {
        const initiatedFrom =
            this.props.location.pathname === RouteNames.SCHEDULING_PLANNER
                ? InitiatedFrom.PLANNER_MAP_RMB
                : InitiatedFrom.SETTINGS_FAVORITES_MAP_RMB;
        this.props.logic.userEvents().addNewPlace(category, brand, initiatedFrom);
    };

    private _onMapClick = () => {
        this.setState(state => ({
            toggle: {
                ...state.toggle,
                contextMenu: false
            },
            mapBoxContextMenu: {
                isVisible: false
            }
        }));
    };

    private _onLayersTrafficChange = (checked: boolean): void => {
        this.setState(
            state => ({
                layer: {
                    ...state.layer,
                    display: { ...state.layer.display, traffic: checked }
                }
            }),
            () => this._logic.map().traffic(checked)
        );
    };

    private _onPostalCodesChange = (checked: boolean): void => {
        this.setState(
            state => ({
                layer: {
                    ...state.layer,
                    display: { ...state.layer.display, postalCodes: checked }
                }
            }),
            () => this._logic.map().postalCodes(checked)
        );
    };

    private _onRestrictionsChange = (checked: boolean): void => {
        this.setState(
            state => ({
                layer: {
                    ...state.layer,
                    display: { ...state.layer.display, restrictions: checked }
                }
            }),
            () => this._logic.map().restrictions(checked)
        );
    };

    private _onFuelOptionsConfirmClick = (form: FuelControlsForm): void => {
        this.setState(
            state => {
                return {
                    fuel: {
                        ...state.fuel,
                        optionsData: {
                            doNotDisplay: !form.subOptionsData,
                            onRoute: state.isRouteCalculated && !!form.subOptionsData,
                            onTheEntireMap: !state.isRouteCalculated && !!form.subOptionsData
                        },
                        subOptionsData: form.subOptionsData,
                        serviceData: form.serviceData,
                        fuelData: form.fuelData
                    }
                };
            },
            () => {
                this._onFuelOptionsConfirm();
            }
        );
    };

    private _onFuelStationClick = async (fuelStation: PoiModelMap, fit?: boolean) => {
        const optionsData = this.state.fuel.optionsData;
        const fuelStationDetail = this._logic.poi().fuelStationDetail(fuelStation.id);

        if (fuelStationDetail) {
            fuelStationDetail.inTransport = fuelStation.inTransport;

            if (optionsData.onRoute || this.state.isRouteCalculated) {
                fuelStationDetail.routeIndex = fuelStation.routeIndex;
            }

            this._showPoiDetail(fuelStationDetail);

            if (fit) {
                this._logic.map().jumpTo({ lat: fuelStationDetail.position.lat, lng: fuelStationDetail.position.lng });
            }
        }
    };

    private _onFuelOptionsConfirm = (): void => {
        if (this.state.fuel.optionsData.onTheEntireMap) {
            this._logic.map().fuelStations().onClick(this._onFuelStationClick);
            this._logic.map().routing().hideFuelStations();
            this._logic.map().routing().setFuelStationsRender(true);

            this._logic
                .map()
                .fuelStations()
                .setData(this._logic.poi().filterFuelStations(this.state.fuel.fuelData, this.state.fuel.serviceData));
            this._logic.map().fuelStations().show();
        } else {
            if (this.state.fuel.optionsData.onRoute) {
                this._logic.map().routing().onFuelStationClick(this._onFuelStationClick);
                this._logic.map().routing().showFuelStations();
                this._logic.map().routing().setFuelStationsRender(true);
            } else {
                if (this.state.fuel.optionsData.doNotDisplay) {
                    this._logic.map().fuelStations().hide();
                    this._logic.map().fuelStations().onClick(undefined);
                    this._logic.map().routing().hideFuelStations();
                    this._logic.map().routing().onFuelStationClick(undefined);
                    this._logic.map().routing().setFuelStationsRender(false);
                    this._logic.poi().unselectFuelStations();
                    this.setState(state => ({
                        fuel: {
                            ...state.fuel,
                            fuelStations: []
                        }
                    }));
                }
            }
        }
    };

    private _toggleParkings = () => {
        this._onParkingOptionsConfirmClick({
            subOptionsData:
                this.state.parking.subOptionsData === DataSourceTypes.EUROWAG ? undefined : DataSourceTypes.EUROWAG,
            serviceData: this.state.parking.serviceData
        });
    };

    private _toggleFuelStations = () => {
        this._onFuelOptionsConfirmClick({
            subOptionsData:
                this.state.fuel.subOptionsData === DataSourceTypes.EUROWAG ? undefined : DataSourceTypes.EUROWAG,
            serviceData: this.state.fuel.serviceData,
            fuelData: this.state.fuel.fuelData
        });
    };

    private _onParkingOptionsConfirmClick = (form: ParkingControlsForm): void => {
        this.setState(
            state => {
                return {
                    parking: {
                        ...state.parking,
                        optionsData: {
                            doNotDisplay: !form.subOptionsData,
                            onRoute: state.isRouteCalculated && !!form.subOptionsData,
                            onTheEntireMap: !!form.subOptionsData && !state.isRouteCalculated
                        },
                        subOptionsData: form.subOptionsData,
                        serviceData: form.serviceData
                    }
                };
            },
            () => {
                this._onParkingOptionsConfirm();
            }
        );
    };

    private _onParkingClick = (parkingPoi: PoiModelMap, fit?: boolean): void => {
        const { routeIndex } = parkingPoi;
        const optionsData = this.state.parking.optionsData;

        const parkingDetail = this._logic.poi().parkingDetail(parkingPoi.id);

        if (parkingDetail) {
            if (optionsData.onTheEntireMap) {
                this._logic.tracking().centerOffVehicles();
            }

            if (optionsData.onRoute || this.state.isRouteCalculated) {
                parkingDetail.routeIndex = routeIndex;
            }

            this._showPoiDetail(parkingDetail);

            if (fit) {
                this._logic.map().jumpTo({ lat: parkingDetail.position.lat, lng: parkingDetail.position.lng });
            }
        }

        const filtered = this.state.parking.parkings!.filter(parking => {
            return parking.id === parkingPoi.id;
        });
        if (filtered!.length === 0) {
            const pss =
                this.state.parking.parkings!.length > 0 ? [parkingPoi, ...this.state.parking.parkings!] : [parkingPoi];

            this.setState(state => ({
                parking: {
                    ...state.parking,
                    parkings: pss
                }
            }));
        }
    };

    private _onParkingOptionsConfirm = () => {
        if (this.state.parking.optionsData.onTheEntireMap) {
            this._logic.map().parkings().onClick(this._onParkingClick);
            this._logic.map().routing().hideParkings();
            this._logic.map().routing().setParkingRender(true);

            this._logic
                .map()
                .parkings()
                .setData(this._logic.poi().getParkings() ?? []);
            this._logic.map().parkings().show();
        } else {
            if (this.state.parking.optionsData.onRoute) {
                this._logic.map().routing().onParkingClick(this._onParkingClick);
                this._logic.map().routing().showParkings();
                this._logic.map().routing().setParkingRender(true);
            } else {
                if (this.state.parking.optionsData.doNotDisplay) {
                    this._logic.map().parkings().hide();
                    this._logic.map().parkings().onClick(undefined);
                    this._logic.map().routing().hideParkings();
                    this._logic.map().routing().onParkingClick(undefined);
                    this._logic.map().routing().setParkingRender(false);
                    this.setState(state => ({
                        parking: {
                            ...state.parking,
                            parkings: []
                        }
                    }));
                }
            }
        }
    };

    private _onMapboxContextMenu = (e: mapboxgl.MapMouseEvent | mapboxgl.MapTouchEvent, feature?: GeoJSON.Feature) => {
        if (!this.state.poi) {
            this.setState({
                mapBoxContextMenu: {
                    isVisible: true,
                    position: {
                        x: e.point.x + 10, // add offset to avoid unintentional menu item click on map long press
                        y: e.point.y
                    },
                    point: {
                        lng: e.lngLat.lng,
                        lat: e.lngLat.lat
                    },
                    feature
                }
            });
        }
    };

    private _onMapContextMenuCreatePlace = () => {
        if (!this.props.isLoggedIn) {
            this.props.history.push({ search: '?login=true' });
            return;
        }

        this.setState(
            {
                ...this.state,
                poiBarVisible: true,
                poiCoords: {
                    lat: this.state.mapBoxContextMenu?.point?.lat!,
                    lng: this.state.mapBoxContextMenu?.point?.lng!
                },
                mapBoxContextMenu: {
                    ...this.state.mapBoxContextMenu,
                    isVisible: false
                }
            },
            () => this._closePoiDetail()
        );
    };

    private _onMapContextMenuAddStart = async () => {
        const point = this.state.mapBoxContextMenu?.point!;
        const address = await this._logic.geocoding().geocodeLatLng(point);

        this._logic.schedulingRoutePlanner().addPlaceToTransport(address, point, MapPlaceType.Waypoint, 0);

        this._toggleMapBoxContextMenu();
    };

    private _onMapContextMenuAddDestination = async () => {
        const point = this.state.mapBoxContextMenu?.point!;
        const address = await this._logic.geocoding().geocodeLatLng(point);

        this._logic.schedulingRoutePlanner().addPlaceToTransport(address, point, MapPlaceType.Waypoint);

        this._toggleMapBoxContextMenu();
    };

    private _onMapContextMenuAddToRoute = async () => {
        const point = this.state.mapBoxContextMenu?.point!;
        const address = await this._logic.geocoding().geocodeLatLng(point);
        const index = this._logic.schedulingRoutePlanner().getWaypointRouteIndex(point);
        this._logic.schedulingRoutePlanner().addPlaceToTransport(address, point, MapPlaceType.Waypoint, index);

        this._toggleMapBoxContextMenu();
    };

    private _onMapContextMenuAddFavorite = async () => {
        const feature = this.state.mapBoxContextMenu?.feature;
        const place = feature?.properties;
        const [lon, lat] = (feature?.geometry as GeoJSON.Point).coordinates;

        if (place) {
            const placeDetail = await this._logic.apiService().places().getPlaceDetail(place.id);
            const name =
                place.category === PlaceType.GAS_STATION && placeDetail?.brand ? placeDetail.brand.name : place.name;

            await this._addFavorite(place.id, place.category, name, place.isFavorite, { lon, lat });

            this.props.logic
                .userEvents()
                .addFavorite(place.category, placeDetail?.brand?.id, InitiatedFrom.PLANNER_MAP_RMB);

            this.setState({
                poi: undefined
            });

            this._logic.map().poiOff();
            this._logic.map().poi().destroy();

            this._toggleMapBoxContextMenu();
        }
    };

    private _onMapContextMenuDeleteFavorite = async () => {
        const place = this.state.mapBoxContextMenu?.feature?.properties;
        if (place) {
            await this._deleteFavorite(place.id, place.category);

            this.setState({
                poi: undefined
            });

            this._logic.map().poiOff();
            this._logic.map().poi().destroy();

            this._toggleMapBoxContextMenu();
        }
    };

    private _toggleMapBoxContextMenu = () => {
        this.setState({
            mapBoxContextMenu: {
                ...this.state.mapBoxContextMenu,
                isVisible: !this.state.mapBoxContextMenu?.isVisible
            }
        });
    };

    private _onFavoritesChange = (show: boolean) => {
        if (!this.props.isLoggedIn) {
            this.props.history.push({ search: '?login=true' });
            return;
        }

        this.setState(
            state => ({
                layer: {
                    ...state.layer,
                    display: {
                        ...state.layer.display,
                        places: {
                            ...state.layer.display.places
                        },
                        favorites: show
                    }
                }
            }),
            () => {
                this._logic.map().showFavoritesLayer(show);
            }
        );
    };

    private clickOutside = () => {
        this.setState({
            mapBoxContextMenu: {
                isVisible: false
            }
        });
    };

    private _onPlacesChange = (category: PlaceType) => {
        this.setState(
            state => ({
                layer: {
                    ...state.layer,
                    display: {
                        ...state.layer.display,
                        places: {
                            company:
                                PlaceType.COMPANY === category
                                    ? !state.layer.display.places.company
                                    : state.layer.display.places.company,
                            shop:
                                PlaceType.SHOP === category
                                    ? !state.layer.display.places.shop
                                    : state.layer.display.places.shop,
                            services:
                                PlaceType.FOOD_AND_DRINK === category
                                    ? !state.layer.display.places.services
                                    : state.layer.display.places.services
                        }
                    }
                }
            }),
            () => {
                this._logic.map().showPlacesLayer(this.state.layer.display.places, this.state.layer.display.favorites);
            }
        );
    };

    private _onPlaceClick = (poi: PoiModelMap, fit?: boolean): void => {
        const placeDetail = this._logic.poi().placeDetail(poi.id);
        if (placeDetail) {
            this._showPoiDetail(placeDetail);

            if (fit) {
                this._logic.map().jumpTo({ lat: poi.position.lat, lng: poi.position.lng });
            }
        }
    };

    private _closePoiDetail = () => {
        this.state.clickedPois?.[0] &&
            this._logic.poi().unselect(this.state.clickedPois[0].id, this.state.clickedPois[0].category);

        this._logic.map().selectedPoi().hide();

        this.setState({ clickedPois: [], selectedMapControl: null });
    };

    private _showPoiDetail = async (poi: PoiModelMap, mapPoiClick: boolean = true) => {
        try {
            this.setState({ poiDetailLoading: true, poiOverviewVisible: isMobileDevice() && mapPoiClick });
            this._onPoiFormClose();

            const clickedPois = this.state.clickedPois?.filter(clickedPoi => clickedPoi.id !== poi.id);
            clickedPois?.[0] && this._logic.poi().unselect(clickedPois[0].id, clickedPois[0].category);

            const [detail, reverseGeocoding] = await Promise.all([
                this._logic.apiService().places().getPlaceDetail(poi.id),
                this._logic
                    .apiService()
                    .geocoding()
                    .reverseGeocoding(poi.position.lat, poi.position.lng)
                    .catch((err: any) => {
                        console.error(err);
                        return {
                            formattedResult: ''
                        };
                    })
            ]);

            const poiDetail: PoiDetailModel = {
                id: poi.id,
                name: poi?.category === PlaceType.GAS_STATION && detail?.brand ? detail.brand.name : poi.name,
                services:
                    detail.services?.map(service => {
                        return { name: service.serviceName, code: service.serviceName };
                    }) || [],
                rating: detail.rating,
                address: reverseGeocoding.formattedResult,
                coords: {
                    lat: poi.position.lat,
                    lng: poi.position.lng
                },
                discount: poi.discount,
                fuelTypes: poi.fuelTypes,
                parkingSize: detail.parkingStatus?.parkingSize,
                routeIndex: poi.routeIndex,
                category: poi.category,
                brand: detail?.brand,
                reviews: detail.reviews,
                isFavorite: !!poi.isFavorite,
                fuelPaymentCards: detail.fuelPaymentCards
            };

            clickedPois?.splice(0, 0, poiDetail);
            this.setState({ clickedPois: clickedPois, selectedMapControl: null, poiDetailLoading: false });
        } catch (err) {
            console.error(err);
            this.setState({ poiDetailLoading: false });

            Notification.error({
                message: 'Error',
                description: err.message
            });
        }
    };

    private _onRecentPoiClick = (poi: PoiDetailModel) => {
        this._logic.map().jumpTo({ lat: poi.coords.lat, lng: poi.coords.lng });

        let poiDetail: PoiModelMap | null = null;

        if (poi.category === PlaceType.GAS_STATION) {
            poiDetail = this._logic.poi().fuelStationDetail(poi.id);
        }

        if (poi.category === PlaceType.PARKING_LOT) {
            poiDetail = this._logic.poi().parkingDetail(poi.id);
        }

        if (poi.category !== PlaceType.GAS_STATION && poi.category !== PlaceType.PARKING_LOT) {
            poiDetail = this._logic.poi().placeDetail(poi.id);
        }

        if (poiDetail) {
            this._showPoiDetail(poiDetail);
        }
    };

    private _onDeleteRecentPoi = (removePoi: PoiDetailModel) => {
        const updatedClickedPois = this.state.clickedPois?.filter(poi => poi.id !== removePoi.id);
        this.setState({
            clickedPois: updatedClickedPois
        });
    };

    private _onAddToRoute = (poi: PoiDetailModel) => {
        let type;
        if (poi.category === PlaceType.GAS_STATION) {
            type = MapPlaceType.FuelStation;
        } else if (poi.category === PlaceType.PARKING_LOT) {
            type = MapPlaceType.ParkingLot;
        } else {
            type = MapPlaceType.Start;
        }

        this._logic.schedulingRoutePlanner().addPoiToTransport({
            type,
            data: {
                id: poi.id,
                position: { lng: poi.coords.lng, lat: poi.coords.lat },
                detailAddress: poi.address,
                name: poi.name,
                selected: true,
                routeIndex: poi.routeIndex
            }
        });

        this._closePoiDetail();

        this.props.history.push({ pathname: RouteNames.SCHEDULING_PLANNER });
    };

    private _onPoiDetailAddFavorite = (poi: PoiDetailModel) => {
        this._addFavorite(poi.id, poi.category!, poi.name, poi.isFavorite, {
            lon: poi.coords.lng,
            lat: poi.coords.lat
        });
    };

    private _onPoiDetailDeleteFavorite = (poi: PoiDetailModel) => {
        this._deleteFavorite(poi.id, poi.category!);
    };

    private async _addFavorite(id: string, category: PlaceType, name: string, isFavorite: boolean, location: Location) {
        if (!this.props.isLoggedIn) {
            this.props.history.push({ search: '?login=true' });
            return;
        }

        try {
            await this.props.logic.poi().addToFavorite(id, String(category) as FavoriteType, name, location);

            if (this.state.clickedPois.length) {
                this.setState(prevState => {
                    const clickedPoisTmp = [...prevState.clickedPois];
                    clickedPoisTmp[0].isFavorite = !isFavorite;
                    return { clickedPois: clickedPoisTmp };
                });
            }

            Notification.success({
                message: this.props.t('AddFavoriteNotification.header'),
                description: (
                    <div>
                        {this.props.t('AddFavoriteNotification.text')}
                        <Button type={'link'} onClick={() => this.props.history.push(RouteNames.SETTINGS_FAVORITES)}>
                            {this.props.t('AddFavoriteNotification.button')}
                        </Button>
                    </div>
                )
            });
        } catch (err) {
            console.error(err);
            Notification.error({
                message: 'Error',
                description: err.message
            });
        }
    }

    private _deleteFavorite = async (id: string, category: PlaceType) => {
        if (!this.props.isLoggedIn) {
            this.props.history.push({ search: '?login=true' });
            return;
        }

        try {
            await this.props.logic.poi().deleteFavorite(id, String(category) as FavoriteType);

            if (this.state.clickedPois.length) {
                this.setState(prevState => {
                    const clickedPoisTmp = [...prevState.clickedPois];
                    clickedPoisTmp[0].isFavorite = false;
                    return { clickedPois: clickedPoisTmp };
                });
            }

            Notification.success({
                message: this.props.t('FavoritesModul.deleteSuccess')
            });
        } catch (err) {
            console.error(err);
            Notification.error({
                message: 'Error',
                description: err.message
            });
        }
    };

    private _onShowDetailClick = (poiId: string) => {
        const placeDetail = this._logic.poi().getPoi(poiId);
        if (placeDetail) {
            this.setState({ poiOverviewVisible: false }, () => this._showPoiDetail(placeDetail, false));
        }
    };

    private _onClosePoiOverview = () => {
        this._closePoiDetail();
        this.setState({ poiOverviewVisible: false, clickedPois: [] });
    };
}

export default withTranslation()(withRouter(MapModule));
