import { Button } from 'antd';
import { ButtonProps as AntdButtonProps } from 'antd/lib/button';

type ButtonType =
    | 'primary'
    | 'secondary'
    | 'default'
    | 'dark'
    | 'text'
    | 'link'
    | 'danger'
    | 'icon'
    | 'action'
    | 'iconDark';

export interface ButtonProps extends Omit<AntdButtonProps, 'type'> {
    qa?: string;
    type?: ButtonType;
    outline?: boolean;
    alignIconTop?: boolean;
}

export default function EnhancedButton({ qa, type, outline, alignIconTop, ...props }: ButtonProps) {
    const typeMap: { [key in ButtonType]: string } = {
        default: outline ? 'rl-button-outline' : '',
        primary: outline ? 'rl-button-primary-outline' : 'rl-button-primary',
        danger: outline ? 'rl-button-danger-outline' : 'rl-button-danger',
        secondary: outline ? 'rl-button-secondary-outline' : 'rl-button-secondary',
        dark: outline ? 'rl-button-dark-outline' : 'rl-button-dark',
        text: 'rl-button-text',
        link: 'rl-button-link',
        icon: 'rl-button-icon-type',
        iconDark: 'rl-button-icon-dark',
        action: 'rl-button-action-type'
    };

    const buttonTypeClass = typeMap[type ?? 'default'];
    let buttonClass = buttonTypeClass ? 'rl-button ' + buttonTypeClass : 'rl-button';

    if (alignIconTop && props.icon) {
        buttonClass += ' rl-button-two-lines';
    }

    return (
        <Button
            data-qa={qa}
            {...props}
            className={props.className ? `${buttonClass} ${props.className}` : buttonClass}
        />
    );
}
