import cn from 'classnames';
import moment from 'moment';
import { DATE_TIME_FORMAT } from 'utils/constants/domain-constants';

interface Props {
    eta: string;
    duration: moment.Duration;
    etaWidth: number;
    onOpenTransport: () => void;
}

const CalendarViewTransportEta = (props: Props) => {
    return (
        <div
            className={cn('rl-calendar-view-transport-eta', {
                late: props.duration.asMinutes() > 0,
                'on-time': props.duration.asMinutes() <= 0
            })}
            style={{
                width: `${props.etaWidth}px`
            }}
            onClick={() => {
                props.onOpenTransport();
            }}
            title={`ETA: ${moment(props.eta).format(DATE_TIME_FORMAT)}`}
        />
    );
};

export default CalendarViewTransportEta;
