import { Button } from '../Button';
import { ButtonProps } from '../Button/Button';

export function WhatsappButton(props: ButtonProps) {
    return (
        <Button {...props} className="rl-whatsapp-btn" icon={<i className="rl-button-icon fab fa-whatsapp" />}>
            {props.children}
        </Button>
    );
}
