import { LatLng } from 'common/model/geo';
import {
    Avoids,
    CostPerKm,
    PlacesModel,
    PlacesTaskModel,
    TaskType,
    TransportModel
} from 'modules/PlannerModule/PlannerModule';
import { AlarmModel, AlarmType } from 'modules/PlannerModule/ui/PlannerAlarms';
import moment from 'moment';
import { BehaviorSubject, Subject } from 'rxjs';
import { toAddress } from './common/address';
import { latLngFromGeoJsonPointType } from './common/geo-utils';
import { Geocoding } from './geocoding';
import { Logic } from './logic';
import { PoiModelMap } from './map/logic/fuelStations';
import { MapLogic, MapPlaceType } from './map/map';
import {
    TunnelType,
    CompanyVehicle,
    VehicleProfile,
    VehicleType,
    VehicleStateObject
} from '../services/api/domains/VehiclesApi';
import { AvailableCurrencies } from 'utils/constants/currencies';
import { PlannedRoute, RoutePlanModel, RouteVehicleType } from 'services/api/domains/RoutingApi';
import axios from 'axios';
import { RootStore } from 'stores/RootStore';
import { IReactionDisposer, reaction } from 'mobx';
import { AddressIdentification } from 'conf';
import { arrayMove } from '@dnd-kit/sortable';
import { AlertType } from './UserEvents';
import {
    mapPlaceTypeToCompanyTransportPointType,
    mapAlarmTypeToCompanyTransportNotificationType,
    mapTaskTypeToCompanyTransportActivityType,
    mapTransportStateToCompanyTransportStatus,
    mapToCompanyTransportAvoids,
    mapFromAvoids,
    mapNotificationToAlarmType,
    mapActivityToTaskType,
    mapTransportStatusToTransportState,
    mapPointTypeToPlaceType
} from './common/transports';
import {
    CompanyTransportCreateModel,
    CompanyTransportPoint,
    CompanyTransportPointTypeEnum,
    TransportState
} from 'services/api/domains/TransportsApi';

export const DEFAULT_VEHICLE_PROFILE = 'Truck 12t';
export const TOLL_COST_DEFAULT_VALUE = 1.5;

export type NoRoutError = {
    type: 'NO_ROUTE';
    wayPointId: string;
};

export type UnknownError = {
    type: 'UNKNOWN';
};

export type NoResultError = {
    type: 'NO_RESULTS';
};

export type PlanRoutError = UnknownError | NoRoutError | NoResultError;

export enum TransportAvoidTypeEnum {
    Highway,
    TollRoads,
    UnpavedRoads,
    Ferry,
    SpecialArea
}

export interface PoiMarkerData {
    type: MapPlaceType;
    distance?: number;
    data: PoiModelMap;
}

export enum PoiMarkerDataType {
    parking
}

export class SchedulingRoutePlannerLogic {
    private _active: boolean;
    private _vehicleProfiles: VehicleProfile[];
    private _selectedVehicleProfileId?: number;
    private _selectedVehicleId?: string;
    private _geocoding: Geocoding;
    private _map: MapLogic;
    private _transport: TransportModel;
    private _availableVehicles?: CompanyVehicle[];
    private _addressIdentification: AddressIdentification;
    private _debounceUpdatePlaceActiveIds: string[] = [];
    private _onMarkerDragEndInterval?: NodeJS.Timeout;
    private _tmpWayPointPlaceId?: string;
    private _fuelStationsLoadedWhileRender: boolean;
    private _parkingsLoadedWhileRender: boolean;
    private _destroySignal$ = new Subject<void>();
    routeOnMap$ = new BehaviorSubject(false);
    loading$ = new BehaviorSubject(false);

    private _onTransportChange?: (transport: TransportModel) => void;
    private _onTransportLoad?: (transport: TransportModel) => void;
    private _onRouteCancel?: () => void;
    private _onRemovePlaceFromTransport?: (place: PlacesModel) => void;
    private _onVehicleSet?: (vehicle: VehicleStateObject) => void;
    private _onPlanRouteError?: (err: PlanRoutError) => void;
    private _mobxDisposeFunctions: IReactionDisposer[] = [];

    constructor(geocoding: Geocoding, map: MapLogic, private _logic: Logic, private _store: RootStore) {
        this._geocoding = geocoding;
        this._map = map;
        this._vehicleProfiles = [];
        this._transport = {
            firstPlaceRta: '',
            lastPlaceRta: '',
            places: [],
            avoids: ''
        };
        this._active = false;
        this._fuelStationsLoadedWhileRender = true;
        this._parkingsLoadedWhileRender = true;
        this._logic.poi().fuelStationsLoaded$.subscribe(() => {
            if (!this._fuelStationsLoadedWhileRender) {
                this.drawRouteOnMap();
            }
        });
        this._logic.poi().parkingsLoaded$.subscribe(() => {
            if (!this._parkingsLoadedWhileRender) {
                this.drawRouteOnMap();
            }
        });

        this._addressIdentification = this._store.userSettings.addressIdentification;
    }

    async init() {
        this._active = true;
        this._map.routing().init();
        this._map.routePlanningMode(true);

        this._onTransportChange?.(this._transport);

        this._logic
            .map()
            .routing()
            .onMarkerDragEnd((markerId: string, latLng: LatLng) => {
                // we need to wait for updates from onDrag function
                this._onMarkerDragEndInterval = setInterval(() => {
                    if (this._debounceUpdatePlaceActiveIds.length === 0) {
                        if (!markerId) {
                            markerId = this._tmpWayPointPlaceId ?? '';
                        }
                        this.updatePlaceLatLong(markerId, latLng);
                        this._onMarkerDragEndInterval && clearInterval(this._onMarkerDragEndInterval);
                        this._tmpWayPointPlaceId = undefined;
                    }
                    // bigger timeout than debounce. to be sure all drag function already fired.
                }, 60);
            });

        this._logic
            .map()
            .routing()
            .onViaPointDragEnd((routeIndex, latLng) => {
                this.addPoiToTransport({
                    type: MapPlaceType.Viapoint,
                    data: {
                        routeIndex: routeIndex,
                        position: latLng
                    }
                } as PoiMarkerData);
            });

        this._logic
            .map()
            .routing()
            .onViaPointRemove(async placeId => {
                await this.removePlaceFromTransport(placeId, true);
            });

        this._mobxDisposeFunctions.push(
            reaction(
                () => this._store.userSettings.addressIdentification,
                addressIdentification => {
                    this._addressIdentification = addressIdentification;
                    this._transport.places.forEach(place => {
                        place.name = toAddress(
                            this._store.userSettings.lang,
                            place.addressStructured,
                            this._addressIdentification,
                            place.originalName
                        );
                    });
                }
            )
        );
    }

    destroy() {
        this._logic.map().destroyRoute();
        this._destroySignal$.next();
        this._mobxDisposeFunctions.forEach(disposer => disposer());
        this._mobxDisposeFunctions = [];
    }

    async fetchVehiclesAndProfiles() {
        return Promise.all([
            this._fetchVehicleProfiles().then(profiles => {
                this._vehicleProfiles = profiles;
                this._selectedVehicleProfileId = this.getDefaultVehicleProfile()?.vehicleProfileId;
                return profiles;
            }),
            this._logic
                .vehiclesState()
                .availableFromTime()
                .then(vehicles => {
                    this._availableVehicles = vehicles;
                    return vehicles;
                })
        ]);
    }

    setTransportClient(note: string) {
        this._transport.client = note;
        this._onTransportChange?.(this._transport);
    }

    setTransportNote(text: string) {
        this._transport.note = text;
    }

    setTransportCostPerKM(costPerKm?: CostPerKm) {
        this._transport.costPerKm = costPerKm || {
            cost: TOLL_COST_DEFAULT_VALUE,
            currency: AvailableCurrencies.EUR
        };
        this._onTransportChange?.(this._transport);
    }

    selectedVehicleProfile() {
        return (
            this._vehicleProfiles.find(v => v.vehicleProfileId === this._selectedVehicleProfileId) ||
            this.getDefaultVehicleProfile()
        );
    }

    getDefaultVehicleProfile() {
        return this._vehicleProfiles?.find(profile => profile.name === DEFAULT_VEHICLE_PROFILE);
    }

    onTransportChange(cb?: (transport: TransportModel) => void): void {
        this._onTransportChange = cb;
    }

    onTransportLoad(cb?: (transport: TransportModel) => void): void {
        this._onTransportLoad = cb;
    }

    onRouteCancel(cb?: () => void): void {
        this._onRouteCancel = cb;
    }

    onRemovePlaceFromTransport(cb?: (place: PlacesModel) => void): void {
        this._onRemovePlaceFromTransport = cb;
    }

    onVehicleSet(cb?: (vehicle: VehicleStateObject) => void): void {
        this._onVehicleSet = cb;
    }

    onPlanRouteError(cb?: (err: PlanRoutError) => void): void {
        this._onPlanRouteError = cb;
    }

    addPoiToTransport(poi: PoiMarkerData) {
        this.addPlaceToTransport(
            poi.data.name,
            poi.data.position,
            poi.type,
            poi.data.routeIndex !== undefined ? poi.data.routeIndex + 1 : undefined
        );
    }

    async loadTransport(id: string) {
        this.loading$.next(true);

        const companyId = this._logic.company().getCompany().companyId;
        const transport = await this._logic.apiService().transports().getTransport(companyId, Number(id));
        if (transport === null) {
            this._logic.userEvents().alert(AlertType.FAILED_TRANSPORT_LOAD, 'No transport found');
            return;
        }

        this._selectedVehicleId = transport.vehicle?.vehicleId?.toString();
        this._selectedVehicleProfileId =
            transport?.vehicleProfileId || this.getDefaultVehicleProfile()?.vehicleProfileId;
        const countryList = await this._logic.enums().countryList();
        this._transport = {
            id: transport.transportId?.toString() || undefined,
            vehicle: this._selectedVehicleId,
            name: transport.name || '',
            firstPlaceRta: transport.route?.points?.[0].arrival,
            lastPlaceRta: transport.route?.points?.[transport.route.points.length - 1].arrival,
            state: mapTransportStatusToTransportState(transport.status!),
            profile: this._selectedVehicleProfileId?.toString(),
            client: transport.clientName ?? '',
            avoids: mapFromAvoids(transport.route?.avoids!, countryList) || '',
            eta: transport.eta,
            distance: transport.route?.distance,
            duration: transport.route?.duration,
            note: transport.note,
            costPerKm: transport.route.cost
                ? {
                      cost: transport.route.cost ?? TOLL_COST_DEFAULT_VALUE,
                      currency: 'EUR' as AvailableCurrencies
                  }
                : undefined,
            places: (transport.route.points || []).map((p, index) => {
                return {
                    id: index.toString(),
                    center: latLngFromGeoJsonPointType({ coordinates: [p.coordinate?.lon!, p.coordinate?.lat!] } ?? {}),
                    name: p.name || '',
                    route: '',
                    tasks: [
                        {
                            id: index.toString(),
                            type: mapActivityToTaskType(p.activityType!),
                            action: p.instructions,
                            additionalTimeSec: moment(p.departure).diff(moment(p.arrival), 'seconds')
                        }
                    ],
                    rta: p.arrival,
                    rtd: p.departure,
                    distance: 0,
                    duration: 0,
                    addressStructured: [
                        {
                            address: p.address!,
                            lang: '',
                            countryCode: p.iso3,
                            country: '',
                            town: '',
                            route: '',
                            streetAddress: '',
                            postalCode: ''
                        }
                    ],
                    type: mapPointTypeToPlaceType(p.pointType!) ?? MapPlaceType.Waypoint,
                    alarms: (p.enabledNotifications! || []).map(
                        notification =>
                            ({
                                type: mapNotificationToAlarmType(notification) as unknown as AlarmType,
                                config: {
                                    name: '',
                                    value: ''
                                }
                            } as unknown as AlarmModel)
                    )
                } as PlacesModel;
            })
        };

        this._onTransportLoad?.(this._transport);

        this._initRoute();

        if (transport.vehicle?.vehicleId) {
            this._selectedVehicleId = String(transport.vehicle?.vehicleId);
            this._logic
                .vehiclesState()
                .vehicle(this._selectedVehicleId)
                .then(v => {
                    if (v?.gpsData && v.address) {
                        this._onVehicleSet?.(v);
                    }
                });
        }

        if (this._active) {
            this.drawRouteOnMap();
        }

        this._planRoute().finally(() => {
            this.loading$.next(false);
        });
    }

    async saveRoute(): Promise<{ operation: 'CREATE' | 'UPDATE'; transportId: string }> {
        const vehicle = this._selectedVehicleId
            ? await this._logic.vehicles().getVehicle(+this._selectedVehicleId)
            : undefined;

        let state: TransportState = this._transport.state ?? TransportState.New;
        if (!this._transport.state) {
            state = !this._selectedVehicleId ? TransportState.New : TransportState.Assigned;
        }

        if (this._transport.state && !this._selectedVehicleId) {
            state = TransportState.New;
        }

        if (this._transport.state && this._selectedVehicleId) {
            state = this._transport.state === TransportState.New ? TransportState.Assigned : this._transport.state;
        }

        const countryList = await this._logic.enums().countryList();
        const data: CompanyTransportCreateModel = {
            name: this._transport.name!,
            vehicleProfileId: this._selectedVehicleProfileId,
            status: mapTransportStateToCompanyTransportStatus(state),
            clientName: this._transport.client,
            route: {
                avoids: mapToCompanyTransportAvoids(this._transport.avoids!, countryList),
                cost: this._transport.costPerKm?.cost,
                distance: this._transport.distance,
                duration: this._transport.duration,
                points: this._transport.places.map((p): CompanyTransportPoint => {
                    return {
                        name: p.name,
                        coordinate: {
                            lat: p.center.lat,
                            lon: p.center.lng
                        },
                        pointType:
                            mapPlaceTypeToCompanyTransportPointType(p.type!) ?? CompanyTransportPointTypeEnum.Waypoint,
                        arrival: p.rta ? new Date(p.rta).toISOString() : undefined,
                        departure: p.rtd ? new Date(p.rtd).toISOString() : undefined,
                        enabledNotifications: p.alarms.map(a => mapAlarmTypeToCompanyTransportNotificationType(a.type)),
                        instructions: p.tasks?.[0]?.action,
                        activityType: mapTaskTypeToCompanyTransportActivityType(p.tasks?.[0]?.type!),
                        address: p.addressStructured[0].address,
                        iso3: p.addressStructured[0].countryCode
                    };
                })
            },
            vehicleId: +this._selectedVehicleId!,
            driverId: vehicle?.drivers?.length ? vehicle?.drivers[0].profileId : undefined,
            note: this._transport.note,
            eta: this._transport.eta ? moment(this._transport.eta).toISOString() : undefined,
            load: {
                referenceNumber: '',
                note: ''
            }
        };

        if (this._transport.id) {
            const transportId = await this._updateTransport(this._transport.id, data);
            return { transportId, operation: 'UPDATE' };
        } else {
            const transportId = await this._createTransport(data);
            this._transport.id = transportId;
            return { transportId, operation: 'CREATE' };
        }
    }

    async changePlaceRta(id: string, value: moment.Moment) {
        const placeIndex = this._transport.places.findIndex(i => i.id === id);
        if (placeIndex !== -1) {
            this._transport.places[placeIndex].rta = value.toISOString();

            if (this._transport.places.length > 1) {
                await this._planRoute();
            } else {
                this._onTransportChange?.(this._transport);
            }
        }
    }

    changeName(value?: string) {
        this._transport = {
            ...this._transport,
            name: value
        };
        this._onTransportChange?.(this._transport);
    }

    async setVehicle(id: string) {
        if (id) {
            this._selectedVehicleProfileId = undefined;
            this._selectedVehicleId = id;
            await this._planRoute();
        }
    }

    async setVehicleProfile(id: number) {
        if (id) {
            this._selectedVehicleId = undefined;
            this._selectedVehicleProfileId = id;
            await this._planRoute();
        }
    }

    reset() {
        this._transport = {
            firstPlaceRta: '',
            lastPlaceRta: '',
            places: [],
            avoids: ''
        };

        this._selectedVehicleId = undefined;
        this._map.destroyRoute();
        this._onRouteCancel?.();
        this.routeOnMap$.next(false);
    }

    async addPlaceToTransport(
        name: string,
        coordinates: LatLng,
        type: MapPlaceType,
        index?: number,
        planRoute: boolean = true
    ): Promise<string> {
        this.loading$.next(true);
        const places = this._transport.places;
        const addressStructured = await this._geocoding.geocodeBatch(coordinates.lat, coordinates.lng);

        const placeId = this._generateId();
        const placeholder: PlacesModel = {
            id: placeId,
            name: toAddress(this._store.userSettings.lang, addressStructured, this._addressIdentification, name),
            originalName: name,
            center: coordinates,
            rta: undefined,
            rtd: undefined,
            eta: undefined,
            distance: 0,
            duration: 0,
            eventStates: [],
            route: undefined,
            source: '-',
            type,
            addressStructured,
            alarms: [],
            tasks:
                type === MapPlaceType.FuelStation || type === MapPlaceType.ParkingLot
                    ? [
                          {
                              id: placeId,
                              action: '',
                              type:
                                  type === MapPlaceType.FuelStation
                                      ? TaskType.Refueling
                                      : type === MapPlaceType.ParkingLot
                                      ? TaskType.Parking
                                      : undefined,
                              additionalTimeSec: 0
                          }
                      ]
                    : [],
            ...(this._transport.places.length === 0 ? { rta: moment().toISOString() } : {})
        };

        this._logic.searchHistory().addToSearchHistory(placeholder);

        const idx = index || index === 0 ? index : places.length;
        places.splice(idx, 0, placeholder);

        let transportName = name;
        if (this._transport?.places.length) {
            transportName = this._transport?.places
                .filter(
                    (p, i) =>
                        p.name &&
                        p.type !== MapPlaceType.Viapoint &&
                        (i === 0 || i === (this._transport?.places?.length ?? 0) - 1)
                )
                .map(p => p.name!)
                .join(' - ');
        }

        this._transport = {
            ...this._transport,
            id: this._transport.id || undefined,
            name: transportName,
            places
        };

        if (planRoute) {
            const fitRoute = type !== MapPlaceType.Viapoint;
            await this._planRoute(fitRoute);
        }

        this.loading$.next(false);
        return placeholder.id;
    }

    async removePlaceFromTransport(id: string, isViaPoint?: boolean): Promise<void> {
        this._onRemovePlaceFromTransport?.(this._transport.places.filter(p => p.id === id)[0]);
        let places = this._transport.places.filter(p => p.id !== id);

        // remove all viapoints
        if (!isViaPoint) {
            places = places.filter(p => !(p.type === MapPlaceType.Viapoint));
        }

        this._transport = {
            ...this._transport,
            avoids: places.length > 1 ? this._transport.avoids : '',
            places
        };

        this._onTransportChange?.(this._transport);
        this._map?.routing().resetPois();
        const fitRoute = !isViaPoint;
        await this._planRoute(fitRoute);
    }

    async updatePlaceLatLong(id: string, latLng: LatLng): Promise<void> {
        this.loading$.next(true);
        const geocoderAddress: string = await this._geocoding.geocodeLatLng(latLng);
        const addressStructured = await this._geocoding.geocodeBatch(latLng.lat, latLng.lng);

        this._transport = {
            ...this._transport,
            // name,
            places: this._transport.places.map(p =>
                p.id === id
                    ? {
                          ...p,
                          center: {
                              lat: latLng.lat,
                              lng: latLng.lng,
                              coordinates: { lat: latLng.lat, lng: latLng.lng }
                          },
                          type: p.type,
                          tasks: [],
                          name: toAddress(
                              this._store.userSettings.lang,
                              addressStructured,
                              this._addressIdentification,
                              geocoderAddress
                          ),
                          originalName: geocoderAddress,
                          addressStructured
                      }
                    : p
            )
        };

        this._onTransportChange?.(this._transport);
        this._map?.routing().resetPois();
        await this._planRoute(false);
        this.loading$.next(false);
    }

    async updatePlaceOrder(oldIndex: number, newIndex: number): Promise<void> {
        if (this._transport.places[newIndex].eventStates && this._transport.places[newIndex].eventStates!.length) {
            return;
        }

        this._transport = {
            ...this._transport,
            places: arrayMove(this._transport.places, oldIndex, newIndex).map((p, index) => ({
                ...p,
                departureTime: undefined,
                rta: index === 0 ? this._transport.places[0].rta : p.rta,
                rtd: index === 0 ? this._transport.places[0].rtd : p.rtd
            }))
        };

        this._transport.places = this._transport.places.filter(place => place.type !== MapPlaceType.Viapoint);

        this._onTransportChange?.(this._transport);
        this._map?.routing().resetPois();
        await this._planRoute();
    }

    /**
     * Updates place alarm conf if is transport already save saves changes
     * @param id Place id
     * @param config Updated place alarm confif
     */
    updatePlaceAlarm(id: string, config: AlarmModel[]): Promise<void> {
        return new Promise((resolve, reject) => {
            const index = this._transport.places.findIndex(p => p.id === id);
            if (index !== -1) {
                this._transport.places[index].alarms = config;
                this._onTransportChange?.(this._transport);
                resolve();
            } else {
                reject();
            }
        });
    }

    async initTransportForVehicle(id: string): Promise<CompanyVehicle> {
        this.setVehicle(id);
        const vehicleState = await this._logic.vehiclesState().vehicle(id);
        const vehicle = await this._logic.vehicles().getVehicle(+id);

        if (vehicle && vehicleState?.gpsData && vehicleState?.address) {
            this._selectedVehicleId = vehicle.vehicleId?.toString();

            await this.addPlaceToTransport(
                vehicleState.address,
                { lat: vehicleState.gpsData.lat!, lng: vehicleState.gpsData.lon! },
                MapPlaceType.Waypoint
            );

            this._onVehicleSet?.(vehicleState);
            this._transport.costPerKm = { cost: vehicle.costPerKm ?? 0, currency: AvailableCurrencies.EUR };
            this._onTransportChange?.(this._transport);
        }

        return vehicle;
    }

    async fetchSuggestions(text: string, requestid?: string) {
        const location = this._logic.map().getCenter();

        this._logic.apiService().search().cancelRequests();

        return await this._logic
            .apiService()
            .search()
            .getAutocompleteSuggestions({
                query: text,
                lang: this._store.userSettings.lang,
                location: { lng: location.lng, lat: location.lat },
                requestid: requestid
            })
            .then(
                data =>
                    data?.map(place => ({
                        id: place.location_id,
                        label: place.subtitle ? `${place.title}, ${place.subtitle}` : place.title
                    })) || []
            );
    }

    getWaypointRouteIndex(waypoint: { lng: number; lat: number }): number {
        const routes = this._transport.places.filter(p => p.route).map(p => p.route!);
        const distances = routes?.map(route => this._logic.map().routing().pointToPolylineDistance(route, waypoint));

        return distances?.indexOf(Math.min(...distances)) + 1 || 1;
    }

    async onChangeAvoids(avoids: Avoids, planRoute = true) {
        this._transport.avoids = this._getAvoidsForRequest(avoids);
        planRoute && (await this._planRoute());
    }

    private drawRouteOnMap() {
        if (!this._transport.places.length) return;

        const places = this._transport.places.map(e => ({
            id: e.id,
            lat: e.center.lat,
            lng: e.center.lng
        }));

        const routes = this._transport.places.filter(p => p.route).map(p => p.route!);

        this._map.routing().renderRoute(places, routes, [], true);

        this._poisAlongRoute(routes);
    }

    private async _fetchVehicleProfiles(): Promise<VehicleProfile[]> {
        if (this._store.auth.isLoggedIn) {
            const companyId = this._logic.company().getCompany().companyId;
            return await this._logic.apiService().vehicles().getCompanyDefaultVehicleProfiles(companyId);
        } else {
            return await this._logic.apiService().vehicles().getDefaultVehicleProfiles();
        }
    }

    private _generateId() {
        return '_' + Math.random().toString(36).substr(2, 9);
    }

    private async _updateTransport(transportId: string, transport: CompanyTransportCreateModel): Promise<string> {
        const companyId = this._logic.company().getCompany().companyId;
        await this._logic.apiService().transports().patchTransport(companyId, +transportId, transport);

        return transportId!;
    }

    private async _createTransport(transport: CompanyTransportCreateModel) {
        const companyId = this._logic.company().getCompany().companyId;
        const t = await this._logic.apiService().transports().createTransport(companyId, transport);
        return t.transportId!.toString();
    }

    private async _poisAlongRoute(encodedRoutes: string[]) {
        if (!encodedRoutes.length && !this._active) return;

        const fuelStations = await this._logic.poi().fuelStations();
        if (fuelStations.length === 0) {
            this._fuelStationsLoadedWhileRender = false;
        } else {
            const fuelStationsModelMap: PoiModelMap[][] = encodedRoutes.map((route, index) =>
                this._logic.map().routing().pointsAlongPolyline(route, fuelStations, index, true)
            );

            this._map.routing().setFuelStations(fuelStationsModelMap);
        }

        const parkings = await this._logic.poi().parkings();
        if (parkings.length === 0) {
            this._parkingsLoadedWhileRender = false;
        } else {
            const parkingsModelMap: PoiModelMap[][] = encodedRoutes.map((route, index) =>
                this._logic.map().routing().pointsAlongPolyline(route, parkings, index, true)
            );

            this._map.routing().setParkings(parkingsModelMap);
        }
    }

    private async _getRoutingProfile(): Promise<
        (RoutePlanModel['profile'] & { vehicleType: VehicleType }) | undefined
    > {
        const profile = await this.selectedVehicleProfile();

        if (!this._availableVehicles) {
            await this._logic
                .vehiclesState()
                .availableFromTime()
                .then(vehicles => {
                    this._availableVehicles = vehicles;
                });
        }

        const vehicle = this._availableVehicles?.find(v => String(v.vehicleId) === this._selectedVehicleId);

        if (!vehicle && profile) {
            return {
                weight: profile.totalWeight,
                length: profile.totalLength,
                height: profile.height,
                tunnel: TunnelType[profile.tunnelCode],
                width: profile.width,
                axleWeight: profile.axleWeight,
                loadType: profile.loadType,
                vehicleType: profile.vehicleType,
                maxSpeed: profile.maxSpeed
            };
        }

        if (vehicle) {
            return {
                height: vehicle.height,
                weight: vehicle.totalWeight,
                length: vehicle.totalLength,
                trailersCount: vehicle.trailersCount,
                tunnel: TunnelType[vehicle.tunnelCode],
                width: vehicle.width,
                axleWeight: vehicle.axleWeight,
                vehicleAxles: vehicle.vehicleAxles,
                loadType: Number(vehicle.hazardousLoadType),
                vehicleType: vehicle.vehicleType,
                maxSpeed: vehicle.maxSpeed
            };
        }

        return undefined;
    }

    private async _planRoute(fitRoute: boolean = true): Promise<void> {
        let transports: PlannedRoute[] = [];

        try {
            if (this._transport?.places.length) {
                const profile = await this._getRoutingProfile();
                transports = await this._logic
                    .apiService()
                    .routing()
                    .plan({
                        vehicleType:
                            (profile?.vehicleType && mapVehicleToRouteVehicleType(profile.vehicleType)) ||
                            RouteVehicleType.Truck,
                        wayPoints: this._transport.places.map(p => ({
                            id: p.id,
                            departure: p.rta && new Date(p.rta).toISOString(), // same as arrival!
                            arrival: p.rta && new Date(p.rta).toISOString(),
                            additionalTimeSec: p.tasks?.length ? p.tasks[0].additionalTimeSec : undefined,
                            lat: p.center.lat,
                            lng: p.center.lng,
                            name: p.name,
                            type: p.type
                        })),
                        profile,
                        avoids: this._transport.avoids || 'unpaved'
                    });
            }
        } catch (err) {
            if (err.json) {
                (err.json() as Promise<any>).then(data => {
                    if (data?.status === 'NO_RESULTS') {
                        this._onPlanRouteError?.({
                            type: 'NO_RESULTS'
                        });
                    }
                });
            } else {
                if (!axios.isCancel(err)) {
                    this._onPlanRouteError?.({
                        type: 'UNKNOWN'
                    });
                }
            }

            this._map.routing().removeMapObjects({
                fuelStations: true,
                parkings: true
            });

            if (this._transport?.places.length) {
                const places = this._transport.places.map(e => ({
                    id: e.id,
                    lat: e.center.lat,
                    lng: e.center.lng,
                    type: e.type
                }));

                this._map.routing().renderRoute(places, [], [], false);
            }

            return;
        }

        if ((transports as any).errors || (transports as any).error || !transports.length) {
            this.routeOnMap$.next(false);
            this._map.routing().removeMapObjects({
                fuelStations: true,
                parkings: true,
                markers: true
            });

            return;
        }

        const getPossibleAvoids = (possibleAvoids?: object) => {
            const avoids = { ...(possibleAvoids || {}) };
            if (this._transport.avoids?.length) {
                this._transport.avoids.split('|').forEach(avoid => {
                    const avoidModel = avoid.split(':');
                    avoids[avoidModel[0]] = [...(avoids[avoidModel[0]] || []), ...[avoidModel[1]]];
                });
            }

            return avoids;
        };

        const currentTransport = transports[0];

        if (currentTransport) {
            this._transport = {
                ...this._transport,
                firstPlaceRta: currentTransport.firstRta,
                lastPlaceRta: currentTransport.lastRta!,
                places: this._transport.places.map(p => {
                    const currentPlace = currentTransport.places.find(t => t.id === p.id);
                    if (!currentPlace) {
                        return p;
                    }

                    return {
                        ...p,
                        distance: currentPlace.distance,
                        duration: currentPlace.duration,
                        route: currentPlace.route,
                        rta: currentPlace.arrival,
                        rtd: currentPlace.departure,
                        center: { lat: currentPlace.position.lat, lng: currentPlace.position.lng },
                        type: currentPlace.type
                    } as PlacesModel;
                }),
                possibleAvoids: getPossibleAvoids(currentTransport.possibleAvoids),
                duration: currentTransport.duration!,
                routePolyline: currentTransport.routePolyline!,
                distance: currentTransport.distance!,
                eta: currentTransport.eta!,
                costPerKm: this._transport.costPerKm
                    ? {
                          cost: this._transport.costPerKm.cost,
                          currency: this._transport.costPerKm.currency
                      }
                    : {
                          cost: TOLL_COST_DEFAULT_VALUE,
                          currency: AvailableCurrencies.EUR
                      }
            };
        } else {
            this._map.routing().renderRoute([], [], [], false);
        }

        this._onTransportChange?.(this._transport);
        const fit = fitRoute && !!currentTransport;
        await this._initRoute(fit);
    }

    async reloadRoute() {
        this.loading$.next(true);
        await this._planRoute(true);
        this.loading$.next(false);
    }

    private async _initRoute(fitRoute = true, readOnly = false): Promise<void> {
        if (!this._transport.places.length) {
            this.routeOnMap$.next(false);
            return;
        }

        const places = this._transport.places.map(e => ({
            id: e.id,
            lat: e.center.lat,
            lng: e.center.lng,
            isVia: e.type === MapPlaceType.Viapoint
        }));

        if (this._transport.firstPlaceRta) {
            await this._logic
                .vehiclesState()
                .availableFromTime()
                .then(v => {
                    this._availableVehicles = v;
                });
        }

        const routes = this._transport.places.filter(p => p.route).map(p => p.route!);

        this._map.routing().renderRoute(places, routes, [], fitRoute, false, readOnly);
        this._poisAlongRoute(routes);
        this.routeOnMap$.next(true);
    }

    updatePlaceOnRoute(place: PoiModelMap) {
        const routes = this._transport.places.filter(p => p.route).map(p => p.route!);
        routes.forEach((route, index) => {
            const places = this._logic.map().routing().pointsAlongPolyline(route, [place], place.routeIndex);
            if (places.length) {
                place.routeIndex = index;
            }
        });
    }

    private _getAvoidsForRequest(avoids: Avoids): string {
        const request = [];
        for (const countryCode in avoids) {
            for (const avoidType in avoids[countryCode]) {
                if (avoids[countryCode][avoidType]) {
                    request.push(countryCode + ':' + avoidType);
                }
            }
        }

        return request.join('|');
    }

    getTransport() {
        return this._transport;
    }

    setTaskOnPlace(model: PlacesTaskModel, placeId: string) {
        const place = this._transport.places.find(p => p.id === placeId);
        if (place) {
            place.tasks = [model];
            this._onTransportChange?.(this._transport);
        }
    }
}

function mapVehicleToRouteVehicleType(vehicleType: VehicleType): RouteVehicleType {
    const map = {
        [VehicleType.Bus]: RouteVehicleType.Truck,
        [VehicleType.Car]: RouteVehicleType.Car,
        [VehicleType.Taxi]: RouteVehicleType.Car,
        [VehicleType.Truck]: RouteVehicleType.Truck,
        [VehicleType.Van]: RouteVehicleType.Van
    };

    return map[vehicleType];
}
