/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AddOrRemoveUserToUserGroupBody
 */
export interface AddOrRemoveUserToUserGroupBody {
    /**
     * 
     * @type {Array<number>}
     * @memberof AddOrRemoveUserToUserGroupBody
     */
    userGroupIds: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof AddOrRemoveUserToUserGroupBody
     */
    action?: AddOrRemoveUserToUserGroupBodyActionEnum;
}

export function AddOrRemoveUserToUserGroupBodyFromJSON(json: any): AddOrRemoveUserToUserGroupBody {
    return AddOrRemoveUserToUserGroupBodyFromJSONTyped(json, false);
}

export function AddOrRemoveUserToUserGroupBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddOrRemoveUserToUserGroupBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userGroupIds': json['user_group_ids'],
        'action': !exists(json, 'action') ? undefined : json['action'],
    };
}

export function AddOrRemoveUserToUserGroupBodyToJSON(value?: AddOrRemoveUserToUserGroupBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'user_group_ids': value.userGroupIds,
        'action': value.action,
    };
}

/**
* @export
* @enum {string}
*/
export enum AddOrRemoveUserToUserGroupBodyActionEnum {
    Add = 'add',
    Remove = 'remove'
}


