import { Button, Spin } from 'components/base/controls';
import { Modal } from 'components/base/layout';
import { useTranslation } from 'react-i18next';

interface Props {
    visible?: boolean;
    loading: boolean;
    onCancelStopShareEtaModal?: () => void;
    onStopSharingClick?: () => void;
}

const ShareEtaStopModal = (props: Props) => {
    const { t } = useTranslation();

    return (
        <Modal
            className="rl-share-eta-stop-modal"
            visible={props.visible}
            onCancel={props.onCancelStopShareEtaModal}
            width={752}
            destroyOnClose={true}
            closable={false}
            centered={true}
        >
            <Spin loading={props.loading} tip={t('common.loading')} opacity={true}>
                <div className="rl-share-eta-stop-modal-content">
                    <h3>{t('StopShareEtaModal.title')}</h3>
                    <div className="rl-share-eta-stop-modal-content-subtitle">{t('StopShareEtaModal.subtitle')}</div>
                    <div className="rl-share-eta-stop-modal-content-buttons">
                        <Button
                            type="default"
                            size="middle"
                            title={t('StopShareEtaModal.cancelButton')}
                            onClick={props.onCancelStopShareEtaModal}
                        >
                            {t('StopShareEtaModal.cancelButton')}
                        </Button>
                        <Button
                            type="danger"
                            size="middle"
                            title={t('StopShareEtaModal.stopSharingButton')}
                            onClick={props.onStopSharingClick}
                        >
                            {t('StopShareEtaModal.stopSharingButton')}
                        </Button>
                    </div>
                </div>
            </Spin>
        </Modal>
    );
};

export default ShareEtaStopModal;
