import { Button } from 'components/base/controls/Button';
import { Modal } from 'components/base/layout';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { RouteNames } from 'router/routes';

interface Props {
    visible: boolean;
    onPlanRouteClick: () => void;
    onCancel: () => void;
}

export function FirstVehicleModal(props: Props) {
    const { t } = useTranslation();
    const history = useHistory();

    return (
        <Modal
            visible={props.visible}
            centered={true}
            width={'auto'}
            onCancel={props.onCancel}
            className="rl-first-vehicle-modal"
        >
            <img src="/img/truck-stars-icon.svg" alt="truck-stars" />

            <h4>{t('FirstVehicleModal.title')}</h4>

            <div className="rl-first-vehicle-modal-cols">
                <div className="rl-first-vehicle-modal-cols-col">
                    <i className="rl-icon-check-circle" />

                    {t('FirstVehicleModal.planRoute')}

                    <Button type="primary" onClick={props.onPlanRouteClick}>
                        {t('FirstVehicleModal.planRouteButton')}
                    </Button>
                </div>

                <div className="rl-first-vehicle-modal-cols-col">
                    <i className="rl-icon-check-circle" />

                    {t('FirstVehicleModal.planRoute')}

                    <Button
                        type="primary"
                        onClick={() => {
                            history.push(RouteNames.SETTINGS_FLEET);
                            props.onCancel();
                        }}
                    >
                        {t('FirstVehicleModal.assignDriverButton')}
                    </Button>
                </div>

                <div className="rl-first-vehicle-modal-cols-col">
                    <i className="rl-icon-check-circle" />

                    {t('FirstVehicleModal.planRoute')}

                    <Button
                        type="primary"
                        onClick={() => {
                            history.push(RouteNames.SCHEDULING_DISPATCHER_BOARD_CALENDAR);
                            props.onCancel();
                        }}
                    >
                        {t('FirstVehicleModal.assignTransportButton')}
                    </Button>
                </div>
            </div>
        </Modal>
    );
}
