import moment from 'moment';
import authjs from '@sygic/auth-sdk';
import { makeAutoObservable, runInAction } from 'mobx';
import { Moment } from 'moment';
import { RegistrationType, User } from 'stores/auth/types';
import { RouteNames } from 'router/routes';
import { Conf, confDefault } from 'conf';
import { AuthOptions } from '@sygic/auth-sdk/lib/auth';
import { Token } from '@sygic/auth-sdk/lib/authState/token';
import { loggedInRoles } from 'stores/auth/roles';
import { CONF } from 'config/conf';

const conf: Conf = { ...confDefault, ...(CONF as any) };

interface AuthUser {
    id: string;
    name: string;
    username: string;
    type: string;
    email: string;
    email_is_verified: boolean;
    linked_users: Array<AuthUser>;
    registered_at: RegistrationType;
}

const LOCAL_STORAGE_AUTH_TOKEN_EXPIRE_DATE = 'token-expire';
export const LOCAL_STORAGE_REDIRECT_FLAG = 'redirect-flag';
export const LOCAL_STORAGE_APP_AUTH_STATE = 'appAuthState';

class AuthStore {
    private _authService;
    private _tokenExpireDate: Moment | null = null;
    private _token: Token | null = null;
    private _updateTokenInterval?: NodeJS.Timeout;
    isLoggedIn = false;
    user: User | null = null;

    constructor(private _config: AuthOptions) {
        makeAutoObservable(this, {
            token: false
        });

        // eslint-disable-next-line no-eval
        this._authService = authjs.initializeApp({ ...this._config, clientId: eval(this._config.clientId) });
    }

    setLoggedIn(value: boolean) {
        this.isLoggedIn = value;
    }

    setToken(token: Token) {
        this._token = token;
    }

    setUser(user: User | null) {
        this.user = user;
    }

    get authState() {
        return {
            isLoggedIn: this.isLoggedIn,
            token: this._token?.getAccessToken(),
            user: this.user
        };
    }

    setAuthStateToLocalStorage(authState: { token: string; isLoggedIn: boolean } | null) {
        localStorage.setItem(LOCAL_STORAGE_APP_AUTH_STATE, JSON.stringify(authState));
    }

    getAuthStateFromLocalStorage(): { token: string; isLoggedIn: boolean } | null {
        const authState = localStorage.getItem(LOCAL_STORAGE_APP_AUTH_STATE);

        return authState ? JSON.parse(authState) : null;
    }

    get token(): string {
        const authState = this.getAuthStateFromLocalStorage();
        const token = authState?.token;
        if (!token) {
            throw new Error('Access token missing');
        }

        return token;
    }

    async init() {
        const authState = await this._authService.getAuthState();
        const localTokenExpiration = localStorage.getItem(LOCAL_STORAGE_AUTH_TOKEN_EXPIRE_DATE);
        this._tokenExpireDate = localTokenExpiration ? moment(localTokenExpiration) : null;

        if (authState?.token) {
            await this._updateToken();
        } else {
            await this.login();
        }

        if (!this._updateTokenInterval) {
            this._updateTokenInterval = setInterval(() => {
                this._updateToken();
            }, 36e5); // update after 1 hour
        }

        const user = await this._getUser();

        runInAction(() => {
            this.setUser(user);
            this.setLoggedIn(!!user);
        });
    }

    async login(username?: string, password?: string) {
        let token: Token | null = null;

        if (username && password) {
            const authState = await this._authService.signInWithEmailAndPassword(username, password);
            token = authState.token;
        } else {
            token = await this._authService.signInWithClientCredentials();
        }

        if (token) {
            this._updateTokenExpireDate(token.getExpiresIn()!);
            const user = await this._getUser();
            await this._onAuthStateChange();

            runInAction(() => {
                this.setToken(token!);
                this.setUser(user);
                this.setLoggedIn(!!user);
            });
        } else {
            throw new Error('Cannot login - Auth state token does not exist after login');
        }
    }

    async loginWithRedirect(providerType: string) {
        const provider =
            providerType === 'facebook'
                ? authjs.providers.facebookAuthProvider()
                : authjs.providers.googleAuthProvider();
        const searchParams = new URLSearchParams(window.location.search);

        if (providerType === 'facebook') {
            searchParams.append('utm_source', 'facebook_access.com');
            searchParams.append('utm_medium', 'button');
        }

        const searchParamsString = searchParams.toString();

        window.history.pushState(
            {},
            '',
            RouteNames.SCHEDULING_PLANNER + (searchParamsString ? '?' + searchParamsString : '')
        );

        localStorage.setItem(LOCAL_STORAGE_REDIRECT_FLAG, 'redirect');
        await this._authService.signInWithRedirect(provider);
    }

    async logout() {
        await this._authService.signOut();
        await this.login(); // login with client credentials
    }

    async register(email: string, password: string) {
        await this._authService.createUserWithEmailAndPassword(email, password);
        const authState = await this._authService.signInWithEmailAndPassword(email, password);
        if (authState.token) {
            await this._onAuthStateChange();
            this.setToken(authState.token);
        } else {
            throw new Error('Access token missing');
        }
    }

    async resetPassword(email: string) {
        await this._authService.resetPassword(email);
    }

    async confirmResetPassword(password: string, token: string) {
        await this._authService.confirmResetPassword(password, token);
    }

    setUserLanguage(lang: string) {
        this._authService.setLanguageHeader(lang);
    }

    confirmEmail(confirmToken: string) {
        return this._authService.confirmEmail(confirmToken);
    }

    async updateToken() {
        if (this._tokenExpireDate?.isValid() && this._tokenExpireDate < moment()) {
            // will update expired token only
            await this._updateToken();
        }
    }

    private async _updateToken() {
        try {
            const authState = await this._authService.signInWithRefreshToken();
            if (authState?.token) {
                await this._onAuthStateChange();
                this._updateTokenExpireDate(authState.token.getExpiresIn()!);
                this.setToken(authState.token);
            } else {
                throw new Error('Cannot update token - Access token missing');
            }
        } catch (err) {
            if (err.code === 409) {
                // refresh token expires
                await this.login();
            } else {
                console.error(err);
                await this.login();
                throw err;
            }
        }
    }

    private async _onAuthStateChange() {
        const authState = await this._authService.getAuthState();
        this.setAuthStateToLocalStorage({ isLoggedIn: !!authState?.user, token: authState?.token?.getAccessToken()! });
    }

    private async _getUser() {
        const authState = await this._authService.getAuthState();
        const userJSON = authState?.user?.toJSON();
        const userParsed: AuthUser | null = userJSON ? JSON.parse(userJSON) : null;
        const user = userParsed
            ? {
                  id: userParsed.id,
                  email: userParsed.email,
                  name: userParsed.name,
                  roles: loggedInRoles(),
                  registerBy: (userParsed?.type ?? RegistrationType.UNREGISTERED_USER) as RegistrationType
              }
            : null;

        return user;
    }

    private _updateTokenExpireDate(expiresIn: number) {
        const expireDate = moment().add(expiresIn, 'seconds');
        this._tokenExpireDate = expireDate;
        localStorage.setItem(LOCAL_STORAGE_AUTH_TOKEN_EXPIRE_DATE, expireDate.toISOString());
    }
}

const authStore = new AuthStore(conf.auth.simpleAuth.config);

export { AuthStore, authStore };
