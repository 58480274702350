import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { PlacesTaskModel } from '../../PlannerModule';
import PlannerAlarms, { AlarmModel } from '../PlannerAlarms';
import PlannerPlacesTask from '../PlannerPlacesTask';
import { Button, Spin } from 'components/base/controls';

interface State {
    alarmConfig: AlarmModel[];
    task: PlacesTaskModel;
}

interface Props extends WithTranslation {
    placeId: string;
    task: PlacesTaskModel;
    alarms?: AlarmModel[];
    loading?: boolean;
    onClose: () => void;
    onPlacesTaskSave: (model: PlacesTaskModel, placeId: string, reloadRoute: boolean) => void;
    onAlarmConfigChange?: (config: AlarmModel[]) => void;
}

class PlaceSettings extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            alarmConfig: [],
            task: props.task
        };
    }

    render() {
        return (
            <div className="rl-place-settings">
                <div className="rl-place-settings-header">
                    <h4>{this.props.t('PlannerPlacesTask.title')}</h4>
                    <Button type="action" onClick={this._onClose} icon={<i className="rl-icon-chevron-left" />} />
                </div>
                <div className="rl-place-settings-content">
                    <Spin loading={!!this.props.loading} tip={this.props.t('common.loading')}>
                        <PlannerPlacesTask
                            placeId={this.props.placeId!}
                            task={this.props.task}
                            onPlaceTaskChange={this._onPlaceTaskChange}
                        />
                        <PlannerAlarms alarms={this.props.alarms} onAlarmConfigChange={this._onAlarmConfigChange} />
                    </Spin>
                </div>
            </div>
        );
    }

    private _onAlarmConfigChange = (config: AlarmModel[]) => {
        this.setState(
            {
                alarmConfig: config
            },
            () => this.props.onAlarmConfigChange?.(config)
        );
    };

    private _onPlaceTaskChange = (task: PlacesTaskModel, reloadRoute: boolean) => {
        this.setState(
            {
                task
            },
            () => {
                this.props.onPlacesTaskSave?.(this.state.task, this.props.placeId, reloadRoute);
            }
        );
    };
    private _onClose = () => {
        this.props.onPlacesTaskSave?.(this.state.task, this.props.placeId, false);
        this.props.onAlarmConfigChange?.(this.state.alarmConfig);
        this.props.onClose?.();
    };
}

export default withTranslation()(PlaceSettings);
