import { Col, Row } from 'antd';
import { ResetPasswordSuccess } from '../ResetPasswordSuccess/ResetPasswordSuccess';
import { ResetPasswordForm } from '../ResetPasswordForm/ResetPasswordForm';
import { FeaturesCarousel } from '../../../../components/FeaturesCarousel';
import { Modal } from 'components/base/layout';
import { useTranslation } from 'react-i18next';
import { Button } from 'components/base/controls';

interface Props {
    visible: boolean;
    onOpenLoginModal: () => void;
    onSubmitResetPassword: (email: string) => void;
    onCancel?: () => void;
    success?: boolean;
}

export function ResetPasswordModal(props: Props) {
    const { t } = useTranslation();

    return (
        <Modal
            className="rl-modal-with-carousel"
            visible={props.visible}
            destroyOnClose={true}
            width={944}
            onCancel={props.onCancel}
        >
            <Row>
                <Col span={24} md={12}>
                    <div className="rl-reset-password-modal-header">
                        <Button type="action" icon={<i className="rl-icon-chevron-left" />} onClick={props.onCancel} />
                        <h3>{t(`${props.success ? 'ResetPasswordSuccess.title' : 'ResetPasswordForm.title'}`)}</h3>
                    </div>

                    <div className="rl-modal-with-carousel-form rl-reset-password-modal-content">
                        {props.success ? (
                            <ResetPasswordSuccess onCancel={props.onCancel} />
                        ) : (
                            <ResetPasswordForm
                                onOpenLoginModal={props.onOpenLoginModal}
                                onSubmitResetPassword={props.onSubmitResetPassword}
                            />
                        )}
                    </div>
                </Col>
                <Col span={0} md={12}>
                    <div className="rl-modal-with-carousel-features">
                        <FeaturesCarousel />
                    </div>
                </Col>
            </Row>
        </Modal>
    );
}
