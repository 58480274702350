import { PlaceType } from 'services/api/domains/PlacesApi';

export const CONTACT_MAIL = 'dispatcher@roadlords.com';

export const DEFAULT_MAP_PADDING = {
    left: 104,
    top: 50,
    bottom: 40,
    right: 104
};

export const MOBILE_MAP_PADDING = {
    left: 40,
    top: 98,
    bottom: 40,
    right: 40
};

export const TRACKING_MOBILE_MAP_PADDING = {
    left: 0,
    top: 0,
    bottom: 140,
    right: 0
};

export const TABLET_MAX_WIDTH = 992;
export const MOBILE_MAX_WIDTH = 768;

export interface Language {
    label: string;
    code: string;
    name: string;
}

export const LANGUAGES: Language[] = [
    { label: 'SK', code: 'sk', name: 'Slovenčina' },
    { label: 'CZ', code: 'cs', name: 'Čeština' },
    { label: 'EN', code: 'en', name: 'English' },
    { label: 'FR', code: 'fr', name: 'Français' },
    { label: 'HU', code: 'hu', name: 'Magyar' },
    { label: 'IT', code: 'it', name: 'Italiano' },
    { label: 'PL', code: 'pl', name: 'Polski' },
    { label: 'RO', code: 'ro', name: 'Română' },
    { label: 'BG', code: 'bg', name: 'Български' },
    { label: 'DE', code: 'de', name: 'Deutsch' },
    { label: 'ES', code: 'es', name: 'Español' },
    { label: 'PT', code: 'pt', name: 'Português' },
    { label: 'TR', code: 'tr', name: 'Türkçe' }
];

export const POI_ICON = {
    [PlaceType.COMPANY]: 'company-icon.svg',
    [PlaceType.PARKING_LOT]: 'parking-icon.svg',
    [PlaceType.SHOP]: 'shop-icon.svg',
    [PlaceType.FOOD_AND_DRINK]: 'services-icon.svg',
    [PlaceType.GAS_STATION]: 'fuelstation-icon.svg'
};

export const LAT_REGEX = /^([+-])?(?:90(?:(?:\.0{1,23})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,23})?))$/;
export const LNG_REGEX = /^([+-])?(?:180(?:(?:\.0{1,23})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,23})?))$/;
