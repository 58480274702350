/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Edit
 */
export interface Edit {
    /**
     * 
     * @type {string}
     * @memberof Edit
     */
    editId?: string;
    /**
     * 
     * @type {Date}
     * @memberof Edit
     */
    timestamp?: Date;
}

export function EditFromJSON(json: any): Edit {
    return EditFromJSONTyped(json, false);
}

export function EditFromJSONTyped(json: any, ignoreDiscriminator: boolean): Edit {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'editId': !exists(json, 'edit_id') ? undefined : json['edit_id'],
        'timestamp': !exists(json, 'timestamp') ? undefined : (new Date(json['timestamp'])),
    };
}

export function EditToJSON(value?: Edit | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'edit_id': value.editId,
        'timestamp': value.timestamp === undefined ? undefined : (value.timestamp.toISOString()),
    };
}


