import { Conf } from 'conf';
import { HttpClient } from '../common/HttpClient';
import moment from 'moment';
import { CompanyVehicle, VehicleProfile, VehicleType } from './VehiclesApi';

const TOLLS_API_VERSION = '1';

export interface TollCosts {
    totalCost: number;
    currency: string;
    totalDistance: number;
    countries: CountryTollCost[];
}

export interface CountryTollCost {
    countryCode: string;
    summary: CountryTollCostSummary;
    sections: TollSection[];
}

interface CountryTollCostSummary {
    cost: {
        amount: number;
        currency: string;
    };
    distance: number;
}

interface TollCost {
    amount: number;
    currency: string;
    paymentMethods: PaymentMethods[];
}

interface TollSection {
    cost: TollCost;
    distance: number;
    displayName: string;
    tollRoadType: TollRoadType;
}

enum TollRoadType {
    GENERAL = 1,
    CITY,
    BRIDGE,
    TUNNEL,
    FERRY,
    MOUNTAIN_PASS,
    UNSPECIFIED
}

enum PaymentMethods {
    ELECTRONIC_TOLL_COLLECTION_SUBSCRIPTION = 1,
    ELECTRONIC_TOLL_COLLECTION,
    CASH,
    CREDIT_CARD,
    UNSPECIFIED
}

enum VehicleLoadType {
    PASSENGER = 1,
    GOODS
}

enum EngineType {
    COMBUSTION = 1,
    ELECTRIC
}

class TollsApi {
    private _abortController: AbortController;

    constructor(private _conf: Conf['api'], private _httpClient: HttpClient) {
        this._abortController = new AbortController();
    }

    cancelRequests = () => {
        this._abortController?.abort();
        this._abortController = new AbortController();
    };

    async getTollCosts(
        polyline: string,
        currency = 'EUR',
        referenceTime?: string,
        vehicle?: CompanyVehicle | VehicleProfile | undefined
    ): Promise<TollCosts> {
        this.cancelRequests();

        const vehicleLoadType = [VehicleType.Bus, VehicleType.Car, VehicleType.Taxi].includes(vehicle?.vehicleType!)
            ? VehicleLoadType.PASSENGER
            : VehicleLoadType.GOODS;

        return this._httpClient.post(
            `${this._conf.tollCostUrl}/tollcost/polyline`,
            {
                currency,
                polyline,
                referenceTime: referenceTime ?? moment().toISOString(),
                vehicle: {
                    //https://tollcost-testing.roadlords.net/swagger/index.html#/TollCost/post_TollCost_polyline
                    width: vehicle?.width,
                    height: vehicle?.height,
                    totalLength: vehicle?.totalLength,
                    totalWeight: vehicle?.totalWeight,
                    axleWeight: vehicle?.axleWeight,
                    vehicleAxles: vehicle?.vehicleAxles,
                    fuelType: vehicle?.fuelType,
                    emissionClass: vehicle?.emissionClass,
                    tunnelCode: vehicle?.tunnelCode,
                    vehicleType: vehicle?.vehicleType === VehicleType.Bus ? VehicleType.Truck : vehicle?.vehicleType,
                    //vehicleLoadType, engineType, yearOfManufacture are default values for now
                    //https://confluence.sygic.com/login.action?os_destination=%2Fpages%2Fviewpage.action%3FpageId%3D133565547&permissionViolation=true
                    vehicleLoadType: vehicleLoadType,
                    engineType: EngineType.COMBUSTION,
                    yearOfManufacture: 2015
                }
            },
            {
                apiVersion: TOLLS_API_VERSION,
                signal: this._abortController.signal
            }
        );
    }
}

export { TollsApi };
