import { Editor, EditorState, Modifier, RichUtils, convertToRaw, convertFromRaw } from 'draft-js';
import { useEffect, useRef, useState } from 'react';
import RichEditorActions from './ui/RichEditorActions/RichEditorActions';
import RichEditorColors from './ui/RichEditorColors/RichEditorColors';
import RichEditorFonts from './ui/RichEditorFonts/RichEditorFonts';

export enum InlineStyles {
    Bold = 'BOLD'
}

interface Props {
    text?: string;
    readonly: boolean;
    onSave?: (text?: string) => void;
    onCancel?: () => void;
    onChange?: (text?: string) => void;
    onEditorClick?: () => void;
    onEditorClickOutside?: (text?: string) => void;
}

const RichEditor = (props: Props) => {
    const editorRef = useRef<Editor>(null);
    const editorDivRef = useRef<any>(null);

    const [outsideClick, setOutsideClick] = useState<boolean | null>(null);
    const [editorChanged, setEditorChanged] = useState(false);
    const [editorState, setEditorState] = useState(() => {
        return props.text
            ? EditorState.createWithContent(convertFromRaw(JSON.parse(props.text)))
            : EditorState.createEmpty();
    });

    const colorStyleMap = {
        black: {
            color: 'rgba(57, 69, 86, 1.0)'
        },
        red: {
            color: 'rgba(249, 66, 66, 1.0)'
        },
        blue: {
            color: 'rgba(13, 135, 204, 1.0)'
        },
        green: {
            color: 'rgba(20, 183, 16, 1.0)'
        },
        orange: {
            color: 'rgba(248, 98, 13, 1.0)'
        },
        violet: {
            color: 'rgba(215, 5, 250, 1.0)'
        }
    };

    useEffect(() => {
        editorRef.current?.focus();
        _onToggleColor('black');
    }, []);

    useEffect(() => {
        document.addEventListener('click', _handleClickOutside);

        return () => {
            document.removeEventListener('click', _handleClickOutside);
        };
    }, [editorDivRef]);

    useEffect(() => {
        setEditorState(EditorState.moveFocusToEnd(editorState));
    }, [props.readonly, props.text]);

    useEffect(() => {
        if (outsideClick && !props.readonly) {
            editorChanged ? _onSave?.() : _onCancel?.();
            props.onEditorClickOutside?.(_getEditorText());
        }
    }, [outsideClick]);

    const _handleClickOutside = (e: any) => {
        if (editorDivRef.current && !editorDivRef.current.contains(e.target)) {
            setOutsideClick(true);
        } else {
            setOutsideClick(false);
        }
    };

    const _getEditorText = () =>
        editorState.getCurrentContent().hasText()
            ? JSON.stringify(convertToRaw(editorState.getCurrentContent()))
            : undefined;

    const _onToggleColor = (toggledColor: string) => {
        const selection = editorState.getSelection();

        const nextContentState = Object.keys(colorStyleMap).reduce((contentState, color) => {
            return Modifier.removeInlineStyle(contentState, selection, color);
        }, editorState.getCurrentContent());

        const currentStyle = editorState.getCurrentInlineStyle();

        let nextEditorState = EditorState.push(editorState, nextContentState, 'change-inline-style');

        if (selection.isCollapsed()) {
            nextEditorState = currentStyle.reduce((state, color) => {
                return RichUtils.toggleInlineStyle(state!, color!);
            }, nextEditorState);
        }

        if (!currentStyle.has(toggledColor)) {
            nextEditorState = RichUtils.toggleInlineStyle(nextEditorState, toggledColor);
        }

        if (currentStyle.has(InlineStyles.Bold)) {
            nextEditorState = RichUtils.toggleInlineStyle(nextEditorState, InlineStyles.Bold);
        }

        _onChange(nextEditorState);
    };

    const _onToggleBold = () => {
        _onChange(RichUtils.toggleInlineStyle(editorState, InlineStyles.Bold));
    };

    const _onSave = () => {
        props.onSave?.(_getEditorText());
    };

    const _onCancel = () => {
        setEditorChanged(false);
        setEditorState(
            props.text
                ? EditorState.createWithContent(convertFromRaw(JSON.parse(props.text)))
                : EditorState.createEmpty()
        );

        props.onCancel?.();
    };

    const _onChange = (state: EditorState) => {
        !editorChanged &&
            !props.readonly &&
            setEditorChanged(editorState.getCurrentContent().getBlockMap() !== state.getCurrentContent().getBlockMap());

        setEditorState(state);
        props.onChange?.(
            state.getCurrentContent().hasText() ? JSON.stringify(convertToRaw(state.getCurrentContent())) : undefined
        );
    };

    return (
        <div className="rl-rich-editor" ref={editorDivRef} onClick={() => props.onEditorClick?.()}>
            <div className="rl-rich-editor-content">
                <Editor
                    ref={editorRef}
                    readOnly={props.readonly}
                    customStyleMap={colorStyleMap}
                    editorState={editorState}
                    onChange={_onChange}
                    onEscape={() => _onCancel()}
                />
            </div>
            {!props.readonly && (
                <div className="rl-rich-editor-controls">
                    <div className="rl-rich-editor-controls-left">
                        <RichEditorActions
                            editorChanged={editorChanged}
                            onSave={props.onSave && _onSave}
                            onCancel={props.onCancel && _onCancel}
                        />
                    </div>
                    <div className="rl-rich-editor-controls-right">
                        <RichEditorFonts editorState={editorState} onToggleBold={_onToggleBold} />
                        <RichEditorColors editorState={editorState} onToggleColor={_onToggleColor} />
                    </div>
                </div>
            )}
        </div>
    );
};

export default RichEditor;
