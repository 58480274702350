import mapboxgl from 'mapbox-gl';
import * as turf from '@turf/turf';
import { VehiclesMapController } from './logic/vehicles';
import { RoutingMapController } from './logic/routing';
import { ParkingsMapController } from './logic/parkings';
import { FuelStationsMapController, PoiModelMap } from './logic/fuelStations';
import { PoiMapController } from './logic/poi';
import { ControlPanel } from 'modules/MapModule/components/MapControlsBar';
import { Logic } from '../logic';
import { PlacesMapController } from './logic/places';
import { SelectedPoiMapController } from './logic/selectedPoi';
import { Subject } from 'rxjs';
import { RootStore } from 'stores/RootStore';
import { isMobileDevice } from 'utils/helpers/navigator';
import {
    RestrictionLayer,
    TrafficLayer,
    PostalCodesLayer,
    FuelstationsLayer,
    ParkingsLayer,
    FavoritesLayer,
    PlacesLayer,
    RoutingLayer,
    ViaPointLayer,
    SelectedPoiLayer,
    VehiclesLayer,
    FuelLayers,
    ParkingLayers,
    PlaceLayers,
    PlaceTypeLayer
} from './layers';

export interface MapConf {
    initBounds: { lat: number; lng: number }[];
    vehicles: {
        vehicleCenterDistance: number;
    };
}

export interface MapPadding {
    left: number;
    top: number;
    bottom: number;
    right: number;
}

export enum MapPlaceType {
    End = 'end',
    FuelStation = 'fuelStation',
    ParkingLot = 'parkingLot',
    Start = 'start',
    Viapoint = 'viapoint',
    Waypoint = 'waypoint',
    Place = 'place'
}

export class MapLogic {
    private _mapBox?: mapboxgl.Map;
    private _conf: MapConf;
    private _vehicles?: VehiclesMapController;
    private _parkings?: ParkingsMapController;
    private _fuelStations?: FuelStationsMapController;
    private _routing?: RoutingMapController;
    private _poi?: PoiMapController;
    private _places?: PlacesMapController;
    private _selectedPoi?: SelectedPoiMapController;
    private _fuelControl?: HTMLInputElement;
    private _parkingControl?: HTMLInputElement;
    private _layerControl?: HTMLInputElement;
    private _currentSideBarControl: ControlPanel | undefined;
    private _logic: Logic;
    private M_AK: string;
    private _navigationControl: mapboxgl.NavigationControl;
    private _fullscreenControl: mapboxgl.FullscreenControl;
    private _mapLoaded: boolean;

    private _onLoading?: (value: boolean) => void;
    private _onFuelControlToggle?: (value?: boolean) => void;
    private _onParkingControlToggle?: (value?: boolean) => void;
    private _onVehicleDetailToggle?: () => void;
    private _onLayerControlToggle?: (value?: boolean) => void;
    private _onPoiToggle?: (value?: boolean) => void;
    private _onControlsOff?: (control?: ControlPanel) => void;
    private _onSideBarControlsOffResetState?: () => void;
    private _onRoutePlanning?: (value?: boolean) => void;
    private _onResetPoisData?: () => void;
    private _onContextMenu?: (event: any, data: any) => void;
    private _onLayersInit?: () => void;
    private _onMapLoad?: () => void;

    loading: boolean;
    sidebarsVisible$: Subject<boolean>;
    showPoiDetail$: Subject<string>;
    mapLoaded$: Subject<void>;

    constructor(conf: MapConf, logic: Logic, private _store: RootStore) {
        this._conf = conf;
        this.loading = false;
        this._logic = logic;
        this.sidebarsVisible$ = new Subject<boolean>();
        this.mapLoaded$ = new Subject<void>();
        this.showPoiDetail$ = new Subject<string>();
        this._navigationControl = new mapboxgl.NavigationControl({
            showCompass: false
        });
        this._fullscreenControl = new mapboxgl.FullscreenControl();
        this._mapLoaded = false;
        this.M_AK =
            "(typeof ([]+[]))[(+[+!+[]+[+[]]])/((+!+[])+(+!+[]))]+(RegExp().constructor.name)[((+!+[])+(+!+[]))+(+!+[]+((+!+[])+(+!+[])))]+([]+[]+[][[]])[(+!+[]+((+!+[])+(+!+[])))]+(typeof ([]+[]))[(+[+!+[]+[+[]]])/((+!+[])+(+!+[]))]+(![]+[])[((+!+[])+(+!+[]))]+`3`+([]+[]+[][[]])[(+!+[]+((+!+[])+(+!+[])))]+'w'+(!![]+[])[((+!+[])+(+!+[]))]+'w'+(typeof [])[((+!+[])+(+!+[]))*((+!+[])+(+!+[]))]+'q'+(RegExp().constructor.name)[((+!+[])+(+!+[]))+(+!+[]+((+!+[])+(+!+[])))]+(RegExp().constructor.name)[((+!+[])+(+!+[]))+(+!+[]+((+!+[])+(+!+[])))]+`9`+'w'+'k'+(typeof ([]+[]))[(+[+!+[]+[+[]]])/((+!+[])+(+!+[]))]+([]+[]+[][[]])[((+!+[])+(+!+[]))]+(([]).constructor.name)[(+!+[])+(+!+[]+((+!+[])+(+!+[])))]+(([]).constructor.name)[(+!+[])+(+!+[]+((+!+[])+(+!+[])))]+(typeof [])[(+!+[])]+([]+[]+[][[]])[((+!+[])+(+!+[]))]+`1`+'q'";
    }

    get mapLoaded(): boolean {
        return this._mapLoaded;
    }

    init(element: HTMLElement) {
        if (this._mapBox) {
            console.warn('Map is already initialized! You should call map init only once.');
            return;
        }

        this._mapBox = new mapboxgl.Map({
            container: element!,
            style: `https://maps.api.sygic.com/vstyle/gpegl3ewuwcqpp9wkgdyybd1q`,
            center: [11.067112, 49.453643],
            zoom: 4,
            minZoom: 4,
            fadeDuration: 0,
            dragRotate: false
        });

        // eslint-disable-next-line no-eval
        const apiKey = eval(this.M_AK);

        this._mapBox.addControl(this._navigationControl, 'bottom-right');
        this._mapBox.addControl(this._fullscreenControl, 'bottom-right');

        this._mapBox?.once('load', async () => {
            RestrictionLayer.init(this._mapBox!, apiKey);
            TrafficLayer.init(this._mapBox!, apiKey);
            PostalCodesLayer.init(this._mapBox!, apiKey);
            await FuelstationsLayer.init(this._mapBox!);
            await ParkingsLayer.init(this._mapBox!);
            await FavoritesLayer.init(this._mapBox!);
            await PlacesLayer.init(this._mapBox!);
            await RoutingLayer.init(this._mapBox!);
            await ViaPointLayer.init(this._mapBox!);
            await SelectedPoiLayer.init(this._mapBox!);
            await VehiclesLayer.init(this._mapBox!);

            this.routing();
            this._mapLoaded = true;
            this._onMapLoad?.();
            this._onLayersInit?.();

            this.mapLoaded$.next();
        });

        if (isMobileDevice()) {
            this._initMobileDeviceContextMenu();
        } else {
            this._mapBox.on('contextmenu', this._triggerContextMenu);
        }
    }

    private _triggerContextMenu = (e: mapboxgl.MapTouchEvent | mapboxgl.MapMouseEvent) => {
        const feature = this._mapBox
            ?.queryRenderedFeatures(e.point)
            .filter(feature =>
                [
                    FuelLayers.FUEL_UNCLUSTERED,
                    ParkingLayers.PARKING_UNCLUSTERED,
                    PlaceLayers.PLACE_UNCLUSTERED_SERVICES,
                    PlaceLayers.PLACE_UNCLUSTERED_SHOP,
                    PlaceLayers.PLACE_UNCLUSTERED_COMPANY
                ].includes(feature.layer.id as FuelLayers | ParkingLayers | PlaceLayers)
            )[0];
        this._onContextMenu?.(e, feature);
    };

    private _initMobileDeviceContextMenu() {
        let showMenuTimeout: NodeJS.Timeout;
        const clearMenuTimeout = () => {
            clearTimeout(showMenuTimeout);
        };

        this._mapBox?.on('touchstart', e => {
            if (e.originalEvent.touches.length > 1) {
                return;
            }
            showMenuTimeout = setTimeout(() => {
                this._triggerContextMenu(e);
            }, 500);
        });
        this._mapBox?.on('touchend', clearMenuTimeout);
        this._mapBox?.on('touchcancel', clearMenuTimeout);
        this._mapBox?.on('touchmove', clearMenuTimeout);
        this._mapBox?.on('pointerdrag', clearMenuTimeout);
        this._mapBox?.on('pointermove', clearMenuTimeout);
        this._mapBox?.on('moveend', clearMenuTimeout);
        this._mapBox?.on('gesturestart', clearMenuTimeout);
        this._mapBox?.on('gesturechange', clearMenuTimeout);
        this._mapBox?.on('gestureend', clearMenuTimeout);
    }

    destroyRoute() {
        this.routePlanningMode(false);
        this._routing?.destroy();
    }

    vehicles(): VehiclesMapController {
        if (!this._vehicles) {
            this._vehicles = new VehiclesMapController(this._conf, this._mapBox);
        }
        return this._vehicles;
    }

    fuelStations(): FuelStationsMapController {
        if (!this._fuelStations) {
            this._fuelStations = new FuelStationsMapController(this._mapBox!, this._logic, this._store);
        }
        return this._fuelStations;
    }

    parkings(): ParkingsMapController {
        if (!this._parkings) {
            this._parkings = new ParkingsMapController(this._mapBox);
        }
        return this._parkings;
    }

    routing(): RoutingMapController {
        if (!this._routing) {
            this._routing = new RoutingMapController(this._conf, this, this._mapBox);
        }
        return this._routing;
    }

    poi(): PoiMapController {
        if (!this._poi) {
            this._poi = new PoiMapController(this._mapBox);
        }
        return this._poi;
    }

    places() {
        if (!this._places) {
            this._places = new PlacesMapController(this._mapBox, this._logic);
        }
        return this._places;
    }

    selectedPoi() {
        if (!this._selectedPoi) {
            this._selectedPoi = new SelectedPoiMapController(this._mapBox);
        }
        return this._selectedPoi;
    }

    setCurrentSideBarControl(control: ControlPanel) {
        this._currentSideBarControl = control;
    }

    traffic(show: boolean): void {
        TrafficLayer.toggleTraffic(this._mapBox!, show);
    }

    postalCodes(checked: boolean): void {
        PostalCodesLayer.togglePostalArea(this._mapBox!, checked);
    }

    restrictions(checked: boolean): void {
        RestrictionLayer.toggleRestrictions(this._mapBox!, checked);
    }

    setLoading(loading: boolean) {
        this.loading = loading;

        this._onLoading?.(loading);
        if (loading) {
            if (this._parkingControl) {
                this._parkingControl.disabled = true;
            }
            if (this._fuelControl) {
                this._fuelControl.disabled = true;
            }
        } else {
            if (this._parkingControl) {
                this._parkingControl.disabled = false;
            }
            if (this._fuelControl) {
                this._fuelControl.disabled = false;
            }
        }
    }

    sideBarControlsOff(control?: ControlPanel) {
        this._onControlsOff?.(control);

        if (!control || (control === ControlPanel.VEHICLE && control === this._currentSideBarControl)) {
            this._currentSideBarControl = undefined;
            this._onFuelControlToggle?.(false);
            this._onLayerControlToggle?.(false);
            this._onParkingControlToggle?.(false);
            this._onVehicleDetailToggle?.();

            this._fuelControl?.classList.remove('t-hide');
            this._parkingControl?.classList.remove('t-hide');
            this._layerControl?.classList.remove('t-hide');
        }
    }

    sideBarControlsOffResetState() {
        this._onSideBarControlsOffResetState?.();
        this.sideBarControlsOff();
        this._fuelStations?.hide();
        this._parkings?.hide();
    }

    resetPois() {
        this._onResetPoisData?.();
    }

    layerOn() {
        this._currentSideBarControl = ControlPanel.LAYER;
        this._onLayerControlToggle?.(true);
        this._fuelControl!.classList.add('t-hide');
        this._parkingControl!.classList.add('t-hide');
        this._layerControl!.classList.add('t-hide');
    }

    vehicleOn() {
        this._currentSideBarControl = ControlPanel.VEHICLE;
    }

    poiOn() {
        this.sideBarControlsOff();
        this._onPoiToggle?.(true);
    }

    poiOff() {
        this._onPoiToggle?.(false);
        this.sideBarControlsOff();
    }

    onControlsOff(cb?: (control?: ControlPanel) => void) {
        this._onControlsOff = cb;
    }

    onControlsOffResetState(cb?: () => void) {
        this._onSideBarControlsOffResetState = cb;
    }

    onLoading(cb?: (value: boolean) => void) {
        this._onLoading = cb;
    }

    onLayersInit(cb?: () => void) {
        this._onLayersInit = cb;
    }

    onMapLoad(cb?: () => void) {
        this._onMapLoad = cb;
    }

    onFuelControlToggle(cb?: (value?: boolean) => void) {
        this._onFuelControlToggle = cb;
    }

    onParkingControlToggle(cb?: (value?: boolean) => void) {
        this._onParkingControlToggle = cb;
    }

    onVehicleDetailToggle(cb: () => void) {
        this._onVehicleDetailToggle = cb;
    }

    onLayerControlToggle(cb?: (value?: boolean) => void) {
        this._onLayerControlToggle = cb;
    }

    onPoiToggle(cb?: (value?: boolean) => void) {
        this._onPoiToggle = cb;
    }
    onRoutePlanning(cb?: (value?: boolean) => void) {
        this._onRoutePlanning = cb;
    }

    onResetPoisData(cb: () => void) {
        this._onResetPoisData = cb;
    }

    routePlanningMode(value?: boolean) {
        this._onRoutePlanning?.(value);
    }

    /**
     * Method returns points that fall within (Multi)Polygon(s) created from polyline based on turf's pointsWithinPolygon
     * @external https://turfjs.org/docs/#pointsWithinPolygon
     */
    pointsInPolygon(polygon: number[][][], pois: PoiModelMap[]): PoiModelMap[] {
        const points = turf.points(pois.map(e => [e.position.lng, e.position.lat]));
        const within = turf.pointsWithinPolygon(points, turf.polygon([polygon[0].map(e => [e[1], e[0]])]));

        const filteredPoints = pois.filter(p =>
            within.features.some(
                geoPoint =>
                    geoPoint.geometry?.coordinates[0] === p.position.lng &&
                    geoPoint.geometry?.coordinates[1] === p.position.lat
            )
        );

        return filteredPoints;
    }

    setPadding(padding: mapboxgl.PaddingOptions) {
        this._mapBox?.setPadding(padding);
    }

    _createControlFuel(mapLogic: MapLogic) {
        return {
            onAdd() {
                mapLogic._fuelControl = document.createElement('input');
                mapLogic._fuelControl.type = 'image';
                mapLogic._fuelControl.disabled = mapLogic.loading;
                mapLogic._fuelControl.src = '/img/fuel-station.svg';
                mapLogic._fuelControl.className = 'mapbox-control map-control t-padding-small t-btn t-inverse';
                mapLogic._fuelControl.setAttribute('data-qa', 'map--fuel-stations-button');

                if (mapLogic._onFuelControlToggle) {
                    mapLogic._fuelControl.addEventListener('click', () => {
                        mapLogic._onFuelControlToggle!(true);

                        if (mapLogic._fuelControl!.classList.contains('t-hide')) {
                            mapLogic._fuelControl!.classList.remove('t-hide');
                            mapLogic._parkingControl!.classList.remove('t-hide');
                            mapLogic._layerControl!.classList.remove('t-hide');
                        } else {
                            mapLogic._fuelControl!.classList.add('t-hide');
                            mapLogic._parkingControl!.classList.add('t-hide');
                            mapLogic._layerControl!.classList.add('t-hide');
                        }
                    });
                }
                return mapLogic._fuelControl;
            },
            onRemove() {}
        };
    }

    _createControlParking(mapLogic: MapLogic) {
        return {
            onAdd() {
                mapLogic._parkingControl = document.createElement('input');
                mapLogic._parkingControl.type = 'input';
                mapLogic._parkingControl.value = 'P';
                mapLogic._parkingControl.disabled = mapLogic.loading;
                mapLogic._parkingControl.className = 'mapbox-control map-control t-padding-small parking t-btn';
                mapLogic._parkingControl.setAttribute('data-qa', 'map--parking-lots-button');

                if (mapLogic._onParkingControlToggle) {
                    mapLogic._parkingControl.addEventListener('click', () => {
                        mapLogic._onParkingControlToggle!(true);
                        if (mapLogic._parkingControl!.classList.contains('t-hide')) {
                            mapLogic._fuelControl!.classList.remove('t-hide');
                            mapLogic._parkingControl!.classList.remove('t-hide');
                            mapLogic._layerControl!.classList.remove('t-hide');
                        } else {
                            mapLogic._fuelControl!.classList.add('t-hide');
                            mapLogic._parkingControl!.classList.add('t-hide');
                            mapLogic._layerControl!.classList.add('t-hide');
                        }
                    });
                }

                return mapLogic._parkingControl;
            },
            onRemove() {}
        };
    }

    onContextMenu(cb: (event: any) => void) {
        this._onContextMenu = cb;
    }

    jumpTo(position: { lat: number; lng: number }, zoom?: number) {
        const currentZoomLevel = this._mapBox?.getZoom();
        const zoomLevel = zoom || currentZoomLevel;

        this._mapBox?.jumpTo({
            center: [position.lng, position.lat],
            zoom: zoomLevel
        });
    }

    getCenter(): { lng: number; lat: number } {
        return this._mapBox!.getCenter();
    }

    showFavoritesLayer(show: boolean) {
        const value = show ? 'visible' : 'none';
        this._mapBox?.setLayoutProperty('fuels-unclustered-point-favorites', 'visibility', value);
        this._mapBox?.setLayoutProperty('parking-unclustered-point-favorites', 'visibility', value);
        this._mapBox?.setLayoutProperty('fuels-clusters-favorites', 'visibility', value);
        this._mapBox?.setLayoutProperty('parking-clusters-favorites', 'visibility', value);
        this._showPlacesFavoritesLayer({ company: show, shop: show, services: show });
    }

    private _showPlacesFavoritesLayer(displayFavorites: { company: boolean; shop: boolean; services: boolean }) {
        const showCompany = displayFavorites.company ? 'visible' : 'none';
        const showServices = displayFavorites.services ? 'visible' : 'none';
        const showShop = displayFavorites.shop ? 'visible' : 'none';

        this._mapBox?.setLayoutProperty(
            'places-clusters-favorites-' + PlaceTypeLayer.COMPANY,
            'visibility',
            showCompany
        );
        this._mapBox?.setLayoutProperty(
            'places-unclustered-point-favorites-' + PlaceTypeLayer.COMPANY,
            'visibility',
            showCompany
        );
        this._mapBox?.setLayoutProperty('places-clusters-favorites-' + PlaceTypeLayer.SHOP, 'visibility', showShop);
        this._mapBox?.setLayoutProperty(
            'places-unclustered-point-favorites-' + PlaceTypeLayer.SHOP,
            'visibility',
            showShop
        );
        this._mapBox?.setLayoutProperty(
            'places-clusters-favorites-' + PlaceTypeLayer.SERVICES,
            'visibility',
            showServices
        );
        this._mapBox?.setLayoutProperty(
            'places-unclustered-point-favorites-' + PlaceTypeLayer.SERVICES,
            'visibility',
            showServices
        );
    }

    showPlacesLayer(displayPlaces: { company: boolean; shop: boolean; services: boolean }, showFavorites: boolean) {
        const showCompany = displayPlaces.company ? 'visible' : 'none';
        const showServices = displayPlaces.services ? 'visible' : 'none';
        const showShop = displayPlaces.shop ? 'visible' : 'none';

        this._mapBox?.setLayoutProperty(PlaceLayers.PLACE_CLUSTER_COMPANY, 'visibility', showCompany);
        this._mapBox?.setLayoutProperty(PlaceLayers.PLACE_UNCLUSTERED_COMPANY, 'visibility', showCompany);
        this._mapBox?.setLayoutProperty(PlaceLayers.PLACE_CLUSTER_COUNT_COMPANY, 'visibility', showCompany);
        this._mapBox?.setLayoutProperty(PlaceLayers.PLACE_CLUSTER_SHOP, 'visibility', showShop);
        this._mapBox?.setLayoutProperty(PlaceLayers.PLACE_UNCLUSTERED_SHOP, 'visibility', showShop);
        this._mapBox?.setLayoutProperty(PlaceLayers.PLACE_CLUSTER_COUNT_SHOP, 'visibility', showShop);
        this._mapBox?.setLayoutProperty(PlaceLayers.PLACE_CLUSTER_SERVICES, 'visibility', showServices);
        this._mapBox?.setLayoutProperty(PlaceLayers.PLACE_UNCLUSTERED_SERVICES, 'visibility', showServices);
        this._mapBox?.setLayoutProperty(PlaceLayers.PLACE_CLUSTER_COUNT_SERVICES, 'visibility', showServices);
        this._showPlacesFavoritesLayer({
            company: displayPlaces.company && showFavorites,
            shop: displayPlaces.shop && showFavorites,
            services: displayPlaces.services && showFavorites
        });
    }

    showMapControls(visible: boolean) {
        if (visible) {
            !this._mapBox?.hasControl(this._navigationControl) &&
                this._mapBox?.addControl(this._navigationControl, 'bottom-right');
            !this._mapBox?.hasControl(this._fullscreenControl) &&
                this._mapBox?.addControl(this._fullscreenControl, 'bottom-right');
        } else {
            this._mapBox?.hasControl(this._navigationControl) && this._mapBox?.removeControl(this._navigationControl);
            this._mapBox?.hasControl(this._fullscreenControl) && this._mapBox?.removeControl(this._fullscreenControl);
        }
    }
}
