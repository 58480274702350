import { ReactNode } from 'react';
import { Checkbox } from 'antd';
import { CheckboxGroupProps, CheckboxProps } from 'antd/lib/checkbox';

function EnhancedCheckbox(props: CheckboxProps) {
    return (
        <Checkbox {...props} className={props.className ? 'rl-checkbox ' + props.className : 'rl-checkbox'}>
            {props.children}
        </Checkbox>
    );
}

interface CheckboxGroupControlProps extends CheckboxGroupProps {
    label?: ReactNode;
    error?: ReactNode;
    children?: ReactNode;
}

EnhancedCheckbox.Group = ({ children, ...props }: CheckboxGroupControlProps) => (
    <Checkbox.Group {...props}>{children}</Checkbox.Group>
);

export default EnhancedCheckbox;
