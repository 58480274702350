import moment from 'moment';
import { TrackingModel } from 'modules/TrackingModule/TrackingModule';
import { useTranslation } from 'react-i18next';
import { remainingTimeDurationFormat, sinceDate } from 'utils/helpers/formatters';
import { Button } from 'components/base/controls';
import { ShareEtaCode, ShareEtaCodeState } from 'services/api/domains/ShareEtaApi';
import { TransportStatusIndicator } from '../TransportStatusIndicator';
import { EtaSharingIndicator } from '../EtaSharingIndicator';
import { TIME_DATE } from 'utils/constants/domain-constants';

interface Props {
    model?: TrackingModel;
    shareCode?: ShareEtaCode;
    onClose: () => void;
    onAddDestinationClick: (transportId: string) => void;
    onTransportClick: (transportId: string) => void;
    onShareEtaClick: (vehicle: TrackingModel) => void;
    onStopShareEtaClick: (shareCode?: ShareEtaCode) => void;
    onRouteOverviewClick: () => void;
    onShowOnMapClick: (vehicle: TrackingModel) => void;
    onToggleShowOnMapClick?: (vehicleId: string) => void;
}

export const TrackingDetail = (props: Props) => {
    const { t } = useTranslation();
    const sinceLastUpdate = props.model?.lastUpdate ? sinceDate(props.model?.lastUpdate) : undefined;
    const eta = props.model?.navigationRoute.eta;
    const remainingDistance = props.model?.navigationRoute.remainingDistance;
    const delayed = props.model?.trackingTransport.delayed;
    const rta = props.model?.trackingTransport.rta;

    return (
        <div className="rl-tracking-detail">
            <div className="rl-tracking-detail-header">
                <Button
                    type="action"
                    id="back-button"
                    onClick={() => props.onClose()}
                    icon={<i className="rl-icon-chevron-left" />}
                />
                <Button type="link" onClick={() => props.onShowOnMapClick(props.model!)}>
                    {t('common.showOnMap')}
                </Button>
            </div>

            <div className="rl-tracking-detail-content">
                <div className="rl-tracking-detail-action-buttons">
                    <Button
                        type="action"
                        icon={<i className={`rl-icon-eye${props.model?.visible ? '' : '-disabled'}`} />}
                        onClick={() => props.onToggleShowOnMapClick?.(props.model?.id!)}
                    />

                    <Button
                        id="close-button"
                        type="action"
                        icon={<i className="rl-icon-close" />}
                        onClick={() => props.onClose()}
                    />
                </div>

                <div className="rl-tracking-detail-content-header">
                    <img src={props.model?.driverAvatarUrl} alt="avatar" />
                    <div className="rl-tracking-detail-content-header-title">
                        <div className="rl-tracking-detail-content-header-title-registration-number">
                            {props.model?.RN}
                            {props.shareCode?.state === ShareEtaCodeState.Active && <EtaSharingIndicator />}
                        </div>
                        <div className="rl-tracking-detail-content-header-title-name">{props.model?.driverName}</div>
                    </div>
                </div>

                <section className="rl-tracking-detail-content-section">
                    <span className="rl-tracking-detail-content-section-title">{t('TrackingDetail.location')}</span>

                    <span className="rl-tracking-detail-address">{props.model?.location}</span>
                    <span className="rl-tracking-detail-coords">
                        {`${props.model?.GPS?.lat}, ${props.model?.GPS?.lng}`}
                        {sinceLastUpdate && (
                            <div className="rl-tracking-detail-last-activity">
                                ({t('TrackingDetail.lastActivity', { time: moment(props.model?.lastUpdate).fromNow() })}
                                )
                            </div>
                        )}
                    </span>

                    {props.model?.navigationRoute.polyline && (
                        <Button
                            className="route-overview-btn"
                            icon={<i className="rl-icon-route" />}
                            type="link"
                            disabled={!props.model?.visible}
                            onClick={props.onRouteOverviewClick}
                        >
                            {t('common.routeOverview')}
                        </Button>
                    )}
                </section>

                <div className="section-separator" />

                <section className="rl-tracking-detail-content-section">
                    <span className="rl-tracking-detail-content-section-title">{t('TrackingDetail.destination')}</span>
                    <div>
                        {props.model?.driverId && !props.model?.navigationRoute.destination ? (
                            <Button
                                type="link"
                                onClick={() => {
                                    props.onAddDestinationClick(props.model?.id || '');
                                }}
                            >
                                {t('TrackingDetail.addDestination')}
                            </Button>
                        ) : (
                            props.model?.navigationRoute.destination
                        )}
                    </div>
                </section>

                {eta && (
                    <>
                        <div className="section-separator" />

                        <section className="rl-tracking-detail-content-section">
                            <span className="rl-tracking-detail-content-section-title">{t('TrackingDetail.eta')}</span>
                            {props.model && (
                                <div className="rl-tracking-detail-eta">
                                    {eta && (
                                        <span className="rl-tracking-detail-eta-date">
                                            {moment(eta).format(TIME_DATE)}

                                            {delayed && (
                                                <span className="rl-tracking-detail-eta-date-delay">{`(${delayed})`}</span>
                                            )}
                                        </span>
                                    )}

                                    <div className="rl-tracking-detail-eta-route-info">
                                        {eta && (
                                            <div className="route-info-item">
                                                <span className="route-info-item-label">
                                                    {t('TrackingDetail.remainingTime')}
                                                </span>
                                                <span className="route-info-item-text">
                                                    {remainingTimeDurationFormat(eta)}
                                                </span>
                                            </div>
                                        )}

                                        {remainingDistance && (
                                            <div className="route-info-item">
                                                <span className="route-info-item-label">
                                                    {t('TrackingDetail.remainingDistance')}
                                                </span>
                                                <span className="route-info-item-text">
                                                    {remainingDistance ? (remainingDistance / 1000).toFixed(0) : 0} km
                                                </span>
                                            </div>
                                        )}
                                    </div>

                                    <div className="rl-tracking-detail-eta-buttons">
                                        <Button
                                            type="primary"
                                            title={t('TrackingDetail.shareEtaButton')}
                                            onClick={() => {
                                                props.onShareEtaClick(props.model!);
                                            }}
                                        >
                                            {t('TrackingDetail.shareEtaButton')}
                                        </Button>

                                        {props.shareCode?.state === ShareEtaCodeState.Active && (
                                            <Button
                                                type="link"
                                                title={t('TrackingDetail.stopShareEtaButton')}
                                                onClick={() => {
                                                    props.onStopShareEtaClick(props.shareCode);
                                                }}
                                            >
                                                {t('TrackingDetail.stopShareEtaButton')}
                                            </Button>
                                        )}
                                    </div>
                                </div>
                            )}
                        </section>
                    </>
                )}

                {rta && (
                    <>
                        <div className="section-separator" />

                        <section className="rl-tracking-detail-content-section">
                            <span className="rl-tracking-detail-content-section-title">
                                {t('TrackingDetail.transportRta')}
                            </span>
                            <span>{moment(props.model?.trackingTransport.rta).format(TIME_DATE)}</span>
                        </section>
                    </>
                )}

                {props.model?.trackingTransport.id && (
                    <>
                        <div className="section-separator" />

                        <section className="rl-tracking-detail-content-section">
                            <span className="rl-tracking-detail-content-section-title">
                                {t('TrackingDetail.transportName')}
                            </span>
                            <Button
                                type="link"
                                onClick={() => props.onTransportClick(props.model?.trackingTransport.id!)}
                                style={{ whiteSpace: 'normal', fontWeight: 'normal' }}
                            >
                                {props.model.trackingTransport.name}
                            </Button>
                        </section>
                    </>
                )}

                <div className="section-separator" />

                <section className="rl-tracking-detail-content-section">
                    <span className="rl-tracking-detail-content-section-title">
                        {t('TrackingDetail.transportStatus')}
                    </span>
                    <TransportStatusIndicator model={props.model} extended={true} />
                </section>
            </div>
        </div>
    );
};
