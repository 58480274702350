import { Modal as AntModal } from 'antd';
import { ModalFuncProps, ModalProps } from 'antd/lib/modal';
import { Component } from 'react';

interface Props extends ModalProps {}

interface State {}

export class EnhancedModal extends Component<Props, State> {
    static open(props: ModalFuncProps) {
        AntModal.info({
            className: 'rl-modal default',
            closeIcon: <i className="rl-icon-close" />,
            centered: true,
            maskClosable: true,
            icon: false,
            ...props
        });
    }

    static info(props: ModalFuncProps) {
        AntModal.info({
            className: 'rl-modal info',
            icon: <i className="rl-modal-icon fas fa-info-circle" />,
            closeIcon: <i className="rl-icon-close" />,
            centered: true,
            ...props
        });
    }

    static confirm(props: ModalFuncProps) {
        AntModal.confirm({
            className: 'rl-modal confirm',
            icon: <i className="rl-modal-icon fas fa-exclamation-circle" />,
            closeIcon: <i className="rl-icon-close" />,
            centered: true,
            width: 550,
            ...props
        });
    }

    static success(props: ModalFuncProps) {
        AntModal.success({
            className: 'rl-modal',
            centered: true,
            maskClosable: true,
            icon: false,
            closeIcon: <i className="rl-icon-close" />,
            ...props
        });
    }

    render() {
        return (
            <AntModal
                visible={true}
                footer={false}
                closeIcon={<i className="rl-icon-close" />}
                {...this.props}
                className={this.props.className ? 'rl-modal ' + this.props.className : 'rl-modal'}
            />
        );
    }
}

export default EnhancedModal;
