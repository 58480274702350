import { useEffect, useRef } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

interface Props extends WithTranslation {
    position?: {
        x: number;
        y: number;
    };
    isFavoritePlace: boolean;
    showPlanningItems?: boolean;
    isClickToFeature?: boolean;
    isRouteCalculated?: boolean;
    onCreatePoi?: () => void;
    onAddToRoute?: () => void;
    onAddDestination?: () => void;
    onAddStart?: () => void;
    onAddFavorite: () => void;
    onDeleteFavorite: () => void;
    onClickOutside?: () => void;
}

function ContextMenu(props: Props) {
    const menuRef = useRef(null);
    useEffect(() => {
        function handleClickOutside(event: any) {
            if (menuRef.current && !(menuRef.current as any).contains(event.target)) {
                props.onClickOutside!();
            }
        }

        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [props.onClickOutside, menuRef]);

    return (
        <div ref={menuRef} className="rl-context-menu" style={{ top: props.position!.y, left: props.position!.x }}>
            {props.showPlanningItems && (
                <>
                    <div onClick={props.onAddStart} className="t-padding t-pointer">
                        {props.t('ContextMenu.startFromHere')}
                    </div>
                    <div onClick={props.onAddDestination} className="t-padding t-pointer">
                        {props.t('ContextMenu.directionsToHere')}
                    </div>
                    {props.isRouteCalculated && (
                        <div onClick={props.onAddToRoute} className="t-padding t-pointer">
                            {props.t('ContextMenu.addToRoute')}
                        </div>
                    )}
                </>
            )}
            {props.isClickToFeature &&
                (props.isFavoritePlace ? (
                    <div onClick={props.onDeleteFavorite} className="t-padding t-pointer">
                        {props.t('ContextMenu.deleteFavorite')}
                    </div>
                ) : (
                    <div onClick={props.onAddFavorite} className="t-padding t-pointer">
                        {props.t('ContextMenu.addToFavorites')}
                    </div>
                ))}
            <div onClick={props.onCreatePoi} className="t-padding t-pointer">
                {props.t('ContextMenu.createNewCustomPlace')}
            </div>
        </div>
    );
}

export default withTranslation()(ContextMenu);
