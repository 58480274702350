import cn from 'classnames';
import { TransportState } from 'services/api/domains/TransportsApi';

interface Props {
    iso2Code: string;
    transportName?: string;
    distance: string;
    state?: TransportState;
}

const CalendarViewTransportInfo = (props: Props) => {
    return (
        <div
            className={cn('rl-calendar-view-transport-info', {
                'transport-white': props.state !== TransportState.New
            })}
        >
            {props.state && props.state !== TransportState.Finished && props.state !== TransportState.Active && (
                <div className="rl-calendar-view-transport-info-drag">
                    <img src="/img/dispatcher-board/drag-icon.svg" alt="Drag" />
                </div>
            )}
            <div className="rl-calendar-view-transport-info-distance">
                <span>{props.distance}</span>
            </div>
            <div className="rl-calendar-view-transport-info-flag">
                <span className={cn(`fi fis fi-${props.iso2Code}`)} />
            </div>
            <div className="rl-calendar-view-transport-info-name">
                <span>{props.transportName}</span>
            </div>
        </div>
    );
};

export default CalendarViewTransportInfo;
