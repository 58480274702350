import { Button } from 'components/base/controls';
import { InitiatedFrom } from 'logic/userEventsProviders/GoogleTagManager';
import { useTranslation } from 'react-i18next';

const listKeys = ['monitor', 'driverArrival', 'realTime', 'interface'];

interface Props {
    onAddVehicle: (initiatedFrom: InitiatedFrom) => void;
}

export function CalendarViewBlank(props: Props) {
    const { t } = useTranslation();

    return (
        <div className="rl-calendar-view-blank">
            <div className="rl-calendar-view-blank-title">{t('BlankModule.dispatcher.title')}</div>
            <div className="rl-calendar-view-blank-description">{t('BlankModule.dispatcher.description')}</div>

            <ul>
                {listKeys.map((key, index) => (
                    <li key={index}>
                        <i className="rl-icon-check-circle" /> {t(`BlankModule.dispatcher.${key}`)}
                    </li>
                ))}
            </ul>

            <Button type="primary" onClick={() => props.onAddVehicle(InitiatedFrom.DISPATCH_BOARD_ADD_VEHICLE_BUTTON)}>
                {t('common.addVehicle')}
            </Button>
        </div>
    );
}
