/* tslint:disable */
/* eslint-disable */
/**
 * Telematics API
 * Telematics services API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    NotificationMessage,
    NotificationMessageFromJSON,
    NotificationMessageFromJSONTyped,
    NotificationMessageToJSON,
} from '.';

/**
 * 
 * @export
 * @interface PushymeSend
 */
export interface PushymeSend {
    /**
     * 
     * @type {NotificationMessage}
     * @memberof PushymeSend
     */
    data: NotificationMessage;
    /**
     * 
     * @type {object}
     * @memberof PushymeSend
     */
    notification?: object;
}

export function PushymeSendFromJSON(json: any): PushymeSend {
    return PushymeSendFromJSONTyped(json, false);
}

export function PushymeSendFromJSONTyped(json: any, ignoreDiscriminator: boolean): PushymeSend {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': NotificationMessageFromJSON(json['data']),
        'notification': !exists(json, 'notification') ? undefined : json['notification'],
    };
}

export function PushymeSendToJSON(value?: PushymeSend | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': NotificationMessageToJSON(value.data),
        'notification': value.notification,
    };
}


