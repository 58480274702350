import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Drawer, Modal, Notification } from 'components/base/layout';
import { Logic } from 'logic/logic';
import { Button } from 'components/base/controls';
import { FuelCardFormModel } from './ui/FuelCardsAddEditForm/FuelCardsAddEditForm';
import FuelCardsTable from './ui/FuelCardsTable/FuelCardsTable';
import FuelCardsBlank from './ui/FuelCardsBlank/FuelCardsBlank';
import FuelCardsAddEditForm from './ui/FuelCardsAddEditForm/FuelCardsAddEditForm';
import { FuelCardsBrandsModel } from 'services/api/domains/FuelCardsApi';
import { FuelCardModel } from 'services/api/domains/CompanyApi';
import selectors from 'qa-selectors';
import { InitiatedFrom } from 'logic/userEventsProviders/GoogleTagManager';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import { useHistory } from 'react-router';
import { RouteNames } from 'router/routes';

interface Props {
    logic: Logic;
}

interface FuelCardTableModel {
    data?: FuelCardModel[];
    isLoading?: boolean;
}

interface Sidebar {
    visible: boolean;
    title?: string;
    isLoading?: boolean;
}

const FuelCardsModule = (props: Props) => {
    const { t } = useTranslation();
    const { isMobileWidth } = useWindowDimensions();
    const history = useHistory();

    const [table, setTable] = useState<FuelCardTableModel>({ isLoading: false });
    const [sideBar, setSidebar] = useState<Sidebar>({ visible: false });
    const [brands, setBrands] = useState<FuelCardsBrandsModel[]>();
    const [formInitValues, setFormInitValues] = useState<FuelCardFormModel>();
    const [editedId, setEditedId] = useState<number>();

    useEffect(() => {
        _loadTableData();
        _fetchBrands();
    }, []);

    const _loadTableData = async () => {
        setTable(prev => ({ ...prev, isLoading: true }));

        const companyId = props.logic.company().getCompany().companyId;

        return props.logic
            .apiService()
            .company()
            .getFuelCards(companyId)
            .then(data => {
                setTable(prev => ({ ...prev, data: data }));
            })
            .finally(() => {
                setTable(prev => ({ ...prev, isLoading: false }));
            });
    };

    const _onAddFuelCard = () => {
        setSidebar({ visible: true, title: t('FuelCardsSettings.addFuelCard') });
    };

    const _onRemove = (fuelCardId: number) => {
        Modal.confirm({
            title: t('FuelCardsSettings.deleteHeader'),
            content: t('FuelCardsSettings.deleteConfirm'),
            onOk: () => _onFuelCardRemoveConfirm(fuelCardId)
        });
    };

    const _onFuelCardRemoveConfirm = async (fuelCardId: number) => {
        const fuelCard = table.data?.find(card => card.cardId === fuelCardId);

        if (!fuelCard) {
            throw new Error('Selected fuel card does not exist');
        }

        try {
            const companyId = props.logic.company().getCompany().companyId;
            await props.logic.apiService().company().deleteFuelCard(companyId, fuelCard.cardId);

            props.logic.userEvents().deleteFuelCard(fuelCard.cardId);

            Notification.success({
                message: t('FuelCardsSettings.deleteSuccess')
            });

            _loadTableData();
        } catch (err) {
            console.error(err);
            Notification.error({
                message: err.message
            });
        }
    };

    const _closeSideBar = () => {
        setSidebar({ visible: false, isLoading: false });
        setFormInitValues(undefined);
        setEditedId(undefined);
    };

    const _fetchBrands = async () => {
        const brands = await props.logic.apiService().fuelCards().getBrands();
        setBrands(brands);
    };

    const _onCreateFuelCard = async (fuelCard: FuelCardFormModel) => {
        setSidebar(prev => ({ ...prev, isLoading: true }));

        try {
            const companyId = props.logic.company().getCompany().companyId;
            await props.logic.apiService().company().postFuelCard(companyId, fuelCard);

            props.logic.userEvents().addFuelCard(fuelCard, InitiatedFrom.SETTINGS_FUEL_CARDS_ADD_BUTTON);

            Notification.success({
                message: t('FuelCardsSettings.createSuccess')
            });

            _loadTableData();
            _closeSideBar();
        } catch (err) {
            console.error(err);
            Notification.error({
                message: err.message
            });
        }
    };

    const _onEditFuelCard = async (fuelCard: FuelCardFormModel) => {
        setSidebar(prev => ({ ...prev, isLoading: true }));

        try {
            const companyId = props.logic.company().getCompany().companyId;
            await props.logic.apiService().company().putFuelCard(companyId, editedId!, fuelCard);

            props.logic.userEvents().updateFuelCard(fuelCard);

            Notification.success({
                message: t('FuelCardsSettings.editSuccess')
            });

            _closeSideBar();
            _loadTableData();
        } catch (err) {
            console.error(err);
            Notification.error({
                message: err.message
            });
        }
    };

    const _onEditClick = (fuelCard: FuelCardModel) => {
        setSidebar({ visible: true, title: t('FuelCardsSettings.editFuelCard') });

        setFormInitValues({
            brandId: fuelCard.brandId,
            description: fuelCard.description,
            discount: fuelCard.discount
        });

        setEditedId(fuelCard.cardId);
    };

    return (
        <div className="rl-settings-module rl-fuel-cards-module">
            <div className="rl-settings-module-header">
                {isMobileWidth && (
                    <Button
                        className="rl-settings-module-header-back"
                        type="action"
                        icon={<i className="rl-icon-chevron-left" />}
                        onClick={() => history.push(RouteNames.SETTINGS)}
                    />
                )}

                <h3 className="rl-settings-module-header-title">{t('common.fuelCards')}</h3>

                {table.data?.length !== 0 && (
                    <Button type="link" onClick={_onAddFuelCard} data-qa={selectors.fuelCards.addFuelCardButton}>
                        {t('FuelCardsSettings.addFuelCard')}
                    </Button>
                )}
            </div>
            <div className="rl-settings-module-content rl-fuel-cards-module-content">
                {table.data?.length === 0 ? (
                    <FuelCardsBlank onFuelCardClick={_onAddFuelCard} />
                ) : (
                    <FuelCardsTable
                        isLoading={table.isLoading}
                        data={table?.data || []}
                        onRemoveClick={_onRemove}
                        onRowDoubleClick={_onEditClick}
                        onEditClick={_onEditClick}
                    />
                )}
            </div>

            <Drawer
                width={580}
                title={sideBar.title}
                visible={sideBar.visible}
                destroyOnClose={true}
                placement="right"
                className="rl-fuel-cards-module-drawer"
                onClose={_closeSideBar}
            >
                <FuelCardsAddEditForm
                    brands={brands}
                    initValues={formInitValues}
                    isLoading={sideBar.isLoading}
                    onCreate={_onCreateFuelCard}
                    onEdit={_onEditFuelCard}
                />
            </Drawer>
        </div>
    );
};

export default FuelCardsModule;
