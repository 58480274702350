import moment from 'moment';
import { toAddress } from './common/address';
import { Logic } from './logic';
import { DATE_FORMAT } from 'utils/constants/domain-constants';
import { TableViewModel } from 'modules/DispatcherBoardModule/TableViewModule/TableViewModule';
import { CompanyVehicle } from '../services/api/domains/VehiclesApi';
import { AddressIdentification } from 'conf';
import { RootStore } from 'stores/RootStore';
import { IReactionDisposer, reaction } from 'mobx';
import { CompanyTransport, TransportState } from 'services/api/domains/TransportsApi';
import { mapTransportStateToCompanyTransportStatus, mapTransportStatusToTransportState } from './common/transports';

export interface DateRange {
    start: string;
    end: string;
}

export class SchedulingRouteOverviewLogic {
    private _vehicles: CompanyVehicle[];
    private _transports: CompanyTransport[];
    private _tableViewData: TableViewModel[];
    private _addressIdentification?: AddressIdentification;
    private _mobxDisposeFunctions: IReactionDisposer[] = [];

    private _onData?: (transports: TableViewModel[], vehicles: CompanyVehicle[]) => void;
    private _onTransportDelete?: (transportId: string) => void;

    constructor(private _logic: Logic, private _store: RootStore) {
        this._tableViewData = [];
        this._vehicles = [];
        this._transports = [];
        this._addressIdentification = this._store.userSettings.addressIdentification;
    }

    get tableViewData() {
        return this._tableViewData;
    }

    async init(dateRange: DateRange) {
        this._addressIdentification = this._store.userSettings.addressIdentification;
        await this.loadData(dateRange);

        this._mobxDisposeFunctions.push(
            reaction(
                () => this._store.userSettings.lang,
                () => {
                    this._tableViewData = this._transports.map(t => this._toRouteOverview(t));
                    this._onData?.(this._tableViewData, this._vehicles);
                }
            )
        );

        this._mobxDisposeFunctions.push(
            reaction(
                () => this._store.userSettings.addressIdentification,
                value => {
                    this._addressIdentification = value;
                    this._tableViewData = this._transports.map(t => this._toRouteOverview(t));
                    this._onData?.(this._tableViewData, this._vehicles);
                }
            )
        );
    }

    destroy() {
        this._onData = undefined;
        this._tableViewData = [];
        this._vehicles = [];
        this._transports = [];
        this._mobxDisposeFunctions.forEach(disposer => disposer());
        this._mobxDisposeFunctions = [];
    }

    onData(cb: (transports: TableViewModel[], vehicles: CompanyVehicle[]) => void) {
        this._onData = cb;
    }

    onTransportDelete(cb: (transportId: string) => void) {
        this._onTransportDelete = cb;
    }

    vehicleByRn(rn: string) {
        return this._vehicles?.find(v => v.registrationNumber === rn);
    }

    async loadData(dateRange: DateRange) {
        await this.loadVehicles();
        this._fetchTransports(dateRange).then(data => {
            this._tableViewData = data;
            this._onData?.(data, this._vehicles);
        });
    }

    async loadVehicles(): Promise<CompanyVehicle[]> {
        this._vehicles = await this._logic.vehicles().getVehicles();
        return this._vehicles;
    }

    async addVehicleToTransport(vehicleId: string, transportId: string, state: TransportState) {
        const companyId = this._logic.company().getCompany().companyId;
        const vehicle = this._transports.find(t => t.vehicle?.vehicleId === +vehicleId)?.vehicle;

        await this._logic
            .apiService()
            .transports()
            .patchTransport(companyId, +transportId, {
                vehicleId: +vehicleId,
                route: {
                    cost: vehicle?.costPerKm
                },
                status: mapTransportStateToCompanyTransportStatus(state)
            });
    }

    async removeTransport(transportId: string) {
        const companyId = this._logic.company().getCompany().companyId;
        await this._logic.apiService().transports().deleteTransport(companyId, +transportId);

        this._onTransportDelete?.(transportId);
    }

    setTransportState(transportId: string, state: TransportState) {
        const companyId = this._logic.company().getCompany().companyId;
        return this._logic
            .apiService()
            .transports()
            .patchTransport(companyId, +transportId, { status: mapTransportStateToCompanyTransportStatus(state) });
    }

    private async _fetchTransports(dateRange: DateRange): Promise<TableViewModel[]> {
        try {
            const companyId = this._logic.company().getCompany().companyId;
            this._transports = await this._logic
                .apiService()
                .transports()
                .getTransports(companyId, {
                    dateFrom: moment(dateRange.start, DATE_FORMAT).utc(true).toDate(),
                    dateTo: moment(dateRange.end, DATE_FORMAT).utc(true).toDate()
                });

            return this._transports.map(t => this._toRouteOverview(t));
        } catch (err) {
            console.error('Get transports error!', err);
            throw err;
        }
    }

    private _toRouteOverview(transport: CompanyTransport): TableViewModel {
        const transportVehicle = this._vehicles.find(v => v.vehicleId === transport.vehicle?.vehicleId);

        return {
            id: transport.transportId?.toString() ?? '',
            name: transport.name ?? '',
            dateAndTimeStart: transport.route.points?.[0].arrival ?? '',
            dateAndTimeFinish: transport.route.points?.[transport.route?.points.length - 1].arrival ?? '',
            firstPointOnTheRoute: toAddress(
                this._store.userSettings.lang,
                [
                    {
                        address: transport?.route?.points?.[0]?.address!,
                        lang: '',
                        countryCode: transport?.route?.points?.[0].iso3!,
                        country: '',
                        town: '',
                        route: '',
                        streetAddress: '',
                        postalCode: ''
                    }
                ],
                this._addressIdentification!,
                transport?.route.points?.[0].name
            ),
            lastPointOnTheRoute: toAddress(
                this._store.userSettings.lang,
                [
                    {
                        address: transport?.route?.points?.[transport?.route.points.length - 1]?.address!,
                        lang: '',
                        countryCode: transport?.route?.points?.[transport?.route.points.length - 1].iso3!,
                        country: '',
                        town: '',
                        route: '',
                        streetAddress: '',
                        postalCode: ''
                    }
                ],
                this._addressIdentification!,
                transport?.route.points?.[transport?.route.points.length - 1].name
            ),
            state: mapTransportStatusToTransportState(transport.status!) ?? ('' as any),
            vehicleRN: transportVehicle?.registrationNumber || '',
            driver: transport.driver
                ? `${transport.driver?.name || transport.driver?.alias || transport.driver?.nick}`
                : '-',
            RTA: transport.route.points?.[transport.route.points?.length - 1]?.arrival ?? '',
            ETA: transport.eta || '',
            vehicleId: transportVehicle?.vehicleId,
            driverId: transport.driver?.profileId ? transport.driver?.profileId : undefined
        };
    }
}
