import { Button } from 'components/base/controls';
import { useTranslation } from 'react-i18next';

interface Props {
    onFuelCardClick: () => void;
}

function FuelCardsBlank(props: Props) {
    const { t } = useTranslation();

    return (
        <div className="rl-settings-blank rl-fuel-cards-blank">
            <img src="/img/settings/fuel-cards/add-fuel-card.svg" alt="Add fuel card icon" />
            <h2>{t('FuelCardsBlank.title')}</h2>
            <p>{t('FuelCardsBlank.text')}</p>

            <Button
                type="primary"
                size="large"
                icon={<i className="rl-button-icon fas fa-plus" />}
                onClick={props.onFuelCardClick}
            >
                {t('FuelCardsBlank.addFuelCard')}
            </Button>
        </div>
    );
}

export default FuelCardsBlank;
