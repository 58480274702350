import { useTranslation } from 'react-i18next';
import { NavLink, Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { RouteNames } from 'router/routes';
import { Logic } from 'logic/logic';
import PreferencesModule from './modules/PreferencesModule/PreferencesModule';
import FleetModule from './modules/FleetModule/FleetModule';
import DriversModule from './modules/DriversModule/DriversModule';
import DispatcherModule from './modules/DispatcherModule/DispatcherModule';
import FuelCardsModule from './modules/FuelCardsModule/FuelCardsModule';
import FavoritesModule from './modules/FavoritesModule/FavoritesModule';
import CompanyModule from './modules/CompanyModule/CompanyModule';
import selectors from 'qa-selectors';
import { useWindowDimensions } from 'hooks/useWindowDimensions';

interface Props {
    logic: Logic;
}

function SettingsModule(props: Props) {
    const location = useLocation();
    const { isMobileWidth } = useWindowDimensions();

    const isSettingMenuActivated = () => {
        return location.pathname === RouteNames.SETTINGS;
    };

    return (
        <div className="rl-settings-wrapper">
            <div className="rl-settings">
                {(!isMobileWidth || isSettingMenuActivated()) && <SettingsSidebarMenu />}

                <Switch>
                    <Route exact path={RouteNames.SETTINGS_FLEET}>
                        <FleetModule logic={props.logic} />
                    </Route>
                    <Route exact path={RouteNames.SETTINGS_DRIVERS}>
                        <DriversModule logic={props.logic} />
                    </Route>
                    <Route exact path={RouteNames.SETTINGS_DISPATCHERS}>
                        <DispatcherModule logic={props.logic} />
                    </Route>
                    <Route exact path={RouteNames.SETTINGS_FUEL_CARDS}>
                        <FuelCardsModule logic={props.logic} />
                    </Route>
                    <Route exact path={RouteNames.SETTINGS_COMPANY}>
                        <CompanyModule logic={props.logic} />
                    </Route>
                    <Route exact path={RouteNames.SETTINGS_FAVORITES}>
                        <FavoritesModule logic={props.logic} />
                    </Route>
                    <Route exact path={RouteNames.SETTINGS_PREFERENCES}>
                        <PreferencesModule logic={props.logic} />
                    </Route>

                    {!isMobileWidth && <Redirect to={RouteNames.SETTINGS_FLEET} />}
                </Switch>
            </div>
        </div>
    );
}

function SettingsSidebarMenu() {
    const { t } = useTranslation();

    return (
        <div className="rl-settings-menu">
            <div className="rl-settings-menu-header">
                <h3>{t('common.settings')}</h3>
            </div>
            <div className="rl-settings-menu-items">
                <NavLink
                    className="rl-settings-menu-item"
                    to={RouteNames.SETTINGS_FLEET}
                    data-qa={selectors.settingsSidebar.fleetLink}
                >
                    <i className="rl-icon-fleet" />
                    {t('common.fleet')}
                    <i className="rl-icon-chevron-right" />
                </NavLink>

                <NavLink
                    className="rl-settings-menu-item"
                    to={RouteNames.SETTINGS_DRIVERS}
                    data-qa={selectors.settingsSidebar.driversLink}
                >
                    <i className="rl-icon-steering-wheel" />
                    {t('common.drivers')}
                    <i className="rl-icon-chevron-right" />
                </NavLink>

                <NavLink
                    className="rl-settings-menu-item"
                    to={RouteNames.SETTINGS_DISPATCHERS}
                    data-qa={selectors.settingsSidebar.dispatchersLink}
                >
                    <i className="rl-icon-dispatcher" />
                    {t('common.dispatchers')}
                    <i className="rl-icon-chevron-right" />
                </NavLink>

                <NavLink
                    className="rl-settings-menu-item"
                    to={RouteNames.SETTINGS_FUEL_CARDS}
                    data-qa={selectors.settingsSidebar.fuelCardsLink}
                >
                    <i className="rl-icon-fuel-card" />
                    {t('common.fuelCards')}
                    <i className="rl-icon-chevron-right" />
                </NavLink>

                <NavLink
                    className="rl-settings-menu-item"
                    to={RouteNames.SETTINGS_FAVORITES}
                    data-qa={selectors.settingsSidebar.favoritesLink}
                >
                    <i className="rl-icon-heart" />
                    {t('common.favorites')}
                    <i className="rl-icon-chevron-right" />
                </NavLink>

                <NavLink
                    className="rl-settings-menu-item"
                    to={RouteNames.SETTINGS_COMPANY}
                    data-qa={selectors.settingsSidebar.companyLink}
                >
                    <i className="rl-icon-company" />
                    {t('common.company')}
                    <i className="rl-icon-chevron-right" />
                </NavLink>

                <NavLink
                    className="rl-settings-menu-item"
                    to={RouteNames.SETTINGS_PREFERENCES}
                    data-qa={selectors.settingsSidebar.preferencesLink}
                >
                    <i className="rl-icon-settings" />
                    {t('common.preferences')}
                    <i className="rl-icon-chevron-right" />
                </NavLink>
            </div>
        </div>
    );
}

export default SettingsModule;
