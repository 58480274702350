import { useTranslation } from 'react-i18next';
import { HowInviteDriverWork } from '../HowInviteDriverWork';
import { InviteLink } from '../InviteLink';
import { ShareButtons } from 'components/ShareButtons';
import { getInviteTextForWhatsApp } from 'utils/helpers/invite-driver';
import { Button } from 'components/base/controls';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import { InitiatedFrom } from 'logic/userEventsProviders/GoogleTagManager';
import { useEffect } from 'react';

interface Props {
    inviteLink: string;
    onDisableLinkClick: () => void;
    onCreateNewLinkClick: () => void;
    onCancel?: () => void;
    onBtnClick: (initiatedFrom: InitiatedFrom) => void;
    setInviteLink: () => void;
}

function InviteDriver(props: Props) {
    const { t } = useTranslation();
    const { isMobileWidth } = useWindowDimensions();

    useEffect(() => {
        props.setInviteLink();
    }, []);

    return (
        <div className="rl-assign-driver-blank">
            <div className="rl-assign-driver-header">
                {isMobileWidth && (
                    <Button type="action" icon={<i className="rl-icon-chevron-left" />} onClick={props.onCancel} />
                )}
            </div>

            <div className="rl-assign-driver-blank-content">
                <img src="/img/assign-driver-modal/driver-icon.svg" alt="Avatar icon" />
                <h3>{t('InviteDriver.title')}</h3>
                <p>{t('InviteDriver.text')}</p>

                <InviteLink
                    inviteLink={props.inviteLink}
                    onCreateNewLinkClick={props.onCreateNewLinkClick}
                    onDisableLinkClick={props.onDisableLinkClick}
                    onCopyClik={props.onBtnClick}
                />
                <ShareButtons
                    inviteLink={props.inviteLink}
                    mailMessage={t('common.inviteDriverMailText', { link: props.inviteLink })}
                    whatsappMessage={getInviteTextForWhatsApp(props.inviteLink)}
                    onBtnClick={props.onBtnClick}
                />
            </div>

            <div className="rl-assign-driver-blank-footer">
                <HowInviteDriverWork />
            </div>
        </div>
    );
}

export default InviteDriver;
