import { Col, Form, Row } from 'antd';
import { Button, Input, Select } from 'components/base/controls';
import { EmissionClassType, FuelType, HazardLoadType, TunnelType, VehicleType } from 'services/api/domains/VehiclesApi';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import selectors from 'qa-selectors';

export interface FleetCreateEditProps {
    type: 'CREATE' | 'EDIT';
    initValues?: Partial<FleetFormModel>;
    loading?: boolean;
    onSubmit: (values: FleetFormModel) => void;
    onChange?: (values: FleetFormModel) => void;
    onDeleteClick?: (vehicleId: number) => void;
    onShowOnMapClick?: (vehicleId: number) => void;
    onAssignDriverClick?: () => void;
    onUnassignDriverClick?: () => void;
}

export interface FleetFormModel {
    vehicleId?: number;
    registrationNumber: string;
    name: string;
    tunnelCode: number;
    emissionClass: number;
    hazardousLoadType: number;
    vehicleAxles: number;
    width: number;
    height: number;
    vehicleType: number;
    axleWeight: number;
    totalLength: number;
    totalWeight: number;
    costPerKm?: number;
    fuelType?: number;
    driver?: { driverId: number; name: string; avatarUrl: string };
    trailersCount: number;
    maxSpeed: number;
}

const VEHICLE_DIMENSIONS = {
    [VehicleType.Car]: {
        totalWeight: 1.2,
        totalLength: 4.6,
        width: 1.8,
        height: 1.5,
        vehicleAxles: 2,
        axleWeight: 1,
        costPerKm: 0.5,
        emissionClass: EmissionClassType.Euro5,
        fuelType: FuelType.Gasoline,
        maxSpeed: 130
    },
    [VehicleType.Truck]: {
        totalWeight: 10,
        totalLength: 18.5,
        width: 2.7,
        height: 3.5,
        vehicleAxles: 5,
        axleWeight: 11.5,
        costPerKm: 0.95,
        emissionClass: EmissionClassType.Euro5,
        fuelType: FuelType.Diesel,
        maxSpeed: 90
    },
    [VehicleType.Taxi]: {
        totalWeight: 1.2,
        totalLength: 4.6,
        width: 1.8,
        height: 1.5,
        vehicleAxles: 2,
        axleWeight: 1,
        costPerKm: 0.5,
        emissionClass: EmissionClassType.Euro5,
        fuelType: FuelType.Gasoline,
        maxSpeed: 130
    },
    [VehicleType.Van]: {
        totalWeight: 3.5,
        totalLength: 18.5,
        width: 2.7,
        height: 3.5,
        vehicleAxles: 2,
        axleWeight: 2.5,
        costPerKm: 0.5,
        emissionClass: EmissionClassType.Euro5,
        fuelType: FuelType.Diesel,
        maxSpeed: 130
    },
    [VehicleType.Bus]: {
        totalWeight: 3.5,
        totalLength: 20,
        width: 3,
        height: 4,
        vehicleAxles: 3,
        axleWeight: 8,
        costPerKm: 0.75,
        emissionClass: EmissionClassType.Euro5,
        fuelType: FuelType.Diesel,
        maxSpeed: 90
    }
};

const DEFAULT_VEHICLE_TYPE = VehicleType.Truck;

export default function FleetCreateEditForm(props: FleetCreateEditProps) {
    const { t } = useTranslation();
    const [form] = Form.useForm<FleetFormModel>();
    const [formTouched, setFormTouched] = useState(false);
    const [vehicleType, setVehicleType] = useState(DEFAULT_VEHICLE_TYPE);

    function _onFinish(formValues: FleetFormModel) {
        props.onSubmit({ ...props.initValues, ...formValues });
    }

    useEffect(() => {
        if (props.initValues) {
            form.setFieldsValue(props.initValues);
            setVehicleType(props.initValues.vehicleType!);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.initValues]);

    function _onValuesChange(changedValues: Partial<FleetFormModel>) {
        setFormTouched(form.isFieldsTouched());

        if (changedValues.vehicleType) {
            setVehicleType(changedValues.vehicleType);

            form.setFieldsValue({
                ...VEHICLE_DIMENSIONS[changedValues.vehicleType]
            });
        }

        props.onChange?.({
            ...props.initValues,
            ...form.getFieldsValue()
        });
    }

    function _getInitVehicleValues(vehicleType: VehicleType) {
        return {
            vehicleType: vehicleType,
            ...VEHICLE_DIMENSIONS[vehicleType]
        };
    }

    function _onAssignDriverClick() {
        props.onAssignDriverClick?.();
        setFormTouched(true);
    }

    function _onUnassignDriverClick() {
        props.onUnassignDriverClick?.();
        setFormTouched(true);
    }

    const _maxSpeedHidden = () => {
        return vehicleType && ![VehicleType.Truck, VehicleType.Bus].includes(vehicleType);
    };

    const _trailersCountHidden = () => {
        return vehicleType && ![VehicleType.Truck].includes(vehicleType);
    };

    return (
        <div className="rl-settings-fleet-form">
            <div className="rl-settings-fleet-form-content">
                <Form<FleetFormModel>
                    form={form}
                    id="fleetCreateEditForm"
                    layout="vertical"
                    name="fleetCreateEditForm"
                    initialValues={{
                        ..._getInitVehicleValues(DEFAULT_VEHICLE_TYPE), // Default vehicle type
                        hazardousLoadType: HazardLoadType.None,
                        tunnelCode: TunnelType.Unknown,
                        trailersCount: 0,
                        ...props.initValues
                    }}
                    onFinish={_onFinish}
                    onValuesChange={_onValuesChange}
                    className="rl-form"
                >
                    <Row>
                        <Col span={24}>
                            <Form.Item label={t('FleetCreateEditForm.assignedDriver')} style={{ width: '100%' }}>
                                {props.initValues?.driver ? (
                                    <div className="rl-settings-fleet-form-assigned-driver">
                                        <div className="driver-name">
                                            <img src={props.initValues.driver.avatarUrl} alt="Driver avatar" />
                                            <strong>{props.initValues.driver.name}</strong>
                                        </div>
                                        <div className="action-btns">
                                            <Button type="link" onClick={_onAssignDriverClick}>
                                                {t('FleetTable.change')}
                                            </Button>
                                            <span className="divider" />
                                            <Button type="link" onClick={_onUnassignDriverClick}>
                                                {t('FleetTable.unassign')}
                                            </Button>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="rl-settings-fleet-form-assign">
                                        <p>
                                            <Trans i18nKey="FleetCreateEditForm.assignText">
                                                Assign and track your drivers real-time with the
                                                <a
                                                    href="https://roadlords.com/download"
                                                    rel="noreferrer"
                                                    target="_blank"
                                                >
                                                    RoadLords Truck navigation app
                                                </a>
                                                installed on your drivers’ devices.
                                            </Trans>
                                        </p>
                                        <Button type="link" onClick={_onAssignDriverClick}>
                                            {t('FleetCreateEditForm.assignDriver')}
                                        </Button>
                                    </div>
                                )}
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={[24, 0]} style={{ marginTop: '8px' }}>
                        <Col md={12} sm={24} xs={24}>
                            <Form.Item
                                label={t('FleetCreateEditForm.vehicleType')}
                                name="vehicleType"
                                rules={[{ required: true }]}
                            >
                                <Select qa={selectors.fleet.addVehicleModal.typeSelect}>
                                    {Object.keys(VehicleType)
                                        .filter(key => isNaN(+key))
                                        .map((v, i) => (
                                            <Select.Option key={i} value={VehicleType[v]}>
                                                {t(`common.vehicleTypeOptions.${v}`)}
                                            </Select.Option>
                                        ))}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col md={12} sm={24} xs={24}>
                            <Form.Item
                                label={t('common.registrationNumber')}
                                name="registrationNumber"
                                rules={[{ required: true }]}
                            >
                                <Input data-qa={selectors.fleet.addVehicleModal.registrationNumberInput} />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row>
                        <Col span="24">
                            <Form.Item label={t('common.name')} name="name" rules={[{ required: true }]}>
                                <Input type="text" data-qa={selectors.fleet.addVehicleModal.nameInput} />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row style={{ marginTop: '8px', marginBottom: '24px' }}>
                        <Col span="24">
                            <label className="params-label">{t('FleetCreateEditForm.paramsLabel')}</label>
                        </Col>
                    </Row>

                    <Row gutter={[20, 0]} style={{ marginTop: '8px' }}>
                        <Col span="8" md={8} sm={12} xs={12}>
                            <Form.Item
                                label={`${t('common.weight')} [${t('common.metrics.tonUnit')}]`}
                                name="totalWeight"
                                rules={[{ type: 'number', required: true, min: 1, max: 50 }]}
                            >
                                <Input.Number type="number" data-qa={selectors.fleet.addVehicleModal.weightInput} />
                            </Form.Item>
                        </Col>
                        <Col span="8" md={8} sm={12} xs={12}>
                            <Form.Item
                                label={`${t('common.length')} [${t('common.metrics.meterUnit')}]`}
                                name="totalLength"
                                rules={[{ type: 'number', required: true, min: 1 }]}
                            >
                                <Input.Number
                                    type="number"
                                    step={0.1}
                                    data-qa={selectors.fleet.addVehicleModal.lengthInput}
                                />
                            </Form.Item>
                        </Col>
                        <Col span="8" md={8} sm={12} xs={12}>
                            <Form.Item
                                label={`${t('common.width')} [${t('common.metrics.meterUnit')}]`}
                                name="width"
                                rules={[{ type: 'number', required: true, min: 1 }]}
                            >
                                <Input.Number
                                    type="number"
                                    step={0.1}
                                    data-qa={selectors.fleet.addVehicleModal.widthInput}
                                />
                            </Form.Item>
                        </Col>

                        <Col span="8" md={8} sm={12} xs={12}>
                            <Form.Item
                                label={`${t('common.height')} [${t('common.metrics.meterUnit')}]`}
                                name="height"
                                rules={[{ type: 'number', required: true, min: 1 }]}
                            >
                                <Input.Number
                                    type="number"
                                    step={0.1}
                                    data-qa={selectors.fleet.addVehicleModal.heightInput}
                                />
                            </Form.Item>
                        </Col>
                        <Col span="8" md={8} sm={12} xs={12}>
                            <Form.Item
                                label={t('FleetCreateEditForm.axlesCount')}
                                name="vehicleAxles"
                                rules={[{ type: 'number', required: true, min: 1 }]}
                            >
                                <Input.Number type="number" data-qa={selectors.fleet.addVehicleModal.axlesCountInput} />
                            </Form.Item>
                        </Col>
                        <Col span="8" md={8} sm={12} xs={12}>
                            <Form.Item
                                label={`${t('FleetCreateEditForm.axleWeight')} [${t('common.metrics.tonUnit')}]`}
                                name="axleWeight"
                                rules={[{ type: 'number', required: true, min: 1 }]}
                            >
                                <Input.Number
                                    type="number"
                                    value={22}
                                    data-qa={selectors.fleet.addVehicleModal.axleWeightInput}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={[24, 0]} style={{ marginTop: '8px' }}>
                        <Col span="12" md={12} xs={24}>
                            <Form.Item label={t('FleetCreateEditForm.fuelType')} name="fuelType">
                                <Select qa={selectors.fleet.addVehicleModal.fuelTypeSelect}>
                                    {Object.keys(FuelType)
                                        .filter(key => isNaN(+key))
                                        .map((v, i) => (
                                            <Select.Option key={i} value={FuelType[v]}>
                                                {t(`FuelTypes.${v}`)}
                                            </Select.Option>
                                        ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span="12" md={12} xs={24}>
                            <Form.Item
                                label={t('FleetCreateEditForm.emissionClass')}
                                name="emissionClass"
                                rules={[{ required: true }]}
                                tooltip={{
                                    title: <Trans i18nKey="FleetCreateEditForm.emissionClassTooltip" />,
                                    icon: (
                                        <span>
                                            <i className="rl-tooltip-icon rl-icon-info" />
                                        </span>
                                    )
                                }}
                            >
                                <Select qa={selectors.fleet.addVehicleModal.emissionClassSelect}>
                                    {Object.keys(EmissionClassType)
                                        .filter(key => isNaN(+key))
                                        .map((v, i) => (
                                            <Select.Option key={i} value={EmissionClassType[v]}>
                                                {t(`FleetCreateEditForm.emissionClassOptions.${v}`)}
                                            </Select.Option>
                                        ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={[24, 0]}>
                        <Col span="12" md={12} xs={24}>
                            <Form.Item
                                label={t('FleetCreateEditForm.tunnelCode')}
                                name="tunnelCode"
                                tooltip={{
                                    title: <Trans i18nKey="FleetCreateEditForm.tunnelCodeTooltip" />,
                                    icon: (
                                        <span>
                                            <i className="rl-tooltip-icon rl-icon-info" />
                                        </span>
                                    )
                                }}
                            >
                                <Select qa={selectors.fleet.addVehicleModal.adrTunnelCodeSelect}>
                                    {Object.keys(TunnelType)
                                        .filter(key => isNaN(+key))
                                        .map((v, i) => (
                                            <Select.Option key={i} value={TunnelType[v]}>
                                                {t(`FleetCreateEditForm.tunnelOptions.${v.toLowerCase()}`)}
                                            </Select.Option>
                                        ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span="12" md={12} xs={24}>
                            <Form.Item
                                label={t('FleetCreateEditForm.costPerKm')}
                                name="costPerKm"
                                rules={[{ type: 'number', min: 0.01 }]}
                            >
                                <Input.Number
                                    type="number"
                                    step={0.1}
                                    data-qa={selectors.fleet.addVehicleModal.costperKmInput}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={[24, 0]}>
                        <Col span="12" md={12} xs={24} hidden={_maxSpeedHidden()}>
                            <Form.Item
                                label={`${t('FleetCreateEditForm.maxSpeed')}`}
                                name="maxSpeed"
                                rules={[{ type: 'number', min: 1, max: 255, required: true }]}
                                tooltip={{
                                    title: <Trans i18nKey="FleetCreateEditForm.maxSpeedTooltip" />,
                                    icon: (
                                        <span>
                                            <i className="rl-tooltip-icon rl-icon-info" />
                                        </span>
                                    )
                                }}
                            >
                                <Input.Number type="number" data-qa={selectors.fleet.addVehicleModal.maxSpeedInput} />
                            </Form.Item>
                        </Col>
                        <Col span="12" md={12} xs={24} hidden={_trailersCountHidden()}>
                            <Form.Item
                                label={`${t('FleetCreateEditForm.trailersCount')}`}
                                name="trailersCount"
                                rules={[{ type: 'number', min: 0, required: true }]}
                                tooltip={{
                                    title: <Trans i18nKey="FleetCreateEditForm.trailersCountTooltip" />,
                                    icon: (
                                        <span>
                                            <i className="rl-tooltip-icon rl-icon-info" />
                                        </span>
                                    )
                                }}
                            >
                                <Input.Number type="number" data-qa={selectors.fleet.addVehicleModal.trailersCount} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[24, 0]}>
                        <Col span="12" md={12} xs={24}>
                            <Form.Item
                                label={t('FleetCreateEditForm.hazardousLoad')}
                                name="hazardousLoadType"
                                tooltip={{
                                    title: t('FleetCreateEditForm.hazardousLoadTooltip'),
                                    icon: (
                                        <span>
                                            <i className="rl-tooltip-icon rl-icon-info" />
                                        </span>
                                    )
                                }}
                            >
                                <Select data-qa={selectors.fleet.addVehicleModal.hazardousLoadSelect}>
                                    {Object.keys(HazardLoadType)
                                        .filter(key => isNaN(+key))
                                        .map((v, i) => (
                                            <Select.Option key={i} value={HazardLoadType[v]}>
                                                {t(`FleetCreateEditForm.hazardLoadsOptions.${v}`)}
                                            </Select.Option>
                                        ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
            <div className="rl-settings-fleet-form-actions">
                {props.type === 'CREATE' && (
                    <Button
                        id="create-button"
                        type="primary"
                        form="fleetCreateEditForm"
                        size="large"
                        htmlType="submit"
                        loading={props.loading ? { delay: 500 } : false}
                        style={{ width: '100%' }}
                        data-qa={selectors.fleet.addVehicleModal.saveButton}
                    >
                        {t('common.save')}
                    </Button>
                )}

                {props.type === 'EDIT' && (
                    <div className="rl-settings-fleet-form-buttons">
                        <div className="rl-settings-fleet-form-buttons-left">
                            <Button
                                id="delete-button"
                                style={{ width: 130 }}
                                danger
                                type="link"
                                title={t('FleetCreateEditForm.deleteVehicle')}
                                onClick={() => props.onDeleteClick?.(props.initValues?.vehicleId!)}
                                icon={<i className="rl-icon-bin" />}
                            >
                                {t('FleetCreateEditForm.deleteVehicle')}
                            </Button>

                            <Button
                                id="show-on-map-button"
                                style={{ width: 130 }}
                                type="link"
                                title={t('common.showOnMap')}
                                onClick={() => props.onShowOnMapClick?.(props.initValues?.vehicleId!)}
                                icon={<i className="rl-icon-map-pin" />}
                            >
                                {t('common.showOnMap')}
                            </Button>
                        </div>

                        <Button
                            id="save-button"
                            type="primary"
                            form="fleetCreateEditForm"
                            htmlType="submit"
                            disabled={!formTouched}
                            loading={props.loading ? { delay: 500 } : false}
                        >
                            {t('common.saveChanges')}
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
}
