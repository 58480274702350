import React from 'react';
import { Switch, Route, Redirect, RouteComponentProps, withRouter } from 'react-router-dom';
import { WithTranslation, withTranslation } from 'react-i18next';
import { ConfigProvider } from 'antd';
import { Notification } from './components/base/layout/Notification';

import { Logic } from 'logic/logic';
import { Role } from 'stores/auth/types';
import { getLocale } from 'utils/helpers/antd-locale';
import { AlarmInDatabaseWithGPSInfo } from './generated/backend-api';
import { NavBar } from 'components/base/layout';

import AlarmsModule from './modules/AlarmsModule/AlarmsModule';
import SettingsModule from 'modules/SettingsModule/SettingsModule';
import TrackingModule from 'modules/TrackingModule/TrackingModule';
import Map from 'modules/MapModule/MapModule';
import PlannerModule from 'modules/PlannerModule/PlannerModule';
import { ModalsModule } from 'modules/ModalsModule/ModalsModule';
import { DEFAULT_ROUTE, RouteNames } from 'router/routes';
import { ProtectedRoute } from 'router/ProtectedRoute';
import { formValidationMessages } from 'utils/helpers/validations';
import { AvailableCurrencies } from 'utils/constants/currencies';
import { LANGUAGES } from 'utils/constants/constants';
import { Company } from 'services/api/domains/CompanyApi';
import { BlankModule, BlankModuleType } from 'modules/BlankModule/BlankModule';
import { inject, observer } from 'mobx-react';
import { IReactionDisposer, reaction } from 'mobx';
import { Stores, AuthStore, UserSettingsStore, DispatcherStore, FavoritesStore, AppStore } from 'stores';
import i18next from 'i18next';
import { DispatcherModel } from 'services/api/domains/UsersApi';
import { LOCAL_STORAGE_APP_AUTH_STATE } from 'stores/auth/AuthStore';
import { Footer } from 'components/base/layout/Footer/Footer';
import { Subject, takeUntil } from 'rxjs';
import { DispatcherBoardModule } from 'modules/DispatcherBoardModule/DispatcherBoardModule';

interface Props extends WithTranslation, RouteComponentProps {
    appStore?: AppStore;
    authStore?: AuthStore;
    userSettingsStore?: UserSettingsStore;
    dispatcherStore?: DispatcherStore;
    favoritesStore?: FavoritesStore;
    logic: Logic;
}

interface State {
    alarmsOpen: boolean;
    alarms: AlarmInDatabaseWithGPSInfo[];
    isLoggedIn?: boolean;
    company?: Company;
    user?: DispatcherModel;
}

@inject(
    Stores.AUTH_STORE,
    Stores.APP_STORE,
    Stores.USER_SETTINGS_STORE,
    Stores.DISPATCHER_STORE,
    Stores.FAVORITES_STORE
)
@observer
class App extends React.Component<Props, State> {
    private readonly version: string;
    private _mobxDisposeFunctions: IReactionDisposer[] = [];
    private _destroySiganl$ = new Subject<void>();

    constructor(props: Props) {
        super(props);
        this.state = {
            alarmsOpen: false,
            alarms: [],
            isLoggedIn: this.props.appStore?.isLoggedIn
        };
        this.version =
            this.props.logic.conf.env === 'DEV' ? this.props.logic.conf.env : process.env.REACT_APP_VERSION ?? '';
    }

    async componentDidMount() {
        await this._emailVerification();

        if (this.props.authStore?.isLoggedIn) {
            await this.props.logic.notification().connect(this._getAuthToken);
        }

        this.props.logic
            .alarms()
            .alarmsUpdates$.pipe(takeUntil(this._destroySiganl$))
            .subscribe(async () => {
                this.setState({
                    alarms: [
                        ...this.props.logic.alarms().geolocationAlarms(),
                        ...this.props.logic.alarms().transportAlarms(),
                        ...this.props.logic.alarms().companyMembershipAlarms()
                    ]
                });
            });

        this.props.logic.map().setLoading(true);

        this.props.logic
            .poi()
            .init()
            .then(() => {
                this.props.logic.map().setLoading(false);
            });

        this._mobxDisposeFunctions.push(
            reaction(
                () => this.props.appStore?.isLoggedIn,
                async isLoggedIn => {
                    if (isLoggedIn) {
                        await this.props.userSettingsStore?.fetchUserSettings();
                        await this.props.logic.schedulingRoutePlanner().fetchVehiclesAndProfiles();
                        this.props.favoritesStore?.fetchFavoritesWithAddress();
                    } else {
                        this.props.logic.company().clearCompany();
                        this.props.logic.schedulingRoutePlanner().reset();
                    }

                    this.setState(
                        {
                            isLoggedIn
                        },
                        () => {
                            this._setComapnyAndUser();
                        }
                    );
                }
            )
        );

        this._mobxDisposeFunctions.push(
            reaction(
                () => this.props.userSettingsStore?.currency,
                () => {
                    this.props.logic.poi().exchangeRate();
                }
            )
        );

        this._mobxDisposeFunctions.push(
            reaction(
                () => this.props.userSettingsStore!.lang,
                lang => {
                    i18next.changeLanguage(lang);
                    this.props.authStore?.setUserLanguage(lang);
                }
            )
        );

        this._mobxDisposeFunctions.push(
            reaction(
                () => this.props.authStore?.authState,
                authState => {
                    // reconnect notifications when token changed
                    if (authState?.token) {
                        this._reconnectNotifications();
                    }
                }
            )
        );

        if (this.state.isLoggedIn) {
            this.props.favoritesStore?.fetchFavoritesWithAddress(); // initial favorites load
        }

        this._setComapnyAndUser();

        this.props.logic
            .company()
            .companyUpdated$.pipe(takeUntil(this._destroySiganl$))
            .subscribe(() => {
                this._setComapnyAndUser();
            });

        this.props.logic.userEvents().pageReady();

        // ensures notification reconnect when user login/logout on another browser tab
        window.addEventListener('storage', event => {
            if (event.key === LOCAL_STORAGE_APP_AUTH_STATE && event.newValue) {
                this._reconnectNotifications();
            }
        });
    }

    componentDidUpdate(prevProps: Props) {
        const pathhWithSearch = this.props.location.pathname + this.props.location.search;
        const prevPathWithSearch = prevProps.location.pathname + prevProps.location.search;

        if (
            pathhWithSearch !== prevPathWithSearch &&
            ![RouteNames.SETTINGS, RouteNames.SCHEDULING_DISPATCHER_BOARD].includes(
                this.props.location.pathname as RouteNames
            )
        ) {
            this.props.logic.userEvents().pageReady();
        }
    }

    componentWillUnmount() {
        this._mobxDisposeFunctions.forEach(disposer => disposer());
        this._mobxDisposeFunctions = [];
        this.props.logic.poi().destroy();
        this.props.logic.notification().disconnect();
    }

    render() {
        const { logic } = this.props;
        const locale = getLocale(this.props.userSettingsStore!.lang);

        return (
            <ConfigProvider locale={locale} form={{ validateMessages: formValidationMessages() }}>
                <ModalsModule logic={logic} />

                <NavBar
                    language={this.props.userSettingsStore!.lang}
                    languages={LANGUAGES}
                    currency={this.props.userSettingsStore!.currency}
                    currencies={Object.values(AvailableCurrencies)}
                    newAlarms={this.state.alarms.filter(a => !a.acknowledged).length}
                    isLoggedIn={this.state.isLoggedIn}
                    companyName={this.state.company?.name}
                    userName={this.state.user?.name}
                    appVersion={this.version}
                    onLogoutClick={this._onLogout}
                    onAlarmClick={this._onAlarmsClick}
                    onLanguageChange={this._onLangChange}
                    onCurrencyChange={this.props.userSettingsStore!.setCurrency}
                />

                {this.state.isLoggedIn && this.props.authStore?.user?.roles.includes(Role.IA_R) && (
                    <AlarmsModule logic={logic} open={this.state.alarmsOpen} onClose={this._onAlarmsClose} />
                )}

                <Map isLoggedIn={this.state.isLoggedIn} logic={logic} />

                <PlannerModule
                    isLoggedIn={this.state.isLoggedIn}
                    logic={logic}
                    currency={this.props.userSettingsStore!.currency as AvailableCurrencies}
                />

                <Switch>
                    <Route exact path={RouteNames.SCHEDULING_PLANNER}>
                        {/* Rendering of this route is provided in PlannerModule. 
                        This route have to be there to ensure not to redirect to default route. */}
                    </Route>

                    <Route path={RouteNames.SCHEDULING_DISPATCHER_BOARD}>
                        <DispatcherBoardModule logic={logic} />
                    </Route>

                    <Route path={RouteNames.TRACKING}>
                        {this.state.isLoggedIn ? (
                            <TrackingModule logic={logic} />
                        ) : (
                            <BlankModule type={BlankModuleType.TRACKING} />
                        )}
                    </Route>

                    <ProtectedRoute path={RouteNames.SETTINGS} auth={this.props.authStore}>
                        <SettingsModule logic={logic} />
                    </ProtectedRoute>

                    <Redirect to={DEFAULT_ROUTE} />
                </Switch>

                <Footer />
            </ConfigProvider>
        );
    }

    private async _emailVerification() {
        const path = this.props.location.pathname;
        const confirmEmailPath = '/confirm/';

        if (path.startsWith(confirmEmailPath)) {
            const token = path.split(confirmEmailPath)[1];
            try {
                await this.props.authStore?.confirmEmail(token);
                Notification.success({
                    message: this.props.t('UserEmailVerification.success')
                });
            } catch (err) {
                console.error(err);
                Notification.error({ message: this.props.t('UserEmailVerification.error') });
            }
        }
    }
    private _onLangChange = async (lang: string) => {
        this.props.userSettingsStore?.setLang(lang);
        if (this.props.authStore?.isLoggedIn) {
            await this.props.dispatcherStore?.updateDispatcher({ language: lang });
        }
    };

    private _setComapnyAndUser = async () => {
        if (this.state.isLoggedIn) {
            const company = this.props.logic.company().getCompany();

            await this.props.dispatcherStore?.fetchDispatcher();
            const me = this.props.dispatcherStore?.dispatcher;

            this.setState(
                {
                    company: company,
                    user: me
                },
                async () => {
                    if (!company?.fleetSize) {
                        this.props.history.push({ search: '?aboutCompany=true' });
                    }
                }
            );
        }
    };

    private _onAlarmsClick = () => {
        if (this.state.alarmsOpen) {
            this._onAlarmsClose();
        } else {
            this.setState(state => ({
                alarms: state.alarms.map(a => ({ ...a, acknowledged: true })),
                alarmsOpen: true
            }));
        }
    };

    private _onAlarmsClose = () => {
        this.setState({ alarmsOpen: false });
        this.props.logic.alarms().markAlarmsAsSeen(this.state.alarms.map(alarm => alarm.alarmId));
    };

    private _onLogout = async () => {
        try {
            this.props.history.push({ pathname: DEFAULT_ROUTE });
            await this.props.authStore?.logout();
        } catch (err) {
            console.error('Logout err: ', err);
            Notification.error({
                message: this.props.t('Notifications.logoutError')
            });
        }
    };

    private _reconnectNotifications = async () => {
        this.props.logic.notification().disconnect();
        if (this.props.authStore?.isLoggedIn) {
            await this.props.logic.notification().connect(this._getAuthToken);
        }
    };

    private _getAuthToken = async () => {
        await this.props.authStore?.updateToken();
        return this.props.authStore!.token;
    };
}

export default withRouter(withTranslation()(App));
