import { Button } from 'components/base/controls';
import { VehicleModelMap } from 'logic/map/logic/vehicles';
import { getVehiclePopupStatus } from 'logic/map/utils';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { sinceDate, formattedDuration, remainingTimeDurationFormat } from 'utils/helpers/formatters';

interface Props {
    vehicle: VehicleModelMap;
    onVehicleIconClick: () => void;
    onRouteOverviewClick: () => void;
    onShareEtaClick: () => void;
    onMouseLeave: () => void;
}

export default function VehiclePopup(props: Props) {
    const { t } = useTranslation();
    const { vehicle } = props;
    const sinceLastUpdate = sinceDate(vehicle.lastUpdate);
    const lastSeen = sinceLastUpdate ? formattedDuration(sinceLastUpdate) : undefined;
    const transportState = getVehiclePopupStatus(vehicle.trackingTransport.status);
    const eta = vehicle.eta || vehicle.navigationRoute.eta;
    const remainingTime = remainingTimeDurationFormat(eta);

    return (
        <div className="vehicle-popup" onMouseLeave={props.onMouseLeave}>
            <div className="vehicle-popup-content">
                <div className="vehicle-plate">{vehicle.registrationNumber}</div>
                <div className="driver-name">{vehicle.driverName}</div>
                {lastSeen && <div className="last-seen">{t('VehicleMarker.lastSeen', { lastSeen })}</div>}
                <div className="route-info">
                    {eta && (
                        <>
                            <div>
                                {remainingTime > 0 && <span>{remainingTimeDurationFormat(eta)}</span>}
                                {remainingTime > 0 && vehicle.remainingDistance && <span className="dot" />}
                                {vehicle.remainingDistance && (
                                    <span>{(vehicle.remainingDistance / 1000).toFixed(1)} km</span>
                                )}
                            </div>

                            <div>
                                <span>
                                    ETA <strong>{moment(eta).format('LT')}</strong>
                                    {moment(eta).isSame(moment(), 'day') ? '' : moment(eta).format(', DD.MM')}
                                </span>

                                <span className={`status-tag status-tag-${transportState}`}>{transportState}</span>
                            </div>
                        </>
                    )}
                </div>
                {vehicle.navigationRoute.eta && (
                    <Button
                        type="link"
                        onClick={e => {
                            e.stopPropagation();
                            props.onShareEtaClick();
                        }}
                    >
                        {t('common.shareEta')}
                    </Button>
                )}
                {vehicle.navigationRoute.polyline && (
                    <Button
                        type="link"
                        onClick={e => {
                            e.stopPropagation();
                            props.onRouteOverviewClick();
                        }}
                    >
                        {t('common.routeOverview')}
                    </Button>
                )}
            </div>
            <div className="vehicle-popup-tip" />
        </div>
    );
}
