import { createIcon } from '../utils';

export const SELECTED_POI_LAYER_SOURCE = 'map-selected-poi-source';
export const SELECTED_POI_LAYER_ID = 'map-selected-poi-layer';

export class SelectedPoiLayer {
    public static async init(map: mapboxgl.Map) {
        await createIcon('poiSelectedIcon', '/img/map-markers/rl-poi-selected.svg', map);

        map.addSource(SELECTED_POI_LAYER_SOURCE, {
            type: 'geojson',
            data: {
                type: 'FeatureCollection',
                features: []
            }
        });

        map.addLayer({
            id: SELECTED_POI_LAYER_ID,
            type: 'symbol',
            source: SELECTED_POI_LAYER_SOURCE,
            layout: {
                'icon-image': 'poiSelectedIcon',
                'icon-anchor': 'bottom'
            }
        });
    }
}
