import { Conf } from 'conf';
import { HttpClient } from '../common/HttpClient';
export interface AutocompleteGeocodeLocationRequest {
    locationid: string;
    requestid?: string;
    lang?: string;
}

export interface AutocompleteLocation {
    type?: string;
    title?: string;
    subtitle?: string;
    location?: {
        lat?: number;
        lon?: number;
    };
    iso?: string;
    address_components?: {
        admin_level_2?: string;
        admin_level_8?: string;
        postal_code?: string;
        street?: string;
        house_number?: string;
    };
    entry_location?: {
        lat?: number;
        lon?: number;
    };
}

export interface AutocompleteSuggestionsRequest {
    query: string;
    requestid?: string;
    location: { lng: number; lat: number };
    lang: string;
}

export interface AutocompleteSuggestion {
    location_id?: string;
    score?: number;
    distance?: number;
    type?: string;
    title?: string;
    subtitle?: string;
    title_highlights?: number[][];
    category_tags?: string[];
}

class SearchApi {
    private _abortController: AbortController;

    constructor(private _conf: Conf['api'], private _httpClient: HttpClient) {
        this._abortController = new AbortController();
    }

    cancelRequests = () => {
        this._abortController?.abort();
        this._abortController = new AbortController();
    };

    async getAutocompleteSuggestions(request: AutocompleteSuggestionsRequest): Promise<AutocompleteSuggestion[]> {
        const params = {
            maxresults: 10,
            query: request.query,
            lang: request.lang,
            location: `${request.location.lat},${request.location.lng}`,
            requestid: request.requestid,
            typefilter: 'admin_area,house_number,place,postal_code,street',
            countryfilter:
                'AL,AD,AT,BY,BE,BA,BG,HR,CY,CZ,DK,EE,FO,FI,FR,DE,GI,GR,HU,IS,IE,IM,IT,RS,LV,LI,LT,LU,MK,MT,MD,MC,ME,NL,NO,PL,PT,RO,RU,SM,SK,SI,ES,SE,CH,UA,GB,VA'
        };

        const { results } = await this._httpClient.get<{ results: any }>(`${this._conf.searchUrl}/autocomplete`, {
            params,
            headers: {
                'Content-Type': 'application/json'
            },
            timeout: 10000,
            signal: this._abortController.signal
        });

        return results;
    }

    async autocompleteGeocodeLocation(request: AutocompleteGeocodeLocationRequest): Promise<AutocompleteLocation> {
        const { result } = await this._httpClient.get<{ result: any }>(`${this._conf.searchUrl}/geocodelocation`, {
            params: { ...request },
            headers: {
                'Content-Type': 'application/json'
            },
            timeout: 10000,
            signal: this._abortController.signal
        });

        return result;
    }
}

export { SearchApi };
