import { useTranslation } from 'react-i18next';
import PreferencesForm, { PreferencesData } from './ui/PreferencesForm';
import { LANGUAGES } from 'utils/constants/constants';
import { Notification } from 'components/base/layout/Notification';
import { observer } from 'mobx-react';
import { useStore } from 'stores';
import { AvailableCurrencies } from 'utils/constants/currencies';
import { transaction } from 'mobx';
import { Logic } from 'logic/logic';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import { useHistory } from 'react-router';
import { RouteNames } from 'router/routes';
import { Button } from 'components/base/controls';

interface Props {
    logic: Logic;
}

const PreferencesModule = (props: Props) => {
    const { t } = useTranslation();
    const { isMobileWidth } = useWindowDimensions();
    const history = useHistory();
    const { userSettingsStore, dispatcherStore } = useStore();
    const { lang, currency, addressIdentification } = userSettingsStore;
    const { dispatcher } = dispatcherStore;

    const _savePreferences = async (data: PreferencesData) => {
        try {
            transaction(() => {
                userSettingsStore.setCurrency(data.currency);
                userSettingsStore.setAddressIdentification(data.addressIdentification);
                userSettingsStore.setLang(data.lang);
            });

            const newslettersAgreementChanged = data.newslettersAgreement !== dispatcher?.newslettersAgreement;

            await dispatcherStore.updateDispatcher({
                language: data.lang,
                newslettersAgreement: data.newslettersAgreement
            });

            if (newslettersAgreementChanged) {
                props.logic.userEvents().newsLetterConsentUpdate(data.newslettersAgreement);
            }

            Notification.success({ message: t('settings.preferences.updateSuccess') });
        } catch (err) {
            console.error(err);
            Notification.error({ message: err.message });
        }
    };

    return (
        <div className="rl-settings-module rl-preferences-module">
            <div className="rl-settings-module-header">
                {isMobileWidth && (
                    <Button
                        className="rl-settings-module-header-back"
                        type="action"
                        icon={<i className="rl-icon-chevron-left" />}
                        onClick={() => history.push(RouteNames.SETTINGS)}
                    />
                )}

                <h3 className="rl-settings-module-header-title">{t('common.preferences')}</h3>
            </div>

            <div className="rl-settings-module-content rl-preferences-module-content">
                <PreferencesForm
                    languages={LANGUAGES}
                    lang={lang}
                    currency={currency as AvailableCurrencies}
                    addressIdentification={addressIdentification}
                    savePreferences={_savePreferences}
                    newslettersAgreement={dispatcher?.newslettersAgreement}
                />
            </div>
        </div>
    );
};

export default observer(PreferencesModule);
