/* eslint-disable @typescript-eslint/no-unused-vars */
import { PoiModelMapFuelType, FuelTypes, PoiModelMap } from '../map/logic/fuelStations';
import { ReadOnlyCurrency } from '../../generated/new-main';

function getEURRate(value?: PoiModelMapFuelType['price'], currencies?: ReadOnlyCurrency[]): number {
    if (value === undefined) {
        return 0;
    } else if (value?.currency === 'EUR') {
        return value.discountedPrice ? Number(value.discountedPrice) : Number(value.price);
    } else {
        if (!currencies) {
            return value.discountedPrice ? Number(value.discountedPrice) : Number(value.price);
        } else {
            const exchangeRate = currencies.find(c => c.code === value.currency);
            const [coefficient] = exchangeRate?.latestExchangeRate ?? [];
            const price = value.discountedPrice ? Number(value.discountedPrice) : Number(value.price);
            return price / (coefficient?.rate || 1);
        }
    }
}

/**
 * Returns best price for allowed fuel types
 */
export function fsBestPriceInFuelTypes(
    fTypes: PoiModelMapFuelType[],
    currencies?: ReadOnlyCurrency[]
): PoiModelMapFuelType | undefined {
    const allowedFuelTypesCodes = Object.entries(FuelTypes)
        .filter(([_, value]) => filterFuelType(value))
        .map(e => e[0]);

    const [bestPrice] = fTypes
        .filter(p =>
            allowedFuelTypesCodes.some(code => code === p.code && p.price?.price && Number(p.price.price) >= 0.005)
        )
        .sort((a, b) => getEURRate(a.price, currencies) - getEURRate(b.price, currencies));
    return bestPrice;
}

export function fsBestPriceInPois(data: PoiModelMap[], currencies?: ReadOnlyCurrency[]): PoiModelMap[] | undefined {
    const allowedFuelTypesCodes = Object.entries(FuelTypes)
        .filter(([_, value]) => filterFuelType(value))
        .map(e => e[0]);

    const bestPrices = data
        .filter(p =>
            p.fuelTypes?.some(
                t => t.price?.price && Number(t.price.price) >= 0.005 && allowedFuelTypesCodes.includes(t.code)
            )
        )
        .sort(
            (a, b) =>
                Number(
                    a.fuelTypes ? getEURRate(fsBestPriceInFuelTypes(a.fuelTypes, currencies)?.price, currencies) : 0
                ) -
                Number(b.fuelTypes ? getEURRate(fsBestPriceInFuelTypes(b.fuelTypes, currencies)?.price, currencies) : 0)
        )
        .reduce((acc, curr, index, arr) => {
            const prevPoi = acc[acc.length - 1];
            const lastPrice = prevPoi?.fuelTypes
                ? getEURRate(fsBestPriceInFuelTypes(prevPoi.fuelTypes, currencies)?.price, currencies)
                : 0;
            const currentPrice = curr.fuelTypes
                ? getEURRate(fsBestPriceInFuelTypes(curr.fuelTypes, currencies)?.price, currencies)
                : 0;

            if ((lastPrice === currentPrice && currentPrice !== 0) || (currentPrice !== 0 && !prevPoi)) {
                return [...acc, curr];
            }
            arr.splice(acc.length - 1);
            return acc;
        }, [] as PoiModelMap[]);

    return bestPrices;
}

function filterFuelType(type: string) {
    return type === FuelTypes['0201'];
}
