import { createIcon } from '../utils';
import mapboxgl from 'mapbox-gl';

export enum WaypointMarkerIcon {
    Start = '/img/map-markers/rl-waypoint-start.svg',
    Middle = '/img/map-markers/rl-waypoint.svg',
    Via = '/img/map-markers/rl-viapoint.svg',
    End = '/img/map-markers/rl-waypoint-end.svg',
    Finish = '/img/map-markers/rl-finish.svg'
}

export class RoutingLayer {
    public static async init(map: mapboxgl.Map) {
        await Promise.all([
            createIcon('waypoint-icon', WaypointMarkerIcon.Middle, map),
            createIcon('viapoint-icon', WaypointMarkerIcon.Via, map),
            createIcon('waypoint-icon-start', WaypointMarkerIcon.Start, map),
            createIcon('waypoint-icon-end', WaypointMarkerIcon.End, map),
            createIcon('finish-icon', WaypointMarkerIcon.Finish, map)
        ]);

        map.addSource('route-source', {
            type: 'geojson',
            data: {
                type: 'FeatureCollection',
                features: []
            }
        });

        map.addSource('route-real-source', {
            type: 'geojson',
            data: {
                type: 'FeatureCollection',
                features: []
            }
        });

        map.addSource('route-marker-source', {
            type: 'geojson',
            data: {
                type: 'FeatureCollection',
                features: []
            }
        });

        map.addLayer(
            {
                id: 'route-layer',
                type: 'line',
                source: 'route-source',
                layout: {
                    'line-join': 'round',
                    'line-cap': 'round',
                    visibility: 'visible',
                    'line-sort-key': ['case', ['==', ['get', 'isAlternative'], true], 0, 1]
                },
                paint: {
                    'line-color': ['case', ['==', ['get', 'isAlternative'], true], '#bcbec0', '#07ADFA'],
                    'line-width': ['case', ['boolean', ['feature-state', 'hover'], false], 8, 6]
                }
            },
            'rail_tram_bridge_hatch'
        );

        map.addLayer(
            {
                id: 'route-real-layer',
                type: 'line',
                source: 'route-real-source',
                layout: {
                    'line-join': 'round',
                    'line-cap': 'round',
                    visibility: 'visible',
                    'line-sort-key': 1
                },
                paint: {
                    'line-color': '#07ADFA',
                    'line-width': ['case', ['boolean', ['feature-state', 'hover'], false], 8, 6]
                }
            },
            'rail_tram_bridge_hatch'
        );

        map.addLayer({
            id: 'route-marker-layer',
            type: 'symbol',
            source: 'route-marker-source',
            layout: {
                visibility: 'visible',
                'icon-image': [
                    'case',
                    ['==', ['get', 'position'], 'start'],
                    'waypoint-icon-start',
                    ['==', ['get', 'position'], 'middle'],
                    'waypoint-icon',
                    ['==', ['get', 'position'], 'viapoint'],
                    'viapoint-icon',
                    ['==', ['get', 'position'], 'finish'],
                    'finish-icon',
                    'waypoint-icon-end'
                ],
                'icon-size': 1,
                'icon-offset': [0, 8],
                'icon-anchor': 'bottom',
                'icon-allow-overlap': true,

                'text-field': '{positionIndex}',
                'text-font': ['Open Sans Semibold'],
                'text-size': 14,
                'text-offset': [-0.04, -1.4],
                'text-allow-overlap': true,
                'text-anchor': 'center'
            },
            paint: {
                'text-color': 'white'
            }
        });
    }
}
