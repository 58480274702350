import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom';
import { DEFAULT_ROUTE } from './routes';
import { AuthStore } from 'stores/auth/AuthStore';

interface Props extends RouteProps {
    auth?: AuthStore;
}

export function ProtectedRoute({ auth, children, ...rest }: Props) {
    const location = useLocation();

    return (
        <Route {...rest}>
            {auth?.isLoggedIn ? (
                children
            ) : (
                <Redirect
                    to={{
                        pathname: DEFAULT_ROUTE,
                        search: '?login=true',
                        state: {
                            redirectFrom: location.pathname
                        }
                    }}
                />
            )}
        </Route>
    );
}
