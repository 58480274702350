import { Button } from 'components/base/controls';
import { WhatsappSendButton } from 'components/WhatsappSendButton';
import { InitiatedFrom } from 'logic/userEventsProviders/GoogleTagManager';
import { useTranslation } from 'react-i18next';

interface Props {
    inviteLink: string;
    mailMessage: string;
    whatsappMessage: string;
    onBtnClick?: (InitiatedFrom: InitiatedFrom) => void;
}

export function ShareButtons(props: Props) {
    const { t } = useTranslation();

    return (
        <div className="rl-share-btns">
            <a
                href={`mailto:?body=${props.mailMessage}`}
                onClick={() => props.onBtnClick?.(InitiatedFrom.DRIVER_INVITATION_SEND_MAIL_BUTTON)}
            >
                <Button type="dark" disabled={!props.inviteLink}>
                    {t('common.shareToMail')}
                </Button>
            </a>

            <WhatsappSendButton
                text={props.whatsappMessage}
                size={'middle'}
                disabled={!props.inviteLink}
                onClick={() => props.onBtnClick?.(InitiatedFrom.DRIVER_INVITATION_WHATSAPP_BUTTON)}
            />
        </div>
    );
}
