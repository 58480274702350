/* tslint:disable */
/* eslint-disable */
/**
 * Telematics API
 * Telematics services API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    NotificationMessage,
    NotificationMessageFromJSON,
    NotificationMessageFromJSONTyped,
    NotificationMessageToJSON,
    PublishToManyTarget,
    PublishToManyTargetFromJSON,
    PublishToManyTargetFromJSONTyped,
    PublishToManyTargetToJSON,
} from '.';

/**
 * 
 * @export
 * @interface PublishToMany
 */
export interface PublishToMany {
    /**
     * 
     * @type {NotificationMessage}
     * @memberof PublishToMany
     */
    message: NotificationMessage;
    /**
     * 
     * @type {Array<PublishToManyTarget>}
     * @memberof PublishToMany
     */
    target: Array<PublishToManyTarget>;
}

export function PublishToManyFromJSON(json: any): PublishToMany {
    return PublishToManyFromJSONTyped(json, false);
}

export function PublishToManyFromJSONTyped(json: any, ignoreDiscriminator: boolean): PublishToMany {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'message': NotificationMessageFromJSON(json['message']),
        'target': ((json['target'] as Array<any>).map(PublishToManyTargetFromJSON)),
    };
}

export function PublishToManyToJSON(value?: PublishToMany | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'message': NotificationMessageToJSON(value.message),
        'target': ((value.target as Array<any>).map(PublishToManyTargetToJSON)),
    };
}


