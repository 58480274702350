/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Client,
    ClientFromJSON,
    ClientFromJSONTyped,
    ClientToJSON,
} from '.';

/**
 * 
 * @export
 * @interface PriceList
 */
export interface PriceList {
    /**
     * 
     * @type {string}
     * @memberof PriceList
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof PriceList
     */
    contentType: string;
    /**
     * 
     * @type {number}
     * @memberof PriceList
     */
    clientId: number;
    /**
     * 
     * @type {Client}
     * @memberof PriceList
     */
    client?: Client;
    /**
     * 
     * @type {Date}
     * @memberof PriceList
     */
    readonly deleted?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PriceList
     */
    activeFrom: Date;
    /**
     * 
     * @type {Date}
     * @memberof PriceList
     */
    activeTill?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof PriceList
     */
    trial?: number;
    /**
     * 
     * @type {string}
     * @memberof PriceList
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof PriceList
     */
    oneTimeFee?: string;
    /**
     * 
     * @type {string}
     * @memberof PriceList
     */
    monthlyFee?: string;
    /**
     * 
     * @type {Date}
     * @memberof PriceList
     */
    readonly createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PriceList
     */
    readonly updatedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof PriceList
     */
    objectId: string;
    /**
     * 
     * @type {number}
     * @memberof PriceList
     */
    currency: number;
}

export function PriceListFromJSON(json: any): PriceList {
    return PriceListFromJSONTyped(json, false);
}

export function PriceListFromJSONTyped(json: any, ignoreDiscriminator: boolean): PriceList {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'contentType': json['content_type'],
        'clientId': json['client_id'],
        'client': !exists(json, 'client') ? undefined : ClientFromJSON(json['client']),
        'deleted': !exists(json, 'deleted') ? undefined : (new Date(json['deleted'])),
        'activeFrom': (new Date(json['active_from'])),
        'activeTill': !exists(json, 'active_till') ? undefined : (json['active_till'] === null ? null : new Date(json['active_till'])),
        'trial': !exists(json, 'trial') ? undefined : json['trial'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'oneTimeFee': !exists(json, 'one_time_fee') ? undefined : json['one_time_fee'],
        'monthlyFee': !exists(json, 'monthly_fee') ? undefined : json['monthly_fee'],
        'createdAt': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
        'updatedAt': !exists(json, 'updated_at') ? undefined : (new Date(json['updated_at'])),
        'objectId': json['object_id'],
        'currency': json['currency'],
    };
}

export function PriceListToJSON(value?: PriceList | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'content_type': value.contentType,
        'client_id': value.clientId,
        'client': ClientToJSON(value.client),
        'active_from': (value.activeFrom.toISOString()),
        'active_till': value.activeTill === undefined ? undefined : (value.activeTill === null ? null : value.activeTill.toISOString()),
        'trial': value.trial,
        'name': value.name,
        'one_time_fee': value.oneTimeFee,
        'monthly_fee': value.monthlyFee,
        'object_id': value.objectId,
        'currency': value.currency,
    };
}


