import { useTranslation } from 'react-i18next';
import { Button } from 'components/base/controls/Button';

interface Props {
    onShowCreateVehicleModal: () => void;
}

const DispatcherBoardNoVehicles = (props: Props) => {
    const { t } = useTranslation();

    return (
        <div className="rl-no-vehicles-info">
            <h3>{t('DispatcherBoardVehicles.title')}</h3>
            <Button type="primary" size="large" onClick={() => props.onShowCreateVehicleModal()}>
                <i className="rl-icon-plus" /> {t('DispatcherBoardVehicles.addVehicle')}
            </Button>
        </div>
    );
};

export default DispatcherBoardNoVehicles;
