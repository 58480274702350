import { Country } from 'services/api/domains/CountryApi';
import { Logic } from './logic';

export class Enums {
    private _countryList?: Country[];

    constructor(private _logic: Logic) {}

    async countryList() {
        if (!this._countryList) {
            this._countryList = await this._logic.apiService().country().countryList();
        }

        return this._countryList;
    }
}
