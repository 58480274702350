import MaintenancePage from 'components/MaintenancePage/MaintenancePage';
import { Component, ErrorInfo, ReactNode } from 'react';

interface Props {
    children?: ReactNode;
}

interface State {
    hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { hasError: false };
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public static getDerivedStateFromError(error: Error): State {
        if ((error as any).response?.status === 503) {
            return { hasError: true };
        }

        // Update state so the next render will show the fallback UI.
        return { hasError: false };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        console.error('Uncaught error:', error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            return <MaintenancePage />;
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
