import { makeAutoObservable, reaction } from 'mobx';
import { RootStore } from './RootStore';

export class AppStore {
    isLoggedIn: boolean = false;

    constructor(private _rootStore: RootStore) {
        makeAutoObservable<AppStore, '_rootStore'>(this, {
            _rootStore: false
        });

        reaction(
            () => this._rootStore.auth.isLoggedIn,
            isLoggedIn => {
                if (!isLoggedIn) {
                    this.setIsLoggedIn(isLoggedIn);
                }
            }
        );
    }

    setIsLoggedIn(value: boolean) {
        this.isLoggedIn = value;
    }
}
