import { useTranslation } from 'react-i18next';
import { Col, Form, Row } from 'antd';
import { Button, Input } from 'components/base/controls';

interface Props {
    onSubmitCreateNewPassword: (password: string) => void;
}

export interface CreateNewPassword {
    password: string;
}

export function CreateNewPasswordForm(props: Props) {
    const { t } = useTranslation();
    const [form] = Form.useForm<CreateNewPassword>();

    function _onFinish(formValues: CreateNewPassword) {
        props.onSubmitCreateNewPassword(formValues.password);
    }

    return (
        <div className="rl-create-new-password-form">
            <Form<CreateNewPassword>
                form={form}
                id="createNewPasswordForm"
                layout="vertical"
                name="createNewPasswordForm"
                requiredMark={false}
                onFinish={_onFinish}
            >
                <div className="rl-create-new-password-form-title">
                    <h3>{t('CreateNewPasswordForm.title')}</h3>
                    <p>{t('CreateNewPasswordForm.infoText')}</p>
                </div>

                <Row>
                    <Col span="24">
                        <Form.Item label={t('common.password')} name="password" rules={[{ required: true, min: 8 }]}>
                            <Input type="password" placeholder={t('common.password')} />
                        </Form.Item>
                    </Col>
                </Row>

                <Row>
                    <Col span="24">
                        <Form.Item
                            name="confirmPassword"
                            label={t('common.repeatPassword')}
                            dependencies={['password']}
                            hasFeedback
                            rules={[
                                {
                                    required: true
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error(t('RegisterFormModal.passwordMatchError')));
                                    }
                                })
                            ]}
                        >
                            <Input type="password" placeholder={t('common.password')} />
                        </Form.Item>
                    </Col>
                </Row>

                <Row>
                    <Col span="24">
                        <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
                            {t('CreateNewPasswordForm.create')}
                        </Button>
                    </Col>
                </Row>
            </Form>
        </div>
    );
}
