/* tslint:disable */
/* eslint-disable */
/**
 * Telematics API
 * Telematics services API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
    NotificationChannelsRes,
    NotificationChannelsResFromJSON,
    NotificationChannelsResToJSON,
    NotificationManyRes,
    NotificationManyResFromJSON,
    NotificationManyResToJSON,
    NotificationMessage,
    NotificationMessageFromJSON,
    NotificationMessageToJSON,
    NotificationNumSub,
    NotificationNumSubFromJSON,
    NotificationNumSubToJSON,
    NotificationRes,
    NotificationResFromJSON,
    NotificationResToJSON,
    Publish,
    PublishFromJSON,
    PublishToJSON,
    PublishToMany,
    PublishToManyFromJSON,
    PublishToManyToJSON,
    PushyMeRegDevicesRes,
    PushyMeRegDevicesResFromJSON,
    PushyMeRegDevicesResToJSON,
    PushyMeRegistrationsRes,
    PushyMeRegistrationsResFromJSON,
    PushyMeRegistrationsResToJSON,
    PushymeRes,
    PushymeResFromJSON,
    PushymeResToJSON,
    PushymeSend,
    PushymeSendFromJSON,
    PushymeSendToJSON
} from '../models';

export interface NumsubUserRequest {
    user: string;
}

export interface NumsubUserDeviceRequest {
    user: string;
    device: string;
}

export interface PushManyRequest {
    publish?: Array<Publish>;
}

export interface PushOneToManyRequest {
    publishToMany?: PublishToMany;
}

export interface PushOneUserRequest {
    user: string;
    notificationMessage?: NotificationMessage;
}

export interface PushOneUserDeviceRequest {
    user: string;
    device: string;
    notificationMessage?: NotificationMessage;
}

export interface PushymeDeviceRegisterRequest {
    appId: string;
    device: string;
}

export interface PushymeDeviceUnregisterRequest {
    appId: string;
    device: string;
}

export interface PushymePushUserRequest {
    user: string;
    pushymeSend?: PushymeSend;
}

export interface PushymePushUserDeviceRequest {
    user: string;
    device: string;
    pushymeSend?: PushymeSend;
}

/**
 * no description
 */
export class NotificationsApi extends runtime.BaseAPI {
    /**
     */
    async channelsRaw(): Promise<runtime.ApiResponse<NotificationChannelsRes>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (
            this.configuration &&
            (this.configuration.username !== undefined || this.configuration.password !== undefined)
        ) {
            headerParameters['Authorization'] =
                'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token('jwt', []) : token;

            if (tokenString) {
                headerParameters['Authorization'] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/notification/push/channels`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters
        });

        return new runtime.JSONApiResponse(response, jsonValue => NotificationChannelsResFromJSON(jsonValue));
    }

    /**
     */
    async channels(): Promise<NotificationChannelsRes> {
        const response = await this.channelsRaw();
        return await response.value();
    }

    /**
     */
    async numsubUserRaw(requestParameters: NumsubUserRequest): Promise<runtime.ApiResponse<NotificationNumSub>> {
        if (requestParameters.user === null || requestParameters.user === undefined) {
            throw new runtime.RequiredError(
                'user',
                'Required parameter requestParameters.user was null or undefined when calling numsubUser.'
            );
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (
            this.configuration &&
            (this.configuration.username !== undefined || this.configuration.password !== undefined)
        ) {
            headerParameters['Authorization'] =
                'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token('jwt', []) : token;

            if (tokenString) {
                headerParameters['Authorization'] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/notification/push/{user}`.replace(
                `{${'user'}}`,
                encodeURIComponent(String(requestParameters.user))
            ),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters
        });

        return new runtime.JSONApiResponse(response, jsonValue => NotificationNumSubFromJSON(jsonValue));
    }

    /**
     */
    async numsubUser(requestParameters: NumsubUserRequest): Promise<NotificationNumSub> {
        const response = await this.numsubUserRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async numsubUserDeviceRaw(
        requestParameters: NumsubUserDeviceRequest
    ): Promise<runtime.ApiResponse<NotificationNumSub>> {
        if (requestParameters.user === null || requestParameters.user === undefined) {
            throw new runtime.RequiredError(
                'user',
                'Required parameter requestParameters.user was null or undefined when calling numsubUserDevice.'
            );
        }

        if (requestParameters.device === null || requestParameters.device === undefined) {
            throw new runtime.RequiredError(
                'device',
                'Required parameter requestParameters.device was null or undefined when calling numsubUserDevice.'
            );
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (
            this.configuration &&
            (this.configuration.username !== undefined || this.configuration.password !== undefined)
        ) {
            headerParameters['Authorization'] =
                'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token('jwt', []) : token;

            if (tokenString) {
                headerParameters['Authorization'] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/notification/push/{user}/{device}`
                .replace(`{${'user'}}`, encodeURIComponent(String(requestParameters.user)))
                .replace(`{${'device'}}`, encodeURIComponent(String(requestParameters.device))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters
        });

        return new runtime.JSONApiResponse(response, jsonValue => NotificationNumSubFromJSON(jsonValue));
    }

    /**
     */
    async numsubUserDevice(requestParameters: NumsubUserDeviceRequest): Promise<NotificationNumSub> {
        const response = await this.numsubUserDeviceRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async pushManyRaw(requestParameters: PushManyRequest): Promise<runtime.ApiResponse<NotificationManyRes>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (
            this.configuration &&
            (this.configuration.username !== undefined || this.configuration.password !== undefined)
        ) {
            headerParameters['Authorization'] =
                'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token('jwt', []) : token;

            if (tokenString) {
                headerParameters['Authorization'] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/notification/push/many`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.publish!.map(PublishToJSON)
        });

        return new runtime.JSONApiResponse(response, jsonValue => NotificationManyResFromJSON(jsonValue));
    }

    /**
     */
    async pushMany(requestParameters: PushManyRequest): Promise<NotificationManyRes> {
        const response = await this.pushManyRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async pushOneToManyRaw(requestParameters: PushOneToManyRequest): Promise<runtime.ApiResponse<NotificationManyRes>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (
            this.configuration &&
            (this.configuration.username !== undefined || this.configuration.password !== undefined)
        ) {
            headerParameters['Authorization'] =
                'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token('jwt', []) : token;

            if (tokenString) {
                headerParameters['Authorization'] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/notification/push/to-many`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PublishToManyToJSON(requestParameters.publishToMany)
        });

        return new runtime.JSONApiResponse(response, jsonValue => NotificationManyResFromJSON(jsonValue));
    }

    /**
     */
    async pushOneToMany(requestParameters: PushOneToManyRequest): Promise<NotificationManyRes> {
        const response = await this.pushOneToManyRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async pushOneUserRaw(requestParameters: PushOneUserRequest): Promise<runtime.ApiResponse<NotificationRes>> {
        if (requestParameters.user === null || requestParameters.user === undefined) {
            throw new runtime.RequiredError(
                'user',
                'Required parameter requestParameters.user was null or undefined when calling pushOneUser.'
            );
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (
            this.configuration &&
            (this.configuration.username !== undefined || this.configuration.password !== undefined)
        ) {
            headerParameters['Authorization'] =
                'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token('jwt', []) : token;

            if (tokenString) {
                headerParameters['Authorization'] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/notification/push/{user}`.replace(
                `{${'user'}}`,
                encodeURIComponent(String(requestParameters.user))
            ),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NotificationMessageToJSON(requestParameters.notificationMessage)
        });

        return new runtime.JSONApiResponse(response, jsonValue => NotificationResFromJSON(jsonValue));
    }

    /**
     */
    async pushOneUser(requestParameters: PushOneUserRequest): Promise<NotificationRes> {
        const response = await this.pushOneUserRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async pushOneUserDeviceRaw(
        requestParameters: PushOneUserDeviceRequest
    ): Promise<runtime.ApiResponse<NotificationRes>> {
        if (requestParameters.user === null || requestParameters.user === undefined) {
            throw new runtime.RequiredError(
                'user',
                'Required parameter requestParameters.user was null or undefined when calling pushOneUserDevice.'
            );
        }

        if (requestParameters.device === null || requestParameters.device === undefined) {
            throw new runtime.RequiredError(
                'device',
                'Required parameter requestParameters.device was null or undefined when calling pushOneUserDevice.'
            );
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (
            this.configuration &&
            (this.configuration.username !== undefined || this.configuration.password !== undefined)
        ) {
            headerParameters['Authorization'] =
                'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token('jwt', []) : token;

            if (tokenString) {
                headerParameters['Authorization'] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/notification/push/{user}/{device}`
                .replace(`{${'user'}}`, encodeURIComponent(String(requestParameters.user)))
                .replace(`{${'device'}}`, encodeURIComponent(String(requestParameters.device))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NotificationMessageToJSON(requestParameters.notificationMessage)
        });

        return new runtime.JSONApiResponse(response, jsonValue => NotificationResFromJSON(jsonValue));
    }

    /**
     */
    async pushOneUserDevice(requestParameters: PushOneUserDeviceRequest): Promise<NotificationRes> {
        const response = await this.pushOneUserDeviceRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async pushymeDeviceRegisterRaw(
        requestParameters: PushymeDeviceRegisterRequest
    ): Promise<runtime.ApiResponse<PushyMeRegDevicesRes>> {
        if (requestParameters.appId === null || requestParameters.appId === undefined) {
            throw new runtime.RequiredError(
                'appId',
                'Required parameter requestParameters.appId was null or undefined when calling pushymeDeviceRegister.'
            );
        }

        if (requestParameters.device === null || requestParameters.device === undefined) {
            throw new runtime.RequiredError(
                'device',
                'Required parameter requestParameters.device was null or undefined when calling pushymeDeviceRegister.'
            );
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (
            this.configuration &&
            (this.configuration.username !== undefined || this.configuration.password !== undefined)
        ) {
            headerParameters['Authorization'] =
                'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token('jwt', []) : token;

            if (tokenString) {
                headerParameters['Authorization'] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/notification/pushyme/register/{appId}/{device}`
                .replace(`{${'appId'}}`, encodeURIComponent(String(requestParameters.appId)))
                .replace(`{${'device'}}`, encodeURIComponent(String(requestParameters.device))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters
        });

        return new runtime.JSONApiResponse(response, jsonValue => PushyMeRegDevicesResFromJSON(jsonValue));
    }

    /**
     */
    async pushymeDeviceRegister(requestParameters: PushymeDeviceRegisterRequest): Promise<PushyMeRegDevicesRes> {
        const response = await this.pushymeDeviceRegisterRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async pushymeDeviceUnregisterRaw(
        requestParameters: PushymeDeviceUnregisterRequest
    ): Promise<runtime.ApiResponse<PushyMeRegDevicesRes>> {
        if (requestParameters.appId === null || requestParameters.appId === undefined) {
            throw new runtime.RequiredError(
                'appId',
                'Required parameter requestParameters.appId was null or undefined when calling pushymeDeviceUnregister.'
            );
        }

        if (requestParameters.device === null || requestParameters.device === undefined) {
            throw new runtime.RequiredError(
                'device',
                'Required parameter requestParameters.device was null or undefined when calling pushymeDeviceUnregister.'
            );
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (
            this.configuration &&
            (this.configuration.username !== undefined || this.configuration.password !== undefined)
        ) {
            headerParameters['Authorization'] =
                'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token('jwt', []) : token;

            if (tokenString) {
                headerParameters['Authorization'] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/notification/pushyme/unregister/{appId}/{device}`
                .replace(`{${'appId'}}`, encodeURIComponent(String(requestParameters.appId)))
                .replace(`{${'device'}}`, encodeURIComponent(String(requestParameters.device))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters
        });

        return new runtime.JSONApiResponse(response, jsonValue => PushyMeRegDevicesResFromJSON(jsonValue));
    }

    /**
     */
    async pushymeDeviceUnregister(requestParameters: PushymeDeviceUnregisterRequest): Promise<PushyMeRegDevicesRes> {
        const response = await this.pushymeDeviceUnregisterRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async pushymePushUserRaw(
        requestParameters: PushymePushUserRequest
    ): Promise<runtime.ApiResponse<Array<PushymeRes>>> {
        if (requestParameters.user === null || requestParameters.user === undefined) {
            throw new runtime.RequiredError(
                'user',
                'Required parameter requestParameters.user was null or undefined when calling pushymePushUser.'
            );
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (
            this.configuration &&
            (this.configuration.username !== undefined || this.configuration.password !== undefined)
        ) {
            headerParameters['Authorization'] =
                'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token('jwt', []) : token;

            if (tokenString) {
                headerParameters['Authorization'] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/notification/pushyme/{user}`.replace(
                `{${'user'}}`,
                encodeURIComponent(String(requestParameters.user))
            ),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PushymeSendToJSON(requestParameters.pushymeSend)
        });

        return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(PushymeResFromJSON));
    }

    /**
     */
    async pushymePushUser(requestParameters: PushymePushUserRequest): Promise<Array<PushymeRes>> {
        const response = await this.pushymePushUserRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async pushymePushUserDeviceRaw(
        requestParameters: PushymePushUserDeviceRequest
    ): Promise<runtime.ApiResponse<Array<PushymeRes>>> {
        if (requestParameters.user === null || requestParameters.user === undefined) {
            throw new runtime.RequiredError(
                'user',
                'Required parameter requestParameters.user was null or undefined when calling pushymePushUserDevice.'
            );
        }

        if (requestParameters.device === null || requestParameters.device === undefined) {
            throw new runtime.RequiredError(
                'device',
                'Required parameter requestParameters.device was null or undefined when calling pushymePushUserDevice.'
            );
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (
            this.configuration &&
            (this.configuration.username !== undefined || this.configuration.password !== undefined)
        ) {
            headerParameters['Authorization'] =
                'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token('jwt', []) : token;

            if (tokenString) {
                headerParameters['Authorization'] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/notification/pushyme/{user}/{device}`
                .replace(`{${'user'}}`, encodeURIComponent(String(requestParameters.user)))
                .replace(`{${'device'}}`, encodeURIComponent(String(requestParameters.device))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PushymeSendToJSON(requestParameters.pushymeSend)
        });

        return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(PushymeResFromJSON));
    }

    /**
     */
    async pushymePushUserDevice(requestParameters: PushymePushUserDeviceRequest): Promise<Array<PushymeRes>> {
        const response = await this.pushymePushUserDeviceRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async pushymeRegistrationsRaw(): Promise<runtime.ApiResponse<PushyMeRegistrationsRes>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (
            this.configuration &&
            (this.configuration.username !== undefined || this.configuration.password !== undefined)
        ) {
            headerParameters['Authorization'] =
                'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token('jwt', []) : token;

            if (tokenString) {
                headerParameters['Authorization'] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/notification/pushyme/registrations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters
        });

        return new runtime.JSONApiResponse(response, jsonValue => PushyMeRegistrationsResFromJSON(jsonValue));
    }

    /**
     */
    async pushymeRegistrations(): Promise<PushyMeRegistrationsRes> {
        const response = await this.pushymeRegistrationsRaw();
        return await response.value();
    }
}
