import bg from 'antd/es/locale/bg_BG';
import cs from 'antd/es/locale/cs_CZ';
import da from 'antd/es/locale/da_DK';
import de from 'antd/es/locale/de_DE';
import el from 'antd/es/locale/el_GR';
import en from 'antd/es/locale/en_GB';
import es from 'antd/es/locale/es_ES';
import et from 'antd/es/locale/et_EE';
import fi from 'antd/es/locale/fi_FI';
import fr from 'antd/es/locale/fr_FR';
import ga from 'antd/es/locale/ga_IE';
import hr from 'antd/es/locale/hr_HR';
import hu from 'antd/es/locale/hu_HU';
import it from 'antd/es/locale/it_IT';
import lt from 'antd/es/locale/lv_LV';
import lv from 'antd/es/locale/lv_LV';
import mt from 'antd/es/locale/en_GB';
import nl from 'antd/es/locale/nl_NL';
import pl from 'antd/es/locale/pl_PL';
import pt from 'antd/es/locale/pt_PT';
import ro from 'antd/es/locale/ro_RO';
import ru from 'antd/es/locale/ru_RU';
import sk from 'antd/es/locale/sk_SK';
import sl from 'antd/es/locale/sl_SI';
import sv from 'antd/es/locale/sv_SE';
import tr from 'antd/es/locale/tr_TR';
import uk from 'antd/es/locale/uk_UA';

export function getLocale(lang: string) {
    switch (lang) {
        case 'bg':
            return bg;
        case 'cs':
            return cs;
        case 'da':
            return da;
        case 'de':
            return de;
        case 'el':
            return el;
        case 'en':
            return en;
        case 'es':
            return es;
        case 'et':
            return et;
        case 'fi':
            return fi;
        case 'fr':
            return fr;
        case 'ga':
            return ga;
        case 'hr':
            return hr;
        case 'hu':
            return hu;
        case 'it':
            return it;
        case 'lt':
            return lt;
        case 'lv':
            return lv;
        case 'mt':
            return mt;
        case 'nl':
            return nl;
        case 'pl':
            return pl;
        case 'pt':
            return pt;
        case 'ro':
            return ro;
        case 'ru':
            return ru;
        case 'sk':
            return sk;
        case 'sl':
            return sl;
        case 'sv':
            return sv;
        case 'tr':
            return tr;
        case 'uk':
            return uk;
        default:
            return en;
    }
}
