/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ClientCreatePriceLists
 */
export interface ClientCreatePriceLists {
    /**
     * 
     * @type {number}
     * @memberof ClientCreatePriceLists
     */
    clientId: number;
    /**
     * 
     * @type {string}
     * @memberof ClientCreatePriceLists
     */
    contentType: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ClientCreatePriceLists
     */
    objects: Array<string>;
}

export function ClientCreatePriceListsFromJSON(json: any): ClientCreatePriceLists {
    return ClientCreatePriceListsFromJSONTyped(json, false);
}

export function ClientCreatePriceListsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClientCreatePriceLists {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'clientId': json['client_id'],
        'contentType': json['content_type'],
        'objects': json['objects'],
    };
}

export function ClientCreatePriceListsToJSON(value?: ClientCreatePriceLists | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'client_id': value.clientId,
        'content_type': value.contentType,
        'objects': value.objects,
    };
}


