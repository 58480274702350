import { Moment } from 'moment';
import { DropResult } from '@hello-pangea/dnd';
import { NotOptimizedWarning, Spin } from 'components/base/controls';
import { InitiatedFrom } from 'logic/userEventsProviders/GoogleTagManager';
import { useTranslation } from 'react-i18next';
import CalendarViewGrid from '../CalendarViewGrid/CalendarViewGrid';
import CalendarViewNavbar from '../CalendarViewNavbar/CalendarViewNavbar';
import { CalendarViewBar } from '../../CalendarViewModule';
import { Logic } from 'logic/logic';
import { CompanyVehicle, VehicleStateObject } from 'services/api/domains/VehiclesApi';
import { TransportModel } from 'logic/dispatcher-board';
import { DispatcherBoardViewBar } from 'modules/DispatcherBoardModule/common/DispatcherBoardViewBar';
import { Country } from 'services/api/domains/CountryApi';

interface Props {
    logic: Logic;
    bar: CalendarViewBar;
    container: {
        data: {
            newTransports: TransportModel[];
            otherTransports: TransportModel[];
            rows: CompanyVehicle[];
            vehicles: VehicleStateObject[];
        };
        daysAfter: number;
        daysBefore: number;
        loading: boolean;
        processing: boolean;
        startDate: string;
    };
    transportDetailId?: string;
    firstTransportPopoverVisible: boolean;
    countryList: Country[];
    onBarEtaClick: () => void;
    onBarNotesClick: () => void;
    onBarExpandClick: () => void;
    onDragTransportEnd: (result: DropResult) => void;
    onStartDateChange: (startDate: Moment) => void;
    onTransportEditClick: (transportId: string, vehicleId?: string) => void;
    onTransportDetailClick: (transport: TransportModel) => void;
    onAssignDriverToVehicle: (vehicle: CompanyVehicle) => void;
    onShowCreateVehicleModal: (initiatedFrom: InitiatedFrom) => void;
    onOpenDeleteTransportModal: (transport: TransportModel) => void;
    onUnassignTransport: (transportId: string) => void;
    onOpenAssignVehicleModal: (transport: TransportModel) => void;
    onTransportNoteChange: (transportId: string, note: string, cb: VoidFunction) => void;
    onDaysSegmentClick: (segment: number) => void;
    onLocalizeVehicle?: (vehicleId?: string) => void;
    onFirstTransportPopoverClose?: () => void;
}

const CalendarView = (props: Props) => {
    const { t } = useTranslation();

    return (
        <>
            <NotOptimizedWarning />

            {/* bottom: 0 because footer is temporarily hide */}
            <div className="rl-calendar-view" style={{ bottom: 0 }}>
                <Spin
                    loading={props.container.loading || props.container.processing}
                    tip={t('common.loading')}
                    opacity={props.container.processing}
                >
                    <div className="rl-calendar-view-grid-wrapper">
                        <DispatcherBoardViewBar />
                        <CalendarViewNavbar
                            bar={props.bar}
                            loading={props.container.loading}
                            onNotesClick={props.onBarNotesClick}
                            onEtaClick={props.onBarEtaClick}
                            onExpandClick={props.onBarExpandClick}
                            onDaysSegmentClick={props.onDaysSegmentClick}
                        />
                        <CalendarViewGrid
                            logic={props.logic}
                            daysAfter={props.container.daysAfter}
                            daysBefore={props.container.daysBefore}
                            bar={props.bar}
                            loading={props.container.loading}
                            newTransports={props.container.data.newTransports}
                            otherTransports={props.container.data.otherTransports}
                            rows={props.container.data.rows}
                            startDate={props.container.startDate}
                            vehicles={props.container.data.vehicles}
                            transportDetailId={props.transportDetailId}
                            firstTransportPopoverVisible={props.firstTransportPopoverVisible}
                            countryList={props.countryList}
                            onDragTransportEnd={props.onDragTransportEnd}
                            onStartDateChange={props.onStartDateChange}
                            onTransportEditClick={props.onTransportEditClick}
                            onTransportDetailClick={props.onTransportDetailClick}
                            onAssignDriverToVehicle={props.onAssignDriverToVehicle}
                            onShowCreateVehicleModal={props.onShowCreateVehicleModal}
                            onOpenDeleteTransportModal={props.onOpenDeleteTransportModal}
                            onUnassignTransport={props.onUnassignTransport}
                            onOpenAssignVehicleModal={props.onOpenAssignVehicleModal}
                            onTransportNoteChange={props.onTransportNoteChange}
                            onLocalizeVehicle={props.onLocalizeVehicle}
                            onFirstTransportPopoverClose={props.onFirstTransportPopoverClose}
                        />
                    </div>
                </Spin>
            </div>
        </>
    );
};

export default CalendarView;
