/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WriteOnlyOBU
 */
export interface WriteOnlyOBU {
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyOBU
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof WriteOnlyOBU
     */
    clientId: number;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyOBU
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyOBU
     */
    identificationNumber: string;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyOBU
     */
    serialNumber: string;
    /**
     * 
     * @type {Date}
     * @memberof WriteOnlyOBU
     */
    activatedAt?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyOBU
     */
    vehicleBrand: string;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyOBU
     */
    imei: string;
    /**
     * 
     * @type {number}
     * @memberof WriteOnlyOBU
     */
    monitoredObject?: number | null;
}

export function WriteOnlyOBUFromJSON(json: any): WriteOnlyOBU {
    return WriteOnlyOBUFromJSONTyped(json, false);
}

export function WriteOnlyOBUFromJSONTyped(json: any, ignoreDiscriminator: boolean): WriteOnlyOBU {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'clientId': json['client_id'],
        'type': json['type'],
        'identificationNumber': json['identification_number'],
        'serialNumber': json['serial_number'],
        'activatedAt': !exists(json, 'activated_at') ? undefined : (json['activated_at'] === null ? null : new Date(json['activated_at'])),
        'vehicleBrand': json['vehicle_brand'],
        'imei': json['imei'],
        'monitoredObject': !exists(json, 'monitored_object') ? undefined : json['monitored_object'],
    };
}

export function WriteOnlyOBUToJSON(value?: WriteOnlyOBU | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'client_id': value.clientId,
        'type': value.type,
        'identification_number': value.identificationNumber,
        'serial_number': value.serialNumber,
        'activated_at': value.activatedAt === undefined ? undefined : (value.activatedAt === null ? null : value.activatedAt.toISOString()),
        'vehicle_brand': value.vehicleBrand,
        'imei': value.imei,
        'monitored_object': value.monitoredObject,
    };
}


