import { useTranslation } from 'react-i18next';
import Row from 'antd/lib/row';
import { Col } from 'antd';
import { PlaceType } from 'services/api/domains/PlacesApi';
import { Favorite } from 'services/api/domains/FavoritesApi';
import { Button, Dropdown, Input, Menu } from 'components/base/controls';
import { useState } from 'react';
import { POI_ICON } from 'utils/constants/constants';

interface Props {
    favorite: Favorite;
    category: PlaceType;
    onShowFavoriteDetail: (poiId: string) => void;
    onCloseFavoriteDetail: () => void;
    onDeleteFavorite: (favorite: Favorite) => void;
    onRenameFavorite: (favorite: Favorite) => void;
}

const FavoriteOverview = (props: Props) => {
    const { t } = useTranslation();

    const [renameFlag, setRenameFlag] = useState(false);
    const [name, setName] = useState(props.favorite.name);

    const handleKeyPress = (event: any) => {
        if (!!name && event.key === 'Enter') {
            onRenameFavorite();
        }
    };

    const onRenameFavorite = () => {
        if (props.favorite.name !== name) {
            const editedFavorite = {
                ...props.favorite,
                name
            };

            props.onRenameFavorite(editedFavorite);
        }

        setRenameFlag(false);
    };

    return (
        <>
            <div className="rl-favorite-overview-back">
                <Button type="icon" onClick={() => props.onCloseFavoriteDetail()}>
                    <i className="rl-icon-chevron-left" />
                    {t('FavoriteOverview.back')}
                </Button>
            </div>
            <div className="rl-favorite-overview">
                <div className="rl-favorite-overview-content">
                    <Row>
                        <Col span={4}>
                            <Row>
                                <Col>
                                    {props.favorite.brand?.logoUrl ? (
                                        <img src={props.favorite.brand.logoUrl} alt="Brand icon" width={40} />
                                    ) : (
                                        <img src={`/img/poi-detail/${POI_ICON[props.category]}`} alt="Poi" width={40} />
                                    )}
                                </Col>
                            </Row>

                            {(props.favorite.rating ?? 0) > 0 && (
                                <Row>
                                    <Col flex="20px">
                                        <img src="/img/settings/favorites/rating-star-icon.svg" alt="Rating" />
                                    </Col>
                                    <Col flex="auto">
                                        <span className="rl-favorite-overview-rating">{props.favorite.rating}</span>
                                    </Col>
                                </Row>
                            )}
                        </Col>

                        <Col span={18}>
                            <Row>
                                <Col span={24}>
                                    {renameFlag ? (
                                        <Input
                                            autoFocus={true}
                                            onBlur={onRenameFavorite}
                                            defaultValue={props.favorite.name}
                                            onKeyPress={handleKeyPress}
                                            onChange={e => {
                                                setName(e.target.value);
                                            }}
                                        />
                                    ) : (
                                        <div className="rl-favorite-overview-name">{name}</div>
                                    )}
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <div className="rl-favorite-overview-address">{props.favorite.address}</div>
                                </Col>
                            </Row>
                        </Col>

                        <Col span={2}>
                            <Dropdown
                                overlay={
                                    <Menu>
                                        <Menu.Item key="0" onClick={() => setRenameFlag(true)}>
                                            {t('FavoritesCard.rename')}
                                        </Menu.Item>
                                        <Menu.Item
                                            key="1"
                                            onClick={() => {
                                                props.onDeleteFavorite(props.favorite);
                                            }}
                                        >
                                            {t('FavoritesCard.remove')}
                                        </Menu.Item>
                                    </Menu>
                                }
                                trigger={['hover', 'click']}
                                arrow
                                placement="topRight"
                            >
                                <div className="rl-favorites-card-actions">
                                    <i className="fas fa-ellipsis-h" />
                                </div>
                            </Dropdown>
                        </Col>
                    </Row>
                    <Button
                        type="primary"
                        className="rl-favorite-overview-detail-button"
                        onClick={() => props.onShowFavoriteDetail?.(props.favorite.id)}
                    >
                        {t('FavoriteOverview.detail')}
                    </Button>
                </div>
            </div>
        </>
    );
};

export default FavoriteOverview;
