import { useRef, useState } from 'react';
import { Button, Dropdown, Menu } from 'components/base/controls';
import { Modal } from 'components/base/layout';
import QRCode from 'qrcode.react';
import { useTranslation } from 'react-i18next';
import { InitiatedFrom } from 'logic/userEventsProviders/GoogleTagManager';

interface Props {
    inviteLink: string;
    onDisableLinkClick: () => void;
    onCreateNewLinkClick: () => void;
    onCopyClik: (initiatedFrom: InitiatedFrom) => void;
}

function InviteLink(props: Props) {
    const { t } = useTranslation();
    const inputEl = useRef<HTMLInputElement>(null);
    const [linkCopied, setLinkCopied] = useState(false);

    const onCopyClick = () => {
        if (linkCopied) return;

        inputEl.current?.select();
        navigator.clipboard.writeText(props.inviteLink);
        setLinkCopied(true);
        props.onCopyClik(InitiatedFrom.DRIVER_INVITATION_COPY_BUTTON);

        setTimeout(() => {
            setLinkCopied(false);
        }, 2000);
    };

    const showQrCode = () => {
        Modal.open({
            width: 500,
            content: (
                <>
                    <p style={{ marginBottom: '40px' }}>{t('InviteLink.qrTitle')}</p>
                    <QRCode value={props.inviteLink} size={256} />
                </>
            )
        });
    };
    return (
        <div className="rl-invite-link-wrapper">
            <div className="rl-invite-link-input-wrapper">
                <input
                    readOnly
                    value={props.inviteLink}
                    placeholder={t('InviteLink.inputPlaceholder')}
                    ref={inputEl}
                    size={10}
                />
            </div>
            <div className="rl-invite-link-btns">
                <Button type={linkCopied ? 'primary' : 'dark'} onClick={onCopyClick} disabled={!props.inviteLink}>
                    {linkCopied ? t('InviteLink.linkCopied') : t('InviteLink.copyLink')}
                </Button>
                <Dropdown
                    overlay={
                        <Menu>
                            <Menu.Item key="0" onClick={props.onDisableLinkClick} disabled={!props.inviteLink}>
                                {t('InviteLink.disableLink')}
                            </Menu.Item>
                            <Menu.Item key="1" onClick={props.onCreateNewLinkClick}>
                                {t('InviteLink.createNewLink')}
                            </Menu.Item>
                            <Menu.Item key="2" onClick={showQrCode} disabled={!props.inviteLink}>
                                {t('InviteLink.viewQrCode')}
                            </Menu.Item>
                        </Menu>
                    }
                    trigger={['click']}
                    arrow
                    placement="bottom"
                >
                    <Button className="more-btn">
                        <i className="fas fa-ellipsis-h" />
                    </Button>
                </Dropdown>
            </div>
        </div>
    );
}

export default InviteLink;
