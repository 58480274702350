import { Button, DatePicker } from 'components/base/controls';
import moment, { Moment } from 'moment';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
    date: string;
    onChangeDate: (date: string) => void;
}

export const CalendarViewDatePicker = (props: Props) => {
    const { t } = useTranslation();
    const [selectedDate, setSelectedDate] = useState(props.date);
    const [datePickerOpen, setDatePickerOpen] = useState(false);

    const onChangeDate = (changedDate: Moment | null) => {
        if (changedDate) {
            setSelectedDate(changedDate.toISOString());
        }
    };

    const onConfirmDate = () => {
        setDatePickerOpen(false);
        props.onChangeDate(selectedDate);
    };

    const onSubstractDay = () => {
        const changedDate = moment(selectedDate).subtract(1, 'days').toISOString();
        setSelectedDate(changedDate);
        props.onChangeDate(changedDate);
    };

    const onAddDay = () => {
        const changedDate = moment(selectedDate).add(1, 'days').toISOString();
        setSelectedDate(changedDate);
        props.onChangeDate(changedDate);
    };

    const onDatePickerClose = () => {
        setDatePickerOpen(false);
        setSelectedDate(props.date);
    };

    return (
        <>
            <div className="rl-calendar-view-mobile-date-picker">
                <i className="rl-icon-chevron-left" onClick={onSubstractDay} />
                <div className="rl-calendar-view-mobile-date-picker-date" onClick={() => setDatePickerOpen(true)}>
                    {moment(props.date).format('D')}/{moment(props.date).format('M')}
                </div>
                <i className="rl-icon-chevron-right" onClick={onAddDay} />
            </div>

            <DatePicker
                value={moment(selectedDate)}
                showSecond={false}
                showTime={false}
                inputReadOnly={true}
                showToday={false}
                onChange={onChangeDate}
                dropdownClassName="rl-date-picker-dropdown rl-calendar-view-mobile-date-picker-dropdown"
                open={datePickerOpen}
                trigger={<></>}
                panelRender={panelNode => (
                    <div className="rl-date-picker-panel-node">
                        <div className="rl-date-picker-panel-node-header">
                            <Button
                                type="action"
                                icon={<i className="rl-icon-chevron-left" />}
                                onClick={onDatePickerClose}
                            />
                            <div className="rl-date-picker-panel-node-header-title">{t('common.selectDate')}</div>
                        </div>
                        {panelNode}

                        <div className="rl-date-picker-panel-node-footer">
                            <Button disabled={!selectedDate} type="primary" onClick={onConfirmDate}>
                                {t('common.confirm')}
                            </Button>
                        </div>
                    </div>
                )}
            />
        </>
    );
};
