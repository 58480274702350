/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ContactPerson
 */
export interface ContactPerson {
    /**
     * 
     * @type {string}
     * @memberof ContactPerson
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof ContactPerson
     */
    client: number;
    /**
     * 
     * @type {string}
     * @memberof ContactPerson
     */
    address: string;
    /**
     * 
     * @type {number}
     * @memberof ContactPerson
     */
    readonly deleted?: number;
    /**
     * 
     * @type {string}
     * @memberof ContactPerson
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactPerson
     */
    surname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactPerson
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactPerson
     */
    cellPhone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactPerson
     */
    position?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactPerson
     */
    ssoId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactPerson
     */
    username?: string | null;
}

export function ContactPersonFromJSON(json: any): ContactPerson {
    return ContactPersonFromJSONTyped(json, false);
}

export function ContactPersonFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContactPerson {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'client': json['client'],
        'address': json['address'],
        'deleted': !exists(json, 'deleted') ? undefined : json['deleted'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'surname': !exists(json, 'surname') ? undefined : json['surname'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'cellPhone': !exists(json, 'cell_phone') ? undefined : json['cell_phone'],
        'position': !exists(json, 'position') ? undefined : json['position'],
        'ssoId': !exists(json, 'sso_id') ? undefined : json['sso_id'],
        'username': !exists(json, 'username') ? undefined : json['username'],
    };
}

export function ContactPersonToJSON(value?: ContactPerson | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'client': value.client,
        'address': value.address,
        'name': value.name,
        'surname': value.surname,
        'email': value.email,
        'cell_phone': value.cellPhone,
        'position': value.position,
        'sso_id': value.ssoId,
        'username': value.username,
    };
}


