/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AlarmInDatabaseWithGPSInfo,
    AlarmInDatabaseWithGPSInfoFromJSON,
    AlarmInDatabaseWithGPSInfoToJSON,
    HTTPValidationError,
    HTTPValidationErrorFromJSON,
    HTTPValidationErrorToJSON,
} from '../models';

export interface AcknowledgeAlarmV1AlarmsAlarmAlarmIdAcknowledgePostRequest {
    alarmId: string;
}

export interface GetAlarmsV1AlarmsMyGetRequest {
    active?: boolean;
    limit?: number;
    offset?: number;
    subscribeDevice?: string;
    subscribeUser?: string;
    dateFrom?: Date;
    dateTo?: Date;
    monitoredObjectId?: number;
    canceled?: boolean;
}

/**
 * no description
 */
export class AlarmsApi extends runtime.BaseAPI {

    /**
     * Acknowledge Alarm
     */
    async acknowledgeAlarmV1AlarmsAlarmAlarmIdAcknowledgePostRaw(requestParameters: AcknowledgeAlarmV1AlarmsAlarmAlarmIdAcknowledgePostRequest): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.alarmId === null || requestParameters.alarmId === undefined) {
            throw new runtime.RequiredError('alarmId','Required parameter requestParameters.alarmId was null or undefined when calling acknowledgeAlarmV1AlarmsAlarmAlarmIdAcknowledgePost.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/alarms/alarm/{alarm_id}/acknowledge`.replace(`{${"alarm_id"}}`, encodeURIComponent(String(requestParameters.alarmId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Acknowledge Alarm
     */
    async acknowledgeAlarmV1AlarmsAlarmAlarmIdAcknowledgePost(requestParameters: AcknowledgeAlarmV1AlarmsAlarmAlarmIdAcknowledgePostRequest): Promise<object> {
        const response = await this.acknowledgeAlarmV1AlarmsAlarmAlarmIdAcknowledgePostRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get Alarms
     */
    async getAlarmsV1AlarmsMyGetRaw(requestParameters: GetAlarmsV1AlarmsMyGetRequest): Promise<runtime.ApiResponse<Array<AlarmInDatabaseWithGPSInfo>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.active !== undefined) {
            queryParameters['active'] = requestParameters.active;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.subscribeDevice !== undefined) {
            queryParameters['subscribe_device'] = requestParameters.subscribeDevice;
        }

        if (requestParameters.subscribeUser !== undefined) {
            queryParameters['subscribe_user'] = requestParameters.subscribeUser;
        }

        if (requestParameters.dateFrom !== undefined) {
            queryParameters['date_from'] = (requestParameters.dateFrom as any).toISOString();
        }

        if (requestParameters.dateTo !== undefined) {
            queryParameters['date_to'] = (requestParameters.dateTo as any).toISOString();
        }

        if (requestParameters.monitoredObjectId !== undefined) {
            queryParameters['monitored_object_id'] = requestParameters.monitoredObjectId;
        }

        if (requestParameters.canceled !== undefined) {
            queryParameters['canceled'] = requestParameters.canceled;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/alarms/my`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AlarmInDatabaseWithGPSInfoFromJSON));
    }

    /**
     * Get Alarms
     */
    async getAlarmsV1AlarmsMyGet(requestParameters: GetAlarmsV1AlarmsMyGetRequest): Promise<Array<AlarmInDatabaseWithGPSInfo>> {
        const response = await this.getAlarmsV1AlarmsMyGetRaw(requestParameters);
        return await response.value();
    }

}
