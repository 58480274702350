import { useTranslation } from 'react-i18next';
import cn from 'classnames';

export enum TrackingView {
    MAP_VIEW,
    LIST_VIEW
}

interface Props {
    view: TrackingView;
    onChangeView: (view: TrackingView) => void;
}

export function TrackingMapSwitch(props: Props) {
    const { t } = useTranslation();

    return (
        <div className="rl-tracking-map-switch">
            <div
                className={cn('rl-tracking-map-switch-tab', {
                    active: props.view === TrackingView.LIST_VIEW
                })}
                onClick={() => props.onChangeView(TrackingView.LIST_VIEW)}
            >
                <i className="rl-tracking-map-switch-tab-icon rl-icon-list" />
                {t('TrackingModule.mapSwitch.listView')}
            </div>
            <div
                className={cn('rl-tracking-map-switch-tab', {
                    active: props.view === TrackingView.MAP_VIEW
                })}
                onClick={() => props.onChangeView(TrackingView.MAP_VIEW)}
            >
                <i className="rl-tracking-map-switch-tab-icon rl-icon-map" />
                {t('common.mapView')}
            </div>
        </div>
    );
}
