import { Map } from 'mapbox-gl';

export class ViaPointLayer {
    public static async init(map: Map) {
        map.addSource('route-viapoint-source', {
            type: 'geojson',
            data: {
                type: 'FeatureCollection',
                features: []
            }
        });

        map.addLayer({
            id: 'route-viapoint-layer',
            type: 'circle',
            source: 'route-viapoint-source',
            layout: {
                visibility: 'visible'
            },
            paint: {
                'circle-color': '#ffffff',
                'circle-radius': 4,
                'circle-stroke-width': 2,
                'circle-stroke-color': '#000000',
                'circle-stroke-opacity': 0.5
            }
        });
    }
}
