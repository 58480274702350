import { Button } from 'components/base/controls';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

export enum BlankModuleType {
    TRACKING = 'tracking',
    DISPATCHER = 'dispatcher'
}

const BlankModuleConf = {
    dispatcher: {
        keyList: ['monitor', 'driverArrival', 'realTime', 'interface']
    },
    tracking: {
        keyList: ['noInstallation', 'liveLocation', 'eta', 'realTime', 'fleet']
    }
};

interface Props {
    type: BlankModuleType;
}

export function BlankModule(props: Props) {
    const { t } = useTranslation();
    const history = useHistory();
    const blankType = props.type;
    const keyList = BlankModuleConf[props.type].keyList;

    return (
        <div className={`rl-module blank-module blank-module-${blankType}`} style={{ bottom: 0, position: 'fixed' }}>
            <div className="blank-module-right">
                <img
                    className="blank-module-bg"
                    src={`/img/blank-module/blank-module-device-bg-${blankType}.png`}
                    alt="blank"
                />

                <div className="blank-module-title">{t(`BlankModule.${blankType}.title`)}</div>
                <div className="blank-module-description">{t(`BlankModule.${blankType}.description`)}</div>

                <ul>
                    {keyList.map((key, index) => (
                        <li key={index}>
                            <i className="fas fa-check-circle" /> {t(`BlankModule.${blankType}.${key}`)}
                        </li>
                    ))}
                </ul>

                <Button
                    className="blank-module-button"
                    type="primary"
                    size="large"
                    style={{ alignSelf: 'flex-start' }}
                    onClick={() => history.push({ search: '?register=true' })}
                >
                    {t('BlankModule.tryDispatcher')}
                </Button>
            </div>
            <div className="blank-module-mobile">
                <Button
                    className="blank-module-mobile-button"
                    type="primary"
                    size="large"
                    style={{ alignSelf: 'flex-start' }}
                    onClick={() => history.push({ search: '?register=true' })}
                >
                    {t('BlankModule.tryDispatcher')}
                </Button>
            </div>
        </div>
    );
}
