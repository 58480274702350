/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReadOnlyCountry
 */
export interface ReadOnlyCountry {
    /**
     * 
     * @type {number}
     * @memberof ReadOnlyCountry
     */
    readonly id?: number;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyCountry
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyCountry
     */
    iso2: string;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyCountry
     */
    iso3: string;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyCountry
     */
    timeZone: string;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyCountry
     */
    currency: string;
    /**
     * 
     * @type {number}
     * @memberof ReadOnlyCountry
     */
    vatRate: number;
}

export function ReadOnlyCountryFromJSON(json: any): ReadOnlyCountry {
    return ReadOnlyCountryFromJSONTyped(json, false);
}

export function ReadOnlyCountryFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReadOnlyCountry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': json['name'],
        'iso2': json['iso2'],
        'iso3': json['iso3'],
        'timeZone': json['time_zone'],
        'currency': json['currency'],
        'vatRate': json['vat_rate'],
    };
}

export function ReadOnlyCountryToJSON(value?: ReadOnlyCountry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'iso2': value.iso2,
        'iso3': value.iso3,
        'time_zone': value.timeZone,
        'currency': value.currency,
        'vat_rate': value.vatRate,
    };
}


