import { Table, TableProps } from 'antd';

interface Props {}

function EnahancedTable<T extends object = any>(props: TableProps<T> & Props) {
    return (
        <Table pagination={false} {...props} className={props.className ? 'rl-table ' + props.className : 'rl-table'} />
    );
}

export default EnahancedTable;
