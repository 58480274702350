/* tslint:disable */
/* eslint-disable */
/**
 * Telematics API
 * Telematics services API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ModificationOperationStats,
    ModificationOperationStatsFromJSON,
    ModificationOperationStatsFromJSONTyped,
    ModificationOperationStatsToJSON,
} from '.';

/**
 * 
 * @export
 * @interface OperationResultSummary
 */
export interface OperationResultSummary {
    /**
     * 
     * @type {ModificationOperationStats}
     * @memberof OperationResultSummary
     */
    created?: ModificationOperationStats;
    /**
     * 
     * @type {ModificationOperationStats}
     * @memberof OperationResultSummary
     */
    updated?: ModificationOperationStats;
    /**
     * 
     * @type {ModificationOperationStats}
     * @memberof OperationResultSummary
     */
    deleted?: ModificationOperationStats;
}

export function OperationResultSummaryFromJSON(json: any): OperationResultSummary {
    return OperationResultSummaryFromJSONTyped(json, false);
}

export function OperationResultSummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): OperationResultSummary {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'created': !exists(json, 'created') ? undefined : ModificationOperationStatsFromJSON(json['created']),
        'updated': !exists(json, 'updated') ? undefined : ModificationOperationStatsFromJSON(json['updated']),
        'deleted': !exists(json, 'deleted') ? undefined : ModificationOperationStatsFromJSON(json['deleted']),
    };
}

export function OperationResultSummaryToJSON(value?: OperationResultSummary | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'created': ModificationOperationStatsToJSON(value.created),
        'updated': ModificationOperationStatsToJSON(value.updated),
        'deleted': ModificationOperationStatsToJSON(value.deleted),
    };
}


