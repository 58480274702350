/* tslint:disable */
/* eslint-disable */
/**
 * Telematics API
 * Telematics services API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ClientApplication,
    ClientApplicationFromJSON,
    ClientApplicationFromJSONTyped,
    ClientApplicationToJSON,
    MainDataVersion,
    MainDataVersionFromJSON,
    MainDataVersionFromJSONTyped,
    MainDataVersionToJSON,
} from '.';

/**
 * 
 * @export
 * @interface MainDataResClientApplication
 */
export interface MainDataResClientApplication {
    /**
     * 
     * @type {MainDataVersion}
     * @memberof MainDataResClientApplication
     */
    version: MainDataVersion;
    /**
     * 
     * @type {ClientApplication}
     * @memberof MainDataResClientApplication
     */
    data?: ClientApplication;
    /**
     * 
     * @type {string}
     * @memberof MainDataResClientApplication
     */
    error?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MainDataResClientApplication
     */
    offset?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MainDataResClientApplication
     */
    total?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MainDataResClientApplication
     */
    limit?: number | null;
}

export function MainDataResClientApplicationFromJSON(json: any): MainDataResClientApplication {
    return MainDataResClientApplicationFromJSONTyped(json, false);
}

export function MainDataResClientApplicationFromJSONTyped(json: any, ignoreDiscriminator: boolean): MainDataResClientApplication {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'version': MainDataVersionFromJSON(json['version']),
        'data': !exists(json, 'data') ? undefined : ClientApplicationFromJSON(json['data']),
        'error': !exists(json, 'error') ? undefined : json['error'],
        'offset': !exists(json, 'offset') ? undefined : json['offset'],
        'total': !exists(json, 'total') ? undefined : json['total'],
        'limit': !exists(json, 'limit') ? undefined : json['limit'],
    };
}

export function MainDataResClientApplicationToJSON(value?: MainDataResClientApplication | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'version': MainDataVersionToJSON(value.version),
        'data': ClientApplicationToJSON(value.data),
        'error': value.error,
        'offset': value.offset,
        'total': value.total,
        'limit': value.limit,
    };
}


