import { Button, Tooltip } from 'components/base/controls';
import { PoiModelMapDiscount, PoiModelMapFuelType } from 'logic/map/logic/fuelStations';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { DATE_FORMAT } from 'utils/constants/domain-constants';

interface Props {
    fuelTypes?: PoiModelMapFuelType[];
    discount?: PoiModelMapDiscount;
    onHideAllPrices?: () => void;
}

export function PoiDetailFuelPrices(props: Props) {
    const { t } = useTranslation();
    const { fuelTypes, discount } = props;
    const order = ['diesel', 'adblue', 'cng', 'lng', 'lpg', 'bioalcohol', 'electricity', 'gasoline'];
    const sortedFuelTypes = fuelTypes?.sort((a, b) => {
        return order.indexOf(a.price?.type!) - order.indexOf(b.price?.type!);
    });

    return (
        <div className="rl-poi-detail-section">
            <div className="rl-poi-detail-fuel-prices-list">
                {sortedFuelTypes?.map((ft, index) => {
                    const isDiscount = discount?.bestDiscount.code === ft.code;

                    return (
                        <div className="rl-poi-detail-fuel-prices-card" key={index}>
                            <div className={`rl-poi-detail-fuel-type rl-poi-detail-fuel-type-${ft.price?.type}`} />
                            <div className="rl-poi-detail-fuel-prices-card-name">{ft.name.replace('_', ' ')}</div>

                            {isDiscount && (
                                <div className="rl-poi-detail-fuel-prices-card-discount">
                                    {`-${discount?.discountCard.discount}% ${discount?.discountCard.brandName}`}
                                </div>
                            )}

                            <Tooltip
                                placement="topLeft"
                                title={`${t('PoiDetail.priceLastUpdate')} ${moment(ft.price?.created).format(
                                    DATE_FORMAT
                                )}`}
                            >
                                <div
                                    className={`rl-poi-detail-fuel-prices-card-price ${
                                        isDiscount && 'rl-poi-detail-fuel-prices-card-price-discount'
                                    }`}
                                >
                                    {parseFloat(
                                        isDiscount ? discount.bestDiscount.price?.discountedPrice! : ft.price?.price!
                                    ).toFixed(3)}{' '}
                                    {ft.price?.currency}
                                </div>
                            </Tooltip>
                        </div>
                    );
                })}
                <Button type="link" onClick={() => props.onHideAllPrices?.()}>
                    {t('PoiDetailFuelGroups.hideAllPrices')}
                </Button>
            </div>
        </div>
    );
}
