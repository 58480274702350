import { Button } from 'components/base/controls';
import { useTranslation } from 'react-i18next';
import { InitiatedFrom } from 'logic/userEventsProviders/GoogleTagManager';

interface Props {
    onShowCreateVehicleModal?: (initiatedFrom: InitiatedFrom) => void;
}

const CalendarViewAddVehicle = (props: Props) => {
    const { t } = useTranslation();

    return (
        <div className="rl-calendar-view-add-vehicle">
            <Button
                type="primary"
                onClick={() => props.onShowCreateVehicleModal?.(InitiatedFrom.DISPATCH_BOARD_ADD_VEHICLE_BUTTON)}
            >
                <i className="rl-icon-plus" /> {t('DispatcherBoardVehicles.addVehicle')}
            </Button>
        </div>
    );
};

export default CalendarViewAddVehicle;
