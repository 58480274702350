import { Logic } from '../../logic/logic';
import { Component } from 'react';
import { AssignVehicleModal } from './ui/AssignVehicleModal/AssignVehicleModal';
import { CompanyVehicle } from '../../services/api/domains/VehiclesApi';
import { VehicleCreateEditModule } from 'modules/VehicleCreateEditModule';

interface Props {
    logic: Logic;
    visible: boolean;
    assigningToTransport?: boolean;
    onCancel: () => void;
    onConfirm: (vehicleId: string) => void;
}

interface State {
    vehicles?: CompanyVehicle[];
    createVehicleSidebar: {
        isVisible: boolean;
    };
}

class AssignVehicleModule extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            createVehicleSidebar: { isVisible: false }
        };
    }

    componentDidMount() {
        if (this.props.visible) {
            this.loadVehicles();
        }
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.visible !== this.props.visible && this.props.visible) {
            this.loadVehicles();
        }
    }

    render() {
        return (
            <>
                <AssignVehicleModal
                    visible={this.props.visible}
                    onCancel={this.props.onCancel}
                    onConfirm={this.props.onConfirm}
                    vehicles={this.state.vehicles}
                    assigningToTransport={this.props.assigningToTransport}
                    onCreateVehicleClick={() => {
                        this.props.onCancel();
                        this._openCreateVehicleSidebar();
                    }}
                />

                <VehicleCreateEditModule
                    logic={this.props.logic}
                    visible={this.state.createVehicleSidebar.isVisible}
                    type={'CREATE'}
                    onCancel={this._closeCreateVehicleSidebar}
                    afterCreate={() => {
                        this._closeCreateVehicleSidebar();
                    }}
                    afterSubmit={() => {}}
                />
            </>
        );
    }

    private async loadVehicles() {
        const companyId = this.props.logic.company().getCompany().companyId;
        const vehicles = await this.props.logic.apiService().vehicles().getVehicles(companyId);
        this.setState({ vehicles: vehicles.sort(vehicle => (vehicle.drivers?.[0] ? 1 : -1)) });
    }

    private _openCreateVehicleSidebar = () => {
        this.setState({
            createVehicleSidebar: {
                isVisible: true
            }
        });
    };

    private _closeCreateVehicleSidebar = () => {
        this.setState({
            createVehicleSidebar: {
                isVisible: false
            }
        });
    };
}

export default AssignVehicleModule;
