/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Module,
    ModuleFromJSON,
    ModuleFromJSONTyped,
    ModuleToJSON,
} from '.';

/**
 * 
 * @export
 * @interface ReadOnlyUserRight
 */
export interface ReadOnlyUserRight {
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyUserRight
     */
    id?: string;
    /**
     * 
     * @type {Module}
     * @memberof ReadOnlyUserRight
     */
    module: Module;
    /**
     * 
     * @type {number}
     * @memberof ReadOnlyUserRight
     */
    readonly deleted?: number;
    /**
     * 
     * @type {number}
     * @memberof ReadOnlyUserRight
     */
    number: number;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyUserRight
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyUserRight
     */
    label: string;
    /**
     * 
     * @type {Date}
     * @memberof ReadOnlyUserRight
     */
    readonly createdAt?: Date;
}

export function ReadOnlyUserRightFromJSON(json: any): ReadOnlyUserRight {
    return ReadOnlyUserRightFromJSONTyped(json, false);
}

export function ReadOnlyUserRightFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReadOnlyUserRight {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'module': ModuleFromJSON(json['module']),
        'deleted': !exists(json, 'deleted') ? undefined : json['deleted'],
        'number': json['number'],
        'code': json['code'],
        'label': json['label'],
        'createdAt': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
    };
}

export function ReadOnlyUserRightToJSON(value?: ReadOnlyUserRight | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'module': ModuleToJSON(value.module),
        'number': value.number,
        'code': value.code,
        'label': value.label,
    };
}


