import { useEffect, useState } from 'react';
import { Logic } from 'logic/logic';
import { Notification } from 'components/base/layout';
import { Favorite, FavoriteType } from 'services/api/domains/FavoritesApi';
import { Button, Spin } from 'components/base/controls';
import { useStore } from 'stores';
import { observer } from 'mobx-react';
import FavoritesBlank from './ui/FavoritesBlank/FavoritesBlank';
import FavoritesList from './ui/FavoritesList/FavoritesList';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { RouteNames } from 'router/routes';
import { PlaceType } from 'services/api/domains/PlacesApi';
import FavoriteOverview from './ui/FavoriteOverview/FavoriteOverview';
import { isMobileDevice } from 'utils/helpers/navigator';

const FAVORITE_CLICK_ZOOM = 18;

interface Props {
    logic: Logic;
}

const FAVORITES_MAP_PADDING = {
    left: 750,
    top: 94,
    bottom: 30,
    right: 94
};

const FavoritesModule = (props: Props) => {
    const { t } = useTranslation();
    const history = useHistory();
    const { favoritesStore } = useStore();
    const [favorite, setFavorite] = useState<Favorite>();

    useEffect(() => {
        favoritesStore.fetchFavoritesWithAddress();

        if (!isMobileDevice()) {
            props.logic.map().setPadding(FAVORITES_MAP_PADDING);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const _getFavoritePlaceType = (type: FavoriteType) => PlaceType[PlaceType[type]];

    const _onClickCard = (favorite: Favorite) => {
        if (favorite.center) {
            props.logic.map().jumpTo({ lat: favorite.center.lat, lng: favorite.center.lng }, FAVORITE_CLICK_ZOOM);
        }

        if (isMobileDevice()) {
            setFavorite(favorite);
        }
    };

    const _onDeleteFavorite = async (favorite: Favorite) => {
        try {
            await props.logic.poi().deleteFavorite(favorite.id!, favorite.type);
            setFavorite(undefined);
            props.logic.map().poiOff();
            props.logic.map().poi().destroy();

            Notification.success({
                message: t('FavoritesModul.deleteSuccess')
            });
        } catch (err) {
            console.error(err);
        }
    };

    const _onRenameFavorite = async (renamedFavorite: Favorite) => {
        try {
            await favoritesStore.updateFavoriteName(renamedFavorite);
            favorite && setFavorite(renamedFavorite);
            props.logic.map().poi().destroy();
            props.logic.map().poiOff();

            Notification.success({
                message: t('FavoritesModul.renameSuccess')
            });
        } catch (err) {
            console.error(err);
        }
    };

    const _onShowFavoriteDetail = (favoriteId: string) => {
        props.logic.map().showPoiDetail$.next(favoriteId);
    };

    const _onCloseFavoriteDetail = () => {
        if (favorite) {
            props.logic.poi().unselect(favorite.id, _getFavoritePlaceType(favorite.type));
            props.logic.map().selectedPoi().hide();
        }

        setFavorite(undefined);
    };

    return isMobileDevice() && favorite ? (
        <FavoriteOverview
            favorite={favorite}
            category={_getFavoritePlaceType(favorite.type)}
            onShowFavoriteDetail={_onShowFavoriteDetail}
            onCloseFavoriteDetail={_onCloseFavoriteDetail}
            onDeleteFavorite={_onDeleteFavorite}
            onRenameFavorite={_onRenameFavorite}
        />
    ) : (
        <div className="rl-favorites-module">
            <div className="rl-settings-module-header rl-favorites-module-header">
                {isMobileDevice() && (
                    <Button
                        className="rl-settings-module-header-back"
                        type="action"
                        icon={<i className="rl-icon-chevron-left" />}
                        onClick={() => history.push(RouteNames.SETTINGS)}
                    />
                )}

                <h3 className="rl-settings-module-header-title">{t('FavoritesList.title')}</h3>
            </div>
            <Spin loading={favoritesStore.isFetching}>
                {!favoritesStore.favoritesWithAddresses?.length ? (
                    <FavoritesBlank />
                ) : (
                    <FavoritesList
                        data={favoritesStore.favoritesWithAddresses ?? []}
                        onClickCard={_onClickCard}
                        onDelete={_onDeleteFavorite}
                        onRename={_onRenameFavorite}
                    />
                )}
            </Spin>
        </div>
    );
};

export default observer(FavoritesModule);
