/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InlineResponse200,
    InlineResponse200FromJSON,
    InlineResponse200ToJSON,
    ReadOnlyAddressNested,
    ReadOnlyAddressNestedFromJSON,
    ReadOnlyAddressNestedToJSON,
    WriteOnlyAddressNested,
    WriteOnlyAddressNestedFromJSON,
    WriteOnlyAddressNestedToJSON,
} from '../models';

export interface AddressCreateRequest {
    data: WriteOnlyAddressNested;
}

export interface AddressDeleteRequest {
    id: string;
}

export interface AddressListRequest {
    deleted?: number;
    id?: string;
    country?: string;
    client?: string;
    city?: string;
    street?: string;
    houseNumber?: string;
    zip?: string;
    type?: string;
    limit?: number;
    offset?: number;
}

export interface AddressPartialUpdateRequest {
    id: string;
    data: WriteOnlyAddressNested;
}

export interface AddressReadRequest {
    id: string;
}

export interface AddressUpdateRequest {
    id: string;
    data: WriteOnlyAddressNested;
}

/**
 * no description
 */
export class AddressApi extends runtime.BaseAPI {

    /**
     */
    async addressCreateRaw(requestParameters: AddressCreateRequest): Promise<runtime.ApiResponse<ReadOnlyAddressNested>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling addressCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/address/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyAddressNestedToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyAddressNestedFromJSON(jsonValue));
    }

    /**
     */
    async addressCreate(requestParameters: AddressCreateRequest): Promise<ReadOnlyAddressNested> {
        const response = await this.addressCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async addressDeleteRaw(requestParameters: AddressDeleteRequest): Promise<runtime.ApiResponse<ReadOnlyAddressNested>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling addressDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/address/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyAddressNestedFromJSON(jsonValue));
    }

    /**
     */
    async addressDelete(requestParameters: AddressDeleteRequest): Promise<ReadOnlyAddressNested> {
        const response = await this.addressDeleteRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async addressListRaw(requestParameters: AddressListRequest): Promise<runtime.ApiResponse<InlineResponse200>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.deleted !== undefined) {
            queryParameters['deleted'] = requestParameters.deleted;
        }

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.country !== undefined) {
            queryParameters['country'] = requestParameters.country;
        }

        if (requestParameters.client !== undefined) {
            queryParameters['client'] = requestParameters.client;
        }

        if (requestParameters.city !== undefined) {
            queryParameters['city'] = requestParameters.city;
        }

        if (requestParameters.street !== undefined) {
            queryParameters['street'] = requestParameters.street;
        }

        if (requestParameters.houseNumber !== undefined) {
            queryParameters['house_number'] = requestParameters.houseNumber;
        }

        if (requestParameters.zip !== undefined) {
            queryParameters['zip'] = requestParameters.zip;
        }

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/address/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse200FromJSON(jsonValue));
    }

    /**
     */
    async addressList(requestParameters: AddressListRequest): Promise<InlineResponse200> {
        const response = await this.addressListRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async addressPartialUpdateRaw(requestParameters: AddressPartialUpdateRequest): Promise<runtime.ApiResponse<ReadOnlyAddressNested>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling addressPartialUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling addressPartialUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/address/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyAddressNestedToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyAddressNestedFromJSON(jsonValue));
    }

    /**
     */
    async addressPartialUpdate(requestParameters: AddressPartialUpdateRequest): Promise<ReadOnlyAddressNested> {
        const response = await this.addressPartialUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async addressReadRaw(requestParameters: AddressReadRequest): Promise<runtime.ApiResponse<ReadOnlyAddressNested>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling addressRead.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/address/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyAddressNestedFromJSON(jsonValue));
    }

    /**
     */
    async addressRead(requestParameters: AddressReadRequest): Promise<ReadOnlyAddressNested> {
        const response = await this.addressReadRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async addressUpdateRaw(requestParameters: AddressUpdateRequest): Promise<runtime.ApiResponse<ReadOnlyAddressNested>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling addressUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling addressUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/address/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyAddressNestedToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyAddressNestedFromJSON(jsonValue));
    }

    /**
     */
    async addressUpdate(requestParameters: AddressUpdateRequest): Promise<ReadOnlyAddressNested> {
        const response = await this.addressUpdateRaw(requestParameters);
        return await response.value();
    }

}
