import { RlDateRange, TableViewData, TableViewFilterModel, TableViewFilterSelected } from '../../TableViewModule';
import TableViewFiltersBar from '../TableViewFiltersBar/TableViewFiltersBar';
import TableViewTable from '../TableViewTable/TableViewTable';
import { CompanyVehicle } from 'services/api/domains/VehiclesApi';
import { TransportState } from 'services/api/domains/TransportsApi';
import { DispatcherBoardViewBar } from 'modules/DispatcherBoardModule/common/DispatcherBoardViewBar';
import TableViewFilterTags from '../TableViewFilterTags/TableViewFilterTags';

interface Props {
    dateRange: RlDateRange;
    table: {
        data?: TableViewData;
        loading: boolean;
        processing: boolean;
        selectedId?: string;
    };
    vehicles?: CompanyVehicle[];
    filter: TableViewFilterModel;
    onDateRangeChange?: (dateRange: RlDateRange) => void;
    onAssignVehicleClick?: (transportId: string) => void;
    onRemoveTransportClick?: (transportId: string, state: TransportState) => void;
    onTableRowExpand?: (type: keyof TableViewData) => void;
    onTableSetStateClick?: (transportId: string, state: TransportState) => void;
    onTableTrackOnMapClick?: (vehicleRN: string) => void;
    onAddVehicleClick?: () => void;
    onTransportEditClick?: (transportId: string, vehicleId: string) => void;
    onTransportDetailClick?: (transportId: string) => void;
    onFilterChange?: (filter: TableViewFilterSelected) => void;
    onFilterClear?: () => void;
    onOpenFilterDrawer?: () => void;
}

const TableView = (props: Props) => {
    return (
        // bottom: 0 because footer is temporarily hide
        <div className="rl-table-view-module" style={{ bottom: 0 }}>
            <div className="rl-table-view">
                <DispatcherBoardViewBar />
                <TableViewFiltersBar
                    dateRange={props.dateRange}
                    filter={props.filter}
                    onDateRangeChange={props.onDateRangeChange}
                    onFilterChange={props.onFilterChange}
                    onOpenFilterDrawer={props.onOpenFilterDrawer}
                />
                <TableViewFilterTags
                    filter={props.filter}
                    onFilterClear={props.onFilterClear}
                    onFilterChange={props.onFilterChange}
                />
                <TableViewTable
                    data={props.table.data}
                    loading={props.table.loading}
                    processing={props.table.processing}
                    selectedId={props.table.selectedId}
                    vehicles={props.vehicles}
                    onAssignVehicleClick={props.onAssignVehicleClick}
                    onClickRowExpand={props.onTableRowExpand}
                    onRemoveTransportClick={props.onRemoveTransportClick}
                    onSetStateClick={props.onTableSetStateClick}
                    onTrackOnMapClick={props.onTableTrackOnMapClick}
                    onAddVehicleClick={props.onAddVehicleClick}
                    onTransportDetailClick={props.onTransportDetailClick}
                    onTransportEditClick={props.onTransportEditClick}
                />
            </div>
        </div>
    );
};

export default TableView;
