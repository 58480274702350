import { MapConf } from '../map';
import mapboxgl, { LngLatBounds, Marker } from 'mapbox-gl';
import { getCenterBoundsWithDistance } from '../utils';
import { TrackingTransport } from 'modules/TrackingModule/ui/TrackingTable/TrackingTable';
import { TrackingNavigationRoute } from 'modules/TrackingModule/TrackingModule';
import { DEFAULT_MAP_PADDING } from 'utils/constants/constants';
import { createRoot } from 'react-dom/client';
import { AlarmType } from 'generated/backend-api';
import { VehiclesLayers } from '../layers';
import VehicleClusterPopup from 'modules/MapModule/ui/vehicles/VehicleClusterPopup/VehicleClusterPopup';
import VehiclePopup from 'modules/MapModule/ui/vehicles/VehiclePopup/VehiclePopup';
import { Position } from 'geojson';

export interface Alarm {
    id: string;
    seen: boolean;
    timestamp: string;
    type: AlarmType;
}

export enum VehicleMarkerIcon {
    Moving = '/img/map-markers/vehicle-moving.svg',
    MovingSelected = '/img/map-markers/vehicle-moving-selected.svg',
    Idling = '/img/map-markers/vehicle-idling.svg',
    IdlingSelected = '/img/map-markers/vehicle-idling-selected.svg',
    Stationary = '/img/map-markers/rl-vehicle-marker.svg',
    StationarySelected = '/img/map-markers/vehicle-stationary-selected.svg',
    AlarmSelected = '/img/map-markers/vehicle-alarm-selected.svg',
    AlarmSelectedWarning = '/img/map-markers/vehicle-alarm-selected-warning.svg',
    Alarm = '/img/map-markers/vehicle-alarm.svg',
    AlarmWarning = '/img/map-markers/vehicle-alarm-warning.svg'
}

export interface VehicleModelMap {
    id: string;
    name: string;
    selected: boolean;
    centered: boolean;
    route: boolean;
    angle?: number;
    position: { lat?: number | null; lng?: number | null };
    driverName: string;
    registrationNumber: string;
    eta: string;
    rta?: string;
    lastUpdate: string;
    remainingDistance?: number;
    trackingTransport: TrackingTransport;
    navigationRoute: TrackingNavigationRoute;
    hovered?: boolean;
}

const VEHICLES_UNCLUSTERED_DISTANCE_TO_TOP = 20;
const VEHICLES_UNCLUSTERED_DISTANCE_LIMIT = 30;
const VEHICLES_CLUSTERED_DISTANCE_TO_RIGHT = 35;
const VEHICLES_CLUSTERED_DISTANCE_LIMIT = 20;

export class VehiclesMapController {
    private _conf: MapConf;
    private _map?: mapboxgl.Map;
    private _markers: GeoJSON.Feature<GeoJSON.Point>[];
    private _vehiclesById: { [id: string]: VehicleModelMap };
    private _popup?: Marker;
    private _padding: mapboxgl.PaddingOptions;
    private _enteredMarkerCoords?: Position;
    private _onVehicleClick?: (vehicle: VehicleModelMap) => void;
    private _onRouteOverviewClick?: (vehicle: VehicleModelMap) => void;
    private _onShareEtaClick?: (vehicle: VehicleModelMap) => void;

    constructor(conf: MapConf, map?: mapboxgl.Map) {
        this._map = map;
        this._conf = conf;
        this._markers = [];
        this._vehiclesById = {};
        this._padding = DEFAULT_MAP_PADDING;

        this._initEvents();
    }

    destroy() {
        this._markers = [];
        this._vehiclesById = {};
        const source = this._map?.getSource('vehicles') as mapboxgl.GeoJSONSource;
        if (source) {
            source.setData({
                type: 'FeatureCollection',
                features: []
            });
        }
    }

    onClick(cb: (vehicle: VehicleModelMap) => void): void {
        this._onVehicleClick = cb;
    }

    onRouteOverview(cb: (vehicle: VehicleModelMap) => void): void {
        this._onRouteOverviewClick = cb;
    }

    onShareEta(cb: (vehicle: VehicleModelMap) => void): void {
        this._onShareEtaClick = cb;
    }

    setData(data: VehicleModelMap[], fitSelectedVehicle = false): void {
        this._markers = data.map(vehicle => {
            const marker = this._mapVehicleMarker(vehicle);
            this._vehiclesById[vehicle.id] = vehicle;
            return marker;
        });

        this._renderFeatures();

        if (fitSelectedVehicle) {
            this.fitSelectedVehicle();
        }
    }

    private async _renderFeatures() {
        const source = this._map?.getSource('vehicles') as mapboxgl.GeoJSONSource;

        if (source) {
            source.setData({
                type: 'FeatureCollection',
                features: this._markers
            });
        }
    }

    fitSelectedVehicle() {
        const selectedMarker = this._markers.find(
            m => m.properties?.selected && m.properties.centered && !m.properties.route
        );

        if (selectedMarker) {
            this._zoomFeature(selectedMarker);
        }
    }

    fitVehicle(id: string) {
        const marker = this._markers.find(m => m.id === id);
        if (marker) {
            this._zoomFeature(marker);
        }
    }

    private _mapVehicleMarker(vehicle: VehicleModelMap) {
        const geoJsonFeature: GeoJSON.Feature<GeoJSON.Point> = {
            type: 'Feature',
            id: vehicle.id,
            properties: {
                //Required fileds for vehicle layer
                angle: vehicle.angle,
                selected: vehicle.selected,
                centered: vehicle.centered,
                hovered: vehicle.hovered,
                route: vehicle.route,
                registrationNumber: vehicle.registrationNumber
            },
            geometry: {
                type: 'Point',
                coordinates: [vehicle.position.lng!, vehicle.position.lat!]
            }
        };

        return geoJsonFeature;
    }

    fitVehicles() {
        if (this._markers && this._markers.length > 0) {
            const bounds = new LngLatBounds();
            this._markers.forEach(m => {
                bounds.extend([m.geometry.coordinates[0], m.geometry.coordinates[1]]);
            });

            if (this._map) {
                if (this._markers.length > 1) {
                    this._map?.fitBounds(bounds, { padding: this._padding });
                } else if (this._markers.length === 1) {
                    const coordinates = this._markers[0].geometry.coordinates;
                    const bounds = getCenterBoundsWithDistance(
                        coordinates[0],
                        coordinates[1],
                        this._conf.vehicles.vehicleCenterDistance
                    );
                    this._map?.fitBounds(bounds, { padding: this._padding });
                }
            }
        }
    }

    private _initEvents() {
        this._map?.on('click', VehiclesLayers.VEHICLES_CLUSTER, e => {
            this._removePopup();

            const features = this._map?.queryRenderedFeatures(e.point, {
                layers: [VehiclesLayers.VEHICLES_CLUSTER]
            }) as GeoJSON.Feature<GeoJSON.Point>[];

            const clusterId = features?.[0].properties?.cluster_id;
            const source = this._map?.getSource('vehicles') as mapboxgl.GeoJSONSource;

            source.getClusterExpansionZoom(clusterId, (err, zoom) => {
                if (err) return;

                this._map?.easeTo({
                    center: features?.[0].geometry.coordinates as [number, number],
                    zoom: zoom
                });
            });
        });

        this._map?.on('click', VehiclesLayers.VEHICLES_UNCLUSTERED, e => {
            this._removePopup();
            const vehicle = this._vehiclesById[e.features?.[0].id!];
            if (vehicle) {
                this._onVehicleClick?.(vehicle);
            }
        });

        this._map?.on('mouseenter', VehiclesLayers.VEHICLES_UNCLUSTERED, e => {
            const vehicle = this._vehiclesById[e.features?.[0].id!];
            const coords = (e.features?.[0].geometry as GeoJSON.Point).coordinates;

            if (vehicle) {
                this._removePopup();

                const el = document.createElement('div');
                const root = createRoot(el);

                root.render(
                    <VehiclePopup
                        vehicle={vehicle}
                        onVehicleIconClick={() => {
                            this._onVehicleClick?.(vehicle);
                        }}
                        onRouteOverviewClick={() => {
                            this._onRouteOverviewClick?.(vehicle);
                        }}
                        onShareEtaClick={() => {
                            this._onShareEtaClick?.(vehicle);
                        }}
                        onMouseLeave={this._removePopup}
                    />
                );

                this._popup = new mapboxgl.Marker(el).setLngLat([coords[0], coords[1]]).addTo(this._map!);
                this._enteredMarkerCoords = coords;
            }
        });

        this._map?.on('mouseleave', VehiclesLayers.VEHICLES_UNCLUSTERED, e => {
            if (!this._enteredMarkerCoords) {
                return;
            }

            const evtPoint = this._map!.project(e.lngLat);
            const markerPoint = this._map!.project(
                new mapboxgl.LngLat(this._enteredMarkerCoords[0], this._enteredMarkerCoords[1])
            );

            const yAbs = Math.abs(evtPoint.y - markerPoint.y + VEHICLES_UNCLUSTERED_DISTANCE_TO_TOP);
            const xAbs = Math.abs(evtPoint.x - markerPoint.x);
            const distance = Math.sqrt(Math.pow(yAbs, 2) + Math.pow(xAbs, 2));

            if (distance > VEHICLES_UNCLUSTERED_DISTANCE_LIMIT) {
                this._removePopup();
                this._enteredMarkerCoords = undefined;
            }
        });

        this._map?.on('mouseenter', VehiclesLayers.VEHICLES_CLUSTER, e => {
            const features = this._map?.queryRenderedFeatures(e.point, {
                layers: [VehiclesLayers.VEHICLES_CLUSTER]
            }) as GeoJSON.Feature<GeoJSON.Point>[];

            const clusterId = features?.[0].properties?.cluster_id;
            const pointCount = features?.[0].properties?.point_count;
            const source = this._map?.getSource('vehicles') as mapboxgl.GeoJSONSource;
            const coords = (e.features?.[0].geometry as GeoJSON.Point).coordinates;

            source.getClusterLeaves(clusterId, pointCount, 0, (error, features) => {
                if (!error) {
                    this._removePopup();

                    const vehicles = features.map(feature => this._vehiclesById[feature.id!]);
                    const el = document.createElement('div');
                    const root = createRoot(el);

                    root.render(
                        <VehicleClusterPopup
                            vehicles={vehicles}
                            onMouseLeave={this._removePopup}
                            onVehicleClick={this._onVehicleClick}
                        />
                    );

                    this._popup = new mapboxgl.Marker(el).setLngLat([coords[0], coords[1]]).addTo(this._map!);
                    this._enteredMarkerCoords = coords;
                }
            });
        });

        this._map?.on('mouseleave', VehiclesLayers.VEHICLES_CLUSTER, e => {
            if (!this._enteredMarkerCoords) {
                return;
            }

            const evtPoint = this._map!.project(e.lngLat);
            const markerPoint = this._map!.project(
                new mapboxgl.LngLat(this._enteredMarkerCoords[0], this._enteredMarkerCoords[1])
            );

            const yAbs = Math.abs(evtPoint.y - markerPoint.y);
            const xAbs = Math.abs(evtPoint.x - markerPoint.x - VEHICLES_CLUSTERED_DISTANCE_TO_RIGHT);
            const distance = Math.sqrt(Math.pow(yAbs, 2) + Math.pow(xAbs, 2));

            if (distance > VEHICLES_CLUSTERED_DISTANCE_LIMIT) {
                this._popup?.remove();
                this._enteredMarkerCoords = undefined;
            }
        });
    }

    private _removePopup = () => {
        this._popup?.remove();
    };

    private _zoomFeature(
        selectedFeature: GeoJSON.Feature<GeoJSON.Point>,
        clusterFeatures?: GeoJSON.Feature<GeoJSON.Point>[],
        zoom?: number
    ) {
        const features =
            clusterFeatures || (this._map?.querySourceFeatures('vehicles') as GeoJSON.Feature<GeoJSON.Point>[]);

        for (const feature of features) {
            if (feature.id?.toString() === selectedFeature.id) {
                this._map?.easeTo({
                    center: selectedFeature?.geometry.coordinates as [number, number],
                    zoom: zoom || this._map.getZoom()
                });

                return;
            } else {
                const source = this._map?.getSource('vehicles') as mapboxgl.GeoJSONSource;
                const clusterId = feature.properties?.cluster_id;
                const pointCount = feature.properties?.point_count;

                source.getClusterExpansionZoom(clusterId, (error, zoom) => {
                    if (error) return;
                    source.getClusterLeaves(clusterId, pointCount, 0, (error, features) => {
                        if (error) return;
                        this._zoomFeature(selectedFeature, features as GeoJSON.Feature<GeoJSON.Point>[], zoom);
                    });
                });
            }
        }
    }
}
