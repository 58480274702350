/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TripEdit
 */
export interface TripEdit {
    /**
     * 
     * @type {boolean}
     * @memberof TripEdit
     */
    isPrivate?: boolean;
}

export function TripEditFromJSON(json: any): TripEdit {
    return TripEditFromJSONTyped(json, false);
}

export function TripEditFromJSONTyped(json: any, ignoreDiscriminator: boolean): TripEdit {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isPrivate': !exists(json, 'isPrivate') ? undefined : json['isPrivate'],
    };
}

export function TripEditToJSON(value?: TripEdit | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'isPrivate': value.isPrivate,
    };
}


