import { TransportModel } from 'logic/dispatcher-board';
import { Country } from 'services/api/domains/CountryApi';

export const getLastPlaceIso2Code = (transport: TransportModel, countryList: Country[]): string => {
    const firstPlace = transport.places[0];
    const lastPlace = transport.places[transport.places.length - 1];

    return (
        countryList.find(
            country =>
                country.iso3?.toLowerCase() ===
                firstPlace.addressStructured?.[lastPlace.addressStructured.length - 1].countryCode.toLowerCase()
        )?.code || ''
    );
};
