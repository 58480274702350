import { AlarmInDatabaseWithGPSInfo, AlarmType } from 'generated/backend-api';
import { Logic } from 'logic/logic';
import { Component } from 'react';
import Notifications, { NotificationModel } from 'modules/AlarmsModule/ui/Notifications/Notifications';
import { WithTranslation, withTranslation } from 'react-i18next';
import AssignVehicleModule from 'modules/AssignVehicleModule/AssignVehicleModule';
import { Notification } from '../../components/base/layout';
import { Button } from '../../components/base/controls';
import moment from 'moment';
import { Subject, takeUntil } from 'rxjs';
import { InitiatedFrom } from 'logic/userEventsProviders/GoogleTagManager';
import { Driver } from 'services/api/domains/CompanyApi';

// TODO: JVN: Refactor alarms module
interface Props extends WithTranslation {
    logic: Logic;
    open: boolean;
    onClose: () => void;
}

interface State {
    alarms: AlarmInDatabaseWithGPSInfo[];
    selectedDriverId?: string;
}

class AlarmsModule extends Component<Props, State> {
    private _destroySignal$ = new Subject<void>();
    logic: Logic;

    constructor(props: Props) {
        super(props);
        this.logic = this.props.logic;
        this.state = {
            alarms: []
        };
    }

    componentDidMount() {
        this.props.logic.alarms().init();
        this.props.logic
            .alarms()
            .alarmsUpdates$.pipe(takeUntil(this._destroySignal$))
            .subscribe(async () => {
                let drivers: Driver[] = [];

                try {
                    drivers = await this.logic
                        .apiService()
                        .company()
                        .getCompanyDrivers(this.logic.company().getCompany().companyId);
                } catch (err) {
                    console.error(err);
                }

                this.setState({
                    alarms: [
                        ...(await Promise.all(
                            this.props.logic
                                .alarms()
                                .geolocationAlarms()
                                .map(async a => {
                                    if (a.lastGpsPointStartObj) {
                                        const address = await this.logic.geocoding().geocodeLatLng({
                                            lat: a.lastGpsPointStartObj.lat,
                                            lng: a.lastGpsPointStartObj.lng
                                        });
                                        a.uniqueData = { ...a.uniqueData, address };
                                    }

                                    // monitoredObjectId is driverId!
                                    const driver = drivers.find(
                                        d => String(d.profileId) === String(a.monitoredObjectId)
                                    );
                                    const driverName = driver?.name ?? driver?.alias ?? a.monitoredObjectId;

                                    return { ...a, uniqueData: { ...a.uniqueData, driverName } };
                                })
                        )),
                        ...(await Promise.all(
                            this.props.logic
                                .alarms()
                                .transportAlarms()
                                .map(async a => {
                                    if (a.uniqueData) {
                                        const companyId = this.logic.company().getCompany().companyId;
                                        const transport = await this.logic
                                            .apiService()
                                            .transports()
                                            .getTransport(companyId, Number(a.uniqueData['transportId']));

                                        return {
                                            ...a,
                                            uniqueData: {
                                                transportName: transport.name!,
                                                remainingTime: moment
                                                    .duration(moment(a.start).diff(transport.eta))
                                                    .format('D [day], H [hour and] m [min]'),
                                                remainingDistance: a.uniqueData?.['remainingDistance']
                                                    ? (+a.uniqueData['remainingDistance'] / 1000).toFixed(2)
                                                    : undefined
                                            }
                                        };
                                    }
                                    return a;
                                })
                        )),
                        ...(await Promise.all(
                            this.props.logic
                                .alarms()
                                .companyMembershipAlarms()
                                .map(async a => {
                                    const driver = drivers.find(d => d.profileId === Number(a.monitoredObjectId));

                                    return {
                                        ...a,
                                        uniqueData: {
                                            ...a.uniqueData,
                                            driverName: driver ? driver.name || driver.alias : a.monitoredObjectId
                                        }
                                    };
                                })
                        ))
                    ]
                });
            });
    }

    componentWillUnmount() {
        this.props.logic.alarms().destroy();
        this._destroySignal$.next();
    }

    private _onClose = () => {
        this.props.onClose();
    };

    render() {
        return (
            <>
                <Notifications
                    visible={this.props.open}
                    onClose={this._onClose}
                    notifications={this._mapAlarmsToNotifications(this.state.alarms)}
                />

                <AssignVehicleModule
                    logic={this.props.logic}
                    visible={!!this.state.selectedDriverId}
                    onConfirm={this._onSubmitAssignToVehicle}
                    onCancel={this._onCloseAssignToVehicle}
                />
            </>
        );
    }

    private _mapAlarmsToNotifications(alarms: AlarmInDatabaseWithGPSInfo[]): NotificationModel[] {
        return alarms.map(alarm => {
            const notification: NotificationModel = {
                content: '',
                type: 'info',
                timestamp: alarm.start.toISOString(),
                read: alarm.acknowledged ?? true
            };

            switch (alarm.alarmType) {
                case AlarmType.PoiArrival:
                    notification.content = (
                        <p>
                            {this.props.t('UserNotifications.driverArrived', {
                                driverName: alarm.uniqueData?.['driverName'],
                                address: alarm.uniqueData?.['address']
                            })}
                        </p>
                    );
                    break;

                case AlarmType.PoiDeparture:
                    notification.content = (
                        <p>
                            {this.props.t('UserNotifications.driverDepartured', {
                                driverName: alarm.uniqueData?.['driverName'],
                                address: alarm.uniqueData?.['address']
                            })}
                        </p>
                    );
                    break;

                case AlarmType.Delayed:
                    notification.content = (
                        <>
                            <p>
                                {this.props.t('UserNotifications.transportDelayed', {
                                    transportName: alarm.uniqueData?.['transportName']
                                })}
                            </p>
                            {alarm.uniqueData?.['remainingDistance'] && alarm.uniqueData?.['remainingTime'] && (
                                <p>
                                    {this.props.t('UserNotifications.transportRemaining', {
                                        distance: alarm.uniqueData?.['remainingDistance'],
                                        time: alarm.uniqueData?.['remainingTime']
                                    })}
                                </p>
                            )}
                        </>
                    );
                    notification.type = 'warning';
                    break;

                case AlarmType.OnTime:
                    notification.content = (
                        <p>
                            {this.props.t('UserNotifications.transportOntime', {
                                transportName: alarm.uniqueData?.['transportName']
                            })}
                        </p>
                    );
                    notification.type = 'success';
                    break;

                case AlarmType.Joined:
                    notification.content = (
                        <>
                            <p>
                                {this.props.t('UserNotifications.driverJoined', {
                                    driverName: alarm.uniqueData?.['driverName']
                                })}
                            </p>
                            <Button
                                type={'link'}
                                onClick={() => this._onClickAssignVehicle?.(alarm.monitoredObjectId!)}
                            >
                                {this.props.t('UserNotifications.assignVehicle')}
                            </Button>
                        </>
                    );
                    break;

                case AlarmType.Left:
                    notification.content = (
                        <p>
                            {this.props.t('UserNotifications.driverLeft', {
                                driverName: alarm.uniqueData?.['driverName']
                            })}
                        </p>
                    );
                    break;
            }

            return notification;
        });
    }

    private _onClickAssignVehicle = (driverId: string) => {
        this.setState({ selectedDriverId: driverId });
    };

    private _onSubmitAssignToVehicle = async (vehicleId: string) => {
        try {
            const companyId = this.props.logic.company().getCompany().companyId;
            await this.props.logic
                .apiService()
                .vehicles()
                .assignDriver(Number(this.state.selectedDriverId), companyId, Number(vehicleId));

            this.props.logic
                .userEvents()
                .assignDriver(+this.state.selectedDriverId!, +vehicleId, InitiatedFrom.NOTIFICATION);

            Notification.success({
                message: this.props.t('SettingsDrivers.assignVehicleSuccess')
            });
        } catch (err) {
            console.error(err);
            Notification.error({
                message: err.message
            });
        } finally {
            this._onCloseAssignToVehicle();
        }
    };

    private _onCloseAssignToVehicle = () => {
        this.setState({ selectedDriverId: undefined });
    };
}

export default withTranslation()(AlarmsModule);
