/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InlineResponse20040,
    InlineResponse20040FromJSON,
    InlineResponse20040ToJSON,
    ReadOnlyWhiteLabel,
    ReadOnlyWhiteLabelFromJSON,
    ReadOnlyWhiteLabelToJSON,
    WriteOnlyWhiteLabel,
    WriteOnlyWhiteLabelFromJSON,
    WriteOnlyWhiteLabelToJSON,
} from '../models';

export interface WhiteLabelCreateRequest {
    data: WriteOnlyWhiteLabel;
}

export interface WhiteLabelDeleteRequest {
    id: string;
}

export interface WhiteLabelListRequest {
    client?: string;
    limit?: number;
    offset?: number;
}

export interface WhiteLabelPartialUpdateRequest {
    id: string;
    data: WriteOnlyWhiteLabel;
}

export interface WhiteLabelReadRequest {
    id: string;
}

export interface WhiteLabelUpdateRequest {
    id: string;
    data: WriteOnlyWhiteLabel;
}

/**
 * no description
 */
export class WhiteLabelApi extends runtime.BaseAPI {

    /**
     */
    async whiteLabelCreateRaw(requestParameters: WhiteLabelCreateRequest): Promise<runtime.ApiResponse<ReadOnlyWhiteLabel>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling whiteLabelCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/white-label/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyWhiteLabelToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyWhiteLabelFromJSON(jsonValue));
    }

    /**
     */
    async whiteLabelCreate(requestParameters: WhiteLabelCreateRequest): Promise<ReadOnlyWhiteLabel> {
        const response = await this.whiteLabelCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async whiteLabelDeleteRaw(requestParameters: WhiteLabelDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling whiteLabelDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/white-label/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async whiteLabelDelete(requestParameters: WhiteLabelDeleteRequest): Promise<void> {
        await this.whiteLabelDeleteRaw(requestParameters);
    }

    /**
     */
    async whiteLabelListRaw(requestParameters: WhiteLabelListRequest): Promise<runtime.ApiResponse<InlineResponse20040>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.client !== undefined) {
            queryParameters['client'] = requestParameters.client;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/white-label/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20040FromJSON(jsonValue));
    }

    /**
     */
    async whiteLabelList(requestParameters: WhiteLabelListRequest): Promise<InlineResponse20040> {
        const response = await this.whiteLabelListRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async whiteLabelPartialUpdateRaw(requestParameters: WhiteLabelPartialUpdateRequest): Promise<runtime.ApiResponse<ReadOnlyWhiteLabel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling whiteLabelPartialUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling whiteLabelPartialUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/white-label/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyWhiteLabelToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyWhiteLabelFromJSON(jsonValue));
    }

    /**
     */
    async whiteLabelPartialUpdate(requestParameters: WhiteLabelPartialUpdateRequest): Promise<ReadOnlyWhiteLabel> {
        const response = await this.whiteLabelPartialUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async whiteLabelReadRaw(requestParameters: WhiteLabelReadRequest): Promise<runtime.ApiResponse<ReadOnlyWhiteLabel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling whiteLabelRead.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/white-label/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyWhiteLabelFromJSON(jsonValue));
    }

    /**
     */
    async whiteLabelRead(requestParameters: WhiteLabelReadRequest): Promise<ReadOnlyWhiteLabel> {
        const response = await this.whiteLabelReadRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async whiteLabelUpdateRaw(requestParameters: WhiteLabelUpdateRequest): Promise<runtime.ApiResponse<ReadOnlyWhiteLabel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling whiteLabelUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling whiteLabelUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/white-label/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyWhiteLabelToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyWhiteLabelFromJSON(jsonValue));
    }

    /**
     */
    async whiteLabelUpdate(requestParameters: WhiteLabelUpdateRequest): Promise<ReadOnlyWhiteLabel> {
        const response = await this.whiteLabelUpdateRaw(requestParameters);
        return await response.value();
    }

}
