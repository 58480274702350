/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MonitoredObjectCosts,
    MonitoredObjectCostsFromJSON,
    MonitoredObjectCostsFromJSONTyped,
    MonitoredObjectCostsToJSON,
} from '.';

/**
 * 
 * @export
 * @interface Costs
 */
export interface Costs {
    /**
     * 
     * @type {number}
     * @memberof Costs
     */
    fleetDistance: number;
    /**
     * 
     * @type {number}
     * @memberof Costs
     */
    monitoredObjectCount: number;
    /**
     * 
     * @type {number}
     * @memberof Costs
     */
    pricePerKmFleet: number;
    /**
     * 
     * @type {number}
     * @memberof Costs
     */
    pricePerKm: number;
    /**
     * 
     * @type {string}
     * @memberof Costs
     */
    currency: string;
    /**
     * 
     * @type {number}
     * @memberof Costs
     */
    companyCost: number;
    /**
     * 
     * @type {Array<MonitoredObjectCosts>}
     * @memberof Costs
     */
    moCosts: Array<MonitoredObjectCosts>;
}

export function CostsFromJSON(json: any): Costs {
    return CostsFromJSONTyped(json, false);
}

export function CostsFromJSONTyped(json: any, ignoreDiscriminator: boolean): Costs {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fleetDistance': json['fleet_distance'],
        'monitoredObjectCount': json['monitored_object_count'],
        'pricePerKmFleet': json['price_per_km_fleet'],
        'pricePerKm': json['price_per_km'],
        'currency': json['currency'],
        'companyCost': json['company_cost'],
        'moCosts': ((json['mo_costs'] as Array<any>).map(MonitoredObjectCostsFromJSON)),
    };
}

export function CostsToJSON(value?: Costs | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fleet_distance': value.fleetDistance,
        'monitored_object_count': value.monitoredObjectCount,
        'price_per_km_fleet': value.pricePerKmFleet,
        'price_per_km': value.pricePerKm,
        'currency': value.currency,
        'company_cost': value.companyCost,
        'mo_costs': ((value.moCosts as Array<any>).map(MonitoredObjectCostsToJSON)),
    };
}


