import { Conf } from 'conf';
import { HttpClient } from '../common/HttpClient';
import { CompanyBase } from './CompanyApi';

const USERS_API_VERSION = '7';

export interface DispatcherModel {
    avatarId: string;
    avatarUrl: string;
    profileId: number;
    nick: string;
    name: string;
    status: string;
    chatUsername: string;
    updatedOn: string;
    isAdmin: boolean;
    note: string;
    newslettersAgreement: boolean;
}

export interface DispatcherBaseModel {
    avatarUrl: string;
    createdOn: string;
    name: string;
    nick: string;
    profileId: number;
    status: string;
}

class UsersApi {
    constructor(private _conf: Conf['api'], private _httpClient: HttpClient) {}

    async updateMe(update: { language?: string; newslettersAgreement?: boolean }): Promise<DispatcherModel> {
        const patch = [];
        if (update.language) {
            patch.push({
                Op: 'replace',
                Path: 'LanguageLocale',
                Value: update.language
            });
        }

        if (typeof update.newslettersAgreement === 'boolean') {
            patch.push({
                Op: 'replace',
                Path: 'NewslettersAgreement',
                Value: !!update.newslettersAgreement
            });
        }

        return this._httpClient.patch(`${this._conf.coreUrl}/dispatcher/me`, patch, {
            headers: {
                'Accept-Version': USERS_API_VERSION
            }
        });
    }

    async getMe(): Promise<DispatcherModel> {
        return await this._httpClient.get(`${this._conf.coreUrl}/dispatcher/me`, {
            apiVersion: USERS_API_VERSION
        });
    }

    async createDispatcher(dispatcher: {
        name: string;
        languageLocale: string;
        avatarUrl?: string;
        note?: string;
        newslettersAgreement?: boolean;
    }) {
        return this._httpClient.post(`${this._conf.middlewareUrl}/dispatcher`, dispatcher, {
            apiVersion: USERS_API_VERSION
        });
    }

    async deleteUser(companyId: number, userId: string, type: string) {
        return this._httpClient.delete(`${this._conf.coreUrl}/membership/${companyId}/${type}/${userId}`, null, {
            apiVersion: USERS_API_VERSION
        });
    }

    //call middleware api
    async postSettingsDispatcher(name: string, password: string, email: string, languageLocale: string) {
        return this._httpClient.post(`${this._conf.middlewareUrl}/user`, {
            name,
            password,
            email,
            languageLocale
        });
    }

    //call middleware api
    async putSettingsMe(name: string, oldPassword: string, newPassword: string) {
        return this._httpClient.put(
            `${this._conf.middlewareUrl}/user/me`,
            { name, oldPassword, newPassword },
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        );
    }

    async registerUser(email: string, password: string, languageLocale: string, recaptchaToken: string) {
        return this._httpClient.post<{
            dispatcher: DispatcherBaseModel;
            company: CompanyBase;
        }>(`${this._conf.middlewareUrl}/user/register`, {
            email,
            password,
            languageLocale,
            recaptchaToken
        });
    }

    async registerUserWithoutAuthAccount(email: string, languageLocale: string) {
        return this._httpClient.post<{
            dispatcher: DispatcherBaseModel;
            company: CompanyBase;
        }>(`${this._conf.middlewareUrl}/user/registerWithoutAuthAccount`, {
            email,
            languageLocale
        });
    }
}

export { UsersApi };
