const baseUrl = 'https://dispatcher.api.roadlords.com';

export const CONF = {
    env: 'PROD',
    debug: true,
    lang: 'en',
    conf: {
        baseUrl: '/'
    },
    auth: {
        simpleAuth: {
            use: true,
            config: {
                authDomain: 'https://auth.api.sygic.com',
                authStatePersistence: 'local',
                clientId:
                    "'w'+([]+[]+[][[]])[(+!+[]+((+!+[])+(+!+[])))]+(typeof [])[(+!+[])]+(RegExp().constructor.name)[((+!+[])+(+!+[]))+(+!+[]+((+!+[])+(+!+[])))]+(![]+[])[((+!+[])+(+!+[]))]+(![]+[])[(+!+[])]+([]+[]+[][[]])[(+!+[])]+([]+[]+[][[]])[(+!+[])]+([]+[]+[][[]])[(+!+[]+((+!+[])+(+!+[])))]+(!![]+[])[(+!+[])]+`.`+(![]+[])[(+!+[]+((+!+[])+(+!+[])))]+(([]).constructor.name)[(+!+[])+(+!+[]+((+!+[])+(+!+[])))]+(typeof ([]+[]))[(+[+!+[]+[+[]]])/((+!+[])+(+!+[]))]+([]+[]+[][[]])[(+[+!+[]+[+[]]])/((+!+[])+(+!+[]))]+(typeof [])[((+!+[])+(+!+[]))*((+!+[])+(+!+[]))]+`.`+(![]+[])[(+!+[])]+(RegExp().constructor.name)[((+!+[])+(+!+[]))+(+!+[]+((+!+[])+(+!+[])))]+(RegExp().constructor.name)[((+!+[])+(+!+[]))+(+!+[]+((+!+[])+(+!+[])))]"
            }
        }
    },
    notification: {
        socketio: {
            uri: baseUrl,
            opts: {
                path: '/engine.io',
                transports: ['websocket']
            }
        }
    },
    whatsapp_messenger: {
        uri: baseUrl + '/messenger',
        path: '/messenger/engine.io'
    },
    api: {
        coreUrl: 'https://api.roadlords.net',
        placesUrl: 'https://places.api.roadlords.com/api',
        inviteUrl: 'https://go.roadlords.com/invite',
        tollCostUrl: 'https://tollcost.roadlords.net',
        geocodingUrl: 'https://geocoding.api.sygic.com',
        middlewareUrl: baseUrl + '/middleware/api',
        searchUrl: 'https://search.api.sygic.com/v1/api',
        shareEtaUrl: 'https://drive.api.roadlords.com/v1/shareEta'
    },
    map: {
        initBounds: [
            {
                lat: 37.84928353292369,
                lng: -18.851082343750022
            },
            {
                lat: 51.227885597987665,
                lng: 18.546378593749978
            }
        ],
        vehicles: {
            autocenter: false,
            vehicleCenterDistance: 2000
        }
    },
    monitoring: {
        bloomreach: {
            active: true
        },
        recaptcha: {
            siteKey: '6LeSBYQjAAAAAGksbWVPMYHJd8-vWeAEYdgrgH9m'
        }
    }
};
