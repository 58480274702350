import { createIcon } from '../utils';
import { PLACE_VISIBLE_MIN_ZOOM } from '../logic/places';
import mapboxgl from 'mapbox-gl';

export enum PlacesMarkerIcon {
    company = '/img/map-markers/rl-company.svg',
    companyCluster = '/img/map-markers/rl-company-cluster.svg',
    shop = '/img/map-markers/rl-shopping.svg',
    shoppingCluster = '/img/map-markers/rl-shopping-cluster.svg',
    services = '/img/map-markers/rl-services.svg',
    servicesCluster = '/img/map-markers/rl-services-cluster.svg'
}

export enum PlaceTypeLayer {
    COMPANY = 'COMPANY',
    SHOP = 'SHOP',
    SERVICES = 'SERVICES'
}

export enum PlaceLayers {
    PLACE_CLUSTER_COMPANY = 'place-cluster-company',
    PLACE_CLUSTER_SERVICES = 'place-cluster-services',
    PLACE_CLUSTER_SHOP = 'place-cluster-shop',
    PLACE_CLUSTER_COUNT_COMPANY = 'place-cluster-count-company',
    PLACE_CLUSTER_COUNT_SERVICES = 'place-cluster-count-services',
    PLACE_CLUSTER_COUNT_SHOP = 'place-cluster-count-shop',
    PLACE_UNCLUSTERED_COMPANY = 'place-unclustered-company',
    PLACE_UNCLUSTERED_SERVICES = 'place-unclustered-services',
    PLACE_UNCLUSTERED_SHOP = 'place-unclustered-shop'
}

export class PlacesLayer {
    public static async init(map: mapboxgl.Map) {
        await Promise.all([
            createIcon(PlaceTypeLayer.COMPANY + '-icon', PlacesMarkerIcon.company, map),
            createIcon(PlaceTypeLayer.COMPANY + '-cluster-icon', PlacesMarkerIcon.companyCluster, map),
            createIcon(PlaceTypeLayer.SHOP + '-icon', PlacesMarkerIcon.shop, map),
            createIcon(PlaceTypeLayer.SHOP + '-cluster-icon', PlacesMarkerIcon.shoppingCluster, map),
            createIcon(PlaceTypeLayer.SERVICES + '-icon', PlacesMarkerIcon.services, map),
            createIcon(PlaceTypeLayer.SERVICES + '-cluster-icon', PlacesMarkerIcon.servicesCluster, map)
        ]);

        map.addSource('places', {
            type: 'geojson',
            data: {
                type: 'FeatureCollection',
                features: []
            },
            cluster: true,
            clusterMaxZoom: 18,
            clusterRadius: 150,
            clusterProperties: {
                hasFavorites: ['any', ['boolean', ['get', 'isFavorite'], false]]
            }
        });

        Object.values(PlaceTypeLayer).forEach(category => {
            const sourceId = category;
            const clusterLayerId = PlaceLayers['PLACE_CLUSTER_' + category];

            map.addSource(sourceId, {
                type: 'geojson',
                data: {
                    type: 'FeatureCollection',
                    features: []
                },
                cluster: true,
                clusterMaxZoom: 18,
                clusterRadius: 150,
                clusterProperties: {
                    hasFavorites: ['any', ['boolean', ['get', 'isFavorite'], false]]
                }
            });

            map.addLayer({
                id: clusterLayerId,
                type: 'symbol',
                source: sourceId,
                filter: ['has', 'point_count'],
                minzoom: PLACE_VISIBLE_MIN_ZOOM,
                layout: {
                    'icon-image': category + '-cluster-icon',
                    'icon-allow-overlap': true,
                    'icon-anchor': 'center'
                }
            });

            map.addLayer({
                id: PlaceLayers['PLACE_CLUSTER_COUNT_' + category],
                type: 'symbol',
                source: sourceId,
                filter: ['has', 'point_count'],
                minzoom: PLACE_VISIBLE_MIN_ZOOM,
                layout: {
                    'text-field': '{point_count_abbreviated}',
                    'text-font': ['Open Sans Bold'],
                    'text-size': 13,
                    'text-offset': [0, -1],
                    'text-allow-overlap': true
                },
                paint: {
                    'text-color': 'white'
                }
            });

            map.addLayer({
                id: PlaceLayers['PLACE_UNCLUSTERED_' + category],
                type: 'symbol',
                minzoom: PLACE_VISIBLE_MIN_ZOOM,
                source: sourceId,
                filter: ['!', ['has', 'point_count']],
                layout: {
                    'icon-image': category + '-icon',
                    'icon-allow-overlap': true,
                    'icon-offset': [0, 11],
                    'icon-anchor': 'bottom'
                }
            });

            map.addLayer({
                id: 'places-unclustered-point-favorites-' + category,
                source: sourceId,
                type: 'symbol',
                minzoom: PLACE_VISIBLE_MIN_ZOOM,
                filter: ['all', ['!', ['has', 'point_count']], ['==', ['get', 'isFavorite'], true]],
                layout: {
                    'icon-image': 'favorites',
                    'icon-offset': [16, -34],
                    'icon-allow-overlap': true
                }
            });

            map.addLayer({
                id: 'places-clusters-favorites-' + category,
                type: 'symbol',
                source: sourceId,
                filter: ['all', ['has', 'point_count'], ['==', ['get', 'hasFavorites'], true]],
                minzoom: PLACE_VISIBLE_MIN_ZOOM,
                layout: {
                    'icon-image': 'favorites',
                    'icon-offset': [27, -20],
                    'icon-allow-overlap': true
                }
            });

            map.on('click', clusterLayerId, e => {
                const features = map.queryRenderedFeatures(e.point, {
                    layers: [clusterLayerId]
                }) as GeoJSON.Feature<GeoJSON.Point>[];

                const clusterId = features?.[0].properties?.cluster_id;
                const source = map.getSource(sourceId) as mapboxgl.GeoJSONSource;

                source.getClusterExpansionZoom(clusterId, (err, zoom) => {
                    if (err) return;

                    map.easeTo({
                        center: features?.[0].geometry.coordinates as [number, number],
                        zoom: zoom
                    });
                });
            });

            map.on('mouseenter', PlaceLayers['PLACE_UNCLUSTERED_' + category], () => {
                map.getCanvas().style.cursor = 'pointer';
            });

            map.on('mouseleave', PlaceLayers['PLACE_UNCLUSTERED_' + category], () => {
                map.getCanvas().style.cursor = '';
            });
        });
    }
}
