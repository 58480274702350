import { Button } from 'components/base/controls/Button';
import { Modal } from 'components/base/layout';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import { useTranslation, Trans } from 'react-i18next';
import { CONTACT_MAIL } from 'utils/constants/constants';

interface Props {
    visible: boolean;
    email: string;
    onCancel: () => void;
    onSendAgainClick: () => void;
}

function RegisterSuccessModal(props: Props) {
    const { t } = useTranslation();
    const { isMobileWidth } = useWindowDimensions();
    const { windowDimensions } = useWindowDimensions();

    return (
        <Modal
            className="rl-registration-success-modal"
            visible={props.visible}
            onCancel={props.onCancel}
            mask={!isMobileWidth}
            width={isMobileWidth ? windowDimensions.width : 752}
        >
            <div className="rl-registration-success-modal-header">
                <Button type="action" icon={<i className="rl-icon-chevron-left" />} onClick={props.onCancel} />
            </div>

            <div className="rl-registration-success-modal-content">
                <img src="/img/registration-success/avatar.svg" alt="Avatar icon" />
                <h3>{t('RegisterSuccessModal.title')}</h3>
                <p>{t('RegisterSuccessModal.success')}</p>
            </div>

            <div className="rl-registration-success-modal-footer">
                <span className="footer-text footer-text-with-icon">
                    <img src="/img/registration-success/verified.svg" alt="Verified icon" />
                    <Trans i18nKey="RegisterSuccessModal.verify" values={{ email: props.email }}>
                        Verify your email address by clicking the verification link we’ve sent to{' '}
                        <strong>example@roadlords.com</strong>.
                    </Trans>
                </span>
                <span className="footer-text" hidden>
                    {t('RegisterSuccessModal.cantFind')}{' '}
                    <Button type="link" onClick={props.onSendAgainClick} size={isMobileWidth ? 'middle' : 'large'}>
                        {t('RegisterSuccessModal.sendAgain')}
                    </Button>
                </span>
                <span className="footer-text">
                    <small>{t('RegisterSuccessModal.makeSure', { email: CONTACT_MAIL })}</small>
                </span>
            </div>
        </Modal>
    );
}

export default RegisterSuccessModal;
