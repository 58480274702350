/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReadOnlyUserGroup
 */
export interface ReadOnlyUserGroup {
    /**
     * 
     * @type {number}
     * @memberof ReadOnlyUserGroup
     */
    readonly id?: number;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyUserGroup
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyUserGroup
     */
    label: string;
    /**
     * 
     * @type {number}
     * @memberof ReadOnlyUserGroup
     */
    client: number;
}

export function ReadOnlyUserGroupFromJSON(json: any): ReadOnlyUserGroup {
    return ReadOnlyUserGroupFromJSONTyped(json, false);
}

export function ReadOnlyUserGroupFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReadOnlyUserGroup {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': json['name'],
        'label': json['label'],
        'client': json['client'],
    };
}

export function ReadOnlyUserGroupToJSON(value?: ReadOnlyUserGroup | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'label': value.label,
        'client': value.client,
    };
}


