import { Col, Row } from 'antd';
import { Button } from 'components/base/controls';
import { Trans, useTranslation } from 'react-i18next';

function HowInviteDriverWork() {
    const { t } = useTranslation();

    return (
        <div className="rl-how-invite-driver-work">
            <h5 className="rl-how-invite-driver-work-title">{t('HowInviteDriverWork.title')}</h5>

            <div className="rl-features-wrapper">
                <Row justify="space-between" gutter={24}>
                    <Col lg={8} sm={24}>
                        <div className="rl-feature">
                            <div className="rl-feature-icon">
                                <span className="icon-wrapper">
                                    <i className="fas fa-share-alt" />
                                </span>
                            </div>
                            <div className="rl-feature-content">
                                <span className="rl-feature-title">{t('HowInviteDriverWork.stepTitle')}</span>
                                <span className="rl-feature-text">{t('HowInviteDriverWork.stepText')}</span>
                            </div>
                        </div>
                    </Col>
                    <Col lg={8} sm={24}>
                        <div className="rl-feature">
                            <div className="rl-feature-icon">
                                <span className="icon-wrapper">
                                    <i className="fas fa-check" />
                                </span>
                            </div>
                            <div className="rl-feature-content">
                                <span className="rl-feature-title">{t('HowInviteDriverWork.step2Title')}</span>
                                <span className="rl-feature-text">
                                    <Trans i18nKey="HowInviteDriverWork.step2Text">
                                        The invite link is accepted by the driver in the
                                        <Button
                                            type="link"
                                            href="https://roadlords.com/download"
                                            target="_blank"
                                            style={{
                                                fontSize: 'inherit',
                                                fontWeight: 'inherit',
                                                lineHeight: 'inherit'
                                            }}
                                        >
                                            RoadLords
                                        </Button>
                                        app. You’ll be notified once this happens.
                                    </Trans>
                                </span>
                            </div>
                        </div>
                    </Col>
                    <Col lg={8} sm={24}>
                        <div className="rl-feature">
                            <div className="rl-feature-icon">
                                <span className="icon-wrapper">
                                    <i className="fas fa-location-arrow" />
                                </span>
                            </div>
                            <div className="rl-feature-content">
                                <span className="rl-feature-title">{t('HowInviteDriverWork.step3Title')}</span>
                                <span className="rl-feature-text">{t('HowInviteDriverWork.step3Text')}</span>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default HowInviteDriverWork;
