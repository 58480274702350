import { Col, Row } from 'antd';
import { Popover } from 'components/base/controls/Popover';
import { Input } from 'components/base/controls/Input';
import { Spin } from 'components/base/controls/Spin';
import { TollCosts } from 'services/api/domains/TollsApi';
import { TOLL_COST_DEFAULT_VALUE } from 'logic/scheduling-route-planner';
import { duration } from 'moment';
import 'moment-duration-format';
import { ChangeEvent, useEffect, useState } from 'react';
import { CostPerKm, PlannerVehicleProfile, TransportModel } from 'modules/PlannerModule/PlannerModule';
import { Button } from 'components/base/controls';
import { AvailableCurrencies } from 'utils/constants/currencies';
import { useTranslation } from 'react-i18next';
import RichEditor from 'components/RichEditor/RichEditor';
import { MouseEvent } from 'react';

interface Props {
    transport?: TransportModel;
    tollCosts?: TollCosts;
    name?: string;
    clientName?: string;
    tollCostsDetailVisible?: boolean;
    vehicleProfile?: PlannerVehicleProfile;
    loading?: boolean;
    currency: string;
    onNameChange?: (title: string) => void;
    onClientNameChange?: (title: string) => void;
    onRouteAvoids?: () => void;
    onNoteChange?: (text: string) => void;
}

const RouteConfigurator = (props: Props) => {
    const { t } = useTranslation();

    const [name, setName] = useState('');
    const [editNote, setEditNote] = useState(false);
    const [transportNameChanged, setTransportNameChanged] = useState(false);
    const [nameValidationError, setNameValidationError] = useState('');
    const [costPerKm, setCostPerKm] = useState<CostPerKm>({
        cost: TOLL_COST_DEFAULT_VALUE,
        currency: AvailableCurrencies.EUR
    });

    useEffect(() => {
        if (props.vehicleProfile?.costPerKm?.cost !== costPerKm.cost) {
            setCostPerKm(
                props.vehicleProfile?.costPerKm || {
                    cost: TOLL_COST_DEFAULT_VALUE,
                    currency: AvailableCurrencies.EUR
                }
            );
        }

        if (props.name !== name && !transportNameChanged) {
            setName(props.name ?? '');
        }
    }, [props.vehicleProfile, props.name]);

    const _getCost = (transport: TransportModel) => {
        const costWithoutToll = Math.round(costPerKm.cost * ((transport.distance ?? 0) / 1000) * 100) / 100;
        const tollFee = props.tollCostsDetailVisible ? props.tollCosts?.totalCost || 0 : 0;
        const totalCost = (costWithoutToll + tollFee).toFixed(2);
        const currency = props.tollCosts?.currency || props.currency || AvailableCurrencies.EUR;

        return props.tollCostsDetailVisible ? (
            <Popover
                content={
                    <div className="rl-route-configurator-selection-route-popover">
                        <span>{t('routing.configurator.popover.costsWithoutToll')}</span>
                        <span>{`${costWithoutToll} ${currency}`}</span>
                        <span>{t('routing.configurator.popover.tollFee')}</span>
                        <span>{`${tollFee} ${currency}`}</span>
                        <span>{t('routing.configurator.popover.totalCost')}</span>
                        <span>{`${totalCost} ${currency}`}</span>
                    </div>
                }
            >
                <span className="rl-route-configurator-route-cost">{`${totalCost} ${currency}`}</span>
            </Popover>
        ) : (
            <span className="rl-route-configurator-route-cost">{`${totalCost} ${currency}`}</span>
        );
    };

    const _onTitleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setTransportNameChanged(true);
        setName(e.target.value);
        setNameValidationError(e.target.value ? '' : t('validator.required'));
        props.onNameChange?.(e.target.value);
    };

    const _onClientChange = (e: ChangeEvent<HTMLInputElement>) => {
        props.onClientNameChange?.(e.target.value);
    };

    const _onNoteChange = (text?: string) => {
        props.onNoteChange?.(text || '');
    };

    const _onNoteClickOutside = (text?: string) => {
        setEditNote(false);
        props.onNoteChange?.(text || '');
    };

    const _addNote = (e: MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        setEditNote(true);
    };

    return (
        <div className="rl-route-configurator">
            <div className="rl-route-configurator-selection">
                <div className="rl-route-configurator-selection-head">
                    <Row>
                        <Col xs={0} md={5}>
                            {t('routing.configurator.routeParams.distance')}
                        </Col>
                        <Col xs={0} md={6}>
                            {t('routing.configurator.routeParams.duration')}
                        </Col>
                        <Col xs={0} md={6}>
                            {props.tollCostsDetailVisible ? t('routing.configurator.routeParams.toll') : null}
                        </Col>
                        <Col xs={0} md={6}>
                            {t('routing.configurator.routeParams.costs')}
                        </Col>
                    </Row>
                </div>

                {props.transport && (
                    <div className="rl-route-configurator-selection-route rl-route-configurator-selection-route-disabled">
                        <Row align="middle">
                            <Col className="rl-route-configurator-selection-route-label" xs={12} md={0}>
                                {t('routing.configurator.routeParams.distance')}
                            </Col>
                            <Col
                                className="rl-route-configurator-selection-route-text rl-route-configurator-selection-route-distance"
                                xs={12}
                                md={5}
                            >
                                {Math.round(Number(props.transport.distance) / 1000) + ' ' + t('common.km')}
                            </Col>

                            <Col className="rl-route-configurator-selection-route-label" xs={12} md={0}>
                                {t('routing.configurator.routeParams.duration')}
                            </Col>
                            <Col className="rl-route-configurator-selection-route-text" xs={12} md={6}>
                                {duration(props.transport.duration ?? 0, 'seconds').format('h[h] m[m]')}
                            </Col>

                            <Col className="rl-route-configurator-selection-route-label" xs={12} md={0}>
                                {props.tollCostsDetailVisible ? t('routing.configurator.routeParams.toll') : null}
                            </Col>
                            <Col className="rl-route-configurator-selection-route-text" xs={12} md={6}>
                                {props.tollCostsDetailVisible ? (
                                    props.loading ? (
                                        <Spin loading={true} size="default" />
                                    ) : (
                                        props.tollCosts && (
                                            <Button
                                                className="rl-toll-costs-button"
                                                onClick={e => {
                                                    e.stopPropagation();
                                                    props.onRouteAvoids?.();
                                                }}
                                            >
                                                {`${props.tollCosts.totalCost} ${props.tollCosts.currency}`}
                                            </Button>
                                        )
                                    )
                                ) : null}
                            </Col>

                            <Col className="rl-route-configurator-selection-route-label" xs={12} md={0}>
                                {t('routing.configurator.routeParams.costs')}
                            </Col>

                            <Col className="rl-route-configurator-selection-route-text" xs={12} md={7}>
                                {_getCost(props.transport)}
                            </Col>
                        </Row>
                    </div>
                )}
            </div>

            <div className="rl-route-configurator-details">
                <Row gutter={12}>
                    <Col xs={14}>
                        <div className="rl-route-configurator-details-item">
                            <span>{t('routing.configurator.details.name')}</span>
                            <Input
                                value={name}
                                placeholder={String(t('routing.configurator.empty'))}
                                onChange={_onTitleChange}
                            />
                        </div>
                        {nameValidationError && (
                            <div className="rl-route-configurator-details-validation t-bold t-text-danger">
                                {nameValidationError}
                            </div>
                        )}
                    </Col>
                    <Col xs={10}>
                        <div className="rl-route-configurator-details-item">
                            <span>{t('routing.configurator.details.client')}</span>
                            <Input
                                placeholder={String(t('routing.configurator.empty'))}
                                value={props.clientName}
                                onChange={_onClientChange}
                            />
                        </div>
                    </Col>
                </Row>
            </div>

            <div className="rl-route-configurator-note">
                {props.transport?.note || editNote ? (
                    <>
                        <div className="rl-route-configurator-note-title">{t('routing.configurator.note.title')}</div>
                        <RichEditor
                            text={props.transport?.note}
                            readonly={!editNote}
                            onChange={_onNoteChange}
                            onEditorClick={() => setEditNote(true)}
                            onEditorClickOutside={_onNoteClickOutside}
                        />
                    </>
                ) : (
                    <Button type="link" onClick={_addNote}>
                        {t('routing.configurator.note.addNote')}
                        <i className="rl-icon-plus" />
                    </Button>
                )}
            </div>
        </div>
    );
};

export default RouteConfigurator;
