import { AddressIdentification, Conf } from 'conf';
import { autorun, makeAutoObservable, runInAction, set, toJS, untracked } from 'mobx';
import { ApiService } from 'services/api/ApiService';
import { RootStore } from './RootStore';
import { AvailableCurrencies } from 'utils/constants/currencies';

export interface UserSettings {
    lang: string;
    currency: string;
    addressIdentification: string;
    calendarVehicleOrder: number[];
    trackingVehiclesHidden: number[];
}

interface IUserSettings extends UserSettings {
    setLang: (lang: string) => void;
    setCurrency: (currency: AvailableCurrencies) => void;
    setAddressIdentification: (value: any) => void;
    setCalendarVehicleOrder: (vehicleIds: number[]) => void;
    setTrackingVehicleListOrder: (vehicleId: number[]) => void;
}

const USER_SETTINGS_STORAGE_KEY = 'userSettings';

export class UserSettingsStore implements IUserSettings {
    lang: string;
    currency: string;
    addressIdentification: AddressIdentification;
    calendarVehicleOrder: number[];
    trackingVehicleListOrder: number[];
    trackingVehiclesHidden: number[];

    constructor(private _rootStore: RootStore, private _conf: Conf, private _apiService: ApiService) {
        this.lang = this._conf.settings.lang;
        this.currency = this._conf.settings.currency;
        this.addressIdentification = this._conf.settings.addressIdentification;
        this.calendarVehicleOrder = [];
        this.trackingVehicleListOrder = [];
        this.trackingVehiclesHidden = [];

        makeAutoObservable<UserSettingsStore, '_rootStore' | '_conf' | '_apiService'>(this, {
            _rootStore: false,
            _conf: false,
            _apiService: false
        });
    }

    async init() {
        const storedSettings = localStorage.getItem(USER_SETTINGS_STORAGE_KEY);

        if (storedSettings) {
            set(this, JSON.parse(storedSettings));
        }
    }

    setLang = (lang: string) => {
        this.lang = lang;
    };

    setCurrency = (currency: string) => {
        this.currency = currency;
    };

    setAddressIdentification = (value: AddressIdentification) => {
        this.addressIdentification = value;
    };

    setCalendarVehicleOrder = (vehicleIds: number[]) => {
        this.calendarVehicleOrder = vehicleIds;
    };

    setTrackingVehicleListOrder = (vehicleIds: number[]) => {
        this.trackingVehicleListOrder = vehicleIds;
    };

    setTrackingVehiclesHidden = (vehicleIds: number[]) => {
        this.trackingVehiclesHidden = vehicleIds;
    };

    autoSave(name: string = USER_SETTINGS_STORAGE_KEY) {
        let firstRun = true;
        autorun(() => {
            const {
                lang,
                currency,
                addressIdentification,
                calendarVehicleOrder,
                trackingVehicleListOrder,
                trackingVehiclesHidden
            } = toJS(this);

            const toPersist = {
                lang,
                currency,
                addressIdentification,
                calendarVehicleOrder,
                trackingVehicleListOrder,
                trackingVehiclesHidden
            };

            if (firstRun) {
                firstRun = false;
                return;
            }

            localStorage.setItem(name, JSON.stringify(toPersist));

            const isLoggedIn = untracked(() => this._rootStore.auth.isLoggedIn);
            if (isLoggedIn) {
                this._apiService.storage().setToStorage({
                    name: USER_SETTINGS_STORAGE_KEY,
                    content: JSON.stringify(toPersist)
                });
            }
        });
    }

    async fetchUserSettings() {
        try {
            const settings = await this._apiService.storage().getFromStorage<UserSettings>(USER_SETTINGS_STORAGE_KEY);
            if (settings) {
                runInAction(() => {
                    set(this, settings);
                });
            }
        } catch (err) {
            if (err.response?.status === 404) {
                // storage key not found
            } else {
                console.error(err);
            }
        }
    }
}
