/* tslint:disable */
/* eslint-disable */
/**
 * Telematics API
 * Telematics services API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NotificationChannelsResChannels
 */
export interface NotificationChannelsResChannels {
    /**
     * 
     * @type {string}
     * @memberof NotificationChannelsResChannels
     */
    user: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationChannelsResChannels
     */
    device: string;
    /**
     * 
     * @type {number}
     * @memberof NotificationChannelsResChannels
     */
    subs: number;
}

export function NotificationChannelsResChannelsFromJSON(json: any): NotificationChannelsResChannels {
    return NotificationChannelsResChannelsFromJSONTyped(json, false);
}

export function NotificationChannelsResChannelsFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotificationChannelsResChannels {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'user': json['user'],
        'device': json['device'],
        'subs': json['subs'],
    };
}

export function NotificationChannelsResChannelsToJSON(value?: NotificationChannelsResChannels | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'user': value.user,
        'device': value.device,
        'subs': value.subs,
    };
}


