/* tslint:disable */
/* eslint-disable */
/**
 * Telematics API
 * Telematics services API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TLangCodesISO6391,
    TLangCodesISO6391FromJSON,
    TLangCodesISO6391FromJSONTyped,
    TLangCodesISO6391ToJSON,
} from '.';

/**
 * 
 * @export
 * @interface UserUpdateBody
 */
export interface UserUpdateBody {
    /**
     * 
     * @type {TLangCodesISO6391}
     * @memberof UserUpdateBody
     */
    language: TLangCodesISO6391;
}

export function UserUpdateBodyFromJSON(json: any): UserUpdateBody {
    return UserUpdateBodyFromJSONTyped(json, false);
}

export function UserUpdateBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserUpdateBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'language': TLangCodesISO6391FromJSON(json['language']),
    };
}

export function UserUpdateBodyToJSON(value?: UserUpdateBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'language': TLangCodesISO6391ToJSON(value.language),
    };
}


