import { Input, Select } from 'components/base/controls';
import React, { Component, ChangeEvent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { PlacesTaskModel, TaskType } from '../../PlannerModule';
import { Tooltip } from 'components/base/controls/Tooltip';
import moment from 'moment';

export interface Props extends WithTranslation {
    placeId: string;
    task: PlacesTaskModel;
    onPlaceTaskChange: (task: PlacesTaskModel, reloadRoute: boolean) => void;
}

export enum TaskTypeIcon {
    Parking = '/img/planner/icn-rl-planner-parking.svg',
    Refueling = '/img/planner/icn-rl-planner-fuel.svg',
    Loading = '/img/planner/icn-rl-planner-load.svg',
    Unloading = '/img/planner/icn-rl-planner-unload.svg'
}

interface State {
    type?: TaskType;
    action?: string;
    additionalTime: number;
    secMultiplier: number;
}

class PlannerPlacesTask extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            type: props.task.type,
            action: props.task.action,
            ...this._getTimeFromSec(props.task.additionalTimeSec)
        };
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.task !== prevProps.task) {
            this.setState({
                type: this.props.task.type,
                action: this.props.task.action,
                ...this._getTimeFromSec(this.props.task.additionalTimeSec)
            });
        }
    }

    timeSelectOptions = {
        minutes: {
            secMultiplier: 60,
            key: 'minutes'
        },
        hours: {
            secMultiplier: 3600,
            key: 'hours'
        }
    };

    render() {
        const { t } = this.props;

        return (
            <div className="rl-planner-places-task">
                <div className="rl-planner-places-task-types-title">
                    <label>{t('PlannerPlacesTask.activity')}</label>
                    <Tooltip.Icon title={t('PlannerPlacesTask.activityTooltip')} placement="right" />
                </div>
                <div className="rl-planner-places-task-types">
                    {Object.keys(TaskType).map(type => {
                        return (
                            <div
                                key={type}
                                className={`${
                                    TaskType[type] === this.state.type
                                        ? 'rl-planner-places-task-types-button selected'
                                        : 'rl-planner-places-task-types-button'
                                }`}
                                onClick={this._onTypeItemClick.bind(undefined, TaskType[type])}
                            >
                                <img
                                    alt={TaskType[type]}
                                    title={t(`PlannerPlacesTask.${String(TaskType[type]).toLowerCase()}`)}
                                    src={TaskTypeIcon[type]}
                                />{' '}
                                {t(`PlannerPlacesTask.${String(TaskType[type]).toLowerCase()}`)}{' '}
                                {TaskType[type] === this.state.type && (
                                    <img
                                        onClick={this._onCancel}
                                        alt={TaskType[type]}
                                        title={t(`PlannerPlacesTask.${String(TaskType[type]).toLowerCase()}`)}
                                        src={'/img/planner/icn-rl-planner-cancel.svg'}
                                    />
                                )}
                            </div>
                        );
                    })}
                </div>
                <div className="rl-planner-places-task-time">
                    <div className="rl-planner-places-task-time-title">
                        <label>{t('PlannerPlacesTask.additionalTime')}</label>
                        <Tooltip.Icon title={t('PlannerPlacesTask.additionalTimeTooltip')} />
                    </div>
                    <div className="rl-planner-places-task-time-inputs">
                        <Input.Number
                            formatter={value => `${value}`}
                            min={0}
                            className={'rl-input'}
                            onBlur={() => this._onPlaceTaskChange(true)}
                            onChange={this._onAdditionalTimeChange}
                            value={this.state.additionalTime}
                            onKeyDown={PlannerPlacesTask._onBlurAfterPressEnter}
                        />
                        <Select defaultValue={this.state.secMultiplier} onChange={this._onSecMultiplierChange}>
                            {Object.values(this.timeSelectOptions).map(option => (
                                <Select.Option
                                    title={option.key}
                                    key={option.secMultiplier}
                                    value={option.secMultiplier}
                                >
                                    {t(`PlannerPlacesTask.${option.key}`, { count: this.state.additionalTime })}
                                </Select.Option>
                            ))}
                        </Select>
                    </div>
                </div>
                <div className="rl-planner-places-task-instructions">
                    <div className="rl-planner-places-task-instructions-title">
                        <label>{t('PlannerPlacesTask.instructionsForDriver')}</label>
                        <Tooltip.Icon title={t('PlannerPlacesTask.instructionsForDriverTooltip')} />
                    </div>
                    <div className="rl-planner-places-task-instructions-text">
                        <textarea
                            placeholder={t('PlannerPlacesTask.instructionsForDriver')}
                            value={this.state.action}
                            onChange={this._onNoteChange}
                        />
                    </div>
                </div>
            </div>
        );
    }

    private _getTimeFromSec(seconds: number): { secMultiplier: number; additionalTime: number } {
        const time = moment().startOf('day').seconds(seconds);
        const hours = time.hours();
        const minutes = time.minutes();

        if (hours && !minutes) {
            return {
                secMultiplier: this.timeSelectOptions.hours.secMultiplier,
                additionalTime: hours
            };
        }

        if (!hours && minutes) {
            return {
                secMultiplier: this.timeSelectOptions.minutes.secMultiplier,
                additionalTime: minutes
            };
        }

        if (hours && minutes) {
            return {
                secMultiplier: this.timeSelectOptions.minutes.secMultiplier,
                additionalTime: hours * 60 + minutes
            };
        }

        return {
            secMultiplier: this.timeSelectOptions.minutes.secMultiplier,
            additionalTime: 0
        };
    }

    private _onPlaceTaskChange = (reloadRoute: boolean = false): void => {
        this.props.onPlaceTaskChange?.(
            {
                id: this.props.placeId,
                type: this.state.type,
                action: this.state.action,
                additionalTimeSec: this.state.additionalTime * this.state.secMultiplier
            },
            reloadRoute
        );
    };

    private _onNoteChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        this.setState({ action: e.target.value }, () => this._onPlaceTaskChange());
    };

    private _onTypeItemClick = (type?: TaskType) => {
        this.setState({ type }, () => this._onPlaceTaskChange());
    };

    private _onCancel = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        this.setState(
            {
                type: undefined
            },
            () => this._onPlaceTaskChange()
        );
    };

    private _onAdditionalTimeChange = (value: number | string | null) => {
        const valueValid = !isNaN(Number(value)) && Number(value) > 0;
        this.setState({
            additionalTime: valueValid ? Number(value) : 0
        });
    };

    private _onSecMultiplierChange = (value: number) => {
        this.setState(
            {
                secMultiplier: value
            },
            () => this._onPlaceTaskChange(true)
        );
    };

    private static _onBlurAfterPressEnter(e: React.KeyboardEvent<HTMLInputElement>) {
        if (e.key === 'Enter') {
            e.currentTarget.blur();
        }
    }
}

export default withTranslation()(PlannerPlacesTask);
