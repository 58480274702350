import { Drawer } from '../../../../components/base/layout';
import cn from 'classnames';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { AlarmType } from '../../../../generated/backend-api';
import { ReactNode } from 'react';

type NotificationType = 'info' | 'success' | 'warning';
export interface NotificationModel {
    content: ReactNode;
    type: NotificationType;
    timestamp: string;
    read: boolean;
    action?: AlarmType;
    driverId?: string;
}

interface Props {
    notifications: NotificationModel[];
    visible: boolean;
    onClose?: () => void;
}

export default function Notifications(props: Props) {
    const { t } = useTranslation();

    return (
        <Drawer
            className="rl-notifications-drawer"
            title={t('UserNotifications.title')}
            width={368}
            mask={false}
            visible={props.visible}
            onClose={props.onClose}
        >
            <div className="rl-notifications">
                {props.notifications.length ? (
                    props.notifications
                        .sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime())
                        .map((item, index) => (
                            <NotificationCard key={index} type={item.type} read={item.read} timestamp={item.timestamp}>
                                {item.content}
                            </NotificationCard>
                        ))
                ) : (
                    <div className="notifications-empty">{t('UserNotifications.empty')}</div>
                )}
            </div>
        </Drawer>
    );
}

interface NotificationCardProps {
    type: NotificationType;
    read: boolean;
    timestamp: string;
}

function NotificationCard(props: React.PropsWithChildren<NotificationCardProps>) {
    return (
        <div
            className={cn('rl-notification-card', {
                unread: !props.read
            })}
        >
            <i
                className={cn('rl-notification-card-icon', {
                    'fas fa-info-circle info': props.type === 'info',
                    'fas fa-info-circle warning': props.type === 'warning',
                    'fas fa-check-circle success': props.type === 'success'
                })}
            />
            <div className="rl-notification-card-content">
                {props.children}
                <span>{moment(props.timestamp).calendar(null, { sameElse: 'L LT' })}</span>
            </div>
        </div>
    );
}
