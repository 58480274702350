import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';

function HowShareEtaWork() {
    const { t } = useTranslation();

    return (
        <div className="rl-how-share-eta-works">
            <div className="rl-features-wrapper">
                <Row justify="space-between" gutter={24}>
                    <Col span={12}>
                        <div className="rl-feature">
                            <div className="rl-feature-icon">
                                <span className="icon-wrapper">
                                    <i className="fas fa-share-alt" />
                                </span>
                            </div>
                            <div className="rl-feature-content">
                                <span className="rl-feature-title">{t('HowShareEtaWork.stepTitle')}</span>
                                <span className="rl-feature-text">{t('HowShareEtaWork.stepText')}</span>
                            </div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="rl-feature">
                            <div className="rl-feature-icon">
                                <span className="icon-wrapper">
                                    <i className="fas fa-check" />
                                </span>
                            </div>
                            <div className="rl-feature-content">
                                <span className="rl-feature-title">{t('HowShareEtaWork.step2Title')}</span>
                                <span className="rl-feature-text">{t('HowShareEtaWork.step2Text')}</span>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default HowShareEtaWork;
