/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InlineResponse20031,
    InlineResponse20031FromJSON,
    InlineResponse20031ToJSON,
    ReadOnlyServiceTypeSerializer,
    ReadOnlyServiceTypeSerializerFromJSON,
    ReadOnlyServiceTypeSerializerToJSON,
    WriteOnlyServiceTypeSerializer,
    WriteOnlyServiceTypeSerializerFromJSON,
    WriteOnlyServiceTypeSerializerToJSON,
} from '../models';

export interface ServiceTypeCreateRequest {
    data: WriteOnlyServiceTypeSerializer;
}

export interface ServiceTypeDeleteRequest {
    id: string;
}

export interface ServiceTypeListRequest {
    nameIcontains?: string;
    nameIexact?: string;
    createdAtIexact?: string;
    createdAtGte?: string;
    createdAtLte?: string;
    limit?: number;
    offset?: number;
}

export interface ServiceTypePartialUpdateRequest {
    id: string;
    data: WriteOnlyServiceTypeSerializer;
}

export interface ServiceTypeReadRequest {
    id: string;
}

export interface ServiceTypeUpdateRequest {
    id: string;
    data: WriteOnlyServiceTypeSerializer;
}

/**
 * no description
 */
export class ServiceTypeApi extends runtime.BaseAPI {

    /**
     */
    async serviceTypeCreateRaw(requestParameters: ServiceTypeCreateRequest): Promise<runtime.ApiResponse<ReadOnlyServiceTypeSerializer>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling serviceTypeCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/service-type/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyServiceTypeSerializerToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyServiceTypeSerializerFromJSON(jsonValue));
    }

    /**
     */
    async serviceTypeCreate(requestParameters: ServiceTypeCreateRequest): Promise<ReadOnlyServiceTypeSerializer> {
        const response = await this.serviceTypeCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async serviceTypeDeleteRaw(requestParameters: ServiceTypeDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling serviceTypeDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/service-type/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async serviceTypeDelete(requestParameters: ServiceTypeDeleteRequest): Promise<void> {
        await this.serviceTypeDeleteRaw(requestParameters);
    }

    /**
     */
    async serviceTypeListRaw(requestParameters: ServiceTypeListRequest): Promise<runtime.ApiResponse<InlineResponse20031>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.nameIcontains !== undefined) {
            queryParameters['name__icontains'] = requestParameters.nameIcontains;
        }

        if (requestParameters.nameIexact !== undefined) {
            queryParameters['name__iexact'] = requestParameters.nameIexact;
        }

        if (requestParameters.createdAtIexact !== undefined) {
            queryParameters['created_at__iexact'] = requestParameters.createdAtIexact;
        }

        if (requestParameters.createdAtGte !== undefined) {
            queryParameters['created_at__gte'] = requestParameters.createdAtGte;
        }

        if (requestParameters.createdAtLte !== undefined) {
            queryParameters['created_at__lte'] = requestParameters.createdAtLte;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/service-type/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20031FromJSON(jsonValue));
    }

    /**
     */
    async serviceTypeList(requestParameters: ServiceTypeListRequest): Promise<InlineResponse20031> {
        const response = await this.serviceTypeListRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async serviceTypePartialUpdateRaw(requestParameters: ServiceTypePartialUpdateRequest): Promise<runtime.ApiResponse<ReadOnlyServiceTypeSerializer>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling serviceTypePartialUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling serviceTypePartialUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/service-type/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyServiceTypeSerializerToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyServiceTypeSerializerFromJSON(jsonValue));
    }

    /**
     */
    async serviceTypePartialUpdate(requestParameters: ServiceTypePartialUpdateRequest): Promise<ReadOnlyServiceTypeSerializer> {
        const response = await this.serviceTypePartialUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async serviceTypeReadRaw(requestParameters: ServiceTypeReadRequest): Promise<runtime.ApiResponse<ReadOnlyServiceTypeSerializer>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling serviceTypeRead.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/service-type/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyServiceTypeSerializerFromJSON(jsonValue));
    }

    /**
     */
    async serviceTypeRead(requestParameters: ServiceTypeReadRequest): Promise<ReadOnlyServiceTypeSerializer> {
        const response = await this.serviceTypeReadRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async serviceTypeUpdateRaw(requestParameters: ServiceTypeUpdateRequest): Promise<runtime.ApiResponse<ReadOnlyServiceTypeSerializer>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling serviceTypeUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling serviceTypeUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/service-type/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyServiceTypeSerializerToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyServiceTypeSerializerFromJSON(jsonValue));
    }

    /**
     */
    async serviceTypeUpdate(requestParameters: ServiceTypeUpdateRequest): Promise<ReadOnlyServiceTypeSerializer> {
        const response = await this.serviceTypeUpdateRaw(requestParameters);
        return await response.value();
    }

}
