import { Button } from 'components/base/controls';
import { TrackingModel } from 'modules/TrackingModule/TrackingModule';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { TIME_DATE } from 'utils/constants/domain-constants';

interface Props {
    visible: boolean;
    model?: TrackingModel;
    onShowDetail: () => void;
    onRouteOverview: () => void;
    onClose: () => void;
}

const TruckOverview = (props: Props) => {
    const { t } = useTranslation();

    return (
        <>
            {props.visible && (
                <div className="rl-truck-overview">
                    <div className="rl-truck-overview-content">
                        <div className="rl-truck-overview-close">
                            <Button type="action">
                                <i className="rl-icon-close" onClick={props.onClose} />
                            </Button>
                        </div>
                        <div className="rl-truck-overview-content-rn">{props.model?.RN}</div>
                        <div className="rl-truck-overview-content-driver">{props.model?.driverName}</div>
                        <div className="rl-truck-overview-content-seen">
                            {props.model?.lastUpdate &&
                                t('TruckOverview.lastSeen', {
                                    time: moment(props.model?.lastUpdate).fromNow()
                                })}
                        </div>
                        <div className="rl-truck-overview-content-eta">
                            {t('TruckOverview.eta')} {moment(props.model?.navigationRoute.eta).format(TIME_DATE)}
                        </div>
                        <div className="rl-truck-overview-content-actions">
                            <Button type="primary" style={{ width: '100%' }} onClick={props.onShowDetail}>
                                {t('TruckOverview.showDetailButton')}
                            </Button>
                            {props.model?.navigationRoute.polyline && (
                                <Button type="default" style={{ width: '100%' }} onClick={props.onRouteOverview}>
                                    {t('TruckOverview.routeOverview')}
                                </Button>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default TruckOverview;
