/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MonitoredObjectOperationalCosts,
    MonitoredObjectOperationalCostsFromJSON,
    MonitoredObjectOperationalCostsFromJSONTyped,
    MonitoredObjectOperationalCostsToJSON,
} from '.';

/**
 * 
 * @export
 * @interface MonitoredObjectOperationalCostCreateMany
 */
export interface MonitoredObjectOperationalCostCreateMany {
    /**
     * 
     * @type {Array<number>}
     * @memberof MonitoredObjectOperationalCostCreateMany
     */
    monitoredObjects: Array<number>;
    /**
     * 
     * @type {MonitoredObjectOperationalCosts}
     * @memberof MonitoredObjectOperationalCostCreateMany
     */
    costs: MonitoredObjectOperationalCosts;
}

export function MonitoredObjectOperationalCostCreateManyFromJSON(json: any): MonitoredObjectOperationalCostCreateMany {
    return MonitoredObjectOperationalCostCreateManyFromJSONTyped(json, false);
}

export function MonitoredObjectOperationalCostCreateManyFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitoredObjectOperationalCostCreateMany {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'monitoredObjects': json['monitored_objects'],
        'costs': MonitoredObjectOperationalCostsFromJSON(json['costs']),
    };
}

export function MonitoredObjectOperationalCostCreateManyToJSON(value?: MonitoredObjectOperationalCostCreateMany | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'monitored_objects': value.monitoredObjects,
        'costs': MonitoredObjectOperationalCostsToJSON(value.costs),
    };
}


