import { Moment } from 'moment';
import { PlacesModel } from 'modules/PlannerModule/PlannerModule';
import { Logic } from './logic';
import { DATE_FORMAT } from 'utils/constants/domain-constants';
import { latLngFromGeoJsonPointType } from './common/geo-utils';
import { RootStore } from 'stores/RootStore';
import { IReactionDisposer, reaction } from 'mobx';
import { CompanyVehicle } from 'services/api/domains/VehiclesApi';
import { CompanyTransport, TransportState } from 'services/api/domains/TransportsApi';
import moment from 'moment';
import { MapPlaceType } from 'logic/map/map';
import { AlarmModel, AlarmType } from 'modules/PlannerModule/ui/PlannerAlarms';
import { DaysSegmentType } from 'modules/DispatcherBoardModule/common/types/DaySegment';
import {
    mapActivityToTaskType,
    mapNotificationToAlarmType,
    mapPointTypeToPlaceType,
    mapTransportStateToCompanyTransportStatus,
    mapTransportStatusToTransportState
} from './common/transports';
import { AddressIdentification } from 'conf';

type OnDataCallback = (data: { newTransports: TransportModel[]; otherTransports: TransportModel[] }) => void;

export interface TransportModel {
    id: string;
    name?: string;
    firstPlaceRta?: string;
    lastPlaceRta?: string;
    places: PlacesModel[];
    vehicleId?: string;
    client?: string;
    state: TransportState;
    eta?: string;
    remainingDistance?: number;
    duration?: number;
    distance?: number;
    note?: string;
}

export class DispatcherBoardLogic {
    private _data: TransportModel[];
    private _rawTranports: CompanyTransport[];
    private _vehicles: CompanyVehicle[];
    private _addressIdentification: AddressIdentification;
    private _mobxDisposeFunctions: IReactionDisposer[] = [];

    private _onTransportsChange?: OnDataCallback;
    private _onVehiclesChange?: (vehicles: CompanyVehicle[]) => void;

    constructor(private _logic: Logic, private _store: RootStore) {
        this._rawTranports = [];
        this._vehicles = [];
        this._data = [];
        this._addressIdentification = _store.userSettings.addressIdentification;
    }

    settings(): {
        eta: boolean;
        notes: boolean;
        break: boolean;
        expanded: boolean;
        startDate: string;
        daySegment: number;
        firstTransportPopoverVisible: boolean;
    } {
        const settings = this._logic.settings().getProp('dispatcherBoardCalendar');

        const filter = {
            eta: settings.eta ?? true,
            notes: settings.notes ?? false,
            break: settings.break ?? true,
            expanded: settings.expanded ?? true,
            startDate: settings.startDate,
            daySegment: settings.daySegment ?? DaysSegmentType.day5,
            firstTransportPopoverVisible: settings.firstTransportPopoverVisible ?? true
        };

        return filter;
    }

    setSettings(settings: {
        eta?: boolean;
        break?: boolean;
        expanded?: boolean;
        startDate?: Moment;
        notes?: boolean;
        daySegment?: number;
        firstTransportPopoverVisible?: boolean;
    }) {
        const originalSettings = this._logic.settings().getProp('dispatcherBoardCalendar');
        const modifiedSettings = {
            eta: settings.eta ?? originalSettings.eta,
            notes: settings.notes ?? originalSettings.notes,
            break: settings.break ?? originalSettings.break,
            expanded: settings.expanded ?? originalSettings.expanded,
            startDate: settings.startDate?.format(DATE_FORMAT) ?? originalSettings.startDate,
            daySegment: settings.daySegment ?? originalSettings.daySegment,
            firstTransportPopoverVisible:
                settings.firstTransportPopoverVisible ?? originalSettings.firstTransportPopoverVisible
        };

        this._logic.settings().setProp('dispatcherBoardCalendar', { ...modifiedSettings });
    }

    onTransportsChange(cb?: OnDataCallback) {
        this._onTransportsChange = cb;
    }

    onVehiclesChange(cb?: (vehicles: CompanyVehicle[]) => void) {
        this._onVehiclesChange = cb;
    }

    init() {
        this._addressIdentification = this._store.userSettings.addressIdentification;

        this._logic
            .vehicles()
            .getVehicles()
            .then(vehicles => {
                this._vehicles = vehicles;
                if (this._onVehiclesChange) {
                    this._onVehiclesChange(this._vehicles);
                }
            });

        this._mobxDisposeFunctions.push(
            reaction(
                () => this._store.userSettings.addressIdentification,
                addressIdentification => {
                    this._addressIdentification = addressIdentification;
                    this._data = this._rawTranports.map(t => this._toTransport(t));
                    const newTransports = this._data.filter(d => d.state === TransportState.New);
                    const otherTransports = this._data.filter(d => d.state !== TransportState.New);
                    this._onTransportsChange?.({
                        newTransports,
                        otherTransports
                    });
                }
            )
        );

        this._mobxDisposeFunctions.push(
            reaction(
                () => this._store.userSettings.lang,
                () => {
                    this._data = this._rawTranports.map(t => this._toTransport(t));
                    const newTransports = this._data.filter(d => d.state === TransportState.New);
                    const otherTransports = this._data.filter(d => d.state !== TransportState.New);
                    if (this._onTransportsChange) {
                        this._onTransportsChange({
                            newTransports,
                            otherTransports
                        });
                    }
                }
            )
        );

        this._logic.settings().onChange(prop => {
            if (prop.vehicleIdentification) {
                this._logic.vehicles().changeVehicleIdentification(prop.vehicleIdentification);
                this._logic
                    .vehicles()
                    .getVehicles()
                    .then(vehicles => {
                        this._vehicles = vehicles;
                        this._onVehiclesChange?.(this._vehicles);
                    });
            }
        });
    }

    destroy() {
        this._mobxDisposeFunctions.forEach(disposer => disposer());
        this._mobxDisposeFunctions = [];
    }

    // TODO: Refactor! We should not fetch all transport entries.
    async getTransportsCount(): Promise<number> {
        try {
            const companyId = this._logic.company().getCompany().companyId;
            const transports = await this._logic.apiService().transports().getTransports(companyId, {
                statuses: []
            });

            return transports.length;
        } catch (err) {
            console.log('Get transports count err', err);
            throw err;
        }
    }

    async transports(filter?: {
        dateFrom?: Moment;
        dateTo?: Moment;
    }): Promise<{ newTransports: TransportModel[]; otherTransports: TransportModel[] }> {
        this._data = await this._fetchTransports(filter);
        const newTransports = this._data.filter(d => d.state === TransportState.New);
        const otherTransports = this._data.filter(d => d.state !== TransportState.New);
        this._onTransportsChange?.({ newTransports, otherTransports });
        return { newTransports, otherTransports };
    }

    async transport(transportId: string): Promise<TransportModel> {
        try {
            const companyId = this._logic.company().getCompany().companyId;
            const transport = await this._logic.apiService().transports().getTransport(companyId, Number(transportId));
            return this._toTransport(transport ?? {});
        } catch (err) {
            console.log('Get transport err', err);
            throw err;
        }
    }

    async addVehicleToTransport(vehicleId: string, transportId: string, state: TransportState) {
        const companyId = this._logic.company().getCompany().companyId;
        const vehicle = this._rawTranports.find(t => t.vehicle?.vehicleId === +vehicleId)?.vehicle;

        await this._logic
            .apiService()
            .transports()
            .patchTransport(companyId, +transportId, {
                vehicleId: +vehicleId,
                route: {
                    cost: vehicle?.costPerKm
                },
                status: mapTransportStateToCompanyTransportStatus(state)
            });
    }

    transportBoardModel(transports: TransportModel[]): TransportModel[][] {
        let sortedTransports = transports.sort((a, b) =>
            moment(a?.firstPlaceRta).isAfter(moment(b?.firstPlaceRta)) ? 1 : -1
        );

        const helpSortedTransports: string[] = sortedTransports.map(t => t.id);
        const transportsArray: TransportModel[][] = [];

        helpSortedTransports.forEach(() => {
            const transportsBoard: TransportModel[] = sortedTransports.reduce((a: TransportModel[], c, i, source) => {
                if (
                    (i === 0 ||
                        !source[i - 1] ||
                        moment(c.firstPlaceRta).isAfter(moment(a[a.length - 1]?.lastPlaceRta))) &&
                    sortedTransports.filter(t => t.id === c.id)[0]
                ) {
                    a.push(c);
                    sortedTransports = sortedTransports.filter(t => t.id !== c.id);
                }
                return a;
            }, []);

            if (transportsBoard.length > 0) {
                transportsArray.push(transportsBoard);
            }
        });
        return transportsArray;
    }

    private async _fetchTransports(filter?: { dateFrom?: Moment; dateTo?: Moment }): Promise<TransportModel[]> {
        try {
            const companyId = this._logic.company().getCompany().companyId;
            const transports = await this._logic
                .apiService()
                .transports()
                .getTransports(companyId, {
                    statuses: [],
                    ...(filter?.dateFrom && { fromDate: filter?.dateFrom.utc(true).toDate() }),
                    ...(filter?.dateTo && { toDate: filter?.dateTo.utc(true).toDate() })
                });

            this._rawTranports = transports;

            return this._rawTranports.map(t => this._toTransport(t)).filter(t => t.state !== TransportState.Rejected);
        } catch (err) {
            console.log('Get transports err', err);
            throw err;
        }
    }

    private _toTransport(transport: CompanyTransport): TransportModel {
        const monitoredObjectId = transport.vehicle?.vehicleId?.toString();

        return {
            id: transport.transportId?.toString() || '',
            name: transport.name || '',
            client: transport.clientName,
            firstPlaceRta: transport.route.points?.[0].arrival,
            lastPlaceRta: transport.route.points?.[transport.route.points.length - 1].arrival,
            state: mapTransportStatusToTransportState(transport.status!) || ('' as any),
            vehicleId: monitoredObjectId?.toString() || '',
            duration: transport.route?.duration ?? 0,
            distance: transport.route?.distance ?? 0,
            eta: transport.eta,
            remainingDistance: transport.remainingDistance,
            places: (transport.route.points || []).map(
                (p, index) =>
                    ({
                        id: index.toString(),
                        center: latLngFromGeoJsonPointType(
                            { coordinates: [p.coordinate?.lon!, p.coordinate?.lat!] } ?? {}
                        ),
                        name: p.name || '',
                        tasks: [
                            {
                                id: index.toString(),
                                action: p.instructions,
                                type: mapActivityToTaskType(p.activityType!)
                            }
                        ],
                        rta: p.arrival,
                        rtd: p.departure,
                        addressStructured: [
                            {
                                address: p.address!,
                                lang: '',
                                countryCode: p.iso3,
                                country: '',
                                town: '',
                                route: '',
                                streetAddress: '',
                                postalCode: ''
                            }
                        ],
                        type: mapPointTypeToPlaceType(p.pointType!) ?? MapPlaceType.Waypoint,
                        alarms: (p.enabledNotifications! || []).map(
                            notification =>
                                ({
                                    type: mapNotificationToAlarmType(notification) as unknown as AlarmType,
                                    config: {
                                        name: '',
                                        value: ''
                                    }
                                } as unknown as AlarmModel)
                        )
                    } as PlacesModel)
            ),
            note: transport.note
        };
    }
}
