import { ColumnProps } from 'antd/lib/table';
import { Button, Dropdown, Menu } from 'components/base/controls';
import { Table } from 'components/base/layout';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import { useTranslation } from 'react-i18next';
import { DriverWithVehicle } from '../../DriversModule';

interface Props {
    data: DriverWithVehicle[];
    isLoading?: boolean;
    onRemoveClick: (driverId: number) => void;
    onAssignClick: (driverId: number) => void;
    onUnassignClick: (vehicleId: number, driverId: number) => void;
}

export function DriversTable(props: Props) {
    const { t } = useTranslation();
    const { isMobileWidth } = useWindowDimensions();

    const columns: ColumnProps<DriverWithVehicle>[] = [
        {
            title: t('common.name'),
            dataIndex: 'name',
            key: 'name',
            render: (name, record) => (
                <span style={{ whiteSpace: 'nowrap' }}>
                    {!isMobileWidth && <img src={record.avatarUrl} alt="Avatar" />}
                    <strong>{name ?? record.alias}</strong>
                </span>
            )
        },
        {
            title: t('SettingsDriversTable.assignedVehicle'),
            key: 'vehicle',
            render: (_, record) =>
                record.vehicle ? (
                    <div className="rl-drivers-table-assigned-vehicle">
                        <span>{record.vehicle.registrationNumber}</span>
                        <Button
                            type="link"
                            onClick={() => {
                                props.onUnassignClick(record.vehicle!.vehicleId!, record.profileId);
                            }}
                        >
                            {t('SettingsDriversTable.unassign')}
                        </Button>
                    </div>
                ) : (
                    <Button type="link" onClick={() => props.onAssignClick(record.profileId)}>
                        {t('SettingsDriversTable.assignVehicle')}
                    </Button>
                )
        },
        {
            key: 'actions',
            align: 'right',
            render: (_, record) => (
                <Dropdown
                    overlay={
                        <Menu>
                            <Menu.Item key="0" onClick={() => props.onRemoveClick(record.profileId)}>
                                {t('SettingsDriversTable.remove')}
                            </Menu.Item>
                        </Menu>
                    }
                    trigger={['click']}
                    arrow
                    placement="bottom"
                >
                    <i className="fas fa-ellipsis-h" />
                </Dropdown>
            )
        }
    ];

    return (
        <Table
            className="rl-drivers-table"
            columns={columns}
            dataSource={props.data}
            rowKey="profileId"
            loading={props.isLoading}
        />
    );
}
