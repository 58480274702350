import { AddressIdentification } from 'conf';
import { AddressStructured } from 'generated/backend-api';

export const toAddress = (
    userLang: string,
    addressStructure: AddressStructured[] | null | undefined,
    setting: AddressIdentification,
    defaultAddress: string | null | undefined
): string => {
    const customName = addressStructure?.find(a => a.lang === 'custom');
    if (customName) {
        return customName.address ?? '';
    }

    let address: AddressStructured | undefined;
    const defaultAddressHelper = defaultAddress ?? '';
    const userAddress = addressStructure?.find(a => a.lang === userLang);
    const clientAddress =
        addressStructure?.find(a => a.lang === userLang) || addressStructure?.find(a => a.lang === 'en');

    if (userAddress) {
        address = userAddress;
    } else if (clientAddress) {
        address = clientAddress;
    }

    if (address?.countryCode) {
        switch (setting) {
            case AddressIdentification.Address:
                // return `${address?.countryCode}${address?.town ? ', ' + address.town : ''}${streetAddress}`;
                return defaultAddressHelper;
            case AddressIdentification.Code:
                return `${address?.countryCode}${address?.postalCode?.substr(0, 2)}`;
            case AddressIdentification.CodeAndAddress:
                return address?.address ?? defaultAddressHelper;
            default:
                return address?.address ?? defaultAddressHelper;
        }
    } else {
        return defaultAddressHelper;
    }
};
