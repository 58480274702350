import polyline, { decode, encode, toGeoJSON } from '@mapbox/polyline';
import * as turf from '@turf/turf';
import { LatLng } from 'common/model/geo';
import { GeoJsonProperties, LineString, Point } from 'geojson';
import mapboxgl, { EventData, GeoJSONSource, LngLat, MapboxGeoJSONFeature, MapMouseEvent, Popup } from 'mapbox-gl';
import { MapConf, MapLogic, MapPadding } from '../map';
import { PoiModelMap } from './fuelStations';
import { FuelLayers } from '../layers/FuelstationsLayer';
import { ParkingLayers } from '../layers/ParkingsLayer';
import { PlaceLayers } from '../layers/PlacesLayer';
import { DEFAULT_MAP_PADDING } from 'utils/constants/constants';

interface MapPlace {
    id: string;
    lat: number;
    lng: number;
    isVia?: boolean;
}

const VIAPOINT_VISIBLE_MAX_DISTANCE_PX = 30;

const poisLayers = [
    FuelLayers.FUEL_CLUSTER,
    FuelLayers.FUEL_UNCLUSTERED,
    ParkingLayers.PARKING_CLUSTER,
    ParkingLayers.PARKING_UNCLUSTERED,
    PlaceLayers.PLACE_CLUSTER_COMPANY,
    PlaceLayers.PLACE_CLUSTER_SERVICES,
    PlaceLayers.PLACE_CLUSTER_SHOP,
    PlaceLayers.PLACE_UNCLUSTERED_COMPANY,
    PlaceLayers.PLACE_UNCLUSTERED_SERVICES,
    PlaceLayers.PLACE_UNCLUSTERED_SERVICES
];

export class RoutingMapController {
    private _mapBox?: mapboxgl.Map;
    private _padding: mapboxgl.PaddingOptions;
    private _markers: GeoJSON.Feature<GeoJSON.Point>[];

    private _polylinesPlanned: GeoJSON.Feature<GeoJSON.LineString>[];
    private _polylinesReal: GeoJSON.Feature<GeoJSON.LineString>[];

    private _fuelStations: PoiModelMap[][];
    private _parkings: PoiModelMap[][];

    private _mapLogic: MapLogic;

    private _parkingsRender: boolean;
    private _fuelStationsRender: boolean;

    private _draggingFeature?: MapboxGeoJSONFeature;
    private _viapointPopup?: mapboxgl.Popup;
    private _viapointLeavePosition?: LngLat;
    private _viapointProperties?: GeoJsonProperties;
    private _viapointCoordinates?: LineString;
    private _viapointSource?: GeoJSONSource;
    private _viapointDrag: boolean = false;
    private _wayPointEnter?: boolean = false;
    private _poiLayerEnter?: boolean = false;
    private _readOnly = false;
    private _markerMoved: boolean = false;

    private _onMarkerDragEnd?: (markerId: string, latLng: LatLng) => void;
    private _onViaPointDragEnd?: (routeIndex: number, latLng: LatLng) => void;
    private _onViaPointRemove?: (placeId: string) => void;

    constructor(private _conf: MapConf, mapLogic: MapLogic, mapbox?: mapboxgl.Map) {
        this._mapLogic = mapLogic;
        this._mapBox = mapbox;
        this._markers = [];

        this._polylinesPlanned = [];
        this._polylinesReal = [];

        this._fuelStations = [];
        this._fuelStationsRender = true;

        this._parkings = [];
        this._parkingsRender = true;

        this._padding = DEFAULT_MAP_PADDING;

        this._mapLogic.onMapLoad(() => this._addEvents());
    }

    init(): void {
        const bounds = new mapboxgl.LngLatBounds();
        this._conf.initBounds.forEach(place => {
            bounds.extend(new LngLat(place.lng, place.lat));
        });

        this._mapBox?.setPadding({ left: 0, right: 0, top: 0, bottom: 0 }); // Reset padding
        this._mapBox?.fitBounds(bounds, { padding: DEFAULT_MAP_PADDING, zoom: 0, linear: true });
    }

    onFuelStationClick(cb?: (fuelStation: PoiModelMap) => void): void {
        this._mapLogic.fuelStations().onClick(cb);
    }

    onParkingClick(cb?: (parking: PoiModelMap) => void): void {
        this._mapLogic.parkings().onClick(cb);
    }

    onViaPointDragEnd(cb: (routeIndex: number, latLng: LatLng) => void) {
        this._onViaPointDragEnd = cb;
    }

    onViaPointRemove(cb: (placeId: string) => void) {
        this._onViaPointRemove = cb;
    }

    onMarkerDragEnd(cb?: (markerId: string, latLng: LatLng) => void): void {
        this._onMarkerDragEnd = cb;
    }

    destroy(): void {
        this._padding = DEFAULT_MAP_PADDING;
        this.renderRoute([], [], []);
        this.resetPois();
    }

    resetPois() {
        this._removeFuelStations();
        this._removeParkings();
    }

    setFuelStations(data: PoiModelMap[][]): void {
        this._fuelStations = data;
        if (this._fuelStations && this._fuelStationsRender) {
            this._mapLogic.fuelStations().setData(this._fuelStations.flat());
        }
    }

    setParkings(data: PoiModelMap[][]): void {
        this._parkings = data;
        if (this._parkings && this._parkingsRender) {
            this._mapLogic.parkings().setData(this._parkings.flat());
        }
    }

    setFuelStationsRender(value: boolean): void {
        this._fuelStationsRender = value;
    }

    setParkingRender(value: boolean): void {
        this._parkingsRender = value;
    }

    fuelStations(): PoiModelMap[][] {
        return this._fuelStations;
    }

    parkings(): PoiModelMap[][] {
        return this._parkings;
    }

    hideParkings() {
        this._parkings = this._parkings.map(p => p.map(pl => ({ ...pl, selected: false })));
        this._mapLogic.parkings().hide();
    }

    showParkings() {
        if (this._mapBox && this._parkings.length) {
            this._mapLogic.parkings().show();
        }
    }

    hideFuelStations() {
        this._fuelStations = this._fuelStations.map(f => f.map(fs => ({ ...fs, selected: false })));
        this._mapLogic.fuelStations().hide();
    }

    showFuelStations() {
        if (this._mapBox && this._fuelStations.length) {
            this._mapLogic.fuelStations().show();
        }
    }

    fitRoute(padding?: MapPadding) {
        if (!(this._markers.length || this._polylinesPlanned.length || this._polylinesReal.length)) {
            return;
        }

        const bounds = new mapboxgl.LngLatBounds();

        // All Markers (before route render)
        this._markers.forEach(m => {
            bounds.extend([m.geometry.coordinates[0], m.geometry.coordinates[1]]);
        });

        // All Routes
        // planed
        this._polylinesPlanned.forEach(route =>
            route.geometry.coordinates.forEach(point => bounds.extend([point[0], point[1]]))
        );

        // real
        // if (this._polylinesReal.length > 0) {
        //     this._polylinesReal.forEach(p => p.getPath().forEach(point => bounds.extend(point)));
        // }
        this._polylinesReal.forEach(route =>
            route.geometry.coordinates.forEach(point => bounds.extend([point[0], point[1]]))
        );

        try {
            // fitbounds can throw invalid lat/lng error when padding is larger than remaining space
            // https://github.com/mapbox/mapbox-gl-js/issues/8732
            this._mapBox?.fitBounds(bounds, { padding: padding ?? this._padding, linear: true, maxZoom: 13 });
        } catch (err) {
            console.warn(err.message);
        }
    }

    setPadding(padding: MapPadding) {
        this._padding = padding;
    }

    removeMapObjects(objectsToRemove: {
        fuelStations?: boolean;
        parkings?: boolean;
        markers?: boolean;
        plannedPolylines?: boolean;
        removePolylinesReal?: boolean;
    }) {
        objectsToRemove.fuelStations && this._removeFuelStations();
        objectsToRemove.parkings && this._removeParkings();
        objectsToRemove.markers && this._removeMarkers();
        objectsToRemove.plannedPolylines && this._removePlannedPolylines();
        objectsToRemove.removePolylinesReal && this._removePolylinesReal();
    }

    renderRoute(
        places: MapPlace[],
        encodedRoutes: string[],
        realRoutes: string[] = [],
        fitRoute: boolean = false,
        tracking: boolean = false,
        readOnly: boolean = false
    ): void {
        this._removeFuelStations();
        this._removeParkings();
        this._removeMarkers();
        this._removePlannedPolylines();
        this._removePolylinesReal();

        this._readOnly = readOnly;

        const decode = (route: any) => {
            return polyline.decode(route, 5).map(value => [value[1], value[0]]);
        };

        this._polylinesPlanned = encodedRoutes.map((route, index) => {
            return {
                type: 'Feature',
                id: index + 1,
                properties: {
                    routeId: index + 1
                    // isAlternative: route.isAlternative
                },
                geometry: {
                    type: 'LineString',
                    coordinates: decode(route)
                }
            };
        });

        const trackingFinishPlaces: number[][] = [];
        if (tracking && realRoutes.length > 0) {
            this._polylinesReal = realRoutes.map((route, index) => {
                const decodeRoute = decode(route);

                trackingFinishPlaces.push(decodeRoute[decodeRoute.length - 1]);

                return {
                    type: 'Feature',
                    id: index + 1,
                    properties: {
                        routeId: index + 1
                        // isAlternative: route.isAlternative
                    },
                    geometry: {
                        type: 'LineString',
                        coordinates: decodeRoute
                    }
                };
            });

            this._renderPolylinesReal();
        }

        this._markers =
            trackingFinishPlaces.length > 0
                ? this._mapTrackingFinishPlaces(trackingFinishPlaces)
                : this._mapRoutePlaces(places);

        this._renderMarkers();
        this._renderPlannedPolylines();

        fitRoute && this.fitRoute();
    }

    pointToPolylineDistance(
        polyline: string,
        point: {
            lng: number;
            lat: number;
        }
    ): number {
        const line = turf.lineString(decode(polyline));
        const pointFeature = turf.point([point.lat, point.lng]);

        return turf.pointToLineDistance(pointFeature, line, { units: 'kilometers' });
    }

    /**
     * Method returns points that fall within (Multi)Polygon(s) created from polyline based on turf's pointsWithinPolygon
     * @external https://turfjs.org/docs/#pointsWithinPolygon
     */
    pointsAlongPolyline(
        polyline: string,
        pois: PoiModelMap[],
        routeIndex?: number,
        returnAllPoints: boolean = false
    ): PoiModelMap[] {
        const points = turf.points(pois.map(e => [e.position.lng, e.position.lat]));
        const decoded = decode(polyline);

        // Return empty array if polyline contains less than two points
        if (decoded.length < 2) {
            return [];
        }

        // Making polyline simpler
        const polylineFiltrationRate = Math.max(1, Math.floor(decoded.length / 100));
        const filteredPolyline = decoded.filter((data, index) => {
            return index % polylineFiltrationRate === 0;
        });

        // Turf conversion & Calculating points within polygon
        const reEncodedPolyline = encode(filteredPolyline);
        const geo = turf.buffer(toGeoJSON(reEncodedPolyline), 20000, { units: 'meters' });
        const within = turf.pointsWithinPolygon(points, geo);

        if (returnAllPoints) {
            return pois.map(p => ({
                ...p,
                ...{
                    routeIndex: routeIndex,
                    isAlongRoute: within.features.some(
                        geoPoint =>
                            geoPoint.geometry?.coordinates[0] === p.position.lng &&
                            geoPoint.geometry?.coordinates[1] === p.position.lat
                    )
                }
            }));
        } else {
            return pois.filter(p =>
                within.features.some(
                    geoPoint =>
                        geoPoint.geometry?.coordinates[0] === p.position.lng &&
                        geoPoint.geometry?.coordinates[1] === p.position.lat
                )
            );
        }
    }

    private _renderPlannedPolylines() {
        const source = this._mapBox?.getSource('route-source') as mapboxgl.GeoJSONSource | undefined;
        source?.setData({
            type: 'FeatureCollection',
            features: this._polylinesPlanned
        });
    }

    private _renderPolylinesReal() {
        const source = this._mapBox?.getSource('route-real-source') as mapboxgl.GeoJSONSource | undefined;
        source?.setData({
            type: 'FeatureCollection',
            features: this._polylinesReal
        });
    }

    private _renderMarkers() {
        const source = this._mapBox?.getSource('route-marker-source') as mapboxgl.GeoJSONSource | undefined;
        source?.setData({
            type: 'FeatureCollection',
            features: this._markers
        });

        this._resetViaPointState(true);
    }

    private _removePlannedPolylines() {
        const source = this._mapBox?.getSource('route-source') as mapboxgl.GeoJSONSource | undefined;
        source?.setData({
            type: 'FeatureCollection',
            features: []
        });
        this._polylinesPlanned = [];
    }

    private _removePolylinesReal() {
        const source = this._mapBox?.getSource('route-real-source') as mapboxgl.GeoJSONSource | undefined;
        source?.setData({
            type: 'FeatureCollection',
            features: []
        });
        this._polylinesReal = [];
    }

    private _removeMarkers() {
        const source = this._mapBox?.getSource('route-marker-source') as mapboxgl.GeoJSONSource | undefined;
        source?.setData({
            type: 'FeatureCollection',
            features: []
        });

        this._markers = [];
    }

    private _removeFuelStations() {
        this._fuelStations = [];
    }

    private _removeParkings() {
        this._parkings = [];
    }

    private _resetViaPointState = (resetViapointSource: boolean = false) => {
        this._viapointCoordinates = undefined;
        this._viapointProperties = undefined;
        this._viapointLeavePosition = undefined;

        if (resetViapointSource) {
            this._viapointSource?.setData({
                type: 'FeatureCollection',
                features: []
            });
        }
    };

    private _addEvents() {
        const _onMove = (event: mapboxgl.MapMouseEvent | mapboxgl.MapTouchEvent) => {
            if (event.originalEvent.cancelable) {
                event.preventDefault();
            }

            const lngLat = event.lngLat;
            const featureId = this._draggingFeature?.properties?.placeId;
            const feature = this._markers.find(marker => marker.properties?.placeId === featureId);

            if (feature) {
                feature.geometry.coordinates = [lngLat.lng, lngLat.lat];
                this._renderMarkers();
                this._markerMoved = true;

                // const marker = new Marker();
                // marker.set('place_id', featureId);
                // this._onMarkerDrag?.(marker as any, lngLat);
            }
        };

        const _onUp = (event: mapboxgl.MapMouseEvent) => {
            if (this._markerMoved) {
                const featureId = this._draggingFeature?.properties?.placeId;
                this._onMarkerDragEnd?.(featureId, event.lngLat);
                this._draggingFeature = undefined;
                this._markerMoved = false;
            }

            this._mapBox?.off('mousemove', _onMove);
        };

        const _onDown = (
            event: (mapboxgl.MapMouseEvent | mapboxgl.MapTouchEvent) & {
                features?: mapboxgl.MapboxGeoJSONFeature[] | undefined;
            } & mapboxgl.EventData
        ) => {
            const feature = event.features?.[0];
            const isFinishMarker = feature?.properties?.position === 'finish';
            if (event.originalEvent.defaultPrevented || this._readOnly || isFinishMarker) {
                return;
            }

            if (event.originalEvent.cancelable) {
                event.preventDefault();
            }

            this._draggingFeature = feature;
        };

        this._mapBox?.on('mousedown', 'route-marker-layer', event => {
            _onDown(event);

            this._mapBox?.on('mousemove', _onMove);
            this._mapBox?.once('mouseup', _onUp);
        });

        this._mapBox?.on('touchstart', 'route-marker-layer', event => {
            if (event.points.length !== 1) {
                return;
            }

            _onDown(event);

            this._mapBox?.on('touchmove', _onMove);
            this._mapBox?.on('touchend', _onUp);
        });

        this._mapBox?.on('mouseenter', 'route-marker-layer', e => {
            const feature = e?.features?.[0];
            if (!feature || this._readOnly) {
                return;
            }

            this._wayPointEnter = true;

            if (feature.properties?.position === 'viapoint') {
                this._resetViaPointState(true);

                this._mapBox!.getCanvas().style.cursor = 'default';
                const coordinates = (feature.geometry as Point).coordinates;

                this._viapointPopup?.remove();
                this._viapointPopup = new Popup({
                    closeButton: false,
                    offset: 35,
                    anchor: 'bottom'
                })
                    .setLngLat([coordinates[0], coordinates[1]])
                    .setHTML('<p style="color: #000000">Click to remove</p>')
                    .addTo(this._mapBox!);
            } else {
                this._resetViaPointState(true);
            }
        });

        this._mapBox?.on('click', 'route-marker-layer', e => {
            if (e.originalEvent.defaultPrevented) {
                return;
            }

            if (e.originalEvent.cancelable) {
                e.preventDefault();
            }

            const feature = e?.features?.[0];
            if (!feature || this._readOnly) {
                return;
            }

            if (feature.properties?.position === 'viapoint') {
                this._onViaPointRemove?.(feature.properties?.placeId);
            }
        });

        this._mapBox?.on('mouseleave', 'route-marker-layer', () => {
            this._mapBox!.getCanvas().style.cursor = 'default';
            this._wayPointEnter = false;
            if (this._viapointPopup?.isOpen()) {
                this._viapointPopup?.remove();
            }
        });

        this._viapointSource = this._mapBox?.getSource('route-viapoint-source') as GeoJSONSource;

        this._mapBox?.on('mousemove', e => {
            if (
                !this._readOnly &&
                !this._viapointDrag &&
                this._viapointCoordinates &&
                this._viapointProperties &&
                this._viapointLeavePosition &&
                !this._wayPointEnter &&
                !this._poiLayerEnter
            ) {
                const evtPoint = this._mapBox!.project(e.lngLat);
                const viaPoint = this._mapBox!.project(this._viapointLeavePosition);
                const distance = Math.sqrt(
                    Math.pow(Math.abs(evtPoint.y - viaPoint.y), 2) + Math.pow(Math.abs(evtPoint.x - viaPoint.x), 2)
                );

                if (distance < VIAPOINT_VISIBLE_MAX_DISTANCE_PX) {
                    const along = turf.nearestPointOnLine(this._viapointCoordinates, [e.lngLat.lng, e.lngLat.lat]);

                    if (along?.geometry) {
                        this._viapointSource?.setData({
                            type: 'FeatureCollection',
                            features: [
                                {
                                    type: 'Feature',
                                    geometry: {
                                        type: 'Point',
                                        coordinates: [along.geometry.coordinates[0]!, along.geometry.coordinates[1]!]
                                    },
                                    properties: this._viapointProperties
                                }
                            ]
                        });

                        this._viapointLeavePosition = new LngLat(
                            along.geometry.coordinates[0]!,
                            along.geometry.coordinates[1]!
                        );
                    }
                } else {
                    this._resetViaPointState(true);
                }
            }
        });

        this._mapBox?.on('mousemove', 'route-layer', e => {
            const feature = e?.features?.[0];
            if (!feature || this._viapointDrag || this._readOnly || this._wayPointEnter || this._poiLayerEnter) {
                return;
            }

            this._mapBox!.getCanvas().style.cursor = 'pointer';

            this._viapointProperties = feature?.properties;
            this._viapointCoordinates = feature.geometry as LineString;
            this._viapointLeavePosition = undefined;

            this._viapointSource?.setData({
                type: 'FeatureCollection',
                features: [
                    {
                        type: 'Feature',
                        geometry: {
                            type: 'Point',
                            coordinates: [e.lngLat.lng, e.lngLat.lat]
                        },
                        properties: this._viapointProperties
                    }
                ]
            });
        });

        this._mapBox?.on('mouseleave', 'route-layer', e => {
            this._viapointLeavePosition = e.lngLat;
        });

        const onMove = (e: MapMouseEvent & { features?: MapboxGeoJSONFeature[] | undefined } & EventData) => {
            if (e.originalEvent.cancelable) {
                e.preventDefault();
            }

            this._mapBox!.getCanvas().style.cursor = 'grabbing';

            this._viapointSource?.setData({
                type: 'FeatureCollection',
                features: [
                    {
                        type: 'Feature',
                        geometry: {
                            type: 'Point',
                            coordinates: [e.lngLat.lng, e.lngLat.lat]
                        },
                        properties: this._viapointProperties || null
                    }
                ]
            });
        };

        this._mapBox?.on('mousedown', 'route-viapoint-layer', e => {
            if (e.originalEvent.cancelable) {
                e.preventDefault();
            }

            const feature = e?.features?.[0];
            if (!feature || this._readOnly) {
                return;
            }

            this._viapointDrag = true;

            this._mapBox!.getCanvas().style.cursor = 'grab';
            this._mapBox?.on('mousemove', onMove);
            this._mapBox?.once('mouseup', async e => {
                this._mapBox?.off('mousemove', onMove);
                this._mapBox?.off('touchmove', onMove);
                this._mapBox!.getCanvas().style.cursor = 'default';

                this._onViaPointDragEnd?.(feature.properties?.routeId - 1, {
                    lat: e.lngLat.lat,
                    lng: e.lngLat.lng
                });

                this._resetViaPointState();
                this._viapointDrag = false;
            });
        });

        poisLayers.forEach(layer => {
            this._mapBox?.on('mouseenter', layer, () => {
                this._poiLayerEnter = true;
                this._resetViaPointState(true);
            });

            this._mapBox?.on('mouseleave', layer, () => {
                this._poiLayerEnter = false;
            });
        });
    }

    private _mapRoutePlaces(places: MapPlace[]): GeoJSON.Feature<GeoJSON.Point>[] {
        let pointIndex = 0;

        return places.map((place, index) => {
            let position = 'middle';

            if (index === 0) {
                position = 'start';
            } else if (index === places.length - 1) {
                position = 'end';
            }

            if (place.isVia) {
                position = 'viapoint';
            } else {
                pointIndex++;
            }

            return {
                type: 'Feature',
                properties: {
                    placeId: place.id,
                    position: position,
                    positionIndex: place.isVia ? undefined : pointIndex
                },
                geometry: {
                    type: 'Point',
                    coordinates: [place.lng, place.lat]
                }
            };
        });
    }

    private _mapTrackingFinishPlaces(trackingPlaces: number[][]): GeoJSON.Feature<GeoJSON.Point>[] {
        return trackingPlaces.map(place => {
            return {
                type: 'Feature',
                properties: {
                    position: 'finish'
                },
                geometry: {
                    type: 'Point',
                    coordinates: [place[0], place[1]]
                }
            };
        });
    }
}
