/* tslint:disable */
/* eslint-disable */
/**
 * Telematics API
 * Telematics services API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum CostUnit {
    PerMonth = 'perMonth',
    PerQuartal = 'perQuartal',
    PerHalfYear = 'perHalfYear',
    PerYear = 'perYear',
    PerKilometer = 'perKilometer'
}

export function CostUnitFromJSON(json: any): CostUnit {
    return CostUnitFromJSONTyped(json, false);
}

export function CostUnitFromJSONTyped(json: any, ignoreDiscriminator: boolean): CostUnit {
    return json as CostUnit;
}

export function CostUnitToJSON(value?: CostUnit | null): any {
    return value as any;
}

