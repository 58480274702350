export class PostalCodesLayer {
    public static init(map: mapboxgl.Map, apiKey: string) {
        if (!map.getSource('sygic-postal-areas-vectors')) {
            map.addSource('sygic-postal-areas-vectors', {
                type: 'vector',
                tiles: [`https://maps.api.sygic.com/vtile/pca/${apiKey}/{z}/{x}/{y}`],
                minzoom: 4,
                maxzoom: 16
            });
        }
    }

    private static enablePostalArea(map: mapboxgl.Map) {
        map.addLayer(
            {
                id: 'postal_area_district_outline',
                type: 'line',
                source: 'sygic-postal-areas-vectors',
                'source-layer': 'postal_area',
                filter: ['==', 'level', 5],
                paint: {
                    'line-color': '#f66',
                    'line-width': {
                        base: 1,
                        stops: [
                            [11, 1],
                            [12, 2]
                        ]
                    }
                }
            },
            'landuse_label_tiny'
        );

        map.addLayer(
            {
                id: 'postal_area_high_outline',
                type: 'line',
                source: 'sygic-postal-areas-vectors',
                'source-layer': 'postal_area',
                filter: ['==', 'level', 2],
                paint: {
                    'line-color': '#f66',
                    'line-width': {
                        base: 1,
                        stops: [
                            [7, 0.5],
                            [11, 1]
                        ]
                    }
                }
            },
            'landuse_label_tiny'
        );

        map.addLayer(
            {
                id: 'postal_area_low_outline',
                type: 'line',
                source: 'sygic-postal-areas-vectors',
                'source-layer': 'postal_area',
                filter: ['==', 'level', 1],
                paint: {
                    'line-color': '#f66',
                    'line-width': {
                        base: 1,
                        stops: [
                            [5, 0.5],
                            [7, 2]
                        ]
                    }
                }
            },
            'postal_area_district_outline'
        );

        map.addLayer({
            id: 'postal_area_label_district',
            minzoom: 6,
            type: 'symbol',
            source: 'sygic-postal-areas-vectors',
            'source-layer': 'postal_area_label',
            filter: ['==', 'level', 5],
            layout: {
                'text-font': ['Open Sans Bold'],
                'text-field': '{code}',
                'text-size': {
                    base: 1.2,
                    stops: [
                        [11, 12],
                        [19, 18]
                    ]
                }
            },
            paint: {
                'text-color': '#000',
                'text-halo-color': 'rgba(255,255,255,0.8)',
                'text-halo-width': 1.2
            }
        });

        map.addLayer({
            id: 'postal_area_label_high',
            minzoom: 6,
            type: 'symbol',
            source: 'sygic-postal-areas-vectors',
            'source-layer': 'postal_area_label',
            filter: ['==', 'level', 2],
            layout: {
                'text-font': ['Open Sans Bold'],
                'text-field': '{code}',
                'text-size': {
                    base: 1.2,
                    stops: [
                        [7, 12],
                        [12, 24]
                    ]
                }
            },
            paint: {
                'text-color': '#000',
                'text-halo-color': 'rgba(255,255,255,0.8)',
                'text-halo-width': 1.2
            }
        });

        map.addLayer({
            id: 'postal_area_label_low',
            minzoom: 5,
            type: 'symbol',
            source: 'sygic-postal-areas-vectors',
            'source-layer': 'postal_area_label',
            filter: ['==', 'level', 1],
            layout: {
                'text-font': ['Open Sans Bold'],
                'text-field': '{code}',
                'text-size': {
                    base: 1.2,
                    stops: [
                        [6, 12],
                        [12, 45]
                    ]
                }
            },
            paint: {
                'text-color': '#f66',
                'text-halo-color': 'rgba(255,255,255,0.8)',
                'text-halo-width': 1.2
            }
        });
    }

    private static disablePostalArea(map: mapboxgl.Map) {
        map.removeLayer('postal_area_district_outline');
        map.removeLayer('postal_area_high_outline');
        map.removeLayer('postal_area_low_outline');
        map.removeLayer('postal_area_label_district');
        map.removeLayer('postal_area_label_high');
        map.removeLayer('postal_area_label_low');
    }

    public static togglePostalArea(map: mapboxgl.Map, visibile: boolean) {
        if (visibile) {
            this.enablePostalArea(map);
        } else {
            this.disablePostalArea(map);
        }
    }
}
