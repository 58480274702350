/* tslint:disable */
/* eslint-disable */
/**
 * Telematics API
 * Telematics services API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserLoginCredentials
 */
export interface UserLoginCredentials {
    /**
     * 
     * @type {string}
     * @memberof UserLoginCredentials
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof UserLoginCredentials
     */
    accountNumber: string;
    /**
     * 
     * @type {string}
     * @memberof UserLoginCredentials
     */
    contactNumber: string;
}

export function UserLoginCredentialsFromJSON(json: any): UserLoginCredentials {
    return UserLoginCredentialsFromJSONTyped(json, false);
}

export function UserLoginCredentialsFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserLoginCredentials {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'username': json['username'],
        'accountNumber': json['accountNumber'],
        'contactNumber': json['contactNumber'],
    };
}

export function UserLoginCredentialsToJSON(value?: UserLoginCredentials | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'username': value.username,
        'accountNumber': value.accountNumber,
        'contactNumber': value.contactNumber,
    };
}


