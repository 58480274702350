/* tslint:disable */
/* eslint-disable */
/**
 * Telematics API
 * Telematics services API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ClientApplication
 */
export interface ClientApplication {
    /**
     * 
     * @type {number}
     * @memberof ClientApplication
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ClientApplication
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ClientApplication
     */
    apiKey: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ClientApplication
     */
    filter: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ClientApplication
     */
    clientId: string;
    /**
     * 
     * @type {number}
     * @memberof ClientApplication
     */
    rateLimit?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ClientApplication
     */
    rateLimitPeriod?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof ClientApplication
     */
    freeVersion?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof ClientApplication
     */
    technicalAccountClientID?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ClientApplication
     */
    technicalAccountNumber?: number | null;
}

export function ClientApplicationFromJSON(json: any): ClientApplication {
    return ClientApplicationFromJSONTyped(json, false);
}

export function ClientApplicationFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClientApplication {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'apiKey': json['apiKey'],
        'filter': json['filter'],
        'clientId': json['clientId'],
        'rateLimit': !exists(json, 'rateLimit') ? undefined : json['rateLimit'],
        'rateLimitPeriod': !exists(json, 'rateLimitPeriod') ? undefined : json['rateLimitPeriod'],
        'freeVersion': !exists(json, 'freeVersion') ? undefined : json['freeVersion'],
        'technicalAccountClientID': !exists(json, 'technicalAccountClientID') ? undefined : json['technicalAccountClientID'],
        'technicalAccountNumber': !exists(json, 'technicalAccountNumber') ? undefined : json['technicalAccountNumber'],
    };
}

export function ClientApplicationToJSON(value?: ClientApplication | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'apiKey': value.apiKey,
        'filter': value.filter,
        'clientId': value.clientId,
        'rateLimit': value.rateLimit,
        'rateLimitPeriod': value.rateLimitPeriod,
        'freeVersion': value.freeVersion,
        'technicalAccountClientID': value.technicalAccountClientID,
        'technicalAccountNumber': value.technicalAccountNumber,
    };
}


