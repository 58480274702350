/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BodyEditTripsV1TripsEditPost,
    BodyEditTripsV1TripsEditPostFromJSON,
    BodyEditTripsV1TripsEditPostToJSON,
    HTTPValidationError,
    HTTPValidationErrorFromJSON,
    HTTPValidationErrorToJSON,
    TripInDatabase,
    TripInDatabaseFromJSON,
    TripInDatabaseToJSON,
} from '../models';

export interface EditTripsV1TripsEditPostRequest {
    bodyEditTripsV1TripsEditPost: BodyEditTripsV1TripsEditPost;
}

export interface GetTripsV1TripsGetRequest {
    monitoredObjectId?: string;
    driverId?: number;
    dateFrom?: Date;
    dateTo?: Date;
    limit?: number;
    offset?: number;
    minDistance?: number;
    stripMetadata?: boolean;
    wrapArray?: boolean;
    sort?: number;
}

/**
 * no description
 */
export class TripApi extends runtime.BaseAPI {

    /**
     * Edit Trips
     */
    async editTripsV1TripsEditPostRaw(requestParameters: EditTripsV1TripsEditPostRequest): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.bodyEditTripsV1TripsEditPost === null || requestParameters.bodyEditTripsV1TripsEditPost === undefined) {
            throw new runtime.RequiredError('bodyEditTripsV1TripsEditPost','Required parameter requestParameters.bodyEditTripsV1TripsEditPost was null or undefined when calling editTripsV1TripsEditPost.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/trips/edit`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BodyEditTripsV1TripsEditPostToJSON(requestParameters.bodyEditTripsV1TripsEditPost),
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Edit Trips
     */
    async editTripsV1TripsEditPost(requestParameters: EditTripsV1TripsEditPostRequest): Promise<object> {
        const response = await this.editTripsV1TripsEditPostRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get Trips
     */
    async getTripsV1TripsGetRaw(requestParameters: GetTripsV1TripsGetRequest): Promise<runtime.ApiResponse<Array<TripInDatabase>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.monitoredObjectId !== undefined) {
            queryParameters['monitored_object_id'] = requestParameters.monitoredObjectId;
        }

        if (requestParameters.driverId !== undefined) {
            queryParameters['driver_id'] = requestParameters.driverId;
        }

        if (requestParameters.dateFrom !== undefined) {
            queryParameters['date_from'] = (requestParameters.dateFrom as any).toISOString();
        }

        if (requestParameters.dateTo !== undefined) {
            queryParameters['date_to'] = (requestParameters.dateTo as any).toISOString();
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.minDistance !== undefined) {
            queryParameters['min_distance'] = requestParameters.minDistance;
        }

        if (requestParameters.stripMetadata !== undefined) {
            queryParameters['strip_metadata'] = requestParameters.stripMetadata;
        }

        if (requestParameters.wrapArray !== undefined) {
            queryParameters['wrap_array'] = requestParameters.wrapArray;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/trips/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TripInDatabaseFromJSON));
    }

    /**
     * Get Trips
     */
    async getTripsV1TripsGet(requestParameters: GetTripsV1TripsGetRequest): Promise<Array<TripInDatabase>> {
        const response = await this.getTripsV1TripsGetRaw(requestParameters);
        return await response.value();
    }

}
