import { Modal } from 'components/base/layout';
import { Col, Form, Row } from 'antd';
import { Button, Checkbox, Input } from 'components/base/controls';
import { Trans, useTranslation } from 'react-i18next';
import selectors from 'qa-selectors';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import { Link } from 'react-router-dom';

export interface AboutCompanyFormModel {
    interest?: string;
    role?: string;
    companyName: string;
    companySize?: number;
    fleetSize: number;
    newslettersAgreement?: boolean;
}

interface Props {
    visible?: boolean;
    loading?: boolean;
    onSubmit: (values: AboutCompanyFormModel) => void;
}

export function AboutCompanyModal(props: Props) {
    const { t } = useTranslation();
    const { isMobileWidth, windowDimensions } = useWindowDimensions();
    const [form] = Form.useForm<AboutCompanyFormModel>();

    function _onFinish(formValues: AboutCompanyFormModel) {
        props.onSubmit(formValues);
    }

    return (
        <Modal
            className="rl-about-company-modal"
            visible={props.visible}
            closable={false}
            mask={!isMobileWidth}
            width={isMobileWidth ? windowDimensions.width : 472}
            destroyOnClose={true}
        >
            <div className="rl-about-company-modal-header">
                <h3>{t('AboutCompanyFormModal.title')}</h3>
            </div>

            <div className="rl-about-company-form" data-qa={selectors.aboutCompanyModal.aboutCompanyModal}>
                <Form<AboutCompanyFormModel>
                    form={form}
                    id="AboutCompanyForm"
                    layout="vertical"
                    name="AboutCompanyForm"
                    onFinish={_onFinish}
                >
                    <Row>
                        <Col span="24">
                            <Form.Item
                                label={t('AboutCompanyFormModal.companyName')}
                                name="companyName"
                                rules={[{ required: true, min: 2, type: 'string' }]}
                            >
                                <Input
                                    placeholder={t('AboutCompanyFormModal.companyNamePlaceholder')}
                                    data-qa={selectors.aboutCompanyModal.aboutCompanyForm.companyNameInput}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    {/* //TODO: RWP-1565 Extend Dispatcher and Company entity
                        <Row>
                        <Col span="24">
                            <Form.Item
                                label={t('AboutCompanyFormModal.companySize')}
                                name="companySize"
                                rules={[{ required: true, min: 1, type: 'number' }]}
                            >
                                <Input.Number
                                    placeholder={t('AboutCompanyFormModal.companySizePlaceholder')}
                                    data-qa={selectors.aboutCompanyModal.aboutCompanyForm.companySizeInput}
                                    min={1}
                                />
                            </Form.Item>
                        </Col>
                    </Row> */}
                    <Row>
                        <Col span="24">
                            <Form.Item
                                label={t('AboutCompanyFormModal.numOfTrucks')}
                                name="fleetSize"
                                rules={[{ required: true, min: 1, type: 'number' }]}
                            >
                                <Input.Number
                                    placeholder={t('AboutCompanyFormModal.numOfTrucksPlaceholder')}
                                    data-qa={selectors.aboutCompanyModal.aboutCompanyForm.numberOfTrucksInput}
                                    min={1}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span="24">
                            <Form.Item name="newslettersAgreement" valuePropName="checked" initialValue={false}>
                                <Checkbox>
                                    <Trans i18nKey="AboutCompanyFormModal.newslettersAgreement">
                                        I would like to be contacted for marketing purposes by any member of the
                                        <Link
                                            to={{ pathname: 'https://www.eurowag.com/en/legal-documents' }}
                                            target="_blank"
                                        >
                                            EUROWAG Group
                                        </Link>
                                        , in line with the
                                        <Link
                                            to={{ pathname: 'https://www.eurowag.com/en/marketing-consent' }}
                                            target="_blank"
                                        >
                                            Marketing consent
                                        </Link>
                                        that I have read and understand.
                                    </Trans>
                                </Checkbox>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span="24">
                            <Button
                                type="primary"
                                size={isMobileWidth ? 'middle' : 'large'}
                                htmlType="submit"
                                loading={props.loading ? { delay: 500 } : false}
                                style={{ width: '100%' }}
                                data-qa={selectors.aboutCompanyModal.aboutCompanyForm.submitButton}
                            >
                                {t('AboutCompanyFormModal.startUsingDispatcher')}
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </div>
        </Modal>
    );
}
