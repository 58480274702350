import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { RouteNames } from 'router/routes';

export const TrackingNavbar = () => {
    const { t } = useTranslation();

    return (
        <div className="rl-tracking-navbar">
            <div className="rl-tracking-navbar-title">
                <h3>{t('TrackingModule.title')}</h3>
            </div>
            <NavLink className="rl-tracking-navbar-item" to={RouteNames.TRACKING_MAP} title={t('common.mapView')}>
                {t('common.mapView')}
            </NavLink>
            <NavLink
                className="rl-tracking-navbar-item"
                to={RouteNames.TRACKING_TABLE}
                title={t('TrackingModule.navbar.tableView')}
            >
                {t('TrackingModule.navbar.tableView')}
            </NavLink>
        </div>
    );
};
