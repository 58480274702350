import { createRef } from 'react';
import { Col, Form, Row } from 'antd';
import { Button, Checkbox, Input } from 'components/base/controls';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import selectors from 'qa-selectors';
import ReCAPTCHA from 'react-google-recaptcha';

interface Props {
    loading?: boolean;
    isMobileWidth: boolean;
    recaptchaSiteKey: string;
    onSubmit: (values: RegisterFormModel) => void;
    onSignInClick: () => void;
    onRedirectRegister: (provider: 'google' | 'facebook') => void;
}

export interface RegisterFormModel {
    email: string;
    password: string;
    recaptchaToken: string;
}

export function RegisterForm(props: Props) {
    const { t, i18n } = useTranslation();
    const [form] = Form.useForm<RegisterFormModel>();
    const recaptchaRef = createRef<ReCAPTCHA>();

    function _onFinish(formValues: RegisterFormModel) {
        props.onSubmit(formValues);
    }

    const recaptchaValidator = async () => {
        const token = recaptchaRef.current?.getValue();
        if (!token) {
            throw new Error(t('RegisterFormModal.recaptchaError'));
        }
    };

    const onCaptchaChange = (token: string | null) => {
        form.setFieldValue('recaptchaToken', token);

        if (token) {
            form.validateFields(['recaptchaToken']);
        }
    };

    return (
        <div className="rl-register-form rl-modal-with-carousel-form">
            <Form<RegisterFormModel>
                form={form}
                id="RegisterForm"
                layout="vertical"
                name="RegisterForm"
                requiredMark={false}
                className="rl-register-form-content"
                onFinish={_onFinish}
            >
                <p>{t('RegisterFormModal.infoText')}</p>

                <Row>
                    <Col span="24">
                        <Form.Item
                            label={t('common.email')}
                            name="email"
                            rules={[{ required: true, type: 'email' }]}
                            validateTrigger="onBlur"
                        >
                            <Input
                                autoComplete={'username'}
                                placeholder={t('RegisterFormModal.emailPlaceholder')}
                                data-qa={selectors.registerModal.registerForm.emailInput}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col span="24">
                        <Form.Item label={t('common.password')} name="password" rules={[{ required: true, min: 8 }]}>
                            <Input
                                type="password"
                                placeholder={t('common.password')}
                                autoComplete={'new-password'}
                                data-qa={selectors.registerModal.registerForm.passwordInput}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row>
                    <Form.Item
                        name="agreement"
                        valuePropName="checked"
                        rules={[
                            {
                                validator: (_, value) =>
                                    value
                                        ? Promise.resolve()
                                        : Promise.reject(new Error(t('RegisterFormModal.termsError')))
                            }
                        ]}
                    >
                        <Checkbox>
                            <Trans i18nKey="RegisterFormModal.terms">
                                I agree with
                                <Link to={{ pathname: 'https://roadlords.com/terms-conditions' }} target="_blank">
                                    Terms and Conditions
                                </Link>
                                and certify that I have read
                                <Link to={{ pathname: 'https://www.eurowag.com/privacy-policy' }} target="_blank">
                                    Privacy and personal data protection principles
                                </Link>
                            </Trans>
                        </Checkbox>
                    </Form.Item>
                </Row>

                <Row>
                    <Form.Item name="recaptchaToken" rules={[{ validator: recaptchaValidator }]}>
                        <Col>
                            <ReCAPTCHA
                                ref={recaptchaRef}
                                sitekey={props.recaptchaSiteKey}
                                hl={i18n.language}
                                onChange={onCaptchaChange}
                            />
                        </Col>
                    </Form.Item>
                </Row>

                <Row>
                    <Col span="24">
                        <Button
                            type="primary"
                            size={props.isMobileWidth ? 'middle' : 'large'}
                            htmlType="submit"
                            loading={props.loading ? { delay: 500 } : false}
                            style={{ width: '100%' }}
                            data-qa={selectors.registerModal.registerForm.registerButton}
                        >
                            {t('RegisterFormModal.register')}
                        </Button>
                    </Col>
                </Row>

                <div className="or-separator">{t('common.or')}</div>

                <Row style={{ marginBottom: '16px' }}>
                    <Col span="24">
                        <Button
                            className="google-btn"
                            size={props.isMobileWidth ? 'middle' : 'large'}
                            icon={<img className="rl-button-icon" src="img/login-modal/google.svg" alt="Google icon" />}
                            onClick={() => props.onRedirectRegister('google')}
                        >
                            {t('RegisterFormModal.googleRegister')}
                        </Button>
                    </Col>
                </Row>

                <Row>
                    <Col span="24">
                        <Button
                            className="facebook-btn"
                            type="primary"
                            size={props.isMobileWidth ? 'middle' : 'large'}
                            icon={<i className="rl-button-icon fab fa-facebook-f" />}
                            onClick={() => props.onRedirectRegister('facebook')}
                        >
                            {t('RegisterFormModal.facebookRegister')}
                        </Button>
                    </Col>
                </Row>
            </Form>
            <div className="rl-register-form-footer">
                <Trans i18nKey="RegisterFormModal.account">
                    Already have an account?{' '}
                    <Button type="link" onClick={props.onSignInClick}>
                        Sign in
                    </Button>
                </Trans>
            </div>
        </div>
    );
}
