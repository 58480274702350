import { DndContext, DragEndEvent, DragStartEvent } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { restrictToParentElement, restrictToVerticalAxis } from '@dnd-kit/modifiers';

interface Props {
    id: string;
    items: any[];
    children: React.ReactNode;
    onSortEnd: (newIndex: number, oldIndex: number) => void;
    onSortStart?: () => void;
}

export const SortableContainer = (props: Props) => {
    const handleDragStart: (event: DragStartEvent) => void = () => {
        props.onSortStart?.();
    };

    const handleDragEnd: (event: DragEndEvent) => void = ({ active, over }) => {
        if (!over) {
            return;
        }

        if (active.id !== over.id) {
            const activeIndex = active?.data?.current?.sortable.index;
            const overIndex = over.data.current?.sortable.index || 0;

            props.onSortEnd(overIndex, activeIndex);
        }
    };
    return (
        <DndContext
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            modifiers={[restrictToVerticalAxis, restrictToParentElement]}
        >
            <SortableContext id={props.id} items={props.items} strategy={verticalListSortingStrategy}>
                {props.children}
            </SortableContext>
        </DndContext>
    );
};
