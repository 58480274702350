import { PoiModelMap } from './fuelStations';
import mapboxgl from 'mapbox-gl';
import { Subject } from 'rxjs';
import { PlaceType } from '../../../services/api/domains/PlacesApi';
import { ParkingLayers, PARKING_SOURCE_ID } from '../layers/ParkingsLayer';

export class ParkingsMapController {
    visible = true;
    visibleChanged$ = new Subject<boolean>();

    private _mapBox?: mapboxgl.Map;
    private _markers: GeoJSON.Feature<GeoJSON.Point>[];
    private _markersById: { [id: string]: GeoJSON.Feature<GeoJSON.Point> } = {};
    private _hoveredStateClusterId?: string | number;
    private _hoveredStateId?: string | number;
    private _onParkingClick?: (parking: PoiModelMap) => void;

    constructor(mapBox?: mapboxgl.Map) {
        this._mapBox = mapBox;
        this._markers = [];

        this._addSourceAndLayers();
    }

    show(): void {
        this._renderFeatures();
        this.visible = true;
        this.visibleChanged$.next(this.visible);
    }

    hide(): void {
        const source = this._mapBox?.getSource(PARKING_SOURCE_ID) as mapboxgl.GeoJSONSource;
        if (source) {
            source.setData({
                type: 'FeatureCollection',
                features: []
            });
        }

        this.visible = false;
        this.visibleChanged$.next(this.visible);
    }

    onClick(cb?: (parking: PoiModelMap) => void): void {
        this._onParkingClick = cb;
    }

    setData(data: PoiModelMap[]): void {
        this._markers = data.map((parking, index) => {
            const marker = this._createParkingMarker(parking, index);
            this._markersById[marker.properties?.id] = marker;
            return marker;
        });

        this._renderFeatures();
    }

    deleteMarker(parkingId: string): void {
        if (this._markersById[parkingId]) {
            delete this._markersById[parkingId];
            this._markers = Object.values(this._markersById);
            this._renderFeatures();
        }
    }

    showMarker(parking: PoiModelMap): void {
        this._markersById[parking.id] = this._createParkingMarker(parking);
        this._markers = Object.values(this._markersById);
        this._renderFeatures();
    }

    private _renderFeatures(): void {
        const source = this._mapBox?.getSource(PARKING_SOURCE_ID) as mapboxgl.GeoJSONSource;
        if (source) {
            source.setData({
                type: 'FeatureCollection',
                features: this._markers
            });
        }
    }

    private _createParkingMarker(parking: PoiModelMap, index?: number): GeoJSON.Feature<GeoJSON.Point> {
        return {
            type: 'Feature',
            id: index,
            properties: {
                id: parking.id,
                name: parking.name,
                services: parking.services,
                isFavorite: parking.isFavorite,
                isSelected: parking.selected,
                routeIndex: parking.routeIndex,
                category: PlaceType.PARKING_LOT,
                isAlongRoute: parking.isAlongRoute
            },
            geometry: {
                type: 'Point',
                coordinates: [parking.position.lng, parking.position.lat]
            }
        };
    }

    private _addSourceAndLayers = () => {
        this._mapBox?.on('click', ParkingLayers.PARKING_CLUSTER, e => {
            const features = this._mapBox?.queryRenderedFeatures(e.point, {
                layers: [ParkingLayers.PARKING_CLUSTER]
            }) as GeoJSON.Feature<GeoJSON.Point>[];

            const clusterId = features?.[0].properties?.cluster_id;
            const source = this._mapBox?.getSource(PARKING_SOURCE_ID) as mapboxgl.GeoJSONSource;

            source.getClusterExpansionZoom(clusterId, (err, zoom) => {
                if (err) return;

                this._mapBox?.easeTo({
                    center: features?.[0].geometry.coordinates as [number, number],
                    zoom: zoom
                });
            });
        });

        this._mapBox?.on('click', ParkingLayers.PARKING_UNCLUSTERED, e => {
            const parking = e.features?.[0].properties;
            const [lng, lat] = (e.features?.[0].geometry as any).coordinates;
            if (parking) {
                parking.position = { lng, lat };
                this._onParkingClick?.(parking as PoiModelMap);
            }
        });

        this._mapBox?.on('mouseenter', ParkingLayers.PARKING_CLUSTER, e => {
            const features = this._mapBox?.queryRenderedFeatures(e.point, {
                layers: [ParkingLayers.PARKING_CLUSTER]
            }) as GeoJSON.Feature<GeoJSON.Point>[];

            const clusterId = features?.[0].properties?.cluster_id;

            if (this._hoveredStateClusterId) {
                this._mapBox?.setFeatureState(
                    { source: PARKING_SOURCE_ID, id: this._hoveredStateClusterId },
                    { hover: false }
                );
            }

            this._hoveredStateClusterId = clusterId;
            this._mapBox?.setFeatureState(
                { source: PARKING_SOURCE_ID, id: this._hoveredStateClusterId },
                { hover: true }
            );
        });

        this._mapBox?.on('mouseleave', ParkingLayers.PARKING_CLUSTER, () => {
            if (this._hoveredStateClusterId) {
                this._mapBox?.setFeatureState(
                    { source: PARKING_SOURCE_ID, id: this._hoveredStateClusterId },
                    { hover: false }
                );
            }

            this._hoveredStateClusterId = undefined;
        });

        this._mapBox?.on('mouseenter', ParkingLayers.PARKING_UNCLUSTERED, e => {
            const feature = e.features?.[0] as GeoJSON.Feature<GeoJSON.Point>;
            if (!feature) {
                return;
            }

            if (feature.properties?.isAlongRoute === false) {
                if (this._hoveredStateId) {
                    this._mapBox?.removeFeatureState({ source: PARKING_SOURCE_ID, id: this._hoveredStateId }, 'hover');
                    this._mapBox?.setFeatureState(
                        { source: PARKING_SOURCE_ID, id: this._hoveredStateId },
                        { hover: false }
                    );
                }

                this._hoveredStateId = feature.id;
                this._mapBox?.setFeatureState({ source: PARKING_SOURCE_ID, id: this._hoveredStateId }, { hover: true });
            }
        });

        this._mapBox?.on('mouseleave', ParkingLayers.PARKING_UNCLUSTERED, () => {
            if (this._hoveredStateId) {
                this._mapBox?.removeFeatureState({ source: PARKING_SOURCE_ID, id: this._hoveredStateId }, 'hover');
            }

            this._hoveredStateId = undefined;
        });
    };
}
