import cn from 'classnames';
import { Spin } from 'components/base/controls/Spin';
import { PoiModel } from 'logic/poi';
import qa from 'qa-selectors';
import React, { FunctionComponent, KeyboardEvent, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select, {
    components,
    FormatOptionLabelMeta,
    InputActionMeta,
    MenuListProps,
    NoticeProps,
    OnChangeValue,
    SelectInstance,
    ValueContainerProps
} from 'react-select';
import { Badge } from './ui/Badge/Badge';
import { isMobileDevice } from 'utils/helpers/navigator';
import { PlaceSuggestionSource, SearchFilter } from 'modules/PlannerModule/PlannerModule';
import PlannerAddMissingPlace from '../PlannerAddMissingPlace';
import { Row, Col } from 'antd';

type AutoCompleteSuggestion = {
    label: string;
    value: string;
    source?: PlaceSuggestionSource;
};

interface Props {
    text: string;
    isTyping?: boolean;
    displayedSuggestions: AutoCompleteSuggestion[];
    allSuggestions: AutoCompleteSuggestion[];
    filter?: SearchFilter;
    searchInProgress?: boolean;
    isAutocompleteMobileOpen?: boolean;
    onChange?: (text: string, focus?: boolean, fetchNewSuggestions?: boolean) => void;
    onSubmit?: (value: string) => void;
    onFilterChange?: (type: SearchFilter, text: string) => void;
    onShowPoiBarVisible?: (model: PoiModel) => void;
    toggleAutocompleteMobileMenu?: (isMenuOpen: boolean) => void;
}

interface Option {
    label: string;
    value: string;
}
const NoOptionsMessage = (props: NoticeProps<any, boolean>) => {
    const { filter, searchInProgress, isTyping, translations, existsSuggestions, onShowPoiBarVisible, lastSearchText } =
        props.selectProps as any;

    if (searchInProgress) {
        return null;
    }

    return (
        <components.NoOptionsMessage {...props}>
            {filter === SearchFilter.All && !existsSuggestions && props.selectProps.inputValue && !isTyping && (
                <div className="rl-planner-autocomplete-blank-all">
                    <p>
                        {translations.blankState.all.text1} “{props.selectProps.inputValue}”.
                    </p>
                    <p>{translations.blankState.all.text2}</p>

                    <Row gutter={[6, 0]}>
                        <Col>{translations.blankState.all.text3}</Col>
                        <Col>
                            <PlannerAddMissingPlace
                                iconVisible={false}
                                onShowPoiBarVisible={onShowPoiBarVisible}
                                name={lastSearchText}
                            />
                        </Col>
                    </Row>
                </div>
            )}

            {filter === SearchFilter.All && !existsSuggestions && props.selectProps.inputValue && isTyping && (
                <div className="rl-planner-autocomplete-nodata">{translations.noData.willAppear}</div>
            )}

            {filter === SearchFilter.All && !existsSuggestions && !props.selectProps.inputValue && (
                <div className="rl-planner-autocomplete-nodata">{translations.noData.all}</div>
            )}

            {filter === SearchFilter.History && !existsSuggestions && props.selectProps.inputValue && (
                <div className="rl-planner-autocomplete-blank-history">
                    <i className="far fa-clock" aria-hidden="true" />
                    <p>{translations.noData.history}</p>
                </div>
            )}

            {filter === SearchFilter.History && !existsSuggestions && !props.selectProps.inputValue && (
                <div className="rl-planner-autocomplete-blank-history">
                    <i className="far fa-clock" aria-hidden="true" />
                    <p>{translations.blankState.history}</p>
                </div>
            )}

            {filter === SearchFilter.Favorites && !existsSuggestions && props.selectProps.inputValue && (
                <div className="rl-planner-autocomplete-blank-favorite">
                    <i className="fas fa-heart" aria-hidden="true" />
                    <p>{translations.noData.favorites}</p>
                </div>
            )}

            {filter === SearchFilter.Favorites && !existsSuggestions && !props.selectProps.inputValue && (
                <div className="rl-planner-autocomplete-blank-favorite">
                    <img src="/img/planner/icn-rl-autocomplete-favorite.svg" alt="favorite" />
                    <div className="rl-planner-autocomplete-blank-favorite-title">
                        {translations.blankState.favorite.title}
                    </div>
                    <div className="rl-planner-autocomplete-blank-favorite-description">
                        {translations.blankState.favorite.description}
                    </div>
                </div>
            )}
        </components.NoOptionsMessage>
    );
};

const ValueContainer: FunctionComponent<ValueContainerProps<any, boolean>> = props => {
    return (
        components.ValueContainer && (
            <components.ValueContainer {...props}>
                {!!props.children && (
                    <i className="fa fa-search" aria-hidden="true" style={{ position: 'absolute', left: 16 }} />
                )}
                {props.children}
            </components.ValueContainer>
        )
    );
};

const MenuList: FunctionComponent<MenuListProps<any, boolean>> = props => {
    const {
        filter,
        allSuggestionsCount,
        translations,
        searchInProgress,
        onFilterAll,
        onFilterHistory,
        onFilterOwnPlaces
    } = props.selectProps as any;

    return (
        <components.MenuList {...props}>
            <div className="rl-planner-autocomplete-overlay">
                <div
                    data-qa={qa.schedulingPlanning.routePointMenu.all}
                    data-text={translations.filter.all}
                    className={cn('rl-planner-autocomplete-overlay-tab', {
                        active: filter === SearchFilter.All,
                        disabled: searchInProgress
                    })}
                    onClick={!searchInProgress ? onFilterAll : undefined}
                >
                    <div>
                        {translations.filter.all}{' '}
                        {filter !== SearchFilter.All && allSuggestionsCount ? (
                            <Badge>{allSuggestionsCount}</Badge>
                        ) : null}
                    </div>
                </div>
                <div
                    data-qa={qa.schedulingPlanning.routePointMenu.history}
                    data-text={translations.filter.history}
                    className={cn('rl-planner-autocomplete-overlay-tab', {
                        active: filter === SearchFilter.History,
                        disabled: searchInProgress
                    })}
                    onClick={!searchInProgress ? onFilterHistory : undefined}
                >
                    {translations.filter.history}
                </div>
                <div
                    data-qa={qa.schedulingPlanning.routePointMenu.ownPlaces}
                    data-text={translations.filter.own}
                    className={cn('rl-planner-autocomplete-overlay-tab', {
                        active: filter === SearchFilter.Favorites,
                        disabled: searchInProgress
                    })}
                    onClick={!searchInProgress ? onFilterOwnPlaces : undefined}
                >
                    {translations.filter.own}
                </div>
            </div>
            {searchInProgress && (
                <div className="rl-planner-autocomplete-loader">
                    <Spin loading={searchInProgress} tip={translations.search} />
                </div>
            )}
            {props.children}
        </components.MenuList>
    );
};

const DropdownCloseIndicator = (props: any) => {
    return (
        <components.DropdownIndicator {...props} className="rl-planner-autocomplete-select__indicator-separator-close">
            <img src="/img/icn-rl-delete.svg" alt="close" height={12} />
        </components.DropdownIndicator>
    );
};

export default function PlannerAutocomplete(props: Props) {
    const { t } = useTranslation();
    const inputSelectRef = useRef<SelectInstance<any>>(null);
    const [lastSearchText, setLastSearchText] = useState<string | undefined>();
    const { displayedSuggestions, isAutocompleteMobileOpen } = props;
    const existsSuggestions = !!displayedSuggestions?.length;
    const selectComponents: any = { MenuList, ValueContainer, NoOptionsMessage };
    const _isMobileDevice = isMobileDevice();

    if (props.isAutocompleteMobileOpen) {
        selectComponents.DropdownIndicator = DropdownCloseIndicator;
    }

    const _highlightSubstring = (option: Option, labelMeta: FormatOptionLabelMeta<Option>): React.ReactNode => {
        const place = props.displayedSuggestions?.find(place => place.value === option.value);

        const highlighted = option.label.replace(
            new RegExp(labelMeta.inputValue.replace(/[^\w\s]/gi, ''), 'gi'),
            higlighted => `<mark>${higlighted}</mark>`
        );

        const icon = {
            [PlaceSuggestionSource.History]: 'fa-clock',
            [PlaceSuggestionSource.Sygic]: 'fa-map-marker-alt',
            [PlaceSuggestionSource.NoSource]: 'fa-map-marker-alt',
            [PlaceSuggestionSource.CustomPlaces]: 'fa-heart'
        };

        return (
            <>
                <i className={cn('fa fa-fw', icon[place?.source || PlaceSuggestionSource.Sygic])} aria-hidden="true" />
                <span dangerouslySetInnerHTML={{ __html: highlighted }} />
            </>
        );
    };

    const _onFilterAllClick = (): void => {
        props.onFilterChange?.(SearchFilter.All, lastSearchText ?? '');
    };

    const _onFilterOwnPlacesClick = (): void => {
        props.onFilterChange?.(SearchFilter.Favorites, lastSearchText ?? '');
    };

    const _onFilterHistoryClick = (): void => {
        props.onFilterChange?.(SearchFilter.History, lastSearchText ?? '');
    };

    const _onKeyDown = (e: KeyboardEvent<HTMLElement>): void => {
        if (e.key === 'Escape') {
            setLastSearchText(undefined);
            props.onChange?.('');
            return;
        }

        if (['Home', 'End'].includes(e.key)) {
            const target = e.target as any;
            e.preventDefault();
            if (e.key === 'Home') {
                target.selectionStart = 0;
                target.setSelectionRange(0, 0);
            } else {
                const len = target.value.length;
                target.selectionEnd = len;
                target.setSelectionRange(len, len);
            }
        }
    };

    const _onSubmit = (option: Option): void => {
        if (option) {
            props.onSubmit?.(option.value);
            setLastSearchText(undefined);

            _isMobileDevice && inputSelectRef?.current?.focus();
        }
    };

    const _onInputChange = (value: string, actionMeta: InputActionMeta) => {
        if (actionMeta.action === 'input-change') {
            setLastSearchText(value);
            props.onChange?.(value);
        }
    };

    const _onChange = (option: OnChangeValue<Option, boolean>): void => {
        const changeOption = option as Option;
        setLastSearchText(undefined);
        if (changeOption) {
            props.onChange?.('');
            _onSubmit(changeOption);
        }
    };

    return (
        <div
            className={cn('rl-planner-autocomplete', {
                'rl-planner-autocomplete-mobile-menu-is-open': isAutocompleteMobileOpen
            })}
        >
            <Select
                id="onboarding-search-input-field"
                ref={inputSelectRef}
                aria-label={`search-input-${Math.floor(Math.random() * 100) + 1}`}
                isClearable={true}
                autoFocus={!_isMobileDevice}
                name="autocomplete"
                className="rl-planner-autocomplete-select"
                classNamePrefix="rl-planner-autocomplete-select"
                placeholder={t('PlannerAutocomplete.searchInput')}
                options={displayedSuggestions.map(item => ({ label: item.label, value: item.value! }))}
                inputValue={lastSearchText}
                defaultInputValue={props.text}
                formatOptionLabel={_highlightSubstring}
                components={selectComponents}
                filterOption={() => {
                    return true;
                }}
                onInputChange={_onInputChange}
                onChange={_onChange}
                onKeyDown={_onKeyDown}
                onMenuOpen={() => props.toggleAutocompleteMobileMenu?.(true)}
                onMenuClose={() => props.toggleAutocompleteMobileMenu?.(false)}
                // Custom props
                // @ts-ignore https://react-select.com/components#adjusting-the-styling
                onShowPoiBarVisible={props.onShowPoiBarVisible}
                onFilterHistory={_onFilterHistoryClick}
                onFilterOwnPlaces={_onFilterOwnPlacesClick}
                onFilterAll={_onFilterAllClick}
                onFilterChange={props.onFilterChange}
                lastSearchText={lastSearchText}
                allSuggestionsCount={props.allSuggestions.length}
                existsSuggestions={existsSuggestions}
                searchInProgress={props.searchInProgress}
                filter={props.filter} // filter data from placesApi, not here.
                isTyping={props.isTyping}
                translations={{
                    filter: {
                        all: t('PlannerAutocomplete.filterAll'),
                        own: t('PlannerAutocomplete.filterOwn'),
                        history: t('PlannerAutocomplete.filterHistory')
                    },
                    subFilter: {
                        companies: t('PlannerAutocomplete.filterCompanies'),
                        ourCompanies: t('PlannerAutocomplete.filterourCompanies'),
                        gasStations: t('PlannerAutocomplete.filterGasStations'),
                        parkingLots: t('PlannerAutocomplete.filterParkingLots')
                    },
                    search: t('common.searchPlaceholder'),
                    noData: {
                        all: t('common.noDataAvailable'),
                        history: t('PlannerAutocomplete.noData.history', { value: lastSearchText }),
                        favorites: t('PlannerAutocomplete.noData.favorites', { value: lastSearchText }),
                        willAppear: t('PlannerAutocomplete.noData.willAppear')
                    },
                    blankState: {
                        all: {
                            text1: t('PlannerAutocomplete.blankState.all.text1'),
                            text2: t('PlannerAutocomplete.blankState.all.text2'),
                            text3: t('PlannerAutocomplete.blankState.all.text3')
                        },
                        history: t('PlannerAutocomplete.blankState.history'),
                        favorite: {
                            title: t('PlannerAutocomplete.blankState.favorite.title'),
                            description: t('PlannerAutocomplete.blankState.favorite.description')
                        }
                    }
                }}
            />
        </div>
    );
}
