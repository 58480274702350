import { Button } from 'components/base/controls';
import { Trans, useTranslation } from 'react-i18next';

const bulletList = ['text1', 'text2', 'text3', 'text4'];

interface Props {
    onAddVehicleClick: () => void;
}

export default function TrackingVehiclesListBlank(props: Props) {
    const { t } = useTranslation();

    return (
        <div className="rl-tracking-vehicles-list-blank">
            <div className="rl-tracking-vehicles-list-blank-not-logged-left">
                <h4>{t('TrackingVehiclesListBlank.title')}</h4>

                <div>{t('TrackingVehiclesListBlank.description')}</div>

                <ul className="rl-tracking-vehicles-list-blank-list">
                    {bulletList.map((key, index) => (
                        <li key={index}>
                            <i className="rl-icon-check-circle" />

                            <p>
                                <Trans i18nKey={`TrackingVehiclesListBlank.${key}`} />
                            </p>
                        </li>
                    ))}
                </ul>

                <Button type="primary" onClick={props.onAddVehicleClick}>
                    {t('common.addVehicle')}
                </Button>
            </div>
        </div>
    );
}
