/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InlineResponse20021,
    InlineResponse20021FromJSON,
    InlineResponse20021ToJSON,
    InlineResponse20022,
    InlineResponse20022FromJSON,
    InlineResponse20022ToJSON,
    ReadOnlyMonitoringDevice,
    ReadOnlyMonitoringDeviceFromJSON,
    ReadOnlyMonitoringDeviceToJSON,
    ReadOnlyMonitoringDeviceNested,
    ReadOnlyMonitoringDeviceNestedFromJSON,
    ReadOnlyMonitoringDeviceNestedToJSON,
    WriteOnlyMonitoringDevice,
    WriteOnlyMonitoringDeviceFromJSON,
    WriteOnlyMonitoringDeviceToJSON,
} from '../models';

export interface MonitoringDeviceCreateRequest {
    data: WriteOnlyMonitoringDevice;
}

export interface MonitoringDeviceDeleteRequest {
    id: number;
}

export interface MonitoringDeviceDiagExportRequest {
    client?: string;
    serialNumberIcontains?: string;
    serialNumberIexact?: string;
    limit?: number;
    offset?: number;
}

export interface MonitoringDeviceListRequest {
    client?: string;
    serialNumberIcontains?: string;
    serialNumberIexact?: string;
    limit?: number;
    offset?: number;
}

export interface MonitoringDeviceNestedRequest {
    client?: string;
    serialNumberIcontains?: string;
    serialNumberIexact?: string;
    limit?: number;
    offset?: number;
}

export interface MonitoringDeviceNestedSingleRequest {
    id: number;
}

export interface MonitoringDevicePartialUpdateRequest {
    id: number;
    data: WriteOnlyMonitoringDevice;
}

export interface MonitoringDevicePatchMetadataRequest {
    id: number;
    data: object;
}

export interface MonitoringDeviceReadRequest {
    id: number;
}

export interface MonitoringDeviceUpdateRequest {
    id: number;
    data: WriteOnlyMonitoringDevice;
}

/**
 * no description
 */
export class MonitoringDeviceApi extends runtime.BaseAPI {

    /**
     */
    async monitoringDeviceCreateRaw(requestParameters: MonitoringDeviceCreateRequest): Promise<runtime.ApiResponse<ReadOnlyMonitoringDevice>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling monitoringDeviceCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/monitoring-device/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyMonitoringDeviceToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyMonitoringDeviceFromJSON(jsonValue));
    }

    /**
     */
    async monitoringDeviceCreate(requestParameters: MonitoringDeviceCreateRequest): Promise<ReadOnlyMonitoringDevice> {
        const response = await this.monitoringDeviceCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async monitoringDeviceDeleteRaw(requestParameters: MonitoringDeviceDeleteRequest): Promise<runtime.ApiResponse<ReadOnlyMonitoringDevice>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling monitoringDeviceDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/monitoring-device/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyMonitoringDeviceFromJSON(jsonValue));
    }

    /**
     */
    async monitoringDeviceDelete(requestParameters: MonitoringDeviceDeleteRequest): Promise<ReadOnlyMonitoringDevice> {
        const response = await this.monitoringDeviceDeleteRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async monitoringDeviceDiagExportRaw(requestParameters: MonitoringDeviceDiagExportRequest): Promise<runtime.ApiResponse<InlineResponse20021>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.client !== undefined) {
            queryParameters['client'] = requestParameters.client;
        }

        if (requestParameters.serialNumberIcontains !== undefined) {
            queryParameters['serial_number__icontains'] = requestParameters.serialNumberIcontains;
        }

        if (requestParameters.serialNumberIexact !== undefined) {
            queryParameters['serial_number__iexact'] = requestParameters.serialNumberIexact;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/monitoring-device/diag-export/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20021FromJSON(jsonValue));
    }

    /**
     */
    async monitoringDeviceDiagExport(requestParameters: MonitoringDeviceDiagExportRequest): Promise<InlineResponse20021> {
        const response = await this.monitoringDeviceDiagExportRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async monitoringDeviceListRaw(requestParameters: MonitoringDeviceListRequest): Promise<runtime.ApiResponse<InlineResponse20021>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.client !== undefined) {
            queryParameters['client'] = requestParameters.client;
        }

        if (requestParameters.serialNumberIcontains !== undefined) {
            queryParameters['serial_number__icontains'] = requestParameters.serialNumberIcontains;
        }

        if (requestParameters.serialNumberIexact !== undefined) {
            queryParameters['serial_number__iexact'] = requestParameters.serialNumberIexact;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/monitoring-device/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20021FromJSON(jsonValue));
    }

    /**
     */
    async monitoringDeviceList(requestParameters: MonitoringDeviceListRequest): Promise<InlineResponse20021> {
        const response = await this.monitoringDeviceListRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async monitoringDeviceNestedRaw(requestParameters: MonitoringDeviceNestedRequest): Promise<runtime.ApiResponse<InlineResponse20022>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.client !== undefined) {
            queryParameters['client'] = requestParameters.client;
        }

        if (requestParameters.serialNumberIcontains !== undefined) {
            queryParameters['serial_number__icontains'] = requestParameters.serialNumberIcontains;
        }

        if (requestParameters.serialNumberIexact !== undefined) {
            queryParameters['serial_number__iexact'] = requestParameters.serialNumberIexact;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/monitoring-device/nested/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20022FromJSON(jsonValue));
    }

    /**
     */
    async monitoringDeviceNested(requestParameters: MonitoringDeviceNestedRequest): Promise<InlineResponse20022> {
        const response = await this.monitoringDeviceNestedRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async monitoringDeviceNestedSingleRaw(requestParameters: MonitoringDeviceNestedSingleRequest): Promise<runtime.ApiResponse<ReadOnlyMonitoringDeviceNested>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling monitoringDeviceNestedSingle.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/monitoring-device/{id}/nested/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyMonitoringDeviceNestedFromJSON(jsonValue));
    }

    /**
     */
    async monitoringDeviceNestedSingle(requestParameters: MonitoringDeviceNestedSingleRequest): Promise<ReadOnlyMonitoringDeviceNested> {
        const response = await this.monitoringDeviceNestedSingleRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async monitoringDevicePartialUpdateRaw(requestParameters: MonitoringDevicePartialUpdateRequest): Promise<runtime.ApiResponse<ReadOnlyMonitoringDevice>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling monitoringDevicePartialUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling monitoringDevicePartialUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/monitoring-device/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyMonitoringDeviceToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyMonitoringDeviceFromJSON(jsonValue));
    }

    /**
     */
    async monitoringDevicePartialUpdate(requestParameters: MonitoringDevicePartialUpdateRequest): Promise<ReadOnlyMonitoringDevice> {
        const response = await this.monitoringDevicePartialUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async monitoringDevicePatchMetadataRaw(requestParameters: MonitoringDevicePatchMetadataRequest): Promise<runtime.ApiResponse<{ [key: string]: object; }>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling monitoringDevicePatchMetadata.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling monitoringDevicePatchMetadata.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/monitoring-device/{id}/metadata/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.data as any,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async monitoringDevicePatchMetadata(requestParameters: MonitoringDevicePatchMetadataRequest): Promise<{ [key: string]: object; }> {
        const response = await this.monitoringDevicePatchMetadataRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async monitoringDeviceReadRaw(requestParameters: MonitoringDeviceReadRequest): Promise<runtime.ApiResponse<ReadOnlyMonitoringDevice>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling monitoringDeviceRead.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/monitoring-device/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyMonitoringDeviceFromJSON(jsonValue));
    }

    /**
     */
    async monitoringDeviceRead(requestParameters: MonitoringDeviceReadRequest): Promise<ReadOnlyMonitoringDevice> {
        const response = await this.monitoringDeviceReadRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async monitoringDeviceUpdateRaw(requestParameters: MonitoringDeviceUpdateRequest): Promise<runtime.ApiResponse<ReadOnlyMonitoringDevice>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling monitoringDeviceUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling monitoringDeviceUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/monitoring-device/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyMonitoringDeviceToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyMonitoringDeviceFromJSON(jsonValue));
    }

    /**
     */
    async monitoringDeviceUpdate(requestParameters: MonitoringDeviceUpdateRequest): Promise<ReadOnlyMonitoringDevice> {
        const response = await this.monitoringDeviceUpdateRaw(requestParameters);
        return await response.value();
    }

}
