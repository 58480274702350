/* tslint:disable */
/* eslint-disable */
/**
 * Telematics API
 * Telematics services API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PushyMeRegistrationsRes
 */
export interface PushyMeRegistrationsRes {
    /**
     * 
     * @type {{ [key: string]: { [key: string]: Array<string>; }; }}
     * @memberof PushyMeRegistrationsRes
     */
    registrations: { [key: string]: { [key: string]: Array<string>; }; };
}

export function PushyMeRegistrationsResFromJSON(json: any): PushyMeRegistrationsRes {
    return PushyMeRegistrationsResFromJSONTyped(json, false);
}

export function PushyMeRegistrationsResFromJSONTyped(json: any, ignoreDiscriminator: boolean): PushyMeRegistrationsRes {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'registrations': json['registrations'],
    };
}

export function PushyMeRegistrationsResToJSON(value?: PushyMeRegistrationsRes | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'registrations': value.registrations,
    };
}


