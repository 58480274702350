import { useTranslation } from 'react-i18next';
import { Col, Form, Row } from 'antd';
import { Button, Input } from 'components/base/controls';

interface Props {
    onOpenLoginModal: () => void;
    onSubmitResetPassword: (email: string) => void;
}

export interface ResetPassword {
    email: string;
}

export function ResetPasswordForm(props: Props) {
    const { t } = useTranslation();
    const [form] = Form.useForm<ResetPassword>();

    function _onFinish(formValues: ResetPassword) {
        props.onSubmitResetPassword(formValues.email);
    }

    return (
        <>
            <Form<ResetPassword>
                form={form}
                id="loginForm"
                layout="vertical"
                name="loginForm"
                requiredMark={false}
                onFinish={_onFinish}
            >
                <p>{t('ResetPasswordForm.infoText')}</p>

                <Row>
                    <Col span="24">
                        <Form.Item label={t('common.email')} name="email" rules={[{ required: true, type: 'email' }]}>
                            <Input placeholder={t('ResetPasswordForm.emailPlaceholder')} />
                        </Form.Item>
                    </Col>
                </Row>

                <Row>
                    <Col span="24">
                        <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
                            {t('ResetPasswordForm.reset')}
                        </Button>
                    </Col>
                </Row>
            </Form>

            <Button
                className="rl-reset-password-modal-back"
                type="link"
                icon={<i className="rl-icon-chevron-left" />}
                onClick={props.onOpenLoginModal}
            >
                {t('ResetPasswordForm.goBack')}
            </Button>
        </>
    );
}
