/* tslint:disable */
/* eslint-disable */
/**
 * Telematics API
 * Telematics services API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OperationalCost,
    OperationalCostFromJSON,
    OperationalCostFromJSONTyped,
    OperationalCostToJSON,
} from '.';

/**
 * Company Operational Costs
 * @export
 * @interface MonitoredObjectOperationalCostProperties
 */
export interface MonitoredObjectOperationalCostProperties {
    /**
     * 
     * @type {OperationalCost}
     * @memberof MonitoredObjectOperationalCostProperties
     */
    fuel: OperationalCost;
    /**
     * 
     * @type {OperationalCost}
     * @memberof MonitoredObjectOperationalCostProperties
     */
    additives: OperationalCost;
    /**
     * 
     * @type {OperationalCost}
     * @memberof MonitoredObjectOperationalCostProperties
     */
    oil: OperationalCost;
    /**
     * 
     * @type {OperationalCost}
     * @memberof MonitoredObjectOperationalCostProperties
     */
    tires: OperationalCost;
    /**
     * 
     * @type {OperationalCost}
     * @memberof MonitoredObjectOperationalCostProperties
     */
    maintenance: OperationalCost;
    /**
     * 
     * @type {OperationalCost}
     * @memberof MonitoredObjectOperationalCostProperties
     */
    liabilityInsurance: OperationalCost;
    /**
     * 
     * @type {OperationalCost}
     * @memberof MonitoredObjectOperationalCostProperties
     */
    collisionInsurance: OperationalCost;
    /**
     * 
     * @type {OperationalCost}
     * @memberof MonitoredObjectOperationalCostProperties
     */
    personalInjuryInsurance: OperationalCost;
    /**
     * 
     * @type {OperationalCost}
     * @memberof MonitoredObjectOperationalCostProperties
     */
    parking: OperationalCost;
    /**
     * 
     * @type {OperationalCost}
     * @memberof MonitoredObjectOperationalCostProperties
     */
    cabinHeating: OperationalCost;
    /**
     * 
     * @type {OperationalCost}
     * @memberof MonitoredObjectOperationalCostProperties
     */
    telecomunicationsAndMonitoring: OperationalCost;
    /**
     * 
     * @type {OperationalCost}
     * @memberof MonitoredObjectOperationalCostProperties
     */
    washing: OperationalCost;
    /**
     * 
     * @type {OperationalCost}
     * @memberof MonitoredObjectOperationalCostProperties
     */
    ewToll: OperationalCost;
    /**
     * 
     * @type {OperationalCost}
     * @memberof MonitoredObjectOperationalCostProperties
     */
    ewTelematics: OperationalCost;
}

export function MonitoredObjectOperationalCostPropertiesFromJSON(json: any): MonitoredObjectOperationalCostProperties {
    return MonitoredObjectOperationalCostPropertiesFromJSONTyped(json, false);
}

export function MonitoredObjectOperationalCostPropertiesFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitoredObjectOperationalCostProperties {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fuel': OperationalCostFromJSON(json['fuel']),
        'additives': OperationalCostFromJSON(json['additives']),
        'oil': OperationalCostFromJSON(json['oil']),
        'tires': OperationalCostFromJSON(json['tires']),
        'maintenance': OperationalCostFromJSON(json['maintenance']),
        'liabilityInsurance': OperationalCostFromJSON(json['liability_insurance']),
        'collisionInsurance': OperationalCostFromJSON(json['collision_insurance']),
        'personalInjuryInsurance': OperationalCostFromJSON(json['personal_injury_insurance']),
        'parking': OperationalCostFromJSON(json['parking']),
        'cabinHeating': OperationalCostFromJSON(json['cabin_heating']),
        'telecomunicationsAndMonitoring': OperationalCostFromJSON(json['telecomunications_and_monitoring']),
        'washing': OperationalCostFromJSON(json['washing']),
        'ewToll': OperationalCostFromJSON(json['ew_toll']),
        'ewTelematics': OperationalCostFromJSON(json['ew_telematics']),
    };
}

export function MonitoredObjectOperationalCostPropertiesToJSON(value?: MonitoredObjectOperationalCostProperties | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fuel': OperationalCostToJSON(value.fuel),
        'additives': OperationalCostToJSON(value.additives),
        'oil': OperationalCostToJSON(value.oil),
        'tires': OperationalCostToJSON(value.tires),
        'maintenance': OperationalCostToJSON(value.maintenance),
        'liability_insurance': OperationalCostToJSON(value.liabilityInsurance),
        'collision_insurance': OperationalCostToJSON(value.collisionInsurance),
        'personal_injury_insurance': OperationalCostToJSON(value.personalInjuryInsurance),
        'parking': OperationalCostToJSON(value.parking),
        'cabin_heating': OperationalCostToJSON(value.cabinHeating),
        'telecomunications_and_monitoring': OperationalCostToJSON(value.telecomunicationsAndMonitoring),
        'washing': OperationalCostToJSON(value.washing),
        'ew_toll': OperationalCostToJSON(value.ewToll),
        'ew_telematics': OperationalCostToJSON(value.ewTelematics),
    };
}


