/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SavingData
 */
export interface SavingData {
    /**
     * 
     * @type {number}
     * @memberof SavingData
     */
    driveConsumption: number;
    /**
     * 
     * @type {number}
     * @memberof SavingData
     */
    idleConsumption: number;
    /**
     * 
     * @type {number}
     * @memberof SavingData
     */
    avgConsumptionWithoutPto: number;
    /**
     * 
     * @type {number}
     * @memberof SavingData
     */
    increasedRpmConsumptionIdle: number;
    /**
     * 
     * @type {number}
     * @memberof SavingData
     */
    avgConsumptionWithCruiseControl: number;
    /**
     * 
     * @type {number}
     * @memberof SavingData
     */
    avgConsumptionWithCruiseControlToAvgConsumption: number;
    /**
     * 
     * @type {number}
     * @memberof SavingData
     */
    avgSpeedWithCruiseControl: number;
    /**
     * 
     * @type {number}
     * @memberof SavingData
     */
    stayIdleCntDp: number;
    /**
     * 
     * @type {number}
     * @memberof SavingData
     */
    maxRpm: number;
    /**
     * 
     * @type {number}
     * @memberof SavingData
     */
    driveTimeWithoutConsumptionWithEcoroll: number;
    /**
     * 
     * @type {number}
     * @memberof SavingData
     */
    driveTimeWithoutConsumptionWithEcorollPercentage: number;
    /**
     * 
     * @type {number}
     * @memberof SavingData
     */
    constantAccelerationTime: number;
    /**
     * 
     * @type {number}
     * @memberof SavingData
     */
    constantAccelerationTimePercentage: number;
    /**
     * 
     * @type {number}
     * @memberof SavingData
     */
    accelerationTimeWithCruiseControl: number;
    /**
     * 
     * @type {number}
     * @memberof SavingData
     */
    accelerationTimeWithCruiseControlPercentage: number;
    /**
     * 
     * @type {number}
     * @memberof SavingData
     */
    kickdownTime: number;
    /**
     * 
     * @type {number}
     * @memberof SavingData
     */
    driveTime85: number;
    /**
     * 
     * @type {number}
     * @memberof SavingData
     */
    driveTime85Percentage: number;
    /**
     * 
     * @type {number}
     * @memberof SavingData
     */
    rpmOverTime: number;
    /**
     * 
     * @type {number}
     * @memberof SavingData
     */
    rpmOverTimePercentage: number;
    /**
     * 
     * @type {number}
     * @memberof SavingData
     */
    predictabilityTime: number;
    /**
     * 
     * @type {number}
     * @memberof SavingData
     */
    distanceEcoroll: number;
    /**
     * 
     * @type {number}
     * @memberof SavingData
     */
    distanceEcorollPercentage: number;
}

export function SavingDataFromJSON(json: any): SavingData {
    return SavingDataFromJSONTyped(json, false);
}

export function SavingDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): SavingData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'driveConsumption': json['drive_consumption'],
        'idleConsumption': json['idle_consumption'],
        'avgConsumptionWithoutPto': json['avg_consumption_without_pto'],
        'increasedRpmConsumptionIdle': json['increased_rpm_consumption_idle'],
        'avgConsumptionWithCruiseControl': json['avg_consumption_with_cruise_control'],
        'avgConsumptionWithCruiseControlToAvgConsumption': json['avg_consumption_with_cruise_control_to_avg_consumption'],
        'avgSpeedWithCruiseControl': json['avg_speed_with_cruise_control'],
        'stayIdleCntDp': json['stay_idle_cnt_dp'],
        'maxRpm': json['max_rpm'],
        'driveTimeWithoutConsumptionWithEcoroll': json['drive_time_without_consumption_with_ecoroll'],
        'driveTimeWithoutConsumptionWithEcorollPercentage': json['drive_time_without_consumption_with_ecoroll_percentage'],
        'constantAccelerationTime': json['constant_acceleration_time'],
        'constantAccelerationTimePercentage': json['constant_acceleration_time_percentage'],
        'accelerationTimeWithCruiseControl': json['acceleration_time_with_cruise_control'],
        'accelerationTimeWithCruiseControlPercentage': json['acceleration_time_with_cruise_control_percentage'],
        'kickdownTime': json['kickdown_time'],
        'driveTime85': json['drive_time_85'],
        'driveTime85Percentage': json['drive_time_85_percentage'],
        'rpmOverTime': json['rpm_over_time'],
        'rpmOverTimePercentage': json['rpm_over_time_percentage'],
        'predictabilityTime': json['predictability_time'],
        'distanceEcoroll': json['distance_ecoroll'],
        'distanceEcorollPercentage': json['distance_ecoroll_percentage'],
    };
}

export function SavingDataToJSON(value?: SavingData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'drive_consumption': value.driveConsumption,
        'idle_consumption': value.idleConsumption,
        'avg_consumption_without_pto': value.avgConsumptionWithoutPto,
        'increased_rpm_consumption_idle': value.increasedRpmConsumptionIdle,
        'avg_consumption_with_cruise_control': value.avgConsumptionWithCruiseControl,
        'avg_consumption_with_cruise_control_to_avg_consumption': value.avgConsumptionWithCruiseControlToAvgConsumption,
        'avg_speed_with_cruise_control': value.avgSpeedWithCruiseControl,
        'stay_idle_cnt_dp': value.stayIdleCntDp,
        'max_rpm': value.maxRpm,
        'drive_time_without_consumption_with_ecoroll': value.driveTimeWithoutConsumptionWithEcoroll,
        'drive_time_without_consumption_with_ecoroll_percentage': value.driveTimeWithoutConsumptionWithEcorollPercentage,
        'constant_acceleration_time': value.constantAccelerationTime,
        'constant_acceleration_time_percentage': value.constantAccelerationTimePercentage,
        'acceleration_time_with_cruise_control': value.accelerationTimeWithCruiseControl,
        'acceleration_time_with_cruise_control_percentage': value.accelerationTimeWithCruiseControlPercentage,
        'kickdown_time': value.kickdownTime,
        'drive_time_85': value.driveTime85,
        'drive_time_85_percentage': value.driveTime85Percentage,
        'rpm_over_time': value.rpmOverTime,
        'rpm_over_time_percentage': value.rpmOverTimePercentage,
        'predictability_time': value.predictabilityTime,
        'distance_ecoroll': value.distanceEcoroll,
        'distance_ecoroll_percentage': value.distanceEcorollPercentage,
    };
}


