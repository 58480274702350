import { Tooltip } from 'components/base/controls/Tooltip';
import { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';

import { Checkbox } from 'components/base/controls/Checkbox';
import { Button } from 'components/base/controls';

export interface AlarmModel {
    type: AlarmType;
    config?: {
        name: string;
        value: number;
    };
}

export enum AlarmType {
    CorridorLeave = 'transport_corridor_leave',
    CloseToPoi = 'transport_poi_close',
    TransportArrival = 'transport_arrival',
    TransportDeparture = 'transport_departure'
}

interface Props extends WithTranslation {
    alarms?: AlarmModel[];
    onAlarmConfigChange?: (config: AlarmModel[]) => void;
}

interface State {
    alarms: AlarmModel[];
    notificationDisclaimer: boolean;
}

class PlannerAlarms extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            alarms: [],
            notificationDisclaimer: true
        };
    }

    options: { label: string; type: AlarmType }[] = [
        {
            label: this.props.t('PlannerAlarms.types.transport_arrival.name'),
            type: AlarmType.TransportArrival
        }
    ];

    componentDidMount() {
        this.setState({ alarms: this.props.alarms || [] });
    }

    componentDidUpdate(prevProps: Props) {
        if (JSON.stringify(prevProps.alarms) !== JSON.stringify(this.props.alarms)) {
            this.setState({ alarms: this.props.alarms || [] });
        }
    }

    getAlarm = (type: AlarmType): AlarmModel | undefined => this.state.alarms.find(a => a.type === type);

    render() {
        const { alarms: value } = this.state;
        const { t } = this.props;

        return (
            <div className="rl-planner-alarms">
                <div className="rl-planner-alarms-title">
                    <label>{t('PlannerAlarms.title')}</label>
                    <Tooltip.Icon title={t('PlannerAlarms.tooltip')} />
                </div>
                <Checkbox.Group value={value.map(v => v.type)} onChange={this.onAlarmChange}>
                    {this.options.map(option => (
                        <div className="ant-checkbox-group-item" key={option.type}>
                            <Checkbox checked={!!this.getAlarm(option.type)} value={option.type}>
                                {option.label}
                            </Checkbox>
                        </div>
                    ))}
                </Checkbox.Group>
                {this.state.notificationDisclaimer && (
                    <div className="rl-planner-alarms-alert">
                        <span>{t('PlannerAlarms.disclaimer')}</span>
                        <div className="rl-planner-alarms-alert-close">
                            <Button type="icon" onClick={this._onNotificationDisclaimerClick}>
                                <i className="rl-icon-close" />
                            </Button>
                        </div>
                    </div>
                )}
            </div>
        );
    }

    private onAlarmChange = (value: CheckboxValueType[]) => {
        this.setState({ alarms: value.map(v => ({ type: v as AlarmType })) }, () => {
            this.props.onAlarmConfigChange?.(this.state.alarms);
        });
    };

    private _onNotificationDisclaimerClick = () => {
        this.setState(state => ({
            notificationDisclaimer: !state.notificationDisclaimer
        }));
    };
}

export default withTranslation()(PlannerAlarms);
