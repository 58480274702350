import { Menu, MenuProps } from 'antd';

interface Props extends MenuProps {}

function EnhancedMenu(props: Props) {
    return <Menu {...props} className={props.className ? 'rl-menu ' + props.className : 'rl-menu'} />;
}

EnhancedMenu.Item = Menu.Item;

export default EnhancedMenu;
