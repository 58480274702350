/* tslint:disable */
/* eslint-disable */
/**
 * Telematics API
 * Telematics services API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NotificationNumSub
 */
export interface NotificationNumSub {
    /**
     * 
     * @type {number}
     * @memberof NotificationNumSub
     */
    subscribers: number;
}

export function NotificationNumSubFromJSON(json: any): NotificationNumSub {
    return NotificationNumSubFromJSONTyped(json, false);
}

export function NotificationNumSubFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotificationNumSub {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'subscribers': json['subscribers'],
    };
}

export function NotificationNumSubToJSON(value?: NotificationNumSub | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'subscribers': value.subscribers,
    };
}


