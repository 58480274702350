import { ColumnProps } from 'antd/lib/table';
import { Dropdown, Menu } from 'components/base/controls';
import { Table } from 'components/base/layout';
import { useTranslation } from 'react-i18next';
import { FuelCardModel } from 'services/api/domains/CompanyApi';
import { BRAND_ID_TO_ICON } from 'utils/constants/fuelcard-icons';

interface Props {
    data: FuelCardModel[];
    isLoading?: boolean;
    onRemoveClick: (vehicleId: number) => void;
    onRowDoubleClick: (fuelCard: FuelCardModel) => void;
    onEditClick: (fuelCard: FuelCardModel) => void;
}

function FuelCardsTable(props: Props) {
    const { t } = useTranslation();

    const columns: ColumnProps<FuelCardModel>[] = [
        {
            title: t('FuelCardsTable.cardBrand'),
            dataIndex: 'holder',
            key: 'cardBrand',
            render: (fuelCards, record) => (
                <>
                    <img
                        src={`/img/fuel-card-brands/${BRAND_ID_TO_ICON[record.brandId!] ?? 'default-icon.svg'}`}
                        alt="Brand icon"
                    />
                    <strong>{record.brandName}</strong>
                </>
            )
        },
        {
            title: t('FuelCardsTable.discount'),
            dataIndex: 'discount',
            key: 'discount',
            render: (discound: number) => `${discound} %`
        },
        {
            title: t('FuelCardsTable.note'),
            dataIndex: 'description',
            key: 'description',
            responsive: ['md']
        },
        {
            key: 'actions',
            align: 'right',
            render: (_, record) => (
                <Dropdown
                    overlay={
                        <Menu>
                            <Menu.Item key="0" onClick={() => props.onEditClick(record)}>
                                {t('common.edit')}
                            </Menu.Item>
                            <Menu.Item key="1" onClick={() => props.onRemoveClick(record.cardId)}>
                                {t('common.delete')}
                            </Menu.Item>
                        </Menu>
                    }
                    trigger={['click']}
                    arrow
                    placement="bottom"
                >
                    <i className="fas fa-ellipsis-h" />
                </Dropdown>
            )
        }
    ];
    return (
        <Table
            className="rl-settings-table rl-fuel-cards-table"
            columns={columns}
            dataSource={props.data}
            rowKey="cardId"
            loading={props.isLoading}
            onRow={record => {
                return {
                    onDoubleClick: () => {
                        props.onRowDoubleClick(record);
                    }
                };
            }}
        />
    );
}

export default FuelCardsTable;
