/* tslint:disable */
/* eslint-disable */
/**
 * Telematics API
 * Telematics services API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ClientApplicationCreateBody
 */
export interface ClientApplicationCreateBody {
    /**
     * 
     * @type {string}
     * @memberof ClientApplicationCreateBody
     */
    name: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof ClientApplicationCreateBody
     */
    vehicles?: Array<number> | null;
}

export function ClientApplicationCreateBodyFromJSON(json: any): ClientApplicationCreateBody {
    return ClientApplicationCreateBodyFromJSONTyped(json, false);
}

export function ClientApplicationCreateBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClientApplicationCreateBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'vehicles': !exists(json, 'vehicles') ? undefined : json['vehicles'],
    };
}

export function ClientApplicationCreateBodyToJSON(value?: ClientApplicationCreateBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'vehicles': value.vehicles,
    };
}


