import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { RouteNames } from 'router/routes';
import { Col, Row } from 'antd';

interface BarItemButtonProps {
    link?: string;
    isNavLink?: boolean;
    children: string | React.ReactNode;
    id?: string;
}

export default function DispatcherBoardViewBar() {
    const { t } = useTranslation();

    return (
        <div className="rl-dispatcher-view-bar">
            <Row align="middle">
                <Col flex="none">
                    <h3>{t('DispatcherBoard.dispatcherBoardTitle')}</h3>
                </Col>

                <Col flex="auto">
                    <nav className="rl-dispatcher-view-bar-nav">
                        <BarItemButton
                            link={RouteNames.SCHEDULING_DISPATCHER_BOARD_CALENDAR}
                            isNavLink
                            id="dispatcher-view-calendar"
                        >
                            {t('NavBar.calendarView')}
                        </BarItemButton>

                        <BarItemButton
                            link={RouteNames.SCHEDULING_DISPATCHER_BOARD_TABLE}
                            isNavLink
                            id="dispatcher-view-table"
                        >
                            {t('NavBar.tableView')}
                        </BarItemButton>
                    </nav>
                </Col>
                <Col flex="none"></Col>
            </Row>
        </div>
    );
}

const BarItemButton = (props: BarItemButtonProps) => {
    const { children, link, isNavLink, id } = props;
    return (
        <NavLink
            to={link ?? ''}
            activeClassName={isNavLink ? 'active' : ''}
            className="rl-dispatcher-view-bar-nav-item"
            id={id}
        >
            {children}
        </NavLink>
    );
};
