import { Col, Row } from 'antd';
import { Modal } from 'components/base/layout';
import { FeaturesCarousel } from '../../../../components/FeaturesCarousel';
import { LoginForm } from '../LoginForm';
import selectors from 'qa-selectors';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import { Button } from 'components/base/controls';
import { useTranslation } from 'react-i18next';

interface Props {
    visible?: boolean;
    loading?: boolean;
    onCancel?: () => void;
    onLoginClick: (credentials: { email: string; password: string }) => void;
    onRedirectLogin: (provider: 'google' | 'facebook') => void;
    onRegisterClick: () => void;
    onForgotPasswordClick: () => void;
}

function LoginModal(props: Props) {
    const { t } = useTranslation();
    const { isMobileWidth, windowDimensions } = useWindowDimensions();

    return (
        <Modal
            className="rl-modal-with-carousel"
            visible={props.visible}
            onCancel={props.onCancel}
            destroyOnClose={true}
            mask={!isMobileWidth}
            width={isMobileWidth ? windowDimensions.width : 944}
        >
            <div className="rl-login-modal-content" data-qa={selectors.loginModal.loginModal}>
                <Row>
                    <Col span={24} md={12}>
                        <div className="rl-login-modal-content-header">
                            <Button
                                type="action"
                                icon={<i className="rl-icon-chevron-left" />}
                                onClick={props.onCancel}
                            />
                            <h3>{t('LoginFormModal.title')}</h3>
                        </div>

                        <LoginForm
                            loading={props.loading}
                            isMobileWidth={isMobileWidth}
                            onSubmit={props.onLoginClick}
                            onRedirectLogin={props.onRedirectLogin}
                            onRegisterClick={props.onRegisterClick}
                            onForgotPasswordClick={props.onForgotPasswordClick}
                        />
                    </Col>
                    <Col span={0} md={12}>
                        <div className="rl-modal-with-carousel-features">
                            <FeaturesCarousel />
                        </div>
                    </Col>
                </Row>
            </div>
        </Modal>
    );
}

export default LoginModal;
