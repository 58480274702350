import { WithTranslation, withTranslation } from 'react-i18next';
import cn from 'classnames';
import qa from 'qa-selectors';
import { TrackingModel } from 'modules/TrackingModule/TrackingModule';

export enum ControlPanel {
    VEHICLE = 'vehicle',
    FUEL = 'fuel',
    PARKING = 'parking',
    LAYER = 'layer',
    POI = 'poi',
    PLACES = 'places'
}

interface Props extends WithTranslation {
    selected?: ControlPanel;
    vehicle?: TrackingModel;
    fuelLoading: boolean;
    parkingLoading: boolean;
    onCancel?: (control?: ControlPanel) => void;
    onBarClick?: (panel: ControlPanel) => void;
}

function MapControlsBar(props: Props) {
    function onVehicle(): void {
        if (props.vehicle) {
            props.onBarClick?.(ControlPanel.VEHICLE);
        }
    }

    function onFuel(): void {
        if (!props.fuelLoading) {
            props.onBarClick?.(ControlPanel.FUEL);
        }
    }

    function onParking(): void {
        if (!props.parkingLoading) {
            props.onBarClick?.(ControlPanel.PARKING);
        }
    }

    function onLayer(): void {
        props.onBarClick?.(ControlPanel.LAYER);
    }

    return (
        <div className="map-controls-bar t-inverse">
            <div className="map-controls-close" data-qa={qa.mapControls.closeButton}>
                <i
                    title={props.t('common.close')}
                    onClick={() => props.onCancel?.(props.selected)}
                    className="fas fa-times t-btn t-right t-ripple"
                />
            </div>
            <div className="map-controls-nav t-bar t-medium">
                <div
                    className={cn([
                        'map-bar-vehicle-card t-bar-item t-btn',
                        {
                            selected: props.selected === ControlPanel.VEHICLE,
                            't-hide': !props.vehicle
                        }
                    ])}
                    onClick={onVehicle}
                    title={props.t('common.vehicle')}
                >
                    <img src="/img/panel-delivery-truck.svg" alt={props.t('common.vehicle')} />
                </div>

                <div
                    className={cn([
                        'map-bar-fuel t-bar-item t-btn',
                        {
                            selected: props.selected === ControlPanel.FUEL,
                            disabled: props.fuelLoading
                        }
                    ])}
                    onClick={onFuel}
                    title={props.t('FuelStationControls.fuelStations')}
                >
                    <img src="/img/panel-fuel-station.svg" alt={props.t('common.fuel')} />
                </div>
                <div
                    className={cn([
                        'map-bar-parking t-bar-item t-btn',
                        {
                            selected: props.selected === ControlPanel.PARKING,
                            disabled: props.parkingLoading
                        }
                    ])}
                    onClick={onParking}
                    title={props.t('ParkingControls.parkings')}
                >
                    <img src="/img/panel-parking-sign.svg" alt={props.t('common.parking')} />
                </div>
                <div
                    className={cn([
                        'map-bar-layer t-bar-item t-btn',
                        { selected: props.selected === ControlPanel.LAYER }
                    ])}
                    onClick={onLayer}
                    title={props.t('LayerControls.mapBackgrounds')}
                >
                    <img src="/img/panel-map.svg" alt={props.t('common.map')} />
                </div>
            </div>
        </div>
    );
}

export default withTranslation()(MapControlsBar);
