/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InlineResponse20013,
    InlineResponse20013FromJSON,
    InlineResponse20013ToJSON,
    ReadOnlyExternalSystem,
    ReadOnlyExternalSystemFromJSON,
    ReadOnlyExternalSystemToJSON,
    WriteOnlyExternalSystem,
    WriteOnlyExternalSystemFromJSON,
    WriteOnlyExternalSystemToJSON,
} from '../models';

export interface ExternalSystemCreateRequest {
    data: WriteOnlyExternalSystem;
}

export interface ExternalSystemDeleteRequest {
    id: number;
}

export interface ExternalSystemListRequest {
    limit?: number;
    offset?: number;
}

export interface ExternalSystemPartialUpdateRequest {
    id: number;
    data: WriteOnlyExternalSystem;
}

export interface ExternalSystemReadRequest {
    id: number;
}

export interface ExternalSystemUpdateRequest {
    id: number;
    data: WriteOnlyExternalSystem;
}

/**
 * no description
 */
export class ExternalSystemApi extends runtime.BaseAPI {

    /**
     */
    async externalSystemCreateRaw(requestParameters: ExternalSystemCreateRequest): Promise<runtime.ApiResponse<ReadOnlyExternalSystem>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling externalSystemCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/external-system/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyExternalSystemToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyExternalSystemFromJSON(jsonValue));
    }

    /**
     */
    async externalSystemCreate(requestParameters: ExternalSystemCreateRequest): Promise<ReadOnlyExternalSystem> {
        const response = await this.externalSystemCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async externalSystemDeleteRaw(requestParameters: ExternalSystemDeleteRequest): Promise<runtime.ApiResponse<ReadOnlyExternalSystem>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling externalSystemDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/external-system/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyExternalSystemFromJSON(jsonValue));
    }

    /**
     */
    async externalSystemDelete(requestParameters: ExternalSystemDeleteRequest): Promise<ReadOnlyExternalSystem> {
        const response = await this.externalSystemDeleteRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async externalSystemListRaw(requestParameters: ExternalSystemListRequest): Promise<runtime.ApiResponse<InlineResponse20013>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/external-system/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20013FromJSON(jsonValue));
    }

    /**
     */
    async externalSystemList(requestParameters: ExternalSystemListRequest): Promise<InlineResponse20013> {
        const response = await this.externalSystemListRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async externalSystemPartialUpdateRaw(requestParameters: ExternalSystemPartialUpdateRequest): Promise<runtime.ApiResponse<ReadOnlyExternalSystem>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling externalSystemPartialUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling externalSystemPartialUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/external-system/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyExternalSystemToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyExternalSystemFromJSON(jsonValue));
    }

    /**
     */
    async externalSystemPartialUpdate(requestParameters: ExternalSystemPartialUpdateRequest): Promise<ReadOnlyExternalSystem> {
        const response = await this.externalSystemPartialUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async externalSystemReadRaw(requestParameters: ExternalSystemReadRequest): Promise<runtime.ApiResponse<ReadOnlyExternalSystem>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling externalSystemRead.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/external-system/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyExternalSystemFromJSON(jsonValue));
    }

    /**
     */
    async externalSystemRead(requestParameters: ExternalSystemReadRequest): Promise<ReadOnlyExternalSystem> {
        const response = await this.externalSystemReadRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async externalSystemUpdateRaw(requestParameters: ExternalSystemUpdateRequest): Promise<runtime.ApiResponse<ReadOnlyExternalSystem>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling externalSystemUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling externalSystemUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/external-system/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyExternalSystemToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyExternalSystemFromJSON(jsonValue));
    }

    /**
     */
    async externalSystemUpdate(requestParameters: ExternalSystemUpdateRequest): Promise<ReadOnlyExternalSystem> {
        const response = await this.externalSystemUpdateRaw(requestParameters);
        return await response.value();
    }

}
