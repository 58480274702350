export enum RouteNames {
    MESSAGING = '/messaging',

    TRACKING = '/tracking',
    TRACKING_MAP = '/tracking/map',
    TRACKING_TABLE = '/tracking/table',

    SCHEDULING_ORDERS = '/scheduling/orders',
    SCHEDULING_PLANNER = '/route-planner',
    SCHEDULING_DISPATCHER_BOARD = '/scheduling/dispatcher-board',
    SCHEDULING_DISPATCHER_BOARD_CALENDAR = '/scheduling/dispatcher-board/calendar',
    SCHEDULING_DISPATCHER_BOARD_TABLE = '/scheduling/dispatcher-board/table',

    SETTINGS = '/settings',
    SETTINGS_FLEET = '/settings/fleet',
    SETTINGS_DRIVERS = '/settings/drivers',
    SETTINGS_DISPATCHERS = '/settings/dispatchers',
    SETTINGS_FUEL_CARDS = '/settings/fuel-cards',
    SETTINGS_FAVORITES = '/settings/favorites',
    SETTINGS_COMPANY = '/settings/company',
    SETTINGS_PREFERENCES = '/settings/preferences'
}

export const DEFAULT_ROUTE = RouteNames.SCHEDULING_PLANNER;
