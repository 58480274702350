import { NavLink } from 'react-router-dom';
import { LabelWithIcon } from './LabelWithIcon';

export const NavBarItem = (props: {
    id?: string;
    label?: string;
    linkTo?: string;
    iconClass?: string;
    notificationsCount?: number;
    title?: string;
    onClick?: () => void;
    qa?: string;
}) => {
    return (
        <>
            {props.linkTo ? (
                <NavLink
                    id={props.id}
                    className="rl-navbar-menu-item"
                    to={props.linkTo}
                    title={props.title}
                    data-qa={props.qa}
                >
                    <LabelWithIcon
                        icon={props.iconClass}
                        label={props.label}
                        notificationsCount={props.notificationsCount}
                    />
                </NavLink>
            ) : (
                <span id={props.id} className="rl-navbar-menu-item" title={props.title} onClick={props.onClick}>
                    <LabelWithIcon
                        icon={props.iconClass}
                        label={props.label}
                        notificationsCount={props.notificationsCount}
                    />
                </span>
            )}
        </>
    );
};
