import i18next, { i18n } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import moment from 'moment';
import numeral from 'numeral';

import 'moment/locale/bg';
import 'moment/locale/cs';
import 'moment/locale/da';
import 'moment/locale/de';
import 'moment/locale/es';
import 'moment/locale/et';
import 'moment/locale/fi';
import 'moment/locale/fr';
import 'moment/locale/el';
import 'moment/locale/hr';
import 'moment/locale/hu';
import 'moment/locale/it';
import 'moment/locale/lt';
import 'moment/locale/lv';
import 'moment/locale/mt';
import 'moment/locale/nl';
import 'moment/locale/pl';
import 'moment/locale/pt';
import 'moment/locale/ro';
import 'moment/locale/ru';
import 'moment/locale/sk';
import 'moment/locale/sl';
import 'moment/locale/sv';
import 'moment/locale/tr';
import 'moment/locale/uk';

import 'numeral/locales/bg';
import 'numeral/locales/cs';
import 'numeral/locales/da-dk';
import 'numeral/locales/de';
import 'numeral/locales/es';
import 'numeral/locales/et';
import 'numeral/locales/fi';
import 'numeral/locales/fr';
import 'numeral/locales/hu';
import 'numeral/locales/it';
import 'numeral/locales/lv';
import 'numeral/locales/nl-nl';
import 'numeral/locales/pl';
import 'numeral/locales/pt-br';
import 'numeral/locales/ru';
import 'numeral/locales/sk';
import 'numeral/locales/sl';
import 'numeral/locales/tr';
import { LANGUAGES } from 'utils/constants/constants';

async function i18nInit(lang: string): Promise<i18n> {
    momentNumeralLangChange(lang);

    // see: https://www.i18next.com/overview/configuration-options
    await i18next
        .use(HttpApi)
        .use(LanguageDetector)
        .use(initReactI18next)
        .init({
            lng: lang,
            fallbackLng: 'en',
            debug: false,
            interpolation: {
                format: function (value, format) {
                    if (format === 'uppercase') {
                        return value.toUpperCase();
                    }
                    if (value instanceof Date) {
                        return moment(value as Date).format(format);
                    }
                    if (typeof value === 'number') {
                        return numeral(value as number).format(format);
                    }
                    return value;
                },
                escapeValue: false // not needed for react!!
            },
            backend: {
                loadPath: '/locales/{{lng}}.json'
            },
            load: 'languageOnly',
            react: {
                // wait: true,
                bindI18n: 'languageChanged loaded',
                useSuspense: true
            },
            initImmediate: false,
            supportedLngs: LANGUAGES.map(l => l.code) // currently supported languages
        });

    i18next.on('languageChanged', async (lang: string) => {
        console.log('languageChanged,', lang);
        momentNumeralLangChange(lang);
    });

    // i18next.on("initialized", (options: any) => {
    //     console.info("i18n initialized", options);
    // });

    // i18next.on("failedLoading", (lang: Lang, ns: any, msg: string) => {
    //     console.error("Failed to load i18n resources", lang, ns, msg);
    // });

    // i18next.on("onMissingKey", (lang: Lang, ns: any, key: string, res: any) => {
    //     console.error("Failed to load i18n resources", lang, ns, key, res);
    // });

    return i18next;
}

const momentNumeralLangChange = (lang: string): void => {
    switch (lang) {
        case 'el':
            moment.locale('el');
            numeral.locale('en');
            break;
        case 'da':
            moment.locale(lang);
            numeral.locale('da-dk');
            break;
        case 'ga':
            moment.locale('en');
            numeral.locale('en');
            break;
        case 'hr':
            moment.locale('hr');
            numeral.locale('en');
            break;
        case 'lt':
            moment.locale('lt');
            numeral.locale('en');
            break;
        case 'mt':
            moment.locale('mt');
            numeral.locale('en');
            break;
        case 'nl':
            moment.locale('nl');
            numeral.locale('nl-nl');
            break;
        case 'ro':
            moment.locale('ro');
            numeral.locale('en');
            break;
        case 'sv':
            moment.locale('sv');
            numeral.locale('en');
            break;
        case 'uk':
            moment.locale('uk');
            numeral.locale('ru');
            break;
        case 'pt':
            moment.locale(lang);
            numeral.locale('pt-br');
            break;
        default:
            moment.locale(lang);
            numeral.locale(lang);
    }
};

export default i18nInit;
