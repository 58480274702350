import { useTranslation } from 'react-i18next';
import { WhatsappButton } from '../base/controls/WhatsappButton';

interface Props {
    text: string;
    size: 'middle' | 'small';
    disabled: boolean;
    onClick?: () => void;
}

export default function WhatsappSendButton(props: Props) {
    const { t } = useTranslation();

    return (
        <WhatsappButton
            size={props.size}
            disabled={props.disabled}
            href={`https://api.whatsapp.com/send?text=${props.text}`}
            target="_blank"
            onClick={props.onClick}
        >
            {t('InviteDriverBanner.sendToWhatsapp')}
        </WhatsappButton>
    );
}
