import { PRICE_VISIBLE_ZOOM } from '../logic/fuelStations';
import { createIcon } from '../utils';
import { FUEL_STATIONS_SOURCE_ID } from './FuelstationsLayer';
import { PARKING_SOURCE_ID } from './ParkingsLayer';

export const FAVORITE_ICON = '/img/map-markers/rl-favorites.svg';

export class FavoritesLayer {
    public static async init(map: mapboxgl.Map) {
        await createIcon('favorites', FAVORITE_ICON, map);

        map.addLayer({
            id: 'fuels-clusters-favorites',
            type: 'symbol',
            source: FUEL_STATIONS_SOURCE_ID,
            filter: ['all', ['has', 'point_count'], ['==', ['get', 'hasFavorites'], true]],
            layout: {
                'icon-image': 'favorites',
                'icon-offset': [
                    'step',
                    ['zoom'],
                    ['literal', [26, -22]],
                    PRICE_VISIBLE_ZOOM,
                    [
                        'case',
                        ['all', ['has', 'bestPrice'], ['!=', ['get', 'bestPrice'], Number.MAX_SAFE_INTEGER]],
                        ['literal', [26, -12]],
                        ['literal', [26, -22]]
                    ],
                    23, // MapBox max zoom level
                    ['literal', [26, -22]]
                ],
                'icon-allow-overlap': true
            }
        });

        map.addLayer({
            id: 'fuels-unclustered-point-favorites',
            source: FUEL_STATIONS_SOURCE_ID,
            type: 'symbol',
            filter: ['all', ['!', ['has', 'point_count']], ['==', ['get', 'isFavorite'], true]],
            layout: {
                'icon-image': 'favorites',
                'icon-offset': [
                    'step',
                    ['zoom'],
                    ['literal', [16, -34]],
                    PRICE_VISIBLE_ZOOM,
                    ['case', ['has', 'fsBestPrice'], ['literal', [28, -38]], ['literal', [16, -34]]],
                    23, // MapBox max zoom level
                    ['literal', [16, -34]]
                ],
                'icon-allow-overlap': true
            },
            paint: {
                'icon-opacity': [
                    'case',
                    ['any', ['==', ['get', 'isAlongRoute'], true], ['boolean', ['feature-state', 'hover'], false]],
                    1,
                    0.6
                ]
            }
        });

        map.addLayer({
            id: 'parking-clusters-favorites',
            type: 'symbol',
            source: PARKING_SOURCE_ID,
            filter: ['all', ['has', 'point_count'], ['==', ['get', 'hasFavorites'], true]],
            layout: {
                'icon-image': 'favorites',
                'icon-offset': [27, -20],
                'icon-allow-overlap': true
            }
        });

        map.addLayer({
            id: 'parking-unclustered-point-favorites',
            source: PARKING_SOURCE_ID,
            type: 'symbol',
            filter: ['all', ['!', ['has', 'point_count']], ['==', ['get', 'isFavorite'], true]],
            layout: {
                'icon-image': 'favorites',
                'icon-offset': [16, -34],
                'icon-allow-overlap': true
            },
            paint: {
                'icon-opacity': [
                    'case',
                    ['any', ['==', ['get', 'isAlongRoute'], true], ['boolean', ['feature-state', 'hover'], false]],
                    1,
                    0.6
                ]
            }
        });
    }
}
