import { GeocodingGrid } from './geocoding-grid';
import { Logic } from './logic';
import { AddressStructuredType, ReverseLanguageBatchLangsEnum } from '../generated/geocoding-api';
import { RootStore } from 'stores/RootStore';

export class Geocoding {
    _grid: GeocodingGrid;

    constructor(private _logic: Logic, private _store: RootStore) {
        this._grid = new GeocodingGrid();
    }

    async geocodeLatLng(location: { lat: number; lng: number }) {
        return this._logic
            .apiService()
            .geocoding()
            .reverseGeocoding(location.lat, location.lng)
            .then(result => result.formattedResult);
    }

    async geocodeBatch(lat: number, lng: number) {
        const [x, y] = this._grid.toXY(lat, lng);
        const language = this._store.userSettings.lang;

        const geocode = async (retryCount = 1) => {
            return new Promise<AddressStructuredType[]>((res, rej) => {
                this._logic
                    .api()
                    .geocodingApi.reverseLanguageBatch({
                        x,
                        y,
                        distance: 2,
                        langs: [language] as string[] as ReverseLanguageBatchLangsEnum[]
                    })
                    .then(result => {
                        res(result);
                    })
                    .catch(err => {
                        if (retryCount && err.status >= 500) {
                            res(geocode(retryCount - 1));
                        } else {
                            rej(err);
                        }
                    });
            });
        };

        return geocode();
    }
}
