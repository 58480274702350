import { TransportModel } from 'logic/dispatcher-board';
import { useState } from 'react';
import { CalendarViewDatePicker } from './ui/CalendarViewDatePicker/CalendarViewDatePicker';
import { VehicleStateObject } from 'services/api/domains/VehiclesApi';
import { CalendarViewMobileGrid } from './ui/CalendarViewMobileGrid/CalendarViewMobileGrid';
import { VehicleSelector } from './ui/VehicleSelector';
import { VehicleSelectorButton } from './ui/VehicleSelectorButton';
import { Spin } from 'components/base/controls';
import { Country } from 'services/api/domains/CountryApi';

interface Props {
    filter: {
        date: string;
        selectedVehicle: VehicleStateObject | null;
    };
    transports: TransportModel[];
    vehicles: VehicleStateObject[];
    countryList: Country[];
    isLoading?: boolean;
    onFilterChange: (filterProps: { date: string; selectedVehicle: VehicleStateObject | null }) => void;
    onTransportClick: (transport: TransportModel) => void;
    onAssignDriverClick: (vehicleId: string) => void;
}

export const CalendarViewMobile = (props: Props) => {
    const [vehicleSelectorVisible, setVehicleSelectorVisible] = useState<boolean>(false);

    const onVehicleChange = (vehicleId: string | null) => {
        const vehicle = vehicleId ? props.vehicles.find(v => v.monitoredObjectId === vehicleId) || null : null;
        props.onFilterChange({
            ...props.filter,
            selectedVehicle: vehicle
        });
    };

    const onDateChange = (date: string) => {
        props.onFilterChange({
            ...props.filter,
            date: date
        });
    };

    return (
        <div className="rl-calendar-view-mobile">
            {vehicleSelectorVisible && (
                <VehicleSelector
                    selectedVehicleId={props.filter.selectedVehicle?.monitoredObjectId || null}
                    vehicles={props.vehicles}
                    onBackButtonClick={() => setVehicleSelectorVisible(false)}
                    onAssignDriverClick={props.onAssignDriverClick}
                    onConfirmClick={vehicleId => {
                        onVehicleChange(vehicleId);
                        setVehicleSelectorVisible(false);
                    }}
                />
            )}

            <Spin loading={!!props.isLoading} opacity={true}>
                <div className="rl-calendar-view-mobile-filter">
                    <CalendarViewDatePicker date={props.filter.date} onChangeDate={onDateChange} />
                    <VehicleSelectorButton
                        selectedVehicle={props.filter.selectedVehicle}
                        onClick={() => setVehicleSelectorVisible(true)}
                    />
                </div>
                <CalendarViewMobileGrid
                    date={props.filter.date}
                    transports={props.transports}
                    countryList={props.countryList}
                    onTransportClick={props.onTransportClick}
                />
            </Spin>
        </div>
    );
};
