import { Carousel } from 'components/base/layout';
import { useTranslation } from 'react-i18next';

export default function FeaturesCarousel() {
    const { t } = useTranslation();

    const carouselItems: {
        image: string;
        title: string;
        description: string;
    }[] = [
        {
            image: '/img/login-modal/drivers-position-icon.svg',
            title: t('FeaturesCarousel.featureTitle'),
            description: t('FeaturesCarousel.featureText')
        },
        {
            image: '/img/login-modal/costs-icon.svg',
            title: t('FeaturesCarousel.featureTitle2'),
            description: t('FeaturesCarousel.featureText2')
        },
        {
            image: '/img/login-modal/stations-icon.svg',
            title: t('FeaturesCarousel.featureTitle3'),
            description: t('FeaturesCarousel.featureText3')
        },
        {
            image: '/img/login-modal/management-icon.svg',
            title: t('FeaturesCarousel.featureTitle4'),
            description: t('FeaturesCarousel.featureText4')
        },
        {
            image: '/img/login-modal/share-route-icon.svg',
            title: t('FeaturesCarousel.featureTitle5'),
            description: t('FeaturesCarousel.featureText5')
        },
        {
            image: '/img/login-modal/messaging-icon.svg',
            title: t('FeaturesCarousel.featureTitle6'),
            description: t('FeaturesCarousel.featureText6')
        },
        {
            image: '/img/login-modal/truck-profiles-icon.svg',
            title: t('FeaturesCarousel.featureTitle7'),
            description: t('FeaturesCarousel.featureText7')
        }
    ];

    return (
        <Carousel autoplay={true}>
            {carouselItems.map((item, index) => {
                return (
                    <div key={index}>
                        <div className="rl-carousel-feature" key={index}>
                            <div className="rl-carousel-feature-image">
                                <img src={item.image} alt={item.title} />
                            </div>
                            <h3 className="rl-carousel-feature-title">{item.title}</h3>
                            <div className="rl-carousel-feature-description">{item.description}</div>
                        </div>
                    </div>
                );
            })}
        </Carousel>
    );
}
