import {
    Configuration as NotificationAPIConfiguration,
    RequestContext,
    FetchParams,
    ResponseContext,
    NotificationsApi
} from 'generated/notification-api';

import { Configuration as GeocodingApiConfiguration, GeocodingApi } from 'generated/geocoding-api';
import {
    Configuration as BackendApiConfiguration,
    AlarmsApi as BackendBaseAPI,
    TripApi,
    DriverBehaviourApi,
    CostsApi,
    BorderCrossingApi,
    DriverBehaviourLightVehiclesApi
} from 'generated/backend-api';

import {
    Configuration as MainAPIConfiguration,
    UserApi,
    ClientApi,
    TagCategoryApi,
    ApplicationsApi
} from 'generated/main-data-api';

import {
    Configuration as NewMainAPIConfiguration,
    ClientApi as NewClientApi,
    CurrencyApi,
    MonitoredObjectApi,
    PriceListApi,
    ObuApi,
    ServiceApi,
    AddressApi,
    ContactPersonApi,
    CountryApi,
    BundleApi,
    ObuTypeApi,
    ServiceTypeApi,
    MonitoredObjectGroupApi,
    MonitoredObjectGroupTypeApi,
    OrderApi,
    UserApi as NewUserApi,
    MonitoringDeviceApi,
    MonitoringDeviceTypeApi,
    MonitoredObjectTypeApi,
    ExternalSystemApi,
    MonitoredObjectOperationalCostApi as NewMonitoredObjectOperationalCostApi,
    CompanyOperationalCostApi as NewCompanyOperationalCostApi,
    OdometerAdjustmentApi,
    DriverApplicationApi,
    WhiteLabelApi,
    WhiteLabelTemplateApi,
    UserGroupApi,
    UserRightApi,
    UserRoleApi,
    ClientContractApi,
    UserTokenApi
} from 'generated/new-main';

import { Conf } from '../conf';
import { AuthStore } from 'stores/auth/AuthStore';

type Token = () => Promise<string | undefined>;

export class Api {
    readonly conf: Conf['api'];

    readonly addressApi: AddressApi;
    readonly applicationsApi: ApplicationsApi;
    readonly backendApi: BackendBaseAPI;
    readonly bundleApi: BundleApi;
    readonly clientApi: ClientApi;
    readonly clientContract: ClientContractApi;
    readonly contactPersonApi: ContactPersonApi;
    readonly countryApi: CountryApi;
    readonly currencyApi: CurrencyApi;
    readonly externalSystemTypeApi: ExternalSystemApi;
    readonly geocodingApi: GeocodingApi;
    readonly monitoredObjectApi: MonitoredObjectApi;
    readonly monitoredObjectGroupApi: MonitoredObjectGroupApi;
    readonly monitoredObjectGroupTypeApi: MonitoredObjectGroupTypeApi;
    readonly monitoredObjectTypeApi: MonitoredObjectTypeApi;
    readonly monitoringDeviceApi: MonitoringDeviceApi;
    readonly monitoringDeviceTypeApi: MonitoringDeviceTypeApi;
    readonly newClientApi: NewClientApi;
    readonly newUserApi: NewUserApi;
    readonly notifications: NotificationsApi;
    readonly obuApi: ObuApi;
    readonly obuTypeApi: ObuTypeApi;
    readonly odometerAdjustmentApi: OdometerAdjustmentApi;
    readonly orderApi: OrderApi;
    readonly priceListApi: PriceListApi;
    readonly serviceApi: ServiceApi;
    readonly serviceTypeApi: ServiceTypeApi;
    readonly tagGroupApi: TagCategoryApi;
    readonly userApi: UserApi;
    readonly newMonitoredObjectOperationalCostApi: NewMonitoredObjectOperationalCostApi;
    readonly newCompanyOperationalCost: NewCompanyOperationalCostApi;
    readonly driverApplicationApi: DriverApplicationApi;
    readonly tripApi: TripApi;
    readonly driverBehaviourApi: DriverBehaviourApi;
    readonly driverBehaviourLightVehiclesApi: DriverBehaviourLightVehiclesApi;
    readonly costsApi: CostsApi;
    readonly userGroupApi: UserGroupApi;
    readonly userRightApi: UserRightApi;
    readonly userRoleApi: UserRoleApi;
    readonly userTokenApi: UserTokenApi;
    readonly borderCrossingApi: BorderCrossingApi;
    readonly whitelabelApi: WhiteLabelApi;
    readonly whitelabelTemplateApi: WhiteLabelTemplateApi;

    private _token?: Token;
    private _auth?: AuthStore;

    constructor(conf: Conf['api']) {
        this.conf = conf;

        const apiNotificationConf = new NotificationAPIConfiguration(
            this._createConfiguration(this.conf.middlewareUrl)
        );

        const apiBackendConf = new BackendApiConfiguration(this._createConfiguration(this.conf.middlewareUrl));
        const apiGeocodingConf = new GeocodingApiConfiguration(this._createConfiguration(this.conf.middlewareUrl));
        const apiMainConf = new MainAPIConfiguration(this._createConfiguration(this.conf.middlewareUrl));
        const apiNewMainConf = new NewMainAPIConfiguration(this._createConfiguration(this.conf.middlewareUrl));

        this.addressApi = new AddressApi(apiNewMainConf);
        this.applicationsApi = new ApplicationsApi(apiMainConf);
        this.backendApi = new BackendBaseAPI(apiBackendConf);
        this.bundleApi = new BundleApi(apiNewMainConf);
        this.clientApi = new ClientApi(apiMainConf);
        this.clientContract = new ClientContractApi(apiNewMainConf);
        this.contactPersonApi = new ContactPersonApi(apiNewMainConf);
        this.countryApi = new CountryApi(apiNewMainConf);
        this.currencyApi = new CurrencyApi(apiNewMainConf);
        this.externalSystemTypeApi = new ExternalSystemApi(apiNewMainConf);
        this.geocodingApi = new GeocodingApi(apiGeocodingConf);
        this.monitoredObjectApi = new MonitoredObjectApi(apiNewMainConf);
        this.monitoredObjectGroupApi = new MonitoredObjectGroupApi(apiNewMainConf);
        this.monitoredObjectGroupTypeApi = new MonitoredObjectGroupTypeApi(apiNewMainConf);
        this.monitoredObjectTypeApi = new MonitoredObjectTypeApi(apiNewMainConf);
        this.monitoringDeviceApi = new MonitoringDeviceApi(apiNewMainConf);
        this.monitoringDeviceTypeApi = new MonitoringDeviceTypeApi(apiNewMainConf);
        this.newClientApi = new NewClientApi(apiNewMainConf);
        this.newUserApi = new NewUserApi(apiNewMainConf);
        this.notifications = new NotificationsApi(apiNotificationConf);
        this.obuApi = new ObuApi(apiNewMainConf);
        this.obuTypeApi = new ObuTypeApi(apiNewMainConf);
        this.odometerAdjustmentApi = new OdometerAdjustmentApi(apiNewMainConf);
        this.orderApi = new OrderApi(apiNewMainConf);
        this.priceListApi = new PriceListApi(apiNewMainConf);
        this.serviceApi = new ServiceApi(apiNewMainConf);
        this.serviceTypeApi = new ServiceTypeApi(apiNewMainConf);
        this.tagGroupApi = new TagCategoryApi(apiMainConf);
        this.userApi = new UserApi(apiMainConf);
        this.newCompanyOperationalCost = new NewCompanyOperationalCostApi(apiNewMainConf);
        this.newMonitoredObjectOperationalCostApi = new NewMonitoredObjectOperationalCostApi(apiNewMainConf);
        this.driverApplicationApi = new DriverApplicationApi(apiNewMainConf);
        this.tripApi = new TripApi(apiBackendConf);
        this.driverBehaviourApi = new DriverBehaviourApi(apiBackendConf);
        this.driverBehaviourLightVehiclesApi = new DriverBehaviourLightVehiclesApi(apiBackendConf);
        this.costsApi = new CostsApi(apiBackendConf);
        this.borderCrossingApi = new BorderCrossingApi(apiBackendConf);
        this.whitelabelApi = new WhiteLabelApi(apiNewMainConf);
        this.whitelabelTemplateApi = new WhiteLabelTemplateApi(apiNewMainConf);
        this.userGroupApi = new UserGroupApi(apiNewMainConf);
        this.userRightApi = new UserRightApi(apiNewMainConf);
        this.userRoleApi = new UserRoleApi(apiNewMainConf);
        this.userTokenApi = new UserTokenApi(apiNewMainConf);
    }

    init(auth: AuthStore, token?: Token) {
        token && (this._token = token);
        this._auth = auth;
    }

    private _createConfiguration(basePath: string) {
        return {
            basePath,
            middleware: [
                {
                    pre: this._preMiddleware,
                    post: this._postMiddleware
                }
            ]
        };
    }

    private _preMiddleware = async (context: RequestContext): Promise<FetchParams | void> => {
        const token = this._token && (await this._token());
        context.init.headers = {
            ...context.init.headers,
            Authorization: 'Bearer ' + token
        };
        (context.init as any).t0 = performance.now();
        return Promise.resolve(context);
    };

    private _postMiddleware = async (context: ResponseContext): Promise<Response | void> => {
        const t1 = performance.now();
        const t = t1 - (context.init as any).t0;
        const limit = 1e3;
        if (t > limit) {
            console.warn(`API ${context.url || ''} ${t} ms (request exceeded ${limit} ms)\n`);
        }

        const status = context.response.status;
        if (status === 409 && this._auth) {
            let content;

            try {
                content = await context.response.json();
            } catch (err) {
                console.error(err);
            }

            if (content?.errorCode === 8) {
                console.warn(`User will be logged out cause: ${content?.message}`);
                await this._auth.logout();
            }
        }

        return;
    };
}
