import { SearchHistory } from 'logic/searchHistory';
import { Conf } from '../conf';
import { Notification } from './notification-eio';
import { TrackingLogic } from './tracking';
import { VehiclesLogic } from './vehicles';
import { Geocoding } from './geocoding';
import { MapLogic } from './map/map';
import { PoiLogic } from './poi';
import { SchedulingRoutePlannerLogic } from './scheduling-route-planner';
import { SchedulingRouteOverviewLogic } from './scheduling-route-overview';
import { VehiclesStateLogic } from './vehicles-state';
import { Api } from './api';
import { DispatcherBoardLogic } from './dispatcher-board';
import { Settings, SettingsPersist } from './settings';
import { CustomerApiLogic } from './customer-api';
import { AlarmsLogic } from './alarms';
import { ApiService } from '../services/api/ApiService';
import { CompanyLogic } from './company';
import { RootStore, store } from 'stores/RootStore';
import UserEvents from './UserEvents';
import { ShareEtaLogic } from './shareEta';
import { Enums } from './enums';

export class Logic {
    readonly conf: Conf;

    private _settings?: Settings<SettingsPersist>;
    private _map?: MapLogic;
    private _geocoding?: Geocoding;
    private _notification?: Notification;
    private _tracking?: TrackingLogic;
    private _schedulingRoutePlanner?: SchedulingRoutePlannerLogic;
    private _schedulingRouteOverview?: SchedulingRouteOverviewLogic;
    private _vehicles?: VehiclesLogic;
    private _poi?: PoiLogic;
    private _vehiclesState?: VehiclesStateLogic;
    private _api?: Api;
    private _dispatcherBoard?: DispatcherBoardLogic;
    private _customerAPI?: CustomerApiLogic;
    private _alarms?: AlarmsLogic;
    private _searchHistory?: SearchHistory;
    private _companyLogic?: CompanyLogic;
    private _userEvents?: UserEvents;
    private _shareEtaLogic?: ShareEtaLogic;
    private _enums?: Enums;

    constructor(conf: Conf, private _apiService: ApiService, private _store: RootStore) {
        this.conf = conf;
    }

    settings() {
        if (!this._settings) {
            this._settings = new Settings<SettingsPersist>({
                vehicleIdentification: this.conf.settings.vehicleIdentification,
                dispatcherBoardTable: {
                    filter: {
                        vehicles: this.conf.settings.dispatcherBoardTable.filter.vehicles,
                        drivers: this.conf.settings.dispatcherBoardTable.filter.drivers,
                        states: this.conf.settings.dispatcherBoardTable.filter.states
                    },
                    dateRange: {
                        start: this.conf.settings.dispatcherBoardTable.dateRange.start,
                        end: this.conf.settings.dispatcherBoardTable.dateRange.end
                    }
                },
                dispatcherBoardCalendar: this.conf.settings.dispatcherBoardCalendar,
                whatsAppSession: {},
                companyId: null,
                initializedAfterRegistration: false
            });
        }
        return this._settings;
    }

    map() {
        if (!this._map) {
            this._map = new MapLogic(this.conf.map, this, this._store);
        }
        return this._map;
    }

    geocoding() {
        if (!this._geocoding) {
            this._geocoding = new Geocoding(this, store);
        }
        return this._geocoding;
    }

    notification() {
        if (!this._notification) {
            this._notification = new Notification(this.conf.notification);
        }
        return this._notification;
    }

    tracking() {
        if (!this._tracking) {
            this._tracking = new TrackingLogic(this.map(), this, store);
        }
        return this._tracking;
    }

    schedulingRouteOverview() {
        if (!this._schedulingRouteOverview) {
            this._schedulingRouteOverview = new SchedulingRouteOverviewLogic(this, store);
        }
        return this._schedulingRouteOverview;
    }

    schedulingRoutePlanner() {
        if (!this._schedulingRoutePlanner) {
            this._schedulingRoutePlanner = new SchedulingRoutePlannerLogic(
                this.geocoding(),
                this.map(),
                this,
                this._store
            );
        }
        return this._schedulingRoutePlanner;
    }

    vehicles() {
        if (!this._vehicles) {
            this._vehicles = new VehiclesLogic(this);
        }
        return this._vehicles;
    }

    poi() {
        if (!this._poi) {
            this._poi = new PoiLogic(this, this._store);
        }
        return this._poi;
    }

    vehiclesState() {
        if (!this._vehiclesState) {
            this._vehiclesState = new VehiclesStateLogic(this, store);
        }
        return this._vehiclesState;
    }

    api() {
        if (!this._api) {
            this._api = new Api(this.conf.api);
        }
        return this._api;
    }

    dispatcherBoard() {
        if (!this._dispatcherBoard) {
            this._dispatcherBoard = new DispatcherBoardLogic(this, store);
        }
        return this._dispatcherBoard;
    }

    customerAPI() {
        if (!this._customerAPI) {
            this._customerAPI = new CustomerApiLogic(this);
        }
        return this._customerAPI;
    }

    alarms(): AlarmsLogic {
        if (!this._alarms) {
            this._alarms = new AlarmsLogic(this, this._store);
        }
        return this._alarms;
    }

    apiService() {
        return this._apiService;
    }

    searchHistory() {
        if (!this._searchHistory) {
            this._searchHistory = new SearchHistory(this);
        }
        return this._searchHistory;
    }

    company() {
        if (!this._companyLogic) {
            this._companyLogic = new CompanyLogic(this);
        }
        return this._companyLogic;
    }

    userEvents() {
        if (!this._userEvents) {
            this._userEvents = new UserEvents(this, this._store);
        }
        return this._userEvents;
    }

    shareEta() {
        if (!this._shareEtaLogic) {
            this._shareEtaLogic = new ShareEtaLogic(this);
        }
        return this._shareEtaLogic;
    }

    enums() {
        if (!this._enums) {
            this._enums = new Enums(this);
        }
        return this._enums;
    }
}
