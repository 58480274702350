import { ShareEtaCode, ShareEtaCodeState, ShareEtaPatch } from 'services/api/domains/ShareEtaApi';
import { Logic } from './logic';

export interface ShareEtaCodes {
    [driverId: string]: ShareEtaCode;
}

export class ShareEtaLogic {
    constructor(private _logic: Logic) {}

    async createShareEtaCode(driverId: string, validUntil?: string) {
        return await this._logic.apiService().shareEta().createShareEtaCode(driverId, validUntil);
    }

    async fetchShareEtaCodes() {
        const result = await this._logic.apiService().shareEta().fetchShareEtaCodes();

        const shareEtaCodes: ShareEtaCodes = {};
        result.forEach(code => {
            shareEtaCodes[code.driverId] = code;
        });

        return shareEtaCodes;
    }

    async cancelShareEtaCode(code: string) {
        await this._logic
            .apiService()
            .shareEta()
            .patchShareEtaCode({
                code: code,
                state: ShareEtaCodeState.Cancelled
            } as ShareEtaPatch);
    }
}
