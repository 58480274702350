import { Conf } from 'conf';
import { HttpClient } from '../common/HttpClient';
export interface Country {
    code: string;
    name: string;
    iso3: string;
}

const COUNTRY_API_VERSION = '7';

export class CountryApi {
    constructor(private _conf: Conf['api'], private _httpClient: HttpClient) {}

    async countryList(): Promise<Country[]> {
        return this._httpClient.get(`${this._conf.coreUrl}/country`, {
            apiVersion: COUNTRY_API_VERSION,
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }
}
