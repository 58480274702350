import moment, { Moment } from 'moment';
import { DragDropContext, DropResult } from '@hello-pangea/dnd';
import { UserEvent, InitiatedFrom } from 'logic/userEventsProviders/GoogleTagManager';
import CalendarViewGridDatePicker from '../CalendarViewGridDatePicker/CalendarViewGridDatePicker';
import CalendarViewGridDateTime from '../CalendarViewGridDateTime/CalendarViewGridDateTime';
import CalendarViewGridMask from '../CalendarViewGridMask/CalendarViewGridMask';
import { CalendarViewBar } from '../../CalendarViewModule';
import { Logic } from 'logic/logic';
import CalendarViewGridUnassigned from '../CalendarViewGridUnassigned/CalendarViewGridUnassigned';
import CalendarViewGridAssigned from '../CalendarViewGridAssigned/CalendarViewGridAssigned';
import { CompanyVehicle, VehicleStateObject } from 'services/api/domains/VehiclesApi';
import { TransportModel } from 'logic/dispatcher-board';
import CalendarViewAddVehicle from '../CalendarViewAddVehicle/CalendarViewAddVehicle';
import { DispatcherBoardNoVehicles } from 'modules/DispatcherBoardModule/common/DispatcherBoardNoVehicles';
import { Country } from 'services/api/domains/CountryApi';

interface Props {
    logic: Logic;
    daysAfter: number;
    daysBefore: number;
    bar: CalendarViewBar;
    loading: boolean;
    newTransports: TransportModel[];
    otherTransports: TransportModel[];
    rows: CompanyVehicle[];
    startDate: string;
    vehicles: VehicleStateObject[];
    transportDetailId?: string;
    firstTransportPopoverVisible: boolean;
    countryList: Country[];
    onDragTransportEnd: (result: DropResult) => void;
    onStartDateChange: (startDate: Moment) => void;
    onTransportEditClick: (transportId: string, vehicleId?: string) => void;
    onTransportDetailClick: (transport: TransportModel) => void;
    onAssignDriverToVehicle: (vehicle: CompanyVehicle) => void;
    onShowCreateVehicleModal?: (initiatedFrom: InitiatedFrom) => void;
    onOpenDeleteTransportModal: (transport: TransportModel) => void;
    onOpenAssignVehicleModal: (transport: TransportModel) => void;
    onUnassignTransport: (transportId: string) => void;
    onTransportNoteChange: (transportId: string, note: string, cb: VoidFunction) => void;
    onLocalizeVehicle?: (vehicleId?: string) => void;
    onFirstTransportPopoverClose?: () => void;
}

const CalendarViewGrid = (props: Props) => {
    const onPreviousDateChange = () => {
        props?.onStartDateChange(moment(props.startDate).subtract(1, 'day'));
    };

    const onNextDateChange = () => {
        props?.onStartDateChange(moment(props.startDate).add(1, 'day'));
    };

    const onTransportNoteEvent = (event: UserEvent, transportId: string, vehicleId?: number) => {
        props.logic
            .userEvents()
            .note(event, InitiatedFrom.DISPATCH_BOARD_CALENDAR_TRANSPORT_NOTE, undefined, transportId, vehicleId);
    };

    return (
        <div className="rl-calendar-view-grid">
            <div className="rl-calendar-view-grid-head">
                <div className="rl-calendar-view-grid-head-datepicker">
                    <CalendarViewGridDatePicker
                        startDate={props.startDate}
                        daysAfter={props.daysAfter}
                        daysBefore={props.daysBefore}
                        onStartDateChange={props.onStartDateChange}
                        onNextDateChange={onNextDateChange}
                        onPreviousDateChange={onPreviousDateChange}
                    />
                </div>
                <div className={`rl-calendar-view-grid-head-datetime day-${props.bar.daySegment}`}>
                    <CalendarViewGridDateTime
                        startDate={props.startDate}
                        daysAfter={props.daysAfter}
                        daysBefore={props.daysBefore}
                        daySegment={props.bar.daySegment}
                    />
                </div>
            </div>
            <div className="rl-calendar-view-grid-main">
                <DragDropContext onDragEnd={props.onDragTransportEnd}>
                    <CalendarViewGridMask startDate={props.startDate} daySegment={props.bar.daySegment} />
                    {props.bar.expanded && (
                        <CalendarViewGridUnassigned
                            bar={props.bar}
                            vehiclesExist={props.rows.length > 0}
                            logic={props.logic}
                            firstTransportPopoverVisible={props.firstTransportPopoverVisible}
                            newTransports={props.newTransports}
                            startDate={props.startDate}
                            countryList={props.countryList}
                            transportDetailId={props.transportDetailId}
                            onOpenDeleteTransportModal={props.onOpenDeleteTransportModal}
                            onOpenAssignVehicleModal={props.onOpenAssignVehicleModal}
                            onTransportEditClick={props.onTransportEditClick}
                            onUnassignTransport={props.onUnassignTransport}
                            onTransportNoteChange={props.onTransportNoteChange}
                            onTransportNoteEvent={onTransportNoteEvent}
                            onTransportDetailClick={props.onTransportDetailClick}
                            onFirstTransportPopoverClose={props.onFirstTransportPopoverClose}
                        />
                    )}
                    <div className="rl-calendar-view-grid-main-scroll">
                        <CalendarViewGridAssigned
                            bar={props.bar}
                            logic={props.logic}
                            startDate={props.startDate}
                            countryList={props.countryList}
                            otherTransports={props.otherTransports}
                            rows={props.rows}
                            vehicles={props.vehicles}
                            transportDetailId={props.transportDetailId}
                            onAssignDriverToVehicle={props.onAssignDriverToVehicle}
                            onOpenDeleteTransportModal={props.onOpenDeleteTransportModal}
                            onOpenAssignVehicleModal={props.onOpenAssignVehicleModal}
                            onTransportEditClick={props.onTransportEditClick}
                            onUnassignTransport={props.onUnassignTransport}
                            onTransportNoteChange={props.onTransportNoteChange}
                            onTransportNoteEvent={onTransportNoteEvent}
                            onTransportDetailClick={props.onTransportDetailClick}
                            onLocalizeVehicle={props.onLocalizeVehicle}
                        />
                        {props.rows.length > 0 ? (
                            <CalendarViewAddVehicle onShowCreateVehicleModal={props.onShowCreateVehicleModal} />
                        ) : (
                            <DispatcherBoardNoVehicles
                                onShowCreateVehicleModal={() =>
                                    props.onShowCreateVehicleModal?.(InitiatedFrom.DISPATCH_BOARD_ADD_VEHICLE_BUTTON)
                                }
                            />
                        )}
                    </div>
                </DragDropContext>
            </div>
        </div>
    );
};

export default CalendarViewGrid;
