import { VehicleModelMap } from 'logic/map/logic/vehicles';
import { getVehiclePopupStatus } from 'logic/map/utils';

interface Props {
    vehicles: VehicleModelMap[];
    onMouseLeave: () => void;
    onVehicleClick?: (vehicle: VehicleModelMap) => void;
}

export default function VehicleClusterPopup(props: Props) {
    return (
        <div className="rl-vehicle-cluster-popup" onMouseLeave={props.onMouseLeave}>
            {props.vehicles.map((vehicle, index) => {
                const transportState = getVehiclePopupStatus(vehicle.trackingTransport.status);

                return (
                    <div className="rl-vehicle-cluster-popup-row" key={index}>
                        {transportState && <span className={`status status-${transportState}`} />}
                        <a onClick={() => props.onVehicleClick?.(vehicle)}>{vehicle.registrationNumber}</a>
                    </div>
                );
            })}
        </div>
    );
}
