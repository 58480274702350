import cn from 'classnames';
import { useState } from 'react';
import RichEditor from 'components/RichEditor/RichEditor';
import { useTranslation } from 'react-i18next';
import { Spin } from 'components/base/controls';
import Portal from 'components/Portal/Portal';
import React from 'react';
import { TransportModel } from 'logic/dispatcher-board';
import { MouseEvent } from 'react';

interface Props {
    transport: TransportModel;
    visible: boolean;
    onTransportSave: (transportId: string, note: string, cb: VoidFunction) => void;
    onEditorActive?: (active: boolean) => void;
    onAddNoteClick?: () => void;
    onCancelNote?: () => void;
}

const PADDING_RIGHT = 30;
const EDITOR_WIDTH = 600;
const EDITOR_HEIGHT = 150;

const CalendarViewTransportNote = React.forwardRef<HTMLDivElement, Props>((props, noteRef) => {
    const { t } = useTranslation();

    const [edit, setEdit] = useState(false);
    const [loading, setLoading] = useState(false);
    const [content, setContent] = useState(props.transport.note);
    const [position, setPosition] = useState<{ top: number; left: number } | null>(null);
    const [isHover, setIsHover] = useState(false);

    const stopPropagation = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
    };

    const _onSave = (text?: string) => {
        setLoading(true);
        setContent(text);
        props.onTransportSave(props.transport.id, text || '', () => {
            _setEdit(false);
            setLoading(false);
        });
    };

    const _onCancel = () => {
        _setEdit(false);
        props.onCancelNote?.();
    };

    const _setEdit = (active: boolean) => {
        _updatePosition(true);
        setEdit(active);
        setIsHover(false);
        props.onEditorActive?.(active);
    };

    const _updatePosition = (isEdit?: boolean) => {
        const noteEl = (noteRef as any)?.current as HTMLDivElement;
        if (noteEl) {
            const bounds = noteEl.getBoundingClientRect();
            const noteWidth = isEdit ? EDITOR_WIDTH : noteEl.clientWidth + PADDING_RIGHT;
            const noteHeight = isEdit ? EDITOR_HEIGHT : noteEl.clientHeight;
            const left = bounds.left + noteWidth > window.innerWidth ? window.innerWidth - noteWidth : bounds.left;
            const top = bounds.top + noteHeight > window.innerHeight ? window.innerHeight - noteHeight : bounds.top;

            setPosition({ top, left });
        }
    };

    const _mouseLeave = () => {
        if (!edit) {
            setIsHover(false);
            setPosition(null);
        }
    };

    const _mouseEnter = (e: any) => {
        if (e.buttons === 1) {
            //left button is clicked
            return;
        }

        setIsHover(true);
        _updatePosition();
    };

    const _addNote = (e: MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        _setEdit(true);
        props.onAddNoteClick?.();
    };

    const _onClickNote = (e: MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        return !edit && _setEdit(true);
    };

    return (
        <>
            {props.visible && (
                <div
                    className="rl-calendar-view-transport-note"
                    onDoubleClick={stopPropagation}
                    onContextMenu={stopPropagation}
                    onMouseDown={stopPropagation}
                >
                    {!content && !edit && (
                        <div ref={noteRef} className="rl-calendar-view-transport-note-add" onClick={_addNote}>
                            <span>
                                <i className="rl-icon-edit" /> {t('common.addNote')}
                            </span>
                        </div>
                    )}

                    {content && !edit && (
                        <div
                            ref={noteRef}
                            className="rl-calendar-view-transport-note-readonly"
                            onMouseEnter={_mouseEnter}
                        >
                            <RichEditor text={content} readonly={true} />
                        </div>
                    )}

                    {position && (edit || isHover) && (
                        <Portal>
                            <div
                                style={{ top: position.top, left: position.left }}
                                className={'rl-calendar-view-transport-note-portal'}
                                onClick={_onClickNote}
                                onMouseLeave={_mouseLeave}
                            >
                                <div
                                    className={cn({
                                        'rl-calendar-view-transport-note-editor': edit,
                                        'rl-calendar-view-transport-note-readonly': !edit
                                    })}
                                >
                                    <div className="rl-calendar-view-transport-note-edit" onClick={_onClickNote}>
                                        <i className="rl-icon-edit" />
                                    </div>

                                    <Spin loading={loading} opacity={true}>
                                        <RichEditor
                                            text={content}
                                            onSave={_onSave}
                                            onCancel={_onCancel}
                                            readonly={!edit}
                                        />
                                    </Spin>
                                </div>
                            </div>
                        </Portal>
                    )}
                </div>
            )}
        </>
    );
});

export default CalendarViewTransportNote;
