import { Col, Form, Row } from 'antd';
import { Button, Checkbox, Select } from 'components/base/controls';
import { useTranslation } from 'react-i18next';
import Input from 'components/base/controls/Input/Input';
import Radio from 'components/base/controls/Radio/Radio';
import React, { useEffect, useState } from 'react';
import { PoiModel } from 'logic/poi';
import { RadioChangeEvent } from 'antd/lib/radio/interface';
import { FuelStationBrandModel, PlacePost, PlaceType } from 'services/api/domains/PlacesApi';
import { LAT_REGEX, LNG_REGEX } from 'utils/constants/constants';

interface Props {
    poi?: PoiModel;
    fuelStationBrands?: FuelStationBrandModel[];
    loading?: boolean;
    onCreate: (model: PlacePost, addToFavorites: boolean) => void;
    onEnterChangePoiMarkerCoords: (lat: number, lng: number) => void;
}

interface CreateNewPlaceFormModel {
    brandName: string;
    brandId: string;
    name: string;
    address: string;
    latitude: string;
    longitude: string;
    notes: string;
    addToFavorites: boolean;
    category: PlaceType;
}

enum mapIconTypeName {
    COMPANY = 'firm',
    PARKING_LOT = 'parking',
    SHOP = 'shop',
    FOOD_AND_DRINK = 'services',
    GAS_STATION = 'fuelStation'
}

let tempFormName = '';

export function CreateNewPlaceForm(props: Props) {
    const { t } = useTranslation();
    const [latChanged, setLatChanged] = useState(false);
    const [lonChanged, setLonChanged] = useState(false);
    const placeTypes = Object.keys(PlaceType)
        .map(key => PlaceType[key])
        .filter(value => !isNaN(value) && value !== PlaceType.BORDER_CROSSING);
    const [form] = Form.useForm();
    const defaultValues = {
        latitude: props.poi?.center?.lat.toFixed(5),
        longitude: props.poi?.center?.lng.toFixed(5),
        address: props.poi?.address
    };

    const onEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            if (latChanged || lonChanged) {
                event.preventDefault();
                const lat = Number(form.getFieldValue('latitude'));
                const lng = Number(form.getFieldValue('longitude'));
                if (lat && lng) {
                    props.onEnterChangePoiMarkerCoords(lat, lng);
                }
            }
        }
    };

    useEffect(() => {
        form.setFieldsValue(defaultValues);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.poi]);

    function onFinish(values: CreateNewPlaceFormModel) {
        const poi: PlacePost = {
            address: values.address,
            position: {
                lat: Number(latChanged ? values.latitude : props.poi?.center?.lat),
                lon: Number(lonChanged ? values.longitude : props.poi?.center?.lng)
            },
            countryIso2: props.poi?.countryIso!,
            category: values.category,
            id: props.poi?.id!,
            city: props.poi?.city!,
            name: values.name
        };

        if (values.category === PlaceType.GAS_STATION) {
            const brand = props.fuelStationBrands?.find(brand => brand.id === values.brandId);
            poi.brandName = brand ? brand.name : values.name;
            poi.brandId = values.brandId;
        }

        props.onCreate(poi, values.addToFavorites);
    }

    function onPlaceChange(e: RadioChangeEvent) {
        const parkingLabel = t('CreateNewPlaceForm.PARKING_LOT');
        const formName = form.getFieldValue('name');

        if (e.target.value === PlaceType.PARKING_LOT) {
            tempFormName = formName;
            form.setFieldsValue({ name: parkingLabel });
        } else {
            form.setFieldsValue({ name: formName === parkingLabel ? tempFormName : formName });
        }
    }

    return (
        <div className="rl-create-place-form">
            <div className="rl-create-place-form-content">
                <Form form={form} layout="vertical" onFinish={onFinish} id="CreatePlaceForm">
                    <Form.Item name="category" rules={[{ required: true }]}>
                        <Radio.Group className="rl-create-place-form-radio-group" onChange={onPlaceChange}>
                            {placeTypes.map(value => (
                                <Radio.Button value={value} key={value}>
                                    <img
                                        src={`img/place-icons/${mapIconTypeName[PlaceType[value]]}.svg`}
                                        alt="Place icon"
                                    />
                                    {t(`CreateNewPlaceForm.${PlaceType[value]}`)}
                                </Radio.Button>
                            ))}
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, currentValues) => prevValues.category !== currentValues.category}
                    >
                        {({ getFieldValue }) => (
                            <>
                                <Form.Item
                                    label={
                                        getFieldValue('category') === PlaceType.GAS_STATION
                                            ? t('CreateNewPlaceForm.brandName')
                                            : t('CreateNewPlaceForm.name')
                                    }
                                    name="name"
                                    rules={[{ required: getFieldValue('category') !== PlaceType.GAS_STATION }]}
                                    hidden={getFieldValue('category') === PlaceType.GAS_STATION}
                                >
                                    <Input disabled={getFieldValue('category') === PlaceType.PARKING_LOT} />
                                </Form.Item>

                                <Form.Item
                                    label={t('CreateNewPlaceForm.brandName')}
                                    name="brandId"
                                    rules={[{ required: getFieldValue('category') === PlaceType.GAS_STATION }]}
                                    hidden={
                                        !props.fuelStationBrands?.length ||
                                        getFieldValue('category') !== PlaceType.GAS_STATION
                                    }
                                >
                                    <Select
                                        showSearch
                                        filterOption={(input, option) =>
                                            String(option?.value).toLowerCase().indexOf(input.toLowerCase())! >= 0
                                        }
                                    >
                                        {props.fuelStationBrands?.map(brand => (
                                            <Select.Option key={brand.id} value={brand.id}>
                                                <div className="rl-create-place-form-brands-item">
                                                    <img src={brand.logoUrl} alt="Brand icon" width={25} />
                                                    {brand.name}
                                                </div>
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </>
                        )}
                    </Form.Item>

                    <Form.Item label={t('CreateNewPlaceForm.address')} name="address">
                        <Input disabled />
                    </Form.Item>

                    <Row justify="space-between">
                        <Col span={11}>
                            <Form.Item
                                label={t('CreateNewPlaceForm.latitude')}
                                name="latitude"
                                rules={[
                                    { required: true },
                                    {
                                        pattern: LAT_REGEX,
                                        message: t('CreateNewPlaceForm.formValidationError.latitude')
                                    }
                                ]}
                            >
                                <Input.Number step={0.00001} onChange={() => setLatChanged(true)} onKeyDown={onEnter} />
                            </Form.Item>
                        </Col>
                        <Col span={11}>
                            <Form.Item
                                label={t('CreateNewPlaceForm.longitude')}
                                name="longitude"
                                rules={[
                                    { required: true },
                                    {
                                        pattern: LNG_REGEX,
                                        message: t('CreateNewPlaceForm.formValidationError.longitude')
                                    }
                                ]}
                            >
                                <Input.Number step={0.00001} onChange={() => setLonChanged(true)} onKeyDown={onEnter} />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Form.Item label={t('CreateNewPlaceForm.note')} name="note">
                        <Input.TextArea rows={3} />
                    </Form.Item>

                    <Form.Item name="addToFavorites" valuePropName="checked">
                        <Checkbox>{t('CreateNewPlaceForm.addToFavorites')}</Checkbox>
                    </Form.Item>
                </Form>
            </div>
            <div className="rl-create-place-form-actions">
                <Button
                    type="primary"
                    htmlType="submit"
                    form="CreatePlaceForm"
                    loading={props.loading ? { delay: 500 } : false}
                >
                    {t('common.save')}
                </Button>
            </div>
        </div>
    );
}
