import { useTranslation } from 'react-i18next';
import { Button } from '../../../../components/base/controls';
import Row from 'antd/lib/row';
import { Col } from 'antd';
import { PlaceType } from 'services/api/domains/PlacesApi';
import { POI_ICON } from 'utils/constants/constants';

interface Props {
    poiId: string;
    name?: string;
    address?: string;
    logoUrl?: string;
    category: PlaceType;
    rating?: number;
    isFavorite: boolean;
    onPoiDetailClick?: (poiId: string) => void;
    onClose?: () => void;
}

const PoiOverview = (props: Props) => {
    const { t } = useTranslation();

    return (
        <div className="rl-poi-overview">
            <div className="rl-poi-overview-content">
                <Row>
                    <Col span={4}>
                        <Row>
                            <Col>
                                {props.logoUrl ? (
                                    <img src={props.logoUrl} alt="Brand icon" width={40} />
                                ) : (
                                    <img src={`/img/poi-detail/${POI_ICON[props.category]}`} alt="Poi" width={40} />
                                )}
                            </Col>
                        </Row>

                        {(props.rating ?? 0) > 0 && (
                            <Row>
                                <Col flex="20px">
                                    <img src="/img/settings/favorites/rating-star-icon.svg" alt="Rating" />
                                </Col>
                                <Col flex="auto">
                                    <span className="rl-poi-overview-rating">{props.rating}</span>
                                </Col>
                            </Row>
                        )}
                    </Col>

                    <Col span={19}>
                        <Row>
                            <Col span={24}>
                                <div className="rl-poi-overview-name">{props.name}</div>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <div className="rrl-poi-overview-address">{props.address}</div>
                            </Col>
                        </Row>
                    </Col>

                    <Col span={1}>
                        <Button type="icon" onClick={() => props.onClose?.()}>
                            <i className="rl-icon-close" />
                        </Button>
                    </Col>
                </Row>
                <Button
                    type="primary"
                    className="rl-poi-overview-detail-button"
                    onClick={() => props.onPoiDetailClick?.(props.poiId)}
                >
                    {t('PoiOverview.detail')}
                </Button>
            </div>
        </div>
    );
};

export default PoiOverview;
