import { Avoids, TransportModel } from 'modules/PlannerModule/PlannerModule';
import { FuelCardFormModel } from 'modules/SettingsModule/modules/FuelCardsModule/ui/FuelCardsAddEditForm/FuelCardsAddEditForm';
import { duration } from 'moment';
import { FavoriteType } from 'services/api/domains/FavoritesApi';
import { PlaceType } from 'services/api/domains/PlacesApi';
import { ShareEtaCodeState } from 'services/api/domains/ShareEtaApi';
import { CompanyVehicle } from 'services/api/domains/VehiclesApi';
import { RootStore } from 'stores';
import { RegistrationType } from 'stores/auth/types';
import { Logic } from './logic';
import Bloomreach, { EventType, RegistrationEventType, UpdateAction } from './userEventsProviders/Bloomreach';
import { GoogleTagManager, InitiatedFrom, UserEvent } from './userEventsProviders/GoogleTagManager';

interface TransportState {
    toll?: boolean;
    tollCost?: number;
    total?: string;
    avoids: Avoids;
    currency?: string;
    euroRate?: number | null;
    transport?: TransportModel;
}

export interface TransportEventProps {
    toll?: boolean;
    avoids?: boolean;
    wayPointsCount?: number;
    ratePerKm?: number;
    distance?: number;
    duration?: number;
    tollCost?: number;
    total?: number;
    id?: string;
    currency?: string;
    euroRate?: number | null;
}

export interface VehicleEventProps {
    id?: string;
    typeId?: string;
    fuelId?: string;
    emissionClassId?: string;
    adrCodeId?: string;
    hazardousLoadId?: string;
    ratePerKm?: number;
}

export interface FuelCardEventProps {
    brandId?: string;
    discount?: number;
}

export interface PlaceEventProps {
    type?: PlaceType;
    brandId?: string;
}

export interface AssignTransportEventProps {
    transport: { id: string };
    vehicle: { id: string };
}

export interface ShareEtaState {
    sharingStatus?: ShareEtaCodeState;
    driverId?: string;
}

export interface ShareEtaEventProps {
    userId?: string;
    companyId?: number;
    driverId?: string;
    sharingStatus?: string;
    language?: string;
}

export interface UserEventProps {
    id?: string;
    registerType?: RegistrationType;
    role?: string;
    company?: string;
    language?: string;
    currency?: string;
}

export interface CompanyEventProps {
    trucks?: number;
    name?: string;
    id?: string;
    postal?: string;
    country?: string;
}

export interface PageEventProps {
    language?: string;
    platform?: string;
}

export interface NoteEventProps {
    company?: CompanyEventProps;
    user?: UserEventProps;
    transport?: { id: string };
    vehicle?: { id: string | number };
    note?: { linesCount?: number; longestLineLength?: number; totalLength?: number };
}

export enum AlertType {
    FAILED_TOLL_COST = 'failed_toll_cost',
    FAILED_TRANSPORT_LOAD = 'failed_transport_load',
    FAILED_ROUTING = 'failed_routing',
    FAILED_SEARCH = 'failed_search',
    OTHER = 'other'
}

export default class UserEvents {
    private _googleTagManager: GoogleTagManager;
    private _bloomreach: Bloomreach;

    constructor(private _logic: Logic, private _store: RootStore) {
        this._googleTagManager = new GoogleTagManager();
        this._bloomreach = new Bloomreach(this._logic, this._store);
    }

    plannedTransport(obj: TransportState) {
        const transportEvent = this._mapTransportEvent(obj);
        this._googleTagManager.pushEvent({ event: UserEvent.ROUTE_PLANNED, route: transportEvent });
        this._bloomreach.trackEvent('route_planned', transportEvent);
    }

    viewTransport(transportId: string, vehicleId?: string, initiatedFrom?: InitiatedFrom) {
        this._googleTagManager.detailTransportEvent(transportId, vehicleId, initiatedFrom);
        this._bloomreach.view(EventType.TRANSPORT, { transportId, vehicleId, initiatedFrom });
    }

    addTransport(obj: TransportState) {
        const transportEvent = this._mapTransportEvent(obj);
        this._googleTagManager.transportSavedEvent(transportEvent);
        this._bloomreach.add(EventType.TRANSPORT, transportEvent);
    }

    updateTransport(obj: TransportState) {
        const transportEvent = this._mapTransportEvent(obj);
        this._googleTagManager.transportUpdatedEvent(transportEvent);
        this._bloomreach.update(EventType.TRANSPORT, UpdateAction.UPDATE, transportEvent);
    }

    assignTransport(transportId: string, vehicleId: string, initiatedFrom?: InitiatedFrom) {
        const assignTransportEvent = { transport: { id: transportId }, vehicle: { id: vehicleId } };
        this._googleTagManager.transportAssignedToVehicleEvent(assignTransportEvent, initiatedFrom);
        this._bloomreach.update(EventType.TRANSPORT, UpdateAction.ASSIGN, assignTransportEvent);
    }

    assignDriver(driverId: number, vehicleId: number, initiatedFrom?: InitiatedFrom) {
        const company = this._mapCompany();

        this._googleTagManager.pushEvent({
            event: UserEvent.ASSIGN_DRIVER,
            driver: { id: driverId },
            vehicle: { id: vehicleId },
            company: { id: company?.id },
            initiatedFrom
        });

        this._bloomreach.update(EventType.DRIVER, UpdateAction.ASSIGN, {
            driver_id: driverId,
            vehicle_id: vehicleId,
            company_id: company?.id,
            company: company?.name,
            initiated_from: initiatedFrom
        });
    }

    unassignDriver(driverId: number, vehicleId: number, companyId: number) {
        const unassignDriverEvent = {
            driver: { id: driverId },
            vehicle: { id: vehicleId },
            company: { id: companyId }
        };
        this._bloomreach.update(EventType.DRIVER, UpdateAction.UNASSIGN, unassignDriverEvent);
    }

    deleteTransport(transportId: string) {
        const deleteTransport = { transport: { id: transportId } };
        this._bloomreach.delete(EventType.TRANSPORT, deleteTransport);
    }

    viewDriver(initiatedFrom: InitiatedFrom) {
        this._googleTagManager.detailDriverEvent(initiatedFrom);
        this._bloomreach.view(EventType.DRIVER);
    }

    addVehicle(vehicle: CompanyVehicle, initiatedFrom?: InitiatedFrom) {
        const vehicleEvent = this._mapVehicle(vehicle);
        this._googleTagManager.addVehicleEvent(vehicleEvent, initiatedFrom);
        this._bloomreach.add(EventType.VEHICLE, vehicleEvent);
    }

    updateVehicle(vehicle: CompanyVehicle) {
        const vehicleEvent = this._mapVehicle(vehicle);
        this._bloomreach.update(EventType.VEHICLE, UpdateAction.UPDATE, vehicleEvent);
    }

    deleteVehicle(id: number) {
        const vehicleEvent = { id };
        this._bloomreach.delete(EventType.VEHICLE, vehicleEvent);
    }

    addFuelCard(fuelCard: FuelCardFormModel, initiatedFrom?: InitiatedFrom) {
        const fuelCardEvent = { brandId: fuelCard.brandId.toString(), discount: fuelCard.discount };
        this._googleTagManager.addFuelCardEvent(fuelCardEvent, initiatedFrom);
        this._bloomreach.add(EventType.FUEL_CARD, fuelCardEvent);
    }

    updateFuelCard(fuelCard: FuelCardFormModel) {
        const fuelCardEvent = { BrandId: fuelCard.brandId.toString(), Discount: fuelCard.discount };
        this._bloomreach.update(EventType.FUEL_CARD, UpdateAction.UPDATE, fuelCardEvent);
    }

    deleteFuelCard(id: number) {
        const fuelCardEvent = { id };
        this._bloomreach.delete(EventType.FUEL_CARD, fuelCardEvent);
    }

    addNewPlace(category: PlaceType, brandId?: string, initiatedFrom?: InitiatedFrom) {
        const newPlaceEvent = { category, brandId };
        this._googleTagManager.addNewPlaceEvent(newPlaceEvent, initiatedFrom);
        this._bloomreach.add(EventType.NEW_PLACE, newPlaceEvent);
    }

    addFavorite(category: PlaceType, brandId?: string, initiatedFrom?: InitiatedFrom) {
        const favoriteEvent = { category, brandId };
        this._googleTagManager.addFavoriteEvent(favoriteEvent, initiatedFrom);
        this._bloomreach.add(EventType.FAVORITE, favoriteEvent);
    }

    deleteFavourite(category?: FavoriteType) {
        const favoriteEvent = { category };
        this._bloomreach.delete(EventType.FAVORITE, favoriteEvent);
    }

    note(
        event: UserEvent,
        initiatedFrom: InitiatedFrom,
        note?: string,
        transportId?: string,
        vehicleId?: string | number
    ) {
        const noteEvent = this._mapNote(transportId, vehicleId, note);
        this._googleTagManager.noteEvent(event, initiatedFrom, noteEvent);

        if (event === UserEvent.SAVE_NOTE) {
            this._bloomreach.update(EventType.NOTE, UpdateAction.UPDATE, noteEvent);
        }
    }

    shareEtaCreate(data: ShareEtaState, initiatedFrom: InitiatedFrom) {
        const shareEtaEvent = this._mapShareEtaEvent(data);
        this._googleTagManager.shareEtaCreateEvent(shareEtaEvent, initiatedFrom);
        this._bloomreach.update(EventType.SHARE_ETA, UpdateAction.CREATE, shareEtaEvent);
    }

    shareEtaStop(data: ShareEtaState, initiatedFrom: InitiatedFrom) {
        const shareEtaEvent = this._mapShareEtaEvent(data);
        this._googleTagManager.shareEtaStopEvent(shareEtaEvent, initiatedFrom);
        this._bloomreach.update(EventType.SHARE_ETA, UpdateAction.STOP, shareEtaEvent);
    }

    registration() {
        const user = this._mapUser();
        this._googleTagManager.registrationEvent(user);
        this._bloomreach.registration(RegistrationEventType.USER, user);
    }

    companyRegistration() {
        const user = this._mapUser();
        this._googleTagManager.companyRegistrationEvent(user);
        this._bloomreach.companyRegistration(RegistrationEventType.COMPANY, user);
    }

    login() {
        const user = this._mapUser();
        this._googleTagManager.loginEvent(user);
        this._bloomreach.login(user);
    }

    pageReady() {
        const user = this._mapUser();
        const company = this._mapCompany();
        const page = { language: this._store.userSettings.lang, platform: 'Dispatcher' };
        this._googleTagManager.pageReadyEvent(user, page, company);
    }

    alert(type: AlertType, message: string, alertObj?: any) {
        this._googleTagManager.alertMessageEvent(type, message, alertObj);
        this._bloomreach.alert(type, { message, ...alertObj });
    }

    newsLetterConsentUpdate(newslettersAgreement?: boolean) {
        this._bloomreach.newsLetterConsentUpdate(newslettersAgreement);
    }

    inviteDriver() {
        const company = this._mapCompany();
        const user = this._mapUser();

        this._googleTagManager.pushEvent({
            event: UserEvent.INVITE_DRIVER,
            company: { id: company?.id },
            user: { id: user.id }
        });

        this._bloomreach.trackEvent('invitation', {
            type: EventType.DRIVER,
            step: 'initial_popup',
            invitation_type: '1st_screen',
            company_id: company?.id,
            user_id: user.id
        });
    }

    inviteDriverSentBy(initiatedFrom: InitiatedFrom) {
        const company = this._mapCompany();
        const user = this._mapUser();

        this._googleTagManager.pushEvent({
            event: UserEvent.INVITE_DRIVER_SENT_BY,
            company: { id: company?.id },
            user: { id: user.id },
            initiatedFrom
        });

        this._bloomreach.trackEvent('invitation', {
            type: EventType.DRIVER,
            step: 'channel_selection',
            invitation_type: initiatedFrom,
            company_id: company?.id,
            user_id: user.id
        });
    }

    private _mapTransportEvent(stateObj: TransportState): TransportEventProps {
        const transport = stateObj.transport!;
        const costWithoutToll =
            Math.round(transport.costPerKm?.cost! * ((transport?.distance ?? 0) / 1000) * 100) / 100;
        const tollFee = stateObj.tollCost || 0;
        const totalCost = Number((costWithoutToll + tollFee).toFixed(2));
        const distance = parseFloat(Number((transport.distance ?? 0) / 1000).toFixed(1));
        const d = duration(transport.duration ?? 0, 'seconds').asMinutes();

        return {
            toll: stateObj.toll,
            avoids: this._isAvoidsActivated(stateObj.avoids),
            wayPointsCount: transport.places.length,
            ratePerKm: transport.costPerKm?.cost,
            distance: distance,
            duration: d,
            tollCost: stateObj.tollCost,
            total: totalCost,
            id: transport.id,
            currency: stateObj.currency,
            euroRate: stateObj.euroRate
        };
    }

    private _isAvoidsActivated(avoids: Avoids) {
        return Object.values(avoids).some(avoidsObj => Object.values(avoidsObj));
    }

    private _mapVehicle(vehicle: CompanyVehicle): VehicleEventProps {
        return {
            id: vehicle.vehicleId?.toString(),
            typeId: vehicle.vehicleType.toString(),
            fuelId: vehicle.fuelType?.toString(),
            emissionClassId: vehicle.emissionClass?.toString(),
            adrCodeId: vehicle.tunnelCode.toString(),
            hazardousLoadId: vehicle.hazardousLoadType.toString(),
            ratePerKm: vehicle.costPerKm
        };
    }

    private _mapShareEtaEvent(data: ShareEtaState): ShareEtaEventProps {
        const user = this._store.auth.user;
        const company = this._logic.company().getCompany();
        return {
            userId: user?.id,
            companyId: company.companyId,
            driverId: data.driverId,
            sharingStatus: data.sharingStatus,
            language: this._store.userSettings.lang
        };
    }

    private _mapNote(transportId?: string, vehicleId?: string | number, note?: string): NoteEventProps {
        const parsedNotes = note && JSON.parse(note);
        let linesCount, totalLength, longestLineLength, noteLinesLength;
        if (parsedNotes) {
            linesCount = parsedNotes.blocks.length;
            noteLinesLength = parsedNotes.blocks.map((block: { text: string }) => block.text.length);
            totalLength = noteLinesLength.reduce(
                (previousValue: number, currentValue: number) => previousValue + currentValue,
                0
            );
            longestLineLength = Math.max(...noteLinesLength);
        }

        return {
            company: this._mapCompany(),
            user: this._mapUser(),
            transport: transportId ? { id: transportId } : undefined,
            vehicle: vehicleId ? { id: vehicleId } : undefined,
            note: parsedNotes ? { linesCount, longestLineLength, totalLength } : undefined
        };
    }

    private _mapUser(): UserEventProps {
        const user = this._store.auth.user;
        const company = this._logic.company().getCompany();

        return {
            id: user?.id ? user.id : undefined,
            registerType: user?.registerBy,
            role: undefined,
            company: company?.name,
            language: this._store.userSettings.lang,
            currency: this._store.userSettings.currency
        };
    }

    private _mapCompany(): CompanyEventProps | undefined {
        const company = this._logic.company().getCompany();

        if (!company?.fleetSize) {
            return;
        }

        return {
            trucks: company.fleetSize,
            name: company.name,
            id: company.companyId.toString(),
            postal: company.postalCode,
            country: company.country?.code
        };
    }
}
