import { Drawer } from 'components/base/layout';
import { Layers } from 'modules/MapModule/MapModule';
import { useTranslation } from 'react-i18next';
import { PlaceType } from 'services/api/domains/PlacesApi';
import { BarItems, MapControlsBar } from './ui/MapControlsBar';
import { MapSettings } from './ui/MapSettings';

interface Props {
    selectedControl: BarItems | null;
    mapSettingsValues: { display: Layers };
    fuelStationsLayerVisible: boolean;
    parkingsLayerVisible: boolean;
    onFuelStationsToggle: () => void;
    onParkingsToggle: () => void;
    onTrafficChange: (show: boolean) => void;
    onPostalCodesChange: (show: boolean) => void;
    onFavoritesChange: (show: boolean) => void;
    onRestrictionsChange: (checked: boolean) => void;
    onPlacesChange: (category: PlaceType) => void;
    onSidebarClose: () => void;
    onBarItemClick: (clickedItem: BarItems) => void;
}

export function MapControls(props: Props) {
    const { t } = useTranslation();

    return (
        <div className="rl-map-controls">
            <MapControlsBar
                selectedItem={props.selectedControl}
                sidebarVisible={!!props.selectedControl}
                onItemClick={props.onBarItemClick}
            />

            <Drawer
                visible={!!props.selectedControl}
                mask={false}
                getContainer={false}
                onClose={props.onSidebarClose}
                className="rl-map-drawer rl-map-drawer-controls"
                title={t('MapControls.Map.title')}
            >
                <MapSettings
                    values={props.mapSettingsValues.display}
                    fuelStationsLayerVisible={props.fuelStationsLayerVisible}
                    parkingsLayerVisible={props.parkingsLayerVisible}
                    onTrafficChange={props.onTrafficChange}
                    onPostalCodesChange={props.onPostalCodesChange}
                    onFavoritesChange={props.onFavoritesChange}
                    onRestrictionsChange={props.onRestrictionsChange}
                    onPlacesChange={props.onPlacesChange}
                    onParkingsToggle={props.onParkingsToggle}
                    onFuelStationsToggle={props.onFuelStationsToggle}
                />
            </Drawer>
        </div>
    );
}
