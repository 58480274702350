/* tslint:disable */
/* eslint-disable */
/**
 * Geocoding API
 * Telematics services geocoding API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GeoGrid
 */
export interface GeoGrid {
    /**
     * 
     * @type {string}
     * @memberof GeoGrid
     */
    id?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GeoGrid
     */
    x: number;
    /**
     * 
     * @type {number}
     * @memberof GeoGrid
     */
    y: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof GeoGrid
     */
    places: Array<string>;
}

export function GeoGridFromJSON(json: any): GeoGrid {
    return GeoGridFromJSONTyped(json, false);
}

export function GeoGridFromJSONTyped(json: any, ignoreDiscriminator: boolean): GeoGrid {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, '_id') ? undefined : json['_id'],
        'x': json['x'],
        'y': json['y'],
        'places': json['places'],
    };
}

export function GeoGridToJSON(value?: GeoGrid | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        '_id': value.id,
        'x': value.x,
        'y': value.y,
        'places': value.places,
    };
}


