/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WriteOnlyServiceSerializer
 */
export interface WriteOnlyServiceSerializer {
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyServiceSerializer
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof WriteOnlyServiceSerializer
     */
    currency: number;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyServiceSerializer
     */
    oneTimeFee?: string;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyServiceSerializer
     */
    monthlyFee?: string;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyServiceSerializer
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyServiceSerializer
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyServiceSerializer
     */
    label?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyServiceSerializer
     */
    moduleCode?: string | null;
    /**
     * 
     * @type {number}
     * @memberof WriteOnlyServiceSerializer
     */
    trial?: number;
}

export function WriteOnlyServiceSerializerFromJSON(json: any): WriteOnlyServiceSerializer {
    return WriteOnlyServiceSerializerFromJSONTyped(json, false);
}

export function WriteOnlyServiceSerializerFromJSONTyped(json: any, ignoreDiscriminator: boolean): WriteOnlyServiceSerializer {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'currency': json['currency'],
        'oneTimeFee': !exists(json, 'one_time_fee') ? undefined : json['one_time_fee'],
        'monthlyFee': !exists(json, 'monthly_fee') ? undefined : json['monthly_fee'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'label': !exists(json, 'label') ? undefined : json['label'],
        'moduleCode': !exists(json, 'module_code') ? undefined : json['module_code'],
        'trial': !exists(json, 'trial') ? undefined : json['trial'],
    };
}

export function WriteOnlyServiceSerializerToJSON(value?: WriteOnlyServiceSerializer | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'currency': value.currency,
        'one_time_fee': value.oneTimeFee,
        'monthly_fee': value.monthlyFee,
        'description': value.description,
        'name': value.name,
        'label': value.label,
        'module_code': value.moduleCode,
        'trial': value.trial,
    };
}


