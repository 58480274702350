import { useState, useEffect } from 'react';
import { MOBILE_MAX_WIDTH } from 'utils/constants/constants';

const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
};

const getIsMobileWidth = () => {
    return window.innerWidth < MOBILE_MAX_WIDTH;
};

export function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const [isMobileWidth, setIsMobileWidth] = useState(getIsMobileWidth());

    useEffect(() => {
        const handleResize = () => {
            setWindowDimensions(getWindowDimensions());
            setIsMobileWidth(getIsMobileWidth());
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return { windowDimensions, isMobileWidth };
}
