/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DriveDataPartial
 */
export interface DriveDataPartial {
    /**
     * 
     * @type {number}
     * @memberof DriveDataPartial
     */
    driveTime: number;
    /**
     * 
     * @type {number}
     * @memberof DriveDataPartial
     */
    engineOnTime: number;
    /**
     * 
     * @type {number}
     * @memberof DriveDataPartial
     */
    avgSpeed: number;
    /**
     * 
     * @type {number}
     * @memberof DriveDataPartial
     */
    cntTakeoff: number;
    /**
     * 
     * @type {number}
     * @memberof DriveDataPartial
     */
    brakeDistance: number;
    /**
     * 
     * @type {number}
     * @memberof DriveDataPartial
     */
    cruiseControlTime: number;
    /**
     * 
     * @type {number}
     * @memberof DriveDataPartial
     */
    cruiseControlPercentage: number;
    /**
     * 
     * @type {number}
     * @memberof DriveDataPartial
     */
    avgRaising: number;
    /**
     * 
     * @type {number}
     * @memberof DriveDataPartial
     */
    distance: number;
    /**
     * 
     * @type {number}
     * @memberof DriveDataPartial
     */
    spendInDrivingModePercentage: number;
}

export function DriveDataPartialFromJSON(json: any): DriveDataPartial {
    return DriveDataPartialFromJSONTyped(json, false);
}

export function DriveDataPartialFromJSONTyped(json: any, ignoreDiscriminator: boolean): DriveDataPartial {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'driveTime': json['drive_time'],
        'engineOnTime': json['engine_on_time'],
        'avgSpeed': json['avg_speed'],
        'cntTakeoff': json['cnt_takeoff'],
        'brakeDistance': json['brake_distance'],
        'cruiseControlTime': json['cruise_control_time'],
        'cruiseControlPercentage': json['cruise_control_percentage'],
        'avgRaising': json['avg_raising'],
        'distance': json['distance'],
        'spendInDrivingModePercentage': json['spend_in_driving_mode_percentage'],
    };
}

export function DriveDataPartialToJSON(value?: DriveDataPartial | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'drive_time': value.driveTime,
        'engine_on_time': value.engineOnTime,
        'avg_speed': value.avgSpeed,
        'cnt_takeoff': value.cntTakeoff,
        'brake_distance': value.brakeDistance,
        'cruise_control_time': value.cruiseControlTime,
        'cruise_control_percentage': value.cruiseControlPercentage,
        'avg_raising': value.avgRaising,
        'distance': value.distance,
        'spend_in_driving_mode_percentage': value.spendInDrivingModePercentage,
    };
}


