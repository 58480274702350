/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Client,
    ClientFromJSON,
    ClientFromJSONTyped,
    ClientToJSON,
} from '.';

/**
 * 
 * @export
 * @interface ReadOnlyMonitoredObjectGroupType
 */
export interface ReadOnlyMonitoredObjectGroupType {
    /**
     * 
     * @type {number}
     * @memberof ReadOnlyMonitoredObjectGroupType
     */
    readonly id?: number;
    /**
     * 
     * @type {Client}
     * @memberof ReadOnlyMonitoredObjectGroupType
     */
    client?: Client;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyMonitoredObjectGroupType
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyMonitoredObjectGroupType
     */
    shape: string;
    /**
     * 
     * @type {number}
     * @memberof ReadOnlyMonitoredObjectGroupType
     */
    deleted: number;
}

export function ReadOnlyMonitoredObjectGroupTypeFromJSON(json: any): ReadOnlyMonitoredObjectGroupType {
    return ReadOnlyMonitoredObjectGroupTypeFromJSONTyped(json, false);
}

export function ReadOnlyMonitoredObjectGroupTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReadOnlyMonitoredObjectGroupType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'client': !exists(json, 'client') ? undefined : ClientFromJSON(json['client']),
        'name': json['name'],
        'shape': json['shape'],
        'deleted': json['deleted'],
    };
}

export function ReadOnlyMonitoredObjectGroupTypeToJSON(value?: ReadOnlyMonitoredObjectGroupType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'client': ClientToJSON(value.client),
        'name': value.name,
        'shape': value.shape,
        'deleted': value.deleted,
    };
}


