import { useTranslation } from 'react-i18next';
import { MouseEvent } from 'react';

interface Props {
    editorChanged: boolean;
    onSave?: () => void;
    onCancel?: () => void;
}

const RichEditorActions = (props: Props) => {
    const { t } = useTranslation();

    const _onSave = (e: MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        props.onSave?.();
    };

    const _onCancel = (e: MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        props.onCancel?.();
    };

    return (
        <div className="rl-rich-editor-actions">
            {props.editorChanged && props.onSave && (
                <div className="rl-rich-editor-actions-save" onClick={_onSave}>
                    {t('common.save')}
                </div>
            )}
            {props.onCancel && (
                <div className="rl-rich-editor-actions-cancel" onClick={_onCancel}>
                    {t('common.cancel')}
                </div>
            )}
        </div>
    );
};

export default RichEditorActions;
