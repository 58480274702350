import { RouteNames } from 'router/routes';

const MaintenancePage = () => {
    return (
        <div className="rl-maintenance-page">
            <div className="rl-maintenance-page-logo">
                <a href={RouteNames.SCHEDULING_PLANNER}>
                    <img src="/img/rl-dispatcher-logo-dark.svg" alt="RoadLords Dispatcher" />
                </a>
            </div>
            <div className="rl-maintenance-page-content">
                <div className="rl-maintenance-page-content-text">
                    <p className="rl-maintenance-page-content-text-title">Something went wrong</p>
                    <p className="rl-maintenance-page-content-text-description">
                        We’re working hard to fix the problem
                    </p>
                    <p className="rl-maintenance-page-content-text-contact">
                        Contact us at <a href="mailto:help@roadlords.com">help@roadlords.com</a>
                    </p>
                </div>
                <div className="rl-maintenance-page-content-logo">
                    <img src="/img/maintenance-page/rl-maintenance-page.svg" alt="RoadLords Dispatcher" />
                </div>
            </div>
        </div>
    );
};

export default MaintenancePage;
