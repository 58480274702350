/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReadOnlyServiceSerializer
 */
export interface ReadOnlyServiceSerializer {
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyServiceSerializer
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof ReadOnlyServiceSerializer
     */
    currency: number;
    /**
     * 
     * @type {Date}
     * @memberof ReadOnlyServiceSerializer
     */
    readonly deleted?: Date;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyServiceSerializer
     */
    oneTimeFee?: string;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyServiceSerializer
     */
    monthlyFee?: string;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyServiceSerializer
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyServiceSerializer
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyServiceSerializer
     */
    label?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyServiceSerializer
     */
    moduleCode?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReadOnlyServiceSerializer
     */
    trial?: number;
    /**
     * 
     * @type {Date}
     * @memberof ReadOnlyServiceSerializer
     */
    readonly createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ReadOnlyServiceSerializer
     */
    readonly updatedAt?: Date;
}

export function ReadOnlyServiceSerializerFromJSON(json: any): ReadOnlyServiceSerializer {
    return ReadOnlyServiceSerializerFromJSONTyped(json, false);
}

export function ReadOnlyServiceSerializerFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReadOnlyServiceSerializer {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'currency': json['currency'],
        'deleted': !exists(json, 'deleted') ? undefined : (new Date(json['deleted'])),
        'oneTimeFee': !exists(json, 'one_time_fee') ? undefined : json['one_time_fee'],
        'monthlyFee': !exists(json, 'monthly_fee') ? undefined : json['monthly_fee'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'label': !exists(json, 'label') ? undefined : json['label'],
        'moduleCode': !exists(json, 'module_code') ? undefined : json['module_code'],
        'trial': !exists(json, 'trial') ? undefined : json['trial'],
        'createdAt': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
        'updatedAt': !exists(json, 'updated_at') ? undefined : (new Date(json['updated_at'])),
    };
}

export function ReadOnlyServiceSerializerToJSON(value?: ReadOnlyServiceSerializer | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'currency': value.currency,
        'one_time_fee': value.oneTimeFee,
        'monthly_fee': value.monthlyFee,
        'description': value.description,
        'name': value.name,
        'label': value.label,
        'module_code': value.moduleCode,
        'trial': value.trial,
    };
}


