import { Col, Row } from 'antd';
import { Button } from 'components/base/controls';
import { useTranslation } from 'react-i18next';

interface Props {
    onAddVehicleClick: () => void;
}

function FleetBlank(props: Props) {
    const { t } = useTranslation();

    return (
        <div className="rl-settings-blank">
            <img src="/img/settings/fleet/add-vehicle-icon.svg" alt="Vehicle icon" />
            <h2>{t('FleetBlank.title')}</h2>

            <Button
                type="primary"
                size="large"
                icon={<i className="rl-button-icon fas fa-plus" />}
                onClick={props.onAddVehicleClick}
            >
                {t('common.addVehicle')}
            </Button>

            <h3>{t('FleetBlank.title2')}</h3>
            <Row justify="space-between" gutter={48}>
                <Col lg={6} sm={24}>
                    <div className="rl-settings-blank-feature">
                        <img src="/img/settings/fleet/routing-profiles.svg" alt="Routing profiles icon" />
                        <span className="rl-settings-blank-feature-title">{t('FleetBlank.profilesTitle')}</span>
                        <span className="rl-settings-blank-feature-text">{t('FleetBlank.profilesText')}</span>
                    </div>
                </Col>
                <Col lg={6} sm={24}>
                    <div className="rl-settings-blank-feature">
                        <img src="/img/settings/fleet/assign-driver.svg" alt="Assign driver icon" />
                        <span className="rl-settings-blank-feature-title">{t('FleetBlank.assignTitle')}</span>
                        <span className="rl-settings-blank-feature-text">{t('FleetBlank.assignText')}</span>
                    </div>
                </Col>
                <Col lg={6} sm={24}>
                    <div className="rl-settings-blank-feature">
                        <img src="/img/settings/fleet/vehicles-on-map.svg" alt="Vehicles on map icon" />
                        <span className="rl-settings-blank-feature-title">{t('FleetBlank.vehiclesTitle')}</span>
                        <span className="rl-settings-blank-feature-text">{t('FleetBlank.vehiclesText')}</span>
                    </div>
                </Col>
                <Col lg={6} sm={24}>
                    <div className="rl-settings-blank-feature">
                        <img src="/img/settings/fleet/scheduler.svg" alt="Scheduler icon" />
                        <span className="rl-settings-blank-feature-title">{t('FleetBlank.schedulerTitle')}</span>
                        <span className="rl-settings-blank-feature-text">{t('FleetBlank.schedulerText')}</span>
                    </div>
                </Col>
            </Row>
        </div>
    );
}

export default FleetBlank;
