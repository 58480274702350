import { PlacesModel } from 'modules/PlannerModule/PlannerModule';
import { Logic } from './logic';
import { MapPlaceType } from './map/map';

const SEARCH_HISTORY_LOCAL_STORAGE_KEY = 'searchHistory';

export class SearchHistory {
    constructor(private _logic: Logic) {}

    addToSearchHistory(place: PlacesModel) {
        if (place.type === MapPlaceType.Viapoint) {
            return;
        }

        let historyItems: PlacesModel[] = [place];
        const searchHistory = localStorage.getItem(SEARCH_HISTORY_LOCAL_STORAGE_KEY);

        if (searchHistory) {
            historyItems = JSON.parse(searchHistory).slice(-5);

            if (
                !historyItems.some(item => {
                    return item.center.lat === place.center.lat && item.center.lng === place.center.lng;
                })
            ) {
                if (historyItems.length === 5) {
                    historyItems.pop();
                }

                historyItems.unshift(place);
            }
        }

        localStorage.setItem(SEARCH_HISTORY_LOCAL_STORAGE_KEY, JSON.stringify(historyItems));
    }

    getSearchHistory(): PlacesModel[] {
        const searchHistory = localStorage.getItem(SEARCH_HISTORY_LOCAL_STORAGE_KEY);
        return JSON.parse(searchHistory || '[]');
    }
}
