import { NotificationConf } from 'logic/notification-eio';
import { DaysSegmentType } from 'modules/DispatcherBoardModule/common/types/DaySegment';
import moment from 'moment';
import { SocketOptions } from 'socket.io-client';
import { LANGUAGES } from 'utils/constants/constants';
import { DATE_FORMAT } from 'utils/constants/domain-constants';
import { getCurrencyFromLanguage } from 'utils/helpers/currency';

export enum Env {
    DEV = 'DEV',
    TEST = 'TEST',
    PROD = 'PROD'
}

export enum VehicleIdentification {
    RegistrationNumber = 'registrationNumber',
    CustomLabel = 'customLabel'
}

export enum AddressIdentification {
    Code = 'code',
    Address = 'address',
    CodeAndAddress = 'codeAndAddress'
}

const BASE_URL = 'http://localhost:8081';

export const confDefault = {
    env: Env.DEV,
    lang: 'en',
    settings: {
        lang: LANGUAGES.map(l => l.code).includes(navigator.language) ? navigator.language : 'en',
        currency: getCurrencyFromLanguage(navigator.language),
        addressIdentification: 'address' as any,
        vehicleIdentification: VehicleIdentification.RegistrationNumber,
        management: {},
        dispatcherBoardTable: {
            filter: {
                vehicles: [] as string[],
                drivers: [] as string[],
                states: [] as string[]
            },
            dateRange: {
                start: moment().subtract(6, 'days').format(DATE_FORMAT),
                end: moment().add(6, 'days').format(DATE_FORMAT)
            }
        },
        dispatcherBoardCalendar: {
            eta: true,
            notes: false,
            break: true,
            expanded: true,
            startDate: moment().subtract(1, 'day').format(DATE_FORMAT),
            daySegment: DaysSegmentType.day5,
            firstTransportPopoverVisible: true
        }
    },
    auth: {
        simpleAuth: {
            use: true,
            config: {
                authDomain: 'https://auth-testing.api.sygic.com',
                authStatePersistence: 'local',
                clientId:
                    "'w'+([]+[]+[][[]])[(+!+[]+((+!+[])+(+!+[])))]+(typeof [])[(+!+[])]+(RegExp().constructor.name)[((+!+[])+(+!+[]))+(+!+[]+((+!+[])+(+!+[])))]+(![]+[])[((+!+[])+(+!+[]))]+(![]+[])[(+!+[])]+([]+[]+[][[]])[(+!+[])]+([]+[]+[][[]])[(+!+[])]+([]+[]+[][[]])[(+!+[]+((+!+[])+(+!+[])))]+(!![]+[])[(+!+[])]+`.`+(![]+[])[(+!+[]+((+!+[])+(+!+[])))]+(([]).constructor.name)[(+!+[])+(+!+[]+((+!+[])+(+!+[])))]+(typeof ([]+[]))[(+[+!+[]+[+[]]])/((+!+[])+(+!+[]))]+([]+[]+[][[]])[(+[+!+[]+[+[]]])/((+!+[])+(+!+[]))]+(typeof [])[((+!+[])+(+!+[]))*((+!+[])+(+!+[]))]+`.`+(![]+[])[(+!+[])]+(RegExp().constructor.name)[((+!+[])+(+!+[]))+(+!+[]+((+!+[])+(+!+[])))]+(RegExp().constructor.name)[((+!+[])+(+!+[]))+(+!+[]+((+!+[])+(+!+[])))]"
            }
        }
    },
    notification: {
        socketio: {
            uri: 'http://localhost:9000',
            opts: { path: '/engine.io', transports: ['websocket'] } as unknown as SocketOptions
        }
    } as NotificationConf,
    whatsapp_messenger: {
        uri: 'http://localhost:1234',
        path: ''
    },
    api: {
        coreUrl: 'https://core-dev.api.roadlords.com',
        placesUrl: 'https://places-dev.api.roadlords.com/api',
        inviteUrl: 'https://go.roadlords.com/invite',
        tollCostUrl: 'https://tollcost-testing.roadlords.net',
        geocodingUrl: 'https://geocoding-testing.api.sygic.com',
        middlewareUrl: BASE_URL + '/api',
        searchUrl: 'https://search-testing.api.sygic.com/v1/api',
        shareEtaUrl: 'https://drive-dev.api.roadlords.com/v1/shareEta'
    },
    map: {
        initBounds: [
            { lat: 37.84928353292369, lng: -18.851082343750022 },
            { lat: 51.227885597987665, lng: 18.546378593749978 }
        ],
        vehicles: {
            vehicleCenterDistance: 2000
        }
    },
    monitoring: {
        bloomreach: {
            active: false
        },
        recaptcha: {
            siteKey: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI'
        }
    }
};

export type Conf = typeof confDefault;
