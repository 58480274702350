export const KM: string = 'km';
export const M: string = 'm';
export const T: string = 't';
export const H: string = 'h';
export const L: string = 'l';
export const PC: string = 'pc';
export const EUR: string = 'EUR';
export const DATE_FORMAT: string = 'DD.MM.YYYY';
export const DATE_TIME_FORMAT: string = 'DD.MM.YYYY HH:mm';
export const DATE_TIME_FORMAT_SHORT: string = 'DD.MM. HH:mm';
export const TIME_FORMAT_SHORT: string = 'HH:mm';
export const TIME_FORMAT_LONG: string = 'DD:HH:mm';
export const DAILY_PERFORMANCE: number = 46800; // 13h
export const DAILY_RIDE: number = 32400; // 9h
export const MIN_WEEKLY_RIDE: number = 129600; // 36h
export const MAX_WEEKLY_RIDE: number = 201600; // 56h
export const MAX_BIWEEKLY_RIDE: number = 324000; // 90h
export const DAILY_RIDE_RESTING_NEED: number = 16200; // 4h 30m
export const DAILY_RESTING_NEED: number = 2700; // 45m
export const NO_DATA_MOVING: number = 3600; // 1h
export const NO_DATA_STANDING: number = Number.MAX_SAFE_INTEGER;
export const TIME_DATE: string = 'LT MMM D';
