/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WriteOnlyWhiteLabel
 */
export interface WriteOnlyWhiteLabel {
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyWhiteLabel
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof WriteOnlyWhiteLabel
     */
    client: number;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyWhiteLabel
     */
    template: string;
}

export function WriteOnlyWhiteLabelFromJSON(json: any): WriteOnlyWhiteLabel {
    return WriteOnlyWhiteLabelFromJSONTyped(json, false);
}

export function WriteOnlyWhiteLabelFromJSONTyped(json: any, ignoreDiscriminator: boolean): WriteOnlyWhiteLabel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'client': json['client'],
        'template': json['template'],
    };
}

export function WriteOnlyWhiteLabelToJSON(value?: WriteOnlyWhiteLabel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'client': value.client,
        'template': value.template,
    };
}


