import { Form } from 'antd';
import { Button, Checkbox, Radio, Select } from 'components/base/controls';
import { useTranslation } from 'react-i18next';
import selectors from 'qa-selectors';
import { AvailableCurrencies } from 'utils/constants/currencies';
import { Language } from 'utils/constants/constants';
import { AddressIdentification } from 'conf';
import { useEffect, useState } from 'react';
import { useForm } from 'antd/lib/form/Form';

interface Props {
    languages: Language[];
    lang: string;
    currency: AvailableCurrencies;
    addressIdentification: AddressIdentification;
    newslettersAgreement?: boolean;
    savePreferences: (data: PreferencesData) => void;
}

export interface PreferencesData {
    lang: string;
    currency: AvailableCurrencies;
    addressIdentification: AddressIdentification;
    newslettersAgreement: boolean;
}

const ADDRESS_NAMING_OPTIONS = [AddressIdentification.Code, AddressIdentification.Address];

const PreferencesForm = (props: Props) => {
    const { t } = useTranslation();
    const [isTouched, setIsTouched] = useState(false);
    const [form] = useForm();

    useEffect(() => {
        form.resetFields(['newslettersAgreement']);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.newslettersAgreement]);

    function _onFinish(values: PreferencesData) {
        props.savePreferences({
            lang: values.lang,
            currency: values.currency,
            addressIdentification: values.addressIdentification,
            newslettersAgreement: values.newslettersAgreement
        });
    }

    return (
        <Form<PreferencesData>
            form={form}
            className="rl-preferences-form"
            layout="vertical"
            onFinish={_onFinish}
            initialValues={{
                lang: props.lang,
                currency: props.currency,
                addressIdentification: props.addressIdentification,
                newslettersAgreement: props.newslettersAgreement
            }}
            onValuesChange={() => setIsTouched(true)}
        >
            <Form.Item label={t('settings.preferences.language')} name="lang">
                <Select qa={selectors.preferences.languageSelect}>
                    {props.languages.map((lang, i) => (
                        <Select.Option key={i} value={lang.code}>
                            {`${lang.label} - ${lang.name}`}
                        </Select.Option>
                    ))}
                </Select>
            </Form.Item>

            <Form.Item label={t('settings.preferences.currency')} name="currency">
                <Select qa={selectors.preferences.currencySelect}>
                    {Object.keys(AvailableCurrencies).map((code, i) => (
                        <Select.Option key={i} value={code}>
                            {code}
                        </Select.Option>
                    ))}
                </Select>
            </Form.Item>

            <Form.Item label={t('settings.preferences.placingName')} name="addressIdentification">
                <Radio.Group optionType="button" buttonStyle="solid">
                    {ADDRESS_NAMING_OPTIONS.map((key, i) => (
                        <Radio.Button
                            value={key}
                            key={i}
                            data-qa={ADDRESS_NAMING_OPTIONS[i]}
                            style={{ textAlign: 'center' }}
                        >
                            {t('settings.preferences.placingNameType.' + key)}
                        </Radio.Button>
                    ))}
                </Radio.Group>
            </Form.Item>

            <Form.Item
                label={t('settings.preferences.emails.label')}
                name="newslettersAgreement"
                valuePropName="checked"
            >
                <Checkbox>{t('settings.preferences.emails.checkbox')}</Checkbox>
            </Form.Item>

            <Form.Item>
                <Button
                    type="primary"
                    size="large"
                    htmlType="submit"
                    data-qa={selectors.preferences.saveChangesButton}
                    disabled={!isTouched}
                >
                    {t('common.saveChanges')}
                </Button>
            </Form.Item>
        </Form>
    );
};

export default PreferencesForm;
