/* tslint:disable */
/* eslint-disable */
/**
 * Geocoding API
 * Telematics services geocoding API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PlusCode
 */
export interface PlusCode {
    /**
     * is a 4 character area code and 6 character or longer local code (849VCWC8+R9).
     * @type {string}
     * @memberof PlusCode
     */
    globalCode: string;
    /**
     * is a 6 character or longer local code with an explicit location (CWC8+R9, Mountain View, CA, USA).
     * @type {string}
     * @memberof PlusCode
     */
    compoundCode: string;
}

export function PlusCodeFromJSON(json: any): PlusCode {
    return PlusCodeFromJSONTyped(json, false);
}

export function PlusCodeFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlusCode {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'globalCode': json['global_code'],
        'compoundCode': json['compound_code'],
    };
}

export function PlusCodeToJSON(value?: PlusCode | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'global_code': value.globalCode,
        'compound_code': value.compoundCode,
    };
}


