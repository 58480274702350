import { useTranslation } from 'react-i18next';
import { Dropdown, Input, Menu } from '../../../../../../components/base/controls';
import Row from 'antd/lib/row';
import { Col } from 'antd';
import { useState } from 'react';
import { Favorite, FavoriteType } from 'services/api/domains/FavoritesApi';

const img = {
    [FavoriteType.PARKING_LOT]: '/img/settings/favorites/parking-icon.svg',
    [FavoriteType.COMPANY]: '/img/settings/favorites/company-icon.svg',
    [FavoriteType.SHOP]: '/img/settings/favorites/shopping-icon.svg',
    [FavoriteType.GAS_STATION]: '/img/settings/favorites/fuel-station-icon.svg',
    [FavoriteType.FOOD_AND_DRINK]: '/img/settings/favorites/food-icon.svg'
};

interface Props {
    favorite: Favorite;
    onClickCard: (favorite: Favorite) => void;
    onDelete: (favorite: Favorite) => void;
    onRename: (favorite: Favorite) => void;
}

export default function FavoritesCard(props: Props) {
    const { t } = useTranslation();
    const [renameFlag, setRenameFlag] = useState(false);
    const [name, setName] = useState(props.favorite.name);

    const handleKeyPress = (event: any) => {
        if (!!name && event.key === 'Enter') {
            onRenameFavorite();
        }
    };

    const onRenameFavorite = () => {
        if (props.favorite.name !== name) {
            const editedFavorite = {
                ...props.favorite,
                name
            };

            props.onRename(editedFavorite);
        }

        setRenameFlag(false);
    };

    return (
        <div
            className="rl-favorites-card"
            onClick={() => {
                props.onClickCard(props.favorite);
            }}
        >
            <Row>
                <Col span={4}>
                    <Row>
                        <Col>
                            {props.favorite?.brand ? (
                                <img src={props.favorite.brand.logoUrl} alt="Brand icon" width={32} />
                            ) : (
                                <img src={img[props.favorite.type!]} alt="Favorite icon" />
                            )}
                        </Col>
                    </Row>

                    {props.favorite.rating && (
                        <Row>
                            <Col flex="20px">
                                <img src="/img/settings/favorites/rating-star-icon.svg" alt="Rating" />
                            </Col>
                            <Col flex="auto">
                                <span className="rl-favorites-card-rating">{props.favorite.rating}</span>
                            </Col>
                        </Row>
                    )}
                </Col>

                <Col span={18}>
                    <Row>
                        <Col span={24}>
                            {renameFlag ? (
                                <Input
                                    autoFocus={true}
                                    onBlur={onRenameFavorite}
                                    defaultValue={props.favorite.name}
                                    onKeyPress={handleKeyPress}
                                    onChange={e => {
                                        setName(e.target.value);
                                    }}
                                />
                            ) : (
                                <div className="rl-favorites-card-name">{name}</div>
                            )}
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <div className="rl-favorites-card-address">{props.favorite.address}</div>
                        </Col>
                    </Row>
                </Col>

                <Col span={2}>
                    <Dropdown
                        overlay={
                            <Menu
                                onClick={e => {
                                    e.domEvent.stopPropagation();
                                }}
                            >
                                <Menu.Item key="0" onClick={() => setRenameFlag(true)}>
                                    {t('FavoritesCard.rename')}
                                </Menu.Item>
                                <Menu.Item
                                    key="1"
                                    onClick={() => {
                                        props.onDelete(props.favorite);
                                    }}
                                >
                                    {t('FavoritesCard.remove')}
                                </Menu.Item>
                            </Menu>
                        }
                        trigger={['hover']}
                        arrow
                        placement="bottom"
                    >
                        <div
                            className="rl-favorites-card-actions"
                            onClick={e => {
                                e.stopPropagation();
                            }}
                        >
                            <i className="fas fa-ellipsis-h" />
                        </div>
                    </Dropdown>
                </Col>
            </Row>
        </div>
    );
}
