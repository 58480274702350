import { PoiModel } from 'logic/poi';
import { MouseEvent, Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Row, Col } from 'antd';

interface Props extends WithTranslation {
    name: string;
    iconVisible: boolean;
    onShowPoiBarVisible?: (model: PoiModel) => void;
}

class MissingPoiModel implements PoiModel {}

class AddMissingPlace extends Component<Props> {
    render() {
        const { t } = this.props;

        return (
            <Row className="rl-add-missing-place" justify="end" align="stretch" onClick={this._onClick}>
                {this.props.iconVisible && <Col className="rl-add-missing-place-icon" />}
                <Col className="rl-add-missing-place-content">{t('AddMissingPlace.button')}</Col>
            </Row>
        );
    }

    private _onClick = (e: MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();

        const model: PoiModel = new MissingPoiModel();
        model.name = this.props.name;

        this.props.onShowPoiBarVisible?.(model);
    };
}

export default withTranslation()(AddMissingPlace);
