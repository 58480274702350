/* tslint:disable */
/* eslint-disable */
/**
 * Geocoding API
 * Telematics services geocoding API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AddressStructuredType,
    AddressStructuredTypeFromJSON,
    AddressStructuredTypeToJSON,
    GeoGrid,
    GeoGridFromJSON,
    GeoGridToJSON,
    GeoStatsResponse,
    GeoStatsResponseFromJSON,
    GeoStatsResponseToJSON,
    GeoStatsXyResponse,
    GeoStatsXyResponseFromJSON,
    GeoStatsXyResponseToJSON,
    ReverseGeocodeResponse,
    ReverseGeocodeResponseFromJSON,
    ReverseGeocodeResponseToJSON,
    ReverseGeocodeResponseFormatted,
    ReverseGeocodeResponseFormattedFromJSON,
    ReverseGeocodeResponseFormattedToJSON,
} from '../models';

export interface GridRequest {
    x: number;
    y: number;
    distance: number;
}

export interface ReverseRequest {
    x: number;
    y: number;
    lang?: ReverseLangEnum;
    distance?: number;
}

export interface ReverseCustomPlacesRequest {
    lat: number;
    lng: number;
    clientId: number;
}

export interface ReverseFormattedRequest {
    x: number;
    y: number;
    lang?: ReverseFormattedLangEnum;
    distance?: number;
}

export interface ReverseLanguageBatchRequest {
    x: number;
    y: number;
    langs?: Array<ReverseLanguageBatchLangsEnum>;
    distance?: number;
    clientId?: number;
}

export interface ReverseSygicRequest {
    lat: number;
    lng: number;
    lang?: ReverseSygicLangEnum;
}

export interface ReverseWithSygicFormattedRequest {
    x: number;
    y: number;
    lang?: ReverseWithSygicFormattedLangEnum;
    distance?: number;
}

export interface StatsXYRequest {
    x: number;
    y: number;
}

/**
 * no description
 */
export class GeocodingApi extends runtime.BaseAPI {

    /**
     * Get specific grid data
     */
    async gridRaw(requestParameters: GridRequest): Promise<runtime.ApiResponse<Array<GeoGrid>>> {
        if (requestParameters.x === null || requestParameters.x === undefined) {
            throw new runtime.RequiredError('x','Required parameter requestParameters.x was null or undefined when calling grid.');
        }

        if (requestParameters.y === null || requestParameters.y === undefined) {
            throw new runtime.RequiredError('y','Required parameter requestParameters.y was null or undefined when calling grid.');
        }

        if (requestParameters.distance === null || requestParameters.distance === undefined) {
            throw new runtime.RequiredError('distance','Required parameter requestParameters.distance was null or undefined when calling grid.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.x !== undefined) {
            queryParameters['x'] = requestParameters.x;
        }

        if (requestParameters.y !== undefined) {
            queryParameters['y'] = requestParameters.y;
        }

        if (requestParameters.distance !== undefined) {
            queryParameters['distance'] = requestParameters.distance;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("jwt", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/geocoding/grid`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GeoGridFromJSON));
    }

    /**
     * Get specific grid data
     */
    async grid(requestParameters: GridRequest): Promise<Array<GeoGrid>> {
        const response = await this.gridRaw(requestParameters);
        return await response.value();
    }

    /**
     * Google cache + google returns raw google result
     */
    async reverseRaw(requestParameters: ReverseRequest): Promise<runtime.ApiResponse<ReverseGeocodeResponse>> {
        if (requestParameters.x === null || requestParameters.x === undefined) {
            throw new runtime.RequiredError('x','Required parameter requestParameters.x was null or undefined when calling reverse.');
        }

        if (requestParameters.y === null || requestParameters.y === undefined) {
            throw new runtime.RequiredError('y','Required parameter requestParameters.y was null or undefined when calling reverse.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.x !== undefined) {
            queryParameters['x'] = requestParameters.x;
        }

        if (requestParameters.y !== undefined) {
            queryParameters['y'] = requestParameters.y;
        }

        if (requestParameters.lang !== undefined) {
            queryParameters['lang'] = requestParameters.lang;
        }

        if (requestParameters.distance !== undefined) {
            queryParameters['distance'] = requestParameters.distance;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("jwt", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/geocoding/reverse`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReverseGeocodeResponseFromJSON(jsonValue));
    }

    /**
     * Google cache + google returns raw google result
     */
    async reverse(requestParameters: ReverseRequest): Promise<ReverseGeocodeResponse> {
        const response = await this.reverseRaw(requestParameters);
        return await response.value();
    }

    /**
     * Only custom places with lat, lng
     */
    async reverseCustomPlacesRaw(requestParameters: ReverseCustomPlacesRequest): Promise<runtime.ApiResponse<AddressStructuredType>> {
        if (requestParameters.lat === null || requestParameters.lat === undefined) {
            throw new runtime.RequiredError('lat','Required parameter requestParameters.lat was null or undefined when calling reverseCustomPlaces.');
        }

        if (requestParameters.lng === null || requestParameters.lng === undefined) {
            throw new runtime.RequiredError('lng','Required parameter requestParameters.lng was null or undefined when calling reverseCustomPlaces.');
        }

        if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
            throw new runtime.RequiredError('clientId','Required parameter requestParameters.clientId was null or undefined when calling reverseCustomPlaces.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.lat !== undefined) {
            queryParameters['lat'] = requestParameters.lat;
        }

        if (requestParameters.lng !== undefined) {
            queryParameters['lng'] = requestParameters.lng;
        }

        if (requestParameters.clientId !== undefined) {
            queryParameters['clientId'] = requestParameters.clientId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("jwt", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/geocoding/reverse/places`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AddressStructuredTypeFromJSON(jsonValue));
    }

    /**
     * Only custom places with lat, lng
     */
    async reverseCustomPlaces(requestParameters: ReverseCustomPlacesRequest): Promise<AddressStructuredType> {
        const response = await this.reverseCustomPlacesRaw(requestParameters);
        return await response.value();
    }

    /**
     * Google cache + google returns formatted address
     */
    async reverseFormattedRaw(requestParameters: ReverseFormattedRequest): Promise<runtime.ApiResponse<ReverseGeocodeResponseFormatted>> {
        if (requestParameters.x === null || requestParameters.x === undefined) {
            throw new runtime.RequiredError('x','Required parameter requestParameters.x was null or undefined when calling reverseFormatted.');
        }

        if (requestParameters.y === null || requestParameters.y === undefined) {
            throw new runtime.RequiredError('y','Required parameter requestParameters.y was null or undefined when calling reverseFormatted.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.x !== undefined) {
            queryParameters['x'] = requestParameters.x;
        }

        if (requestParameters.y !== undefined) {
            queryParameters['y'] = requestParameters.y;
        }

        if (requestParameters.lang !== undefined) {
            queryParameters['lang'] = requestParameters.lang;
        }

        if (requestParameters.distance !== undefined) {
            queryParameters['distance'] = requestParameters.distance;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("jwt", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/geocoding/reverse/formatted`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReverseGeocodeResponseFormattedFromJSON(jsonValue));
    }

    /**
     * Google cache + google returns formatted address
     */
    async reverseFormatted(requestParameters: ReverseFormattedRequest): Promise<ReverseGeocodeResponseFormatted> {
        const response = await this.reverseFormattedRaw(requestParameters);
        return await response.value();
    }

    /**
     * Google cache with sygic results batch returns formatted results, if client id is present and distance is less than 100 result may contain custom place result
     */
    async reverseLanguageBatchRaw(requestParameters: ReverseLanguageBatchRequest): Promise<runtime.ApiResponse<Array<AddressStructuredType>>> {
        if (requestParameters.x === null || requestParameters.x === undefined) {
            throw new runtime.RequiredError('x','Required parameter requestParameters.x was null or undefined when calling reverseLanguageBatch.');
        }

        if (requestParameters.y === null || requestParameters.y === undefined) {
            throw new runtime.RequiredError('y','Required parameter requestParameters.y was null or undefined when calling reverseLanguageBatch.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.x !== undefined) {
            queryParameters['x'] = requestParameters.x;
        }

        if (requestParameters.y !== undefined) {
            queryParameters['y'] = requestParameters.y;
        }

        if (requestParameters.langs) {
            queryParameters['langs'] = requestParameters.langs;
        }

        if (requestParameters.distance !== undefined) {
            queryParameters['distance'] = requestParameters.distance;
        }

        if (requestParameters.clientId !== undefined) {
            queryParameters['clientId'] = requestParameters.clientId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("jwt", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/geocoding/reverse/batch`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AddressStructuredTypeFromJSON));
    }

    /**
     * Google cache with sygic results batch returns formatted results, if client id is present and distance is less than 100 result may contain custom place result
     */
    async reverseLanguageBatch(requestParameters: ReverseLanguageBatchRequest): Promise<Array<AddressStructuredType>> {
        const response = await this.reverseLanguageBatchRaw(requestParameters);
        return await response.value();
    }

    /**
     * Only sygic with lat, lng
     */
    async reverseSygicRaw(requestParameters: ReverseSygicRequest): Promise<runtime.ApiResponse<AddressStructuredType>> {
        if (requestParameters.lat === null || requestParameters.lat === undefined) {
            throw new runtime.RequiredError('lat','Required parameter requestParameters.lat was null or undefined when calling reverseSygic.');
        }

        if (requestParameters.lng === null || requestParameters.lng === undefined) {
            throw new runtime.RequiredError('lng','Required parameter requestParameters.lng was null or undefined when calling reverseSygic.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.lat !== undefined) {
            queryParameters['lat'] = requestParameters.lat;
        }

        if (requestParameters.lng !== undefined) {
            queryParameters['lng'] = requestParameters.lng;
        }

        if (requestParameters.lang !== undefined) {
            queryParameters['lang'] = requestParameters.lang;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("jwt", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/geocoding/reverse/sygic`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AddressStructuredTypeFromJSON(jsonValue));
    }

    /**
     * Only sygic with lat, lng
     */
    async reverseSygic(requestParameters: ReverseSygicRequest): Promise<AddressStructuredType> {
        const response = await this.reverseSygicRaw(requestParameters);
        return await response.value();
    }

    /**
     * Google cache + google + sygic returns formatted data
     */
    async reverseWithSygicFormattedRaw(requestParameters: ReverseWithSygicFormattedRequest): Promise<runtime.ApiResponse<ReverseGeocodeResponseFormatted>> {
        if (requestParameters.x === null || requestParameters.x === undefined) {
            throw new runtime.RequiredError('x','Required parameter requestParameters.x was null or undefined when calling reverseWithSygicFormatted.');
        }

        if (requestParameters.y === null || requestParameters.y === undefined) {
            throw new runtime.RequiredError('y','Required parameter requestParameters.y was null or undefined when calling reverseWithSygicFormatted.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.x !== undefined) {
            queryParameters['x'] = requestParameters.x;
        }

        if (requestParameters.y !== undefined) {
            queryParameters['y'] = requestParameters.y;
        }

        if (requestParameters.lang !== undefined) {
            queryParameters['lang'] = requestParameters.lang;
        }

        if (requestParameters.distance !== undefined) {
            queryParameters['distance'] = requestParameters.distance;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("jwt", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/geocoding/reverse/sygic/formatted`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReverseGeocodeResponseFormattedFromJSON(jsonValue));
    }

    /**
     * Google cache + google + sygic returns formatted data
     */
    async reverseWithSygicFormatted(requestParameters: ReverseWithSygicFormattedRequest): Promise<ReverseGeocodeResponseFormatted> {
        const response = await this.reverseWithSygicFormattedRaw(requestParameters);
        return await response.value();
    }

    /**
     * - Gets combined stats
     */
    async statsRaw(): Promise<runtime.ApiResponse<GeoStatsResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("jwt", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/geocoding/stats`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GeoStatsResponseFromJSON(jsonValue));
    }

    /**
     * - Gets combined stats
     */
    async stats(): Promise<GeoStatsResponse> {
        const response = await this.statsRaw();
        return await response.value();
    }

    /**
     * - Gets hits for specific point in grid
     */
    async statsXYRaw(requestParameters: StatsXYRequest): Promise<runtime.ApiResponse<GeoStatsXyResponse>> {
        if (requestParameters.x === null || requestParameters.x === undefined) {
            throw new runtime.RequiredError('x','Required parameter requestParameters.x was null or undefined when calling statsXY.');
        }

        if (requestParameters.y === null || requestParameters.y === undefined) {
            throw new runtime.RequiredError('y','Required parameter requestParameters.y was null or undefined when calling statsXY.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.x !== undefined) {
            queryParameters['x'] = requestParameters.x;
        }

        if (requestParameters.y !== undefined) {
            queryParameters['y'] = requestParameters.y;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("jwt", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/geocoding/stats/hits`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GeoStatsXyResponseFromJSON(jsonValue));
    }

    /**
     * - Gets hits for specific point in grid
     */
    async statsXY(requestParameters: StatsXYRequest): Promise<GeoStatsXyResponse> {
        const response = await this.statsXYRaw(requestParameters);
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum ReverseLangEnum {
    Aa = 'aa',
    Ab = 'ab',
    Af = 'af',
    Ak = 'ak',
    Am = 'am',
    An = 'an',
    Ar = 'ar',
    As = 'as',
    Av = 'av',
    Ay = 'ay',
    Az = 'az',
    Ba = 'ba',
    Be = 'be',
    Bg = 'bg',
    Bh = 'bh',
    Bi = 'bi',
    Bm = 'bm',
    Bn = 'bn',
    Bo = 'bo',
    Br = 'br',
    Bs = 'bs',
    Ca = 'ca',
    Ce = 'ce',
    Ch = 'ch',
    Co = 'co',
    Cr = 'cr',
    Cs = 'cs',
    Cu = 'cu',
    Cv = 'cv',
    Cy = 'cy',
    Da = 'da',
    De = 'de',
    Dv = 'dv',
    Dz = 'dz',
    Ee = 'ee',
    El = 'el',
    En = 'en',
    Eo = 'eo',
    Es = 'es',
    Et = 'et',
    Eu = 'eu',
    Fa = 'fa',
    Ff = 'ff',
    Fi = 'fi',
    Fj = 'fj',
    Fo = 'fo',
    Fr = 'fr',
    Fy = 'fy',
    Ga = 'ga',
    Gd = 'gd',
    Gl = 'gl',
    Gn = 'gn',
    Gu = 'gu',
    Gv = 'gv',
    Ha = 'ha',
    He = 'he',
    Hi = 'hi',
    Ho = 'ho',
    Hr = 'hr',
    Ht = 'ht',
    Hu = 'hu',
    Hy = 'hy',
    Hz = 'hz',
    Ia = 'ia',
    Id = 'id',
    Ie = 'ie',
    Ig = 'ig',
    Ii = 'ii',
    Ik = 'ik',
    Io = 'io',
    Is = 'is',
    It = 'it',
    Iu = 'iu',
    Ja = 'ja',
    Jv = 'jv',
    Ka = 'ka',
    Kg = 'kg',
    Ki = 'ki',
    Kj = 'kj',
    Kk = 'kk',
    Kl = 'kl',
    Km = 'km',
    Kn = 'kn',
    Ko = 'ko',
    Kr = 'kr',
    Ks = 'ks',
    Ku = 'ku',
    Kv = 'kv',
    Kw = 'kw',
    Ky = 'ky',
    La = 'la',
    Lb = 'lb',
    Lg = 'lg',
    Li = 'li',
    Ln = 'ln',
    Lo = 'lo',
    Lt = 'lt',
    Lu = 'lu',
    Lv = 'lv',
    Mg = 'mg',
    Mh = 'mh',
    Mi = 'mi',
    Mk = 'mk',
    Ml = 'ml',
    Mn = 'mn',
    Mo = 'mo',
    Mr = 'mr',
    Ms = 'ms',
    Mt = 'mt',
    My = 'my',
    Na = 'na',
    Nb = 'nb',
    Nd = 'nd',
    Ne = 'ne',
    Ng = 'ng',
    Nl = 'nl',
    Nn = 'nn',
    No = 'no',
    Nr = 'nr',
    Nv = 'nv',
    Ny = 'ny',
    Oc = 'oc',
    Oj = 'oj',
    Om = 'om',
    Or = 'or',
    Os = 'os',
    Pa = 'pa',
    Pi = 'pi',
    Pl = 'pl',
    Ps = 'ps',
    Pt = 'pt',
    Qu = 'qu',
    Rm = 'rm',
    Rn = 'rn',
    Ro = 'ro',
    Ru = 'ru',
    Rw = 'rw',
    Sa = 'sa',
    Sc = 'sc',
    Sd = 'sd',
    Se = 'se',
    Sg = 'sg',
    Sh = 'sh',
    Si = 'si',
    Sk = 'sk',
    Sl = 'sl',
    Sm = 'sm',
    Sn = 'sn',
    So = 'so',
    Sq = 'sq',
    Sr = 'sr',
    Ss = 'ss',
    St = 'st',
    Su = 'su',
    Sv = 'sv',
    Sw = 'sw',
    Ta = 'ta',
    Te = 'te',
    Tg = 'tg',
    Th = 'th',
    Ti = 'ti',
    Tk = 'tk',
    Tl = 'tl',
    Tn = 'tn',
    To = 'to',
    Tr = 'tr',
    Ts = 'ts',
    Tt = 'tt',
    Tw = 'tw',
    Ty = 'ty',
    Ug = 'ug',
    Uk = 'uk',
    Ur = 'ur',
    Uz = 'uz',
    Ve = 've',
    Vi = 'vi',
    Vo = 'vo',
    Wa = 'wa',
    Wo = 'wo',
    Xh = 'xh',
    Yi = 'yi',
    Yo = 'yo',
    Za = 'za',
    Zh = 'zh',
    Zu = 'zu'
}
/**
    * @export
    * @enum {string}
    */
export enum ReverseFormattedLangEnum {
    Aa = 'aa',
    Ab = 'ab',
    Af = 'af',
    Ak = 'ak',
    Am = 'am',
    An = 'an',
    Ar = 'ar',
    As = 'as',
    Av = 'av',
    Ay = 'ay',
    Az = 'az',
    Ba = 'ba',
    Be = 'be',
    Bg = 'bg',
    Bh = 'bh',
    Bi = 'bi',
    Bm = 'bm',
    Bn = 'bn',
    Bo = 'bo',
    Br = 'br',
    Bs = 'bs',
    Ca = 'ca',
    Ce = 'ce',
    Ch = 'ch',
    Co = 'co',
    Cr = 'cr',
    Cs = 'cs',
    Cu = 'cu',
    Cv = 'cv',
    Cy = 'cy',
    Da = 'da',
    De = 'de',
    Dv = 'dv',
    Dz = 'dz',
    Ee = 'ee',
    El = 'el',
    En = 'en',
    Eo = 'eo',
    Es = 'es',
    Et = 'et',
    Eu = 'eu',
    Fa = 'fa',
    Ff = 'ff',
    Fi = 'fi',
    Fj = 'fj',
    Fo = 'fo',
    Fr = 'fr',
    Fy = 'fy',
    Ga = 'ga',
    Gd = 'gd',
    Gl = 'gl',
    Gn = 'gn',
    Gu = 'gu',
    Gv = 'gv',
    Ha = 'ha',
    He = 'he',
    Hi = 'hi',
    Ho = 'ho',
    Hr = 'hr',
    Ht = 'ht',
    Hu = 'hu',
    Hy = 'hy',
    Hz = 'hz',
    Ia = 'ia',
    Id = 'id',
    Ie = 'ie',
    Ig = 'ig',
    Ii = 'ii',
    Ik = 'ik',
    Io = 'io',
    Is = 'is',
    It = 'it',
    Iu = 'iu',
    Ja = 'ja',
    Jv = 'jv',
    Ka = 'ka',
    Kg = 'kg',
    Ki = 'ki',
    Kj = 'kj',
    Kk = 'kk',
    Kl = 'kl',
    Km = 'km',
    Kn = 'kn',
    Ko = 'ko',
    Kr = 'kr',
    Ks = 'ks',
    Ku = 'ku',
    Kv = 'kv',
    Kw = 'kw',
    Ky = 'ky',
    La = 'la',
    Lb = 'lb',
    Lg = 'lg',
    Li = 'li',
    Ln = 'ln',
    Lo = 'lo',
    Lt = 'lt',
    Lu = 'lu',
    Lv = 'lv',
    Mg = 'mg',
    Mh = 'mh',
    Mi = 'mi',
    Mk = 'mk',
    Ml = 'ml',
    Mn = 'mn',
    Mo = 'mo',
    Mr = 'mr',
    Ms = 'ms',
    Mt = 'mt',
    My = 'my',
    Na = 'na',
    Nb = 'nb',
    Nd = 'nd',
    Ne = 'ne',
    Ng = 'ng',
    Nl = 'nl',
    Nn = 'nn',
    No = 'no',
    Nr = 'nr',
    Nv = 'nv',
    Ny = 'ny',
    Oc = 'oc',
    Oj = 'oj',
    Om = 'om',
    Or = 'or',
    Os = 'os',
    Pa = 'pa',
    Pi = 'pi',
    Pl = 'pl',
    Ps = 'ps',
    Pt = 'pt',
    Qu = 'qu',
    Rm = 'rm',
    Rn = 'rn',
    Ro = 'ro',
    Ru = 'ru',
    Rw = 'rw',
    Sa = 'sa',
    Sc = 'sc',
    Sd = 'sd',
    Se = 'se',
    Sg = 'sg',
    Sh = 'sh',
    Si = 'si',
    Sk = 'sk',
    Sl = 'sl',
    Sm = 'sm',
    Sn = 'sn',
    So = 'so',
    Sq = 'sq',
    Sr = 'sr',
    Ss = 'ss',
    St = 'st',
    Su = 'su',
    Sv = 'sv',
    Sw = 'sw',
    Ta = 'ta',
    Te = 'te',
    Tg = 'tg',
    Th = 'th',
    Ti = 'ti',
    Tk = 'tk',
    Tl = 'tl',
    Tn = 'tn',
    To = 'to',
    Tr = 'tr',
    Ts = 'ts',
    Tt = 'tt',
    Tw = 'tw',
    Ty = 'ty',
    Ug = 'ug',
    Uk = 'uk',
    Ur = 'ur',
    Uz = 'uz',
    Ve = 've',
    Vi = 'vi',
    Vo = 'vo',
    Wa = 'wa',
    Wo = 'wo',
    Xh = 'xh',
    Yi = 'yi',
    Yo = 'yo',
    Za = 'za',
    Zh = 'zh',
    Zu = 'zu'
}
/**
    * @export
    * @enum {string}
    */
export enum ReverseLanguageBatchLangsEnum {
    Aa = 'aa',
    Ab = 'ab',
    Af = 'af',
    Ak = 'ak',
    Am = 'am',
    An = 'an',
    Ar = 'ar',
    As = 'as',
    Av = 'av',
    Ay = 'ay',
    Az = 'az',
    Ba = 'ba',
    Be = 'be',
    Bg = 'bg',
    Bh = 'bh',
    Bi = 'bi',
    Bm = 'bm',
    Bn = 'bn',
    Bo = 'bo',
    Br = 'br',
    Bs = 'bs',
    Ca = 'ca',
    Ce = 'ce',
    Ch = 'ch',
    Co = 'co',
    Cr = 'cr',
    Cs = 'cs',
    Cu = 'cu',
    Cv = 'cv',
    Cy = 'cy',
    Da = 'da',
    De = 'de',
    Dv = 'dv',
    Dz = 'dz',
    Ee = 'ee',
    El = 'el',
    En = 'en',
    Eo = 'eo',
    Es = 'es',
    Et = 'et',
    Eu = 'eu',
    Fa = 'fa',
    Ff = 'ff',
    Fi = 'fi',
    Fj = 'fj',
    Fo = 'fo',
    Fr = 'fr',
    Fy = 'fy',
    Ga = 'ga',
    Gd = 'gd',
    Gl = 'gl',
    Gn = 'gn',
    Gu = 'gu',
    Gv = 'gv',
    Ha = 'ha',
    He = 'he',
    Hi = 'hi',
    Ho = 'ho',
    Hr = 'hr',
    Ht = 'ht',
    Hu = 'hu',
    Hy = 'hy',
    Hz = 'hz',
    Ia = 'ia',
    Id = 'id',
    Ie = 'ie',
    Ig = 'ig',
    Ii = 'ii',
    Ik = 'ik',
    Io = 'io',
    Is = 'is',
    It = 'it',
    Iu = 'iu',
    Ja = 'ja',
    Jv = 'jv',
    Ka = 'ka',
    Kg = 'kg',
    Ki = 'ki',
    Kj = 'kj',
    Kk = 'kk',
    Kl = 'kl',
    Km = 'km',
    Kn = 'kn',
    Ko = 'ko',
    Kr = 'kr',
    Ks = 'ks',
    Ku = 'ku',
    Kv = 'kv',
    Kw = 'kw',
    Ky = 'ky',
    La = 'la',
    Lb = 'lb',
    Lg = 'lg',
    Li = 'li',
    Ln = 'ln',
    Lo = 'lo',
    Lt = 'lt',
    Lu = 'lu',
    Lv = 'lv',
    Mg = 'mg',
    Mh = 'mh',
    Mi = 'mi',
    Mk = 'mk',
    Ml = 'ml',
    Mn = 'mn',
    Mo = 'mo',
    Mr = 'mr',
    Ms = 'ms',
    Mt = 'mt',
    My = 'my',
    Na = 'na',
    Nb = 'nb',
    Nd = 'nd',
    Ne = 'ne',
    Ng = 'ng',
    Nl = 'nl',
    Nn = 'nn',
    No = 'no',
    Nr = 'nr',
    Nv = 'nv',
    Ny = 'ny',
    Oc = 'oc',
    Oj = 'oj',
    Om = 'om',
    Or = 'or',
    Os = 'os',
    Pa = 'pa',
    Pi = 'pi',
    Pl = 'pl',
    Ps = 'ps',
    Pt = 'pt',
    Qu = 'qu',
    Rm = 'rm',
    Rn = 'rn',
    Ro = 'ro',
    Ru = 'ru',
    Rw = 'rw',
    Sa = 'sa',
    Sc = 'sc',
    Sd = 'sd',
    Se = 'se',
    Sg = 'sg',
    Sh = 'sh',
    Si = 'si',
    Sk = 'sk',
    Sl = 'sl',
    Sm = 'sm',
    Sn = 'sn',
    So = 'so',
    Sq = 'sq',
    Sr = 'sr',
    Ss = 'ss',
    St = 'st',
    Su = 'su',
    Sv = 'sv',
    Sw = 'sw',
    Ta = 'ta',
    Te = 'te',
    Tg = 'tg',
    Th = 'th',
    Ti = 'ti',
    Tk = 'tk',
    Tl = 'tl',
    Tn = 'tn',
    To = 'to',
    Tr = 'tr',
    Ts = 'ts',
    Tt = 'tt',
    Tw = 'tw',
    Ty = 'ty',
    Ug = 'ug',
    Uk = 'uk',
    Ur = 'ur',
    Uz = 'uz',
    Ve = 've',
    Vi = 'vi',
    Vo = 'vo',
    Wa = 'wa',
    Wo = 'wo',
    Xh = 'xh',
    Yi = 'yi',
    Yo = 'yo',
    Za = 'za',
    Zh = 'zh',
    Zu = 'zu'
}
/**
    * @export
    * @enum {string}
    */
export enum ReverseSygicLangEnum {
    Aa = 'aa',
    Ab = 'ab',
    Af = 'af',
    Ak = 'ak',
    Am = 'am',
    An = 'an',
    Ar = 'ar',
    As = 'as',
    Av = 'av',
    Ay = 'ay',
    Az = 'az',
    Ba = 'ba',
    Be = 'be',
    Bg = 'bg',
    Bh = 'bh',
    Bi = 'bi',
    Bm = 'bm',
    Bn = 'bn',
    Bo = 'bo',
    Br = 'br',
    Bs = 'bs',
    Ca = 'ca',
    Ce = 'ce',
    Ch = 'ch',
    Co = 'co',
    Cr = 'cr',
    Cs = 'cs',
    Cu = 'cu',
    Cv = 'cv',
    Cy = 'cy',
    Da = 'da',
    De = 'de',
    Dv = 'dv',
    Dz = 'dz',
    Ee = 'ee',
    El = 'el',
    En = 'en',
    Eo = 'eo',
    Es = 'es',
    Et = 'et',
    Eu = 'eu',
    Fa = 'fa',
    Ff = 'ff',
    Fi = 'fi',
    Fj = 'fj',
    Fo = 'fo',
    Fr = 'fr',
    Fy = 'fy',
    Ga = 'ga',
    Gd = 'gd',
    Gl = 'gl',
    Gn = 'gn',
    Gu = 'gu',
    Gv = 'gv',
    Ha = 'ha',
    He = 'he',
    Hi = 'hi',
    Ho = 'ho',
    Hr = 'hr',
    Ht = 'ht',
    Hu = 'hu',
    Hy = 'hy',
    Hz = 'hz',
    Ia = 'ia',
    Id = 'id',
    Ie = 'ie',
    Ig = 'ig',
    Ii = 'ii',
    Ik = 'ik',
    Io = 'io',
    Is = 'is',
    It = 'it',
    Iu = 'iu',
    Ja = 'ja',
    Jv = 'jv',
    Ka = 'ka',
    Kg = 'kg',
    Ki = 'ki',
    Kj = 'kj',
    Kk = 'kk',
    Kl = 'kl',
    Km = 'km',
    Kn = 'kn',
    Ko = 'ko',
    Kr = 'kr',
    Ks = 'ks',
    Ku = 'ku',
    Kv = 'kv',
    Kw = 'kw',
    Ky = 'ky',
    La = 'la',
    Lb = 'lb',
    Lg = 'lg',
    Li = 'li',
    Ln = 'ln',
    Lo = 'lo',
    Lt = 'lt',
    Lu = 'lu',
    Lv = 'lv',
    Mg = 'mg',
    Mh = 'mh',
    Mi = 'mi',
    Mk = 'mk',
    Ml = 'ml',
    Mn = 'mn',
    Mo = 'mo',
    Mr = 'mr',
    Ms = 'ms',
    Mt = 'mt',
    My = 'my',
    Na = 'na',
    Nb = 'nb',
    Nd = 'nd',
    Ne = 'ne',
    Ng = 'ng',
    Nl = 'nl',
    Nn = 'nn',
    No = 'no',
    Nr = 'nr',
    Nv = 'nv',
    Ny = 'ny',
    Oc = 'oc',
    Oj = 'oj',
    Om = 'om',
    Or = 'or',
    Os = 'os',
    Pa = 'pa',
    Pi = 'pi',
    Pl = 'pl',
    Ps = 'ps',
    Pt = 'pt',
    Qu = 'qu',
    Rm = 'rm',
    Rn = 'rn',
    Ro = 'ro',
    Ru = 'ru',
    Rw = 'rw',
    Sa = 'sa',
    Sc = 'sc',
    Sd = 'sd',
    Se = 'se',
    Sg = 'sg',
    Sh = 'sh',
    Si = 'si',
    Sk = 'sk',
    Sl = 'sl',
    Sm = 'sm',
    Sn = 'sn',
    So = 'so',
    Sq = 'sq',
    Sr = 'sr',
    Ss = 'ss',
    St = 'st',
    Su = 'su',
    Sv = 'sv',
    Sw = 'sw',
    Ta = 'ta',
    Te = 'te',
    Tg = 'tg',
    Th = 'th',
    Ti = 'ti',
    Tk = 'tk',
    Tl = 'tl',
    Tn = 'tn',
    To = 'to',
    Tr = 'tr',
    Ts = 'ts',
    Tt = 'tt',
    Tw = 'tw',
    Ty = 'ty',
    Ug = 'ug',
    Uk = 'uk',
    Ur = 'ur',
    Uz = 'uz',
    Ve = 've',
    Vi = 'vi',
    Vo = 'vo',
    Wa = 'wa',
    Wo = 'wo',
    Xh = 'xh',
    Yi = 'yi',
    Yo = 'yo',
    Za = 'za',
    Zh = 'zh',
    Zu = 'zu'
}
/**
    * @export
    * @enum {string}
    */
export enum ReverseWithSygicFormattedLangEnum {
    Aa = 'aa',
    Ab = 'ab',
    Af = 'af',
    Ak = 'ak',
    Am = 'am',
    An = 'an',
    Ar = 'ar',
    As = 'as',
    Av = 'av',
    Ay = 'ay',
    Az = 'az',
    Ba = 'ba',
    Be = 'be',
    Bg = 'bg',
    Bh = 'bh',
    Bi = 'bi',
    Bm = 'bm',
    Bn = 'bn',
    Bo = 'bo',
    Br = 'br',
    Bs = 'bs',
    Ca = 'ca',
    Ce = 'ce',
    Ch = 'ch',
    Co = 'co',
    Cr = 'cr',
    Cs = 'cs',
    Cu = 'cu',
    Cv = 'cv',
    Cy = 'cy',
    Da = 'da',
    De = 'de',
    Dv = 'dv',
    Dz = 'dz',
    Ee = 'ee',
    El = 'el',
    En = 'en',
    Eo = 'eo',
    Es = 'es',
    Et = 'et',
    Eu = 'eu',
    Fa = 'fa',
    Ff = 'ff',
    Fi = 'fi',
    Fj = 'fj',
    Fo = 'fo',
    Fr = 'fr',
    Fy = 'fy',
    Ga = 'ga',
    Gd = 'gd',
    Gl = 'gl',
    Gn = 'gn',
    Gu = 'gu',
    Gv = 'gv',
    Ha = 'ha',
    He = 'he',
    Hi = 'hi',
    Ho = 'ho',
    Hr = 'hr',
    Ht = 'ht',
    Hu = 'hu',
    Hy = 'hy',
    Hz = 'hz',
    Ia = 'ia',
    Id = 'id',
    Ie = 'ie',
    Ig = 'ig',
    Ii = 'ii',
    Ik = 'ik',
    Io = 'io',
    Is = 'is',
    It = 'it',
    Iu = 'iu',
    Ja = 'ja',
    Jv = 'jv',
    Ka = 'ka',
    Kg = 'kg',
    Ki = 'ki',
    Kj = 'kj',
    Kk = 'kk',
    Kl = 'kl',
    Km = 'km',
    Kn = 'kn',
    Ko = 'ko',
    Kr = 'kr',
    Ks = 'ks',
    Ku = 'ku',
    Kv = 'kv',
    Kw = 'kw',
    Ky = 'ky',
    La = 'la',
    Lb = 'lb',
    Lg = 'lg',
    Li = 'li',
    Ln = 'ln',
    Lo = 'lo',
    Lt = 'lt',
    Lu = 'lu',
    Lv = 'lv',
    Mg = 'mg',
    Mh = 'mh',
    Mi = 'mi',
    Mk = 'mk',
    Ml = 'ml',
    Mn = 'mn',
    Mo = 'mo',
    Mr = 'mr',
    Ms = 'ms',
    Mt = 'mt',
    My = 'my',
    Na = 'na',
    Nb = 'nb',
    Nd = 'nd',
    Ne = 'ne',
    Ng = 'ng',
    Nl = 'nl',
    Nn = 'nn',
    No = 'no',
    Nr = 'nr',
    Nv = 'nv',
    Ny = 'ny',
    Oc = 'oc',
    Oj = 'oj',
    Om = 'om',
    Or = 'or',
    Os = 'os',
    Pa = 'pa',
    Pi = 'pi',
    Pl = 'pl',
    Ps = 'ps',
    Pt = 'pt',
    Qu = 'qu',
    Rm = 'rm',
    Rn = 'rn',
    Ro = 'ro',
    Ru = 'ru',
    Rw = 'rw',
    Sa = 'sa',
    Sc = 'sc',
    Sd = 'sd',
    Se = 'se',
    Sg = 'sg',
    Sh = 'sh',
    Si = 'si',
    Sk = 'sk',
    Sl = 'sl',
    Sm = 'sm',
    Sn = 'sn',
    So = 'so',
    Sq = 'sq',
    Sr = 'sr',
    Ss = 'ss',
    St = 'st',
    Su = 'su',
    Sv = 'sv',
    Sw = 'sw',
    Ta = 'ta',
    Te = 'te',
    Tg = 'tg',
    Th = 'th',
    Ti = 'ti',
    Tk = 'tk',
    Tl = 'tl',
    Tn = 'tn',
    To = 'to',
    Tr = 'tr',
    Ts = 'ts',
    Tt = 'tt',
    Tw = 'tw',
    Ty = 'ty',
    Ug = 'ug',
    Uk = 'uk',
    Ur = 'ur',
    Uz = 'uz',
    Ve = 've',
    Vi = 'vi',
    Vo = 'vo',
    Wa = 'wa',
    Wo = 'wo',
    Xh = 'xh',
    Yi = 'yi',
    Yo = 'yo',
    Za = 'za',
    Zh = 'zh',
    Zu = 'zu'
}
