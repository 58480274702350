export const LabelWithIcon = (props: {
    label?: string;
    icon?: string;
    notificationsCount?: number;
    title?: string;
}) => {
    return (
        <span title={props.title}>
            {props.icon && (
                <>
                    <i className={`rl-icon ${props.icon}`} />
                    {(props.notificationsCount || 0) > 0 && (
                        <span className="rl-navbar-menu-item-badge">
                            <i className="fa fa-circle" />
                            <span>{props.notificationsCount}</span>
                        </span>
                    )}
                </>
            )}
            <span
                className="rl-navbar-menu-item-text"
                data-text={props.label}
                style={{ marginLeft: props.label && props.icon ? '8px' : 0 }}
            >
                {props.label}
            </span>
        </span>
    );
};
