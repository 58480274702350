import cn from 'classnames';
import { Switch } from 'components/base/controls/Switch';
import { useTranslation } from 'react-i18next';
import { CalendarViewBar } from '../../CalendarViewModule';
import BadgeNew from 'components/BadgeNew/BadgeNew';
import { DaysSegmentType } from '../../../common/types/DaySegment';

interface Props {
    bar: CalendarViewBar;
    loading: boolean;
    onDffClick?: () => void;
    onEtaClick: () => void;
    onNotesClick: () => void;
    onExpandClick?: () => void;
    onDaysSegmentClick: (segment: number) => void;
}

const CalendarViewNavbar = (props: Props) => {
    const { t } = useTranslation();

    return (
        <div className="rl-calendar-view-navbar">
            <div className="rl-calendar-view-navbar-day-segment">
                <div className="rl-days-segment">
                    {Object.keys(DaysSegmentType).map(type => {
                        return (
                            <div
                                className={cn('rl-days-segment-item', {
                                    selected: DaysSegmentType[type] === props.bar.daySegment
                                })}
                                key={type}
                                onClick={() => props.onDaysSegmentClick(DaysSegmentType[type])}
                            >
                                {t(`DaysRange.${DaysSegmentType[type]}`)}
                            </div>
                        );
                    })}
                </div>
            </div>
            <div className="rl-calendar-view-navbar-actions">
                <div className="rl-calendar-view-navbar-item">
                    <Switch
                        onChange={() => props.onNotesClick?.()}
                        disabled={props.loading}
                        defaultChecked={props.bar.notes}
                    />
                    <span>{t('DispatcherBoard.notes')}</span>
                    <BadgeNew
                        badgeText={t('BadgeNew.text')}
                        popoverTitle={t('BadgeNew.popoverTitle')}
                        popoverText={t('BadgeNew.popoverText')}
                        // popoverLink={t('BadgeNew.popoverLink')}
                        // onLinkClick={() => {}}
                    />
                </div>
                <div className="rl-calendar-view-navbar-item">
                    <Switch
                        onChange={() => props.onEtaClick?.()}
                        disabled={props.loading}
                        defaultChecked={props.bar.eta}
                    />
                    <span>{t('DispatcherBoard.delay')}</span>
                </div>
                <div className="rl-calendar-view-navbar-item">
                    <Switch
                        onChange={() => props.onExpandClick?.()}
                        disabled={props.loading}
                        defaultChecked={props.bar.expanded}
                    />
                    <span>{t('DispatcherBoard.newTransports')}</span>
                </div>
                {/* <div className="rl-calendar-view-navbar-item">
                <Switch onChange={() => props.onDffClick?.()} disabled={props.loading} />
                <span>{t('DispatcherBoard.dff')}</span>
            </div> */}
            </div>
        </div>
    );
};

export default CalendarViewNavbar;
