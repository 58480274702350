import { Tooltip } from 'components/base/controls/Tooltip';
import cn from 'classnames';
import { DatePicker } from 'components/base/controls/DatePicker';
import moment, { Moment } from 'moment';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PlacesModel, TaskType } from '../../PlannerModule';
import { TaskTypeIcon } from '../PlannerPlacesTask/index';
import { MapPlaceType } from 'logic/map/map';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { SortableContainer } from 'components/base/layout/Sortable/SortableContainer';
import { Button } from 'components/base/controls';

interface Props {
    places: PlacesModel[];
    placeId?: string;
    loading?: boolean;
    sorting: boolean;
    disabled?: boolean;
    onSortStart?: () => void;
    onSortEnd?: (oldIndex: number, newIndex: number) => void;
    onRtaChange?: (id: string, value: Moment) => void;
    onPlaceTaskClick?: (id: string) => void;
    onDeleteClick?: (id: string) => void;
}

const PlacesConfigurator = ({ places = [], ...props }: Props) => {
    const totalDistance = places.reduce((a, c) => a + (c.distance ?? 0), 0);

    function selectPlaceBeforeRta(index: number) {
        return (
            (places[index - 1] &&
                places[index - 1].rta &&
                moment(places[index - 1].rta).add(places[index - 1].duration || undefined)) ||
            undefined
        );
    }

    return (
        <SortableContainer
            id={'places-sortable-container'}
            items={places.map(place => place.id)}
            onSortStart={props.onSortStart}
            onSortEnd={(newIndex, oldIndex) => {
                props.onSortEnd?.(oldIndex, newIndex);
            }}
        >
            <div
                className={cn('rl-places-configurator', {
                    'rl-places-configurator-loading': props.loading
                })}
            >
                {places.map((place, index) =>
                    place.type !== MapPlaceType.Viapoint ? (
                        <Place
                            key={place.id}
                            order={index}
                            count={places.length}
                            placeId={props.placeId}
                            placeBeforeRta={selectPlaceBeforeRta(index)}
                            {...{ index, totalDistance, data: place, places, ...props }}
                        />
                    ) : null
                )}
            </div>
        </SortableContainer>
    );
};

interface PlaceProps extends Props {
    order: number;
    count: number;
    data: PlacesModel;
    totalDistance: number;
    placeBeforeRta?: Moment;
    placeId?: string;
}

const DragHandle = (props: any) => (
    <span {...props} className="rl-places-configurator-place-drag-handle">
        <i className="rl-icon-drag-handler" />
    </span>
);

const Place = ({ data, order, places = [], ...props }: PlaceProps) => {
    const momentRta = moment(data.rta).local();
    const { t } = useTranslation();
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: data.id });

    const style = {
        transform: CSS.Translate.toString(transform),
        transition
    };

    function onRtaChange(value: Moment | null) {
        if (value) {
            props.onRtaChange?.(data.id, value);
        }
    }

    const getTaskIcon = (taskType: TaskType): string | undefined => {
        const taskKey = Object.keys(TaskType).find(t => TaskType[t] === taskType);
        return taskKey ? TaskTypeIcon[taskKey] : undefined;
    };

    const task = data.tasks?.[0];
    const taskType = task?.type;
    const taskIcon = taskType ? getTaskIcon(taskType) : undefined;

    function selectDisabledPlace(value: Moment): boolean {
        const placeBefore = places[order - 1];
        const now = moment().startOf('day');
        const isBefore = value.isBefore(now);
        const isBeforePlaceBeforeRTA = placeBefore ? value.isBefore(moment(placeBefore.rta)) : false;

        return isBefore || isBeforePlaceBeforeRTA;
    }

    const disabled = !!(data.eventStates?.some(e => e.eventRuleName === 'transport_skipping') || data.ata);
    const distance = places.slice(0, order + 1).reduce((a, c) => (a += c.distance ?? 0), 0);
    const distanceLabel = `${distance && parseFloat(Number((distance ?? 0) / 1000).toFixed(1))} ${t('common.km')}`;
    const additionalTimeSec = moment.duration(task?.additionalTimeSec ?? 0, 'seconds');

    return (
        <div
            ref={setNodeRef}
            style={style}
            className={cn('rl-places-configurator-place-wrapper', {
                'rl-places-configurator-place-wrapper-activity': props.placeId === data.id
            })}
        >
            <DragHandle {...attributes} {...listeners} />

            <div
                className={cn('rl-places-configurator-place', {
                    'rl-places-configurator-place-activity': props.placeId === data.id,
                    'rl-places-configurator-place-sorting': props.sorting,
                    'rl-places-configurator-place-disabled': disabled
                })}
            >
                {order ? (
                    <div className="rl-places-configurator-place-date-picker">
                        <div className="rl-places-configurator-place-date">
                            <div className="rl-places-configurator-place-date-time">{momentRta.format('HH:mm')}</div>
                            <div className="rl-places-configurator-place-date-day">{momentRta.format('DD.MM.')}</div>
                        </div>
                    </div>
                ) : (
                    <div className="rl-places-configurator-place-date-picker">
                        <Tooltip title={t('PlannerBar.arrivalTooltip')} placement="topLeft">
                            <DatePicker
                                showTime={{ format: 'HH:mm' }}
                                disabled={disabled}
                                value={momentRta}
                                disabledDate={selectDisabledPlace}
                                showSecond={false}
                                onChange={onRtaChange}
                                inputReadOnly={true}
                                dropdownClassName="rl-places-configurator-place-date-picker-dropdown"
                                trigger={
                                    <div className="rl-places-configurator-place-date">
                                        <div className="rl-places-configurator-place-date-time">
                                            {momentRta.format('HH:mm')}
                                        </div>
                                        <div className="rl-places-configurator-place-date-day">
                                            {momentRta.format('DD.MM.')}
                                        </div>
                                    </div>
                                }
                                panelRender={panelNode => (
                                    <div className="rl-date-picker-panel-node">
                                        <div className="rl-date-picker-panel-node-header">
                                            <Button type="action" icon={<i className="rl-icon-chevron-left" />} />
                                            <h4>{t('PlannerBar.datepickerTitle')}</h4>
                                        </div>
                                        {panelNode}
                                    </div>
                                )}
                            />
                        </Tooltip>
                    </div>
                )}

                <div className="rl-places-configurator-place-icon">
                    {taskIcon ? (
                        <img src={taskIcon ?? '/img/icn-rl-actions.svg'} alt="tasks" />
                    ) : (
                        <i className="fa fa-map-marker" />
                    )}
                </div>

                <div className="rl-places-configurator-place-info">
                    <div className="rl-places-configurator-place-info-distance-and-icons">
                        <div className="rl-places-configurator-place-info-distance" title={distanceLabel}>
                            {distanceLabel}
                        </div>
                        <div className="rl-places-configurator-place-info-icons">
                            {!!task?.additionalTimeSec && (
                                <span
                                    className="rl-places-configurator-place-info-icon text-icon"
                                    title={t('PlannerPlacesTask.additionalTime')}
                                >
                                    {additionalTimeSec.format('H[hr] m[min]')}
                                </span>
                            )}

                            {!!task?.action && (
                                <span
                                    className="rl-places-configurator-place-info-icon"
                                    title={t('PlannerPlacesTask.instructionsForDriver')}
                                >
                                    <i className="rl-icon-note" />
                                </span>
                            )}

                            {!!data.alarms.length && (
                                <span
                                    className="rl-places-configurator-place-info-icon"
                                    title={t('PlannerAlarms.title')}
                                >
                                    <i className="rl-icon-notification" />
                                </span>
                            )}
                        </div>
                    </div>
                    <div className="rl-places-configurator-place-info-name">{data.name}</div>
                </div>

                <div className="rl-places-configurator-place-info-name">{data.name}</div>

                <div className="rl-places-configurator-place-actions">
                    <Button
                        type="action"
                        className="rl-places-configurator-place-actions-close"
                        icon={<i className="rl-icon-close" />}
                        onClick={() => props.onDeleteClick?.(data.id)}
                    />
                    <Button
                        type="action"
                        className="rl-places-configurator-place-actions-chevron-right"
                        icon={<i className="rl-icon-chevron-right" />}
                        onClick={() => props.onPlaceTaskClick?.(data.id)}
                    />
                </div>
            </div>
        </div>
    );
};

const PlacesConfiguratorSorting = ({ onSortEnd, ...props }: Omit<Props, 'sorting'>) => {
    const [sorting, setIsSorting] = useState(false);

    function handleSortStart() {
        setIsSorting(true);
    }

    function handleSortEnd(oldIndex: number, newIndex: number) {
        setIsSorting(false);
        onSortEnd?.(oldIndex, newIndex);
    }

    return <PlacesConfigurator onSortStart={handleSortStart} onSortEnd={handleSortEnd} sorting={sorting} {...props} />;
};

export default PlacesConfiguratorSorting;
