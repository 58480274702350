/* tslint:disable */
/* eslint-disable */
/**
 * Telematics API
 * Telematics services API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AssetType
 */
export interface AssetType {
    /**
     * 
     * @type {number}
     * @memberof AssetType
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof AssetType
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof AssetType
     */
    tableName: string;
}

export function AssetTypeFromJSON(json: any): AssetType {
    return AssetTypeFromJSONTyped(json, false);
}

export function AssetTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): AssetType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'tableName': json['table_name'],
    };
}

export function AssetTypeToJSON(value?: AssetType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'table_name': value.tableName,
    };
}


