import { Logic } from 'logic/logic';
import { AlertType, UserEventProps } from 'logic/UserEvents';
import moment from 'moment';
import { RootStore } from 'stores';

export enum UpdateAction {
    UPDATE = 'update',
    DELETE = 'delete',
    STOP = 'stop',
    CREATE = 'create',
    ASSIGN = 'assign',
    UNASSIGN = 'unassign'
}

export enum EventType {
    TRANSPORT = 'transport',
    DRIVER = 'driver',
    VEHICLE = 'vehicle',
    FUEL_CARD = 'fuel_card',
    NEW_PLACE = 'new_place',
    FAVORITE = 'favorite',
    NOTE = 'note',
    SHARE_ETA = 'share_eta'
}

export enum RegistrationEventType {
    USER = 'user',
    COMPANY = 'company'
}

export default class Bloomreach {
    constructor(private _logic: Logic, private _store: RootStore) {}

    newsLetterConsentUpdate(newslettersAgreement?: boolean) {
        this.trackEvent('consent', {
            action: newslettersAgreement ? 'accept' : 'reject',
            category: 'newsletter_ew',
            valid_until: moment().add(3, 'years').unix()
        });
    }

    view(type: EventType, obj?: any) {
        this.trackEvent('view', { type, ...obj });
    }

    add(type: EventType, obj: any) {
        this.trackEvent('add', { type, ...obj });
    }

    update(type: EventType, action: UpdateAction, obj: any) {
        this.trackEvent('update', { type, action, ...obj });
    }

    delete(type: EventType, obj: any) {
        this.trackEvent('delete', { type, ...obj });
    }

    registration(type: RegistrationEventType, user: UserEventProps) {
        this.trackEvent('registration', { type, ...user });
    }

    companyRegistration(type: RegistrationEventType, user: UserEventProps) {
        this.trackEvent('registration', { type, ...user });
    }

    login(user: UserEventProps) {
        this.trackEvent('login', user);
    }

    alert(type: AlertType, alertObj: any) {
        this.trackEvent('alert', { type, ...alertObj });
    }

    trackEvent(eventType: string, data?: any) {
        if (!this._logic.conf.monitoring.bloomreach.active) {
            return;
        }

        // https://documentation.bloomreach.com/engagement/docs/tracking
        const infinario = (window as any).infinario; // infinario(exponea) is initialized from GTM
        const email = this._store.auth.user?.email;
        const language = this._store.userSettings.lang;
        const company = this._logic.company().getCompany()?.name;

        if (!infinario || !email) {
            return;
        }

        try {
            infinario.identify(email);
            infinario.update({ email, language, company });
            infinario.track(eventType, data);
        } catch (err) {
            console.error('Infinario error:', err);
        }
    }
}
