/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BorderCrossingEntry
 */
export interface BorderCrossingEntry {
    /**
     * 
     * @type {string}
     * @memberof BorderCrossingEntry
     */
    monitoredObjectId: string;
    /**
     * 
     * @type {string}
     * @memberof BorderCrossingEntry
     */
    clientId: string;
    /**
     * 
     * @type {string}
     * @memberof BorderCrossingEntry
     */
    countryEntry: string;
    /**
     * 
     * @type {string}
     * @memberof BorderCrossingEntry
     */
    countryExit: string;
    /**
     * 
     * @type {number}
     * @memberof BorderCrossingEntry
     */
    driverId?: number;
    /**
     * 
     * @type {number}
     * @memberof BorderCrossingEntry
     */
    distance?: number;
    /**
     * 
     * @type {Date}
     * @memberof BorderCrossingEntry
     */
    start: Date;
    /**
     * 
     * @type {Array<object>}
     * @memberof BorderCrossingEntry
     */
    pointEntry?: Array<object>;
    /**
     * 
     * @type {Array<object>}
     * @memberof BorderCrossingEntry
     */
    pointExit?: Array<object>;
    /**
     * 
     * @type {Date}
     * @memberof BorderCrossingEntry
     */
    end?: Date;
    /**
     * 
     * @type {number}
     * @memberof BorderCrossingEntry
     */
    duration: number;
    /**
     * 
     * @type {Array<object>}
     * @memberof BorderCrossingEntry
     */
    addressEntry?: Array<object>;
    /**
     * 
     * @type {Array<object>}
     * @memberof BorderCrossingEntry
     */
    addressExit?: Array<object>;
}

export function BorderCrossingEntryFromJSON(json: any): BorderCrossingEntry {
    return BorderCrossingEntryFromJSONTyped(json, false);
}

export function BorderCrossingEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): BorderCrossingEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'monitoredObjectId': json['monitored_object_id'],
        'clientId': json['client_id'],
        'countryEntry': json['country_entry'],
        'countryExit': json['country_exit'],
        'driverId': !exists(json, 'driver_id') ? undefined : json['driver_id'],
        'distance': !exists(json, 'distance') ? undefined : json['distance'],
        'start': (new Date(json['start'])),
        'pointEntry': !exists(json, 'point_entry') ? undefined : json['point_entry'],
        'pointExit': !exists(json, 'point_exit') ? undefined : json['point_exit'],
        'end': !exists(json, 'end') ? undefined : (new Date(json['end'])),
        'duration': json['duration'],
        'addressEntry': !exists(json, 'address_entry') ? undefined : json['address_entry'],
        'addressExit': !exists(json, 'address_exit') ? undefined : json['address_exit'],
    };
}

export function BorderCrossingEntryToJSON(value?: BorderCrossingEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'monitored_object_id': value.monitoredObjectId,
        'client_id': value.clientId,
        'country_entry': value.countryEntry,
        'country_exit': value.countryExit,
        'driver_id': value.driverId,
        'distance': value.distance,
        'start': (value.start.toISOString()),
        'point_entry': value.pointEntry,
        'point_exit': value.pointExit,
        'end': value.end === undefined ? undefined : (value.end.toISOString()),
        'duration': value.duration,
        'address_entry': value.addressEntry,
        'address_exit': value.addressExit,
    };
}


