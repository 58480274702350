import { Col, Row } from 'antd';
import { Button, Dropdown, Menu, Tooltip } from 'components/base/controls';
import { Drawer } from 'components/base/layout';
import RichEditor from 'components/RichEditor/RichEditor';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import { TransportModel } from 'logic/dispatcher-board';
import { TaskType } from 'modules/PlannerModule/PlannerModule';
import { TaskTypeIcon } from 'modules/PlannerModule/ui/PlannerPlacesTask';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { TransportState } from 'services/api/domains/TransportsApi';
import { CompanyVehicle } from 'services/api/domains/VehiclesApi';
import { DATE_FORMAT, TIME_FORMAT_SHORT } from 'utils/constants/domain-constants';
import { remainingTimeDurationFormat } from 'utils/helpers/formatters';

interface Props {
    visible: boolean;
    transport: TransportModel;
    vehicle?: CompanyVehicle;
    onClose?: () => void;
    onShareEta?: () => void;
    onEditTransport?: (transportId: string, vehicleId?: string) => void;
    onDeleteTransport?: (transport: TransportModel) => void;
    onLocalizeVehicle?: (vehicleId?: string) => void;
}

const TransportDetailSidebar = (props: Props) => {
    const { t } = useTranslation();
    const { isMobileWidth } = useWindowDimensions();

    const eta = props.transport.eta;
    const remainingDistance = props.transport.remainingDistance;

    const getTaskIcon = (taskType: TaskType): string | undefined => {
        const taskKey = Object.keys(TaskType).find(t => TaskType[t] === taskType);
        return taskKey ? TaskTypeIcon[taskKey] : undefined;
    };

    return (
        <Drawer
            className="rl-transport-detail"
            title={t('TransportDetail.title')}
            visible={props.visible}
            width={432}
            destroyOnClose={true}
            placement="right"
            mask={false}
            closable={isMobileWidth}
            onClose={() => props.onClose?.()}
            extra={
                <div className="rl-transport-detail-header">
                    <Tooltip placement="left" title={t('TransportDetail.editTransport')}>
                        <Button
                            type="action"
                            onClick={() =>
                                props.onEditTransport?.(props.transport.id, props.vehicle?.vehicleId?.toString())
                            }
                        >
                            <i className="rl-icon-edit" />
                        </Button>
                    </Tooltip>
                    {[TransportState.New, TransportState.Assigned].includes(props.transport.state) && (
                        <Dropdown
                            trigger={['click']}
                            placement={'bottomRight'}
                            arrow
                            overlay={
                                <Menu onClick={menuInfo => menuInfo.domEvent.stopPropagation()}>
                                    <Menu.Item key="2" onClick={() => props.onDeleteTransport?.(props.transport)}>
                                        {t('common.delete')}
                                    </Menu.Item>
                                </Menu>
                            }
                        >
                            <Button type="action">
                                <i className="rl-icon-more" />
                            </Button>
                        </Dropdown>
                    )}
                    {!isMobileWidth && (
                        <Button type="action" onClick={() => props.onClose?.()}>
                            <i className="rl-icon-close" />
                        </Button>
                    )}
                </div>
            }
        >
            <div className="rl-transport-detail-wrapper">
                {props.transport.state !== TransportState.New && (
                    <div className="eta-info">
                        <Row gutter={[40, 10]}>
                            <Col>
                                <div className="eta-info-text green">{remainingTimeDurationFormat(eta)}</div>
                                <div className="eta-info-label">{t('TrackingDetailSidebar.remainingTime')}</div>
                            </Col>
                            {remainingDistance && (
                                <Col>
                                    <div className="eta-info-text">
                                        {remainingDistance ? (remainingDistance / 1000).toFixed(0) : 0} km
                                    </div>
                                    <div className="eta-info-label">{t('TrackingDetailSidebar.remainingDistance')}</div>
                                </Col>
                            )}
                            {eta && (
                                <Col>
                                    <div className="eta-info-text">
                                        <span>{moment(eta).format('LT')}</span>
                                        <span className="date">{moment(eta).format(DATE_FORMAT)}</span>
                                    </div>
                                    <div className="eta-info-label">{t('TrackingDetailSidebar.eta')}</div>
                                </Col>
                            )}
                        </Row>
                    </div>
                )}

                {props.vehicle && (
                    <>
                        <div className="separator" />
                        <div className="vehicle-info">
                            <Row gutter={[40, 10]} align="middle">
                                <Col>
                                    <div className="vehicle-info-label">{t('common.vehicle')}</div>
                                    <div className="vehicle-info-text blue">{props.vehicle?.registrationNumber}</div>
                                </Col>
                                {props.vehicle?.drivers?.[0] && (
                                    <>
                                        <Col>
                                            <div className="vehicle-info-label">{t('common.driver')}</div>
                                            <div className="vehicle-info-text">{props.vehicle?.drivers?.[0].name}</div>
                                        </Col>
                                        <Col flex={1} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                            <Button
                                                outline
                                                onClick={() =>
                                                    props.onLocalizeVehicle?.(props.vehicle!.vehicleId!.toString())
                                                }
                                            >
                                                {t('TransportDetail.localize')}
                                            </Button>
                                        </Col>
                                    </>
                                )}
                            </Row>
                        </div>
                        <div className="separator" />
                    </>
                )}

                <div className="route-info">
                    <h5>{t('TransportDetail.routeInfo')}</h5>

                    {props.transport.places?.map(p => {
                        const taskType = p.tasks?.[0]?.type;
                        const taskIcon = taskType ? getTaskIcon(taskType) : undefined;

                        return (
                            <div className="route-info-item" key={p.id}>
                                <div className="route-info-item-content">
                                    <div className="route-info-item-content-place">
                                        <div className="route-info-item-content-place-icon">
                                            {taskIcon ? (
                                                <img
                                                    src={taskIcon ?? '/img/icn-rl-actions.svg'}
                                                    alt="tasks"
                                                    height={15}
                                                />
                                            ) : (
                                                <i className="fa fa-map-marker" />
                                            )}
                                        </div>
                                    </div>
                                    <div className="route-info-item-content-eta">
                                        <span>
                                            {moment(p.rta).format(DATE_FORMAT)} <span className="dot"> • </span>
                                            {moment(p.rta).format(TIME_FORMAT_SHORT)}
                                        </span>
                                    </div>
                                    {taskType && (
                                        <div className="route-info-item-content-action">
                                            <span>{t(`PlannerPlacesTask.${taskType}`)}</span>
                                        </div>
                                    )}
                                </div>
                                <div className="route-info-item-address">{p.name}</div>
                            </div>
                        );
                    })}
                </div>

                <div className="transport-info">
                    <div className="separator" />
                    <Row gutter={[40, 10]} className="transport-info-item">
                        <Col>
                            <div className="transport-info-item-label">{t('common.distance')}</div>
                            <div className="transport-info-item-text">{`${
                                props.transport.distance ? (props.transport.distance / 1000).toFixed(0) : 0
                            } km`}</div>
                        </Col>
                        <Col>
                            <div className="transport-info-item-label">{t('common.duration')}</div>
                            <div className="transport-info-item-text">
                                {moment.duration(props.transport.duration, 'seconds').format(TIME_FORMAT_SHORT)}
                            </div>
                        </Col>
                        <Col span={24}>
                            <div className="transport-info-item-label">{t('TransportDetail.transportName')}</div>
                            <div className="transport-info-item-text">{props.transport.name}</div>
                        </Col>
                        {props.transport.client && (
                            <Col span={24}>
                                <div className="transport-info-item-label">{t('TransportDetail.client')}</div>
                                <div className="transport-info-item-text">{props.transport.client}</div>
                            </Col>
                        )}
                        {props.transport.note && (
                            <Col span={24}>
                                <div className="transport-info-item-label">{t('TransportDetail.note')}</div>
                                <RichEditor text={props.transport.note} readonly={true} />
                            </Col>
                        )}
                    </Row>
                </div>
            </div>
            <div className="detail-actions">
                <Row gutter={[10, 10]}>
                    {/* <Col span={12}>
                        <Button type="primary" style={{ width: '100%' }}>
                            {t('TransportDetail.shareETA')}
                        </Button>
                    </Col> */}
                </Row>
            </div>
        </Drawer>
    );
};

export default TransportDetailSidebar;
