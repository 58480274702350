import { Conf } from 'conf';
import { HttpClient } from '../common/HttpClient';
import { CompanyVehicle } from './VehiclesApi';

export enum TransportState {
    Accepted = 'accepted',
    Active = 'active',
    Assigned = 'assigned',
    Finished = 'finished',
    New = 'new',
    Planned = 'planned',
    Rejected = 'rejected'
}

export interface GetTransportsRequest {
    statuses?: CompanyTransportStatus[];
    dateFrom?: Date;
    dateTo?: Date;
}

export interface CompanyTransport {
    name: string;
    clientName?: string;
    eta?: string;
    remainingDistance?: number;
    note?: string;
    status?: CompanyTransportStatus;
    vehicle?: CompanyVehicle;
    completedBy?: string;
    route: CompanyTransportRoute;
    transportId?: number;
    vehicleProfileId?: number;
    driver?: VehicleDriverProfile;
}

export enum CompanyTransportStatus {
    New = 0,
    Assigned = 1,
    Active = 2,
    Completed = 4
}

export interface CompanyTransportLoad {
    referenceNumber?: string;
    note?: string;
}

export interface CompanyTransportRoute {
    duration?: number;
    distance?: number;
    cost?: number;
    avoids?: TransportAvoidModel[];
    points?: CompanyTransportPoint[];
}

export interface TransportAvoidModel {
    type?: CompanyTransportAvoidTypeEnum;
    countryIso?: string;
}

export enum CompanyTransportAvoidTypeEnum {
    Highway = 0,
    TollRoads = 1,
    UnpavedRoads = 2,
    Ferry = 3,
    SpecialArea = 4,
    Country = 5
}

export interface CompanyTransportPoint {
    name?: string;
    pointType?: CompanyTransportPointTypeEnum;
    coordinate?: CoordinateModel;
    address?: string;
    arrival?: string;
    departure?: string;
    iso3?: string;
    activityType?: CompanyTransportActivityTypeEnum;
    instructions?: string;
    enabledNotifications?: TransportNotificationEnum[];
}

export enum CompanyTransportPointTypeEnum {
    Start = 0,
    End = 1,
    Waypoint = 2,
    Via = 3
}

export interface CoordinateModel {
    lon: number;
    lat: number;
}

export enum CompanyTransportActivityTypeEnum {
    None = 0,
    Parking = 1,
    Fuel = 2,
    Load = 3,
    Unload = 4
}

export enum TransportNotificationEnum {
    Arrival = 0,
    PassingWaypoint = 1,
    DeviationFromRoute = 2
}

export interface VehicleDriverProfile {
    profileId?: number;
    nick?: string;
    name?: string;
    avatarId?: string;
    avatarImageId?: string;
    avatarUrl?: string;
    alias?: string;
    plateNumber?: string;
    note?: string;
}

export interface CompanyTransportCreateModel {
    name?: string;
    status?: CompanyTransportStatus;
    eta?: string;
    vehicleId?: number;
    vehicleProfileId?: number;
    driverId?: number;
    clientName?: string;
    note?: string;
    load?: CompanyTransportLoad;
    route?: CompanyTransportRoute;
}

class TransportsApi {
    constructor(private _conf: Conf['api'], private _httpClient: HttpClient) {}

    async getTransports(companyId: number, request: GetTransportsRequest) {
        const params = new URLSearchParams();
        request.dateFrom && params.append('dateFrom', request.dateFrom.toDateString());
        request.dateTo && params.append('dateTo', request.dateTo.toDateString());
        request.statuses?.forEach(status => {
            params.append('statuses', CompanyTransportStatus[status]);
        });

        const { transports } = await this._httpClient.get<{ transports: CompanyTransport[] }>(
            `${this._conf.coreUrl}/company/${companyId}/transports`,
            { params, apiVersion: '7' }
        );

        return transports;
    }

    getTransport(companyId: number, transportId: number) {
        return this._httpClient.get<CompanyTransport>(
            `${this._conf.coreUrl}/company/${companyId}/transport/${transportId}`,
            {
                apiVersion: '7'
            }
        );
    }

    createTransport(companyId: number, transport: CompanyTransportCreateModel) {
        return this._httpClient.post<{ transportId: number }>(
            `${this._conf.coreUrl}/company/${companyId}/transport`,
            transport,
            {
                apiVersion: '7'
            }
        );
    }

    patchTransport(companyId: number, transportId: number, transport: CompanyTransportCreateModel) {
        const patchModel = [];

        if (transport.name) {
            patchModel.push({
                value: transport.name,
                path: 'name',
                op: 'Replace'
            });
        }

        if (transport.status) {
            patchModel.push({
                value: transport.status.toString(),
                path: 'status',
                op: 'Replace'
            });
        }

        if (transport.vehicleId) {
            patchModel.push({
                value: transport.vehicleId.toString(),
                path: 'vehicleId',
                op: 'Replace'
            });

            patchModel.push({
                value: CompanyTransportStatus.Assigned.toString(),
                path: 'status',
                op: 'Replace'
            });
        } else if (transport.vehicleProfileId) {
            patchModel.push({
                value: transport.vehicleProfileId.toString(),
                path: 'vehicleProfileId',
                op: 'Replace'
            });

            patchModel.push({
                value: null,
                path: 'vehicleId',
                op: 'Replace'
            });

            patchModel.push({
                value: CompanyTransportStatus.New.toString(),
                path: 'status',
                op: 'Replace'
            });
        }

        if (transport.driverId) {
            patchModel.push({
                value: transport.driverId.toString(),
                path: 'driverId',
                op: 'Replace'
            });
        }

        if (transport?.route?.duration) {
            patchModel.push({
                value: transport?.route.duration.toString(),
                path: 'route/duration',
                op: 'Replace'
            });
        }

        if (transport?.route?.distance) {
            patchModel.push({
                value: transport.route.distance.toString(),
                path: 'route/distance',
                op: 'Replace'
            });
        }

        if (transport?.route?.cost) {
            patchModel.push({
                value: transport?.route.cost.toString(),
                path: 'route/cost',
                op: 'Replace'
            });
        }

        if (transport.clientName) {
            patchModel.push({
                value: transport.clientName,
                path: 'clientName',
                op: 'Replace'
            });
        }

        if (transport?.route?.avoids) {
            patchModel.push({
                value: transport?.route.avoids,
                path: 'route/avoids',
                op: 'Replace'
            });
        }

        if (transport.route?.points) {
            patchModel.push({
                value: transport.route.points,
                path: 'route/points',
                op: 'Replace'
            });
        }

        if (typeof transport.note === 'string') {
            patchModel.push({
                value: transport.note,
                path: 'note',
                op: 'Replace'
            });
        }

        return this._httpClient.patch(
            `${this._conf.coreUrl}/company/${companyId}/transport/${transportId}`,
            patchModel,
            { apiVersion: '7' }
        );
    }

    deleteTransport(companyId: number, transportId: number) {
        return this._httpClient.delete(
            `${this._conf.coreUrl}/company/${companyId}/transport/${transportId}`,
            {},
            {
                apiVersion: '7'
            }
        );
    }
}

export { TransportsApi };
