/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CompanyCosts,
    CompanyCostsFromJSON,
    CompanyCostsFromJSONTyped,
    CompanyCostsToJSON,
} from '.';

/**
 * 
 * @export
 * @interface CompanyOperationalCostDefaults
 */
export interface CompanyOperationalCostDefaults {
    /**
     * 
     * @type {CompanyCosts}
     * @memberof CompanyOperationalCostDefaults
     */
    costs: CompanyCosts;
}

export function CompanyOperationalCostDefaultsFromJSON(json: any): CompanyOperationalCostDefaults {
    return CompanyOperationalCostDefaultsFromJSONTyped(json, false);
}

export function CompanyOperationalCostDefaultsFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyOperationalCostDefaults {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'costs': CompanyCostsFromJSON(json['costs']),
    };
}

export function CompanyOperationalCostDefaultsToJSON(value?: CompanyOperationalCostDefaults | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'costs': CompanyCostsToJSON(value.costs),
    };
}


