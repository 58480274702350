export default {
    navbar: {
        // tableViewLink: 'navbar--table-view-link',
        // menuOpenerButton: 'navbar--menu-opener-button',
        // userSettingsButton: 'navbar--user-button',
        // messagingButton: 'navbar--messaging-button',
        // profileButton: 'navbar--profile-button',
        // logo: 'navbar--logo',
        liveTrackingLink: 'navbar-link--tracking',
        loginButton: 'navbar-button--login',
        freeRegistrationButton: 'navbar-button--free-registration',
        settingsLink: 'navbar-link--settings',
        routePlannerLink: 'navbar-link--route-planner',
        dispatcherBoardLink: 'navbar-link--dispatcher-board',
        notificationsLink: 'navbar-link-notifications',
        logoutButton: 'navbar-button--logout'
    },
    settingsSidebar: {
        fleetLink: 'settings-sidebar-link--fleet',
        driversLink: 'settings-sidebar-link--drivers',
        dispatchersLink: 'settings-sidebar-link--dispatchers',
        fuelCardsLink: 'settings-sidebar-link--fuel-cards',
        favoritesLink: 'settings-sidebar-link--favorites',
        companyLink: 'settings-sidebar-link--company',
        preferencesLink: 'settings-sidebar-link--preferences'
    },
    preferences: {
        languageSelect: 'preferences-select--language-select',
        currencySelect: 'preferences-select--currency',
        // placesNaming: 'user-settings--places-naming-container',
        postalCodeRadioButton: 'preferences-radio-button--postal-code',
        adressRadioButton: 'preferences-radio-button--address',
        // vehicleIdentificationContainer: 'user-settings--vehicle-identification-container',
        saveChangesButton: 'preferences-button--save-changes'
    },
    companyModule: {
        companyNameInput: 'company-input--company-name',
        vatNumberInput: 'company-input--vat-number',
        fleetSizeInput: 'company-input--fleet-size',
        streetAddressInput: 'company-input--street-address',
        streetNumberInput: 'company-input--street-number',
        cityInput: 'company-input--city',
        postalCodeInput: 'company-input--postal-code',
        countrySelect: 'company-select--country',
        telephoneInput: 'company-input--telephone',
        emailInput: 'company-input--email',
        saveButton: 'company-button--save-changes'
    },
    fuelCards: {
        addFuelCardButton: 'fuel-cards-button--add-fuel-card',
        addFuelCardModal: {
            cardBrandSelect: 'fuel-cards-modal-select--card-brand',
            discountInput: 'fuel-cards-modal-input--discount',
            noteInput: 'fuel-cards-modal-input--note',
            saveButton: 'fuel-cards-modal-button--save'
        }
    },
    dispatchers: {
        addDispatcherButton: 'dispatchers-button--add-dispatcher',
        addDispatcherModal: {
            nameInput: 'dispatchers-modal-input--name',
            emailInput: 'dispatchers-modal-input--email',
            passwordInput: 'dispatchers-modal-input--password',
            confirmPasswordInput: 'dispatchers-modal-input--confirm-password',
            saveButton: 'dispatchers-modal-button-save'
        }
    },
    fleet: {
        addVehicleButton: 'fleet-button--add-vehicle',
        addVehicleModal: {
            typeSelect: 'fleet-modal-select--type',
            registrationNumberInput: 'fleet-modal-input--registration-number',
            nameInput: 'fleet-modal-select--name',
            weightInput: 'fleet-modal-input--weight',
            lengthInput: 'fleet-modal-input--length',
            widthInput: 'fleet-modal-input--width',
            heightInput: 'fleet-modal-input--height',
            axlesCountInput: 'fleet-modal-input--axles-count',
            axleWeightInput: 'fleet-modal-input--axle-weight',
            fuelTypeSelect: 'fleet-modal-select--fuel-type',
            emissionClassSelect: 'fleet-modal-select--emission-class',
            adrTunnelCodeSelect: 'fleet-modal-select--adr-tunnel-code',
            hazardousLoadSelect: 'fleet-modal-select--hazardous-load',
            costperKmInput: 'fleet-modal-input--cost-per-km',
            trailersCount: 'fleet-modal-input--trailers-count',
            saveButton: 'fleet-modal-button--save',
            maxSpeedInput: 'fleet-modal-input--max-speed'
        }
    },
    routePlanner: {
        vehicleSelect: 'route-planner-select--vehicle',
        costPerKmInput: 'route-planner-input--cost-per-km',
        searchInput: 'route-planner-input--search',
        closeButton: 'route-planner-button--close'
    },
    registerModal: {
        registerModal: 'register-modal-modal',
        registerForm: {
            emailInput: 'register-form-input--email',
            passwordInput: 'register-form-input--password',
            consentCheckboxInput: 'register-form-checkbox',
            registerButton: 'register-form-button'
        }
    },
    aboutCompanyModal: {
        aboutCompanyModal: 'register-modal-modal',
        aboutCompanyForm: {
            companyNameInput: 'register-form-input--company-name',
            companySizeInput: 'register-form-input--company-size',
            numberOfTrucksInput: 'register-form-input--number-of-trucks',
            submitButton: 'submit-form-button'
        }
    },
    loginModal: {
        loginModal: 'login-modal-modal',
        loginForm: {
            emailInput: 'login-form-input--email',
            passwordInput: 'login-form-input--password',
            signInButton: 'login-form-button--sign-in'
        }
    },

    map: {
        zoomInButton: 'map--zoom-in-button',
        zoomOutButton: 'map--zoom-out-button',
        fullScreenButton: 'map--fullscreen-button',
        parkingLotsButton: 'map--parking-lots-button',
        fuelStationsButton: 'map--fuel-stations-button',
        layersButton: 'map--layers-button'
    },
    mapControls: {
        simpleLayerButton: 'map-controls--simple-layer-button',
        colourLayerButton: 'map-controls--colour-layer-button',
        heightLayerButton: 'map-controls--height-layer-button',
        displayTrafficButton: 'map-controls--display-traffic-button',
        displayPostalCodesButton: 'map-controls--display-postalcodes-button',
        colouredVehiclesButton: 'map-controls--coloured-vehicles-button',
        labeledVehiclesButton: 'map-controls--labeled-vehicles-button',
        showFuelStationsButton: 'map-controls--show-fuel-stations-button',
        mapControls: 'map-controls--fuel-station-owners-container',
        fuelsAndServicesButton: 'map-controls--fuels-and-services-button',
        fuelStationsServicesContainer: 'map-controls--fuel-stations-services-container',
        showParkingLotsButton: 'map-controls--show-parking-lots-button',
        parkingLotOwnersContainer: 'map-controls--parking-lot-owners-container',
        parkingLotServicesContainer: 'map-controls--parking-lot-services-container',
        closeButton: 'map-controls--close-button',
        favoritesButton: 'map-controls--favorites-button',
        restrictionsButton: 'map-controls--restrictions-button'
    },
    trackingBar: {
        search: 'tracking-bar--search',
        searchInput: 'tracking-search--input',
        showFilterButton: 'tracking-bar--show-filter-button',
        expandTableButton: 'tracking-bar--expand-table-button',
        filter: {
            EtaAll: 'tracking-bar--filter--all_etas',
            EtaMoreThan30: 'tracking-bar--filter--eta_more_than_30_minutes_late',
            EtaLessThan30: 'tracking-bar--filter--eta-less_than_30_minutes_late',
            EtaOnTime: 'tracking-bar--filter--eta-on-time',
            StatusAll: 'tracking-bar--filter--status-all',
            EtaRtaNotEntered: 'tracking-bar--filter--eta-rta-not-entered',
            StatusVehiclesMoving: 'tracking-bar--filter--status-vehicles-moving',
            StatusVehiclesStanding: 'tracking-bar--filter--status-vehicles-standing',
            VehicleGroups: 'tracking-bar--filter--vehicle-groups',
            ConfirmButton: 'tracking-bar--filter--confirm-button',
            CancelButton: 'tracking-bar--filter--cancel-button'
        }
    },
    trackingTable: {
        addDestinationButton: 'tracking-table--add-destination-button',
        assignDriverButton: 'tracking-table--assign-driver-button'
    },
    vehicleCard: {
        rn: 'vehicle-card--rn',
        driverName: 'vehicle-card--driver-name'
    },
    dispatcherBoard: {
        showNewTransportsButton: 'dispatcher-board--show-new-transports-button',
        // sleepButton: 'dispatcher-board--sleep-button',
        delayButton: 'dispatcher-board--delay-button',
        previousButton: 'dispatcher-board--previous-button',
        calendarButton: 'dispatcher-board--calendar-button',
        nextButton: 'dispatcher-board--next-button',
        table: {
            filter: {
                states: 'dispatcher-board-table--filter--states',
                drivers: 'dispatcher-board-table--filter--drivers',
                vehicles: 'dispatcher-board-table--filter--vehicles'
            }
        }
    },
    schedulingPlanning: {
        defaultRouteName: 'scheduling-planing--default-route-name',
        addRoute: 'route-planning--add-route-button',
        search: 'route-planning--search',
        showKMButton: 'route-planning--show-km-button',
        activateTasksButton: 'route-planning--activate-tasks-button',
        routePointSearch: 'route-planning--route-point-search',
        cancelWithoutSave: 'route-planning--cancel-without-save',
        deleteTransport: 'route-planning--delete-transport',
        saveRoute: 'route-planning--save-route',
        sendToDriver: 'route-planning--send-to-driver',
        routePointMenu: {
            all: 'route-planning--route-point-menu--all',
            ownPlaces: 'route-planning--route-point-menu--own-places',
            ourCompanies: 'route-planning--route-point-menu--our-companies',
            companies: 'route-planning--route-point-menu--companies',
            mapResult: 'route-planning--route-point-menu--map-results',
            history: 'route-planning--route-point-menu--map-history',
            gasStations: 'route-planning--route-point-menu--gas-stations',
            parkingLots: 'route-planning--route-point-menu--parking-lots'
        },
        vehicleProfile: {
            availableVehicle: 'route-planning--available-vehicle',
            disabledleVehicle: 'route-planning--disabled-vehicle',
            noCriteria: 'route-planning--no-criteria',
            profile: 'route--planning--vehicle-profile',
            createProfile: 'route-planning--create-new-profile'
        }
    },
    // fleet: {
    //     fleetVehicleTags: {
    //         cancelDeleteAction: 'fleet-tags--cancel-delete-action',
    //         cancelAddEditTag: 'fleet-tags--cancel-add-edit-tags',
    //         saveAddedTag: 'fleet-tags--save-add-tags',
    //         saveUpdatedTag: 'fleet-tags--save-updated-tags',
    //         newTagGroup: 'fleet-tags--new-tag-group',
    //         cancelAll: 'fleet-tags-cancel-all',
    //         resertAll: 'fleet-tags--reset-all',
    //         confirm: 'fleet-tags--confirm',
    //         tagNewInput: 'fleet-tags--tag-input',
    //         tagNewErr: 'fleet-tags--tag-err',
    //         tagEditInput: 'fleet-tags--tag-input',
    //         tagEditErr: 'fleet-tags--tag-err',
    //         tagGroupNewInput: 'fleet-tags--tag-group-err',
    //         tagGroupNewErr: 'fleet-tags--tag-group-err',
    //         tagGroupEditInput: 'fleet-tags--tag-group-err',
    //         tagGroupEditErr: 'fleet-tags--tag-group-err'
    //     },
    //     managementFleetDetail: 'management-fleet-detail',
    //     editVehicle: {
    //         type: 'vehicle-type-input',
    //         emissionClass: 'vehicle-emission-class-input',
    //         tunnel: 'vehicle-tunnel-type-input',
    //         hazardLoad: 'vehicle-hazardous-load-input'
    //     }
    // },
    driverBehaviour: {
        score: 'driver-behaviour--score',
        driverScore: 'driver-behaviour--driver-score',
        ecoScore: 'driver-behaviour--eco-score',
        wearTearScore: 'driver-behaviour--wear-tear-score'
    }
    // fuelCards: {
    //     inputDiscount: 'input-fuel-cards-discount',
    //     inputNote: 'input-fuel-cards-note',
    //     inputDiscountDetail: 'input-fuel-cards-discount-detail',
    //     inputNoteDetail: 'input-fuel-cards-note-detail'
    // }
};
