import { Conf } from 'conf';
import { HttpClient } from '../common/HttpClient';
import { FuelCardFormModel } from '../../../modules/SettingsModule/modules/FuelCardsModule/ui/FuelCardsAddEditForm/FuelCardsAddEditForm';
import { PatchType } from '../common/utils';
import { DispatcherModel } from './UsersApi';

const COMPANY_API_VERSION = '7';

export enum UserRole {
    dispatcher = 1,
    driver
}

interface Code {
    code: string;
    createdOn: string;
    creator: {
        name: string;
        nick: string;
        profileId: number;
    };
}

export interface Company {
    companyId: number;
    name: string;
    taxId?: string;
    phone?: string;
    email?: string;
    street?: string;
    streetNumber?: string;
    city?: string;
    country?: { name: string; code: string };
    postalCode?: string;
    fleetSize?: number;
}

export interface CompanyBase {
    companyId: number;
    name: string;
    photoUrl: string;
}

export interface Driver {
    alias: string;
    note: string;
    plateNumber: string;
    name: string;
    status: string;
    chatUsername: string;
    updatedOn: string;
    avatarId: string;
    avatarUrl: string;
    profileId: number;
    nick: string;
    lastPosition: {
        longitude: number;
        latitude: number;
    };
    positionUpdatedOn: string;
    isVisible: boolean;
}

export interface FuelCardModel {
    cardId: number;
    brandId: number;
    brandName: string;
    discount: number;
    description: string;
}

export interface FuelCardPutModel {
    brandId: number;
    discount: number;
    description: string;
}

export interface NavigationRoutePoint {
    coordinate: {
        lon: number;
        lat: number;
    };
    address: string;
    name: string;
}

export interface NavigationRoute {
    startPoint: NavigationRoutePoint;
    waypoints: NavigationRoutePoint[];
    endPoint: NavigationRoutePoint;
    progress: number;
    eta: string;
    polyline: string;
    updatedOn: string;
    remainingDistance: number;
}

class CompanyApi {
    constructor(private _conf: Conf['api'], private _httpClient: HttpClient) {}

    async getCode(companyId: number, role: number) {
        return this._httpClient.get<Code>(`${this._conf.coreUrl}/company/${companyId}/code?Role=${role}`, {
            apiVersion: COMPANY_API_VERSION
        });
    }

    async createCode(companyId: number, role: number): Promise<Code> {
        return this._httpClient.post(`${this._conf.coreUrl}/company/${companyId}/code?Role=${role}`, null, {
            apiVersion: COMPANY_API_VERSION
        });
    }

    async deleteCode(companyId: number, role: number) {
        return this._httpClient.delete(`${this._conf.coreUrl}/company/${companyId}/code?Role=${role}`, null, {
            apiVersion: COMPANY_API_VERSION
        });
    }

    async getCompany(companyId: number) {
        return this._httpClient.get<Company>(`${this._conf.coreUrl}/company/${companyId}`, {
            apiVersion: COMPANY_API_VERSION
        });
    }

    async patchCompany(companyId: number, data: PatchType<Omit<Company, 'country'> & { countryIso2: string }>[]) {
        return this._httpClient.patch(`${this._conf.coreUrl}/company/${companyId}`, data, {
            apiVersion: COMPANY_API_VERSION
        });
    }

    async getCompanyId() {
        return this._httpClient
            .get<{ companies: Company[] }>(`${this._conf.coreUrl}/company/my`, {
                apiVersion: COMPANY_API_VERSION
            })
            .then(data => data.companies[0]?.companyId || null);
    }

    async createCompany(company: Omit<Company, 'companyId'>) {
        return this._httpClient.post<{
            companyId: number;
            name: string;
            photoUrl: string;
        }>(`${this._conf.coreUrl}/company`, company, {
            headers: { 'Content-Type': 'application/json' },
            apiVersion: COMPANY_API_VERSION
        });
    }

    async getCompanyDrivers(companyId: number): Promise<Driver[]> {
        const { drivers } = await this._httpClient.get<{ drivers: Driver[] }>(
            `${this._conf.coreUrl}/company/${companyId}/drivers`,
            {
                apiVersion: COMPANY_API_VERSION
            }
        );

        return drivers;
    }

    async postRouteToDriver(companyId: number, driverId: number, input: any) {
        return this._httpClient.post(`${this._conf.coreUrl}/company/${companyId}/route/${driverId}`, input, {
            headers: { 'Content-Type': 'application/json' },
            apiVersion: COMPANY_API_VERSION
        });
    }

    async getDispatchers(companyId: number): Promise<DispatcherModel[]> {
        const { dispatchers } = await this._httpClient.get<{ dispatchers: DispatcherModel[] }>(
            `${this._conf.coreUrl}/company/${companyId}/dispatchers`,
            {
                apiVersion: COMPANY_API_VERSION
            }
        );

        return dispatchers;
    }

    async getUserRoles(companyId: number, userId: string) {
        const { roles } = await this._httpClient.get<{ roles: any }>(
            `${this._conf.coreUrl}/company/${companyId}/dispatcher/${userId}/roles`,
            {
                apiVersion: COMPANY_API_VERSION
            }
        );

        return roles;
    }

    async setUserRoles(companyId: number, userId: string, roles: string[]) {
        await this._httpClient.put(
            `${this._conf.coreUrl}/company/${companyId}/dispatcher/${userId}/roles`,
            { roles: roles },
            {
                apiVersion: COMPANY_API_VERSION
            }
        );
    }

    async getFuelCards(companyId: number): Promise<FuelCardModel[]> {
        const { fuelCards } = await this._httpClient.get<{ fuelCards: FuelCardModel[] }>(
            `${this._conf.coreUrl}/company/${companyId}/fuelcards`,
            {
                apiVersion: COMPANY_API_VERSION
            }
        );

        return fuelCards;
    }

    async deleteFuelCard(companyId: number, cardId: number) {
        return this._httpClient.delete(`${this._conf.coreUrl}/company/${companyId}/fuelcard/${cardId}`, null, {
            apiVersion: COMPANY_API_VERSION
        });
    }

    async postFuelCard(companyId: number, fuelCard: FuelCardFormModel) {
        return this._httpClient.post(`${this._conf.coreUrl}/company/${companyId}/fuelcard`, fuelCard, {
            headers: { 'Content-Type': 'application/json' },
            apiVersion: COMPANY_API_VERSION
        });
    }

    async putFuelCard(companyId: number, cardId: number, fuelCard: FuelCardPutModel) {
        return this._httpClient.put(`${this._conf.coreUrl}/company/${companyId}/fuelcard/${cardId}`, fuelCard, {
            headers: { 'Content-Type': 'application/json' },
            apiVersion: COMPANY_API_VERSION
        });
    }

    async getNavigationRoute(companyId: number, driverId: string) {
        return this._httpClient.get<NavigationRoute>(
            `${this._conf.coreUrl}/company/${companyId}/navigationRoute/${driverId}`,
            {
                apiVersion: COMPANY_API_VERSION
            }
        );
    }
}

export { CompanyApi };
