/* tslint:disable */
/* eslint-disable */
/**
 * Geocoding API
 * Telematics services geocoding API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LatLngBounds,
    LatLngBoundsFromJSON,
    LatLngBoundsFromJSONTyped,
    LatLngBoundsToJSON,
    LatLngLiteral,
    LatLngLiteralFromJSON,
    LatLngLiteralFromJSONTyped,
    LatLngLiteralToJSON,
} from '.';

/**
 * 
 * @export
 * @interface AddressGeometry
 */
export interface AddressGeometry {
    /**
     * 
     * @type {LatLngLiteral}
     * @memberof AddressGeometry
     */
    location: LatLngLiteral;
    /**
     * 
     * @type {object}
     * @memberof AddressGeometry
     */
    locationType: object;
    /**
     * 
     * @type {LatLngBounds}
     * @memberof AddressGeometry
     */
    viewport: LatLngBounds;
    /**
     * 
     * @type {LatLngBounds}
     * @memberof AddressGeometry
     */
    bounds: LatLngBounds;
}

export function AddressGeometryFromJSON(json: any): AddressGeometry {
    return AddressGeometryFromJSONTyped(json, false);
}

export function AddressGeometryFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddressGeometry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'location': LatLngLiteralFromJSON(json['location']),
        'locationType': json['location_type'],
        'viewport': LatLngBoundsFromJSON(json['viewport']),
        'bounds': LatLngBoundsFromJSON(json['bounds']),
    };
}

export function AddressGeometryToJSON(value?: AddressGeometry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'location': LatLngLiteralToJSON(value.location),
        'location_type': value.locationType,
        'viewport': LatLngBoundsToJSON(value.viewport),
        'bounds': LatLngBoundsToJSON(value.bounds),
    };
}


