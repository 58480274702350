import moment from 'moment';
import qs from 'qs';
import { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router';
import { Logic } from '../../../logic/logic';
import { confDefault } from 'conf';
import AssignVehicleModule from '../../AssignVehicleModule/AssignVehicleModule';
import { RouteNames } from 'router/routes';
import { VehicleCreateEditModule } from 'modules/VehicleCreateEditModule';
import { CompanyVehicle } from 'services/api/domains/VehiclesApi';
import { Modal, Notification } from 'components/base/layout';
import { InitiatedFrom } from 'logic/userEventsProviders/GoogleTagManager';
import { TransportState } from 'services/api/domains/TransportsApi';
import TableView from './ui/TableView/TableView';
import { TransportModel } from 'logic/dispatcher-board';
import TransportDetailSidebar from '../common/TransportDetailSidebar/TransportDetailSidebar';
import TableViewFilterSidebar from './ui/TableViewFilterSidebar/TableViewFilterSidebar';

export interface TableViewModel {
    dateAndTimeFinish: string;
    dateAndTimeStart: string;
    driver: string;
    ETA: string;
    firstPointOnTheRoute: string;
    id: string;
    lastPointOnTheRoute: string;
    name: string;
    RTA: string;
    state: TransportState;
    vehicleRN: string;
    vehicleId?: number;
    driverId?: number;
}

export type TableViewData = {
    routesNew?: TableViewModel[];
    routesAssigned?: TableViewModel[];
    routesDelayed?: TableViewModel[];
    routesActive?: TableViewModel[];
    routesFinished?: TableViewModel[];
};

interface RouteParams {
    startDate: string;
    endDate: string;
    selected?: string;
    dispatcherBoard?: string;
}

export interface RlDateRange {
    start: string;
    end: string;
}

export interface TableViewFilterDataItem<T extends string | number> {
    id: T;
    label: string;
}

export interface TableViewFilterData {
    drivers: Record<number, TableViewFilterDataItem<number>>;
    vehicles: Record<number, TableViewFilterDataItem<number>>;
    states: Record<string, TableViewFilterDataItem<string>>;
}

export interface TableViewFilterSelected {
    drivers: string[];
    vehicles: string[];
    states: string[];
}

export interface TableViewFilterModel {
    data: TableViewFilterData;
    selected: TableViewFilterSelected;
}

interface Props extends WithTranslation, RouteComponentProps<RouteParams> {
    logic: Logic;
}

interface State {
    filter: TableViewFilterModel;
    selectedTransport?: string;
    vehicles?: CompanyVehicle[];
    dateRange: RlDateRange;
    table: {
        data?: TableViewData;
        selectedId?: string;
        loading: boolean;
        processing: boolean;
    };
    backUrl?: string;
    createVehicleModal: boolean;
    assignVehicleModal: boolean;
    transportDetailSidebar: {
        visible: boolean;
        transport?: TransportModel;
        vehicle?: CompanyVehicle;
    };
    filterDrawerVisible: boolean;
}

class TableViewModule extends Component<Props, State> {
    private _logic: Logic;

    constructor(props: Props) {
        super(props);
        this._logic = this.props.logic;
        const defaults = confDefault.settings.dispatcherBoardTable;

        this.state = {
            filter: {
                data: {
                    drivers: {},
                    vehicles: {},
                    states: {}
                },
                selected: {
                    drivers: [],
                    vehicles: [],
                    states: []
                }
            },
            dateRange: {
                start: defaults.dateRange.start,
                end: defaults.dateRange.end
            },
            table: {
                data: {},
                loading: true,
                processing: false
            },
            createVehicleModal: false,
            assignVehicleModal: false,
            transportDetailSidebar: {
                visible: false
            },
            filterDrawerVisible: false
        };
    }

    async componentDidMount() {
        const params = new URLSearchParams(this.props.history.location.search);
        const startDate = params.get('startDate');
        const endDate = params.get('endDate');
        const selected = params.get('selected');
        const dispatcherBoard = params.get('dispatcherBoard');

        this.setState(
            state => ({
                dateRange: {
                    start: startDate ? startDate : state.dateRange.start,
                    end: endDate ? endDate : state.dateRange.end
                },
                table: {
                    ...state.table,
                    selectedId: selected ?? state.table.selectedId
                },
                backUrl:
                    startDate && endDate && dispatcherBoard
                        ? `${RouteNames.SCHEDULING_DISPATCHER_BOARD_CALENDAR}?editId=${selected}`
                        : undefined
            }),
            async () => {
                await this._initFiltersData();
                this._logic.schedulingRouteOverview().init(this.state.dateRange);
            }
        );

        this._logic.schedulingRouteOverview().onData((tableViewData, companyVehicles) => {
            this.setState(state => ({
                table: {
                    ...state.table,
                    data: this._filterTable(tableViewData),
                    loading: false
                },
                vehicles: companyVehicles
            }));
        });
    }

    componentWillUnmount() {
        this._logic.schedulingRouteOverview().destroy();
    }

    render() {
        return (
            <>
                <VehicleCreateEditModule
                    logic={this.props.logic}
                    visible={this.state.createVehicleModal}
                    type={'CREATE'}
                    onCancel={this._onCreateVehicleCancel}
                    afterSubmit={this._onCreateVehicleSubmit}
                />

                <TableView
                    dateRange={this.state.dateRange}
                    table={this.state.table}
                    vehicles={this.state.vehicles}
                    filter={this.state.filter}
                    onDateRangeChange={this._onDateRangeChange}
                    onRemoveTransportClick={this._onRemoveTransportClick}
                    onTableRowExpand={this._onTableRowClickExpand}
                    onTableSetStateClick={this._onTableSetStateClick}
                    onTableTrackOnMapClick={this._onTableTrackOnMapClick}
                    onAssignVehicleClick={this._onAssignVehicleClick}
                    onAddVehicleClick={this._onAddVehicleClick}
                    onTransportDetailClick={this._onTransportDetailClick}
                    onTransportEditClick={this._onTransportEditClick}
                    onFilterChange={this._onFilterChange}
                    onFilterClear={this._onFilterClear}
                    onOpenFilterDrawer={this._onToggleFilterDrawer}
                />

                <AssignVehicleModule
                    logic={this._logic}
                    visible={this.state.assignVehicleModal}
                    assigningToTransport={true}
                    onCancel={this._onVehicleAssignCancel}
                    onConfirm={this._onVehicleAssignConfirm}
                />
                {this.state.transportDetailSidebar.transport && (
                    <TransportDetailSidebar
                        transport={this.state.transportDetailSidebar.transport}
                        vehicle={this.state.transportDetailSidebar.vehicle}
                        visible={this.state.transportDetailSidebar.visible}
                        onClose={this._onCloseTransportDetail}
                        onEditTransport={this._onTransportEditClick}
                        onLocalizeVehicle={this._onLocalizeVehicle}
                        onDeleteTransport={transport => {
                            this._onRemoveTransportClick(transport.id, transport.state);
                        }}
                    />
                )}

                <TableViewFilterSidebar
                    filter={this.state.filter}
                    visible={this.state.filterDrawerVisible}
                    onFilterChange={this._onFilterChange}
                    onCloseFilterSidebar={this._onToggleFilterDrawer}
                    onFilterClear={this._onFilterClear}
                />
            </>
        );
    }

    private _initFiltersData = async () => {
        const companyId = this._logic.company().getCompany().companyId;
        const vehicles = await this._logic.vehicles().getVehicles();
        const drivers = await this._logic.apiService().company().getCompanyDrivers(companyId);

        const data: TableViewFilterData = {
            drivers: {},
            vehicles: {},
            states: {}
        };

        vehicles.forEach(v => {
            if (v.vehicleId) {
                data.vehicles[v.vehicleId] = { id: v.vehicleId, label: v.registrationNumber };
            }
        });

        drivers.forEach(d => {
            if (d.profileId) {
                data.drivers[d.profileId] = { id: d.profileId, label: d.name };
            }
        });

        Object.values(TransportState)
            .filter(v => v !== TransportState.Rejected)
            .forEach(s => (data.states[s] = { id: s, label: s as string }));

        this.setState(state => ({
            filter: {
                ...state.filter,
                data: data
            }
        }));
    };

    private _filterNewAccepted = (r: TableViewModel): boolean => {
        if (
            !this.state.filter.selected.states.length &&
            [TransportState.Accepted, TransportState.New].includes(r.state)
        ) {
            return true;
        } else {
            if (
                this.state.filter.selected.states.includes(TransportState.Accepted as string) &&
                r.state === TransportState.Accepted
            ) {
                return true;
            } else {
                return (
                    this.state.filter.selected.states.includes(TransportState.New as string) &&
                    r.state === TransportState.New
                );
            }
        }
    };

    private _filterAssigned = (r: TableViewModel): boolean => {
        if (!this.state.filter.selected.states.length && r.state === TransportState.Assigned) {
            return true;
        }

        return (
            this.state.filter.selected.states.includes(TransportState.Assigned) && r.state === TransportState.Assigned
        );
    };

    private _filterActive = (r: TableViewModel): boolean => {
        if (!this.state.filter.selected.states.length && r.state === TransportState.Active && !this._filterDelayed(r)) {
            return true;
        }

        return (
            this.state.filter.selected.states.includes(TransportState.Active) &&
            r.state === TransportState.Active &&
            !this._filterDelayed(r)
        );
    };

    private _filterFinished = (r: TableViewModel): boolean => {
        if (!this.state.filter.selected.states.length && r.state === TransportState.Finished) {
            return true;
        }

        return (
            this.state.filter.selected.states.includes(TransportState.Finished) && r.state === TransportState.Finished
        );
    };

    private _filterVehicles = (r: TableViewModel): boolean => {
        if (!this.state.filter.selected.vehicles.length) {
            return true;
        }

        if (r.vehicleId) {
            const vehicle = this.state.filter.data.vehicles[r.vehicleId];
            return this.state.filter.selected.vehicles.includes(vehicle.id.toString());
        }

        return false;
    };

    private _filterDrivers = (r: TableViewModel): boolean => {
        if (!this.state.filter.selected.drivers.length) {
            return true;
        }

        if (r.driverId) {
            const driver = this.state.filter.data.drivers[r.driverId];
            return this.state.filter.selected.drivers.includes(driver.id.toString());
        }

        return false;
    };

    private _filterDelayed = (r: TableViewModel): boolean => {
        if (r.ETA && r.dateAndTimeFinish && r.state === TransportState.Active) {
            return moment(r.ETA).isAfter(moment(r.dateAndTimeFinish));
        } else {
            return false;
        }
    };

    private _filterTable(data: TableViewModel[]): TableViewData {
        return {
            routesNew: data
                .filter(this._filterNewAccepted)
                .filter(this._filterVehicles)
                .filter(this._filterDrivers)
                .sort(this._sortByDate),
            routesAssigned: data
                .filter(this._filterAssigned)
                .filter(this._filterVehicles)
                .filter(this._filterDrivers)
                .sort(this._sortByDate),
            routesDelayed: data.filter(this._filterDelayed).filter(this._filterVehicles).filter(this._filterDrivers),
            routesActive: data
                .filter(this._filterActive)
                .filter(this._filterVehicles)
                .filter(this._filterDrivers)
                .sort(this._sortByDate),
            routesFinished: data
                .filter(this._filterFinished)
                .filter(this._filterVehicles)
                .filter(this._filterDrivers)
                .sort(this._sortByDate)
        };
    }

    private _onTableSetStateClick = (transportId: string, state: TransportState) => {
        this.setState(state => ({
            table: {
                ...state.table,
                processing: true
            }
        }));

        this._logic
            .schedulingRouteOverview()
            .setTransportState(transportId, state)
            .then(() => {
                this._logic.schedulingRouteOverview().loadData(this.state.dateRange);
            })
            .catch(err => {
                Notification.error({
                    message: err.message,
                    placement: 'bottomRight'
                });
            })
            .finally(() => {
                this.setState(state => ({
                    table: {
                        ...state.table,
                        processing: false
                    }
                }));
            });
    };

    private _onRemoveConfirm = (transportId: string) => {
        this.setState(state => ({
            table: {
                ...state.table,
                processing: true
            }
        }));

        this._logic
            .schedulingRouteOverview()
            .removeTransport(transportId)
            .then(() => {
                Notification.success({
                    message: this.props.t('common.deleted'),
                    description: this.props.t('DispatcherBoardDetail.deleteSuccess'),
                    duration: 5,
                    placement: 'bottomRight'
                });

                this._logic.userEvents().deleteTransport(transportId);

                this._logic.schedulingRouteOverview().loadData(this.state.dateRange);
            })
            .catch(err => {
                Notification.error({
                    message: err.message,
                    placement: 'bottomRight'
                });
            })
            .finally(() => {
                this.setState(state => ({
                    table: {
                        ...state.table,
                        processing: false
                    }
                }));
            });
    };

    private _onRemoveTransportClick = (transportId: string, state: TransportState) => {
        if (state === TransportState.Active) {
            Modal.info({
                title: this.props.t('ActiveTransportEditModal.title'),
                content: this.props.t('ActiveTransportEditModal.content')
            });

            return;
        }

        Modal.confirm({
            title: this.props.t('Planner.deleteHeader'),
            content: this.props.t('Planner.deleteConfirm'),
            onOk: () => this._onRemoveConfirm(transportId)
        });
    };

    private _onTableTrackOnMapClick = (plate: string) => {
        const vehicle = this._logic.schedulingRouteOverview().vehicleByRn(plate);
        if (vehicle) {
            this.props.history.push({
                pathname: RouteNames.TRACKING,
                search: qs.stringify({
                    vehicleId: vehicle.vehicleId
                })
            });
        }
    };

    private _onAssignVehicleClick = (transportId: string) => {
        this.setState({
            assignVehicleModal: true,
            selectedTransport: transportId
        });
    };

    private _onVehicleAssignCancel = () => {
        this.setState({
            assignVehicleModal: false,
            selectedTransport: undefined
        });
    };

    private _onVehicleAssignConfirm = async (vehicleId: string) => {
        const transport = this.state.table.data?.routesNew?.filter(
            transport => transport.id === this.state.selectedTransport
        )[0];

        const transportState: TransportState = moment()
            .subtract(30, 'minutes')
            .isAfter(moment(transport?.dateAndTimeStart))
            ? TransportState.Active
            : TransportState.Accepted;

        if (this.state.selectedTransport) {
            await this._logic
                .schedulingRouteOverview()
                .addVehicleToTransport(vehicleId, this.state.selectedTransport, transportState)
                .then(() => {
                    this._logic.userEvents().assignTransport(this.state.selectedTransport!, vehicleId);

                    this.setState({ selectedTransport: undefined, assignVehicleModal: false }, () =>
                        this._logic.schedulingRouteOverview().loadData(this.state.dateRange)
                    );
                });
        }
    };

    private _onDateRangeChange = (dateRange: RlDateRange) => {
        this.setState(
            state => ({
                dateRange: dateRange,
                table: {
                    ...state.table,
                    loading: true
                }
            }),
            () => {
                this.props.history.push({
                    search: qs.stringify({
                        startDate: dateRange.start,
                        endDate: dateRange.end
                    } as RouteParams)
                });
                this._logic.schedulingRouteOverview().loadData(this.state.dateRange);
            }
        );
    };

    private _onTableRowClickExpand = (type: keyof TableViewData) => {
        if (this.state.table?.data) {
            if (this.state.table.data[type]) {
                this.setState({
                    table: {
                        ...this.state.table,
                        data: { ...this.state.table.data, [type]: undefined },
                        loading: false
                    }
                });
            } else {
                this.setState({
                    table: {
                        ...this.state.table,
                        data: {
                            ...this.state.table.data,
                            [type]: this._filterTable(this._logic.schedulingRouteOverview().tableViewData)[type]
                        },
                        loading: false
                    }
                });
            }
        }
    };

    private _sortByDate(a: TableViewModel, b: TableViewModel) {
        return moment(b.dateAndTimeStart).diff(a.dateAndTimeStart);
    }

    private _onAddVehicleClick = () => {
        this.setState({ createVehicleModal: true });
    };

    private _onCreateVehicleCancel = () => {
        this.setState({ createVehicleModal: false });
    };

    private _onCreateVehicleSubmit = () => {
        this._onCreateVehicleCancel();
        this._logic
            .schedulingRouteOverview()
            .loadVehicles()
            .then(vehicles => this.setState({ vehicles }));
    };

    private _onTransportEditClick = (transportId: string, vehicleId?: string) => {
        this.props.logic
            .userEvents()
            .viewTransport(transportId, vehicleId, InitiatedFrom.DISPATCH_BOARD_TABLE_ROW_CLICK);

        this.props.history.push({
            pathname: RouteNames.SCHEDULING_PLANNER,
            search: qs.stringify({
                editId: transportId,
                vehicleId: vehicleId,
                startDate: this.state.dateRange.start,
                endDate: this.state.dateRange.end
            })
        });
    };

    private _onTransportDetailClick = async (transportId: string): Promise<void> => {
        const transport = await this.props.logic.dispatcherBoard().transport(transportId);
        this.setState({
            transportDetailSidebar: {
                visible: true,
                transport: transport,
                vehicle: this.state.vehicles?.find(v => v.vehicleId?.toString() === transport.vehicleId)
            }
        });
    };

    private _onLocalizeVehicle = (vehicleId?: string): void => {
        this.props.history.push({
            pathname: RouteNames.TRACKING,
            search: qs.stringify({
                vehicleId: vehicleId
            })
        });
    };

    private _onCloseTransportDetail = (): void => {
        this.setState({
            transportDetailSidebar: {
                visible: false,
                transport: undefined,
                vehicle: undefined
            }
        });
    };

    private _onFilterChange = (data: TableViewFilterSelected) => {
        const filterData = () => {
            const tableViewData = this._logic.schedulingRouteOverview().tableViewData;
            const tableData = this._filterTable(tableViewData);

            this.setState(state => ({
                table: {
                    ...state.table,
                    data: tableData
                }
            }));
        };

        this.setState(
            state => ({
                filter: {
                    ...state.filter,
                    selected: {
                        drivers: data.drivers || [],
                        states: data.states || [],
                        vehicles: data.vehicles || []
                    }
                }
            }),
            () => filterData()
        );
    };

    private _onToggleFilterDrawer = () => {
        this.setState(state => ({
            filterDrawerVisible: !state.filterDrawerVisible
        }));
    };

    private _onFilterClear = () => {
        this.setState(
            state => ({
                filterDrawerVisible: false,
                filter: {
                    ...state.filter,
                    selected: {
                        drivers: [],
                        states: [],
                        vehicles: []
                    }
                }
            }),
            () => this._onFilterChange({ drivers: [], states: [], vehicles: [] })
        );
    };
}

export default withRouter(withTranslation()(TableViewModule));
