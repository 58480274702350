import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { ReactNode } from 'react';
import { SpinProps } from 'antd/lib/spin';

interface Props extends SpinProps {
    children?: ReactNode;
    opacity?: boolean;
    qa?: string;
    tip?: string;
    loading: boolean;
    size?: 'small' | 'default' | 'large' | undefined;
}

function EnhancedSpin({ qa, children, ...props }: Props) {
    enum fontSize {
        small = 18,
        default = 28,
        large = 38
    }

    const antIcon = <LoadingOutlined style={{ fontSize: fontSize[props.size || 'large'] }} spin={true} />;

    return (
        <>
            {props.loading ? (
                <Spin
                    delay={props.delay}
                    data-qa={qa}
                    indicator={antIcon}
                    tip={props.tip}
                    spinning={props.loading}
                    size={props.size || 'large'}
                    wrapperClassName={'rl-spin ' + (!props.opacity ? 'opacity' : '')}
                >
                    {children}
                </Spin>
            ) : (
                children
            )}
        </>
    );
}

export default EnhancedSpin;
