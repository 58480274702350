import { makeAutoObservable, runInAction } from 'mobx';
import { RootStore } from './RootStore';
import { ApiService } from 'services/api/ApiService';
import { Favorite, FavoriteType } from 'services/api/domains/FavoritesApi';
import { Location } from 'types';

export class FavoritesStore {
    isFetching = false;
    favoritesWithAddresses?: Favorite[];

    constructor(private _rootStore: RootStore, private _apiService: ApiService) {
        makeAutoObservable<FavoritesStore, '_rootStore' | '_apiService'>(this, {
            _rootStore: false,
            _apiService: false
        });
    }

    async addToFavorite(id: string, category: FavoriteType, name: string, location: Location) {
        await this._apiService.favorites().updateFavorite({
            id,
            type: category,
            content: JSON.stringify({
                name,
                location
            }),
            version: '5'
        });
        this.fetchFavoritesWithAddress();
    }

    async updateFavoriteName(favorite: Favorite) {
        const update = { ...favorite };

        if (update.version === '4') {
            update.content = update.name || '';
        } else if (update.version === '5') {
            update.content = JSON.stringify({
                ...JSON.parse(update.content),
                name: update.name
            });
        }

        await this._apiService.favorites().updateFavorite(update);

        if (this.favoritesWithAddresses) {
            const index = this.favoritesWithAddresses?.findIndex(f => f.id === update.id);
            runInAction(() => {
                if (this.favoritesWithAddresses) {
                    this.favoritesWithAddresses[index] = { ...this.favoritesWithAddresses[index], ...update };
                }
            });
        }
    }

    async deleteFavorite(id: string, type: FavoriteType) {
        await this._apiService.favorites().deleteFavorite(id, type);
        runInAction(() => {
            this.favoritesWithAddresses = this.favoritesWithAddresses?.filter(f => f.id !== id || f.type !== type);
        });
    }

    async getFavoritesWithAddress() {
        if (!this.favoritesWithAddresses) {
            return await this.fetchFavoritesWithAddress();
        }
        return this.favoritesWithAddresses;
    }

    async fetchFavoritesWithAddress() {
        try {
            this._setFetching(true);
            const favorites = await this._apiService.favorites().getFavoritesWithAddress();
            this._setFetching(false);
            runInAction(() => {
                this.favoritesWithAddresses = favorites;
            });
            return favorites;
        } catch (err) {
            this._setFetching(false);
            throw err;
        }
    }

    private _setFetching(value: boolean) {
        this.isFetching = value;
    }
}
