import { ColumnProps } from 'antd/lib/table';
import { Button } from 'components/base/controls';
import { Table } from 'components/base/layout';
import { DriverWithVehicle } from 'modules/SettingsModule/modules/DriversModule/DriversModule';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import { useHistory } from 'react-router-dom';
import { RouteNames } from 'router/routes';

interface Props {
    loading?: boolean;
    table: {
        data?: DriverWithVehicle[];
        loading?: boolean;
    };
    onAssignClick: (driverId: number) => void;
    onCancel?: () => void;
    onclickToInvite: () => void;
}

function AssignDriver(props: Props) {
    const { t } = useTranslation();
    const { isMobileWidth } = useWindowDimensions();
    const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);
    const [availableDrivers, setAvailableDrivers] = useState<DriverWithVehicle[]>();

    useEffect(() => {
        if (!availableDrivers) {
            setAvailableDrivers(props.table.data?.filter(driver => !driver.vehicle));
        }
    }, [props.table.data]);

    const history = useHistory();

    const columns: ColumnProps<DriverWithVehicle>[] = [
        {
            title: t('common.name'),
            dataIndex: 'name',
            key: 'name',
            className: 'rl-col-name',
            render: (name, record) => (
                <span style={{ whiteSpace: 'nowrap' }}>
                    <img src={record.avatarUrl} alt="Avatar" />
                    <strong>{name ?? record.alias}</strong>
                </span>
            )
        }
    ];

    const _goToInviteModal = () => {
        props.onclickToInvite();
        const searchParams = new URLSearchParams(history.location.search);
        searchParams.append('inviteDriver', 'true');
        history.push({ search: searchParams.toString() });
    };

    return (
        <div className="rl-assign-driver">
            <div className="rl-assign-driver-header">
                {isMobileWidth && (
                    <Button type="action" icon={<i className="rl-icon-chevron-left" />} onClick={props.onCancel} />
                )}
                <h3>{t('AssignDriverModal.title')}</h3>
            </div>

            <div className="rl-assign-driver-content">
                <div className="rl-assign-driver-content-wrapper">
                    {availableDrivers?.length ? (
                        <>
                            <h6>{t('AssignDriverModal.availableDrivers')}</h6>

                            <Table
                                columns={columns}
                                showHeader={false}
                                dataSource={availableDrivers}
                                rowKey="profileId"
                                loading={props.table.loading}
                                rowSelection={{
                                    selectedRowKeys,
                                    type: 'radio'
                                }}
                                onRow={record => ({
                                    onClick: () => {
                                        if (record.vehicle) return;
                                        setSelectedRowKeys([record.profileId]);
                                    }
                                })}
                                rowClassName={record => (record.vehicle ? 'rl-table-row-disabled' : '')}
                            />

                            <Button
                                type="primary"
                                loading={props.loading ? { delay: 500 } : false}
                                disabled={!selectedRowKeys.length}
                                onClick={() => props.onAssignClick(selectedRowKeys[0])}
                            >
                                {t('AssignDriverModal.assign')}
                            </Button>

                            <div style={{ margin: '16px 0' }}>{t('common.or')}</div>

                            <Button type="dark" onClick={() => _goToInviteModal()}>
                                {t('AssignDriverModal.invite')}
                            </Button>

                            <div style={{ marginTop: 48 }}>
                                <Trans i18nKey="AssignDriverModal.manageDriver">
                                    You can manage all your drivers
                                    <Button
                                        type="link"
                                        style={{ width: 'auto' }}
                                        onClick={() => {
                                            history.push(RouteNames.SETTINGS_DRIVERS);
                                            props.onCancel?.();
                                        }}
                                    >
                                        here
                                    </Button>
                                </Trans>
                            </div>
                        </>
                    ) : (
                        <>
                            <div style={{ marginBottom: 40 }}>
                                <strong>{t('AssignDriverModal.noDriver.title')}</strong>
                                <br />
                                {t('AssignDriverModal.noDriver.desc')}
                            </div>

                            <Button
                                type="primary"
                                onClick={() => {
                                    history.push(RouteNames.SETTINGS_DRIVERS);
                                    props.onCancel?.();
                                }}
                            >
                                {t('AssignDriverModal.noDriver.manage')}
                            </Button>

                            <div style={{ margin: '16px 0' }}>{t('common.or')}</div>

                            <Button type="dark" onClick={() => _goToInviteModal()}>
                                {t('AssignDriverModal.noDriver.invite')}
                            </Button>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}

export default AssignDriver;
