import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Logic } from 'logic/logic';
import { Notification } from 'components/base/layout';
import { LoginModal } from './ui/LoginModal';
import { useHistory } from 'react-router-dom';
import { useStore } from 'stores/RootStore';

interface Props {
    logic: Logic;
    visible: boolean;
    onCancel?: () => void;
    afterLogin?: () => void;
}

export default function LoginModule(props: Props) {
    const history = useHistory();
    const { t } = useTranslation();
    const { authStore, appStore } = useStore();
    const [loading, setLoading] = useState(false);

    const onLogin = async (credentials: { email: string; password: string }) => {
        try {
            setLoading(true);
            await authStore?.login(credentials.email, credentials.password);
            await props.logic.company().fetchCompany();
            appStore?.setIsLoggedIn(true);

            props.afterLogin?.();

            props.logic.userEvents().login();
        } catch (err) {
            console.error('Login err:', err);
            const errMessage = JSON.parse(JSON.parse(err.message));
            Notification.error({
                message: t(errMessage.type)
            });
        } finally {
            setLoading(false);
        }
    };

    const loginWithRedirect = async (provider: 'google' | 'facebook') => {
        setLoading(true);

        try {
            const searchParams = new URLSearchParams(history.location.search);
            searchParams.delete('login');

            history.push({ search: searchParams.toString() });

            await authStore?.loginWithRedirect?.(provider);
        } catch (err) {
            console.error('Login err: ', err);
            Notification.error({
                message: 'Cannot login' // TODO: add translation for login error
            });
        } finally {
            setLoading(false);
        }
    };

    const openRegisterModal = () => {
        history.push({ search: '?register=true' });
    };

    const openForgotPasswordModal = () => {
        history.push({ search: '?resetPassword=true' });
    };

    return (
        <LoginModal
            visible={props.visible}
            loading={loading}
            onCancel={props.onCancel}
            onLoginClick={onLogin}
            onRedirectLogin={loginWithRedirect}
            onRegisterClick={openRegisterModal}
            onForgotPasswordClick={openForgotPasswordModal}
        />
    );
}
