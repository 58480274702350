/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DriverBehaviourScore,
    DriverBehaviourScoreFromJSON,
    DriverBehaviourScoreFromJSONTyped,
    DriverBehaviourScoreToJSON,
} from '.';

/**
 * 
 * @export
 * @interface BackendApiApiV1DriverbehaviourDriverBehaviourResponse
 */
export interface BackendApiApiV1DriverbehaviourDriverBehaviourResponse {
    /**
     * 
     * @type {Array<DriverBehaviourScore>}
     * @memberof BackendApiApiV1DriverbehaviourDriverBehaviourResponse
     */
    prev: Array<DriverBehaviourScore>;
    /**
     * 
     * @type {Array<DriverBehaviourScore>}
     * @memberof BackendApiApiV1DriverbehaviourDriverBehaviourResponse
     */
    act: Array<DriverBehaviourScore>;
    /**
     * 
     * @type {number}
     * @memberof BackendApiApiV1DriverbehaviourDriverBehaviourResponse
     */
    numberOfDrivers: number;
}

export function BackendApiApiV1DriverbehaviourDriverBehaviourResponseFromJSON(json: any): BackendApiApiV1DriverbehaviourDriverBehaviourResponse {
    return BackendApiApiV1DriverbehaviourDriverBehaviourResponseFromJSONTyped(json, false);
}

export function BackendApiApiV1DriverbehaviourDriverBehaviourResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): BackendApiApiV1DriverbehaviourDriverBehaviourResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'prev': ((json['prev'] as Array<any>).map(DriverBehaviourScoreFromJSON)),
        'act': ((json['act'] as Array<any>).map(DriverBehaviourScoreFromJSON)),
        'numberOfDrivers': json['number_of_drivers'],
    };
}

export function BackendApiApiV1DriverbehaviourDriverBehaviourResponseToJSON(value?: BackendApiApiV1DriverbehaviourDriverBehaviourResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'prev': ((value.prev as Array<any>).map(DriverBehaviourScoreToJSON)),
        'act': ((value.act as Array<any>).map(DriverBehaviourScoreToJSON)),
        'number_of_drivers': value.numberOfDrivers,
    };
}


