import { Select } from 'components/base/controls';
import { Language } from 'utils/constants/constants';

export const LanguageSelect = (props: {
    language?: string;
    languages: Language[];
    onLanguageChange?: (lang: string) => void;
}) => {
    return (
        <Select
            value={props.language}
            className="navbar-language-select"
            onChange={props.onLanguageChange}
            dropdownMatchSelectWidth={false}
            optionLabelProp="label"
            bordered={false}
            dropdownAlign={{
                points: ['tr', 'br'],
                offset: [0, 13],
                overflow: {
                    adjustX: true,
                    adjustY: true
                }
            }}
            dropdownClassName="navbar-language-select-dropdown"
        >
            {props.languages.map((lang, index) => (
                <Select.Option key={index} value={lang.code} label={lang.label}>
                    {`${lang.label} - ${lang.name}`}
                </Select.Option>
            ))}
        </Select>
    );
};
