import { Button } from 'components/base/controls';
import { POI_ICON } from 'utils/constants/constants';
import { PoiDetailModel } from '../../PoiDetail';

interface Props {
    poi: PoiDetailModel;
    index: number;
    onRecentPoiClick: (poi: PoiDetailModel) => void;
    onDeleteRecentPoi: (poi: PoiDetailModel) => void;
}

const PoiDetailRecently = (props: Props) => {
    return (
        <div
            key={props.index}
            className="rl-poi-detail-recently-list-card"
            onClick={() => {
                props.onRecentPoiClick(props.poi);
            }}
        >
            {props.poi?.brand ? (
                <img
                    src={props.poi.brand.logoUrl}
                    alt={props.poi.brand.name}
                    className="rl-poi-detail-recently-list-card-icon"
                />
            ) : (
                <img
                    className="rl-poi-detail-recently-list-card-icon"
                    src={`/img/poi-detail/${POI_ICON[props.poi?.category!]}`}
                    alt="last"
                />
            )}

            <div className="rl-poi-detail-recently-list-card-right-section">
                <div className="rl-poi-detail-recently-list-card-right-section-name">{props.poi.name}</div>
                {props.poi.address}
            </div>

            <Button type="icon" className="rl-poi-detail-recently-list-card-delete">
                <i
                    className="rl-icon-close"
                    onClick={e => {
                        e.stopPropagation();
                        props.onDeleteRecentPoi(props.poi);
                    }}
                />
            </Button>
        </div>
    );
};

export default PoiDetailRecently;
