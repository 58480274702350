import { Tag, TagProps } from 'antd';
import * as React from 'react';

interface Props extends TagProps {
    qa?: string;
    text: string;
    onClose?: (e: React.MouseEvent<HTMLElement>) => void;
}

export default function EnhancedTag({ qa, ...props }: Props) {
    return (
        <Tag
            data-qa={qa}
            closable={!!props.onClose}
            onClose={props.onClose}
            color="#F1F4F9"
            className={props.className ? props.className + ' rl-tag' : 'rl-tag'}
            closeIcon={<i className="fas fa-times close-icon" />}
            {...props}
        >
            {props.text}
        </Tag>
    );
}
