/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Client,
    ClientFromJSON,
    ClientFromJSONTyped,
    ClientToJSON,
} from '.';

/**
 * 
 * @export
 * @interface ReadOnlyMonitoredObjectGroup
 */
export interface ReadOnlyMonitoredObjectGroup {
    /**
     * 
     * @type {number}
     * @memberof ReadOnlyMonitoredObjectGroup
     */
    readonly id?: number;
    /**
     * 
     * @type {Client}
     * @memberof ReadOnlyMonitoredObjectGroup
     */
    client?: Client;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyMonitoredObjectGroup
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyMonitoredObjectGroup
     */
    color?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReadOnlyMonitoredObjectGroup
     */
    deleted: number;
    /**
     * 
     * @type {number}
     * @memberof ReadOnlyMonitoredObjectGroup
     */
    monitoredObjectGroupType?: number | null;
}

export function ReadOnlyMonitoredObjectGroupFromJSON(json: any): ReadOnlyMonitoredObjectGroup {
    return ReadOnlyMonitoredObjectGroupFromJSONTyped(json, false);
}

export function ReadOnlyMonitoredObjectGroupFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReadOnlyMonitoredObjectGroup {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'client': !exists(json, 'client') ? undefined : ClientFromJSON(json['client']),
        'name': json['name'],
        'color': !exists(json, 'color') ? undefined : json['color'],
        'deleted': json['deleted'],
        'monitoredObjectGroupType': !exists(json, 'monitored_object_group_type') ? undefined : json['monitored_object_group_type'],
    };
}

export function ReadOnlyMonitoredObjectGroupToJSON(value?: ReadOnlyMonitoredObjectGroup | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'client': ClientToJSON(value.client),
        'name': value.name,
        'color': value.color,
        'deleted': value.deleted,
        'monitored_object_group_type': value.monitoredObjectGroupType,
    };
}


