/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ClientContract,
    ClientContractFromJSON,
    ClientContractToJSON,
    InlineResponse2003,
    InlineResponse2003FromJSON,
    InlineResponse2003ToJSON,
    ReadOnlyClientContract,
    ReadOnlyClientContractFromJSON,
    ReadOnlyClientContractToJSON,
    WriteOnlyClientContract,
    WriteOnlyClientContractFromJSON,
    WriteOnlyClientContractToJSON,
} from '../models';

export interface ClientContractCreateRequest {
    data: WriteOnlyClientContract;
}

export interface ClientContractDeleteRequest {
    id: number;
}

export interface ClientContractGetActiveClientContractsRequest {
    client?: string;
    externalSystem?: string;
    externalId?: string;
    module?: string;
    serviceAreaCode?: string;
    validFrom?: string;
    validTo?: string;
    clientContractStatus?: string;
    limit?: number;
    offset?: number;
}

export interface ClientContractListRequest {
    client?: string;
    externalSystem?: string;
    externalId?: string;
    module?: string;
    serviceAreaCode?: string;
    validFrom?: string;
    validTo?: string;
    clientContractStatus?: string;
    limit?: number;
    offset?: number;
}

export interface ClientContractPartialUpdateRequest {
    id: number;
    data: WriteOnlyClientContract;
}

export interface ClientContractReadRequest {
    id: number;
}

export interface ClientContractUpdateRequest {
    id: number;
    data: WriteOnlyClientContract;
}

/**
 * no description
 */
export class ClientContractApi extends runtime.BaseAPI {

    /**
     */
    async clientContractCreateRaw(requestParameters: ClientContractCreateRequest): Promise<runtime.ApiResponse<ReadOnlyClientContract>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling clientContractCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/client-contract/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyClientContractToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyClientContractFromJSON(jsonValue));
    }

    /**
     */
    async clientContractCreate(requestParameters: ClientContractCreateRequest): Promise<ReadOnlyClientContract> {
        const response = await this.clientContractCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async clientContractDeleteRaw(requestParameters: ClientContractDeleteRequest): Promise<runtime.ApiResponse<ReadOnlyClientContract>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling clientContractDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/client-contract/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyClientContractFromJSON(jsonValue));
    }

    /**
     */
    async clientContractDelete(requestParameters: ClientContractDeleteRequest): Promise<ReadOnlyClientContract> {
        const response = await this.clientContractDeleteRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async clientContractGetActiveClientContractsRaw(requestParameters: ClientContractGetActiveClientContractsRequest): Promise<runtime.ApiResponse<Array<ClientContract>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.client !== undefined) {
            queryParameters['client'] = requestParameters.client;
        }

        if (requestParameters.externalSystem !== undefined) {
            queryParameters['external_system'] = requestParameters.externalSystem;
        }

        if (requestParameters.externalId !== undefined) {
            queryParameters['external_id'] = requestParameters.externalId;
        }

        if (requestParameters.module !== undefined) {
            queryParameters['module'] = requestParameters.module;
        }

        if (requestParameters.serviceAreaCode !== undefined) {
            queryParameters['service_area_code'] = requestParameters.serviceAreaCode;
        }

        if (requestParameters.validFrom !== undefined) {
            queryParameters['valid_from'] = requestParameters.validFrom;
        }

        if (requestParameters.validTo !== undefined) {
            queryParameters['valid_to'] = requestParameters.validTo;
        }

        if (requestParameters.clientContractStatus !== undefined) {
            queryParameters['client_contract_status'] = requestParameters.clientContractStatus;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/client-contract/active/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ClientContractFromJSON));
    }

    /**
     */
    async clientContractGetActiveClientContracts(requestParameters: ClientContractGetActiveClientContractsRequest): Promise<Array<ClientContract>> {
        const response = await this.clientContractGetActiveClientContractsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async clientContractListRaw(requestParameters: ClientContractListRequest): Promise<runtime.ApiResponse<InlineResponse2003>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.client !== undefined) {
            queryParameters['client'] = requestParameters.client;
        }

        if (requestParameters.externalSystem !== undefined) {
            queryParameters['external_system'] = requestParameters.externalSystem;
        }

        if (requestParameters.externalId !== undefined) {
            queryParameters['external_id'] = requestParameters.externalId;
        }

        if (requestParameters.module !== undefined) {
            queryParameters['module'] = requestParameters.module;
        }

        if (requestParameters.serviceAreaCode !== undefined) {
            queryParameters['service_area_code'] = requestParameters.serviceAreaCode;
        }

        if (requestParameters.validFrom !== undefined) {
            queryParameters['valid_from'] = requestParameters.validFrom;
        }

        if (requestParameters.validTo !== undefined) {
            queryParameters['valid_to'] = requestParameters.validTo;
        }

        if (requestParameters.clientContractStatus !== undefined) {
            queryParameters['client_contract_status'] = requestParameters.clientContractStatus;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/client-contract/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse2003FromJSON(jsonValue));
    }

    /**
     */
    async clientContractList(requestParameters: ClientContractListRequest): Promise<InlineResponse2003> {
        const response = await this.clientContractListRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async clientContractPartialUpdateRaw(requestParameters: ClientContractPartialUpdateRequest): Promise<runtime.ApiResponse<ReadOnlyClientContract>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling clientContractPartialUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling clientContractPartialUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/client-contract/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyClientContractToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyClientContractFromJSON(jsonValue));
    }

    /**
     */
    async clientContractPartialUpdate(requestParameters: ClientContractPartialUpdateRequest): Promise<ReadOnlyClientContract> {
        const response = await this.clientContractPartialUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async clientContractReadRaw(requestParameters: ClientContractReadRequest): Promise<runtime.ApiResponse<ReadOnlyClientContract>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling clientContractRead.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/client-contract/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyClientContractFromJSON(jsonValue));
    }

    /**
     */
    async clientContractRead(requestParameters: ClientContractReadRequest): Promise<ReadOnlyClientContract> {
        const response = await this.clientContractReadRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async clientContractUpdateRaw(requestParameters: ClientContractUpdateRequest): Promise<runtime.ApiResponse<ReadOnlyClientContract>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling clientContractUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling clientContractUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/client-contract/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyClientContractToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyClientContractFromJSON(jsonValue));
    }

    /**
     */
    async clientContractUpdate(requestParameters: ClientContractUpdateRequest): Promise<ReadOnlyClientContract> {
        const response = await this.clientContractUpdateRaw(requestParameters);
        return await response.value();
    }

}
