/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReadOnlyOrderSerializer
 */
export interface ReadOnlyOrderSerializer {
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyOrderSerializer
     */
    id?: string;
    /**
     * 
     * @type {Date}
     * @memberof ReadOnlyOrderSerializer
     */
    readonly deleted?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ReadOnlyOrderSerializer
     */
    readonly createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ReadOnlyOrderSerializer
     */
    readonly updatedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyOrderSerializer
     */
    priceList: string;
    /**
     * 
     * @type {number}
     * @memberof ReadOnlyOrderSerializer
     */
    monitoringDevice: number;
}

export function ReadOnlyOrderSerializerFromJSON(json: any): ReadOnlyOrderSerializer {
    return ReadOnlyOrderSerializerFromJSONTyped(json, false);
}

export function ReadOnlyOrderSerializerFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReadOnlyOrderSerializer {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'deleted': !exists(json, 'deleted') ? undefined : (new Date(json['deleted'])),
        'createdAt': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
        'updatedAt': !exists(json, 'updated_at') ? undefined : (new Date(json['updated_at'])),
        'priceList': json['price_list'],
        'monitoringDevice': json['monitoring_device'],
    };
}

export function ReadOnlyOrderSerializerToJSON(value?: ReadOnlyOrderSerializer | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'price_list': value.priceList,
        'monitoring_device': value.monitoringDevice,
    };
}


