import { Button, Tooltip } from 'components/base/controls';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useStore } from 'stores/RootStore';
import { DATE_FORMAT } from 'utils/constants/domain-constants';
import { PoiDetailModel } from '../../PoiDetail';

interface Props {
    poiDetail: PoiDetailModel;
    onShowAllPrices?: () => void;
}

interface FuelTypeGroupCardProps {
    id: string;
    price: number;
    currency?: string;
    code: string;
    created?: Date;
}

const fuelTypesGroups = ['diesel', 'adblue', 'cng', 'lng', 'lpg', 'electricity', 'bioalcohol'];

export function PoiDetailFuelGroups(props: Props) {
    const { t } = useTranslation();
    const { poiDetail } = props;
    const fuelGroups = mapFuelGroups(poiDetail);
    const { authStore } = useStore();
    const history = useHistory();

    function FuelTypeCard(fuelType: FuelTypeGroupCardProps, key: number) {
        return (
            <Tooltip
                key={key}
                placement="topLeft"
                title={`${t('PoiDetail.priceLastUpdate')} ${moment(fuelType.created).format(DATE_FORMAT)}`}
            >
                <div className="rl-poi-detail-fuel-groups-card">
                    <div
                        className={`rl-poi-detail-fuel-type rl-poi-detail-fuel-groups-card-type rl-poi-detail-fuel-type-${fuelType.id}`}
                    />

                    <div className="rl-poi-detail-fuel-groups-card-price">
                        {fuelType?.price.toFixed(3)} {fuelType?.currency}
                        {poiDetail?.discount?.bestDiscount.code === fuelType?.code && (
                            <div className="rl-poi-detail-fuel-groups-card-price-discount">
                                {poiDetail?.discount?.discountCard.discount} %
                            </div>
                        )}
                    </div>
                </div>
            </Tooltip>
        );
    }

    const openSignIn = () => {
        history.push({ search: '?login=true' });
    };

    return (
        <div className="rl-poi-detail-section">
            {authStore.isLoggedIn ? (
                fuelGroups.length ? (
                    <div className="rl-poi-detail-fuel-groups-list">
                        {fuelGroups.map(
                            (fuelType, index) => fuelType.price && <FuelTypeCard {...fuelType} key={index} />
                        )}
                        <Button type="link" onClick={() => props.onShowAllPrices?.()}>
                            {t('PoiDetailFuelGroups.showAllPrices')}
                        </Button>
                    </div>
                ) : (
                    <div className="rl-poi-detail-fuel-groups-no-prices">
                        <i className="rl-icon-info" />
                        {t('PoiDetailFuelGroups.noPrices')}
                    </div>
                )
            ) : (
                <div className="rl-poi-detail-fuel-groups-no-sign-in">
                    <Button onClick={openSignIn}>{t('PoiDetailFuelGroups.signIn')}</Button>
                </div>
            )}
        </div>
    );
}

function mapFuelGroups(poiDetail: PoiDetailModel): FuelTypeGroupCardProps[] {
    const poiFuelTypes = poiDetail?.fuelTypes;
    const discount = poiDetail?.discount?.bestDiscount;

    if (!poiFuelTypes?.length) {
        return [];
    }

    return fuelTypesGroups
        .map(groupFuelType => {
            const types: { price: number; currency?: string; code: string; created?: Date }[] = [];

            poiFuelTypes!.forEach(ft => {
                if (groupFuelType === ft.price?.type) {
                    const discountPrice = ft.code === discount?.code ? discount.price?.discountedPrice : 0;
                    types.push({
                        price: parseFloat(discountPrice || ft.price?.price!),
                        currency: ft.price?.currency,
                        code: ft.code,
                        created: ft.price?.created
                    });
                }
            });

            const fuelType = types.sort((a, b) => a!.price - b!.price)[0];

            return {
                id: groupFuelType,
                ...fuelType
            };
        })
        .filter(fg => fg.price);
}
