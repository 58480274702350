/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InlineResponse20014,
    InlineResponse20014FromJSON,
    InlineResponse20014ToJSON,
    ReadOnlyMonitoredObjectGroupType,
    ReadOnlyMonitoredObjectGroupTypeFromJSON,
    ReadOnlyMonitoredObjectGroupTypeToJSON,
    WriteOnlyMonitoredObjectGroupType,
    WriteOnlyMonitoredObjectGroupTypeFromJSON,
    WriteOnlyMonitoredObjectGroupTypeToJSON,
} from '../models';

export interface MonitoredObjectGroupTypeCreateRequest {
    data: WriteOnlyMonitoredObjectGroupType;
}

export interface MonitoredObjectGroupTypeDeleteRequest {
    id: number;
}

export interface MonitoredObjectGroupTypeListRequest {
    nameIcontains?: string;
    nameIexact?: string;
    limit?: number;
    offset?: number;
}

export interface MonitoredObjectGroupTypePartialUpdateRequest {
    id: number;
    data: WriteOnlyMonitoredObjectGroupType;
}

export interface MonitoredObjectGroupTypeReadRequest {
    id: number;
}

export interface MonitoredObjectGroupTypeUpdateRequest {
    id: number;
    data: WriteOnlyMonitoredObjectGroupType;
}

/**
 * no description
 */
export class MonitoredObjectGroupTypeApi extends runtime.BaseAPI {

    /**
     */
    async monitoredObjectGroupTypeCreateRaw(requestParameters: MonitoredObjectGroupTypeCreateRequest): Promise<runtime.ApiResponse<ReadOnlyMonitoredObjectGroupType>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling monitoredObjectGroupTypeCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/monitored-object-group-type/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyMonitoredObjectGroupTypeToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyMonitoredObjectGroupTypeFromJSON(jsonValue));
    }

    /**
     */
    async monitoredObjectGroupTypeCreate(requestParameters: MonitoredObjectGroupTypeCreateRequest): Promise<ReadOnlyMonitoredObjectGroupType> {
        const response = await this.monitoredObjectGroupTypeCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async monitoredObjectGroupTypeDeleteRaw(requestParameters: MonitoredObjectGroupTypeDeleteRequest): Promise<runtime.ApiResponse<ReadOnlyMonitoredObjectGroupType>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling monitoredObjectGroupTypeDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/monitored-object-group-type/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyMonitoredObjectGroupTypeFromJSON(jsonValue));
    }

    /**
     */
    async monitoredObjectGroupTypeDelete(requestParameters: MonitoredObjectGroupTypeDeleteRequest): Promise<ReadOnlyMonitoredObjectGroupType> {
        const response = await this.monitoredObjectGroupTypeDeleteRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async monitoredObjectGroupTypeListRaw(requestParameters: MonitoredObjectGroupTypeListRequest): Promise<runtime.ApiResponse<InlineResponse20014>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.nameIcontains !== undefined) {
            queryParameters['name__icontains'] = requestParameters.nameIcontains;
        }

        if (requestParameters.nameIexact !== undefined) {
            queryParameters['name__iexact'] = requestParameters.nameIexact;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/monitored-object-group-type/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20014FromJSON(jsonValue));
    }

    /**
     */
    async monitoredObjectGroupTypeList(requestParameters: MonitoredObjectGroupTypeListRequest): Promise<InlineResponse20014> {
        const response = await this.monitoredObjectGroupTypeListRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async monitoredObjectGroupTypePartialUpdateRaw(requestParameters: MonitoredObjectGroupTypePartialUpdateRequest): Promise<runtime.ApiResponse<ReadOnlyMonitoredObjectGroupType>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling monitoredObjectGroupTypePartialUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling monitoredObjectGroupTypePartialUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/monitored-object-group-type/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyMonitoredObjectGroupTypeToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyMonitoredObjectGroupTypeFromJSON(jsonValue));
    }

    /**
     */
    async monitoredObjectGroupTypePartialUpdate(requestParameters: MonitoredObjectGroupTypePartialUpdateRequest): Promise<ReadOnlyMonitoredObjectGroupType> {
        const response = await this.monitoredObjectGroupTypePartialUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async monitoredObjectGroupTypeReadRaw(requestParameters: MonitoredObjectGroupTypeReadRequest): Promise<runtime.ApiResponse<ReadOnlyMonitoredObjectGroupType>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling monitoredObjectGroupTypeRead.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/monitored-object-group-type/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyMonitoredObjectGroupTypeFromJSON(jsonValue));
    }

    /**
     */
    async monitoredObjectGroupTypeRead(requestParameters: MonitoredObjectGroupTypeReadRequest): Promise<ReadOnlyMonitoredObjectGroupType> {
        const response = await this.monitoredObjectGroupTypeReadRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async monitoredObjectGroupTypeUpdateRaw(requestParameters: MonitoredObjectGroupTypeUpdateRequest): Promise<runtime.ApiResponse<ReadOnlyMonitoredObjectGroupType>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling monitoredObjectGroupTypeUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling monitoredObjectGroupTypeUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/monitored-object-group-type/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyMonitoredObjectGroupTypeToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyMonitoredObjectGroupTypeFromJSON(jsonValue));
    }

    /**
     */
    async monitoredObjectGroupTypeUpdate(requestParameters: MonitoredObjectGroupTypeUpdateRequest): Promise<ReadOnlyMonitoredObjectGroupType> {
        const response = await this.monitoredObjectGroupTypeUpdateRaw(requestParameters);
        return await response.value();
    }

}
