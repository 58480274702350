import { ColumnProps } from 'antd/lib/table';
import { Button, Dropdown, Menu } from 'components/base/controls';
import { Table } from 'components/base/layout';
import { CompanyVehicle, VehicleType } from 'services/api/domains/VehiclesApi';
import { useTranslation } from 'react-i18next';

interface Props {
    data: CompanyVehicle[];
    loading?: boolean;
    onEditClick: (model: CompanyVehicle) => void;
    onRemoveClick: (vehicleId: number) => void;
    onAssignClick: (vehicleId: number) => void;
    onUnassignClick: (vehicleId: number, driverId: number) => void;
    onChangeClick: (vehicleId: number) => void;
    onShowOnMapClick: (vehicleId: number) => void;
    onRowDoubleClick: (model: CompanyVehicle) => void;
}

export function FleetTable(props: Props) {
    const { t } = useTranslation();

    const columns: ColumnProps<CompanyVehicle>[] = [
        {
            title: t('common.rn'),
            dataIndex: 'registrationNumber',
            key: 'registrationNumber',
            render: (rn: string) => <strong>{rn}</strong>
        },
        {
            title: t('common.name'),
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: t('common.type'),
            dataIndex: 'vehicleType',
            key: 'type',
            render: type => <span>{t(`VehicleProfileForm.vehicleType.${VehicleType[type]}`)}</span>
        },
        {
            title: t('FleetTable.assignedDriver'),
            dataIndex: 'drivers',
            key: 'driver',
            render: (_, record) =>
                record.drivers?.length ? (
                    <span>
                        <span className="rl-fleet-table-driver-name">
                            {record.drivers[0].name || record.drivers[0].alias}
                        </span>
                        <div className="rl-fleet-table-btns">
                            <Button
                                type="link"
                                onClick={() => {
                                    props.onChangeClick(record.vehicleId!);
                                }}
                            >
                                {t('FleetTable.change')}
                            </Button>
                            <span className="divider" />
                            <Button
                                type="link"
                                onClick={() => {
                                    props.onUnassignClick(record.vehicleId!, record.drivers![0].profileId);
                                }}
                            >
                                {t('FleetTable.unassign')}
                            </Button>
                        </div>
                    </span>
                ) : (
                    <Button type="link" onClick={() => props.onAssignClick(record.vehicleId!)}>
                        {t('FleetTable.assignDriver')}
                    </Button>
                )
        },
        {
            key: 'actions',
            align: 'right',
            render: (_, record) => (
                <Dropdown
                    overlay={
                        <Menu>
                            <Menu.Item key="0" onClick={() => props.onEditClick(record)}>
                                {t('common.edit')}
                            </Menu.Item>
                            {!!record.drivers?.length && (
                                <Menu.Item key="1" onClick={() => props.onShowOnMapClick(record.vehicleId!)}>
                                    {t('common.showOnMap')}
                                </Menu.Item>
                            )}
                            <Menu.Item key="2" onClick={() => props.onRemoveClick(record.vehicleId!)}>
                                {t('common.delete')}
                            </Menu.Item>
                        </Menu>
                    }
                    trigger={['click']}
                    arrow
                    placement="bottom"
                >
                    <i className="fas fa-ellipsis-h" />
                </Dropdown>
            )
        }
    ];
    return (
        <Table
            className="rl-settings-table rl-fleet-table"
            columns={columns}
            dataSource={props.data}
            rowKey="vehicleId"
            loading={props.loading}
            onRow={record => {
                return {
                    onDoubleClick: () => {
                        props.onRowDoubleClick(record);
                    }
                };
            }}
        />
    );
}
