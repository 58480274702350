export class TrafficLayer {
    public static init(map: mapboxgl.Map, apiKey: string) {
        map.addSource('sygic-traffic-vectors', {
            type: 'vector',
            tiles: [`https://maps.api.sygic.com/vtraffic/${apiKey}/{z}/{x}/{y}/live`],
            minzoom: 9,
            maxzoom: 16
        });
    }

    private static enableTraffic(map: mapboxgl.Map) {
        map.addLayer(
            {
                id: 'traffic-flow-rc0',
                type: 'line',
                minzoom: 9,
                source: 'sygic-traffic-vectors',
                'source-layer': 'traffic-flow',
                filter: ['==', 'rc', 0],
                paint: {
                    'line-color': [
                        'case',
                        ['==', ['get', 'flow'], 'Free'],
                        '#32D285',
                        ['==', ['get', 'flow'], 'Moderate'],
                        '#FF7F37',
                        ['==', ['get', 'flow'], 'Heavy'],
                        '#F12E51',
                        '#A42222'
                    ],
                    'line-width': 2
                }
            },
            'admin_level_21'
        );

        map.addLayer(
            {
                id: 'traffic-flow-rc1',
                type: 'line',
                minzoom: 11,
                source: 'sygic-traffic-vectors',
                'source-layer': 'traffic-flow',
                filter: ['==', 'rc', 1],
                paint: {
                    'line-color': [
                        'case',
                        ['==', ['get', 'flow'], 'Free'],
                        '#84CA50',
                        ['==', ['get', 'flow'], 'Moderate'],
                        '#F07D02',
                        ['==', ['get', 'flow'], 'Heavy'],
                        '#E60000',
                        '#A42222'
                    ],
                    'line-width': 2
                }
            },
            'admin_level_21'
        );

        map.addLayer(
            {
                id: 'traffic-flow-rc2',
                type: 'line',
                minzoom: 12,
                source: 'sygic-traffic-vectors',
                'source-layer': 'traffic-flow',
                filter: ['==', 'rc', 2],
                paint: {
                    'line-color': [
                        'case',
                        ['==', ['get', 'flow'], 'Free'],
                        '#84CA50',
                        ['==', ['get', 'flow'], 'Moderate'],
                        '#F07D02',
                        ['==', ['get', 'flow'], 'Heavy'],
                        '#E60000',
                        '#A42222'
                    ],
                    'line-width': 2
                }
            },
            'admin_level_21'
        );

        map.addLayer(
            {
                id: 'traffic-flow-rc3',
                type: 'line',
                minzoom: 14,
                source: 'sygic-traffic-vectors',
                'source-layer': 'traffic-flow',
                filter: ['==', 'rc', 3],
                paint: {
                    'line-color': [
                        'case',
                        ['==', ['get', 'flow'], 'Free'],
                        '#84CA50',
                        ['==', ['get', 'flow'], 'Moderate'],
                        '#F07D02',
                        ['==', ['get', 'flow'], 'Heavy'],
                        '#E60000',
                        '#A42222'
                    ],
                    'line-width': 2
                }
            },
            'admin_level_21'
        );
    }

    private static disableTraffic(map: mapboxgl.Map) {
        map.removeLayer('traffic-flow-rc0');
        map.removeLayer('traffic-flow-rc1');
        map.removeLayer('traffic-flow-rc2');
        map.removeLayer('traffic-flow-rc3');
    }

    public static toggleTraffic(map: mapboxgl.Map, visibile: boolean) {
        if (visibile) {
            this.enableTraffic(map);
        } else {
            this.disableTraffic(map);
        }
    }
}
