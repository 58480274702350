/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ReadOnlyOdometerAdjustment,
    ReadOnlyOdometerAdjustmentFromJSON,
    ReadOnlyOdometerAdjustmentToJSON,
    WriteOnlyOdometerAdjustment,
    WriteOnlyOdometerAdjustmentFromJSON,
    WriteOnlyOdometerAdjustmentToJSON,
} from '../models';

export interface OdometerAdjustmentCreateRequest {
    data: WriteOnlyOdometerAdjustment;
}

export interface OdometerAdjustmentDeleteRequest {
    id: string;
}

export interface OdometerAdjustmentOdometerAdjustmentBeforeRequest {
    monitoredObject: number;
    dateBefore: Date;
}

export interface OdometerAdjustmentOdometerAdjustmentBetweenDatesRequest {
    monitoredObject: number;
    dateFrom: Date;
    dateTo: Date;
}

export interface OdometerAdjustmentOdometerAdjustmentForTripsRequest {
    monitoredObject: number;
    date: Date;
}

export interface OdometerAdjustmentPartialUpdateRequest {
    id: string;
    data: WriteOnlyOdometerAdjustment;
}

export interface OdometerAdjustmentReadRequest {
    id: string;
}

export interface OdometerAdjustmentUpdateRequest {
    id: string;
    data: WriteOnlyOdometerAdjustment;
}

/**
 * no description
 */
export class OdometerAdjustmentApi extends runtime.BaseAPI {

    /**
     */
    async odometerAdjustmentCreateRaw(requestParameters: OdometerAdjustmentCreateRequest): Promise<runtime.ApiResponse<ReadOnlyOdometerAdjustment>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling odometerAdjustmentCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/odometer-adjustment/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyOdometerAdjustmentToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyOdometerAdjustmentFromJSON(jsonValue));
    }

    /**
     */
    async odometerAdjustmentCreate(requestParameters: OdometerAdjustmentCreateRequest): Promise<ReadOnlyOdometerAdjustment> {
        const response = await this.odometerAdjustmentCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async odometerAdjustmentDeleteRaw(requestParameters: OdometerAdjustmentDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling odometerAdjustmentDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/odometer-adjustment/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async odometerAdjustmentDelete(requestParameters: OdometerAdjustmentDeleteRequest): Promise<void> {
        await this.odometerAdjustmentDeleteRaw(requestParameters);
    }

    /**
     */
    async odometerAdjustmentListRaw(): Promise<runtime.ApiResponse<Array<ReadOnlyOdometerAdjustment>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/odometer-adjustment/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReadOnlyOdometerAdjustmentFromJSON));
    }

    /**
     */
    async odometerAdjustmentList(): Promise<Array<ReadOnlyOdometerAdjustment>> {
        const response = await this.odometerAdjustmentListRaw();
        return await response.value();
    }

    /**
     */
    async odometerAdjustmentOdometerAdjustmentBeforeRaw(requestParameters: OdometerAdjustmentOdometerAdjustmentBeforeRequest): Promise<runtime.ApiResponse<Array<ReadOnlyOdometerAdjustment>>> {
        if (requestParameters.monitoredObject === null || requestParameters.monitoredObject === undefined) {
            throw new runtime.RequiredError('monitoredObject','Required parameter requestParameters.monitoredObject was null or undefined when calling odometerAdjustmentOdometerAdjustmentBefore.');
        }

        if (requestParameters.dateBefore === null || requestParameters.dateBefore === undefined) {
            throw new runtime.RequiredError('dateBefore','Required parameter requestParameters.dateBefore was null or undefined when calling odometerAdjustmentOdometerAdjustmentBefore.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.monitoredObject !== undefined) {
            queryParameters['monitored_object'] = requestParameters.monitoredObject;
        }

        if (requestParameters.dateBefore !== undefined) {
            queryParameters['date_before'] = (requestParameters.dateBefore as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/odometer-adjustment/before/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReadOnlyOdometerAdjustmentFromJSON));
    }

    /**
     */
    async odometerAdjustmentOdometerAdjustmentBefore(requestParameters: OdometerAdjustmentOdometerAdjustmentBeforeRequest): Promise<Array<ReadOnlyOdometerAdjustment>> {
        const response = await this.odometerAdjustmentOdometerAdjustmentBeforeRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async odometerAdjustmentOdometerAdjustmentBetweenDatesRaw(requestParameters: OdometerAdjustmentOdometerAdjustmentBetweenDatesRequest): Promise<runtime.ApiResponse<Array<ReadOnlyOdometerAdjustment>>> {
        if (requestParameters.monitoredObject === null || requestParameters.monitoredObject === undefined) {
            throw new runtime.RequiredError('monitoredObject','Required parameter requestParameters.monitoredObject was null or undefined when calling odometerAdjustmentOdometerAdjustmentBetweenDates.');
        }

        if (requestParameters.dateFrom === null || requestParameters.dateFrom === undefined) {
            throw new runtime.RequiredError('dateFrom','Required parameter requestParameters.dateFrom was null or undefined when calling odometerAdjustmentOdometerAdjustmentBetweenDates.');
        }

        if (requestParameters.dateTo === null || requestParameters.dateTo === undefined) {
            throw new runtime.RequiredError('dateTo','Required parameter requestParameters.dateTo was null or undefined when calling odometerAdjustmentOdometerAdjustmentBetweenDates.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.monitoredObject !== undefined) {
            queryParameters['monitored_object'] = requestParameters.monitoredObject;
        }

        if (requestParameters.dateFrom !== undefined) {
            queryParameters['date_from'] = (requestParameters.dateFrom as any).toISOString();
        }

        if (requestParameters.dateTo !== undefined) {
            queryParameters['date_to'] = (requestParameters.dateTo as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/odometer-adjustment/between-dates/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReadOnlyOdometerAdjustmentFromJSON));
    }

    /**
     */
    async odometerAdjustmentOdometerAdjustmentBetweenDates(requestParameters: OdometerAdjustmentOdometerAdjustmentBetweenDatesRequest): Promise<Array<ReadOnlyOdometerAdjustment>> {
        const response = await this.odometerAdjustmentOdometerAdjustmentBetweenDatesRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async odometerAdjustmentOdometerAdjustmentForTripsRaw(requestParameters: OdometerAdjustmentOdometerAdjustmentForTripsRequest): Promise<runtime.ApiResponse<Array<ReadOnlyOdometerAdjustment>>> {
        if (requestParameters.monitoredObject === null || requestParameters.monitoredObject === undefined) {
            throw new runtime.RequiredError('monitoredObject','Required parameter requestParameters.monitoredObject was null or undefined when calling odometerAdjustmentOdometerAdjustmentForTrips.');
        }

        if (requestParameters.date === null || requestParameters.date === undefined) {
            throw new runtime.RequiredError('date','Required parameter requestParameters.date was null or undefined when calling odometerAdjustmentOdometerAdjustmentForTrips.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.monitoredObject !== undefined) {
            queryParameters['monitored_object'] = requestParameters.monitoredObject;
        }

        if (requestParameters.date !== undefined) {
            queryParameters['date'] = (requestParameters.date as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/odometer-adjustment/for-trip/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReadOnlyOdometerAdjustmentFromJSON));
    }

    /**
     */
    async odometerAdjustmentOdometerAdjustmentForTrips(requestParameters: OdometerAdjustmentOdometerAdjustmentForTripsRequest): Promise<Array<ReadOnlyOdometerAdjustment>> {
        const response = await this.odometerAdjustmentOdometerAdjustmentForTripsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async odometerAdjustmentPartialUpdateRaw(requestParameters: OdometerAdjustmentPartialUpdateRequest): Promise<runtime.ApiResponse<ReadOnlyOdometerAdjustment>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling odometerAdjustmentPartialUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling odometerAdjustmentPartialUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/odometer-adjustment/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyOdometerAdjustmentToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyOdometerAdjustmentFromJSON(jsonValue));
    }

    /**
     */
    async odometerAdjustmentPartialUpdate(requestParameters: OdometerAdjustmentPartialUpdateRequest): Promise<ReadOnlyOdometerAdjustment> {
        const response = await this.odometerAdjustmentPartialUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async odometerAdjustmentReadRaw(requestParameters: OdometerAdjustmentReadRequest): Promise<runtime.ApiResponse<ReadOnlyOdometerAdjustment>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling odometerAdjustmentRead.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/odometer-adjustment/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyOdometerAdjustmentFromJSON(jsonValue));
    }

    /**
     */
    async odometerAdjustmentRead(requestParameters: OdometerAdjustmentReadRequest): Promise<ReadOnlyOdometerAdjustment> {
        const response = await this.odometerAdjustmentReadRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async odometerAdjustmentUpdateRaw(requestParameters: OdometerAdjustmentUpdateRequest): Promise<runtime.ApiResponse<ReadOnlyOdometerAdjustment>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling odometerAdjustmentUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling odometerAdjustmentUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/odometer-adjustment/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyOdometerAdjustmentToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyOdometerAdjustmentFromJSON(jsonValue));
    }

    /**
     */
    async odometerAdjustmentUpdate(requestParameters: OdometerAdjustmentUpdateRequest): Promise<ReadOnlyOdometerAdjustment> {
        const response = await this.odometerAdjustmentUpdateRaw(requestParameters);
        return await response.value();
    }

}
