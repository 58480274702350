/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Client,
    ClientFromJSON,
    ClientFromJSONTyped,
    ClientToJSON,
} from '.';

/**
 * 
 * @export
 * @interface ReadOnlyDriverApplication
 */
export interface ReadOnlyDriverApplication {
    /**
     * 
     * @type {number}
     * @memberof ReadOnlyDriverApplication
     */
    readonly id?: number;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyDriverApplication
     */
    readonly syncAccessToken?: string;
    /**
     * 
     * @type {Client}
     * @memberof ReadOnlyDriverApplication
     */
    client?: Client;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyDriverApplication
     */
    pushId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyDriverApplication
     */
    readonly pairingCode?: string;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyDriverApplication
     */
    version?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReadOnlyDriverApplication
     */
    readonly deleted?: number;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyDriverApplication
     */
    imei?: string | null;
}

export function ReadOnlyDriverApplicationFromJSON(json: any): ReadOnlyDriverApplication {
    return ReadOnlyDriverApplicationFromJSONTyped(json, false);
}

export function ReadOnlyDriverApplicationFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReadOnlyDriverApplication {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'syncAccessToken': !exists(json, 'sync_access_token') ? undefined : json['sync_access_token'],
        'client': !exists(json, 'client') ? undefined : ClientFromJSON(json['client']),
        'pushId': !exists(json, 'push_id') ? undefined : json['push_id'],
        'pairingCode': !exists(json, 'pairing_code') ? undefined : json['pairing_code'],
        'version': !exists(json, 'version') ? undefined : json['version'],
        'deleted': !exists(json, 'deleted') ? undefined : json['deleted'],
        'imei': !exists(json, 'imei') ? undefined : json['imei'],
    };
}

export function ReadOnlyDriverApplicationToJSON(value?: ReadOnlyDriverApplication | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'client': ClientToJSON(value.client),
        'push_id': value.pushId,
        'version': value.version,
        'imei': value.imei,
    };
}


