/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Client,
    ClientFromJSON,
    ClientFromJSONTyped,
    ClientToJSON,
} from '.';

/**
 * 
 * @export
 * @interface ReadOnlyOdometerAdjustment
 */
export interface ReadOnlyOdometerAdjustment {
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyOdometerAdjustment
     */
    id?: string;
    /**
     * 
     * @type {Client}
     * @memberof ReadOnlyOdometerAdjustment
     */
    client?: Client;
    /**
     * 
     * @type {number}
     * @memberof ReadOnlyOdometerAdjustment
     */
    state: number;
    /**
     * 
     * @type {Date}
     * @memberof ReadOnlyOdometerAdjustment
     */
    date: Date;
    /**
     * 
     * @type {Date}
     * @memberof ReadOnlyOdometerAdjustment
     */
    processed?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof ReadOnlyOdometerAdjustment
     */
    monitoredObject: number;
}

export function ReadOnlyOdometerAdjustmentFromJSON(json: any): ReadOnlyOdometerAdjustment {
    return ReadOnlyOdometerAdjustmentFromJSONTyped(json, false);
}

export function ReadOnlyOdometerAdjustmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReadOnlyOdometerAdjustment {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'client': !exists(json, 'client') ? undefined : ClientFromJSON(json['client']),
        'state': json['state'],
        'date': (new Date(json['date'])),
        'processed': !exists(json, 'processed') ? undefined : (json['processed'] === null ? null : new Date(json['processed'])),
        'monitoredObject': json['monitored_object'],
    };
}

export function ReadOnlyOdometerAdjustmentToJSON(value?: ReadOnlyOdometerAdjustment | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'client': ClientToJSON(value.client),
        'state': value.state,
        'date': (value.date.toISOString()),
        'processed': value.processed === undefined ? undefined : (value.processed === null ? null : value.processed.toISOString()),
        'monitored_object': value.monitoredObject,
    };
}


