import { io, Socket, SocketOptions } from 'socket.io-client';
import { Subject } from 'rxjs';

export interface NotificationConf {
    socketio: {
        uri: string;
        opts: SocketOptions;
    };
}

export type Callback = (message: NotificationMessage) => void;

export interface NotificationMessage {
    type: string;
    data?: any;
}

export type PublishMany = {
    message: NotificationMessage;
    user: string;
    device?: string;
}[];

export type PublishToMany = {
    message: NotificationMessage;
    target: {
        user: string;
        device?: string;
    }[];
};

type Auth = () => Promise<string | undefined>;

export class Notification {
    readonly conf: NotificationConf;
    readonly device?: string;

    private _auth?: Auth;

    private _cb: Array<Callback> = [];
    private _cbs: { [type: string]: Array<Callback> } = {};

    private _onDisconnect?: () => void;

    private _eio?: Socket;

    private _reconnect: boolean = false;
    private _connectSubject = new Subject<void>();

    constructor(conf: NotificationConf, auth?: Auth) {
        this.conf = conf;
        this.device = `${Math.random().toString(36).substr(2)}-${Date.now().toString(36)}`;
        auth && (this._auth = auth);
    }

    onConnect() {
        return this._connectSubject;
    }

    onDisconnect(cb?: () => void) {
        this._onDisconnect = cb;
    }

    async connect(authToken: () => Promise<string>) {
        this._eio = io(this.conf.socketio?.uri, {
            path: '/engine.io',
            transports: ['websocket'],
            reconnectionAttempts: 20,
            ...this.conf.socketio.opts,
            query: {
                token: await authToken(),
                device: this.device
            }
        });

        this._eio.on('connect', () => {
            this._connectSubject.next();
            this._eio?.on('message', (message: any) => {
                this._emit(JSON.parse(message));
            });
        });

        this._eio.io.on('error', (err: any) => {
            console.error(err);
        });

        this._eio.io.on('close', () => {
            this._onDisconnect?.();
        });

        this._eio.io.on('reconnect_attempt', async () => {
            if (this._eio) {
                this._eio.io.opts.query = {
                    ...this._eio.io.opts.query,
                    token: await authToken()
                };
            }
        });
    }

    disconnect() {
        this._eio?.close();
    }

    async send(message: NotificationMessage, user: string, device?: string): Promise<{ subscribers: number }> {
        try {
            const headers = {
                'Content-Type': 'application/json'
            };
            if (this._auth) {
                const token = await this._auth();
                headers['Authorization'] = `Bearer ${token}`;
            }
            const res = await fetch(`${this.conf.socketio.uri}/push/${user}/${device || ''}`, {
                method: 'POST',
                headers,
                body: JSON.stringify(message)
            });
            return res.json();
        } catch (err) {
            console.log('Send message err', err);
            throw err;
        }
    }

    async sendMany(publish: PublishMany): Promise<{ subscribers: number }> {
        try {
            const headers = {
                'Content-Type': 'application/json'
            };
            if (this._auth) {
                const token = await this._auth();
                headers['Authorization'] = `Bearer ${token}`;
            }
            const res = await fetch(`${this.conf.socketio.uri}/push/many`, {
                method: 'POST',
                headers,
                body: JSON.stringify(publish)
            });
            return res.json();
        } catch (err) {
            console.log('Send many message err', err);
            throw err;
        }
    }

    async sendToMany(publish: PublishToMany): Promise<{ subscribers: number }> {
        try {
            const headers = {
                'Content-Type': 'application/json'
            };
            if (this._auth) {
                const token = await this._auth();
                headers['Authorization'] = `Bearer ${token}`;
            }

            const res = await fetch(`${this.conf.socketio.uri}/push/to-many`, {
                method: 'POST',
                headers,
                body: JSON.stringify(publish)
            });
            return res.json();
        } catch (err) {
            console.log('Send to many message err', err);
            throw err;
        }
    }

    on(type: string | string[], cb: Callback): this {
        if (type.constructor === String) {
            const t = type as string;
            if (!(t in this._cbs)) {
                this._cbs[t] = [];
            }
            if (this._cbs[t].indexOf(cb) === -1) {
                this._cbs[t].push(cb);
            }
        } else {
            (type as string[]).forEach(e => this.on(e, cb));
        }
        return this;
    }

    any(cb: Callback): this {
        this._cb.push(cb);
        return this;
    }

    once(type: string | string[], cb: Callback): this {
        if (type.constructor === String) {
            const t = type as string;
            const wrap = (message: NotificationMessage) => {
                this.off(t, wrap);
                cb(message);
            };
            this.on(type, wrap);
        } else {
            (type as string[]).forEach(e => this.once(e, cb));
        }
        return this;
    }

    off(type?: string, cb?: Callback): this {
        if (type === undefined) {
            if (cb) {
                this._cb = this._cb.filter(c => c !== cb);
            } else {
                this._cb.length = 0;
            }
        }
        if (type && type in this._cbs) {
            if (cb) {
                this._cbs[type].splice(this._cbs[type].indexOf(cb), 1);
            } else {
                this._cbs[type].length = 0;
                delete this._cbs[type];
            }
        }
        return this;
    }

    many(...cbs: { [type: string]: Callback }[]): this {
        cbs.forEach(cb => Object.keys(cb).forEach(t => this.on(t, cb[t])));
        return this;
    }

    private _emit(message: NotificationMessage): this {
        if (message.type in this._cbs) {
            for (let i = 0, l = this._cbs[message.type].length; i < l; i++) {
                this._cbs[message.type][i](message);
            }
        }
        for (let i = 0, l = this._cb.length; i < l; i++) {
            this._cb[i](message);
        }
        return this;
    }
}
