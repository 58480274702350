import { Col, Row } from 'antd';
import { Button } from 'components/base/controls';
import { useTranslation } from 'react-i18next';

interface Props {
    onAddDispatcherClick: () => void;
}

function DispatcherBlank(props: Props) {
    const { t } = useTranslation();

    return (
        <div className="rl-settings-blank">
            <img src="/img/settings/dispatcher/dispatcher-icon.svg" alt="Vehicle icon" />
            <h2>{t('DispatcherBlank.title')}</h2>

            <Button
                type="primary"
                size="large"
                icon={<i className="rl-button-icon fas fa-plus" />}
                onClick={props.onAddDispatcherClick}
            >
                {t('DispatcherBlank.addDispatcher')}
            </Button>

            <h3>{t('DispatcherBlank.addDispatcherTitle')}</h3>
            <Row justify="space-between" gutter={48}>
                <Col lg={6} sm={24}>
                    <div className="rl-settings-blank-feature">
                        <img src="/img/settings/dispatcher/work-together.svg" alt="Routing profiles icon" />
                        <span className="rl-settings-blank-feature-title">{t('DispatcherBlank.workTitle')}</span>
                        <span className="rl-settings-blank-feature-text">{t('DispatcherBlank.workText')}</span>
                    </div>
                </Col>
                <Col lg={6} sm={24}>
                    <div className="rl-settings-blank-feature">
                        <img src="/img/settings/dispatcher/keep-notes.svg" alt="Assign driver icon" />
                        <span className="rl-settings-blank-feature-title">{t('DispatcherBlank.notesTitle')}</span>
                        <span className="rl-settings-blank-feature-text">{t('DispatcherBlank.notesText')}</span>
                    </div>
                </Col>
                <Col lg={6} sm={24}>
                    <div className="rl-settings-blank-feature">
                        <img src="/img/settings/dispatcher/company-chat.svg" alt="Vehicles on map icon" />
                        <span className="rl-settings-blank-feature-title">{t('DispatcherBlank.chatTitle')}</span>
                        <span className="rl-settings-blank-feature-text">{t('DispatcherBlank.chatText')}</span>
                    </div>
                </Col>
                <Col lg={6} sm={24}>
                    <div className="rl-settings-blank-feature">
                        <img src="/img/settings/dispatcher/scheduled-routes.svg" alt="Scheduler icon" />
                        <span className="rl-settings-blank-feature-title">{t('DispatcherBlank.scheduleTitle')}</span>
                        <span className="rl-settings-blank-feature-text">{t('DispatcherBlank.scheduleText')}</span>
                    </div>
                </Col>
            </Row>
        </div>
    );
}

export default DispatcherBlank;
