import { makeAutoObservable, runInAction } from 'mobx';
import { ApiService } from 'services/api/ApiService';
import { DispatcherModel } from 'services/api/domains/UsersApi';
import { RootStore } from './RootStore';

export class DispatcherStore {
    dispatcher?: DispatcherModel;

    constructor(private _rootStore: RootStore, private _apiService: ApiService) {
        makeAutoObservable<DispatcherStore, '_rootStore' | '_apiService'>(this, {
            _rootStore: false,
            _apiService: false
        });
    }

    async fetchDispatcher() {
        try {
            const dispatcher = await this._apiService.users().getMe();
            runInAction(() => {
                this.dispatcher = dispatcher;
            });
        } catch (err) {
            console.error(err);
        }
    }

    async updateDispatcher(params: { language?: string; newslettersAgreement?: boolean }) {
        const dispatcher = await this._apiService.users().updateMe(params);

        runInAction(() => {
            this.dispatcher = dispatcher;
        });
    }
}
