import { VehicleIdentification } from 'conf';

export interface SettingsPersist {
    vehicleIdentification: VehicleIdentification;
    dispatcherBoardTable: {
        dateRange: {
            start: string;
            end: string;
        };
        filter: {
            vehicles: string[];
            drivers: string[];
            states: string[];
        };
    };
    dispatcherBoardCalendar: {
        eta: boolean;
        notes: boolean;
        break: boolean;
        expanded: boolean;
        startDate: string;
        daySegment: number;
        firstTransportPopoverVisible: boolean;
    };
    whatsAppSession: Record<string, any>;
    companyId: number | null;
    initializedAfterRegistration: boolean;
}

export class Settings<T extends object> {
    readonly name: string;
    readonly props: T;

    private _onChange?: (props: Partial<T>) => void;

    constructor(props: T, name: string = 'settings') {
        this.props = props;
        this.name = name;
        if (window.localStorage[this.name]) {
            this.storeLoad();
        } else {
            this.storeSave();
        }
    }

    getProps(): T {
        return this.props;
    }

    setProps(props: T): this {
        Object.keys(props).forEach(p => ((this.props as any)[p] = props[p]));

        this.storeSave();
        this._onChange?.(props);
        return this;
    }

    getProp<P extends T, K extends keyof P>(key: K): P[K] {
        return (this.props as P)[key];
    }

    setProp<P extends T, K extends keyof P>(key: K, value: P[K]): this {
        (this.props as any)[key] = value;
        this.storeSave();
        const props = {} as Partial<T>;
        (props as any)[key] = value;
        this._onChange?.(props);
        return this;
    }

    onChange(callback: (props: Partial<T>) => void): this {
        this._onChange = callback;
        return this;
    }

    storeLoad(): this {
        const data = JSON.parse(window.localStorage[this.name]);
        Object.keys(data).forEach(k => (this.props[k] = data[k]));
        return this;
    }

    storeSave(): this {
        window.localStorage[this.name] = JSON.stringify(this.props);
        return this;
    }

    storeClear(): this {
        delete window.localStorage[this.name];
        return this;
    }
}

// Test

// const x = { n: 2, s: "s" };
// const s = new Settings<typeof x>(x);
// typeof s.getProp("n"); // number
// typeof s.getProp("s"); // string
