/* tslint:disable */
/* eslint-disable */
/**
 * Geocoding API
 * Telematics services geocoding API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';

/**
 * no description
 */
export class MetricsApi extends runtime.BaseAPI {

    /**
     * - gets metrics for prometheus
     */
    async metricsRaw(): Promise<runtime.ApiResponse<string>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/metrics`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * - gets metrics for prometheus
     */
    async metrics(): Promise<string> {
        const response = await this.metricsRaw();
        return await response.value();
    }

}
