import { InitiatedFrom } from 'logic/userEventsProviders/GoogleTagManager';
import { TrackingModel } from 'modules/TrackingModule/TrackingModule';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { TIME_DATE } from 'utils/constants/domain-constants';
import { TransportStatusIndicator } from '../TransportStatusIndicator';
import { Button, Spin } from 'components/base/controls';
import { useEffect, useState } from 'react';
import { TrackingVehiclesListBlank } from './ui/TrackingVehiclesListBlank';
import { DropResult, DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';

const TRACKING_DRIVERS_LIST_TIP_VISIBLE_KEY = 'trackingDriversListTipVisible';

interface Props {
    vehicles?: TrackingModel[];
    isLoading: boolean;
    onAssignDriverClick: (vehicleId: string) => void;
    onAddVehicle: (initiatedFrom: InitiatedFrom) => void;
    onSelectVehicleFromList: (vehicleId: string) => void;
    onMouseEnterVehicle: (vehicleId: string) => void;
    onMouseLeaveVehicle: () => void;
    onDragVehicleEnd: (result: DropResult) => void;
    onOpenVehicleDetail: (vehicle: TrackingModel, cb?: () => void, initiatedFrom?: InitiatedFrom) => void;
}

export default function TrackingVehiclesList(props: Props) {
    const { t } = useTranslation();
    const [tipVisible, setTipVisible] = useState(true);

    useEffect(() => {
        const tipVisible = localStorage.getItem(TRACKING_DRIVERS_LIST_TIP_VISIBLE_KEY);
        if (tipVisible === 'false') {
            setTipVisible(false);
        }
    }, []);

    const closeTip = () => {
        localStorage.setItem(TRACKING_DRIVERS_LIST_TIP_VISIBLE_KEY, 'false');
        setTipVisible(false);
    };

    return (
        <div className="rl-tracking-vehicles-list">
            {(props.isLoading || (props.vehicles && props.vehicles?.length > 0)) && (
                <Spin loading={props.isLoading} tip={t('common.loading')}>
                    <DragDropContext onDragEnd={props.onDragVehicleEnd}>
                        <Droppable key={'tracking-list-droppable'} droppableId={'tracking-list-droppable'}>
                            {provided1 => (
                                <div {...provided1.droppableProps} ref={provided1.innerRef}>
                                    {props.vehicles?.map((vehicle, index) => (
                                        <Draggable draggableId={vehicle.id} index={index} key={vehicle.id}>
                                            {provided2 => (
                                                <div
                                                    className={cn('rl-tracking-vehicles-list-item', {
                                                        selected: vehicle.selected
                                                    })}
                                                    key={vehicle.id}
                                                    ref={provided2.innerRef}
                                                    {...provided2.draggableProps}
                                                    onClick={() => props.onSelectVehicleFromList(vehicle.id)}
                                                    onMouseEnter={() => {
                                                        props.onMouseEnterVehicle(vehicle.id);
                                                    }}
                                                    onMouseLeave={props.onMouseLeaveVehicle}
                                                >
                                                    <div
                                                        className="rl-tracking-vehicles-list-item-drag"
                                                        {...provided2.dragHandleProps}
                                                    >
                                                        <i className="rl-icon-drag-handler" />
                                                    </div>

                                                    <div className="rl-tracking-vehicles-list-item-content">
                                                        {vehicle.driverAvatarUrl ? (
                                                            <img src={vehicle.driverAvatarUrl} alt="driver-avatar" />
                                                        ) : (
                                                            <i className="rl-icon-user" />
                                                        )}

                                                        <div className="rl-tracking-vehicles-list-item-content-info">
                                                            <div className="rl-tracking-vehicles-list-item-content-info-header">
                                                                <div className="rl-tracking-vehicles-list-item-content-info-header-number">
                                                                    {vehicle.RN}
                                                                </div>

                                                                <TransportStatusIndicator
                                                                    model={vehicle}
                                                                    extended={false}
                                                                />

                                                                {!vehicle?.visible && (
                                                                    <i className="rl-icon-eye-disabled" />
                                                                )}
                                                            </div>

                                                            {vehicle.driverName ? (
                                                                <div className="rl-tracking-vehicles-list-item-content-info-name">
                                                                    {vehicle.driverName}
                                                                </div>
                                                            ) : (
                                                                <Button
                                                                    type="link"
                                                                    onClick={() => {
                                                                        props.onAssignDriverClick(vehicle.id);
                                                                    }}
                                                                >
                                                                    {t('TrackingVehiclesList.assignDriver')}
                                                                </Button>
                                                            )}

                                                            {vehicle.ETA && (
                                                                <div className="rl-tracking-vehicles-list-item-content-info-tracking">
                                                                    <div className="rl-tracking-vehicles-list-item-content-info-tracking-eta">
                                                                        {moment(vehicle.ETA).format(TIME_DATE)}
                                                                    </div>
                                                                    {vehicle.navigationRoute.remainingDistance && (
                                                                        <div className="rl-tracking-vehicles-list-item-content-info-tracking-distance">
                                                                            <span>&nbsp;·&nbsp;</span>
                                                                            {(
                                                                                vehicle.navigationRoute
                                                                                    .remainingDistance / 1000
                                                                            ).toFixed(1)}
                                                                            km
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            )}
                                                        </div>

                                                        {vehicle.driverId && (
                                                            <Button
                                                                type="action"
                                                                onClick={event => {
                                                                    event.stopPropagation();
                                                                    props.onOpenVehicleDetail(
                                                                        vehicle,
                                                                        undefined,
                                                                        InitiatedFrom.TRACKING_VEHICLES_LIST
                                                                    );
                                                                }}
                                                            >
                                                                <i className="rl-icon-chevron-right" />
                                                            </Button>
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided1.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                    {tipVisible && (
                        <div className="rl-tracking-vehicles-list-tip">
                            {t('TrackingVehiclesList.tip')}
                            <Button type="icon" onClick={closeTip}>
                                <i className="rl-icon-close" />
                            </Button>
                        </div>
                    )}

                    <Button type="link" onClick={() => props.onAddVehicle(InitiatedFrom.TRACKING_VEHICLES_LIST)}>
                        {t('TrackingVehiclesList.addVehicle')}
                    </Button>
                </Spin>
            )}

            {!props.isLoading && !props.vehicles?.length && (
                <TrackingVehiclesListBlank
                    onAddVehicleClick={() => props.onAddVehicle(InitiatedFrom.TRACKING_BLANK_LIST)}
                />
            )}
        </div>
    );
}
