/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DriveDataPartial,
    DriveDataPartialFromJSON,
    DriveDataPartialFromJSONTyped,
    DriveDataPartialToJSON,
    DriveDataSummary,
    DriveDataSummaryFromJSON,
    DriveDataSummaryFromJSONTyped,
    DriveDataSummaryToJSON,
} from '.';

/**
 * 
 * @export
 * @interface DriveData
 */
export interface DriveData {
    /**
     * 
     * @type {DriveDataPartial}
     * @memberof DriveData
     */
    highway: DriveDataPartial;
    /**
     * 
     * @type {DriveDataPartial}
     * @memberof DriveData
     */
    nocity: DriveDataPartial;
    /**
     * 
     * @type {DriveDataPartial}
     * @memberof DriveData
     */
    city: DriveDataPartial;
    /**
     * 
     * @type {DriveDataPartial}
     * @memberof DriveData
     */
    other: DriveDataPartial;
    /**
     * 
     * @type {DriveDataSummary}
     * @memberof DriveData
     */
    summary: DriveDataSummary;
}

export function DriveDataFromJSON(json: any): DriveData {
    return DriveDataFromJSONTyped(json, false);
}

export function DriveDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): DriveData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'highway': DriveDataPartialFromJSON(json['highway']),
        'nocity': DriveDataPartialFromJSON(json['nocity']),
        'city': DriveDataPartialFromJSON(json['city']),
        'other': DriveDataPartialFromJSON(json['other']),
        'summary': DriveDataSummaryFromJSON(json['summary']),
    };
}

export function DriveDataToJSON(value?: DriveData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'highway': DriveDataPartialToJSON(value.highway),
        'nocity': DriveDataPartialToJSON(value.nocity),
        'city': DriveDataPartialToJSON(value.city),
        'other': DriveDataPartialToJSON(value.other),
        'summary': DriveDataSummaryToJSON(value.summary),
    };
}


