import { Modal } from 'components/base/layout';
import { CreateNewPasswordForm } from '../CreateNewPasswordForm/CreateNewPasswordForm';

interface Props {
    visible: boolean;
    onCancel?: () => void;
    onSubmitCreateNewPassword: (password: string) => void;
}

export function CreateNewPasswordModal(props: Props) {
    return (
        <Modal
            className="rl-create-new-password-modal"
            visible={props.visible}
            destroyOnClose={true}
            onCancel={props.onCancel}
        >
            <CreateNewPasswordForm onSubmitCreateNewPassword={props.onSubmitCreateNewPassword} />
        </Modal>
    );
}
