import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { Country } from 'services/api/domains/CountryApi';
import { TransportModel } from 'logic/dispatcher-board';
import { CalendarViewMobileTransport } from '../CalendarViewMobileTransport/CalendarViewMobileTransport';

interface Props {
    date: string;
    transports: TransportModel[];
    countryList: Country[];
    onTransportClick: (transport: TransportModel) => void;
}

const TIMELINE_SEGMENTS = 12;
const TRANSPORT_OVERLAP_OFFSET = 15;
const TRANSPORT_DEFAULT_OFFSET = 8;

export const CalendarViewMobileGrid = (props: Props) => {
    const gridRef = useRef<HTMLDivElement>(null);
    const [gridRowWidth, setGridRowWidth] = useState(0);
    const [sortedTransports, setSortedTransports] = useState<TransportModel[]>([]);
    const [offsets, setOffsets] = useState<number[]>(new Array(props.transports.length).fill(TRANSPORT_DEFAULT_OFFSET));

    useEffect(() => {
        setGridRowWidth(gridRef.current?.scrollWidth || 500);

        const sortedTransports = [...props.transports].sort((t1, t2) =>
            moment(t1.firstPlaceRta).diff(t2.firstPlaceRta)
        );
        setSortedTransports(sortedTransports);

        const newOffsets = new Array(props.transports.length).fill(TRANSPORT_DEFAULT_OFFSET);
        for (let i = 1; i < sortedTransports.length; i++) {
            const currTransport = sortedTransports[i];
            const prevTransport = sortedTransports[i - 1];

            if (moment(prevTransport.lastPlaceRta).isAfter(currTransport.firstPlaceRta)) {
                newOffsets[i] = newOffsets[i - 1] + TRANSPORT_OVERLAP_OFFSET;
            }
        }
        setOffsets(newOffsets);
    }, [props.transports]);

    const getTopMargin = (transport: TransportModel) => {
        const diff = moment(transport.firstPlaceRta).diff(moment(props.date).startOf('day'), 'seconds');
        return (diff / (24 * 3600)) * 100;
    };

    const getHeight = (transport: TransportModel) => {
        return (moment(transport.lastPlaceRta).diff(transport.firstPlaceRta, 'seconds') / (24 * 3600)) * 100;
    };

    return (
        <div className="rl-calendar-view-mobile-grid">
            <div className="rl-calendar-view-mobile-grid-left">
                {[...Array(TIMELINE_SEGMENTS)].map((_, index) => (
                    <div className="rl-calendar-view-mobile-grid-left-row" key={index}>
                        <span className="rl-calendar-view-mobile-grid-left-row-time">
                            {moment(props.date)
                                .startOf('day')
                                .add((24 / TIMELINE_SEGMENTS) * index, 'hours')
                                .format('LT')}
                        </span>
                    </div>
                ))}
            </div>
            <div className="rl-calendar-view-mobile-grid-right" ref={gridRef}>
                {[...Array(TIMELINE_SEGMENTS)].map((_, index) => (
                    <div
                        className="rl-calendar-view-mobile-grid-right-row"
                        key={index}
                        style={{ width: gridRowWidth }}
                    />
                ))}

                {sortedTransports.map((transport, index) => {
                    const heightPercentage = getHeight(transport);

                    return (
                        <div
                            className="rl-calendar-view-mobile-transport-wrapper"
                            key={transport.id}
                            style={{
                                position: 'absolute',
                                height: `${heightPercentage}%`,
                                width: '90%',
                                top: `${getTopMargin(transport)}%`,
                                left: `${offsets[index]}px`
                            }}
                        >
                            <CalendarViewMobileTransport
                                transport={transport}
                                countryList={props.countryList}
                                date={props.date}
                                heightPercentage={heightPercentage}
                                onClick={props.onTransportClick}
                            />
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
