/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Client,
    ClientFromJSON,
    ClientFromJSONTyped,
    ClientToJSON,
} from '.';

/**
 * 
 * @export
 * @interface MonitoredObject
 */
export interface MonitoredObject {
    /**
     * 
     * @type {number}
     * @memberof MonitoredObject
     */
    readonly id?: number;
    /**
     * 
     * @type {number}
     * @memberof MonitoredObject
     */
    clientId: number;
    /**
     * 
     * @type {Client}
     * @memberof MonitoredObject
     */
    client?: Client;
    /**
     * 
     * @type {object}
     * @memberof MonitoredObject
     */
    costPerKm?: object;
    /**
     * 
     * @type {string}
     * @memberof MonitoredObject
     */
    externalId: string;
    /**
     * 
     * @type {string}
     * @memberof MonitoredObject
     */
    registrationNumber: string;
    /**
     * 
     * @type {string}
     * @memberof MonitoredObject
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MonitoredObject
     */
    systemDescription?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MonitoredObject
     */
    manufacturerName?: string | null;
    /**
     * 
     * @type {object}
     * @memberof MonitoredObject
     */
    _configuration?: object | null;
    /**
     * 
     * @type {object}
     * @memberof MonitoredObject
     */
    metadata?: object | null;
    /**
     * 
     * @type {string}
     * @memberof MonitoredObject
     */
    note?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MonitoredObject
     */
    deleted: number;
    /**
     * 
     * @type {number}
     * @memberof MonitoredObject
     */
    status?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MonitoredObject
     */
    tankSize?: number;
    /**
     * 
     * @type {string}
     * @memberof MonitoredObject
     */
    customLabel?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MonitoredObject
     */
    vehicleTrainMaxWeight?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MonitoredObject
     */
    vehicleTractorMaxWeight?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MonitoredObject
     */
    cng?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof MonitoredObject
     */
    monitoringDevice?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MonitoredObject
     */
    monitoredObjectType: number;
    /**
     * 
     * @type {number}
     * @memberof MonitoredObject
     */
    driverApplication?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MonitoredObject
     */
    externalSystem: number;
    /**
     * 
     * @type {number}
     * @memberof MonitoredObject
     */
    countryOfRegistration?: number | null;
}

export function MonitoredObjectFromJSON(json: any): MonitoredObject {
    return MonitoredObjectFromJSONTyped(json, false);
}

export function MonitoredObjectFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitoredObject {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'clientId': json['client_id'],
        'client': !exists(json, 'client') ? undefined : ClientFromJSON(json['client']),
        'costPerKm': !exists(json, 'cost_per_km') ? undefined : json['cost_per_km'],
        'externalId': json['external_id'],
        'registrationNumber': json['registration_number'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'systemDescription': !exists(json, 'system_description') ? undefined : json['system_description'],
        'manufacturerName': !exists(json, 'manufacturer_name') ? undefined : json['manufacturer_name'],
        '_configuration': !exists(json, 'configuration') ? undefined : json['configuration'],
        'metadata': !exists(json, 'metadata') ? undefined : json['metadata'],
        'note': !exists(json, 'note') ? undefined : json['note'],
        'deleted': json['deleted'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'tankSize': !exists(json, 'tank_size') ? undefined : json['tank_size'],
        'customLabel': !exists(json, 'custom_label') ? undefined : json['custom_label'],
        'vehicleTrainMaxWeight': !exists(json, 'vehicle_train_max_weight') ? undefined : json['vehicle_train_max_weight'],
        'vehicleTractorMaxWeight': !exists(json, 'vehicle_tractor_max_weight') ? undefined : json['vehicle_tractor_max_weight'],
        'cng': !exists(json, 'cng') ? undefined : json['cng'],
        'monitoringDevice': !exists(json, 'monitoring_device') ? undefined : json['monitoring_device'],
        'monitoredObjectType': json['monitored_object_type'],
        'driverApplication': !exists(json, 'driver_application') ? undefined : json['driver_application'],
        'externalSystem': json['external_system'],
        'countryOfRegistration': !exists(json, 'country_of_registration') ? undefined : json['country_of_registration'],
    };
}

export function MonitoredObjectToJSON(value?: MonitoredObject | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'client_id': value.clientId,
        'client': ClientToJSON(value.client),
        'cost_per_km': value.costPerKm,
        'external_id': value.externalId,
        'registration_number': value.registrationNumber,
        'description': value.description,
        'system_description': value.systemDescription,
        'manufacturer_name': value.manufacturerName,
        'configuration': value._configuration,
        'metadata': value.metadata,
        'note': value.note,
        'deleted': value.deleted,
        'status': value.status,
        'tank_size': value.tankSize,
        'custom_label': value.customLabel,
        'vehicle_train_max_weight': value.vehicleTrainMaxWeight,
        'vehicle_tractor_max_weight': value.vehicleTractorMaxWeight,
        'cng': value.cng,
        'monitoring_device': value.monitoringDevice,
        'monitored_object_type': value.monitoredObjectType,
        'driver_application': value.driverApplication,
        'external_system': value.externalSystem,
        'country_of_registration': value.countryOfRegistration,
    };
}


