import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Logic } from 'logic/logic';
import DispatcherBlank from './ui/DispatcherBlank/DispatcherBlank';
import { Button } from '../../../../components/base/controls';
import DispatcherTable from './ui/DispatcherTable/DispatcherTable';
import { Notification, Drawer } from '../../../../components/base/layout';
import DispatcherCreateEditForm, {
    DispatcherFormModel,
    DispatcherFormType
} from './ui/DispatcherCreateEditForm/DispatcherCreateEditForm';
import selectors from 'qa-selectors';
import { observer } from 'mobx-react';
import { useStore } from 'stores/RootStore';
import { DispatcherModel } from 'services/api/domains/UsersApi';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import { useHistory } from 'react-router';
import { RouteNames } from 'router/routes';

interface Props {
    logic: Logic;
}

interface DispatcherTableModel {
    data?: DispatcherModel[];
    isLoading: boolean;
    dispatcherId?: number;
}

interface DrawerForm {
    visible: boolean;
    isLoading: boolean;
    title?: string;
    type?: DispatcherFormType;
    initValues?: DispatcherFormModel;
}

enum CompanyRoles {
    ADMIN = 'CompanyAdmin',
    DISPATCHER = 'Dispatcher'
}

const DispatcherModule = (props: Props) => {
    const { t } = useTranslation();
    const { isMobileWidth } = useWindowDimensions();
    const history = useHistory();
    const { userSettingsStore } = useStore();
    const { lang } = userSettingsStore;

    const [editId, setEditId] = useState<number>();
    const [disabled, setDisabled] = useState<boolean>(false);
    const [drawerForm, setDrawerForm] = useState<DrawerForm>({ visible: false, isLoading: false });
    const [table, setTable] = useState<DispatcherTableModel>({ isLoading: false });

    useEffect(() => {
        _loadTableData();
    }, []);

    const _loadTableData = async () => {
        setTable(prev => ({ ...prev, isLoading: true }));

        const dispatcher = await props.logic.apiService().users().getMe();
        const companyId = props.logic.company().getCompany().companyId;
        const data = await props.logic.apiService().company().getDispatchers(companyId);

        setTable(prev => ({
            ...prev,
            isLoading: false,
            data: data.length ? data : prev.data,
            dispatcherId: dispatcher.profileId
        }));
    };

    const _showAddDispatcher = () => {
        setDrawerForm({
            visible: true,
            isLoading: false,
            title: t('DispatcherCreateEditForm.createTitle'),
            type: DispatcherFormType.CREATE
        });
    };

    const _showEditDispatcher = async (user: DispatcherModel) => {
        const me = await props.logic.apiService().users().getMe();

        setDrawerForm({
            visible: true,
            isLoading: false,
            title: t('DispatcherCreateEditForm.editTitle'),
            type: DispatcherFormType.EDIT,
            initValues: {
                name: user.name,
                makeAdmin: user.isAdmin
            }
        });

        setEditId(user.profileId);
        setDisabled(user.profileId === me?.profileId);
    };

    const _onDeleteClick = async (userId: number) => {
        try {
            const companyId = props.logic.company().getCompany().companyId;
            await props.logic.apiService().users().deleteUser(companyId, String(userId), 'dispatcher');

            Notification.success({
                message: t('DispatcherCreateEditForm.deleteSuccess')
            });

            _loadTableData();
        } catch (e) {
            Notification.error({
                message: e.message
            });
        }
    };

    const _toggleAdminRole = async (userId: number, isAdmin: boolean) => {
        try {
            const companyId = props.logic.company().getCompany().companyId;
            const roles = [CompanyRoles.DISPATCHER];

            if (isAdmin) {
                roles.push(CompanyRoles.ADMIN);
            }

            await props.logic.apiService().company().setUserRoles(companyId, String(userId), roles);

            Notification.success({
                message: t(`DispatcherCreateEditForm.${isAdmin ? 'makeAdminSuccess' : 'deleteAdminSuccess'}`)
            });

            _loadTableData();

            setDrawerForm({
                visible: false,
                isLoading: false
            });
        } catch (err) {
            console.error(err);
            Notification.error({
                message: err.message
            });
        }
    };

    const _onCloseDrawer = () => {
        setDrawerForm({
            visible: false,
            isLoading: false
        });
    };

    const _onDispatcherCreateSubmit = async (data: DispatcherFormModel) => {
        setDrawerForm(prev => ({
            ...prev,
            visible: true,
            isLoading: true
        }));

        try {
            if (table.data?.some(u => u.name === data.email)) {
                throw Error(t('authorization.user.alreadyExists'));
            }

            await props.logic.apiService().users().postSettingsDispatcher(data.name, data.password!, data.email!, lang);

            _loadTableData();

            setDrawerForm({
                visible: false,
                isLoading: false
            });

            Notification.success({
                message: t('DispatcherCreateEditForm.createSuccess')
            });
        } catch (err) {
            setDrawerForm(prev => ({
                ...prev,
                isLoading: false
            }));

            console.error(err);
            const errMessage =
                err?.response?.status === 409
                    ? t('authorization.user.alreadyExists')
                    : t('authorization.registrationFailed');

            Notification.error({
                message: errMessage
            });
        }
    };

    const _onDispatcherEditSubmit = async (data: DispatcherFormModel) => {
        setDrawerForm(prev => ({
            ...prev,
            visible: true,
            isLoading: true
        }));

        try {
            await props.logic.apiService().users().putSettingsMe(data.name, data.password!, data.newPassword!);

            _loadTableData();
            setDrawerForm({
                visible: false,
                isLoading: false
            });

            Notification.success({
                message: t('DispatcherCreateEditForm.editSuccess')
            });
        } catch (err) {
            setDrawerForm(prev => ({
                ...prev,
                isLoading: false
            }));

            console.error(err);
            Notification.error({
                message: err.message
            });
        }
    };

    return (
        <div className="rl-settings-module rl-dispatcher-module">
            <div className="rl-settings-module-header">
                {isMobileWidth && (
                    <Button
                        className="rl-settings-module-header-back"
                        type="action"
                        icon={<i className="rl-icon-chevron-left" />}
                        onClick={() => history.push(RouteNames.SETTINGS)}
                    />
                )}

                <h3 className="rl-settings-module-header-title">{t('common.dispatchers')}</h3>

                {table.data?.length !== 0 && (
                    <Button
                        type="link"
                        onClick={_showAddDispatcher}
                        data-qa={selectors.dispatchers.addDispatcherButton}
                    >
                        {t('DispatcherSettings.addDispatcher')}
                    </Button>
                )}
            </div>
            <div className="rl-settings-module-content">
                {table.data?.length === 0 ? (
                    <DispatcherBlank onAddDispatcherClick={_showAddDispatcher} />
                ) : (
                    <DispatcherTable
                        data={table.data || []}
                        isLoading={table.isLoading}
                        dispatcherId={table.dispatcherId}
                        onDeleteClick={_onDeleteClick}
                        onEditClick={_showEditDispatcher}
                        onToggleAdminRole={_toggleAdminRole}
                        onRowDoubleClick={_showEditDispatcher}
                    />
                )}
            </div>

            <Drawer
                width={500}
                placement="right"
                className="rl-create-dispatcher-drawer"
                title={drawerForm.title}
                visible={drawerForm.visible}
                destroyOnClose={true}
                onClose={_onCloseDrawer}
            >
                <DispatcherCreateEditForm
                    profileId={editId}
                    disabled={disabled}
                    type={drawerForm.type!}
                    isLoading={drawerForm.isLoading}
                    initValues={drawerForm.initValues}
                    onCreateSubmit={_onDispatcherCreateSubmit}
                    onEditSubmit={_onDispatcherEditSubmit}
                    deleteUser={_onDeleteClick}
                />
            </Drawer>
        </div>
    );
};

export default observer(DispatcherModule);
