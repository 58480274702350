import cn from 'classnames';
import { Button } from 'components/base/controls';
import { Modal, Notification } from 'components/base/layout';
import { HowInviteDriverWork } from 'modules/InviteDriverModule/ui/HowInviteDriverWork';
import { WhatsappSendButton } from 'components/WhatsappSendButton';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { getInviteTextForWhatsApp } from 'utils/helpers/invite-driver';
import { InitiatedFrom } from 'logic/userEventsProviders/GoogleTagManager';

interface Props {
    inviteLink: string;
    size?: 'default' | 'large';
    onCreateNewLinkClick: () => void;
    onDisableLinkClick: () => void;
    onClickUserEvent: (initiatedFrom: InitiatedFrom) => void;
}

function InviteDriverBanner(props: Props) {
    const { t } = useTranslation();
    const inputEl = useRef<HTMLInputElement>(null);

    const onCopyClick = () => {
        inputEl.current?.select();
        navigator.clipboard.writeText(props.inviteLink);
        Notification.success({ message: t('InviteDriverBanner.linkCopied') });
        props.onClickUserEvent(InitiatedFrom.DRIVER_INVITATION_COPY_BUTTON);
    };

    const openHowItWorkModal = () => Modal.open({ width: 960, content: <HowInviteDriverWork /> });

    return (
        <div className={cn('rl-invite-driver', { large: props.size === 'large' })}>
            <div className="rl-invite-driver-title">
                <img src="/img/settings/drivers/drivers.svg" alt="Drivers avatar" />
                <span>{t('InviteDriverBanner.title')}</span>
            </div>

            <div className="invite-link-section">
                <div className="invite-link-input-wrapper">
                    <input
                        readOnly
                        value={props.inviteLink}
                        placeholder={t('InviteDriverBanner.placeholder')}
                        ref={inputEl}
                    />
                    <div className="invite-link-input-btns">
                        <Button
                            type="dark"
                            size={props.size === 'large' ? 'middle' : 'small'}
                            onClick={onCopyClick}
                            disabled={!props.inviteLink}
                        >
                            {t('InviteDriverBanner.copy')}
                        </Button>
                        <a href={`mailto:?body=${t('common.inviteDriverMailText', { link: props.inviteLink })}`}>
                            <Button
                                type="dark"
                                size={props.size === 'large' ? 'middle' : 'small'}
                                disabled={!props.inviteLink}
                                onClick={() => {
                                    props.onClickUserEvent(InitiatedFrom.DRIVER_INVITATION_SEND_MAIL_BUTTON);
                                }}
                            >
                                {t('InviteDriverBanner.sendToMail')}
                            </Button>
                        </a>
                        <WhatsappSendButton
                            text={getInviteTextForWhatsApp(props.inviteLink)}
                            size={props.size === 'large' ? 'middle' : 'small'}
                            disabled={!props.inviteLink}
                            onClick={() => {
                                props.onClickUserEvent(InitiatedFrom.DRIVER_INVITATION_WHATSAPP_BUTTON);
                            }}
                        />
                    </div>
                </div>
                <div className="actions">
                    <Button
                        type="link"
                        icon={
                            <span className="icon-wrapper">
                                <i className="fas fa-plus" />
                            </span>
                        }
                        onClick={props.onCreateNewLinkClick}
                    >
                        {t('InviteDriverBanner.createNewLink')}
                    </Button>
                    <Button
                        type="link"
                        icon={
                            <span className="icon-wrapper">
                                <i className="fas fa-times" />
                            </span>
                        }
                        onClick={props.onDisableLinkClick}
                    >
                        {t('InviteDriverBanner.disableLink')}
                    </Button>
                    <Button
                        type="link"
                        icon={
                            <span className="icon-wrapper">
                                <i className="fas fa-info" />
                            </span>
                        }
                        onClick={openHowItWorkModal}
                    >
                        {t('InviteDriverBanner.howItWork')}
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default InviteDriverBanner;
