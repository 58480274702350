/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InlineResponse2007,
    InlineResponse2007FromJSON,
    InlineResponse2007ToJSON,
    ReadOnlyCountry,
    ReadOnlyCountryFromJSON,
    ReadOnlyCountryToJSON,
    WriteOnlyCountry,
    WriteOnlyCountryFromJSON,
    WriteOnlyCountryToJSON,
} from '../models';

export interface CountryCreateRequest {
    data: WriteOnlyCountry;
}

export interface CountryDeleteRequest {
    id: number;
}

export interface CountryListRequest {
    name?: string;
    iso2?: string;
    iso3?: string;
    limit?: number;
    offset?: number;
}

export interface CountryPartialUpdateRequest {
    id: number;
    data: WriteOnlyCountry;
}

export interface CountryReadRequest {
    id: number;
}

export interface CountryUpdateRequest {
    id: number;
    data: WriteOnlyCountry;
}

/**
 * no description
 */
export class CountryApi extends runtime.BaseAPI {

    /**
     */
    async countryCreateRaw(requestParameters: CountryCreateRequest): Promise<runtime.ApiResponse<ReadOnlyCountry>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling countryCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/country/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyCountryToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyCountryFromJSON(jsonValue));
    }

    /**
     */
    async countryCreate(requestParameters: CountryCreateRequest): Promise<ReadOnlyCountry> {
        const response = await this.countryCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async countryDeleteRaw(requestParameters: CountryDeleteRequest): Promise<runtime.ApiResponse<ReadOnlyCountry>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling countryDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/country/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyCountryFromJSON(jsonValue));
    }

    /**
     */
    async countryDelete(requestParameters: CountryDeleteRequest): Promise<ReadOnlyCountry> {
        const response = await this.countryDeleteRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async countryListRaw(requestParameters: CountryListRequest): Promise<runtime.ApiResponse<InlineResponse2007>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.iso2 !== undefined) {
            queryParameters['iso2'] = requestParameters.iso2;
        }

        if (requestParameters.iso3 !== undefined) {
            queryParameters['iso3'] = requestParameters.iso3;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/country/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse2007FromJSON(jsonValue));
    }

    /**
     */
    async countryList(requestParameters: CountryListRequest): Promise<InlineResponse2007> {
        const response = await this.countryListRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async countryPartialUpdateRaw(requestParameters: CountryPartialUpdateRequest): Promise<runtime.ApiResponse<ReadOnlyCountry>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling countryPartialUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling countryPartialUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/country/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyCountryToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyCountryFromJSON(jsonValue));
    }

    /**
     */
    async countryPartialUpdate(requestParameters: CountryPartialUpdateRequest): Promise<ReadOnlyCountry> {
        const response = await this.countryPartialUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async countryReadRaw(requestParameters: CountryReadRequest): Promise<runtime.ApiResponse<ReadOnlyCountry>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling countryRead.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/country/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyCountryFromJSON(jsonValue));
    }

    /**
     */
    async countryRead(requestParameters: CountryReadRequest): Promise<ReadOnlyCountry> {
        const response = await this.countryReadRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async countryUpdateRaw(requestParameters: CountryUpdateRequest): Promise<runtime.ApiResponse<ReadOnlyCountry>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling countryUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling countryUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/country/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyCountryToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyCountryFromJSON(jsonValue));
    }

    /**
     */
    async countryUpdate(requestParameters: CountryUpdateRequest): Promise<ReadOnlyCountry> {
        const response = await this.countryUpdateRaw(requestParameters);
        return await response.value();
    }

}
