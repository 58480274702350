import { Conf } from 'conf';
import { HttpClient } from '../common/HttpClient';

const STORAGE_API_VERSION = '7';

export class StorageApi {
    constructor(private _conf: Conf['api'], private _httpClient: HttpClient) {}

    async getFromStorage<T = any>(key: string) {
        return this._httpClient.get<T>(`${this._conf.coreUrl}/storage?name=${key}`, {
            apiVersion: STORAGE_API_VERSION
        });
    }

    async setToStorage(value: { name: string; content: string; contentType?: string }) {
        return this._httpClient.put(`${this._conf.coreUrl}/storage`, value, {
            apiVersion: STORAGE_API_VERSION
        });
    }

    async deleteFromStorage(name: string) {
        return this._httpClient.delete(`${this._conf.coreUrl}/storage?name=${name}`, {
            apiVersion: STORAGE_API_VERSION
        });
    }
}
