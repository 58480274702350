/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ActivityInterval,
    ActivityIntervalFromJSON,
    ActivityIntervalFromJSONTyped,
    ActivityIntervalToJSON,
    TripDriver,
    TripDriverFromJSON,
    TripDriverFromJSONTyped,
    TripDriverToJSON,
    TripMetadata,
    TripMetadataFromJSON,
    TripMetadataFromJSONTyped,
    TripMetadataToJSON,
    TripPlace,
    TripPlaceFromJSON,
    TripPlaceFromJSONTyped,
    TripPlaceToJSON,
} from '.';

/**
 * 
 * @export
 * @interface TripInDatabase
 */
export interface TripInDatabase {
    /**
     * 
     * @type {ActivityInterval}
     * @memberof TripInDatabase
     */
    activityInterval?: ActivityInterval;
    /**
     * 
     * @type {string}
     * @memberof TripInDatabase
     */
    monitoredObjectId: string;
    /**
     * 
     * @type {string}
     * @memberof TripInDatabase
     */
    monitoringDeviceId: string;
    /**
     * 
     * @type {Date}
     * @memberof TripInDatabase
     */
    startTime: Date;
    /**
     * 
     * @type {Date}
     * @memberof TripInDatabase
     */
    endTime?: Date;
    /**
     * 
     * @type {TripPlace}
     * @memberof TripInDatabase
     */
    placeStart: TripPlace;
    /**
     * 
     * @type {TripPlace}
     * @memberof TripInDatabase
     */
    placeEnd?: TripPlace;
    /**
     * 
     * @type {number}
     * @memberof TripInDatabase
     */
    distance?: number;
    /**
     * 
     * @type {number}
     * @memberof TripInDatabase
     */
    canDistance?: number;
    /**
     * 
     * @type {number}
     * @memberof TripInDatabase
     */
    gpsDistance?: number;
    /**
     * 
     * @type {number}
     * @memberof TripInDatabase
     */
    odometerStart?: number;
    /**
     * 
     * @type {number}
     * @memberof TripInDatabase
     */
    canOdometerStart?: number;
    /**
     * 
     * @type {number}
     * @memberof TripInDatabase
     */
    gpsOdometerStart?: number;
    /**
     * 
     * @type {number}
     * @memberof TripInDatabase
     */
    odometerEnd?: number;
    /**
     * 
     * @type {number}
     * @memberof TripInDatabase
     */
    canOdometerEnd?: number;
    /**
     * 
     * @type {number}
     * @memberof TripInDatabase
     */
    gpsOdometerEnd?: number;
    /**
     * 
     * @type {number}
     * @memberof TripInDatabase
     */
    duration?: number;
    /**
     * 
     * @type {number}
     * @memberof TripInDatabase
     */
    totalConsumption?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TripInDatabase
     */
    isPrivate?: boolean;
    /**
     * 
     * @type {Array<TripDriver>}
     * @memberof TripInDatabase
     */
    drivers: Array<TripDriver>;
    /**
     * 
     * @type {Array<TripDriver>}
     * @memberof TripInDatabase
     */
    passengers: Array<TripDriver>;
    /**
     * 
     * @type {boolean}
     * @memberof TripInDatabase
     */
    closed: boolean;
    /**
     * 
     * @type {TripMetadata}
     * @memberof TripInDatabase
     */
    metadata?: TripMetadata;
    /**
     * 
     * @type {string}
     * @memberof TripInDatabase
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof TripInDatabase
     */
    idNew?: string;
}

export function TripInDatabaseFromJSON(json: any): TripInDatabase {
    return TripInDatabaseFromJSONTyped(json, false);
}

export function TripInDatabaseFromJSONTyped(json: any, ignoreDiscriminator: boolean): TripInDatabase {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'activityInterval': !exists(json, 'activity_interval') ? undefined : ActivityIntervalFromJSON(json['activity_interval']),
        'monitoredObjectId': json['monitoredObjectId'],
        'monitoringDeviceId': json['monitoringDeviceId'],
        'startTime': (new Date(json['startTime'])),
        'endTime': !exists(json, 'endTime') ? undefined : (new Date(json['endTime'])),
        'placeStart': TripPlaceFromJSON(json['placeStart']),
        'placeEnd': !exists(json, 'placeEnd') ? undefined : TripPlaceFromJSON(json['placeEnd']),
        'distance': !exists(json, 'distance') ? undefined : json['distance'],
        'canDistance': !exists(json, 'canDistance') ? undefined : json['canDistance'],
        'gpsDistance': !exists(json, 'gpsDistance') ? undefined : json['gpsDistance'],
        'odometerStart': !exists(json, 'odometerStart') ? undefined : json['odometerStart'],
        'canOdometerStart': !exists(json, 'canOdometerStart') ? undefined : json['canOdometerStart'],
        'gpsOdometerStart': !exists(json, 'gpsOdometerStart') ? undefined : json['gpsOdometerStart'],
        'odometerEnd': !exists(json, 'odometerEnd') ? undefined : json['odometerEnd'],
        'canOdometerEnd': !exists(json, 'canOdometerEnd') ? undefined : json['canOdometerEnd'],
        'gpsOdometerEnd': !exists(json, 'gpsOdometerEnd') ? undefined : json['gpsOdometerEnd'],
        'duration': !exists(json, 'duration') ? undefined : json['duration'],
        'totalConsumption': !exists(json, 'totalConsumption') ? undefined : json['totalConsumption'],
        'isPrivate': !exists(json, 'isPrivate') ? undefined : json['isPrivate'],
        'drivers': ((json['drivers'] as Array<any>).map(TripDriverFromJSON)),
        'passengers': ((json['passengers'] as Array<any>).map(TripDriverFromJSON)),
        'closed': json['closed'],
        'metadata': !exists(json, 'metadata') ? undefined : TripMetadataFromJSON(json['metadata']),
        'id': json['id'],
        'idNew': !exists(json, 'id_new') ? undefined : json['id_new'],
    };
}

export function TripInDatabaseToJSON(value?: TripInDatabase | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'activity_interval': ActivityIntervalToJSON(value.activityInterval),
        'monitoredObjectId': value.monitoredObjectId,
        'monitoringDeviceId': value.monitoringDeviceId,
        'startTime': (value.startTime.toISOString()),
        'endTime': value.endTime === undefined ? undefined : (value.endTime.toISOString()),
        'placeStart': TripPlaceToJSON(value.placeStart),
        'placeEnd': TripPlaceToJSON(value.placeEnd),
        'distance': value.distance,
        'canDistance': value.canDistance,
        'gpsDistance': value.gpsDistance,
        'odometerStart': value.odometerStart,
        'canOdometerStart': value.canOdometerStart,
        'gpsOdometerStart': value.gpsOdometerStart,
        'odometerEnd': value.odometerEnd,
        'canOdometerEnd': value.canOdometerEnd,
        'gpsOdometerEnd': value.gpsOdometerEnd,
        'duration': value.duration,
        'totalConsumption': value.totalConsumption,
        'isPrivate': value.isPrivate,
        'drivers': ((value.drivers as Array<any>).map(TripDriverToJSON)),
        'passengers': ((value.passengers as Array<any>).map(TripDriverToJSON)),
        'closed': value.closed,
        'metadata': TripMetadataToJSON(value.metadata),
        'id': value.id,
        'id_new': value.idNew,
    };
}


