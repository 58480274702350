import { Radio, RadioGroupProps } from 'antd';
import { RadioProps } from 'antd/lib/radio';

interface Props extends RadioProps {
    qa?: string;
}

function EnhancedRadio({ qa, ...props }: Props) {
    return <Radio data-qa={qa} {...props} />;
}

EnhancedRadio.Group = (props: RadioGroupProps) => {
    return (
        <Radio.Group {...props} className={props.className ? 'rl-radio-group ' + props.className : 'rl-radio-group'} />
    );
};

EnhancedRadio.Button = Radio.Button;

export default EnhancedRadio;
