import cn from 'classnames';
import {
    PlacesModel,
    PlacesTaskModel,
    TransportModel,
    Avoids,
    CostPerKm,
    PlannerVehicleProfile,
    PlaceSuggestionSource,
    SearchFilter
} from 'modules/PlannerModule/PlannerModule';
import PlannerAutocomplete from 'modules/PlannerModule/ui/PlannerAutocomplete';
import { useTranslation } from 'react-i18next';
import { Moment } from 'moment';
import { AlarmModel } from 'modules/PlannerModule/ui/PlannerAlarms';
import RouteConfigurator from 'modules/PlannerModule/ui/PlannerRouteConfigurator/PlannerRouteConfigurator';
import PlannerVehicle from 'modules/PlannerModule/ui/PlannerVehicle';
import PlacesConfigurator from 'modules/PlannerModule/ui/PlacesConfigurator';
import PlaceSettings from 'modules/PlannerModule/ui/PlaceSettings';
import PlannerSearchFooter from 'modules/PlannerModule/ui/PlannerSearchFooter';
import PlannerRouteOptions from 'modules/PlannerModule/ui/PlannerRouteOptions';
import PlannerRouteAvoids from 'modules/PlannerModule/ui/PlannerRouteAvoids';
import { TollCosts } from 'services/api/domains/TollsApi';
import { Spin } from 'components/base/controls';
import { CompanyVehicle, VehicleProfile } from '../../../../services/api/domains/VehiclesApi';
import { PoiModel } from 'logic/poi';

interface Props {
    searchInProgress?: boolean;
    places: PlacesModel[];
    task: {
        placeId?: string;
        task?: PlacesTaskModel;
    };
    autoComplete: {
        text: string;
        displayedSuggestions: { label: string; value: string; source: PlaceSuggestionSource }[];
        allSuggestions: { label: string; value: string; source: PlaceSuggestionSource }[];
        isTyping?: boolean;
    };
    routeOptions: {
        avoids: Avoids;
        showRouteAvoids: boolean;
    };
    vehicleProfiles: {
        vehicles?: CompanyVehicle[];
        profiles?: VehicleProfile[];
    };
    filter?: SearchFilter;
    loading?: boolean;
    tollCostsLoading?: boolean;
    alarms?: AlarmModel[];
    tollCosts?: TollCosts;
    transportName?: string;
    clientName?: string;
    isLoggedIn?: boolean;
    transport?: TransportModel;
    tollCostsDetailVisible?: boolean;
    countries: { [code: string]: { name: string; code: string } };
    includeTollCosts: boolean;
    isEditTransport: boolean;
    vehicleProfile?: PlannerVehicleProfile;
    currency: string;
    isMobileWidth?: boolean;
    isAutocompleteMobileOpen?: boolean;
    transportUpdated?: boolean;
    onTransportNameChange?: (name: string) => void;
    onTransportClientNameChange?: (name: string) => void;
    onPlacesRtaChange: (id: string, value: Moment) => void;
    onPlaceTaskClick: (id: string) => void;
    onPlacesDeleteClick: (id: string) => void;
    onPlacesDragAndDrop: (oldIndex: number, newIndex: number) => void;
    onPlacesTaskCancel: () => void;
    onPlacesTaskSave: (model: PlacesTaskModel, placeId: string, reloadRoute: boolean) => void;
    onAutocompleteSubmit: (value: string) => void;
    onAutocompleteChange: (text: string, focus?: boolean) => void;
    onPlannerSave?: () => void;
    onPlannerDelete?: () => void;
    onPlannerCancel?: () => void;
    onFilterChange?: (type: SearchFilter, text: string) => void;
    onAlarmsConfigChange?: (config: AlarmModel[]) => void;
    onAlarmsConfigCancel?: () => void;
    onCostPerKmChange?: (costPerKm: CostPerKm) => void;
    onVehicleChange?: (vehicleProfile: PlannerVehicleProfile) => void;
    onShowRouteAvoids?: () => void;
    onCreateProfileClick?: () => void;
    onAvoidsChange?: (countryCode: string, avoidType: string, value: boolean) => void;
    onTollCostsClick?: (checked: boolean) => void;
    onToggleAutocompleteMobileMenu?: (isMenuOpen: boolean) => void;
    onShowPoiBarVisible?: (model: PoiModel) => void;
    onTransportNoteChange?: (note: string) => void;
}

const Planner = (props: Props) => {
    const { t } = useTranslation();

    const onPlannerConfiguratorClose = () => {
        props.onPlacesTaskCancel();
        props.onAlarmsConfigCancel?.();
    };

    return (
        <div
            className={cn('rl-planner-wrapper', {
                'rl-planner-wrapper-planned': props.places?.length,
                'rl-planner-wrapper-autocomplete-is-open': props.isAutocompleteMobileOpen
            })}
        >
            <div
                className={cn('rl-planner-main', {
                    'rl-planner-main-planned': props.places?.length
                })}
            >
                <div className="rl-planner-search">
                    <div className="rl-planner-title">
                        {props.isEditTransport ? t('PlannerBar.editTitle') : t('PlannerBar.title')}
                    </div>

                    {!props.isAutocompleteMobileOpen && (
                        <PlannerVehicle
                            profiles={props.vehicleProfiles.profiles ?? []}
                            vehicles={props.vehicleProfiles.vehicles ?? []}
                            vehicleProfile={props.vehicleProfile}
                            loading={props.loading}
                            currency={props.currency}
                            isMobileWidth={props.isMobileWidth}
                            onCostPerKmChange={props.onCostPerKmChange}
                            onVehicleChange={props.onVehicleChange}
                            onCreateProfileClick={props.onCreateProfileClick}
                        />
                    )}

                    <Spin loading={!!props.loading} tip={t('common.loading')} opacity={true}>
                        {!!props.places.length && !props.isAutocompleteMobileOpen && (
                            <PlacesConfigurator
                                loading={props.loading}
                                places={props.places}
                                placeId={props.task?.placeId}
                                onRtaChange={props.onPlacesRtaChange}
                                onPlaceTaskClick={props.onPlaceTaskClick}
                                onSortEnd={props.onPlacesDragAndDrop}
                                onDeleteClick={props.onPlacesDeleteClick}
                            />
                        )}

                        <PlannerAutocomplete
                            allSuggestions={props.autoComplete.allSuggestions}
                            displayedSuggestions={props.autoComplete.displayedSuggestions}
                            text={props.autoComplete.text}
                            isTyping={props.autoComplete.isTyping}
                            filter={props.filter}
                            onChange={props.onAutocompleteChange}
                            onSubmit={props.onAutocompleteSubmit}
                            onFilterChange={props.onFilterChange}
                            searchInProgress={props.searchInProgress}
                            toggleAutocompleteMobileMenu={props.onToggleAutocompleteMobileMenu}
                            isAutocompleteMobileOpen={props.isAutocompleteMobileOpen}
                            onShowPoiBarVisible={props.onShowPoiBarVisible}
                        />
                    </Spin>
                </div>
                {!props.isAutocompleteMobileOpen && (
                    <>
                        {props.places.length > 1 && (
                            <>
                                <PlannerRouteOptions
                                    loading={props.loading || props.tollCostsLoading}
                                    transport={props.transport}
                                    countries={props.countries}
                                    avoids={props.routeOptions.avoids}
                                    tollCosts={props.tollCosts}
                                    includeTollCosts={props.includeTollCosts}
                                    isLoggedIn={props.isLoggedIn}
                                    onAvoidsChange={props.onAvoidsChange}
                                    onRouteAvoids={props.onShowRouteAvoids}
                                    onTollCostsClick={props.onTollCostsClick}
                                />

                                <RouteConfigurator
                                    transport={props.transport}
                                    tollCosts={props.tollCosts}
                                    name={props.transportName}
                                    clientName={props.clientName}
                                    tollCostsDetailVisible={props.tollCostsDetailVisible}
                                    vehicleProfile={props.vehicleProfile}
                                    loading={props.loading || props.tollCostsLoading}
                                    currency={props.currency}
                                    onNameChange={props.onTransportNameChange}
                                    onClientNameChange={props.onTransportClientNameChange}
                                    onRouteAvoids={props.onShowRouteAvoids}
                                    onNoteChange={props.onTransportNoteChange}
                                />
                            </>
                        )}

                        {props.places.length > 0 && (
                            <PlannerSearchFooter
                                places={props.places}
                                loading={props.loading}
                                isEditTransport={props.isEditTransport}
                                transportState={props.transport?.state}
                                onPlannerCancel={props.onPlannerCancel}
                                onPlannerSave={props.onPlannerSave}
                                onPlannerDelete={props.onPlannerDelete}
                                transportUpdated={props.transportUpdated}
                            />
                        )}
                    </>
                )}
            </div>

            {!props.isAutocompleteMobileOpen && (
                <>
                    {props.task.task && props.task.placeId && (
                        <PlaceSettings
                            onClose={onPlannerConfiguratorClose}
                            placeId={props.task.placeId}
                            loading={props.loading}
                            task={props.task.task}
                            alarms={props.alarms}
                            onPlacesTaskSave={props.onPlacesTaskSave}
                            onAlarmConfigChange={props.onAlarmsConfigChange}
                        />
                    )}
                    {props.routeOptions.showRouteAvoids && (
                        <PlannerRouteAvoids
                            loading={props.loading || props.tollCostsLoading}
                            transport={props.transport}
                            avoids={props.routeOptions.avoids}
                            tollCosts={props.tollCosts}
                            countries={props.countries}
                            tollCostsDetailVisible={props.tollCostsDetailVisible}
                            onAvoidsChange={props.onAvoidsChange}
                            onClose={props.onShowRouteAvoids}
                        />
                    )}
                </>
            )}
        </div>
    );
};

export default Planner;
