/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    APIPriceList,
    APIPriceListFromJSON,
    APIPriceListFromJSONTyped,
    APIPriceListToJSON,
    Client,
    ClientFromJSON,
    ClientFromJSONTyped,
    ClientToJSON,
} from '.';

/**
 * 
 * @export
 * @interface MonitoringDevice
 */
export interface MonitoringDevice {
    /**
     * 
     * @type {number}
     * @memberof MonitoringDevice
     */
    readonly id?: number;
    /**
     * 
     * @type {number}
     * @memberof MonitoringDevice
     */
    clientId: number;
    /**
     * 
     * @type {Client}
     * @memberof MonitoringDevice
     */
    client?: Client;
    /**
     * 
     * @type {object}
     * @memberof MonitoringDevice
     */
    permissions?: object;
    /**
     * 
     * @type {Array<string>}
     * @memberof MonitoringDevice
     */
    readonly modules?: Array<string>;
    /**
     * 
     * @type {Array<APIPriceList>}
     * @memberof MonitoringDevice
     */
    readonly priceLists?: Array<APIPriceList>;
    /**
     * 
     * @type {number}
     * @memberof MonitoringDevice
     */
    readonly deleted?: number;
    /**
     * 
     * @type {object}
     * @memberof MonitoringDevice
     */
    metadata?: object | null;
    /**
     * 
     * @type {string}
     * @memberof MonitoringDevice
     */
    serialNumber: string;
    /**
     * 
     * @type {string}
     * @memberof MonitoringDevice
     */
    externalVehicleId?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MonitoringDevice
     */
    monitoringDeviceType: number;
    /**
     * 
     * @type {number}
     * @memberof MonitoringDevice
     */
    monitoringDeviceStatus?: number;
    /**
     * 
     * @type {number}
     * @memberof MonitoringDevice
     */
    externalSystem?: number | null;
}

export function MonitoringDeviceFromJSON(json: any): MonitoringDevice {
    return MonitoringDeviceFromJSONTyped(json, false);
}

export function MonitoringDeviceFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitoringDevice {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'clientId': json['client_id'],
        'client': !exists(json, 'client') ? undefined : ClientFromJSON(json['client']),
        'permissions': !exists(json, 'permissions') ? undefined : json['permissions'],
        'modules': !exists(json, 'modules') ? undefined : json['modules'],
        'priceLists': !exists(json, 'price_lists') ? undefined : ((json['price_lists'] as Array<any>).map(APIPriceListFromJSON)),
        'deleted': !exists(json, 'deleted') ? undefined : json['deleted'],
        'metadata': !exists(json, 'metadata') ? undefined : json['metadata'],
        'serialNumber': json['serial_number'],
        'externalVehicleId': !exists(json, 'external_vehicle_id') ? undefined : json['external_vehicle_id'],
        'monitoringDeviceType': json['monitoring_device_type'],
        'monitoringDeviceStatus': !exists(json, 'monitoring_device_status') ? undefined : json['monitoring_device_status'],
        'externalSystem': !exists(json, 'external_system') ? undefined : json['external_system'],
    };
}

export function MonitoringDeviceToJSON(value?: MonitoringDevice | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'client_id': value.clientId,
        'client': ClientToJSON(value.client),
        'permissions': value.permissions,
        'metadata': value.metadata,
        'serial_number': value.serialNumber,
        'external_vehicle_id': value.externalVehicleId,
        'monitoring_device_type': value.monitoringDeviceType,
        'monitoring_device_status': value.monitoringDeviceStatus,
        'external_system': value.externalSystem,
    };
}


