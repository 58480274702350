import mapboxgl from 'mapbox-gl';
import { LngLatBounds } from 'mapbox-gl';

export class BoudingBoxCache {
    private _squareCountCoef: number;
    private _newBoundingBoxesLimit: number;

    squareCache = new Set();

    constructor(_squareCountCoef: number, newBoundingBoxesLimit: number) {
        this._squareCountCoef = _squareCountCoef;
        this._newBoundingBoxesLimit = newBoundingBoxesLimit;
    }

    getNewBoundingBoxes(boundingBox: LngLatBounds) {
        const boundList = this._getVisibleBoxes(boundingBox);
        let newBoundingBoxes = boundList.filter(e => !this.squareCache.has(e.id));

        if (this.squareCache.size + newBoundingBoxes.length > this._newBoundingBoxesLimit) {
            newBoundingBoxes = boundList;
        }

        return newBoundingBoxes;
    }

    private _getVisibleBoxes(boundingBox: LngLatBounds) {
        const startLng = Math.floor(boundingBox.getSouthWest().lng * this._squareCountCoef);
        const endLng = Math.ceil(boundingBox.getNorthEast().lng * this._squareCountCoef);
        const startLat = Math.floor(boundingBox.getSouthWest().lat * this._squareCountCoef);
        const endLat = Math.ceil(boundingBox.getNorthEast().lat * this._squareCountCoef);
        const squares = [];

        for (let lng = startLng; lng < endLng; lng++) {
            for (let lat = startLat; lat < endLat; lat++) {
                squares.push({
                    id: this._getPoiSquareId({ lng, lat }),
                    boundingBox: new mapboxgl.LngLatBounds(
                        [lng / this._squareCountCoef, lat / this._squareCountCoef],
                        [(lng + 1) / this._squareCountCoef, (lat + 1) / this._squareCountCoef]
                    )
                });
            }
        }

        return squares;
    }

    private _getPoiSquareId({ lng, lat }: { lng: number; lat: number }) {
        return `${Math.floor(lng * this._squareCountCoef)}_${Math.floor(lat * this._squareCountCoef)}`;
    }
}
