import { useTranslation } from 'react-i18next';
import cn from 'classnames';

export enum PlannerView {
    ROUTE_PLANNER,
    MAP_VIEW
}

interface Props {
    view: PlannerView;
    onChangeView: (view: PlannerView) => void;
}

export function PlannerSwitch(props: Props) {
    const { t } = useTranslation();

    return (
        <div className="rl-planner-switch">
            <div
                className={cn('rl-planner-switch-tab', {
                    'rl-planner-switch-active': props.view === PlannerView.ROUTE_PLANNER
                })}
                onClick={() => props.onChangeView(PlannerView.ROUTE_PLANNER)}
            >
                <i className="rl-planner-switch-icon rl-icon-route" />
                {t('PlannerSwitch.routePlanner')}
            </div>
            <div
                className={cn('rl-planner-switch-tab', {
                    'rl-planner-switch-active': props.view === PlannerView.MAP_VIEW
                })}
                onClick={() => props.onChangeView(PlannerView.MAP_VIEW)}
            >
                <i className="rl-planner-switch-icon rl-icon-map" />
                {t('PlannerSwitch.mapView')}
            </div>
        </div>
    );
}
