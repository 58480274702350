import { Form } from 'antd';
import { Button, Input, Checkbox } from '../../../../../../components/base/controls';
import { useTranslation } from 'react-i18next';
import selectors from 'qa-selectors';

interface Props {
    type: DispatcherFormType;
    initValues?: DispatcherFormModel;
    profileId?: number;
    isLoading?: boolean;
    disabled: boolean;
    onClose?: () => void;
    onCreateSubmit: (data: DispatcherFormModel) => void;
    onEditSubmit: (data: DispatcherFormModel) => void;
    deleteUser: (profileId: number) => void;
}

export interface DispatcherFormModel {
    name: string;
    email?: string;
    makeAdmin: boolean;
    password?: string;
    newPassword?: string;
    confirmPassword?: string;
}

export enum DispatcherFormType {
    CREATE,
    EDIT
}

export default function DispatcherCreateEditForm(props: Props) {
    const { t } = useTranslation();

    function onFinish(data: DispatcherFormModel) {
        if (props.type === DispatcherFormType.CREATE) {
            props.onCreateSubmit(data);
        } else {
            props.onEditSubmit(data);
        }
    }

    return (
        <div className="rl-settings-dispatcher-form">
            <div className="rl-settings-dispatcher-form-content">
                <Form
                    id="dispatcherForm"
                    layout="vertical"
                    name="DispatcherAddForm"
                    onFinish={onFinish}
                    autoComplete="off"
                    requiredMark={false}
                    initialValues={{ ...props.initValues }}
                >
                    <Form.Item label={t('DispatcherCreateEditForm.name')} name="name" rules={[{ required: true }]}>
                        <Input data-qa={selectors.dispatchers.addDispatcherModal.nameInput} />
                    </Form.Item>

                    {props.type === DispatcherFormType.CREATE && (
                        <Form.Item
                            label={t('DispatcherCreateEditForm.email')}
                            name="email"
                            rules={[{ required: true, type: 'email' }]}
                            validateTrigger="onBlur"
                        >
                            <Input data-qa={selectors.dispatchers.addDispatcherModal.emailInput} />
                        </Form.Item>
                    )}

                    <Form.Item
                        label={t(
                            `DispatcherCreateEditForm.${
                                props.type === DispatcherFormType.CREATE ? 'password' : 'oldPassword'
                            }`
                        )}
                        name="password"
                        rules={[
                            { required: props.type === DispatcherFormType.CREATE, min: 8 },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    return props.type === DispatcherFormType.EDIT &&
                                        getFieldValue('newPassword') &&
                                        !value
                                        ? Promise.reject(t('validator.required'))
                                        : Promise.resolve();
                                }
                            })
                        ]}
                    >
                        <Input type="password" data-qa={selectors.dispatchers.addDispatcherModal.passwordInput} />
                    </Form.Item>

                    {props.type === DispatcherFormType.CREATE ? (
                        <Form.Item
                            label={t('DispatcherCreateEditForm.confirmPassword')}
                            name="confirmPassword"
                            dependencies={['password']}
                            hasFeedback
                            rules={[
                                { required: true },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(
                                            new Error(t('DispatcherCreateEditForm.passwordDontMatch'))
                                        );
                                    }
                                })
                            ]}
                        >
                            <Input
                                type="password"
                                data-qa={selectors.dispatchers.addDispatcherModal.confirmPasswordInput}
                            />
                        </Form.Item>
                    ) : (
                        <Form.Item
                            label={t('DispatcherCreateEditForm.newPassword')}
                            name="newPassword"
                            dependencies={['password']}
                            rules={[
                                { min: 8 },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        return getFieldValue('password') && !value
                                            ? Promise.reject(t('validator.required'))
                                            : Promise.resolve();
                                    }
                                })
                            ]}
                        >
                            <Input type="password" />
                        </Form.Item>
                    )}

                    {props.type === DispatcherFormType.EDIT && (
                        <Form.Item name="makeAdmin" valuePropName="checked">
                            <Checkbox disabled={props.disabled}>{t('DispatcherCreateEditForm.makeAdmin')}</Checkbox>
                        </Form.Item>
                    )}
                </Form>
            </div>

            <div className="rl-settings-dispatcher-form-actions">
                {props.type === DispatcherFormType.CREATE && (
                    <Button
                        type="primary"
                        form="dispatcherForm"
                        style={{ width: '100%' }}
                        htmlType="submit"
                        loading={props.isLoading}
                        data-qa={selectors.dispatchers.addDispatcherModal.saveButton}
                    >
                        {t('DispatcherCreateEditForm.create')}
                    </Button>
                )}
                {props.type === DispatcherFormType.EDIT && (
                    <Button
                        type="primary"
                        form="dispatcherForm"
                        style={{ width: '100%' }}
                        htmlType="submit"
                        loading={props.isLoading}
                    >
                        {t('common.saveChanges')}
                    </Button>
                )}
            </div>
        </div>
    );
}
