export const BRAND_ID_TO_ICON = {
    1: 'agip.svg',
    2: 'aral.svg',
    5: 'avia.svg',
    7: 'bp.svg',
    16: 'dkv.svg',
    20: 'esso.svg',
    21: 'ew.svg',
    35: 'omv.svg',
    41: 'q8.svg',
    44: 'shell.svg'
};

export const BRAND_NAME_TO_ICON = {
    shell: 'shell.svg',
    as_24: 'default-icon.svg',
    dkv: 'dkv.svg',
    aral: 'aral.svg',
    esso: 'esso.svg',
    allstar: 'allstar.svg',
    agip: 'agip.svg',
    repsol: 'repsol.svg',
    berilo: 'default-icon.svg',
    neste: 'neste.svg',
    travelcard: 'travelcard.svg',
    q8: 'q8.svg',
    eurowag: 'ew.svg',
    orlen: 'orlen.svg',
    omv: 'omv.svg',
    xximo: 'xximo.svg',
    galp: 'galp.svg'
};
