import { useTranslation } from 'react-i18next';
import { VehicleStateObject } from 'services/api/domains/VehiclesApi';

interface Props {
    selectedVehicle: VehicleStateObject | null;
    onClick: () => void;
}

export const VehicleSelectorButton = (props: Props) => {
    const { t } = useTranslation();

    return (
        <div className="rl-vehicle-selector-button" onClick={props.onClick}>
            <div className="rl-vehicle-selector-button-text">
                <span className="rl-vehicle-selector-button-text-rn">
                    {props.selectedVehicle ? props.selectedVehicle.rn : 'Unassigned transports'}
                </span>
                {props.selectedVehicle && (
                    <span className="rl-vehicle-selector-button-text-driver">
                        {props.selectedVehicle?.driver?.id
                            ? props.selectedVehicle.driver.name
                            : t('DispatcherBoard.noDriver')}
                    </span>
                )}
            </div>
            <i className="rl-icon-chevron-down"></i>
        </div>
    );
};
