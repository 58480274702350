import moment from 'moment';

export function formattedDuration(duration: moment.Duration | undefined): string {
    if (duration === undefined) return '';

    let format = '';
    if (Math.abs(duration.get('years')) > 0) format += `${Math.abs(duration.get('years'))}Y `;
    if (Math.abs(duration.get('months')) > 0) format += `${Math.abs(duration.get('months'))}M `;
    if (Math.abs(duration.get('days')) > 0) format += `${Math.abs(duration.get('days'))}D `;
    if (Math.abs(duration.get('hours')) > 0) format += `${Math.abs(duration.get('hours'))}h `;
    if (Math.abs(duration.get('minutes')) > 0) format += `${Math.abs(duration.get('minutes'))}m`;

    return format;
}

export const sinceDate = (lastUpdate: string) => {
    return moment.duration(moment().diff(moment(lastUpdate).toDate()));
};

export const remainingTimeDurationFormat = (eta?: string) => {
    const remainingTime = eta && moment(eta).diff(moment());
    return remainingTime && remainingTime > 0
        ? `${moment.duration(remainingTime).days() ? moment.duration(remainingTime).days() + 'd' : ''} ${
              moment.duration(remainingTime).hours() ? moment.duration(remainingTime).hours() + 'h' : ''
          } ${moment.duration(remainingTime).minutes() ? moment.duration(remainingTime).minutes() + 'm' : ''}`
        : 0;
};
