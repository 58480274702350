import cn from 'classnames';
import 'flag-icons/css/flag-icons.min.css';
import { Country } from 'services/api/domains/CountryApi';
import { UserEvent } from 'logic/userEventsProviders/GoogleTagManager';
import { CalendarViewBar } from '../../CalendarViewModule';
import { Dropdown, Menu, Tooltip } from 'components/base/controls';
import { useTranslation } from 'react-i18next';
import { CSSProperties, memo, useEffect, useRef, useState } from 'react';
import { Draggable, DraggableStateSnapshot } from '@hello-pangea/dnd';
import moment from 'moment';
import CalendarViewTransportInfo from '../CalendarViewTransportInfo/CalendarViewTransportInfo';
import CalendarViewTransportEta from '../CalendarViewTransportEta/CalendarViewTransportEta';
import CalendarViewTransportNote from '../CalendarViewTransportNote/CalendarViewTransportNote';
import { CompanyVehicle, VehicleStateObject } from 'services/api/domains/VehiclesApi';
import { TransportModel } from 'logic/dispatcher-board';
import { TransportState } from 'services/api/domains/TransportsApi';
import useSingleAndDoubleClick from 'hooks/useSingleAndDoubleClick';
import { DaysSegmentType } from '../../../common/types/DaySegment';
import CalendarViewTransportPopover from '../CalendarViewTransportPopover/CalendarViewTransportPopover';
import { getLastPlaceIso2Code } from 'modules/DispatcherBoardModule/common/utils/place';

interface Props {
    countryList: Country[];
    bar: CalendarViewBar;
    startDate: string;
    transport: TransportModel;
    vehicle?: CompanyVehicle;
    vehicleState?: VehicleStateObject;
    transportDetailId?: string;
    firstTransportPopoverVisible: boolean;
    onTransportEditClick?: (transportId: string, vehicleId?: string) => void;
    onTransportDetailClick?: (transport: TransportModel) => void;
    onOpenDeleteTransportModal: (transport: TransportModel) => void;
    onUnassignTransport: (transportId: string) => void;
    onOpenAssignVehicleModal: (transport: TransportModel) => void;
    onTransportNoteChange: (transportId: string, note: string, cb: VoidFunction) => void;
    onTransportNoteEvent: (event: UserEvent, transportId: string, vehicleId?: number) => void;
    onFirstTransportPopoverClose?: () => void;
}

const CalendarViewTransport = (props: Props) => {
    const { t } = useTranslation();
    const transportRef = useRef<HTMLDivElement>(null);
    const noteRef = useRef<HTMLDivElement>(null);

    const [transportSize, setTransportSize] = useState<{ clientHeight: number; clientWidth: number } | null>(null);
    const [visibleTooltip, setVisibleTooltip] = useState(false);
    const [visibleDropdown, setVisibleDropdown] = useState(false);
    const [transportDetail, setTransportDetail] = useState(false);
    const [activeEditor, setActiveEditor] = useState(false);

    const DAY_PERCENTAGE_WIDTH = 100 / props.bar.daySegment;
    const DAY_PERCENTAGE_OFFSET = props.bar.daySegment === DaysSegmentType.day5 ? 0 : 2;
    const MINUTES_IN_DAY = 1440;

    useEffect(() => {
        if (transportRef.current) {
            setTransportSize({
                clientHeight: transportRef.current.clientHeight + (noteRef.current?.clientHeight || 0),
                clientWidth: transportRef.current.clientWidth
            });
        }
    }, [props.bar, props.transport]);

    useEffect(() => {
        setTransportDetail(props.transport.id === props.transportDetailId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.transportDetailId]);

    const firstPlace = props.transport.places[0];
    const lastPlace = props.transport.places[props.transport.places.length - 1];
    const firstRtaDiffStartDate = moment(firstPlace.rta).diff(moment(props.startDate), 'minutes');
    const activeTransport = props.vehicleState?.activeTransports?.filter(t => t.id === props.transport.id)[0];
    const duration = moment.duration(moment(props.transport.eta).diff(moment(props.transport.lastPlaceRta)));
    const marginLeft =
        firstRtaDiffStartDate > 0
            ? (DAY_PERCENTAGE_WIDTH * firstRtaDiffStartDate) / MINUTES_IN_DAY + DAY_PERCENTAGE_OFFSET
            : 0;

    const render =
        moment(lastPlace.rta).isAfter(props.startDate) &&
        moment(firstPlace.rta).isBefore(moment(props.startDate).add(4, 'days').endOf('day'));

    const renderEta = !!(
        activeTransport?.nextWaypoint?.eta &&
        moment.utc(activeTransport.nextWaypoint.eta).isAfter(moment.utc(props.startDate))
    );

    const width =
        firstRtaDiffStartDate > 0
            ? DAY_PERCENTAGE_WIDTH *
              (Math.abs(moment(lastPlace.rta).diff(moment(firstPlace.rta), 'minutes')) / MINUTES_IN_DAY)
            : DAY_PERCENTAGE_WIDTH *
              (Math.abs(moment(props.startDate).diff(moment(lastPlace.rta), 'minutes')) / MINUTES_IN_DAY);

    const etaWidth =
        duration.asMinutes() < 0
            ? width - DAY_PERCENTAGE_WIDTH * (Math.abs(duration.asMinutes()) / MINUTES_IN_DAY)
            : DAY_PERCENTAGE_WIDTH * (Math.abs(duration.asMinutes()) / MINUTES_IN_DAY) + width;

    const etaWidthPixel = transportSize ? (etaWidth * transportSize.clientWidth) / width : 0;

    const dropdownMenu = (
        <Menu id="transport-dropdown-menu" onClick={e => e.domEvent.stopPropagation()}>
            <Menu.Item
                key="0"
                onClick={() => {
                    props.onTransportEditClick?.(props.transport.id, props.vehicle?.vehicleId?.toString());
                }}
            >
                {t('common.edit')}
            </Menu.Item>

            <Menu.Item
                key="1"
                onClick={() => {
                    onTransportClick();
                }}
            >
                {t('common.detail')}
            </Menu.Item>

            {[TransportState.New, TransportState.Assigned].includes(props.transport.state) && (
                <>
                    <Menu.Item key="2" onClick={() => props.onOpenDeleteTransportModal(props.transport)}>
                        {t('common.delete')}
                    </Menu.Item>
                    {props.vehicle?.vehicleId ? (
                        <Menu.Item key="3" onClick={() => props.onUnassignTransport(props.transport.id)}>
                            {t('DispatcherBoard.unassigneVehicle')}
                        </Menu.Item>
                    ) : (
                        <Menu.Item key="4" onClick={() => props.onOpenAssignVehicleModal(props.transport)}>
                            {t('DispatcherBoard.assigneVehicle')}
                        </Menu.Item>
                    )}
                </>
            )}
        </Menu>
    );

    const getDistance = () => `${props.transport.distance ? (props.transport.distance / 1000).toFixed(0) : 0} km`;

    const onNoteAddClick = () => {
        props.onTransportNoteEvent(UserEvent.ADD_NOTE, props.transport.id, props.vehicle?.vehicleId);
    };

    const onNoteCancel = () => {
        props.onTransportNoteEvent(UserEvent.CANCEL_NOTE, props.transport.id, props.vehicle?.vehicleId);
    };

    const onTransportClick = () => {
        setTransportDetail(true);
        props.onTransportDetailClick?.(props.transport);
    };

    const onTransportDoubleClick = () => {
        props.onTransportEditClick?.(props.transport.id, props.vehicle?.vehicleId?.toString());
    };

    const transportClick = useSingleAndDoubleClick(onTransportClick, onTransportDoubleClick, 200);

    return (
        <>
            {render && (
                <div
                    className="rl-calendar-view-transport"
                    key={props.transport.id}
                    style={{
                        height: transportSize?.clientHeight || 95
                    }}
                >
                    <div
                        style={{
                            left: `${marginLeft}%`,
                            width: `${width}%`,
                            position: 'absolute',
                            zIndex: 10
                        }}
                    >
                        <Draggable
                            isDragDisabled={
                                activeEditor ||
                                props.transport.state === TransportState.Active ||
                                props.transport.state === TransportState.Finished
                            }
                            key={props.transport.id}
                            draggableId={props.transport.id}
                            index={+props.transport.id}
                        >
                            {(provided, snapshot) => (
                                <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={getDraggableStyle(provided.draggableProps.style, snapshot)}
                                >
                                    <div
                                        data-transport-name={props.transport?.name}
                                        data-rn={props.vehicle?.registrationNumber}
                                        onClick={transportClick}
                                        ref={transportRef}
                                    >
                                        {props.transport.eta &&
                                            props.bar.eta &&
                                            etaWidth &&
                                            renderEta &&
                                            duration &&
                                            props.transport.state !== TransportState.Finished &&
                                            props.transport.state !== TransportState.Rejected && (
                                                <CalendarViewTransportEta
                                                    eta={props.transport.eta}
                                                    duration={duration}
                                                    etaWidth={etaWidthPixel}
                                                    onOpenTransport={() =>
                                                        props.onTransportDetailClick?.(props.transport)
                                                    }
                                                />
                                            )}

                                        <Dropdown
                                            overlay={dropdownMenu}
                                            trigger={['contextMenu']}
                                            arrow={true}
                                            destroyPopupOnHide={true}
                                            placement="top"
                                            onVisibleChange={value => {
                                                setVisibleTooltip(false);
                                                setVisibleDropdown(value);
                                            }}
                                        >
                                            <Tooltip
                                                title={`${getDistance()} ~ ${props.transport.name}`}
                                                placement="topLeft"
                                                visible={visibleTooltip && !visibleDropdown}
                                                onVisibleChange={setVisibleTooltip}
                                            >
                                                <div
                                                    className={cn('rl-calendar-view-transport-content', {
                                                        'is-dragging': snapshot.isDragging,
                                                        'is-clicked': visibleDropdown || transportDetail
                                                    })}
                                                >
                                                    <CalendarViewTransportPopover
                                                        visible={
                                                            props.firstTransportPopoverVisible && !snapshot.isDragging
                                                        }
                                                        onTransportPopoverClose={props.onFirstTransportPopoverClose}
                                                    >
                                                        <CalendarViewTransportInfo
                                                            distance={getDistance()}
                                                            state={props.transport?.state}
                                                            transportName={props.transport.name}
                                                            iso2Code={getLastPlaceIso2Code(
                                                                props.transport,
                                                                props.countryList
                                                            )}
                                                        />
                                                    </CalendarViewTransportPopover>
                                                </div>
                                            </Tooltip>
                                        </Dropdown>
                                    </div>
                                </div>
                            )}
                        </Draggable>

                        <CalendarViewTransportNote
                            ref={noteRef}
                            transport={props.transport}
                            visible={props.bar.notes}
                            onTransportSave={props.onTransportNoteChange}
                            onEditorActive={setActiveEditor}
                            onAddNoteClick={onNoteAddClick}
                            onCancelNote={onNoteCancel}
                        />
                    </div>
                </div>
            )}
        </>
    );
};

export default memo(CalendarViewTransport);

function getDraggableStyle(style: CSSProperties = {}, snapshot: DraggableStateSnapshot) {
    return !snapshot.isDropAnimating
        ? style
        : {
              ...style,
              // Hack! This will remove unwanted transport drop effect.
              transitionDuration: `0.001s` // cannot be 0, but make it super tiny.
          };
}
