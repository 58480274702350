import { AvailableCurrencies } from 'utils/constants/currencies';

const languageCurrencyMap = {
    sk: AvailableCurrencies.EUR,
    cs: AvailableCurrencies.CZK,
    en: AvailableCurrencies.GBP,
    'en-US': AvailableCurrencies.USD,
    'en-GB': AvailableCurrencies.GBP,
    'en-IE': AvailableCurrencies.EUR,
    pl: AvailableCurrencies.PLN,
    hu: AvailableCurrencies.HUF,
    ro: AvailableCurrencies.RON,
    ru: AvailableCurrencies.RUB,
    hr: AvailableCurrencies.HRK,
    bg: AvailableCurrencies.BGN,
    da: AvailableCurrencies.DKK,
    is: AvailableCurrencies.ISK,
    li: AvailableCurrencies.CHF,
    no: AvailableCurrencies.NOK,
    sv: AvailableCurrencies.SEK,
    tr: AvailableCurrencies.TRY
};

export function getCurrencyFromLanguage(language: string): AvailableCurrencies {
    return languageCurrencyMap[language] ?? AvailableCurrencies.EUR;
}
