/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MonitoredObjectCosts
 */
export interface MonitoredObjectCosts {
    /**
     * 
     * @type {number}
     * @memberof MonitoredObjectCosts
     */
    monitoredObjectId: number;
    /**
     * 
     * @type {number}
     * @memberof MonitoredObjectCosts
     */
    distance: number;
    /**
     * 
     * @type {number}
     * @memberof MonitoredObjectCosts
     */
    realDistanceFromTrips: number;
    /**
     * 
     * @type {number}
     * @memberof MonitoredObjectCosts
     */
    pricePerKm: number;
    /**
     * 
     * @type {number}
     * @memberof MonitoredObjectCosts
     */
    pricePerKmPerVehicle: number;
    /**
     * 
     * @type {string}
     * @memberof MonitoredObjectCosts
     */
    currency: string;
    /**
     * 
     * @type {number}
     * @memberof MonitoredObjectCosts
     */
    numberOfDaysWithDrives: number;
}

export function MonitoredObjectCostsFromJSON(json: any): MonitoredObjectCosts {
    return MonitoredObjectCostsFromJSONTyped(json, false);
}

export function MonitoredObjectCostsFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitoredObjectCosts {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'monitoredObjectId': json['monitored_object_id'],
        'distance': json['distance'],
        'realDistanceFromTrips': json['real_distance_from_trips'],
        'pricePerKm': json['price_per_km'],
        'pricePerKmPerVehicle': json['price_per_km_per_vehicle'],
        'currency': json['currency'],
        'numberOfDaysWithDrives': json['number_of_days_with_drives'],
    };
}

export function MonitoredObjectCostsToJSON(value?: MonitoredObjectCosts | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'monitored_object_id': value.monitoredObjectId,
        'distance': value.distance,
        'real_distance_from_trips': value.realDistanceFromTrips,
        'price_per_km': value.pricePerKm,
        'price_per_km_per_vehicle': value.pricePerKmPerVehicle,
        'currency': value.currency,
        'number_of_days_with_drives': value.numberOfDaysWithDrives,
    };
}


