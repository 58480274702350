import moment from 'moment';
import { DaysSegmentType } from '../../../common/types/DaySegment';

interface Props {
    startDate: string;
    daySegment: number;
}

const CalendarViewGridMask = (props: Props) => {
    const currentDateIndex = moment().startOf('day').diff(moment(props.startDate).startOf('day'), 'days');
    const DAY_PERCENTAGE_WIDTH = 100 / props.daySegment;
    const DAY_PERCENTAGE_OFFSET = props.daySegment === DaysSegmentType.day5 ? 0 : 2;

    const timelineLeft =
        DAY_PERCENTAGE_WIDTH * currentDateIndex +
        (DAY_PERCENTAGE_WIDTH / 24) * (moment().hours() + moment().minutes() / 60) +
        DAY_PERCENTAGE_OFFSET;

    return (
        <div className="rl-calendar-view-grid-mask">
            <div className="rl-calendar-view-grid-mask-left" />
            <div className={`rl-calendar-view-grid-mask-right day-${props.daySegment}`}>
                {[...Array(props.daySegment)].map((_, index) => (
                    <div className="rl-calendar-view-grid-mask-right-col" key={index} />
                ))}
                <div
                    className="rl-calendar-view-grid-mask-timeline"
                    style={{
                        left: `${timelineLeft}%`,
                        visibility: currentDateIndex >= 0 ? 'visible' : 'hidden'
                    }}
                />
            </div>
        </div>
    );
};

export default CalendarViewGridMask;
