/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InlineResponse20033,
    InlineResponse20033FromJSON,
    InlineResponse20033ToJSON,
    ReadOnlyUserGroup,
    ReadOnlyUserGroupFromJSON,
    ReadOnlyUserGroupToJSON,
    WriteOnlyUserGroup,
    WriteOnlyUserGroupFromJSON,
    WriteOnlyUserGroupToJSON,
} from '../models';

export interface UserGroupCreateRequest {
    data: WriteOnlyUserGroup;
}

export interface UserGroupDeleteRequest {
    id: number;
}

export interface UserGroupListRequest {
    limit?: number;
    offset?: number;
}

export interface UserGroupPartialUpdateRequest {
    id: number;
    data: WriteOnlyUserGroup;
}

export interface UserGroupReadRequest {
    id: number;
}

export interface UserGroupUpdateRequest {
    id: number;
    data: WriteOnlyUserGroup;
}

/**
 * no description
 */
export class UserGroupApi extends runtime.BaseAPI {

    /**
     */
    async userGroupCreateRaw(requestParameters: UserGroupCreateRequest): Promise<runtime.ApiResponse<ReadOnlyUserGroup>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling userGroupCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/user-group/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyUserGroupToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyUserGroupFromJSON(jsonValue));
    }

    /**
     */
    async userGroupCreate(requestParameters: UserGroupCreateRequest): Promise<ReadOnlyUserGroup> {
        const response = await this.userGroupCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async userGroupDeleteRaw(requestParameters: UserGroupDeleteRequest): Promise<runtime.ApiResponse<ReadOnlyUserGroup>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userGroupDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/user-group/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyUserGroupFromJSON(jsonValue));
    }

    /**
     */
    async userGroupDelete(requestParameters: UserGroupDeleteRequest): Promise<ReadOnlyUserGroup> {
        const response = await this.userGroupDeleteRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async userGroupListRaw(requestParameters: UserGroupListRequest): Promise<runtime.ApiResponse<InlineResponse20033>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/user-group/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20033FromJSON(jsonValue));
    }

    /**
     */
    async userGroupList(requestParameters: UserGroupListRequest): Promise<InlineResponse20033> {
        const response = await this.userGroupListRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async userGroupPartialUpdateRaw(requestParameters: UserGroupPartialUpdateRequest): Promise<runtime.ApiResponse<ReadOnlyUserGroup>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userGroupPartialUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling userGroupPartialUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/user-group/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyUserGroupToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyUserGroupFromJSON(jsonValue));
    }

    /**
     */
    async userGroupPartialUpdate(requestParameters: UserGroupPartialUpdateRequest): Promise<ReadOnlyUserGroup> {
        const response = await this.userGroupPartialUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async userGroupReadRaw(requestParameters: UserGroupReadRequest): Promise<runtime.ApiResponse<ReadOnlyUserGroup>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userGroupRead.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/user-group/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyUserGroupFromJSON(jsonValue));
    }

    /**
     */
    async userGroupRead(requestParameters: UserGroupReadRequest): Promise<ReadOnlyUserGroup> {
        const response = await this.userGroupReadRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async userGroupUpdateRaw(requestParameters: UserGroupUpdateRequest): Promise<runtime.ApiResponse<ReadOnlyUserGroup>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userGroupUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling userGroupUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/user-group/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyUserGroupToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyUserGroupFromJSON(jsonValue));
    }

    /**
     */
    async userGroupUpdate(requestParameters: UserGroupUpdateRequest): Promise<ReadOnlyUserGroup> {
        const response = await this.userGroupUpdateRaw(requestParameters);
        return await response.value();
    }

}
