import i18next from 'i18next';

export const formValidationMessages = () => ({
    default: i18next.t('Forms.Validations.default'),
    required: i18next.t('Forms.Validations.required'),
    whitespace: i18next.t('Forms.Validations.whitespace'),
    date: {
        format: i18next.t('Forms.Validations.date.format'),
        parse: i18next.t('Forms.Validations.date.parse'),
        invalid: i18next.t('Forms.Validations.date.invalid')
    },
    string: {
        len: i18next.t('Forms.Validations.string.len'),
        min: i18next.t('Forms.Validations.string.min'),
        max: i18next.t('Forms.Validations.string.max'),
        range: i18next.t('Forms.Validations.string.range')
    },
    number: {
        len: i18next.t('Forms.Validations.number.len'),
        min: i18next.t('Forms.Validations.number.min'),
        max: i18next.t('Forms.Validations.number.max'),
        range: i18next.t('Forms.Validations.number.range')
    }
});
