/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InlineResponse20017,
    InlineResponse20017FromJSON,
    InlineResponse20017ToJSON,
    ReadOnlyMonitoredObjectType,
    ReadOnlyMonitoredObjectTypeFromJSON,
    ReadOnlyMonitoredObjectTypeToJSON,
    WriteOnlyMonitoredObjectType,
    WriteOnlyMonitoredObjectTypeFromJSON,
    WriteOnlyMonitoredObjectTypeToJSON,
} from '../models';

export interface MonitoredObjectTypeCreateRequest {
    data: WriteOnlyMonitoredObjectType;
}

export interface MonitoredObjectTypeDeleteRequest {
    id: number;
}

export interface MonitoredObjectTypeListRequest {
    limit?: number;
    offset?: number;
}

export interface MonitoredObjectTypePartialUpdateRequest {
    id: number;
    data: WriteOnlyMonitoredObjectType;
}

export interface MonitoredObjectTypeReadRequest {
    id: number;
}

export interface MonitoredObjectTypeUpdateRequest {
    id: number;
    data: WriteOnlyMonitoredObjectType;
}

/**
 * no description
 */
export class MonitoredObjectTypeApi extends runtime.BaseAPI {

    /**
     */
    async monitoredObjectTypeCreateRaw(requestParameters: MonitoredObjectTypeCreateRequest): Promise<runtime.ApiResponse<ReadOnlyMonitoredObjectType>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling monitoredObjectTypeCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/monitored-object-type/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyMonitoredObjectTypeToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyMonitoredObjectTypeFromJSON(jsonValue));
    }

    /**
     */
    async monitoredObjectTypeCreate(requestParameters: MonitoredObjectTypeCreateRequest): Promise<ReadOnlyMonitoredObjectType> {
        const response = await this.monitoredObjectTypeCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async monitoredObjectTypeDeleteRaw(requestParameters: MonitoredObjectTypeDeleteRequest): Promise<runtime.ApiResponse<ReadOnlyMonitoredObjectType>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling monitoredObjectTypeDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/monitored-object-type/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyMonitoredObjectTypeFromJSON(jsonValue));
    }

    /**
     */
    async monitoredObjectTypeDelete(requestParameters: MonitoredObjectTypeDeleteRequest): Promise<ReadOnlyMonitoredObjectType> {
        const response = await this.monitoredObjectTypeDeleteRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async monitoredObjectTypeListRaw(requestParameters: MonitoredObjectTypeListRequest): Promise<runtime.ApiResponse<InlineResponse20017>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/monitored-object-type/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20017FromJSON(jsonValue));
    }

    /**
     */
    async monitoredObjectTypeList(requestParameters: MonitoredObjectTypeListRequest): Promise<InlineResponse20017> {
        const response = await this.monitoredObjectTypeListRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async monitoredObjectTypePartialUpdateRaw(requestParameters: MonitoredObjectTypePartialUpdateRequest): Promise<runtime.ApiResponse<ReadOnlyMonitoredObjectType>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling monitoredObjectTypePartialUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling monitoredObjectTypePartialUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/monitored-object-type/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyMonitoredObjectTypeToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyMonitoredObjectTypeFromJSON(jsonValue));
    }

    /**
     */
    async monitoredObjectTypePartialUpdate(requestParameters: MonitoredObjectTypePartialUpdateRequest): Promise<ReadOnlyMonitoredObjectType> {
        const response = await this.monitoredObjectTypePartialUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async monitoredObjectTypeReadRaw(requestParameters: MonitoredObjectTypeReadRequest): Promise<runtime.ApiResponse<ReadOnlyMonitoredObjectType>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling monitoredObjectTypeRead.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/monitored-object-type/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyMonitoredObjectTypeFromJSON(jsonValue));
    }

    /**
     */
    async monitoredObjectTypeRead(requestParameters: MonitoredObjectTypeReadRequest): Promise<ReadOnlyMonitoredObjectType> {
        const response = await this.monitoredObjectTypeReadRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async monitoredObjectTypeUpdateRaw(requestParameters: MonitoredObjectTypeUpdateRequest): Promise<runtime.ApiResponse<ReadOnlyMonitoredObjectType>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling monitoredObjectTypeUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling monitoredObjectTypeUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/monitored-object-type/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyMonitoredObjectTypeToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyMonitoredObjectTypeFromJSON(jsonValue));
    }

    /**
     */
    async monitoredObjectTypeUpdate(requestParameters: MonitoredObjectTypeUpdateRequest): Promise<ReadOnlyMonitoredObjectType> {
        const response = await this.monitoredObjectTypeUpdateRaw(requestParameters);
        return await response.value();
    }

}
