import { Logic } from './logic';
import { ClientApplication } from '../generated/main-data-api';

export class CustomerApiLogic {
    constructor(private _logic: Logic) {}

    async getAll(): Promise<ClientApplication[]> {
        const { data } = await this._logic.api().applicationsApi.getAll();
        return data ? data : [];
    }

    async generateKey(name: string, vehicles: string[]): Promise<ClientApplication | null> {
        const { data } = await this._logic.api().applicationsApi.create({
            clientApplicationCreateBody: {
                name: name,
                vehicles: vehicles.map(v => Number(v))
            }
        });

        return data ? data : null;
    }

    async removeKey(id: number): Promise<ClientApplication | null> {
        const { data } = await this._logic.api().applicationsApi._delete({
            appId: Number(id)
        });

        return data ? data : null;
    }

    async editKey(id: number, name: string, vehicles: string[]) {
        const { data } = await this._logic.api().applicationsApi.edit({
            appId: Number(id),
            clientApplicationCreateBody: {
                name: name,
                vehicles: vehicles.map(v => Number(v))
            }
        });

        return data ? data : null;
    }
}
