import { Component } from 'react';
import qs from 'qs';
import mapboxgl from 'mapbox-gl';
import { Logic } from 'logic/logic';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Redirect, Route, RouteComponentProps, Switch, withRouter } from 'react-router-dom';
import AssignDriverModule from '../AssignDriverModule/AssignDriverModule';
import { RouteNames } from '../../router/routes';
import { VehicleCreateEditModule } from 'modules/VehicleCreateEditModule';
import { DEFAULT_MAP_PADDING, TRACKING_MOBILE_MAP_PADDING } from 'utils/constants/constants';
import { CompanyVehicle, VehicleTransportObject } from 'services/api/domains/VehiclesApi';
import ShareEtaModule from 'modules/ShareEtaModule/ShareEtaModule';
import { ShareEtaCodes } from 'logic/shareEta';
import { ShareEtaCode } from 'services/api/domains/ShareEtaApi';
import { InitiatedFrom } from 'logic/userEventsProviders/GoogleTagManager';
import TrackingTable, { TrackingTransport } from './ui/TrackingTable/TrackingTable';
import { AppStore, Stores, UserSettingsStore } from 'stores';
import { inject, observer } from 'mobx-react';
import { TrackingMapSwitch, TrackingView } from './ui/TrackingMapSwitch';
import { TrackingDetail } from './ui/TrackingDetail';
import { TrackingNavbar } from './ui/TrackingNavbar';
import TruckOverview from './ui/TruckOverview/TruckOverview';
import { isMobileDevice } from 'utils/helpers/navigator';
import { TrackingVehiclesList } from './ui/TrackingVehiclesList';
import { DropResult } from '@hello-pangea/dnd';

type GPS = {
    lat?: number;
    lng?: number;
    angle?: number;
    speed?: number;
};

export type TachoStatus = 'driving' | 'resting' | 'working';

export type TrackingTableHead = {
    label: string;
    value: string;
    checked: boolean;
}[];

export type TrailerObject = {
    id: string;
    registrationNumber: string;
    customLabel: string;
};

export type TrackingNavigationRoute = {
    polyline?: string;
    destination?: string;
    eta?: string;
    remainingDistance?: number;
};

export interface TrackingModel {
    activeTransports?: VehicleTransportObject[];
    arrivalTimes: { eta?: string; rta?: string };
    centered: boolean;
    customLabel?: string;
    destination?: string;
    driverName: string;
    driverId: string;
    driverAvatarUrl?: string;
    ETA: string;
    firstRTA: string;
    GPS?: GPS;
    id: string;
    lastUpdate?: string;
    location: string;
    RN: string;
    name?: string;
    route: boolean;
    RTA: string;
    selected: boolean;
    trackingTransport: TrackingTransport;
    navigationRoute: TrackingNavigationRoute;
    visible?: boolean;
    hovered?: boolean;
}

interface Props extends WithTranslation, RouteComponentProps {
    logic: Logic;
    userSettingsStore?: UserSettingsStore;
    appStore?: AppStore;
}

interface State {
    table: {
        data?: TrackingModel[];
        isLoading: boolean;
    };
    sidebar: {
        visible: boolean;
        data?: TrackingModel;
    };
    addVehicleSidebar?: {
        visible: boolean;
        loading: boolean;
        initiatedFrom?: InitiatedFrom;
    };
    assignDriverModal?: {
        visible: boolean;
        loading?: boolean;
        vehicleId?: number;
    };
    shareEta: {
        codes?: ShareEtaCodes;
        shareEtaModalVisible: boolean;
        stopShareEtaModalVisible: boolean;
        driverCode?: ShareEtaCode;
        driverId?: string;
    };
    truckOverview: {
        visible: boolean;
    };
    truckingView: TrackingView;
}

export type RouteParams = {
    delay?: string;
    status?: string;
    expanded?: string;
    vehicleId?: string;
    fromFleet?: string;
};

const TRACKING_MAP_PADDING = isMobileDevice()
    ? {
          ...TRACKING_MOBILE_MAP_PADDING,
          bottom: 0
      }
    : {
          ...DEFAULT_MAP_PADDING,
          left: 200
      };

@inject(Stores.USER_SETTINGS_STORE, Stores.APP_STORE)
@observer
class TrackingModule extends Component<Props, State> {
    private _mapPadding: mapboxgl.PaddingOptions = TRACKING_MAP_PADDING;

    constructor(props: Props) {
        super(props);

        this.state = {
            table: {
                isLoading: true
            },
            sidebar: {
                visible: false
            },
            shareEta: {
                shareEtaModalVisible: false,
                stopShareEtaModalVisible: false
            },
            truckOverview: {
                visible: false
            },
            truckingView: TrackingView.LIST_VIEW
        };
    }

    componentDidMount() {
        this._setMapPadding(this._mapPadding);

        const params = qs.parse(this.props.history.location.search, {
            ignoreQueryPrefix: true
        }) as RouteParams;

        this.props.logic
            .tracking()
            .init()
            .then(() => {
                if (params.vehicleId) {
                    const vehicleData = this.state.table.data?.find(data => data.id === params.vehicleId);
                    if (vehicleData) {
                        this._selectVehicle(vehicleData);
                    }
                }
            });

        this.props.logic.tracking().onData(data => {
            this.setState(state => ({
                table: {
                    data: this._getOrderedVehicles(data),
                    isLoading: false
                },
                sidebar: {
                    ...state.sidebar,
                    data: data.find(trackingvehicle => trackingvehicle.id === this.state.sidebar?.data?.id)
                }
            }));
        });

        this.props.logic
            .map()
            .vehicles()
            .onClick(vehicle => {
                const vehicleData = this.state.table.data?.find(data => data.id === vehicle.id);
                if (vehicleData) {
                    if (vehicleData.selected && vehicleData.centered) {
                        this._unselectVehicle();
                    } else if (!vehicleData.selected || !vehicleData.centered) {
                        this._onShowOnMap(vehicleData, InitiatedFrom.TRACKING_VEHICLE_MARKER);
                    }
                }
            });

        this.props.logic
            .map()
            .vehicles()
            .onRouteOverview(vehicle => {
                const vehicleData = this.state.table.data?.find(data => data.id === vehicle.id);
                if (vehicleData) {
                    this._selectVehicle(vehicleData, this._onRouteOverview);
                }
            });

        this.props.logic
            .map()
            .vehicles()
            .onShareEta(vehicle => {
                const vehicleData = this.state.table.data?.find(data => data.id === vehicle.id);
                if (vehicleData) {
                    this._onShareEtaClick(vehicleData, InitiatedFrom.TRACKING_VEHICLE_MARKER);
                }
            });

        this.props.logic
            .shareEta()
            .fetchShareEtaCodes()
            .then(shareEtaCodes => {
                this.setState(state => ({
                    shareEta: {
                        ...state.shareEta,
                        codes: shareEtaCodes,
                        driverCode: undefined,
                        driverId: undefined
                    }
                }));
            });
    }

    componentWillUnmount() {
        this.props.logic.tracking().destroy();
    }

    render() {
        return (
            <>
                <TrackingNavbar />
                <TrackingMapSwitch view={this.state.truckingView} onChangeView={this._setTrackingView} />

                <Switch>
                    <Route path={RouteNames.TRACKING_MAP}>
                        {this.state.truckingView === TrackingView.LIST_VIEW && (
                            <TrackingVehiclesList
                                vehicles={this.state.table.data}
                                isLoading={this.state.table.isLoading}
                                onAssignDriverClick={this._onAssignDriverClick}
                                onAddVehicle={this._addVehicle}
                                onSelectVehicleFromList={this._onSelectVehicleFromList}
                                onMouseEnterVehicle={this._onMouseEnterVehicle}
                                onMouseLeaveVehicle={this._onMouseLeaveVehicle}
                                onDragVehicleEnd={this._onDragVehicleEnd}
                                onOpenVehicleDetail={vehicle => {
                                    this.state.sidebar.data?.id === vehicle.id
                                        ? this._unselectVehicle()
                                        : this._selectVehicle(vehicle);
                                }}
                            />
                        )}

                        {this.state.sidebar.visible && (
                            <TrackingDetail
                                model={this.state.sidebar.data}
                                shareCode={
                                    this.state.sidebar?.data &&
                                    this.state.shareEta.codes?.[this.state.sidebar?.data.driverId]
                                }
                                onClose={this._unselectVehicle}
                                onAddDestinationClick={this._addDestination}
                                onTransportClick={this._onTransportClick}
                                onRouteOverviewClick={this._onRouteOverview}
                                onShareEtaClick={vehicle => {
                                    this._onShareEtaClick(vehicle, InitiatedFrom.TRACKING_DETAIL);
                                }}
                                onStopShareEtaClick={shareCode => {
                                    this._onStopShareEtaClick(shareCode, InitiatedFrom.TRACKING_DETAIL);
                                }}
                                onShowOnMapClick={this._onShowOnMap}
                                onToggleShowOnMapClick={this._onToggleShowOnMapClick}
                            />
                        )}

                        <TruckOverview
                            visible={this.state.truckOverview.visible}
                            model={this.state.sidebar.data}
                            onShowDetail={this._showTruckDetail}
                            onRouteOverview={this._onRouteOverview}
                            onClose={() => this._unselectVehicle()}
                        />
                    </Route>

                    <Route path={RouteNames.TRACKING_TABLE}>
                        <TrackingTable
                            data={this.state.table.data!}
                            isLoading={this.state.table.isLoading}
                            shareCodes={this.state.shareEta.codes}
                            onAssignDriverClick={this._onAssignDriverClick}
                            onAddDestination={this._addDestination}
                            onSelectVehicle={this._selectVehicle}
                            onTransportClick={this._onTransportClick}
                            onAddVehicle={this._addVehicle}
                            onShareEtaClick={vehicle => {
                                this._onShareEtaClick(vehicle, InitiatedFrom.TRACKING_TABLE);
                            }}
                            onStopShareEtaClick={shareCode => {
                                this._onStopShareEtaClick(shareCode, InitiatedFrom.TRACKING_TABLE);
                            }}
                            onTransportDbClick={this._selectVehicle}
                            onDragVehicleEnd={this._onDragVehicleEnd}
                        />
                    </Route>

                    <Redirect to={RouteNames.TRACKING_MAP} />
                </Switch>

                <VehicleCreateEditModule
                    logic={this.props.logic}
                    visible={this.state.addVehicleSidebar?.visible}
                    type={'CREATE'}
                    onCancel={this._closeAddVehicleSidebar}
                    afterSubmit={this._afterVehicleSubmit}
                    afterCreate={this._afterVehicleCreateSuccess}
                />

                <AssignDriverModule
                    visible={!!this.state.assignDriverModal?.visible}
                    logic={this.props.logic}
                    vehicleId={this.state.assignDriverModal?.vehicleId!}
                    onCancel={this._cancelAssignDriver}
                    afterAssign={this._afterAssignDriver}
                />

                <ShareEtaModule
                    logic={this.props.logic}
                    driverId={this.state.shareEta?.driverId}
                    shareCode={this.state.shareEta?.driverCode}
                    shareEtaModalVisible={this.state.shareEta?.shareEtaModalVisible}
                    stopShareEtaModalVisible={this.state.shareEta?.stopShareEtaModalVisible}
                    onCloseShareEtaModal={this._onCloseShareEtaModal}
                    onCancelStopShareEtaModal={this._onCancelStopShareEtaModal}
                    onStopShareEta={() => this._loadShareEtaCodes()}
                />
            </>
        );
    }

    private _setMapPadding(padding: mapboxgl.PaddingOptions) {
        this._mapPadding = padding;
        this.props.logic.map().setPadding(this._mapPadding);
    }

    private _onRouteOverview = () => {
        this.props.logic.tracking().setRouteOverviewMode(true);

        const routePadding = isMobileDevice()
            ? { left: 100, top: 100, bottom: 250, right: 40 }
            : { left: 300, top: 100, bottom: 40, right: 40 };

        this.props.logic.map().routing().fitRoute(routePadding);

        if (isMobileDevice() && this.state.sidebar.data) {
            this.setState(
                state => ({
                    sidebar: { ...state.sidebar, visible: false },
                    truckOverview: { visible: true }
                }),
                () => this._setTrackingView(TrackingView.MAP_VIEW)
            );
        }
    };

    private _afterAssignDriver = async () => {
        await this._loadTrackingTable();
        this.props.logic.tracking().selectVehicle(String(this.state.assignDriverModal?.vehicleId));
        this._cancelAssignDriver();
    };

    private _afterVehicleSubmit = () => {
        this._closeAddVehicleSidebar();
        this._loadTrackingTable();
    };

    private _afterVehicleCreateSuccess = (vehicle: CompanyVehicle) => {
        this.props.logic.userEvents().addVehicle(vehicle, this.state.addVehicleSidebar?.initiatedFrom);
    };

    private _loadTrackingTable = async () => {
        this.setState(state => ({
            table: {
                ...state.table,
                isLoading: true
            }
        }));

        await this.props.logic.tracking().loadVehicles();
    };

    private _selectVehicle = (vehicle: TrackingModel, callback?: () => void, initiatedFrom?: InitiatedFrom) => {
        this.setState({ sidebar: { data: vehicle, visible: true }, truckOverview: { visible: false } }, () => {
            vehicle?.visible && this.props.logic.tracking().selectVehicle(vehicle.id);
            this.props.history.push({
                search: qs.stringify({
                    vehicleId: vehicle.id
                })
            });

            initiatedFrom && this.props.logic.userEvents().viewDriver(initiatedFrom);

            callback?.();
        });
    };

    private _onShowOnMap = (vehicle: TrackingModel, initiatedFrom?: InitiatedFrom) => {
        this._setMapPadding(
            isMobileDevice()
                ? { ...TRACKING_MOBILE_MAP_PADDING }
                : { ...this._mapPadding, left: window.outerWidth / 2 - 100 }
        );

        if (isMobileDevice()) {
            this.props.logic.map().showMapControls(false);
        }

        initiatedFrom && this.props.logic.userEvents().viewDriver(initiatedFrom);

        this.setState(
            { sidebar: { data: vehicle, visible: !isMobileDevice() }, truckOverview: { visible: isMobileDevice() } },
            () => {
                this.props.logic.tracking().selectVehicle(vehicle.id);
                isMobileDevice() && this._setTrackingView(TrackingView.MAP_VIEW);

                this.props.history.push({
                    search: qs.stringify({
                        vehicleId: vehicle.id
                    })
                });
            }
        );
    };

    private _unselectVehicle = (callback?: () => void, fitVehicles = true) => {
        this._setMapPadding(
            isMobileDevice()
                ? { ...TRACKING_MOBILE_MAP_PADDING, bottom: 0 }
                : { ...this._mapPadding, right: TRACKING_MAP_PADDING.right }
        );

        this.props.logic.tracking().setRouteOverviewMode(false);
        isMobileDevice() && this.props.logic.map().showMapControls(true);

        this.setState({ sidebar: { data: undefined, visible: false }, truckOverview: { visible: false } }, () => {
            this.props.logic.tracking().unselectVehicle(fitVehicles);
            this.props.history.push({
                search: qs.stringify({
                    vehicleId: undefined
                })
            });

            callback?.();
        });
    };

    private _addVehicle = (initiatedFrom: InitiatedFrom) => {
        this.setState({
            addVehicleSidebar: { visible: true, loading: false, initiatedFrom },
            sidebar: { visible: false }
        });
    };

    private _closeAddVehicleSidebar = () => {
        this.setState({ addVehicleSidebar: { visible: false, loading: false } });
    };

    private _onAssignDriverClick = (vehicleId: string) => {
        this.setState({
            assignDriverModal: { visible: true, vehicleId: Number(vehicleId) }
        });
    };

    private _cancelAssignDriver = () => {
        this.setState({ assignDriverModal: { visible: false } });
    };

    private _addDestination = (vehicleId: string) => {
        const data = this.props.logic.tracking().loadVehicle(vehicleId);

        if (data?.GPS) {
            this.props.history.push({
                pathname: RouteNames.SCHEDULING_PLANNER,
                search: qs.stringify({
                    vehicleId: vehicleId,
                    redirectedFrom: 'tracking'
                })
            });
        }
    };

    private _onTransportClick = (transportId: string) => {
        this.props.history.push({
            pathname: RouteNames.SCHEDULING_PLANNER,
            search: qs.stringify({
                editId: transportId
            })
        });
    };

    private _loadShareEtaCodes = async () => {
        this.setState(state => ({
            table: {
                ...state.table,
                isLoading: true
            }
        }));

        const shareEtaCodes = await this.props.logic.shareEta().fetchShareEtaCodes();

        this.setState(state => ({
            shareEta: {
                ...state.shareEta,
                codes: shareEtaCodes,
                driverCode: undefined,
                driverId: undefined
            },
            table: {
                ...state.table,
                isLoading: false
            }
        }));
    };

    private _onShareEtaClick = (vehicle: TrackingModel, initiatedFrom: InitiatedFrom) => {
        this.setState(
            state => ({
                shareEta: {
                    ...state.shareEta,
                    shareEtaModalVisible: true,
                    stopShareEtaModalVisible: false,
                    driverCode: this.state.shareEta.codes?.[vehicle.driverId],
                    driverId: vehicle.driverId
                }
            }),
            () => {
                this.props.logic.userEvents().shareEtaCreate(
                    {
                        sharingStatus: this.state.shareEta.driverCode?.state,
                        driverId: this.state.shareEta.driverCode?.driverId
                    },
                    initiatedFrom
                );
            }
        );
    };

    private _onStopShareEtaClick = (shareCode?: ShareEtaCode, initiatedFrom?: InitiatedFrom) => {
        this.setState(
            state => ({
                shareEta: {
                    ...state.shareEta,
                    shareEtaModalVisible: false,
                    stopShareEtaModalVisible: !!shareCode,
                    driverCode: shareCode,
                    driverId: undefined
                }
            }),
            () => {
                this.props.logic.userEvents().shareEtaStop(
                    {
                        sharingStatus: this.state.shareEta.driverCode?.state,
                        driverId: this.state.shareEta.driverCode?.driverId
                    },
                    initiatedFrom!
                );
            }
        );
    };

    private _onCloseShareEtaModal = (reload: boolean) => {
        this.setState(state => ({
            shareEta: {
                ...state.shareEta,
                shareEtaModalVisible: false,
                stopShareEtaModalVisible: false,
                driverCode: undefined,
                driverId: undefined
            }
        }));

        reload && this._loadShareEtaCodes();
    };

    private _onCancelStopShareEtaModal = () => {
        this.setState(state => ({
            shareEta: {
                ...state.shareEta,
                shareEtaModalVisible: false,
                stopShareEtaModalVisible: false,
                driverCode: undefined
            }
        }));
    };

    private _onDragVehicleEnd = (result: DropResult) => {
        if (this.state.table.data) {
            const vehicles = [...this.state.table.data];
            const vehicle = vehicles?.[result.source.index];

            if (vehicle && result.destination) {
                vehicles.splice(result.source.index, 1);
                vehicles.splice(result.destination.index, 0, vehicle);
                this.props.userSettingsStore?.setTrackingVehicleListOrder(vehicles.map(v => +v.id));

                this.setState(state => ({
                    table: {
                        ...state.table,
                        data: vehicles
                    }
                }));
            }
        }
    };

    private _showTruckDetail = () => {
        this.setState(state => ({ sidebar: { ...state.sidebar, visible: true }, truckOverview: { visible: false } }));
    };

    private _onSelectVehicleFromList = (vehicleId: string) => {
        this._unselectVehicle(() => {
            const vehicleData = this.state.table.data?.find(data => data.id === vehicleId);
            vehicleData?.visible && this.props.logic.tracking().selectVehicle(vehicleId);
        });
    };

    private _onMouseEnterVehicle = (vehicleId: string) => {
        this.props.logic.tracking().toggleEnterVehicle(vehicleId);
    };

    private _onMouseLeaveVehicle = () => {
        this.props.logic.tracking().toggleEnterVehicle();
    };

    private _getOrderedVehicles = (vehicles: TrackingModel[]) => {
        const vehicleSortOrder = this.props.userSettingsStore?.trackingVehicleListOrder;
        const orderedVehicles = [...vehicles];

        if (vehicleSortOrder) {
            return orderedVehicles.sort((a, b) => {
                return vehicleSortOrder.indexOf(+a.id) - vehicleSortOrder.indexOf(+b.id);
            });
        }

        return vehicles;
    };

    private _setTrackingView = (view: TrackingView) => {
        if (view === TrackingView.LIST_VIEW) {
            this._unselectVehicle();
        }

        if (view === TrackingView.MAP_VIEW && this.state.sidebar.visible) {
            this._unselectVehicle();
        }

        this.setState({
            truckingView: view
        });
    };

    private _onToggleShowOnMapClick = (vehicleId: string) => {
        const vehiclesNotVisibleIds = this.props.logic.tracking().toggleVehicleVisibleOnMap(vehicleId);
        this.props.userSettingsStore?.setTrackingVehiclesHidden(vehiclesNotVisibleIds);
    };
}

export default withRouter(withTranslation()(TrackingModule));
