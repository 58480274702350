import qa from 'qa-selectors';
import { DatePicker } from 'components/base/controls/DatePicker';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { Moment } from 'moment';
import moment from 'moment';

interface Props {
    startDate: string;
    daysAfter: number;
    daysBefore: number;
    onPreviousDateChange: () => void;
    onNextDateChange: () => void;
    onStartDateChange: (startDate: Moment) => void;
}

const CalendarViewGridDatePicker = (props: Props) => {
    const { t } = useTranslation();
    const [datePickerOpen, setDatePickerOpen] = useState(false);

    const getDays = (): number[] => {
        return [...Array(props.daysBefore + props.daysAfter + 1)];
    };

    const getCalendarFrom = (): Moment => {
        return moment(props.startDate).startOf('day');
    };

    const getCalendarTo = (): Moment => {
        return moment(props.startDate)
            .startOf('day')
            .add(getDays().length - 1, 'days');
    };

    return (
        <div className="rl-calendar-view-grid-datepicker">
            <div className="rl-calendar-view-grid-datepicker-previous">
                <i
                    data-qa={qa.dispatcherBoard.previousButton}
                    title={t('common.previous')}
                    className="fa fa-fw fa-chevron-left"
                    onClick={props.onPreviousDateChange}
                />
            </div>

            <div className="rl-calendar-view-grid-datepicker-from">
                <b>{getCalendarFrom().format('DD')}</b>
                <p>{getCalendarFrom().format('MMM')}</p>
            </div>

            <div className="rl-calendar-view-grid-datepicker-dash">
                <i
                    data-qa={qa.dispatcherBoard.calendarButton}
                    title={t('common.calendar')}
                    className="fa fa-fw fa-calendar"
                    onClick={() => setDatePickerOpen(!datePickerOpen)}
                />
                <DatePicker
                    value={moment(props.startDate)}
                    className="rl-calendar-view-grid-datepicker-date"
                    showSecond={false}
                    showTime={false}
                    open={datePickerOpen}
                    onOpenChange={setDatePickerOpen}
                    onChange={value => value && props.onStartDateChange(value)}
                />
            </div>

            <div className="rl-calendar-view-grid-datepicker-to">
                <b>{getCalendarTo().format('DD')}</b>
                <p>{getCalendarTo().format('MMM')}</p>
            </div>

            <div className="rl-calendar-view-grid-datepicker-next">
                <i
                    data-qa={qa.dispatcherBoard.nextButton}
                    title={t('common.next')}
                    className="fa fa-fw fa-chevron-right"
                    onClick={props.onNextDateChange}
                />
            </div>
        </div>
    );
};

export default CalendarViewGridDatePicker;
