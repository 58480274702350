import { RadioChangeEvent } from 'antd';
import { Button, Radio } from 'components/base/controls';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { VehicleStateObject } from 'services/api/domains/VehiclesApi';

interface Props {
    selectedVehicleId?: string | null;
    vehicles: VehicleStateObject[];
    onBackButtonClick: () => void;
    onAssignDriverClick: (vehicleId: string) => void;
    onConfirmClick: (vehicleId: string | null) => void;
}

const NO_VEHICLE = 'no-vehicle';

export const VehicleSelector = (props: Props) => {
    const [selectedVehicleId, setSelectedVehilceId] = useState<string | null>(props.selectedVehicleId || NO_VEHICLE);
    const { t } = useTranslation();

    const onVehicleChange = (event: RadioChangeEvent) => {
        setSelectedVehilceId(event.target.value);
    };

    const confirm = () => {
        if (selectedVehicleId === NO_VEHICLE) {
            props.onConfirmClick(null);
        } else {
            props.onConfirmClick(selectedVehicleId);
        }
    };

    return (
        <div className="rl-calendar-view-mobile-vehicle-selector">
            <div className="rl-calendar-view-mobile-vehicle-selector-header">
                <h5>{t('common.select')}</h5>
                <Button
                    type="action"
                    icon={<i className="rl-icon-arrow-left" />}
                    id="back-button"
                    onClick={props.onBackButtonClick}
                />
            </div>

            <div className="rl-calendar-view-mobile-vehicle-selector-content">
                <Radio.Group
                    className="rl-calendar-view-mobile-vehicle-selector-radio-group"
                    value={selectedVehicleId || NO_VEHICLE}
                    onChange={onVehicleChange}
                >
                    <Radio value={NO_VEHICLE}>{t('DispatcherBoard.unassignedTransports')}</Radio>
                    {props.vehicles.map(vehicle => (
                        <Radio value={vehicle.monitoredObjectId} key={vehicle.monitoredObjectId}>
                            <div className="rl-calendar-view-mobile-vehicle-selector-rn">
                                <i className="rl-icon-truck" />
                                {vehicle.rn}
                            </div>
                            <div>
                                {vehicle.driver?.id ? (
                                    vehicle.driver.name
                                ) : (
                                    <Button
                                        type="link"
                                        onClick={() => props.onAssignDriverClick(vehicle.monitoredObjectId!)}
                                    >
                                        {t('common.assignDriver')}
                                    </Button>
                                )}
                            </div>
                        </Radio>
                    ))}
                </Radio.Group>
            </div>

            <div className="rl-calendar-view-mobile-vehicle-selector-footer">
                <Button type="primary" disabled={selectedVehicleId === undefined} onClick={confirm}>
                    {t('common.confirm')}
                </Button>
            </div>
        </div>
    );
};
