import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { RouteNames } from 'router/routes';

export enum BarItems {
    Fuel = 'fuel',
    Parking = 'parking',
    Map = 'map'
}

interface Props {
    selectedItem: BarItems | null;
    sidebarVisible: boolean;
    onItemClick: (item: BarItems) => void;
}

export function MapControlsBar(props: Props) {
    const { t } = useTranslation();
    const { pathname } = useLocation();

    return (
        <div
            className={cn('rl-map-controls-bar', {
                opened: props.sidebarVisible,
                ['rl-map-controls-bar-tracking']: pathname.includes(RouteNames.TRACKING)
            })}
        >
            <div
                className={cn('rl-map-controls-bar-item', { selected: BarItems.Map === props.selectedItem })}
                title={t('MapControls.Map.title')}
                onClick={() => props.onItemClick(BarItems.Map)}
            >
                <i className="rl-icon-map" />
            </div>
        </div>
    );
}
