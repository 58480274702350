import CryptoJS from 'crypto-js';

/**
 * Generates POI UUID
 * Lat and Long are rounded to two last digits.
 * UUID consists of concatenation of lat and long as hex strings plus first 8 bytes of name md5 representation as hex;
 *
 * @param latitude - either as a string or float (ie: 48.1462771)
 * @param longitude - either as a string or float (ie: 17.1147925)
 * @param name - poi name
 * @returns UUID as a string
 */
export function getPoiGuid(latitude: number, longitude: number, name: string) {
    return canonicalUUid(
        lpad(convertPositionToInt(longitude).toString(16), '0', 8) +
            lpad(convertPositionToInt(latitude).toString(16), '0', 8) +
            lpad(charCodesToHex(CryptoJS.MD5(name).toString(CryptoJS.enc.Hex).substr(0, 8)), '0', 12)
    );
}

/**
 * This is an SDK bug causing that md5 string is not appended correctly and thus had to be reimplemented here to
 * generate identical UUID
 * @param str
 * @returns {string}
 */
function charCodesToHex(str: string): string {
    let ret = '';
    for (let i = 0; i < str.length; i++) {
        ret += str.charCodeAt(i).toString(16);
    }
    return ret;
}

function canonicalUUid(hex: string) {
    return (
        hex.substr(0, 8) +
        '-' +
        hex.substr(8, 4) +
        '-' +
        hex.substr(12, 4) +
        '-' +
        hex.substr(16, 4) +
        '-' +
        hex.substr(20, 12)
    );
}

function lpad(str: string, char: string, length: number) {
    return !str || str.length >= length ? str : char.repeat(length - str.length) + str;
}

function convertPositionToInt(position: string | number, roundPlaces = 2) {
    const _rawPos = (typeof position === 'string' ? parseFloat(position) : position) * 100000;
    return Math.floor(_rawPos / Math.pow(10, roundPlaces)) * Math.pow(10, roundPlaces);
}
