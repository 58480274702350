import { RouteComponentProps, withRouter } from 'react-router-dom';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Component } from 'react';
import { ResetPasswordModal } from './ui/ResetPasswordModal/ResetPasswordModal';
import { Logic } from '../../logic/logic';
import { CreateNewPasswordModal } from './ui/CreateNewPasswordModal/CreateNewPasswordModal';
import Modal from 'components/base/layout/Modal/Modal';
import { Notification } from 'components/base/layout';
import { inject, observer } from 'mobx-react';
import { AuthStore } from 'stores/auth/AuthStore';
import { Stores } from 'stores/RootStore';

export enum ResetPasswordModalType {
    RESET_PASSWORD,
    CREATE_NEW_PASSWORD
}
interface Props extends WithTranslation, RouteComponentProps {
    authStore?: AuthStore;
    visible: ResetPasswordModalType | boolean;
    logic: Logic;
    onCancel?: () => void;
    token?: string;
}

interface State {
    resetPasswordSuccess: boolean;
}

@inject(Stores.AUTH_STORE)
@observer
class ResetPasswordModule extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            resetPasswordSuccess: false
        };
    }

    render() {
        return (
            <>
                <ResetPasswordModal
                    visible={this.props.visible === ResetPasswordModalType.RESET_PASSWORD}
                    onSubmitResetPassword={this._onSubmitResetPassword}
                    success={this.state.resetPasswordSuccess}
                    onCancel={this.props.onCancel}
                    onOpenLoginModal={this._openLoginModal}
                />

                <CreateNewPasswordModal
                    visible={this.props.visible === ResetPasswordModalType.CREATE_NEW_PASSWORD}
                    onCancel={this.props.onCancel}
                    onSubmitCreateNewPassword={this._onSubmitCreateNewPassword}
                />
            </>
        );
    }

    private _onSubmitResetPassword = async (email: string) => {
        try {
            await this.props.authStore?.resetPassword?.(email);
            this.setState({ resetPasswordSuccess: true });
        } catch (err) {
            console.error(err);
            Notification.error({ message: err.message });
        }
    };

    private _openLoginModal = () => {
        this.props.history.push({ search: '?login=true' });
    };

    private _onSubmitCreateNewPassword = async (password: string) => {
        try {
            await this.props.authStore?.confirmResetPassword?.(password, this.props.token!);

            Modal.success({
                title: this.props.t('CreateNewPasswordForm.successModalTitle'),
                okText: this.props.t('CreateNewPasswordForm.successModalSignIn'),
                onOk: () => {
                    this.props.onCancel?.();
                    this.props.history.push({ search: '?login=true' });
                }
            });
        } catch (err) {
            console.error(err);
            Notification.error({ message: err.message });
        }
    };
}

export default withRouter(withTranslation()(ResetPasswordModule));
