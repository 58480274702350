/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ClientContract
 */
export interface ClientContract {
    /**
     * 
     * @type {number}
     * @memberof ClientContract
     */
    readonly id?: number;
    /**
     * 
     * @type {number}
     * @memberof ClientContract
     */
    module: number;
    /**
     * 
     * @type {string}
     * @memberof ClientContract
     */
    externalId: string;
    /**
     * 
     * @type {string}
     * @memberof ClientContract
     */
    serviceAreaCode: string;
    /**
     * 
     * @type {Date}
     * @memberof ClientContract
     */
    validFrom: Date;
    /**
     * 
     * @type {Date}
     * @memberof ClientContract
     */
    validTo?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof ClientContract
     */
    client: number;
    /**
     * 
     * @type {number}
     * @memberof ClientContract
     */
    externalSystem: number;
    /**
     * 
     * @type {number}
     * @memberof ClientContract
     */
    clientContractStatus: number;
}

export function ClientContractFromJSON(json: any): ClientContract {
    return ClientContractFromJSONTyped(json, false);
}

export function ClientContractFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClientContract {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'module': json['module'],
        'externalId': json['external_id'],
        'serviceAreaCode': json['service_area_code'],
        'validFrom': (new Date(json['valid_from'])),
        'validTo': !exists(json, 'valid_to') ? undefined : (json['valid_to'] === null ? null : new Date(json['valid_to'])),
        'client': json['client'],
        'externalSystem': json['external_system'],
        'clientContractStatus': json['client_contract_status'],
    };
}

export function ClientContractToJSON(value?: ClientContract | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'module': value.module,
        'external_id': value.externalId,
        'service_area_code': value.serviceAreaCode,
        'valid_from': (value.validFrom.toISOString()),
        'valid_to': value.validTo === undefined ? undefined : (value.validTo === null ? null : value.validTo.toISOString()),
        'client': value.client,
        'external_system': value.externalSystem,
        'client_contract_status': value.clientContractStatus,
    };
}


