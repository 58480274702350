import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Logic } from 'logic/logic';
import { CompanyForm } from './ui/CompanyForm';
import { CompanyFormModel } from './ui/CompanyForm/CompanyForm';
import { Country } from 'services/api/domains/CountryApi';
import { Notification } from 'components/base/layout/Notification';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import { RouteNames } from 'router/routes';
import { Button } from 'components/base/controls';

interface Props {
    logic: Logic;
}

const CompanyModule = (props: Props) => {
    const { t } = useTranslation();
    const { isMobileWidth } = useWindowDimensions();
    const history = useHistory();

    const [countryList, setCountryList] = useState<Country[]>([]);
    const [formValues, setFormValues] = useState<CompanyFormModel>();
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        _loadFormData();
        _loadCountryList();
    }, []);

    const _loadFormData = async () => {
        setLoading(true);

        const companyId = props.logic.company().getCompany().companyId;

        return props.logic
            .apiService()
            .company()
            .getCompany(companyId)
            .then(data => {
                setFormValues({ ...data, countryCode: data.country?.code });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const _loadCountryList = async () => {
        const data = await props.logic.enums().countryList();
        setCountryList(data);
    };

    const _onFormSubmit = async (values: Partial<CompanyFormModel>) => {
        setLoading(true);

        try {
            await props.logic.company().updateCompany([
                { path: 'name', value: values.name },
                { path: 'taxId', value: values.taxId },
                { path: 'phone', value: values.phone },
                { path: 'email', value: values.email },
                { path: 'street', value: values.street },
                { path: 'streetNumber', value: values.streetNumber },
                { path: 'city', value: values.city },
                { path: 'postalCode', value: values.postalCode },
                { path: 'countryIso2', value: values.countryCode },
                { path: 'fleetSize', value: values.fleetSize }
            ]);

            Notification.success({ message: t('CompanyModule.updateSuccess') });
        } catch (err) {
            console.error(err);
            Notification.error({ message: err.message });
            throw err;
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="rl-settings-module rl-company-module">
            <div className="rl-settings-module-header">
                {isMobileWidth && (
                    <Button
                        className="rl-settings-module-header-back"
                        type="action"
                        icon={<i className="rl-icon-chevron-left" />}
                        onClick={() => history.push(RouteNames.SETTINGS)}
                    />
                )}

                <h3 className="rl-settings-module-header-title">{t('common.company')}</h3>
            </div>

            <div className="rl-settings-module-content">
                <CompanyForm loading={loading} values={formValues} countryList={countryList} onSubmit={_onFormSubmit} />
            </div>
        </div>
    );
};

export default CompanyModule;
