/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InlineResponse20032,
    InlineResponse20032FromJSON,
    InlineResponse20032ToJSON,
    ReadOnlyServiceSerializer,
    ReadOnlyServiceSerializerFromJSON,
    ReadOnlyServiceSerializerToJSON,
    WriteOnlyServiceSerializer,
    WriteOnlyServiceSerializerFromJSON,
    WriteOnlyServiceSerializerToJSON,
} from '../models';

export interface ServiceCreateRequest {
    data: WriteOnlyServiceSerializer;
}

export interface ServiceDeleteRequest {
    id: string;
}

export interface ServiceListRequest {
    createdAtIexact?: string;
    createdAtGte?: string;
    createdAtLte?: string;
    limit?: number;
    offset?: number;
}

export interface ServicePartialUpdateRequest {
    id: string;
    data: WriteOnlyServiceSerializer;
}

export interface ServiceReadRequest {
    id: string;
}

export interface ServiceUpdateRequest {
    id: string;
    data: WriteOnlyServiceSerializer;
}

/**
 * no description
 */
export class ServiceApi extends runtime.BaseAPI {

    /**
     */
    async serviceCreateRaw(requestParameters: ServiceCreateRequest): Promise<runtime.ApiResponse<ReadOnlyServiceSerializer>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling serviceCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/service/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyServiceSerializerToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyServiceSerializerFromJSON(jsonValue));
    }

    /**
     */
    async serviceCreate(requestParameters: ServiceCreateRequest): Promise<ReadOnlyServiceSerializer> {
        const response = await this.serviceCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async serviceDeleteRaw(requestParameters: ServiceDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling serviceDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/service/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async serviceDelete(requestParameters: ServiceDeleteRequest): Promise<void> {
        await this.serviceDeleteRaw(requestParameters);
    }

    /**
     */
    async serviceListRaw(requestParameters: ServiceListRequest): Promise<runtime.ApiResponse<InlineResponse20032>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.createdAtIexact !== undefined) {
            queryParameters['created_at__iexact'] = requestParameters.createdAtIexact;
        }

        if (requestParameters.createdAtGte !== undefined) {
            queryParameters['created_at__gte'] = requestParameters.createdAtGte;
        }

        if (requestParameters.createdAtLte !== undefined) {
            queryParameters['created_at__lte'] = requestParameters.createdAtLte;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/service/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20032FromJSON(jsonValue));
    }

    /**
     */
    async serviceList(requestParameters: ServiceListRequest): Promise<InlineResponse20032> {
        const response = await this.serviceListRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async servicePartialUpdateRaw(requestParameters: ServicePartialUpdateRequest): Promise<runtime.ApiResponse<ReadOnlyServiceSerializer>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling servicePartialUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling servicePartialUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/service/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyServiceSerializerToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyServiceSerializerFromJSON(jsonValue));
    }

    /**
     */
    async servicePartialUpdate(requestParameters: ServicePartialUpdateRequest): Promise<ReadOnlyServiceSerializer> {
        const response = await this.servicePartialUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async serviceReadRaw(requestParameters: ServiceReadRequest): Promise<runtime.ApiResponse<ReadOnlyServiceSerializer>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling serviceRead.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/service/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyServiceSerializerFromJSON(jsonValue));
    }

    /**
     */
    async serviceRead(requestParameters: ServiceReadRequest): Promise<ReadOnlyServiceSerializer> {
        const response = await this.serviceReadRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async serviceUpdateRaw(requestParameters: ServiceUpdateRequest): Promise<runtime.ApiResponse<ReadOnlyServiceSerializer>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling serviceUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling serviceUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/service/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyServiceSerializerToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyServiceSerializerFromJSON(jsonValue));
    }

    /**
     */
    async serviceUpdate(requestParameters: ServiceUpdateRequest): Promise<ReadOnlyServiceSerializer> {
        const response = await this.serviceUpdateRaw(requestParameters);
        return await response.value();
    }

}
