/* tslint:disable */
/* eslint-disable */
/**
 * Telematics API
 * Telematics services API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Stuff for controllers
 * @export
 * @enum {string}
 */
export enum MainDataVersion {
    V1 = 'v1'
}

export function MainDataVersionFromJSON(json: any): MainDataVersion {
    return MainDataVersionFromJSONTyped(json, false);
}

export function MainDataVersionFromJSONTyped(json: any, ignoreDiscriminator: boolean): MainDataVersion {
    return json as MainDataVersion;
}

export function MainDataVersionToJSON(value?: MainDataVersion | null): any {
    return value as any;
}

