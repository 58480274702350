/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Score
 */
export interface Score {
    /**
     * 
     * @type {number}
     * @memberof Score
     */
    economy: number;
    /**
     * 
     * @type {number}
     * @memberof Score
     */
    safety: number;
    /**
     * 
     * @type {number}
     * @memberof Score
     */
    ecology: number;
    /**
     * 
     * @type {number}
     * @memberof Score
     */
    overall: number;
}

export function ScoreFromJSON(json: any): Score {
    return ScoreFromJSONTyped(json, false);
}

export function ScoreFromJSONTyped(json: any, ignoreDiscriminator: boolean): Score {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'economy': json['economy'],
        'safety': json['safety'],
        'ecology': json['ecology'],
        'overall': json['overall'],
    };
}

export function ScoreToJSON(value?: Score | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'economy': value.economy,
        'safety': value.safety,
        'ecology': value.ecology,
        'overall': value.overall,
    };
}


