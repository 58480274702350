import { Conf } from 'conf';
import { HttpClient } from '../common/HttpClient';

export enum RouteVehicleType {
    Car = 'car',
    Truck = 'truck',
    Pedestrian = 'pedestrian',
    Camper = 'camper',
    GarbageTruck = 'garbagetruck',
    Van = 'van'
}

export interface RoutePlanModel {
    wayPoints: RouteWayPoint[];
    vehicleType: RouteVehicleType;
    profile?: RouteVehicleProfile;
    departure?: string | null;
    routePerWayPoint?: boolean | null;
    avoids?: string | null;
}

export interface RouteWayPoint {
    id?: string | null;
    lat: number;
    lng: number;
    arrival?: string | null;
    departure?: string | null;
    name?: string | null;
    type?: string;
    additionalTimeSec?: number;
}

interface RouteVehicleProfile {
    width?: number | null;
    height?: number | null;
    weight?: number | null;
    length?: number | null;
    tunnel?: string | null;
    trailersCount?: number | null;
    vehicleAxles?: number | null;
    axleWeight?: number | null;
    loadType?: number | null;
    label?: string | null;
    maxSpeed?: number | null;
}

export enum RouteType {
    Fastest = 'fastest',
    Balanced = 'balanced'
}

interface PlannedRoutePlace {
    id?: string | null;
    name?: string | null;
    departure: string;
    arrival: string;
    duration?: number | null;
    distance?: number | null;
    position: {
        lat?: number;
        lng?: number;
    };
    route?: string | null;
    type?: string;
}

export interface PlannedRoute {
    type: RouteType;
    firstRta: string;
    lastRta?: string | null;
    duration?: number | null;
    distance?: number | null;
    places: PlannedRoutePlace[];
    possibleAvoids?: object;
    routePolyline?: string | null;
    eta?: string | null;
}

class RoutingApi {
    private _abortController: AbortController;

    constructor(private _conf: Conf['api'], private _httpClient: HttpClient) {
        this._abortController = new AbortController();
    }

    cancelRequests = () => {
        this._abortController?.abort();
        this._abortController = new AbortController();
    };

    async plan(request: RoutePlanModel): Promise<PlannedRoute[]> {
        return await this._httpClient.post(`${this._conf.middlewareUrl}/routing/plan`, request, {
            signal: this._abortController.signal
        });
    }
}

export { RoutingApi };
