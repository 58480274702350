import { Conf } from 'conf';
import { HttpClient } from '../common/HttpClient';
import axiosRetry from 'axios-retry';

const PLACES_API_VERSION = '4';

export interface PlaceBasic {
    id: string;
    name: string;
    brand: string;
    category: number;
    location: {
        lon: number;
        lat: number;
    };
    entryPoint: {
        lon: number;
        lat: number;
    };
    countryCode: string;
    attrs: {
        [key: string]: string;
    };
}

export interface PlaceBasicInfo {
    id: string;
    name: string;
    brand: {
        id: string;
        name: string;
        logoUrl: string;
    };
    category: number;
    location: {
        lon: number;
        lat: number;
    };
    entryPoint: {
        lon: number;
        lat: number;
    };
    countryCode: string;
}

export interface PlacePost {
    id: string;
    brandName?: string;
    brandId?: string;
    name?: string;
    parkingSize?: number;
    position: {
        lon: number;
        lat: number;
    };
    category: PlaceType;
    countryIso2: string;
    countryCode?: string;
    address: string;
    city: string;
}

export enum PlaceType {
    COMPANY = 230,
    PARKING_LOT = 231,
    SHOP = 232,
    FOOD_AND_DRINK = 233,
    GAS_STATION = 234,
    BORDER_CROSSING = 235
}

export interface PlaceReviewItem {
    id: number;
    rating?: number;
    profile?: PlaceReviewProfile;
    message?: string;
    createdOn: string;
}

interface PlaceReviewProfile {
    id?: number;
    avatarId?: string;
    avatarUrl?: string;
    nick?: string;
    countryCode?: string;
}

export interface PlaceReviews {
    totalCount: number;
    items?: PlaceReviewItem[];
    currentUserHasReview: boolean;
}

interface PlaceParkingStatus {
    availability: number;
    updatedOn?: string;
    placeId: string;
    parkingSize?: number;
}

interface PlaceDetail {
    rating?: number;
    reviews: PlaceReviews;
    services: { serviceName: string; status: number }[];
    tags: { key?: string; name?: string }[];
    parkingStatus: PlaceParkingStatus;
    openingHours?: string;
    loadUnloadHours?: string;
    brand?: FuelStationBrandModel;
    fuelPaymentCards?: FuelPaymentCard[];
}

export interface FuelStationModel {
    _id: string;
    center: {
        type: string;
        coordinates: [number, number];
    };
    externalId: string;
    name: string;
    services: string[];
    fuelTypes: string[];
    fuelCardIds: number[];
}

export interface ParkingLotModel {
    _id: string;
    center: {
        type: string;
        coordinates: [number, number];
    };
    name: string;
}

export interface FuelStationBrandModel {
    id: string;
    name: string;
    logoUrl: string;
}

export interface FuelPaymentCard {
    id: string;
    name: string;
}

interface PlaceInArea {
    id: string;
    position: {
        lat: number;
        lng: number;
    };
    name: string;
    category: number;
    selected: boolean;
}

class PlacesApi {
    constructor(private _conf: Conf['api'], private _httpClient: HttpClient) {}

    async getPlace(placeId: string): Promise<PlaceBasic> {
        return this._httpClient.get(`${this._conf.coreUrl}/place/basic?placeId=${placeId}`, {
            apiVersion: '1'
        });
    }

    async getPlaceDetail(placeId: string): Promise<PlaceDetail> {
        return this._httpClient.get(`${this._conf.coreUrl}/place?placeId=${placeId}`, {
            apiVersion: '7',
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    async postPlace(model: PlacePost) {
        if (model.category === PlaceType.GAS_STATION) {
            model.name = model.brandName;
        } else {
            delete model.brandName;
            delete model.brandId;
        }

        if (model.category === PlaceType.PARKING_LOT) {
            model.parkingSize = 0;
        }

        const bodyFormData = new FormData();
        bodyFormData.set('details', JSON.stringify(model));

        return this._httpClient.post<PlaceBasicInfo>(`${this._conf.coreUrl}/place`, bodyFormData, {
            apiVersion: '7'
        });
    }

    async getPlacesInArea(bounds: string) {
        const { data } = await this._httpClient.get<{ data: { places: PlaceInArea[] } }>(
            `${this._conf.middlewareUrl}/places/inarea?bounds=${bounds}`,
            {
                apiVersion: PLACES_API_VERSION,
                timeout: 6000
            }
        );
        return data.places;
    }

    async getFuelPaymentsCards() {
        return await this._httpClient.get<FuelPaymentCard[]>(`${this._conf.coreUrl}/place/fuelpaymentcards`, {
            apiVersion: '7'
        });
    }

    async getFuelStations() {
        return this._httpClient.get<{ data: { fuelStations: FuelStationModel[] } }>(
            `${this._conf.middlewareUrl}/places/get-fuel-stations`,
            {
                'axios-retry': {
                    retries: 5,
                    retryDelay: axiosRetry.exponentialDelay,
                    retryCondition: err => !!(err.response && err.response.status >= 400)
                }
            }
        );
    }

    async getParkings() {
        return this._httpClient.get<{ data: { parkingLots: ParkingLotModel[] } }>(
            `${this._conf.middlewareUrl}/places/get-parking-lots`,
            {
                'axios-retry': {
                    retries: 5,
                    retryDelay: axiosRetry.exponentialDelay,
                    retryCondition: err => !!(err.response && err.response.status >= 400)
                }
            }
        );
    }

    async getFuelStationBrands() {
        return this._httpClient.get<{ brands: FuelStationBrandModel[] }>(`${this._conf.placesUrl}/brand`, {
            apiVersion: '1'
        });
    }

    async clearCache() {
        return this._httpClient.post(`${this._conf.middlewareUrl}/places/clearCache`, null);
    }
}

export { PlacesApi };
