import mapboxgl from 'mapbox-gl';
import { TrackingStatus } from 'modules/TrackingModule/ui/TransportStatusIndicator';

export const latlngRegex =
    /^([+-])?(?:90(?:(?:\.0{1,23})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,23})?))[,;]\s*([+-])?(?:180(?:(?:\.0{1,23})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,23})?))$/;

export function createIcon(
    name: string,
    src: string,
    map: mapboxgl.Map,
    options?: { width?: number; height?: number }
) {
    return new Promise<void>(res => {
        const icon = new Image(options?.width, options?.height);
        icon.src = src;

        icon.onload = () => {
            map.addImage(name, icon);
            res();
        };
    });
}

export function getCenterBoundsWithDistance(lng: number, lat: number, distanceInMeters = 2000): mapboxgl.LngLatBounds {
    const center = new mapboxgl.LngLat(lng, lat);
    const bounds = new mapboxgl.LngLatBounds();
    bounds.extend(center.toBounds(distanceInMeters));
    return bounds;
}

export function parseTextToPosition(text: string): {
    position: { address: string; lng?: number; lat?: number };
    isValid: boolean;
} {
    const result = {
        position: { address: text },
        isValid: false
    };

    if (text?.match(latlngRegex)) {
        const latlng = text.replace(/ +/g, '').split(/,|;/);
        if (latlng && latlng.length === 2) {
            const lat = +latlng[0];
            const lng = +latlng[1];

            if (!isNaN(lat) && !isNaN(lng)) {
                result.position = {
                    ...result.position,
                    ...{ lat, lng }
                };

                result.isValid = true;
            }
        }
    }

    return result;
}

export function pulsingDot(map: mapboxgl.Map, size: number) {
    // This implements `StyleImageInterface`
    // to draw a pulsing dot icon on the map.
    return {
        width: size,
        height: size,
        data: new Uint8Array(size * size * 4),

        // When the layer is added to the map,
        // get the rendering context for the map canvas.
        onAdd: function () {
            const canvas = document.createElement('canvas');
            canvas.width = this.width;
            canvas.height = this.height;
            (this as any).context = canvas.getContext('2d');
        },

        // Call once before every frame where the icon will be used.
        render: function () {
            const duration = 1000;
            const t = (performance.now() % duration) / duration;

            const radius = (size / 2) * 0.3;
            const outerRadius = (size / 2) * 0.7 * t + radius;
            const context = (this as any).context;

            // Draw the outer circle.
            context.clearRect(0, 0, this.width, this.height);
            context.beginPath();
            context.arc(this.width / 2, this.height / 2, outerRadius, 0, Math.PI * 2);
            context.fillStyle = `rgba(189, 203, 206, ${1 - t})`;
            context.fill();

            // Update this image's data with data from the canvas.
            this.data = context.getImageData(0, 0, this.width, this.height).data;

            // Continuously repaint the map, resulting
            // in the smooth animation of the dot.
            map.triggerRepaint();

            // Return `true` to let the map know that the image was updated.
            return true;
        }
    };
}

export function getVehiclePopupStatus(status: TrackingStatus) {
    if (status === TrackingStatus.ACTIVE) {
        return 'on-time';
    } else if (status === TrackingStatus.DELAYED) {
        return 'delayed';
    } else if (status === TrackingStatus.ASSIGNED) {
        return 'assigned';
    }

    return 'no-transport';
}
