import { Dropdown, DropDownProps } from 'antd';
import { ReactNode } from 'react';

interface Props extends DropDownProps {
    children: ReactNode;
}

EnhancedDropdown.Button = Dropdown.Button;

export default function EnhancedDropdown(props: Props) {
    return <Dropdown {...props} className={props.className ? 'rl-dropdown ' + props.className : 'rl-dropdown'} />;
}
