import { Conf } from 'conf';
import { HttpClient } from '../common/HttpClient';

export interface ReverseGeocodingModel {
    countryIso: string;
    formattedResult: string;
    city?: string;
}

interface GeocodingComponent {
    type: GeocodingComponentType;
    value: string;
}

interface GeocodingResultItem {
    components: GeocodingComponent[];
    formatted_result: string;
    location: {
        lat: number;
        lon: number;
    };
    location_type: GeocodingLocationType;
    type: GeocodingType;
    country_iso: string;
    distance?: number;
}

enum GeocodingComponentType {
    COUNTRY_NAME = 'admin_level_2',
    COUNTRY_SUBDIVISION = 'admin_level_3',
    COUNTRY_SECONDARY_SUBDIVISION = 'admin_level_4',
    MUNICIPALITY_NAME = 'admin_level_8',
    MUNICIPALITY_SUBDIVISION = 'admin_level_9',
    POSTAL_CODE = 'postal_code',
    STREET_NAME = 'street',
    HOUSE_NUMBER = 'house_number',
    ROUTE_NUMBER = 'route'
}

enum GeocodingLocationType {
    EXACT = 'exact',
    INTERPOLATED = 'interpolated',
    CLOSEST_POINT = 'closest_point',
    CENTROID = 'centroid'
}

enum GeocodingType {
    ADDRESS = 'address',
    ROAD = 'road',
    LOCALITY = 'locality'
}

class GeocodingApi {
    constructor(private _conf: Conf['api'], private _httpClient: HttpClient) {}

    async reverseGeocoding(lat: number, lon: number): Promise<ReverseGeocodingModel> {
        const { results } = await this._httpClient.get<{ results: GeocodingResultItem[] }>(
            `${this._conf.geocodingUrl}/v2/api/reversegeocode?location=${lat},${lon}`
        );

        if (results.length) {
            return {
                countryIso: results[0].country_iso,
                formattedResult: results[0].formatted_result,
                city: results[0].components.find(
                    (component: any) => component.type === GeocodingComponentType.MUNICIPALITY_NAME
                )?.value
            };
        }

        throw new Error('NO_RESULT');
    }
}

export { GeocodingApi };
