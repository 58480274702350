import { Logic } from 'logic/logic';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { ShareEtaCode, ShareEtaCodeState } from 'services/api/domains/ShareEtaApi';
import { ShareEtaModal } from './ui/ShareEtaModal';
import { ShareEtaStopModal } from './ui/ShareEtaStopModal';

export interface ShareEtaRouteInfo {
    destination?: string;
    remainingDistance?: number;
    eta?: string;
}

interface Props {
    logic: Logic;
    driverId?: string;
    shareCode?: ShareEtaCode;
    shareEtaModalVisible?: boolean;
    stopShareEtaModalVisible?: boolean;
    onCloseShareEtaModal: (reload: boolean) => void;
    onCancelStopShareEtaModal: () => void;
    onStopShareEta: () => void;
}

const ShareEtaModule = (props: Props) => {
    const [shareEtaCode, setShareEtaCode] = useState<ShareEtaCode | null>();
    const [loading, setLoading] = useState(false);
    const [shareInfo, setShareInfo] = useState<ShareEtaRouteInfo>();

    useEffect(() => {
        if (props.driverId) {
            setLoading(true);
            const companyId = props.logic.company().getCompany().companyId;

            props.logic
                .apiService()
                .company()
                .getNavigationRoute(companyId, props.driverId)
                .then(result => {
                    setShareInfo({
                        destination: result.endPoint.address ?? result.endPoint.name,
                        remainingDistance: result.remainingDistance,
                        eta: result.eta
                    });
                    setLoading(false);
                })
                .catch(err => {
                    console.error(err);
                    setShareInfo(undefined);
                    setLoading(false);
                });
        }

        if (
            props.shareCode &&
            props.shareCode.state === ShareEtaCodeState.Active &&
            moment(props.shareCode.validUntil) >= moment()
        ) {
            setShareEtaCode(props.shareCode);
        } else {
            _createNewLink();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.driverId]);

    const _createNewLink = async () => {
        try {
            if (props.driverId) {
                setLoading(true);
                const result = await props.logic.shareEta().createShareEtaCode(props.driverId);
                setShareEtaCode(result);
            }
        } catch (err) {
            console.error(err);
            setShareEtaCode(null);
        } finally {
            setLoading(false);
        }
    };

    const _stopSharing = async () => {
        try {
            if (props.shareCode?.driverId) {
                setLoading(true);
                await props.logic.shareEta().cancelShareEtaCode(props.shareCode.code);
                props.onStopShareEta();
            }
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
            props.onCancelStopShareEtaModal();
        }
    };

    return (
        <>
            <ShareEtaModal
                shareInfo={shareInfo}
                visible={props.shareEtaModalVisible}
                loading={loading}
                shareEtaLink={shareEtaCode?.etaShareLink!}
                companyName={props.logic.company().getCompany().name}
                onCloseShareEtaModal={() =>
                    props.onCloseShareEtaModal(props.shareCode?.etaShareLink !== shareEtaCode?.etaShareLink)
                }
            />

            <ShareEtaStopModal
                loading={loading}
                visible={props.stopShareEtaModalVisible}
                onCancelStopShareEtaModal={props.onCancelStopShareEtaModal}
                onStopSharingClick={_stopSharing}
            />
        </>
    );
};

export default ShareEtaModule;
