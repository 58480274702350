import cn from 'classnames';
import { Button } from 'components/base/controls';
import { PlacesModel } from 'modules/PlannerModule/PlannerModule';
import qa from 'qa-selectors';
import { useTranslation } from 'react-i18next';
import { TransportState } from 'services/api/domains/TransportsApi';

interface Props {
    places: PlacesModel[];
    loading?: boolean;
    isEditTransport: boolean;
    transportState?: TransportState;
    transportUpdated?: boolean;
    onPlannerCancel?: () => void;
    onPlannerSave?: () => void;
    onPlannerDelete?: () => void;
}

const PlannerSearchFooter = (props: Props) => {
    const { t } = useTranslation();

    const disabledEdit =
        props.transportState && [TransportState.Active, TransportState.Finished].includes(props.transportState);

    const buttonToolTipText = {
        [TransportState.Active]: t('Planner.editActiveTransport'),
        [TransportState.Finished]: t('Planner.editCompletedTransport')
    } as const;

    return (
        <div
            className={cn('rl-planner-footer', {
                'rl-planner-footer-planned': props.places?.length,
                'rl-planner-footer-edit-transport': props.isEditTransport
            })}
        >
            {props.places.length > 0 && (
                <Button
                    onClick={() => props.onPlannerCancel?.()}
                    type="default"
                    className="rl-planner-footer-close"
                    data-qa={qa.schedulingPlanning.cancelWithoutSave}
                >
                    {t('Planner.cancel')}
                </Button>
            )}

            {props.isEditTransport && (
                <Button
                    onClick={() => props.onPlannerDelete?.()}
                    type="link"
                    danger
                    title={props.transportState && buttonToolTipText[props.transportState]}
                    className="rl-planner-footer-delete"
                    disabled={props.loading || disabledEdit}
                    data-qa={qa.schedulingPlanning.deleteTransport}
                    icon={<i className="rl-button-icon fas fa-trash-alt" />}
                >
                    {t('Planner.delete')}
                </Button>
            )}

            {props.places.length > 1 && (
                <Button
                    className="rl-planner-footer-save"
                    onClick={() => props.onPlannerSave?.()}
                    type="primary"
                    title={props.transportState && buttonToolTipText[props.transportState]}
                    disabled={props.loading || disabledEdit || (!props.transportUpdated && props.isEditTransport)}
                    data-qa={qa.schedulingPlanning.saveRoute}
                >
                    {t(props.isEditTransport ? 'Planner.updateRoute' : 'Planner.saveRoute')}
                </Button>
            )}
        </div>
    );
};

export default PlannerSearchFooter;
