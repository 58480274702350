/* tslint:disable */
/* eslint-disable */
/**
 * Geocoding API
 * Telematics services geocoding API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AddressComponent
 */
export interface AddressComponent {
    /**
     * is an array indicating the *type* of the address component.
     * @type {Array<object>}
     * @memberof AddressComponent
     */
    types: Array<object>;
    /**
     * is the full text description or name of the address component as returned by the Geocoder.
     * @type {string}
     * @memberof AddressComponent
     */
    longName: string;
    /**
     * is an abbreviated textual name for the address component, if available. For example, an address component for the state of Alaska may have a `long_name` of \"Alaska\" and a `short_name` of \"AK\" using the 2-letter postal abbreviation.
     * @type {string}
     * @memberof AddressComponent
     */
    shortName: string;
}

export function AddressComponentFromJSON(json: any): AddressComponent {
    return AddressComponentFromJSONTyped(json, false);
}

export function AddressComponentFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddressComponent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'types': json['types'],
        'longName': json['long_name'],
        'shortName': json['short_name'],
    };
}

export function AddressComponentToJSON(value?: AddressComponent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'types': value.types,
        'long_name': value.longName,
        'short_name': value.shortName,
    };
}


