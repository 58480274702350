import { WithTranslation, withTranslation } from 'react-i18next';
import { Component } from 'react';
import { PoiModelMap } from '../../logic/map/logic/fuelStations';
import { FavoriteType } from '../../services/api/domains/FavoritesApi';
import { Drawer, Notification } from 'components/base/layout';
import { Logic } from '../../logic/logic';
import { FuelStationBrandModel, PlacePost } from '../../services/api/domains/PlacesApi';
import { CreateNewPlaceForm } from './ui/CreateNewPlaceForm/CreateNewPlaceForm';
import mapboxgl from 'mapbox-gl';
import { LatLng } from '../../common/model/geo';
import { ReverseGeocodingModel } from '../../services/api/domains/GeocodingApi';
import { InitiatedFrom } from 'logic/userEventsProviders/GoogleTagManager';

interface Props extends WithTranslation {
    visible?: boolean;
    mask?: boolean;
    coords?: { lat: number; lng: number };
    logic: Logic;
    onClose: () => void;
    onAfterSubmit?: () => void;
    onAfterCreate?: (category: number, brand: string) => void;
}

interface State {
    model?: {
        address?: string;
        center?: LatLng;
        countryIso?: string;
    };
    fuelStationBrands?: FuelStationBrandModel[];
    loading: boolean;
}

class CreateNewPlaceModule extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            loading: false
        };
    }

    private async _fetchBrands() {
        return this.props.logic
            .apiService()
            .places()
            .getFuelStationBrands()
            .then(result => {
                this.setState({ fuelStationBrands: result.brands });
            });
    }

    componentDidMount() {
        if (this.props.visible) {
            this._fetchBrands();
        }
    }

    componentDidUpdate(prevProps: Readonly<Props>) {
        if (
            prevProps.coords?.lat !== this.props.coords?.lat! &&
            prevProps.coords?.lng !== this.props.coords?.lng! &&
            this.props.visible
        ) {
            if (this.props.coords?.lat! && this.props.coords?.lng!) {
                this._createPoiMarker(this.props.coords?.lat!, this.props.coords?.lng!);
            } else {
                this.props.logic.map().poi().destroy();
                this.props.logic.map().poiOff();
            }
        }

        if (!this.props.visible && this.props.visible !== prevProps.visible) {
            this.setState({ model: undefined });
        }

        if (this.props.visible && this.props.visible !== prevProps.visible) {
            this._fetchBrands();
        }
    }

    render() {
        return (
            <Drawer
                title={this.props.t('CreateNewPlaceForm.title')}
                width={424}
                onClose={this.props.onClose}
                visible={this.props.visible}
                mask={this.props.mask}
                destroyOnClose
                className="rl-map-drawer rl-create-new-place-drawer"
            >
                <CreateNewPlaceForm
                    poi={this.state.model}
                    loading={this.state.loading}
                    fuelStationBrands={this.state.fuelStationBrands}
                    onCreate={this._onPoiFormSubmit}
                    onEnterChangePoiMarkerCoords={this._createPoiMarker}
                />
            </Drawer>
        );
    }

    private _onPoiFormSubmit = async (model: PlacePost, addToFavorites: boolean) => {
        try {
            this.setState({ loading: true });
            const createdPoi = await this.props.logic.apiService().places().postPlace(model);
            const poiMap: PoiModelMap = {
                id: createdPoi.id,
                category: createdPoi.category,
                position: {
                    lng: createdPoi.location.lon,
                    lat: createdPoi.location.lat
                },
                externalId: createdPoi.id,
                name: createdPoi.name! || createdPoi.brand.name!,
                selected: false,
                services: [],
                fuelTypes: []
            };

            this.props.logic.poi().addPoiToCollectionAndShowOnMap(poiMap, createdPoi.category);

            if (addToFavorites) {
                const type = String(createdPoi.category) as FavoriteType;
                try {
                    await this.props.logic
                        .poi()
                        .addToFavorite(createdPoi.id, type, createdPoi.name, createdPoi.location);
                    this.props.logic
                        .userEvents()
                        .addFavorite(
                            createdPoi.category,
                            createdPoi.brand?.id,
                            InitiatedFrom.ADD_NEW_PLACE_ADD_FAVORITE_CHECKBOX
                        );
                } catch (error) {
                    console.error('Add To Favorites failed');
                }
            }

            Notification.info({
                message: this.props.t('CreateNewPlaceForm.inReview'),
                description: this.props.t('CreateNewPlaceForm.inReviewDescription')
            });

            // Clear middleware cache
            this.props.logic.apiService().places().clearCache().catch(console.error);

            this.props.onAfterCreate?.(createdPoi.category, createdPoi.brand?.name);
        } catch (err) {
            console.error(err);
            Notification.error({
                message: this.props.t('CreateNewPlaceForm.createFailed')
            });
        }

        this.setState({ loading: false });
        this.props.onAfterSubmit?.();
    };

    private _createPoiMarker = (lat?: number, lng?: number) => {
        if (lat && lng) {
            this._setPlaceModel(lat, lng);

            this.props.logic.map().poi().destroy();
            this.props.logic.map().poiOff();

            const point = new mapboxgl.LngLat(lng, lat);
            this.props.logic
                .map()
                .poi()
                .renderPoiMarker(point, (lat, lng) => {
                    this._setPlaceModel(lat, lng);
                });
        }
    };

    private _setPlaceModel = (lat: number, lng: number) => {
        let geoResult: ReverseGeocodingModel;

        this.props.logic
            .apiService()
            .geocoding()
            .reverseGeocoding(lat, lng)
            .then(result => {
                geoResult = result;
            })
            .finally(() => {
                this.setState(state => ({
                    model: {
                        ...state.model,
                        address: geoResult?.formattedResult,
                        countryIso: geoResult?.countryIso,
                        city: geoResult?.city,
                        center: {
                            lng: lng,
                            lat: lat
                        }
                    }
                }));
            });
    };
}

export default withTranslation()(CreateNewPlaceModule);
