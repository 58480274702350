/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BackendApiApiV1DriverbehaviourDriver
 */
export interface BackendApiApiV1DriverbehaviourDriver {
    /**
     * 
     * @type {number}
     * @memberof BackendApiApiV1DriverbehaviourDriver
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof BackendApiApiV1DriverbehaviourDriver
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof BackendApiApiV1DriverbehaviourDriver
     */
    surname: string;
    /**
     * 
     * @type {string}
     * @memberof BackendApiApiV1DriverbehaviourDriver
     */
    tachoCard: string;
}

export function BackendApiApiV1DriverbehaviourDriverFromJSON(json: any): BackendApiApiV1DriverbehaviourDriver {
    return BackendApiApiV1DriverbehaviourDriverFromJSONTyped(json, false);
}

export function BackendApiApiV1DriverbehaviourDriverFromJSONTyped(json: any, ignoreDiscriminator: boolean): BackendApiApiV1DriverbehaviourDriver {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'surname': json['surname'],
        'tachoCard': json['tachoCard'],
    };
}

export function BackendApiApiV1DriverbehaviourDriverToJSON(value?: BackendApiApiV1DriverbehaviourDriver | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'surname': value.surname,
        'tachoCard': value.tachoCard,
    };
}


