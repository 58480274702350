/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Ratings
 */
export interface Ratings {
    /**
     * 
     * @type {number}
     * @memberof Ratings
     */
    cityAggresiveTakeoffPenalty: number;
    /**
     * 
     * @type {number}
     * @memberof Ratings
     */
    cityDangerousBreakingPenalty: number;
    /**
     * 
     * @type {number}
     * @memberof Ratings
     */
    cityDangerousTurnPenalty: number;
    /**
     * 
     * @type {number}
     * @memberof Ratings
     */
    countryDangerousBreakingPenalty: number;
    /**
     * 
     * @type {number}
     * @memberof Ratings
     */
    countryDangerousTurnPenalty: number;
    /**
     * 
     * @type {number}
     * @memberof Ratings
     */
    countryUnevenDrivePenalty: number;
    /**
     * 
     * @type {number}
     * @memberof Ratings
     */
    highwayDangerousBreakingPenalty: number;
    /**
     * 
     * @type {number}
     * @memberof Ratings
     */
    highwayDangerousTurnPenalty: number;
    /**
     * 
     * @type {number}
     * @memberof Ratings
     */
    highwayUnevenDrivePenalty: number;
    /**
     * 
     * @type {number}
     * @memberof Ratings
     */
    highwayDangerousSpeedPenalty: number;
}

export function RatingsFromJSON(json: any): Ratings {
    return RatingsFromJSONTyped(json, false);
}

export function RatingsFromJSONTyped(json: any, ignoreDiscriminator: boolean): Ratings {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cityAggresiveTakeoffPenalty': json['city_aggresive_takeoff_penalty'],
        'cityDangerousBreakingPenalty': json['city_dangerous_breaking_penalty'],
        'cityDangerousTurnPenalty': json['city_dangerous_turn_penalty'],
        'countryDangerousBreakingPenalty': json['country_dangerous_breaking_penalty'],
        'countryDangerousTurnPenalty': json['country_dangerous_turn_penalty'],
        'countryUnevenDrivePenalty': json['country_uneven_drive_penalty'],
        'highwayDangerousBreakingPenalty': json['highway_dangerous_breaking_penalty'],
        'highwayDangerousTurnPenalty': json['highway_dangerous_turn_penalty'],
        'highwayUnevenDrivePenalty': json['highway_uneven_drive_penalty'],
        'highwayDangerousSpeedPenalty': json['highway_dangerous_speed_penalty'],
    };
}

export function RatingsToJSON(value?: Ratings | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'city_aggresive_takeoff_penalty': value.cityAggresiveTakeoffPenalty,
        'city_dangerous_breaking_penalty': value.cityDangerousBreakingPenalty,
        'city_dangerous_turn_penalty': value.cityDangerousTurnPenalty,
        'country_dangerous_breaking_penalty': value.countryDangerousBreakingPenalty,
        'country_dangerous_turn_penalty': value.countryDangerousTurnPenalty,
        'country_uneven_drive_penalty': value.countryUnevenDrivePenalty,
        'highway_dangerous_breaking_penalty': value.highwayDangerousBreakingPenalty,
        'highway_dangerous_turn_penalty': value.highwayDangerousTurnPenalty,
        'highway_uneven_drive_penalty': value.highwayUnevenDrivePenalty,
        'highway_dangerous_speed_penalty': value.highwayDangerousSpeedPenalty,
    };
}


