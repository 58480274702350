import { Space } from 'antd';
import { Popover } from 'components/base/controls';

interface Props {
    badgeText: string;
    popoverTitle: string;
    popoverText: string;
    popoverLink?: string;
    onLinkClick?: VoidFunction;
}

const BadgeNew = (props: Props) => {
    return (
        <Popover
            content={
                <div className="rl-badge-popover">
                    <div className="rl-badge-popover-title">{props.popoverTitle}</div>
                    <div className="rl-badge-popover-content">{props.popoverText}</div>
                    {props.popoverLink && (
                        <div className="rl-badge-popover-link" onClick={() => props.onLinkClick}>
                            {props.popoverLink}
                        </div>
                    )}
                </div>
            }
            placement={'bottomLeft'}
            color={'white'}
        >
            <Space size={5}>
                <div className="rl-badge-new">
                    <div className="rl-badge-new-text">{props.badgeText}</div>
                </div>
            </Space>
        </Popover>
    );
};

export default BadgeNew;
