import {
    AlertType,
    AssignTransportEventProps,
    CompanyEventProps,
    FuelCardEventProps,
    NoteEventProps,
    PageEventProps,
    PlaceEventProps,
    ShareEtaEventProps,
    TransportEventProps,
    UserEventProps,
    VehicleEventProps
} from 'logic/UserEvents';

export enum InitiatedFrom {
    DRIVER_INVITATION_COPY_BUTTON = 'driver_invitation_copy_button',
    DRIVER_INVITATION_WHATSAPP_BUTTON = 'driver_invitation_whatsapp_button',
    DRIVER_INVITATION_SEND_MAIL_BUTTON = 'driver_invitation_send_mail_button',
    TRACKING_ADD_VEHICLE_BUTTON = 'tracking_add_vehicle_button',
    TRACKING_BLANK_ADD_VEHICLE_BUTTON = 'tracking_blank_add_vehicle_button',
    PLANNER_VEHICLE_SELECT_ADD_VEHICLE_BUTTON = 'planner_vehicle_select_add_vehicle_button',
    PLANNER_MAP_RMB = 'planner_map_rmb',
    SETTINGS_FLEET_ADD_VEHICLE_BUTTON = 'settings_fleet_add_vehicle_button',
    SETTINGS_FUEL_CARDS_ADD_BUTTON = 'settings_fuelcards_add_card_button',
    SETTINGS_FAVORITES_MAP_RMB = 'settings_favorites_map_rmb',
    ADD_NEW_PLACE_ADD_FAVORITE_CHECKBOX = 'add_new_place_add_favorite_checkbox',
    DISPATCH_BOARD_TRANSPORT_DB_CLICK = 'dispatch_board_transport_db_click',
    DISPATCH_BOARD_TRANSPORT_CONTEXT_MENU = 'dispatch_board_transport_context_menu',
    DISPATCH_BOARD_ADD_VEHICLE_BUTTON = 'dispatch_board_add_vehicle_button',
    DISPATCH_BOARD_BLANK_ADD_VEHICLE_BUTTON = 'dispatch_board_blank_add_vehicle_button',
    DISPATCH_BOARD_TRANSPORT_CLICK = 'dispatch_board_transport_click',
    DISPATCH_BOARD_TABLE_ROW_CLICK = 'dispatch_board_table_row_click',
    DISPATCH_BOARD_CALENDAR_NAVBAR = 'dispatch_board_calendar_navbar',
    DISPATCH_BOARD_CALENDAR_TRANSPORT_NOTE = 'dispatch_board_calendar_transport_note',
    DISPATCH_BOARD_CALENDAR_DRAG_AND_DROP = 'dispatch_board_calendar_drag_and_drop',
    TRACKING_TABLE = 'tracking_table',
    TRACKING_DETAIL = 'tracking_detail',
    TRACKING_VEHICLE_MARKER = 'tracking_vehicle_marker',
    TRACKING_VEHICLES_LIST = 'tracking_vehicles_list',
    TRACKING_BLANK_LIST = 'tracking_blank_list',
    NOTIFICATION = 'notification'
}

export enum UserEvent {
    ROUTE_PLANNED = 'routePlanned',
    TRANSPORT_SAVED = 'transportSaved',
    TRANSPORT_UPDATED = 'transportUpdated',
    ADD_VEHICLE = 'addVehicle',
    ADD_FUEL_CARD = 'addFuelCard',
    ADD_NEW_PLACE = 'addNewPlace',
    ADD_FAVORITES = 'addFavorites',
    INVITE_DRIVER = 'inviteDriver',
    DETAIL_DRIVER = 'detailDriver',
    DETAIL_TRANSPORT = 'detailTransport',
    TRANSPORT_ASSIGNED_TO_VEHICLE = 'transportAssignedToVehicle',
    ALERT_MESSAGE = 'alertMessage',
    ERROR_MESSAGE = 'errorMessage',
    PAGE_READY = 'pageReady',
    USER_REGISTRATION = 'userRegistration',
    COMPANY_REGISTRAION = 'companyRegistration',
    USER_LOGIN = 'userLogin',
    SHARE_ETA_CREATE = 'shareEtaCreate',
    SHARE_ETA_STOP = 'shareEtaStop',
    NOTES_ACTIVATED = 'notesActivated',
    NOTES_DEACTIVATED = 'notesDeactivated',
    ADD_NOTE = 'addNote',
    CANCEL_NOTE = 'cancelNote',
    SAVE_NOTE = 'saveNote',
    ASSIGN_DRIVER = 'assignDriver',
    INVITE_DRIVER_SENT_BY = 'inviteDriverSentBy'
}

export class GoogleTagManager {
    constructor() {}

    pushEvent(pushObj?: any) {
        (window as any).dataLayer?.push({ ...pushObj });
    }

    transportSavedEvent(stateObj: TransportEventProps) {
        this.pushEvent({ event: UserEvent.TRANSPORT_SAVED, route: stateObj });
    }

    transportUpdatedEvent(stateObj: TransportEventProps) {
        this.pushEvent({ event: UserEvent.TRANSPORT_UPDATED, route: stateObj });
    }

    detailTransportEvent(transportId: string, vehicleId?: string, initiatedFrom?: InitiatedFrom) {
        this.pushEvent({
            event: UserEvent.DETAIL_TRANSPORT,
            transport: {
                id: transportId
            },
            vehicle: {
                id: vehicleId
            },
            initiatedFrom
        });
    }

    addVehicleEvent(vehicle: VehicleEventProps, initiatedFrom?: InitiatedFrom) {
        this.pushEvent({ event: UserEvent.ADD_VEHICLE, vehicle, initiatedFrom });
    }

    addFuelCardEvent(card: FuelCardEventProps, initiatedFrom?: InitiatedFrom) {
        this.pushEvent({ event: UserEvent.ADD_FUEL_CARD, card, initiatedFrom });
    }

    addNewPlaceEvent(place: PlaceEventProps, initiatedFrom?: InitiatedFrom) {
        this.pushEvent({ event: UserEvent.ADD_NEW_PLACE, place, initiatedFrom });
    }

    addFavoriteEvent(place: PlaceEventProps, initiatedFrom?: InitiatedFrom) {
        this.pushEvent({ event: UserEvent.ADD_FAVORITES, place, initiatedFrom });
    }

    detailDriverEvent(initiatedFrom?: InitiatedFrom) {
        this.pushEvent({
            event: UserEvent.DETAIL_DRIVER,
            initiatedFrom
        });
    }

    transportAssignedToVehicleEvent(assignTransport: AssignTransportEventProps, initiatedFrom?: InitiatedFrom) {
        this.pushEvent({ event: UserEvent.TRANSPORT_ASSIGNED_TO_VEHICLE, ...assignTransport, initiatedFrom });
    }

    alertMessageEvent(type: AlertType, message: string, alertObj?: any) {
        this.pushEvent({
            event: UserEvent.ALERT_MESSAGE,
            alert: {
                type: type,
                message: message
            },
            ...alertObj
        });
    }

    pageReadyEvent(user: UserEventProps, page: PageEventProps, company?: CompanyEventProps) {
        this.pushEvent({ user });
        this.pushEvent({ company });
        this.pushEvent({ page });
        this.pushEvent({ event: UserEvent.PAGE_READY });
    }

    registrationEvent(user: UserEventProps) {
        this.pushEvent({ event: UserEvent.USER_REGISTRATION, user });
    }

    loginEvent(user: UserEventProps) {
        this.pushEvent({ event: UserEvent.USER_LOGIN, user });
    }

    companyRegistrationEvent(user: UserEventProps) {
        this.pushEvent({ event: UserEvent.COMPANY_REGISTRAION, user });
    }

    shareEtaCreateEvent(data: ShareEtaEventProps, initiatedFrom: InitiatedFrom) {
        this.pushEvent({ event: UserEvent.SHARE_ETA_CREATE, initiatedFrom, ...data });
    }

    shareEtaStopEvent(data: ShareEtaEventProps, initiatedFrom: InitiatedFrom) {
        this.pushEvent({ event: UserEvent.SHARE_ETA_STOP, initiatedFrom, ...data });
    }

    noteEvent(event: UserEvent, initiatedFrom: InitiatedFrom, note: NoteEventProps) {
        this.pushEvent({ event, initiatedFrom, ...note });
    }
}
