/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InlineResponse20020,
    InlineResponse20020FromJSON,
    InlineResponse20020ToJSON,
    ReadOnlyMonitoringDeviceType,
    ReadOnlyMonitoringDeviceTypeFromJSON,
    ReadOnlyMonitoringDeviceTypeToJSON,
    WriteOnlyMonitoringDeviceType,
    WriteOnlyMonitoringDeviceTypeFromJSON,
    WriteOnlyMonitoringDeviceTypeToJSON,
} from '../models';

export interface MonitoringDeviceTypeCreateRequest {
    data: WriteOnlyMonitoringDeviceType;
}

export interface MonitoringDeviceTypeDeleteRequest {
    id: number;
}

export interface MonitoringDeviceTypeListRequest {
    limit?: number;
    offset?: number;
}

export interface MonitoringDeviceTypePartialUpdateRequest {
    id: number;
    data: WriteOnlyMonitoringDeviceType;
}

export interface MonitoringDeviceTypeReadRequest {
    id: number;
}

export interface MonitoringDeviceTypeUpdateRequest {
    id: number;
    data: WriteOnlyMonitoringDeviceType;
}

/**
 * no description
 */
export class MonitoringDeviceTypeApi extends runtime.BaseAPI {

    /**
     */
    async monitoringDeviceTypeCreateRaw(requestParameters: MonitoringDeviceTypeCreateRequest): Promise<runtime.ApiResponse<ReadOnlyMonitoringDeviceType>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling monitoringDeviceTypeCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/monitoring-device-type/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyMonitoringDeviceTypeToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyMonitoringDeviceTypeFromJSON(jsonValue));
    }

    /**
     */
    async monitoringDeviceTypeCreate(requestParameters: MonitoringDeviceTypeCreateRequest): Promise<ReadOnlyMonitoringDeviceType> {
        const response = await this.monitoringDeviceTypeCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async monitoringDeviceTypeDeleteRaw(requestParameters: MonitoringDeviceTypeDeleteRequest): Promise<runtime.ApiResponse<ReadOnlyMonitoringDeviceType>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling monitoringDeviceTypeDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/monitoring-device-type/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyMonitoringDeviceTypeFromJSON(jsonValue));
    }

    /**
     */
    async monitoringDeviceTypeDelete(requestParameters: MonitoringDeviceTypeDeleteRequest): Promise<ReadOnlyMonitoringDeviceType> {
        const response = await this.monitoringDeviceTypeDeleteRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async monitoringDeviceTypeListRaw(requestParameters: MonitoringDeviceTypeListRequest): Promise<runtime.ApiResponse<InlineResponse20020>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/monitoring-device-type/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20020FromJSON(jsonValue));
    }

    /**
     */
    async monitoringDeviceTypeList(requestParameters: MonitoringDeviceTypeListRequest): Promise<InlineResponse20020> {
        const response = await this.monitoringDeviceTypeListRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async monitoringDeviceTypePartialUpdateRaw(requestParameters: MonitoringDeviceTypePartialUpdateRequest): Promise<runtime.ApiResponse<ReadOnlyMonitoringDeviceType>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling monitoringDeviceTypePartialUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling monitoringDeviceTypePartialUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/monitoring-device-type/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyMonitoringDeviceTypeToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyMonitoringDeviceTypeFromJSON(jsonValue));
    }

    /**
     */
    async monitoringDeviceTypePartialUpdate(requestParameters: MonitoringDeviceTypePartialUpdateRequest): Promise<ReadOnlyMonitoringDeviceType> {
        const response = await this.monitoringDeviceTypePartialUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async monitoringDeviceTypeReadRaw(requestParameters: MonitoringDeviceTypeReadRequest): Promise<runtime.ApiResponse<ReadOnlyMonitoringDeviceType>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling monitoringDeviceTypeRead.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/monitoring-device-type/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyMonitoringDeviceTypeFromJSON(jsonValue));
    }

    /**
     */
    async monitoringDeviceTypeRead(requestParameters: MonitoringDeviceTypeReadRequest): Promise<ReadOnlyMonitoringDeviceType> {
        const response = await this.monitoringDeviceTypeReadRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async monitoringDeviceTypeUpdateRaw(requestParameters: MonitoringDeviceTypeUpdateRequest): Promise<runtime.ApiResponse<ReadOnlyMonitoringDeviceType>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling monitoringDeviceTypeUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling monitoringDeviceTypeUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/monitoring-device-type/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyMonitoringDeviceTypeToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyMonitoringDeviceTypeFromJSON(jsonValue));
    }

    /**
     */
    async monitoringDeviceTypeUpdate(requestParameters: MonitoringDeviceTypeUpdateRequest): Promise<ReadOnlyMonitoringDeviceType> {
        const response = await this.monitoringDeviceTypeUpdateRaw(requestParameters);
        return await response.value();
    }

}
