import { CompanyVehicle, VehicleType } from 'services/api/domains/VehiclesApi';
import { Collapse } from 'antd';
import { useTranslation } from 'react-i18next';
import { Button, Dropdown, Menu } from 'components/base/controls';

interface Props {
    data: CompanyVehicle[];
    loading?: boolean;
    onEditClick: (model: CompanyVehicle) => void;
    onRemoveClick: (vehicleId: number) => void;
    onAssignClick: (vehicleId: number) => void;
    onUnassignClick: (vehicleId: number, driverId: number) => void;
    onChangeClick: (vehicleId: number) => void;
    onShowOnMapClick: (vehicleId: number) => void;
}

export default function FleetList(props: Props) {
    const { t } = useTranslation();

    const DropdownMenu = ({ vehicle }: { vehicle: CompanyVehicle }) => (
        <Dropdown
            trigger={['click']}
            overlay={
                <Menu onClick={menuInfo => menuInfo.domEvent.stopPropagation()}>
                    <Menu.Item
                        key="0"
                        onClick={() => {
                            props.onEditClick(vehicle);
                        }}
                    >
                        {t('common.edit')}
                    </Menu.Item>
                    <Menu.Item
                        key="1"
                        onClick={() => {
                            props.onShowOnMapClick(vehicle.vehicleId!);
                        }}
                    >
                        {t('FleetList.showOnMap')}
                    </Menu.Item>
                    <Menu.Item
                        key="2"
                        onClick={() => {
                            props.onRemoveClick(vehicle.vehicleId!);
                        }}
                    >
                        {t('common.delete')}
                    </Menu.Item>
                </Menu>
            }
        >
            <Button type="icon" onClick={e => e.stopPropagation()}>
                <i className="rl-icon-more" />
            </Button>
        </Dropdown>
    );

    return (
        <Collapse
            className="rl-settings-fleet-list"
            expandIconPosition="end"
            expandIcon={({ isActive }) => <i className={`rl-icon-chevron-${isActive ? 'up' : 'down'}`} />}
        >
            {props.data.map((vehicle, index) => (
                <Collapse.Panel
                    extra={<DropdownMenu vehicle={vehicle} />}
                    header={vehicle.registrationNumber}
                    key={index}
                    className="rl-settings-fleet-list-item"
                >
                    <div className="rl-settings-fleet-list-item-wrapper">
                        <div className="rl-settings-fleet-list-item-wrapper-section">
                            <div className="rl-settings-fleet-list-item-wrapper-section-label">
                                {t('FleetList.name')}
                            </div>

                            <div className="rl-settings-fleet-list-item-wrapper-section-value">{vehicle.name}</div>
                        </div>

                        <div className="rl-settings-fleet-list-item-wrapper-section">
                            <div className="rl-settings-fleet-list-item-wrapper-section-label">
                                {t('FleetList.type')}
                            </div>

                            <div className="rl-settings-fleet-list-item-wrapper-section-value">
                                {t(`common.vehicleTypeOptions.${VehicleType[vehicle.vehicleType]}`)}
                            </div>
                        </div>

                        {/* <div className="rl-settings-fleet-list-item-wrapper-section">
                            <div className="rl-settings-fleet-list-item-wrapper-section-label">
                                {t('FleetList.trailerName')}
                            </div>
                            <div className="rl-settings-fleet-list-item-wrapper-section-value">trailerName</div>
                        </div>

                        <div className="rl-settings-fleet-list-item-wrapper-section">
                            <div className="rl-settings-fleet-list-item-wrapper-section-label">
                                {t('FleetList.trailerType')}
                            </div>
                            <div className="rl-settings-fleet-list-item-wrapper-section-value">trailerType</div>
                        </div> */}

                        <div className="rl-settings-fleet-list-item-wrapper-section">
                            <div className="rl-settings-fleet-list-item-wrapper-section-label">
                                {t('FleetList.driver')}
                            </div>

                            <div className="rl-settings-fleet-list-item-wrapper-section-value">
                                {vehicle.drivers?.[0]?.name}
                            </div>

                            <div>
                                <Button
                                    type="link"
                                    onClick={() => {
                                        props.onChangeClick(vehicle.vehicleId!);
                                    }}
                                >
                                    {t('FleetList.change')}
                                </Button>

                                <span className="rl-settings-fleet-list-item-wrapper-section-dot">•</span>

                                {vehicle.drivers?.[0]?.profileId ? (
                                    <Button
                                        type="link"
                                        onClick={() => {
                                            props.onUnassignClick(vehicle.vehicleId!, vehicle.drivers?.[0].profileId!);
                                        }}
                                    >
                                        {t('FleetList.unassign')}
                                    </Button>
                                ) : (
                                    <Button
                                        type="link"
                                        onClick={() => {
                                            props.onAssignClick(vehicle.vehicleId!);
                                        }}
                                    >
                                        {t('FleetList.assign')}
                                    </Button>
                                )}
                            </div>
                        </div>
                    </div>
                </Collapse.Panel>
            ))}
        </Collapse>
    );
}
