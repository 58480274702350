import mapboxgl from 'mapbox-gl';

export class PoiMapController {
    private _mapBox?: mapboxgl.Map;
    private _marker?: mapboxgl.Marker;

    private _addMarker?: (e: mapboxgl.MapMouseEvent) => void;
    private _onCreatePolygon?: (lat: number, lng: number) => void;

    constructor(_mapBox?: mapboxgl.Map) {
        this._mapBox = _mapBox;
    }

    destroy(): void {
        this._removePoiMarker();
        this._removePoiMarker();

        if (this._marker) {
            this.removeMarkerListener();
        }

        if (this._mapBox) {
            this._mapBox.getCanvas().style.cursor = '';
        }
    }

    renderPoiMarker(
        point: mapboxgl.LngLat,
        dragendHandler?: (lat: number, lng: number) => void,
        options?: {
            iconPath?: string;
            anchor?: mapboxgl.Anchor;
            offset?: [number, number];
            badgePath?: string;
        }
    ) {
        if (this._mapBox) {
            let el: HTMLDivElement | undefined;
            if (options?.iconPath) {
                el = document.createElement('div');
                const icon = document.createElement('img');
                icon.src = options.iconPath;
                el.appendChild(icon);

                if (options?.badgePath) {
                    const badgeIcon = document.createElement('img');
                    badgeIcon.src = options.badgePath;
                    badgeIcon.style.position = 'absolute';
                    badgeIcon.style.top = '0';
                    badgeIcon.style.right = '0';
                    el.appendChild(badgeIcon);
                }
            }

            const marker = new mapboxgl.Marker(el, {
                anchor: options?.anchor,
                draggable: !!dragendHandler,
                offset: options?.offset
            })
                .setLngLat(point)
                .addTo(this._mapBox);

            marker?.on('dragend', () => {
                const lngLat = marker.getLngLat();

                if (dragendHandler) {
                    dragendHandler(lngLat.lat, lngLat.lng);
                } else {
                    this._onCreatePolygon?.(lngLat.lat, lngLat.lng);
                }
            });

            this._marker = marker;
        }
    }

    removeMarkerListener() {
        this._marker?.off('dragend');
        if (this._mapBox) {
            this._addMarker && this._mapBox.off('click', this._addMarker);
        }
    }

    private _removePoiMarker() {
        this.removeMarkerListener();
        this._marker?.remove();
        this._marker = undefined;
    }

    getCoordsWithCrosshair(cb: (lat: number, lng: number) => void) {
        this._onCreatePolygon = cb;
        if (this._mapBox) {
            this._mapBox.getCanvas().style.cursor = 'crosshair';

            this._addMarker = (e: mapboxgl.MapMouseEvent) => {
                this._onCreatePolygon?.(e.lngLat.lat, e.lngLat.lng);
                if (this._mapBox) {
                    this._mapBox.getCanvas().style.cursor = '';
                }
            };

            this._mapBox.once('click', this._addMarker);
        }
    }
}
