/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TripAddressStructured,
    TripAddressStructuredFromJSON,
    TripAddressStructuredFromJSONTyped,
    TripAddressStructuredToJSON,
} from '.';

/**
 * 
 * @export
 * @interface TripPlace
 */
export interface TripPlace {
    /**
     * 
     * @type {string}
     * @memberof TripPlace
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof TripPlace
     */
    lat: number;
    /**
     * 
     * @type {number}
     * @memberof TripPlace
     */
    lon: number;
    /**
     * 
     * @type {Array<TripAddressStructured>}
     * @memberof TripPlace
     */
    addressStructured?: Array<TripAddressStructured>;
}

export function TripPlaceFromJSON(json: any): TripPlace {
    return TripPlaceFromJSONTyped(json, false);
}

export function TripPlaceFromJSONTyped(json: any, ignoreDiscriminator: boolean): TripPlace {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'lat': json['lat'],
        'lon': json['lon'],
        'addressStructured': !exists(json, 'addressStructured') ? undefined : ((json['addressStructured'] as Array<any>).map(TripAddressStructuredFromJSON)),
    };
}

export function TripPlaceToJSON(value?: TripPlace | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'lat': value.lat,
        'lon': value.lon,
        'addressStructured': value.addressStructured === undefined ? undefined : ((value.addressStructured as Array<any>).map(TripAddressStructuredToJSON)),
    };
}


