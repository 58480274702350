import { Form } from 'antd';
import { Button, Select } from 'components/base/controls';
import { useTranslation } from 'react-i18next';
import Input from 'components/base/controls/Input/Input';
import { BRAND_ID_TO_ICON } from 'utils/constants/fuelcard-icons';
import { FuelCardsBrandsModel } from 'services/api/domains/FuelCardsApi';
import { FuelCardPutModel } from '../../../../../../services/api/domains/CompanyApi';
import { useEffect } from 'react';
import selectors from 'qa-selectors';

export interface FuelCardFormModel extends FuelCardPutModel {}

export interface Props {
    brands?: FuelCardsBrandsModel[];
    onCreate: (fuelCard: FuelCardFormModel) => void;
    onEdit: (fuelCard: FuelCardFormModel) => void;
    initValues?: FuelCardFormModel;
    isLoading?: boolean;
}

export default function FuelCardsAddEditForm(props: Props) {
    const { t } = useTranslation();
    const [form] = Form.useForm<FuelCardFormModel>();

    useEffect(() => {
        form.resetFields();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.initValues]);

    const onFinish = (values: FuelCardFormModel) => {
        if (props.initValues) {
            props.onEdit(values);
        } else {
            props.onCreate(values);
        }
    };

    return (
        <div className="rl-fuel-cards-form">
            <div className="rl-fuel-cards-form-content">
                <Form
                    id="FuelCardsAddEditForm"
                    layout="vertical"
                    form={form}
                    onFinish={onFinish}
                    initialValues={props.initValues}
                >
                    <Form.Item label={t('FuelCardsAddEditForm.brand')} name="brandId" rules={[{ required: true }]}>
                        <Select
                            qa={selectors.fuelCards.addFuelCardModal.cardBrandSelect}
                            getPopupContainer={triggerNode => triggerNode.parentElement}
                        >
                            {props.brands?.map(brand => (
                                <Select.Option key={brand.brandId} value={brand.brandId}>
                                    <img
                                        src={`/img/fuel-card-brands/${
                                            BRAND_ID_TO_ICON[brand.brandId] ?? 'default-icon.svg'
                                        }`}
                                        alt="Brand icon"
                                    />
                                    {brand.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label={`${t('FuelCardsAddEditForm.discount')} [%]`}
                        name="discount"
                        rules={[{ required: true }]}
                    >
                        <Input.Number data-qa={selectors.fuelCards.addFuelCardModal.discountInput} />
                    </Form.Item>

                    <Form.Item label={t('FuelCardsAddEditForm.note')} name="description">
                        <Input.TextArea rows={3} data-qa={selectors.fuelCards.addFuelCardModal.noteInput} />
                    </Form.Item>
                </Form>
            </div>
            <div className="rl-fuel-cards-form-actions">
                <Button
                    type="primary"
                    htmlType="submit"
                    form="FuelCardsAddEditForm"
                    loading={props.isLoading ? { delay: 500 } : false}
                    data-qa={selectors.fuelCards.addFuelCardModal.saveButton}
                >
                    {props.initValues ? t('common.saveChanges') : t('common.save')}
                </Button>
            </div>
        </div>
    );
}
