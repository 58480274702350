/* tslint:disable */
/* eslint-disable */
/**
 * Telematics API
 * Telematics services API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PushymeRes
 */
export interface PushymeRes {
    /**
     * 
     * @type {string}
     * @memberof PushymeRes
     */
    id: string;
    /**
     * 
     * @type {boolean}
     * @memberof PushymeRes
     */
    success: boolean;
    /**
     * 
     * @type {string}
     * @memberof PushymeRes
     */
    error?: string | null;
    /**
     * 
     * @type {{ [key: string]: { [key: string]: object; }; }}
     * @memberof PushymeRes
     */
    info: { [key: string]: { [key: string]: object; }; };
}

export function PushymeResFromJSON(json: any): PushymeRes {
    return PushymeResFromJSONTyped(json, false);
}

export function PushymeResFromJSONTyped(json: any, ignoreDiscriminator: boolean): PushymeRes {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'success': json['success'],
        'error': !exists(json, 'error') ? undefined : json['error'],
        'info': json['info'],
    };
}

export function PushymeResToJSON(value?: PushymeRes | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'success': value.success,
        'error': value.error,
        'info': value.info,
    };
}


