/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WriteOnlyOrderSerializer
 */
export interface WriteOnlyOrderSerializer {
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyOrderSerializer
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyOrderSerializer
     */
    priceList: string;
    /**
     * 
     * @type {number}
     * @memberof WriteOnlyOrderSerializer
     */
    monitoringDevice: number;
}

export function WriteOnlyOrderSerializerFromJSON(json: any): WriteOnlyOrderSerializer {
    return WriteOnlyOrderSerializerFromJSONTyped(json, false);
}

export function WriteOnlyOrderSerializerFromJSONTyped(json: any, ignoreDiscriminator: boolean): WriteOnlyOrderSerializer {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'priceList': json['price_list'],
        'monitoringDevice': json['monitoring_device'],
    };
}

export function WriteOnlyOrderSerializerToJSON(value?: WriteOnlyOrderSerializer | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'price_list': value.priceList,
        'monitoring_device': value.monitoringDevice,
    };
}


