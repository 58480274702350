/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserToken
 */
export interface UserToken {
    /**
     * 
     * @type {number}
     * @memberof UserToken
     */
    readonly id?: number;
    /**
     * 
     * @type {string}
     * @memberof UserToken
     */
    token: string;
    /**
     * 
     * @type {string}
     * @memberof UserToken
     */
    tokenType: string;
    /**
     * 
     * @type {Date}
     * @memberof UserToken
     */
    startTime: Date;
    /**
     * 
     * @type {Date}
     * @memberof UserToken
     */
    endTime?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof UserToken
     */
    deleted: number;
    /**
     * 
     * @type {string}
     * @memberof UserToken
     */
    holder: string;
    /**
     * 
     * @type {number}
     * @memberof UserToken
     */
    user?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UserToken
     */
    client?: number | null;
}

export function UserTokenFromJSON(json: any): UserToken {
    return UserTokenFromJSONTyped(json, false);
}

export function UserTokenFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserToken {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'token': json['token'],
        'tokenType': json['token_type'],
        'startTime': (new Date(json['start_time'])),
        'endTime': !exists(json, 'end_time') ? undefined : (json['end_time'] === null ? null : new Date(json['end_time'])),
        'deleted': json['deleted'],
        'holder': json['holder'],
        'user': !exists(json, 'user') ? undefined : json['user'],
        'client': !exists(json, 'client') ? undefined : json['client'],
    };
}

export function UserTokenToJSON(value?: UserToken | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'token': value.token,
        'token_type': value.tokenType,
        'start_time': (value.startTime.toISOString()),
        'end_time': value.endTime === undefined ? undefined : (value.endTime === null ? null : value.endTime.toISOString()),
        'deleted': value.deleted,
        'holder': value.holder,
        'user': value.user,
        'client': value.client,
    };
}


