import { createContext, useContext } from 'react';
import { configure } from 'mobx';
import { Conf, confDefault } from 'conf';
import { ApiService, apiService } from 'services/api/ApiService';
import { AuthStore, authStore } from './auth/AuthStore';
import { UserSettingsStore } from './UserSettingsStore';
import { DispatcherStore } from './DispatcherStore';
import { FavoritesStore } from './FavoritesStore';
import { AppStore } from './AppStore';
import { CONF } from 'config/conf';

const conf: Conf = { ...confDefault, ...(CONF as any) };

configure({ enforceActions: 'observed' });

export enum Stores {
    ROOT_STORE = 'rootStore',
    APP_STORE = 'appStore',
    AUTH_STORE = 'authStore',
    USER_SETTINGS_STORE = 'userSettingsStore',
    DISPATCHER_STORE = 'dispatcherStore',
    FAVORITES_STORE = 'favoritesStore'
}

export class RootStore {
    appStore: AppStore;
    auth: AuthStore;
    userSettings: UserSettingsStore;
    dispatcherStore: DispatcherStore;
    favoritesStore: FavoritesStore;

    constructor(authStore: AuthStore, apiService: ApiService) {
        this.auth = authStore;
        this.appStore = new AppStore(this);
        this.userSettings = new UserSettingsStore(this, conf, apiService);
        this.dispatcherStore = new DispatcherStore(this, apiService);
        this.favoritesStore = new FavoritesStore(this, apiService);
    }

    getStores() {
        return {
            [Stores.ROOT_STORE]: this,
            [Stores.APP_STORE]: this.appStore,
            [Stores.AUTH_STORE]: this.auth,
            [Stores.USER_SETTINGS_STORE]: this.userSettings,
            [Stores.DISPATCHER_STORE]: this.dispatcherStore,
            [Stores.FAVORITES_STORE]: this.favoritesStore
        };
    }
}

const store = new RootStore(authStore, apiService);

const StoreContext = createContext(store.getStores());

const useStore = () => useContext(StoreContext);

export { store, StoreContext, useStore };
