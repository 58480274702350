/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InlineResponse20015,
    InlineResponse20015FromJSON,
    InlineResponse20015ToJSON,
    ReadOnlyMonitoredObjectGroup,
    ReadOnlyMonitoredObjectGroupFromJSON,
    ReadOnlyMonitoredObjectGroupToJSON,
    WriteOnlyMonitoredObjectGroup,
    WriteOnlyMonitoredObjectGroupFromJSON,
    WriteOnlyMonitoredObjectGroupToJSON,
} from '../models';

export interface MonitoredObjectGroupCreateRequest {
    data: WriteOnlyMonitoredObjectGroup;
}

export interface MonitoredObjectGroupDeleteRequest {
    id: number;
}

export interface MonitoredObjectGroupListRequest {
    nameIcontains?: string;
    nameIexact?: string;
    limit?: number;
    offset?: number;
}

export interface MonitoredObjectGroupPartialUpdateRequest {
    id: number;
    data: WriteOnlyMonitoredObjectGroup;
}

export interface MonitoredObjectGroupReadRequest {
    id: number;
}

export interface MonitoredObjectGroupUpdateRequest {
    id: number;
    data: WriteOnlyMonitoredObjectGroup;
}

/**
 * no description
 */
export class MonitoredObjectGroupApi extends runtime.BaseAPI {

    /**
     */
    async monitoredObjectGroupCreateRaw(requestParameters: MonitoredObjectGroupCreateRequest): Promise<runtime.ApiResponse<ReadOnlyMonitoredObjectGroup>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling monitoredObjectGroupCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/monitored-object-group/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyMonitoredObjectGroupToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyMonitoredObjectGroupFromJSON(jsonValue));
    }

    /**
     */
    async monitoredObjectGroupCreate(requestParameters: MonitoredObjectGroupCreateRequest): Promise<ReadOnlyMonitoredObjectGroup> {
        const response = await this.monitoredObjectGroupCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async monitoredObjectGroupDeleteRaw(requestParameters: MonitoredObjectGroupDeleteRequest): Promise<runtime.ApiResponse<ReadOnlyMonitoredObjectGroup>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling monitoredObjectGroupDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/monitored-object-group/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyMonitoredObjectGroupFromJSON(jsonValue));
    }

    /**
     */
    async monitoredObjectGroupDelete(requestParameters: MonitoredObjectGroupDeleteRequest): Promise<ReadOnlyMonitoredObjectGroup> {
        const response = await this.monitoredObjectGroupDeleteRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async monitoredObjectGroupListRaw(requestParameters: MonitoredObjectGroupListRequest): Promise<runtime.ApiResponse<InlineResponse20015>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.nameIcontains !== undefined) {
            queryParameters['name__icontains'] = requestParameters.nameIcontains;
        }

        if (requestParameters.nameIexact !== undefined) {
            queryParameters['name__iexact'] = requestParameters.nameIexact;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/monitored-object-group/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20015FromJSON(jsonValue));
    }

    /**
     */
    async monitoredObjectGroupList(requestParameters: MonitoredObjectGroupListRequest): Promise<InlineResponse20015> {
        const response = await this.monitoredObjectGroupListRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async monitoredObjectGroupPartialUpdateRaw(requestParameters: MonitoredObjectGroupPartialUpdateRequest): Promise<runtime.ApiResponse<ReadOnlyMonitoredObjectGroup>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling monitoredObjectGroupPartialUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling monitoredObjectGroupPartialUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/monitored-object-group/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyMonitoredObjectGroupToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyMonitoredObjectGroupFromJSON(jsonValue));
    }

    /**
     */
    async monitoredObjectGroupPartialUpdate(requestParameters: MonitoredObjectGroupPartialUpdateRequest): Promise<ReadOnlyMonitoredObjectGroup> {
        const response = await this.monitoredObjectGroupPartialUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async monitoredObjectGroupReadRaw(requestParameters: MonitoredObjectGroupReadRequest): Promise<runtime.ApiResponse<ReadOnlyMonitoredObjectGroup>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling monitoredObjectGroupRead.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/monitored-object-group/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyMonitoredObjectGroupFromJSON(jsonValue));
    }

    /**
     */
    async monitoredObjectGroupRead(requestParameters: MonitoredObjectGroupReadRequest): Promise<ReadOnlyMonitoredObjectGroup> {
        const response = await this.monitoredObjectGroupReadRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async monitoredObjectGroupUpdateRaw(requestParameters: MonitoredObjectGroupUpdateRequest): Promise<runtime.ApiResponse<ReadOnlyMonitoredObjectGroup>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling monitoredObjectGroupUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling monitoredObjectGroupUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/monitored-object-group/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyMonitoredObjectGroupToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyMonitoredObjectGroupFromJSON(jsonValue));
    }

    /**
     */
    async monitoredObjectGroupUpdate(requestParameters: MonitoredObjectGroupUpdateRequest): Promise<ReadOnlyMonitoredObjectGroup> {
        const response = await this.monitoredObjectGroupUpdateRaw(requestParameters);
        return await response.value();
    }

}
