import { RouteNames } from 'router/routes';
import { DATE_FORMAT } from 'utils/constants/domain-constants';
import { Driver } from 'services/api/domains/CompanyApi';
import { Logic } from 'logic/logic';
import moment, { Moment } from 'moment';
import qs from 'qs';
import { Component } from 'react';
import { DropResult } from '@hello-pangea/dnd';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router';
import { VehicleCreateEditModule } from 'modules/VehicleCreateEditModule';
import AssignDriverModule from 'modules/AssignDriverModule/AssignDriverModule';
import { TOLL_COST_DEFAULT_VALUE } from 'logic/scheduling-route-planner';
import { Modal, Notification } from 'components/base/layout';
import { UserEvent, InitiatedFrom } from 'logic/userEventsProviders/GoogleTagManager';
import { CompanyVehicle, VehicleStateObject } from 'services/api/domains/VehiclesApi';
import AssignVehicleModule from 'modules/AssignVehicleModule/AssignVehicleModule';
import CalendarView from './ui/CalendarView/CalendarView';
import { CompanyTransportStatus, TransportState } from 'services/api/domains/TransportsApi';
import { inject, observer } from 'mobx-react';
import { Stores, UserSettingsStore } from 'stores';
import TransportDetailSidebar from '../common/TransportDetailSidebar/TransportDetailSidebar';
import { TransportModel } from 'logic/dispatcher-board';
import { DaysSegmentType } from '../common/types/DaySegment';
import { isMobileDevice } from 'utils/helpers/navigator';
import { CalendarViewMobile } from './ui/CalendarViewMobile';
import { CalendarViewBlank } from './ui/CalendarViewBlank/CalendarViewBlank';
import { Country } from 'services/api/domains/CountryApi';

interface RouteParams {
    startDate: string;
    endDate: string;
    selected: string;
    dispatcherBoard: string;
    expanded?: string;
    eta?: string;
    notes?: string;
    break?: string;
    redirectedFrom?: 'calendar' | 'table';
    daySegment?: string;
}

export interface CalendarViewBar {
    eta: boolean;
    notes: boolean;
    expanded: boolean;
    daySegment: number;
}

export enum CalendarViewDragType {
    Vehicles = 'vehicles',
    Transports = 'transports'
}

interface Props extends WithTranslation, RouteComponentProps<RouteParams> {
    logic: Logic;
    userSettingsStore?: UserSettingsStore;
}

interface State {
    bar: CalendarViewBar;
    container: {
        daysBefore: number;
        daysAfter: number;
        startDate: string;
        data: {
            rows: CompanyVehicle[];
            vehicles: VehicleStateObject[];
            newTransports: TransportModel[];
            otherTransports: TransportModel[];
            drivers?: Driver[];
        };
        loading: boolean;
        processing: boolean;
        selectedVehicle: VehicleStateObject | null;
    };
    createVehicleSidebar: {
        visible: boolean;
        initiatedFrom?: InitiatedFrom;
    };
    assignDriverModal?: {
        visible?: boolean;
        vehicleId?: number;
    };
    assignVehicleModal: { visible: boolean; selectedTransport?: TransportModel };
    transportDetailSidebar: {
        visible: boolean;
        transport?: TransportModel;
        vehicle?: CompanyVehicle;
    };
    firstTransportPopoverVisible: boolean;
    countryList: Country[];
}

@inject(Stores.USER_SETTINGS_STORE)
@observer
class CalendarViewModule extends Component<Props, State> {
    private logic: Logic;

    constructor(props: Props) {
        super(props);
        this.logic = this.props.logic;
        const settings = this.logic.dispatcherBoard().settings();

        this.state = {
            bar: {
                eta: settings.eta,
                notes: settings.notes,
                expanded: settings.expanded,
                daySegment: settings.daySegment
            },
            container: {
                startDate: moment().startOf('day').toISOString(),
                daysBefore: 0,
                daysAfter: 4,
                data: {
                    rows: [],
                    vehicles: [],
                    newTransports: [],
                    otherTransports: []
                },
                loading: true,
                processing: false,
                selectedVehicle: null
            },
            createVehicleSidebar: {
                visible: false
            },
            assignDriverModal: {
                visible: false
            },
            assignVehicleModal: {
                visible: false
            },
            transportDetailSidebar: {
                visible: false
            },
            firstTransportPopoverVisible: false,
            countryList: []
        };
    }

    async componentDidMount() {
        const params = qs.parse(this.props.history.location.search, {
            ignoreQueryPrefix: true
        }) as unknown as RouteParams;

        this.setState(state => ({
            container: {
                ...state.container,
                loading: true
            }
        }));

        const settings = this.logic.dispatcherBoard().settings();
        const daySegment = isMobileDevice()
            ? DaysSegmentType.day1
            : params.daySegment
            ? +params.daySegment
            : settings.daySegment;

        this.setState(state => ({
            bar: {
                ...state.bar,
                eta: params.eta ? params.eta === 'true' : settings.eta,
                notes: params.notes ? params.notes === 'true' : settings.notes,
                expanded: params.expanded ? params.expanded === 'true' : settings.expanded,
                daySegment: daySegment
            },
            container: {
                ...state.container,
                ...this._setDaysBySegment(daySegment, params.startDate)
            }
        }));

        this.logic.dispatcherBoard().init();

        this.logic.dispatcherBoard().onTransportsChange(async data => {
            this._updateTransportSidebar();

            if (settings.firstTransportPopoverVisible && !isMobileDevice()) {
                const transportsCount = await this.logic.dispatcherBoard().getTransportsCount();
                if (transportsCount > 1) {
                    this.setState(
                        {
                            firstTransportPopoverVisible: false
                        },
                        () => this.logic.dispatcherBoard().setSettings({ firstTransportPopoverVisible: false })
                    );
                } else {
                    this.setState({
                        firstTransportPopoverVisible: transportsCount === 1
                    });
                }
            }

            this.setState(state => ({
                container: {
                    ...state.container,
                    data: {
                        ...state.container.data,
                        newTransports: data.newTransports,
                        otherTransports: data.otherTransports
                    },
                    processing: false
                }
            }));
        });

        this.logic.dispatcherBoard().onVehiclesChange(result => {
            const vehicles = [...result];

            this.setState(state => ({
                container: {
                    ...state.container,
                    data: {
                        ...state.container.data,
                        rows: this._getOrderedVehicles(vehicles)
                    }
                }
            }));

            this._updateTransportSidebar();
        });

        const vehiclesState = await this.logic.vehiclesState().data();
        const transports = await this._fetchTransports();

        this.setState(state => ({
            bar: {
                ...state.bar,
                newTransportsCount: transports.newTransports.length
            },
            container: {
                ...state.container,
                data: {
                    ...state.container.data,
                    vehicles: vehiclesState,
                    newTransports: transports.newTransports,
                    otherTransports: transports.otherTransports
                },
                loading: false
            }
        }));

        this.props.logic
            .enums()
            .countryList()
            .then(countryList => this.setState({ countryList }));
    }

    componentWillUnmount() {
        (window as any).app.DispatcherBoardModule = undefined;
        this.logic.dispatcherBoard().destroy();
    }

    render() {
        return (
            <>
                <VehicleCreateEditModule
                    logic={this.logic}
                    visible={this.state.createVehicleSidebar.visible}
                    type={'CREATE'}
                    onCancel={this._cancelCreateVehicleModal}
                    afterSubmit={() => {
                        this._cancelCreateVehicleModal();
                        this._updateVehicles();
                    }}
                    afterCreate={this._afterCreateVehicle}
                />

                <AssignDriverModule
                    visible={!!this.state.assignDriverModal?.visible}
                    logic={this.props.logic}
                    vehicleId={this.state.assignDriverModal?.vehicleId!}
                    onCancel={this._closeAssignDriverModal}
                    afterAssign={() => {
                        this._closeAssignDriverModal();
                        this._updateVehicles();
                    }}
                />

                <AssignVehicleModule
                    logic={this.props.logic}
                    visible={this.state.assignVehicleModal.visible}
                    assigningToTransport={true}
                    onCancel={() => {
                        this.setState({ assignVehicleModal: { visible: false } });
                    }}
                    onConfirm={this._onAssignVehicleToTransport}
                />

                {this.state.transportDetailSidebar.transport && (
                    <TransportDetailSidebar
                        transport={this.state.transportDetailSidebar.transport}
                        vehicle={this.state.transportDetailSidebar.vehicle}
                        visible={this.state.transportDetailSidebar.visible}
                        onClose={this._onCloseTransportDetail}
                        onEditTransport={this._onTransportEditClick}
                        onLocalizeVehicle={this._onLocalizeVehicle}
                        onDeleteTransport={this._onOpenDeleteTransportModal}
                    />
                )}

                {isMobileDevice() ? (
                    <>
                        {this.state.container.data.rows.length || this.state.container.loading ? (
                            <CalendarViewMobile
                                transports={this._filterTransports()}
                                vehicles={this.state.container.data.vehicles}
                                countryList={this.state.countryList}
                                filter={{
                                    date: this.state.container.startDate,
                                    selectedVehicle: this.state.container.selectedVehicle
                                }}
                                isLoading={this.state.container.loading || this.state.container.processing}
                                onFilterChange={this._onMobileFilterChanged}
                                onTransportClick={transport => this._onTransportDetailClick(transport)}
                                onAssignDriverClick={vehicleId => this._openAssignDriverModal(Number(vehicleId))}
                            />
                        ) : (
                            <CalendarViewBlank onAddVehicle={this._showCreateVehicleModal} />
                        )}
                    </>
                ) : (
                    <CalendarView
                        logic={this.props.logic}
                        bar={this.state.bar}
                        container={this.state.container}
                        transportDetailId={this.state.transportDetailSidebar.transport?.id}
                        firstTransportPopoverVisible={this.state.firstTransportPopoverVisible}
                        countryList={this.state.countryList}
                        onBarEtaClick={this._onBarEtaClick}
                        onBarNotesClick={this._onBarNotesClick}
                        onBarExpandClick={this._onBarExpandClick}
                        onDragTransportEnd={this._onDragTransportEnd}
                        onStartDateChange={this._onStartDateChange}
                        onTransportNoteChange={this._onTransportNoteChange}
                        onAssignDriverToVehicle={vehicle => this._openAssignDriverModal(vehicle.vehicleId)}
                        onShowCreateVehicleModal={this._showCreateVehicleModal}
                        onOpenDeleteTransportModal={this._onOpenDeleteTransportModal}
                        onUnassignTransport={this._onUnassignTransport}
                        onOpenAssignVehicleModal={this._onOpenAssignVehicleModal}
                        onTransportEditClick={this._onTransportEditClick}
                        onTransportDetailClick={this._onTransportDetailClick}
                        onDaysSegmentClick={this._onDaysSegmentChange}
                        onLocalizeVehicle={this._onLocalizeVehicle}
                        onFirstTransportPopoverClose={this._onFirstTransportPopoverClose}
                    />
                )}
            </>
        );
    }

    private _closeAssignDriverModal = () => {
        this.setState({ assignDriverModal: undefined });
    };

    private _openAssignDriverModal = (vehicleId?: number) => {
        this.setState({
            assignDriverModal: {
                visible: true,
                vehicleId
            }
        });
    };

    private _onBarExpandClick = (): void => {
        const params = qs.parse(this.props.location.search, {
            ignoreQueryPrefix: true,
            plainObjects: true
        });
        this.props.history.push({
            search: qs.stringify({
                ...params,
                expanded: String(!this.state.bar.expanded)
            } as RouteParams)
        });
        this.setState(
            state => ({
                bar: {
                    ...state.bar,
                    expanded: !state.bar.expanded
                }
            }),
            () => this.logic.dispatcherBoard().setSettings({ expanded: this.state.bar.expanded })
        );
    };

    private _onBarNotesClick = (): void => {
        const params = qs.parse(this.props.location.search, {
            ignoreQueryPrefix: true,
            plainObjects: true
        });
        this.props.history.push({
            search: qs.stringify({
                ...params,
                notes: String(!this.state.bar.notes)
            } as RouteParams)
        });

        this.setState(
            state => ({
                bar: {
                    ...state.bar,
                    notes: !state.bar.notes
                }
            }),
            () => {
                const notesActivated = this.state.bar.notes;
                this.logic
                    .userEvents()
                    .note(
                        notesActivated ? UserEvent.NOTES_ACTIVATED : UserEvent.NOTES_DEACTIVATED,
                        InitiatedFrom.DISPATCH_BOARD_CALENDAR_NAVBAR
                    );
                this.logic.dispatcherBoard().setSettings({ notes: notesActivated });
            }
        );
    };

    private _onBarEtaClick = (): void => {
        const params = qs.parse(this.props.location.search, {
            ignoreQueryPrefix: true,
            plainObjects: true
        });
        this.props.history.push({
            search: qs.stringify({
                ...params,
                eta: String(!this.state.bar.eta)
            } as RouteParams)
        });
        this.setState(
            state => ({
                bar: {
                    ...state.bar,
                    eta: !state.bar.eta
                }
            }),
            () => this.logic.dispatcherBoard().setSettings({ eta: this.state.bar.eta })
        );
    };

    private _onStartDateChange = (startDate: Moment): void => {
        const params = qs.parse(this.props.location.search, {
            ignoreQueryPrefix: true,
            plainObjects: true
        });

        this.props.history.push({
            search: qs.stringify({
                ...params,
                startDate: startDate.format(DATE_FORMAT)
            } as RouteParams)
        });

        this.logic.dispatcherBoard().setSettings({ startDate });

        this.setState(
            state => ({
                container: {
                    ...state.container,
                    date: false,
                    startDate: startDate.toISOString(),
                    processing: true
                }
            }),
            () => {
                this._fetchTransports();
            }
        );
    };

    private _onTransportNoteChange = async (transportId: string, note: string, cb: VoidFunction): Promise<void> => {
        try {
            const companyId = this.logic.company().getCompany().companyId;
            this.logic
                .apiService()
                .transports()
                .patchTransport(companyId, +transportId, {
                    note
                })
                .then(() => {
                    cb();

                    this._fetchTransports();

                    this.logic
                        .userEvents()
                        .note(UserEvent.SAVE_NOTE, InitiatedFrom.DISPATCH_BOARD_CALENDAR_TRANSPORT_NOTE, note);

                    Notification.success({
                        message: this.props.t('common.updated'),
                        description: this.props.t('Planner.updateSuccess')
                    });
                });
        } catch (err) {
            cb();
            console.error(err);
            Notification.error({
                message: err.message
            });
        }
    };

    private _onDragVehicleItemEnd = (result: DropResult) => {
        const vehicles = [...this.state.container.data.rows];
        const vehicle = vehicles?.[result.source.index];

        if (vehicle && result.destination) {
            vehicles.splice(result.source.index, 1);
            vehicles.splice(result.destination.index, 0, vehicle);

            this.props.userSettingsStore?.setCalendarVehicleOrder(vehicles.map(v => v.vehicleId!));
        }

        this.setState(state => ({
            container: {
                ...state.container,
                data: {
                    ...state.container.data,
                    rows: vehicles
                }
            }
        }));
    };

    private _onDragTransportItemEnd = async (result: DropResult) => {
        const transportId = result.draggableId;
        const destinationVehicleId = result.destination?.droppableId;
        const sourceVehicleId = result.source?.droppableId;

        if (!destinationVehicleId || destinationVehicleId === sourceVehicleId) {
            return;
        }

        if (destinationVehicleId && transportId && sourceVehicleId === 'unassigned-transports') {
            try {
                this.setState(state => ({
                    container: {
                        ...state.container,
                        data: {
                            ...state.container.data,
                            newTransports: state.container.data.newTransports.filter(
                                transport => transport.id !== transportId
                            ),
                            otherTransports: [
                                ...state.container.data.otherTransports,
                                {
                                    ...state.container.data.newTransports.filter(
                                        transport => transport.id === transportId
                                    )[0],
                                    vehicleId: destinationVehicleId,
                                    state: TransportState.Assigned
                                }
                            ]
                        },
                        processing: true
                    }
                }));

                this.logic
                    .dispatcherBoard()
                    .addVehicleToTransport(destinationVehicleId, transportId, TransportState.Assigned)
                    .then(() => {
                        this.props.logic
                            .userEvents()
                            .assignTransport(
                                transportId!,
                                destinationVehicleId!,
                                InitiatedFrom.DISPATCH_BOARD_CALENDAR_DRAG_AND_DROP
                            );

                        this._updateTransportSidebar();
                        this.setState(
                            state => ({
                                container: {
                                    ...state.container,
                                    processing: false
                                }
                            }),
                            () => {
                                Notification.success({
                                    message: this.props.t('common.updated'),
                                    description: this.props.t('Planner.updateSuccess')
                                });
                            }
                        );
                    });
            } catch (err) {
                console.log(err);
                Notification.error({
                    message: err.message,
                    placement: 'bottomRight'
                });
            }

            return;
        }

        if (
            destinationVehicleId !== 'unassigned-transports' &&
            transportId &&
            sourceVehicleId !== 'unassigned-transports'
        ) {
            try {
                this.setState(state => ({
                    container: {
                        ...state.container,
                        data: {
                            ...state.container.data,
                            otherTransports: this.state.container.data.otherTransports?.map(transport => {
                                if (transport.id === transportId) {
                                    transport.vehicleId = destinationVehicleId;
                                }

                                return transport;
                            })
                        },
                        processing: true
                    }
                }));

                const transport = this.state.container.data.otherTransports?.find(
                    transport => transport.id === transportId
                );

                await this.logic
                    .dispatcherBoard()
                    .addVehicleToTransport(destinationVehicleId!, transportId, transport?.state!)
                    .then(() => {
                        this.props.logic
                            .userEvents()
                            .assignTransport(
                                transportId!,
                                destinationVehicleId!,
                                InitiatedFrom.DISPATCH_BOARD_CALENDAR_DRAG_AND_DROP
                            );
                        this._updateTransportSidebar();
                        this.setState(
                            state => ({
                                container: {
                                    ...state.container,
                                    processing: false
                                }
                            }),
                            () => {
                                Notification.success({
                                    message: this.props.t('common.updated'),
                                    description: this.props.t('Planner.updateSuccess')
                                });
                            }
                        );
                    });
            } catch (err) {
                console.log(err);
                Notification.error({
                    message: err.message
                });
            }

            return;
        }

        if (
            destinationVehicleId === 'unassigned-transports' &&
            transportId &&
            sourceVehicleId !== 'unassigned-transports'
        ) {
            this._onUnassignTransport(transportId);
        }
    };

    private _onDragTransportEnd = async (result: DropResult): Promise<void> => {
        if (result.type === CalendarViewDragType.Vehicles) {
            this._onDragVehicleItemEnd(result);
        }

        if (result.type === CalendarViewDragType.Transports) {
            this._onDragTransportItemEnd(result);
        }
    };

    private _showCreateVehicleModal = (initiatedFrom: InitiatedFrom) => {
        this.setState({ createVehicleSidebar: { visible: true, initiatedFrom } });
    };

    private _cancelCreateVehicleModal = () => {
        this.setState({ createVehicleSidebar: { visible: false } });
    };

    private _updateVehicles() {
        this.logic
            .vehicles()
            .getVehicles()
            .then(vehicles => {
                this.setState(state => ({
                    container: {
                        ...state.container,
                        data: {
                            ...state.container.data,
                            rows: this._getOrderedVehicles(vehicles)
                        }
                    }
                }));
            });

        this.logic
            .vehiclesState()
            .data()
            .then(vehiclesState =>
                this.setState(state => ({
                    container: {
                        ...state.container,
                        data: {
                            ...state.container.data,
                            vehicles: vehiclesState
                        }
                    }
                }))
            );
    }

    private _afterCreateVehicle = (vehicle: CompanyVehicle) => {
        this.props.logic.userEvents().addVehicle(vehicle, this.state.createVehicleSidebar?.initiatedFrom);
    };

    private _onOpenDeleteTransportModal = (transport: TransportModel) => {
        if (transport.state === TransportState.Active) {
            Modal.info({
                title: this.props.t('ActiveTransportEditModal.title'),
                content: this.props.t('ActiveTransportEditModal.content')
            });

            return;
        }

        Modal.confirm({
            title: this.props.t('Planner.deleteHeader'),
            content: `${transport.places[0].name} - ${transport.places[transport.places.length - 1].name}`,
            okText: this.props.t('common.delete'),
            onOk: () => {
                this._onCloseTransportDetail();
                this._onDeleteTransportConfirm(transport.id);
            },
            icon: <i className="rl-modal-icon fas fa-trash-alt" />,
            okType: 'danger'
        });
    };

    private _onDeleteTransportConfirm = (transportId: string) => {
        this.setState(state => ({
            container: {
                ...state.container,
                processing: true
            }
        }));

        this.props.logic
            .schedulingRouteOverview()
            .removeTransport(transportId)
            .then(() => {
                this._fetchTransports();
                Notification.success({
                    message: this.props.t('Notifications.transportDeleted.message'),
                    description: this.props.t('Notifications.transportDeleted.description'),
                    duration: 5,
                    placement: 'bottomRight',
                    icon: <i className="fas fa-trash-alt" style={{ color: '#de3029' }} />
                });
                this.logic.userEvents().deleteTransport(transportId);
            })
            .catch(err => {
                Notification.error({
                    message: err.message,
                    placement: 'bottomRight'
                });
            })
            .finally(() => {
                this.setState(state => ({
                    container: {
                        ...state.container,
                        processing: false
                    }
                }));
            });
    };

    private _onUnassignTransport = async (transportId: string) => {
        try {
            this.setState(state => ({
                container: {
                    ...state.container,
                    data: {
                        ...state.container.data,
                        newTransports: [
                            ...state.container.data.newTransports,
                            {
                                ...state.container.data.otherTransports.filter(
                                    transport => transport.id === transportId
                                )[0],
                                vehicleId: undefined,
                                state: TransportState.New
                            }
                        ],
                        otherTransports: state.container.data.otherTransports.filter(
                            transport => transport.id !== transportId
                        )
                    },
                    processing: true
                }
            }));

            const companyId = this.logic.company().getCompany().companyId;
            const defaultProfile = this.logic.schedulingRoutePlanner().getDefaultVehicleProfile();
            this.logic
                .apiService()
                .transports()
                .patchTransport(companyId, Number(transportId), {
                    vehicleId: undefined,
                    driverId: undefined,
                    route: {
                        cost: defaultProfile?.costPerKm || TOLL_COST_DEFAULT_VALUE
                    },
                    vehicleProfileId: defaultProfile?.vehicleProfileId,
                    status: CompanyTransportStatus.New
                })
                .then(() => {
                    this._updateTransportSidebar();
                    this.setState(
                        state => ({
                            container: {
                                ...state.container,
                                processing: false
                            }
                        }),
                        () => {
                            Notification.success({
                                message: this.props.t('Notifications.transportUnassigned.message'),
                                description: this.props.t('Notifications.transportUnassigned.description'),
                                icon: <img src="/img/dispatcher-board/unassigned-icon.svg" alt="unassigned" />
                            });
                        }
                    );
                });
        } catch (err) {
            console.log(err);
            Notification.error({
                message: err.message
            });
        }
    };

    private _onOpenAssignVehicleModal = (transport: TransportModel) => {
        this.setState({ assignVehicleModal: { visible: true, selectedTransport: transport } });
    };

    private _onAssignVehicleToTransport = async (vehicleId: string) => {
        const selectedTransport = this.state.assignVehicleModal.selectedTransport;
        try {
            await this.logic
                .dispatcherBoard()
                .addVehicleToTransport(
                    vehicleId,
                    selectedTransport?.id!,
                    selectedTransport?.state || TransportState.Assigned
                );

            this.setState(
                state => ({
                    container: {
                        ...state.container,
                        processing: false
                    },
                    assignVehicleModal: { visible: false }
                }),
                () => {
                    this._fetchTransports();

                    Notification.success({
                        message: this.props.t('common.updated'),
                        description: this.props.t('Planner.updateSuccess')
                    });
                }
            );
        } catch (err) {
            console.log(err);
            Notification.error({
                message: err.message
            });
        }
    };

    private _onTransportEditClick = (transportId: string, vehicleId?: string): void => {
        this.props.logic
            .userEvents()
            .viewTransport(transportId, vehicleId, InitiatedFrom.DISPATCH_BOARD_TRANSPORT_DB_CLICK);

        this.props.history.push({
            pathname: RouteNames.SCHEDULING_PLANNER,
            search: qs.stringify({
                redirectedFrom: 'calendar',
                editId: transportId,
                vehicleId: vehicleId,
                startDate: moment(this.state.container.startDate)
                    .subtract(this.state.container.daysBefore, 'day')
                    .format(DATE_FORMAT)
            })
        });
    };

    private _onTransportDetailClick = (transport: TransportModel): void => {
        this.props.logic
            .userEvents()
            .viewTransport(transport.id, transport.vehicleId, InitiatedFrom.DISPATCH_BOARD_TRANSPORT_CLICK);

        this.setState({
            transportDetailSidebar: {
                visible: true,
                transport: [
                    ...this.state.container.data.newTransports,
                    ...this.state.container.data.otherTransports
                ].find(t => t.id === transport.id),
                vehicle: this.state.container.data.rows.find(v => v.vehicleId?.toString() === transport.vehicleId)
            }
        });
    };

    private _onLocalizeVehicle = (vehicleId?: string): void => {
        this.props.history.push({
            pathname: RouteNames.TRACKING_MAP,
            search: qs.stringify({
                vehicleId: vehicleId
            })
        });
    };

    private _onCloseTransportDetail = (): void => {
        this.setState({
            transportDetailSidebar: {
                visible: false,
                transport: undefined,
                vehicle: undefined
            }
        });
    };

    private _onDaysSegmentChange = (segment: number) => {
        const params = qs.parse(this.props.location.search, {
            ignoreQueryPrefix: true,
            plainObjects: true
        });

        this.props.history.push({
            search: qs.stringify({
                ...params,
                daySegment: segment.toString()
            } as RouteParams)
        });

        this.setState(
            state => ({
                bar: {
                    ...state.bar,
                    daySegment: segment
                },
                container: {
                    ...state.container,
                    ...this._setDaysBySegment(segment)
                }
            }),
            () => {
                this.logic.dispatcherBoard().setSettings({ daySegment: segment });
            }
        );
    };

    private _updateTransportSidebar = () => {
        if (this.state.transportDetailSidebar.visible && this.state.transportDetailSidebar.transport) {
            const transport = [
                ...this.state.container.data.newTransports,
                ...this.state.container.data.otherTransports
            ].find(t => t.id === this.state.transportDetailSidebar.transport?.id);

            this.setState(state => ({
                transportDetailSidebar: {
                    ...state.transportDetailSidebar,
                    transport: transport,
                    vehicle: this.state.container.data.rows.find(v => v.vehicleId?.toString() === transport?.vehicleId)
                }
            }));
        }
    };

    private _setDaysBySegment(daySegment: number, startDate?: string) {
        return {
            startDate: startDate
                ? moment(startDate, DATE_FORMAT).toISOString()
                : moment(this.state.container.startDate).startOf('day').toISOString(),
            daysBefore: daySegment === DaysSegmentType.day5 ? 1 : 0,
            daysAfter: daySegment === DaysSegmentType.day5 ? 3 : daySegment === DaysSegmentType.day2 ? 1 : 0
        };
    }

    private _getOrderedVehicles(vehicles: CompanyVehicle[]) {
        const vehicleSortOrder = this.props.userSettingsStore?.calendarVehicleOrder;
        const orderedVehicles = [...vehicles];

        if (vehicleSortOrder) {
            return orderedVehicles.sort((a, b) => {
                return vehicleSortOrder.indexOf(a.vehicleId!) - vehicleSortOrder.indexOf(b.vehicleId!);
            });
        }

        return vehicles;
    }

    private _onFirstTransportPopoverClose = () => {
        this.setState(
            {
                firstTransportPopoverVisible: false
            },
            () => this.logic.dispatcherBoard().setSettings({ firstTransportPopoverVisible: false })
        );
    };

    private async _fetchTransports() {
        return this.logic.dispatcherBoard().transports({
            dateFrom: moment(this.state.container.startDate),
            dateTo: moment(this.state.container.startDate)
                .add(this.state.container.daysBefore + this.state.container.daysAfter, 'days')
                .endOf('day')
        });
    }

    private _onMobileFilterChanged = (filter: { date: string; selectedVehicle: VehicleStateObject | null }) => {
        if (filter.date !== this.state.container.startDate) {
            this._onStartDateChange(moment(filter.date));
        }

        if (filter.selectedVehicle?.monitoredObjectId !== this.state.container.selectedVehicle?.monitoredObjectId) {
            this.setState(state => ({
                container: {
                    ...state.container,
                    selectedVehicle: filter.selectedVehicle
                }
            }));
        }
    };

    private _filterTransports = () => {
        const selectedVehicle = this.state.container.selectedVehicle;
        return selectedVehicle
            ? this.state.container.data.otherTransports.filter(t => t.vehicleId === selectedVehicle?.monitoredObjectId)
            : this.state.container.data.newTransports;
    };
}

export default withTranslation()(withRouter(CalendarViewModule));
