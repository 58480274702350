import { ReadOnlyCurrency } from 'generated/new-main';
import { LatLng } from 'common/model/geo';
import mapboxgl from 'mapbox-gl';
import { Logic } from '../../logic';
import { MapLogicUtils } from '../common/MapLogicUtils';
import { FuelStationPriceList } from '../../../generated/backend-api';
import { fsBestPriceInPois } from '../../common/best-price';
import { debounce } from '../../../utils/helpers/debounce';
import { Subject } from 'rxjs';
import { PlaceType } from '../../../services/api/domains/PlacesApi';
import { FuelLayers, FUEL_STATIONS_SOURCE_ID } from '../layers/FuelstationsLayer';
import { AvailableCurrencies } from 'utils/constants/currencies';
import { RootStore } from 'stores/RootStore';
import { FuelPriceFuelStation } from 'services/api/domains/FuelApi';
import { BoudingBoxCache } from 'logic/common/BoundingBoxCache';
import { FuelCardModel } from 'services/api/domains/CompanyApi';

export enum FuelStationServices {
    WF00001 = 'MYCKA',
    WF00002 = 'NONSTOP',
    WF00003 = 'OBCERSTVENI',
    WF00004 = 'PARKOVISTE',
    WF00005 = 'PARKOVISTE_hlid',
    WF00006 = 'PNEU',
    WF00007 = 'REST',
    WF00008 = 'RESTAURACE',
    WF00009 = 'SELFSERVICE',
    WF00010 = 'SHOP',
    WF00011 = 'SPRCHA',
    WF00012 = 'STK',
    WF00013 = 'TOLL',
    WF00014 = 'WiFi',
    WF00015 = 'TRUCK_WASH',
    WF00016 = 'KAMEROVYZAZNAM',
    WF00017 = 'DO3,5TUNY',
    WF00018 = 'NAD3,5TUNY'
}

export enum FuelStationServicesTranslations {
    WF00001 = 'carWash',
    WF00002 = 'nonstop',
    WF00003 = 'refreshment',
    WF00004 = 'parking',
    WF00005 = 'parkingSecure',
    WF00006 = 'pneu',
    WF00007 = 'rest',
    WF00008 = 'restaurant',
    WF00009 = 'selfService',
    WF00010 = 'shop',
    WF00011 = 'shower',
    WF00012 = 'carTechCheckPlace',
    WF00013 = 'toll',
    WF00014 = 'wifi',
    WF00015 = 'truckWash',
    WF00016 = 'cameraArea',
    WF00017 = 'under3,5t',
    WF00018 = 'over3,5t'
}

export enum FuelTypes {
    '0100' = 'Gasoline',
    '0101' = 'Gasoline_85',
    '0102' = 'Gasoline_87',
    '0103' = 'Gasoline_88',
    '0104' = 'Gasoline_89',
    '0105' = 'Gasoline_90',
    '0106' = 'Gasoline_91',
    '0107' = 'Gasoline_92',
    '0108' = 'Gasoline_93',
    '0109' = 'Gasoline_94',
    '0110' = 'Gasoline_95',
    '0111' = 'Gasoline_95+',
    '0112' = 'Gasoline_98',
    '0113' = 'Gasoline_98+',
    '0114' = 'Gasoline_100',
    '0115' = 'Gasoline_86',
    '0116' = 'Gasoline_96',
    '0117' = 'Gasoline_97',
    '0118' = 'Gasoline_102',
    '0119' = 'Gasoline_95-E10',

    '0200' = 'Diesel',
    '0201' = 'Diesel_ON',
    '0202' = 'Diesel_ON+',
    '0203' = 'Diesel_1D',
    '0204' = 'Diesel_2D',
    '0205' = 'Diesel_4D',
    '0206' = 'Diesel_ULSD',
    '0207' = 'Diesel_B2',
    '0208' = 'Diesel_B5',
    '0209' = 'Diesel_B20',
    '0210' = 'Diesel_B99',
    '0211' = 'Diesel_B100',
    '0212' = 'Diesel_B95',
    '0213' = 'Diesel_ULSD_10ppm',
    '0214' = 'Diesel_ULSD_50ppm',
    '0215' = 'Diesel_ULSD_500ppm',
    '0216' = 'Diesel_DEF',
    '0218' = 'Diesel_B10',
    '0219' = 'Diesel_B30',

    '0217' = 'Diesel_AdBlue',

    '0300' = 'Bioalcohol',
    '0301' = 'Bioalcohol_E5',
    '0302' = 'Bioalcohol_E10',
    '0303' = 'Bioalcohol_E22',
    '0304' = 'Bioalcohol_E50',
    '0305' = 'Bioalcohol_E85',
    '0306' = 'Bioalcohol_E93',
    '0307' = 'Bioalcohol_E100',
    '0308' = 'Bioalcohol_E70',
    '0309' = 'Bioalcohol_E27',
    '0310' = 'Bioalcohol_E15',
    '0311' = 'Bioalcohol_E20',

    '0400' = 'LPG/GPL',
    '0401' = 'LPG/GPL_GPL/LPG',

    '0500' = 'CNG',
    '0501' = 'CNG_CNG',
    '0502' = 'CNG_CBG',
    '0503' = 'CNG_Biogas',

    '0600' = 'Electricity',
    '0601' = 'Electricity_AC_240V',
    '0602' = 'Electricity_DC_500_Fast_Charge',
    '0603' = 'Electricity_Slow_charge',
    '0604' = 'Fast_charge',

    '0700' = 'Flex'
}

export const fuelToFuelTypeMap = {
    diesel: [
        '0200',
        '0201',
        '0202',
        '0203',
        '0204',
        '0205',
        '0206',
        '0207',
        '0208',
        '0209',
        '0210',
        '0211',
        '0212',
        '0213',
        '0214',
        '0215',
        '0216',
        '0218',
        '0219'
    ],
    adblue: ['0217'],
    cng: ['0500', '0501', '0502', '0503'],
    lng: [],
    lpg: ['0400', '0401'],
    electricity: ['0600', '0601', '0602', '0603', '0604', '0700'],
    bioalcohol: ['0300', '0301', '0302', '0303', '0304', '0305', '0306', '0307', '0308', '0309', '0310', '0311'],
    gasoline: [
        '0100',
        '0101',
        '0102',
        '0103',
        '0104',
        '0105',
        '0106',
        '0107',
        '0108',
        '0109',
        '0110',
        '0111',
        '0112',
        '0113',
        '0114',
        '0115',
        '0116',
        '0117',
        '0118',
        '0119'
    ]
};

export const serviceToTypeMap = {
    wc: ['WF00009'],
    shower: ['WF00011'],
    food: ['WF00003', 'WF00008', 'WF00010'],
    carWash: ['WF00001', 'WF00015'],
    special: []
};

export interface PoiModelMapFuelType {
    name: string;
    code: string;
    price?: {
        price: string;
        discountedPrice?: string;
        source: string;
        currency: string;
        type: string;
        productId: string;
        sumExtraFees: string;
        created?: Date;
    };
}

export interface PoiModelMapDiscount {
    discountCard: FuelCardModel;
    bestDiscount: PoiModelMapFuelType;
}

export interface PoiModelMap {
    id: string;
    name: string;
    detailAddress?: string;
    selected: boolean;
    inTransport?: boolean;
    position: LatLng;
    fuelTypes?: PoiModelMapFuelType[];
    bestPrice?: { value: number; label: string };
    services?: { name: string; code: string }[];
    routeIndex?: number;
    externalId?: string;
    fuelCardIds?: number[];
    discount?: PoiModelMapDiscount;
    isFavorite?: boolean;
    category?: PlaceType;
    isAlongRoute?: boolean | null;
}

export const PRICE_VISIBLE_ZOOM = 10;

export class FuelStationsMapController {
    visible: boolean;
    visibleChanged$ = new Subject<boolean>();

    private readonly _logic?: Logic;

    private _store: RootStore;
    private _mapBox?: mapboxgl.Map;
    private _markers: GeoJSON.Feature<GeoJSON.Point>[];
    private _markersById: { [id: string]: GeoJSON.Feature<GeoJSON.Point> } = {};
    private _currencies?: ReadOnlyCurrency[];
    private _fuelpricesBBoxCache = new BoudingBoxCache(2, 50);
    private _hoveredStateClusterId?: string | number;
    private _hoveredStateId?: string | number;

    private _onFuelStationClick?: (fuelStation: PoiModelMap) => void;

    constructor(mapBox: mapboxgl.Map, logic: Logic, store: RootStore) {
        this._store = store;
        this._mapBox = mapBox;
        this._markers = [];
        this.visible = true;
        this._logic = logic;

        this._addSourceAndLayers();
    }

    setCurrencies(currencies: ReadOnlyCurrency[]) {
        this._currencies = currencies;
    }

    async show() {
        this.visible = true;
        this.visibleChanged$.next(this.visible);
        this._renderMarkers(this._markers);
        this._updatePrices();
    }

    hide(): void {
        const source = this._mapBox?.getSource(FUEL_STATIONS_SOURCE_ID) as mapboxgl.GeoJSONSource;
        if (source) {
            source.setData({
                type: 'FeatureCollection',
                features: []
            });
        }
        this.visible = false;
        this.visibleChanged$.next(this.visible);
    }

    onClick(cb?: (fuelStation: PoiModelMap) => void): void {
        this._onFuelStationClick = cb;
    }

    setData(data: PoiModelMap[]): void {
        this._markers = data.map((fuelStation, index) => {
            const marker = this._createFuelStationMarker(fuelStation, index);
            this._markersById[marker.properties?.id] = marker;
            return marker;
        });

        this._renderMarkers(this._markers);
    }

    deleteMarker(fuelStationId: string): void {
        if (this._markersById[fuelStationId]) {
            delete this._markersById[fuelStationId];
            this._markers = Object.values(this._markersById);
            this._renderMarkers(this._markers);
        }
    }

    showMarker(fuelStation: PoiModelMap): void {
        this._markersById[fuelStation.id] = this._createFuelStationMarker(fuelStation, fuelStation.id);
        this._markers = Object.values(this._markersById);
        this._setHasBestPrice(this._markers);
        this._renderMarkers(this._markers);
    }

    private _renderMarkers(features: GeoJSON.Feature<GeoJSON.Point>[]): void {
        const source = this._mapBox?.getSource(FUEL_STATIONS_SOURCE_ID) as mapboxgl.GeoJSONSource;
        source?.setData({
            type: 'FeatureCollection',
            features
        });
    }

    private _createFuelStationMarker(fuelStation: PoiModelMap, index: number | string) {
        const geoJsonFeature: GeoJSON.Feature<GeoJSON.Point> = {
            type: 'Feature',
            id: index,
            properties: {
                id: fuelStation.id,
                name: fuelStation.name,
                fuelCardIds: fuelStation.fuelCardIds,
                services: fuelStation.services,
                isFavorite: fuelStation.isFavorite,
                isSelected: fuelStation.selected,
                routeIndex: fuelStation.routeIndex,
                category: PlaceType.GAS_STATION,
                fsBestPriceString: fuelStation.bestPrice?.label,
                fsBestPrice: fuelStation.bestPrice?.value,
                isAlongRoute: fuelStation.isAlongRoute,
                fuelTypes: fuelStation?.fuelTypes
            },
            geometry: {
                type: 'Point',
                coordinates: [fuelStation.position.lng, fuelStation.position.lat]
            }
        };

        return geoJsonFeature;
    }

    private _addSourceAndLayers(): void {
        this._mapBox?.on('moveend', async () => {
            this._updatePrices();
        });

        this._mapBox?.on('mouseenter', FuelLayers.FUEL_CLUSTER, e => {
            const features = this._mapBox?.queryRenderedFeatures(e.point, {
                layers: [FuelLayers.FUEL_CLUSTER]
            }) as GeoJSON.Feature<GeoJSON.Point>[];

            const clusterId = features?.[0].properties?.cluster_id;

            if (this._hoveredStateClusterId) {
                this._mapBox?.setFeatureState(
                    { source: FUEL_STATIONS_SOURCE_ID, id: this._hoveredStateClusterId },
                    { hover: false }
                );
            }

            this._hoveredStateClusterId = clusterId;
            this._mapBox?.setFeatureState(
                { source: FUEL_STATIONS_SOURCE_ID, id: this._hoveredStateClusterId },
                { hover: true }
            );
        });

        this._mapBox?.on('mouseleave', FuelLayers.FUEL_CLUSTER, () => {
            if (this._hoveredStateClusterId) {
                this._mapBox?.setFeatureState(
                    { source: FUEL_STATIONS_SOURCE_ID, id: this._hoveredStateClusterId },
                    { hover: false }
                );
            }

            this._hoveredStateClusterId = undefined;
        });

        this._mapBox?.on('click', FuelLayers.FUEL_CLUSTER, e => {
            const features = this._mapBox?.queryRenderedFeatures(e.point, {
                layers: [FuelLayers.FUEL_CLUSTER]
            }) as GeoJSON.Feature<GeoJSON.Point>[];

            const clusterId = features?.[0].properties?.cluster_id;
            const source = this._mapBox?.getSource(FUEL_STATIONS_SOURCE_ID) as mapboxgl.GeoJSONSource;

            source.getClusterExpansionZoom(clusterId, (err, zoom) => {
                if (err) return;

                this._mapBox?.easeTo({
                    center: features?.[0].geometry.coordinates as [number, number],
                    zoom: zoom
                });
            });
        });

        this._mapBox?.on('click', FuelLayers.FUEL_UNCLUSTERED, async e => {
            const feature = e.features?.[0] as GeoJSON.Feature<GeoJSON.Point>;
            if (!feature) {
                return;
            }

            const zoomLvl = this._mapBox!.getZoom();
            if (zoomLvl <= PRICE_VISIBLE_ZOOM && this._store.auth.isLoggedIn) {
                await this._updatePricesForFuelStation(feature);
            }

            const fuelStation = feature.properties;
            const [lng, lat] = feature.geometry.coordinates;

            if (fuelStation) {
                fuelStation.fuelTypes = fuelStation?.fuelTypes ? JSON.parse(fuelStation?.fuelTypes) : [];
                fuelStation.position = { lng, lat };
                fuelStation.services = fuelStation?.services ? JSON.parse(fuelStation?.services) : [];
                fuelStation.fuelCardIds = fuelStation.fuelCardIds ? JSON.parse(fuelStation.fuelCardIds) : [];

                this._onFuelStationClick?.(fuelStation as PoiModelMap);
            }
        });

        this._mapBox?.on('mouseenter', FuelLayers.FUEL_UNCLUSTERED, e => {
            const feature = e.features?.[0] as GeoJSON.Feature<GeoJSON.Point>;
            if (!feature) {
                return;
            }

            if (feature.properties?.isAlongRoute === false) {
                if (this._hoveredStateId) {
                    this._mapBox?.removeFeatureState(
                        { source: FUEL_STATIONS_SOURCE_ID, id: this._hoveredStateId },
                        'hover'
                    );
                }

                this._hoveredStateId = feature.id;
                this._mapBox?.setFeatureState(
                    { source: FUEL_STATIONS_SOURCE_ID, id: this._hoveredStateId },
                    { hover: true }
                );
            }
        });

        this._mapBox?.on('mouseleave', FuelLayers.FUEL_UNCLUSTERED, () => {
            if (this._hoveredStateId) {
                this._mapBox?.removeFeatureState(
                    { source: FUEL_STATIONS_SOURCE_ID, id: this._hoveredStateId },
                    'hover'
                );
            }

            this._hoveredStateId = undefined;
        });
    }

    _updatePrices = debounce(async () => {
        if (
            this.visible &&
            this._markers.length &&
            this._store.auth.isLoggedIn &&
            this._mapBox!.getZoom() >= PRICE_VISIBLE_ZOOM
        ) {
            const bounds = this._mapBox!.getBounds();
            const newBoundingBoxes = this._fuelpricesBBoxCache.getNewBoundingBoxes(bounds);
            let fuelPrices: FuelPriceFuelStation[] = [];
            let fuelStationsOnMap: GeoJSON.Feature<GeoJSON.Point>[] = [];

            await Promise.all(
                newBoundingBoxes.map(async square => {
                    const bounds = square.boundingBox;
                    const boundingBox = `${bounds.getSouthWest().lat},${bounds.getSouthWest().lng}|${
                        bounds.getNorthEast().lat
                    },${bounds.getNorthEast().lng}`;
                    const fp = await this._logic?.poi().getFuelPrices(boundingBox);

                    fuelPrices = [...fuelPrices, ...(fp || [])];
                    fuelStationsOnMap = [
                        ...fuelStationsOnMap,
                        ...this._markers.filter(m => {
                            const [lng, lat] = m.geometry.coordinates;
                            return bounds.contains({ lng, lat });
                        })
                    ];

                    this._fuelpricesBBoxCache.squareCache.add(square.id);
                })
            );

            if (fuelPrices?.length) {
                const companyFuelCards = await this._logic
                    ?.apiService()
                    .company()
                    .getFuelCards(this._logic.company().getCompany().companyId);

                const updatedFuelStations = this._updateFuelStationsWithPrice(
                    fuelStationsOnMap,
                    fuelPrices,
                    companyFuelCards || []
                );

                for (const index in updatedFuelStations) {
                    const fuelStation = updatedFuelStations[index];
                    const geoJsonFeature = this._markersById?.[fuelStation.id];
                    geoJsonFeature.properties!.fuelTypes = fuelStation.fuelTypes;

                    if (fuelStation.bestPrice) {
                        geoJsonFeature.properties!.fsBestPriceString = fuelStation.bestPrice.label;
                        geoJsonFeature.properties!.fsBestPrice = fuelStation.bestPrice.value;
                    }
                }

                this._setHasBestPrice(this._markers);

                if (this.visible && this._mapBox!.getZoom() >= PRICE_VISIBLE_ZOOM) {
                    this._renderMarkers(this._markers);
                }
            }
        }
    }, 1000);

    convertFuelPriceToUserCurrency(fuel: FuelPriceFuelStation): FuelPriceFuelStation {
        const currency = this._store.userSettings.currency ?? AvailableCurrencies.EUR;

        const newPrices = fuel.fuelPrices.map(price => {
            if (price.currency === currency) {
                return price;
            }
            const exchangeRate = this._currencies?.find(c => c.code === price.currency)?.latestExchangeRate?.[0];
            if (exchangeRate?.rate) {
                const newPrice = price.price / exchangeRate.rate;
                return {
                    ...price,
                    price: newPrice,
                    currency
                };
            } else {
                console.warn(`Cannot convert [${price.currency}] -> [${currency}]`);
                return price;
            }
        });

        return { ...fuel, fuelPrices: newPrices };
    }

    _updateFuelStationsWithPrice(
        fuelStations: GeoJSON.Feature<GeoJSON.Point>[],
        fuelStationPriceList: FuelPriceFuelStation[],
        fuelCards: FuelCardModel[]
    ): PoiModelMap[] {
        const updatedFuelStations: PoiModelMap[] = [];

        fuelStationPriceList.forEach(priceList => {
            const preparePrices: FuelStationPriceList[] = [];
            const fuelPricePosition = { lat: priceList.coordinate.lat, lng: priceList.coordinate.lon };
            const foundedFuelStationForPriceList = fuelStations.find(fs => {
                const [lng, lat] = fs.geometry.coordinates;
                return MapLogicUtils.getDistanceInMeters({ lng, lat }, fuelPricePosition) < 50;
            });

            if (foundedFuelStationForPriceList) {
                const convertedPriceList = this.convertFuelPriceToUserCurrency(priceList);
                const id = foundedFuelStationForPriceList.properties?.id;
                convertedPriceList.fuelPrices.forEach(fp => {
                    const code = '0' + fp.fuel.id.toString();
                    preparePrices.push({
                        id: id + '_' + fp.fuel.id,
                        price: String(fp.price),
                        currency: fp.currency,
                        locationCode: id,
                        extraFees: [],
                        date: new Date(fp.created),
                        productId: code,
                        type: Object.keys(fuelToFuelTypeMap).find(key => fuelToFuelTypeMap[key].includes(code))
                    });
                });

                const updatedFuelStation = this._logic?.poi().updateFuelStationFuelPrices(id, preparePrices, fuelCards);
                updatedFuelStation && updatedFuelStations.push(updatedFuelStation);
            }
        });

        return updatedFuelStations;
    }

    private _setHasBestPrice(features: GeoJSON.Feature<GeoJSON.Point>[]) {
        const bounds = this._mapBox!.getBounds();
        const stationsInBounds: GeoJSON.Feature<GeoJSON.Point>[] = [];

        features.forEach(feature => {
            const [lng, lat] = feature.geometry.coordinates;
            const fuelStation = feature.properties as PoiModelMap;

            if (fuelStation) {
                fuelStation['hasBestPrice'] = false; // reset best prices
            }

            if (bounds.contains({ lng, lat })) {
                stationsInBounds.push(feature);
            }
        });

        const bestPriceStations = fsBestPriceInPois(
            stationsInBounds.map(s => s.properties! as PoiModelMap),
            this._currencies
        );

        bestPriceStations?.forEach(bestPriceFs => {
            const featureWithBestPrice = stationsInBounds.find(fs => fs.properties?.id.toString() === bestPriceFs.id);
            const stationWithBestPrice = featureWithBestPrice?.properties;
            if (stationWithBestPrice) {
                stationWithBestPrice['hasBestPrice'] = true;
            }
        });

        return features;
    }

    private async _updatePricesForFuelStation(feature: GeoJSON.Feature<GeoJSON.Point>) {
        const center = feature.geometry.coordinates;
        const latLng = new mapboxgl.LngLat(center[0], center[1]);
        const bounds = latLng.toBounds(50);
        const boundingBox = `${bounds.getSouthWest().lat},${bounds.getSouthWest().lng}|${bounds.getNorthEast().lat},${
            bounds.getNorthEast().lng
        }`;

        const fuelPrices = await this._logic?.poi().getFuelPrices(boundingBox);
        const fuelCards =
            (await this._logic?.apiService().company().getFuelCards(this._logic.company().getCompany().companyId)) ||
            [];

        if (fuelPrices?.length) {
            this._updateFuelStationsWithPrice([feature], fuelPrices, fuelCards);
        }
    }
}
