/* tslint:disable */
/* eslint-disable */
/**
 * Telematics backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BackendApiApiV1DriverbehaviourLightVehiclesDriverBehaviourResponse,
    BackendApiApiV1DriverbehaviourLightVehiclesDriverBehaviourResponseFromJSON,
    BackendApiApiV1DriverbehaviourLightVehiclesDriverBehaviourResponseToJSON,
    HTTPValidationError,
    HTTPValidationErrorFromJSON,
    HTTPValidationErrorToJSON,
} from '../models';

export interface GetDriverBehaviourMobileV1DriverbehaviourlightMyMobileAppsGetRequest {
    name?: string;
    driverId?: number;
    fromT?: Date;
}

export interface GetDriverBehaviourV1DriverbehaviourlightMyGetRequest {
    name?: string;
    driverId?: number;
    fromT?: Date;
}

/**
 * no description
 */
export class DriverBehaviourLightVehiclesApi extends runtime.BaseAPI {

    /**
     * Get Driver Behaviour Mobile
     */
    async getDriverBehaviourMobileV1DriverbehaviourlightMyMobileAppsGetRaw(requestParameters: GetDriverBehaviourMobileV1DriverbehaviourlightMyMobileAppsGetRequest): Promise<runtime.ApiResponse<BackendApiApiV1DriverbehaviourLightVehiclesDriverBehaviourResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.driverId !== undefined) {
            queryParameters['driver_id'] = requestParameters.driverId;
        }

        if (requestParameters.fromT !== undefined) {
            queryParameters['from_t'] = (requestParameters.fromT as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/driverbehaviourlight/my/mobile-apps`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BackendApiApiV1DriverbehaviourLightVehiclesDriverBehaviourResponseFromJSON(jsonValue));
    }

    /**
     * Get Driver Behaviour Mobile
     */
    async getDriverBehaviourMobileV1DriverbehaviourlightMyMobileAppsGet(requestParameters: GetDriverBehaviourMobileV1DriverbehaviourlightMyMobileAppsGetRequest): Promise<BackendApiApiV1DriverbehaviourLightVehiclesDriverBehaviourResponse> {
        const response = await this.getDriverBehaviourMobileV1DriverbehaviourlightMyMobileAppsGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get Driver Behaviour
     */
    async getDriverBehaviourV1DriverbehaviourlightMyGetRaw(requestParameters: GetDriverBehaviourV1DriverbehaviourlightMyGetRequest): Promise<runtime.ApiResponse<BackendApiApiV1DriverbehaviourLightVehiclesDriverBehaviourResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.driverId !== undefined) {
            queryParameters['driver_id'] = requestParameters.driverId;
        }

        if (requestParameters.fromT !== undefined) {
            queryParameters['from_t'] = (requestParameters.fromT as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("HTTPBearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/driverbehaviourlight/my`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BackendApiApiV1DriverbehaviourLightVehiclesDriverBehaviourResponseFromJSON(jsonValue));
    }

    /**
     * Get Driver Behaviour
     */
    async getDriverBehaviourV1DriverbehaviourlightMyGet(requestParameters: GetDriverBehaviourV1DriverbehaviourlightMyGetRequest): Promise<BackendApiApiV1DriverbehaviourLightVehiclesDriverBehaviourResponse> {
        const response = await this.getDriverBehaviourV1DriverbehaviourlightMyGetRaw(requestParameters);
        return await response.value();
    }

}
