import { Modal } from 'components/base/layout';
import { Logic } from 'logic/logic';
import { useState } from 'react';
import { UserRole } from 'services/api/domains/CompanyApi';
import { getInviteLink } from 'utils/helpers/invite-driver';
import { InviteDriver } from './ui/InviteDriver';

interface Props {
    logic: Logic;
    visible: boolean;
    onCancel?: () => void;
}

export const InviteDriverModule = (props: Props) => {
    const [inviteLink, setInviteLink] = useState('');

    const _createNewInviteLink = async () => {
        try {
            const companyId = props.logic.company().getCompany().companyId;
            const code = await props.logic.apiService().company().createCode(companyId, UserRole.driver);
            const inviteLink = getInviteLink(code.code, companyId, props.logic.conf.api.inviteUrl);
            setInviteLink(inviteLink);
        } catch (err) {
            console.error(err);
        }
    };

    const _disableInviteLink = async () => {
        try {
            const companyId = props.logic.company().getCompany().companyId;
            await props.logic.apiService().company().deleteCode(companyId, UserRole.driver);
            setInviteLink('');
        } catch (err) {
            console.error(err);
        }
    };

    const _setInviteLink = async () => {
        try {
            const companyId = props.logic.company().getCompany().companyId;
            const code = await props.logic.apiService().company().getCode(companyId, UserRole.driver);
            const inviteLink = getInviteLink(code.code, companyId, props.logic.conf.api.inviteUrl);
            setInviteLink(inviteLink);
        } catch (err) {
            console.error(err);
        }
    };

    return (
        <Modal
            className="rl-assign-driver-modal"
            visible={props.visible}
            onCancel={props.onCancel}
            width={944}
            destroyOnClose={true}
        >
            <InviteDriver
                inviteLink={inviteLink}
                onDisableLinkClick={_disableInviteLink}
                onCreateNewLinkClick={_createNewInviteLink}
                onCancel={props.onCancel}
                onBtnClick={initiatedFrom => props.logic.userEvents().inviteDriverSentBy(initiatedFrom)}
                setInviteLink={_setInviteLink}
            />
        </Modal>
    );
};
