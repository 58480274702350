import { PRICE_VISIBLE_ZOOM } from '../logic/fuelStations';
import { createIcon } from '../utils';

export enum FuelStationMarkerIcon {
    FuelStation = '/img/map-markers/rl-fuel.svg',
    FuelStationPrice = '/img/map-markers/rl-fuel-price.svg',
    FuelStationBestPrice = '/img/map-markers/rl-fuel-best-price.svg',
    FuelStationCluster = '/img/map-markers/rl-fuel-cluster.svg',
    FuelStationClusterPrice = '/img/map-markers/rl-fuel-cluster-price.svg',
    FuelStationClusterBestPrice = '/img/map-markers/rl-fuel-cluster-best-price.svg'
}

export enum FuelLayers {
    FUEL_CLUSTER = 'fuel-cluster',
    FUEL_CLUSTER_PRICE_TEXT = 'fuel-cluster-price-text',
    FUEL_CLUSTER_COUNT = 'fuel-cluster-count',
    FUEL_UNCLUSTERED = 'fuel-unclustered',
    FUEL_UNCLUSTERED_PRICE_TEXT = 'fuel-unclustered-price-text'
}

export const FUEL_STATIONS_SOURCE_ID = 'fuelStations';

export class FuelstationsLayer {
    public static async init(map: mapboxgl.Map) {
        await Promise.all([
            createIcon('fuelStationIcon', FuelStationMarkerIcon.FuelStation, map),
            createIcon('fuelStationPriceIcon', FuelStationMarkerIcon.FuelStationPrice, map),
            createIcon('fuelStationBestPriceIcon', FuelStationMarkerIcon.FuelStationBestPrice, map),
            createIcon('fuelStationClusterIcon', FuelStationMarkerIcon.FuelStationCluster, map),
            createIcon('fuelStationClusterPriceIcon', FuelStationMarkerIcon.FuelStationClusterPrice, map),
            createIcon('fuelStationClusterBestPriceIcon', FuelStationMarkerIcon.FuelStationClusterBestPrice, map)
        ]);

        map.addSource(FUEL_STATIONS_SOURCE_ID, {
            type: 'geojson',
            data: {
                type: 'FeatureCollection',
                features: []
            },
            cluster: true,
            clusterMaxZoom: 18,
            clusterRadius: 150,
            clusterProperties: {
                bestPrice: ['min', ['number', ['get', 'fsBestPrice'], Number.MAX_SAFE_INTEGER]],
                hasBestPrice: ['any', ['boolean', ['get', 'hasBestPrice'], false], true],
                hasFavorites: ['any', ['boolean', ['get', 'isFavorite'], false]],
                isAlongRouteActive: ['all', ['!=', ['get', 'isAlongRoute'], null]],
                existsPoiAlongRoute: ['any', ['==', ['get', 'isAlongRoute'], true]]
            }
        });

        map.addLayer({
            id: FuelLayers.FUEL_CLUSTER,
            type: 'symbol',
            source: FUEL_STATIONS_SOURCE_ID,
            filter: ['has', 'point_count'],
            layout: {
                'icon-image': [
                    'step',
                    ['zoom'],
                    'fuelStationClusterIcon',
                    PRICE_VISIBLE_ZOOM,
                    [
                        'case',
                        [
                            'all',
                            ['==', ['get', 'hasBestPrice'], true],
                            ['has', 'bestPrice'],
                            ['!=', ['get', 'bestPrice'], Number.MAX_SAFE_INTEGER]
                        ],
                        'fuelStationClusterBestPriceIcon',
                        ['all', ['has', 'bestPrice'], ['!=', ['get', 'bestPrice'], Number.MAX_SAFE_INTEGER]],
                        'fuelStationClusterPriceIcon',
                        'fuelStationClusterIcon'
                    ],
                    23, // MapBox max zoom level
                    'fuelStationClusterIcon'
                ],
                'icon-allow-overlap': true,
                'icon-anchor': 'center'
            },
            paint: {
                'icon-opacity': [
                    'case',
                    ['==', ['get', 'isAlongRouteActive'], true],
                    [
                        'case',
                        [
                            'any',
                            ['==', ['get', 'existsPoiAlongRoute'], true],
                            ['boolean', ['feature-state', 'hover'], false]
                        ],
                        1,
                        0.6
                    ],
                    1
                ]
            }
        });

        map.addLayer({
            id: FuelLayers.FUEL_CLUSTER_PRICE_TEXT,
            source: FUEL_STATIONS_SOURCE_ID,
            type: 'symbol',
            filter: [
                'all',
                ['has', 'point_count'],
                ['>=', ['zoom'], PRICE_VISIBLE_ZOOM],
                ['!=', ['get', 'bestPrice'], Number.MAX_SAFE_INTEGER]
            ],
            layout: {
                'text-field': [
                    // @ts-ignore
                    'number-format',
                    ['get', 'bestPrice'],
                    { 'min-fraction-digits': 3, 'max-fraction-digits': 3 }
                ],
                'text-font': [
                    'case',
                    ['==', ['get', 'hasBestPrice'], true],
                    ['literal', ['Open Sans Bold']],
                    ['literal', ['Open Sans Regular']]
                ],
                'text-size': 12,
                'text-offset': [0, -1.9],
                'text-allow-overlap': true
            },
            paint: {
                'text-color': ['case', ['==', ['get', 'hasBestPrice'], true], 'white', 'black']
            }
        });

        map.addLayer({
            id: FuelLayers.FUEL_CLUSTER_COUNT,
            type: 'symbol',
            source: FUEL_STATIONS_SOURCE_ID,
            filter: ['has', 'point_count'],
            layout: {
                'text-field': '{point_count_abbreviated}',
                'text-font': ['Open Sans Bold'],
                'text-size': 13,
                'text-offset': [
                    'step',
                    ['zoom'],
                    ['literal', [0, -1.2]],
                    PRICE_VISIBLE_ZOOM,
                    [
                        'case',
                        ['all', ['has', 'bestPrice'], ['!=', ['get', 'bestPrice'], Number.MAX_SAFE_INTEGER]],
                        ['literal', [0, -0.2]],
                        ['literal', [0, -1.2]]
                    ],
                    23, // MapBox max zoom level
                    ['literal', [0, -1.2]]
                ],
                'text-allow-overlap': true
            },
            paint: {
                'text-color': 'white'
            }
        });

        map.addLayer({
            id: FuelLayers.FUEL_UNCLUSTERED,
            type: 'symbol',
            source: FUEL_STATIONS_SOURCE_ID,
            filter: ['!', ['has', 'point_count']],
            layout: {
                'icon-image': [
                    'step',
                    ['zoom'],
                    'fuelStationIcon',
                    PRICE_VISIBLE_ZOOM,
                    [
                        'case',
                        ['all', ['==', ['get', 'hasBestPrice'], true], ['has', 'fsBestPriceString']],
                        'fuelStationBestPriceIcon',
                        ['has', 'fsBestPriceString'],
                        'fuelStationPriceIcon',
                        'fuelStationIcon'
                    ],
                    23, // MapBox max zoom level
                    'fuelStationIcon'
                ],
                'icon-allow-overlap': true,
                'icon-offset': [0, 11],
                'icon-anchor': 'bottom'
            },
            paint: {
                'icon-opacity': [
                    'case',
                    ['==', ['get', 'isAlongRoute'], null],
                    1,
                    [
                        'case',
                        ['boolean', ['feature-state', 'hover'], false],
                        1,
                        ['case', ['==', ['get', 'isAlongRoute'], true], 1, 0.6]
                    ]
                ]
            }
        });

        map.addLayer({
            id: FuelLayers.FUEL_UNCLUSTERED_PRICE_TEXT,
            source: FUEL_STATIONS_SOURCE_ID,
            type: 'symbol',
            filter: ['all', ['!', ['has', 'point_count']], ['>=', ['zoom'], PRICE_VISIBLE_ZOOM]],
            layout: {
                'text-field': [
                    // @ts-ignore
                    'number-format',
                    ['get', 'fsBestPrice'],
                    { 'min-fraction-digits': 3, 'max-fraction-digits': 3 }
                ],
                'text-font': [
                    'case',
                    ['==', ['get', 'hasBestPrice'], true],
                    ['literal', ['Open Sans Bold']],
                    ['literal', ['Open Sans Regular']]
                ],
                'text-size': 12,
                'text-offset': [0, -4.1],
                'text-allow-overlap': true
            },
            paint: {
                'text-color': ['case', ['==', ['get', 'hasBestPrice'], true], 'white', 'black']
            }
        });

        map.on('mouseenter', FuelLayers.FUEL_UNCLUSTERED, () => {
            map.getCanvas().style.cursor = 'pointer';
        });

        map.on('mouseleave', FuelLayers.FUEL_UNCLUSTERED, () => {
            map.getCanvas().style.cursor = '';
        });

        map.on('mouseenter', FuelLayers.FUEL_CLUSTER, () => {
            map.getCanvas().style.cursor = 'pointer';
        });

        map.on('mouseleave', FuelLayers.FUEL_CLUSTER, () => {
            map.getCanvas().style.cursor = '';
        });
    }
}
