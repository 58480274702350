import { useState } from 'react';
import { Trans } from 'react-i18next';

const NotOptimizedWarning = () => {
    const [visible, setVisible] = useState(true);

    return visible ? (
        <div className="rl-not-optimized-warning">
            <div className="rl-not-optimized-warning-text">
                <Trans i18nKey="NotOptimizedWarning.text">
                    <p>Parts of this web are not optimized for smaller screens.</p>
                    <p>Desktop PC is recommended.</p>
                </Trans>
            </div>
            <div className="rl-not-optimized-warning-close">
                <img src="/img/icn-rl-delete.svg" alt="close" height={10} onClick={() => setVisible(!visible)} />
            </div>
        </div>
    ) : (
        <></>
    );
};

export default NotOptimizedWarning;
