import { Logic } from './logic';
import { CompanyVehicle, VehicleStateObject } from 'services/api/domains/VehiclesApi';
import { RootStore } from 'stores';

export type VehicleWithAvailability = {
    available: boolean;
    data: VehicleStateObject;
    profile?: CompanyVehicle;
};

export class VehiclesStateLogic {
    private _logic: Logic;
    private _data?: VehicleStateObject[];

    constructor(logic: Logic, private _store: RootStore) {
        this._logic = logic;
    }

    async data(): Promise<VehicleStateObject[]> {
        try {
            const vehicles = await this._logic.apiService().vehicles().getVehiclesTracking();

            if (vehicles.length) {
                this._data = vehicles;
                return this._data;
            } else {
                return [];
            }
        } catch (err) {
            console.log('Increase vehicle updates err', err);
            throw err;
        }
    }

    async vehicle(id: string): Promise<VehicleStateObject | undefined> {
        try {
            if (!this._data) {
                try {
                    await this.data();
                } catch (err) {
                    console.log('Increase vehicle updates no data err', err);
                    throw err;
                }
            }

            const vehicle = this._data!.find(v => v.monitoredObjectId === id);

            return vehicle;
        } catch (err) {
            console.log('Increase vehicle updates err', err);
            throw err;
        }
    }

    async availableFromTime(): Promise<CompanyVehicle[]> {
        try {
            return this._store.auth.isLoggedIn ? await this._logic.vehicles().getVehicles() : [];
        } catch (err) {
            console.log('AvalableFrom time err', err);
            throw err;
        }
    }
}
