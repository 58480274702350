/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InlineResponse20027,
    InlineResponse20027FromJSON,
    InlineResponse20027ToJSON,
    ReadOnlyPriceListSerializer,
    ReadOnlyPriceListSerializerFromJSON,
    ReadOnlyPriceListSerializerToJSON,
    WriteOnlyPriceListSerializer,
    WriteOnlyPriceListSerializerFromJSON,
    WriteOnlyPriceListSerializerToJSON,
} from '../models';

export interface PriceListCreateRequest {
    data: WriteOnlyPriceListSerializer;
}

export interface PriceListDeleteRequest {
    id: string;
}

export interface PriceListListRequest {
    nameIexact?: string;
    nameIcontains?: string;
    clientNameIexact?: string;
    clientNameIcontains?: string;
    clientId?: string;
    createdAtIexact?: string;
    createdAtGte?: string;
    createdAtLte?: string;
    clientIdNexact?: number;
    limit?: number;
    offset?: number;
}

export interface PriceListPartialUpdateRequest {
    id: string;
    data: WriteOnlyPriceListSerializer;
}

export interface PriceListReadRequest {
    id: string;
}

export interface PriceListUpdateRequest {
    id: string;
    data: WriteOnlyPriceListSerializer;
}

/**
 * no description
 */
export class PriceListApi extends runtime.BaseAPI {

    /**
     */
    async priceListCreateRaw(requestParameters: PriceListCreateRequest): Promise<runtime.ApiResponse<ReadOnlyPriceListSerializer>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling priceListCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/price-list/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyPriceListSerializerToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyPriceListSerializerFromJSON(jsonValue));
    }

    /**
     */
    async priceListCreate(requestParameters: PriceListCreateRequest): Promise<ReadOnlyPriceListSerializer> {
        const response = await this.priceListCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async priceListDeleteRaw(requestParameters: PriceListDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling priceListDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/price-list/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async priceListDelete(requestParameters: PriceListDeleteRequest): Promise<void> {
        await this.priceListDeleteRaw(requestParameters);
    }

    /**
     */
    async priceListListRaw(requestParameters: PriceListListRequest): Promise<runtime.ApiResponse<InlineResponse20027>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.nameIexact !== undefined) {
            queryParameters['name__iexact'] = requestParameters.nameIexact;
        }

        if (requestParameters.nameIcontains !== undefined) {
            queryParameters['name__icontains'] = requestParameters.nameIcontains;
        }

        if (requestParameters.clientNameIexact !== undefined) {
            queryParameters['client__name__iexact'] = requestParameters.clientNameIexact;
        }

        if (requestParameters.clientNameIcontains !== undefined) {
            queryParameters['client__name__icontains'] = requestParameters.clientNameIcontains;
        }

        if (requestParameters.clientId !== undefined) {
            queryParameters['client_id'] = requestParameters.clientId;
        }

        if (requestParameters.createdAtIexact !== undefined) {
            queryParameters['created_at__iexact'] = requestParameters.createdAtIexact;
        }

        if (requestParameters.createdAtGte !== undefined) {
            queryParameters['created_at__gte'] = requestParameters.createdAtGte;
        }

        if (requestParameters.createdAtLte !== undefined) {
            queryParameters['created_at__lte'] = requestParameters.createdAtLte;
        }

        if (requestParameters.clientIdNexact !== undefined) {
            queryParameters['client_id__nexact'] = requestParameters.clientIdNexact;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/price-list/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20027FromJSON(jsonValue));
    }

    /**
     */
    async priceListList(requestParameters: PriceListListRequest): Promise<InlineResponse20027> {
        const response = await this.priceListListRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async priceListPartialUpdateRaw(requestParameters: PriceListPartialUpdateRequest): Promise<runtime.ApiResponse<ReadOnlyPriceListSerializer>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling priceListPartialUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling priceListPartialUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/price-list/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyPriceListSerializerToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyPriceListSerializerFromJSON(jsonValue));
    }

    /**
     */
    async priceListPartialUpdate(requestParameters: PriceListPartialUpdateRequest): Promise<ReadOnlyPriceListSerializer> {
        const response = await this.priceListPartialUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async priceListReadRaw(requestParameters: PriceListReadRequest): Promise<runtime.ApiResponse<ReadOnlyPriceListSerializer>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling priceListRead.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/price-list/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyPriceListSerializerFromJSON(jsonValue));
    }

    /**
     */
    async priceListRead(requestParameters: PriceListReadRequest): Promise<ReadOnlyPriceListSerializer> {
        const response = await this.priceListReadRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async priceListUpdateRaw(requestParameters: PriceListUpdateRequest): Promise<runtime.ApiResponse<ReadOnlyPriceListSerializer>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling priceListUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling priceListUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/price-list/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyPriceListSerializerToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyPriceListSerializerFromJSON(jsonValue));
    }

    /**
     */
    async priceListUpdate(requestParameters: PriceListUpdateRequest): Promise<ReadOnlyPriceListSerializer> {
        const response = await this.priceListUpdateRaw(requestParameters);
        return await response.value();
    }

}
