/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AddressClient
 */
export interface AddressClient {
    /**
     * 
     * @type {number}
     * @memberof AddressClient
     */
    readonly id?: number;
    /**
     * 
     * @type {string}
     * @memberof AddressClient
     */
    externalId: string;
    /**
     * 
     * @type {string}
     * @memberof AddressClient
     */
    name: string;
    /**
     * 
     * @type {object}
     * @memberof AddressClient
     */
    address?: object | null;
    /**
     * 
     * @type {string}
     * @memberof AddressClient
     */
    fieldOfActivity?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddressClient
     */
    language?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddressClient
     */
    currency?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddressClient
     */
    billingCode?: string | null;
    /**
     * 
     * @type {object}
     * @memberof AddressClient
     */
    correspondenceAddress?: object | null;
    /**
     * 
     * @type {object}
     * @memberof AddressClient
     */
    contact?: object | null;
    /**
     * 
     * @type {string}
     * @memberof AddressClient
     */
    emailForInvocing?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddressClient
     */
    identificationNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddressClient
     */
    vatNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddressClient
     */
    taxNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddressClient
     */
    bankingConnection?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddressClient
     */
    timeZone?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AddressClient
     */
    deleted: number;
    /**
     * 
     * @type {string}
     * @memberof AddressClient
     */
    languageIso6391?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AddressClient
     */
    isPartner?: boolean;
    /**
     * 
     * @type {object}
     * @memberof AddressClient
     */
    styles?: object | null;
    /**
     * 
     * @type {string}
     * @memberof AddressClient
     */
    logo?: string | null;
    /**
     * 
     * @type {object}
     * @memberof AddressClient
     */
    metadata?: object;
    /**
     * 
     * @type {object}
     * @memberof AddressClient
     */
    costPerKm?: object | null;
    /**
     * 
     * @type {number}
     * @memberof AddressClient
     */
    country?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AddressClient
     */
    externalSystem?: number;
    /**
     * 
     * @type {number}
     * @memberof AddressClient
     */
    referrer?: number | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof AddressClient
     */
    readonly modules?: Array<number>;
}

export function AddressClientFromJSON(json: any): AddressClient {
    return AddressClientFromJSONTyped(json, false);
}

export function AddressClientFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddressClient {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'externalId': json['external_id'],
        'name': json['name'],
        'address': !exists(json, 'address') ? undefined : json['address'],
        'fieldOfActivity': !exists(json, 'field_of_activity') ? undefined : json['field_of_activity'],
        'language': !exists(json, 'language') ? undefined : json['language'],
        'currency': !exists(json, 'currency') ? undefined : json['currency'],
        'billingCode': !exists(json, 'billing_code') ? undefined : json['billing_code'],
        'correspondenceAddress': !exists(json, 'correspondence_address') ? undefined : json['correspondence_address'],
        'contact': !exists(json, 'contact') ? undefined : json['contact'],
        'emailForInvocing': !exists(json, 'email_for_invocing') ? undefined : json['email_for_invocing'],
        'identificationNumber': !exists(json, 'identification_number') ? undefined : json['identification_number'],
        'vatNumber': !exists(json, 'vat_number') ? undefined : json['vat_number'],
        'taxNumber': !exists(json, 'tax_number') ? undefined : json['tax_number'],
        'bankingConnection': !exists(json, 'banking_connection') ? undefined : json['banking_connection'],
        'timeZone': !exists(json, 'time_zone') ? undefined : json['time_zone'],
        'deleted': json['deleted'],
        'languageIso6391': !exists(json, 'language_iso_639_1') ? undefined : json['language_iso_639_1'],
        'isPartner': !exists(json, 'is_partner') ? undefined : json['is_partner'],
        'styles': !exists(json, 'styles') ? undefined : json['styles'],
        'logo': !exists(json, 'logo') ? undefined : json['logo'],
        'metadata': !exists(json, 'metadata') ? undefined : json['metadata'],
        'costPerKm': !exists(json, 'cost_per_km') ? undefined : json['cost_per_km'],
        'country': !exists(json, 'country') ? undefined : json['country'],
        'externalSystem': !exists(json, 'external_system') ? undefined : json['external_system'],
        'referrer': !exists(json, 'referrer') ? undefined : json['referrer'],
        'modules': !exists(json, 'modules') ? undefined : json['modules'],
    };
}

export function AddressClientToJSON(value?: AddressClient | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'external_id': value.externalId,
        'name': value.name,
        'address': value.address,
        'field_of_activity': value.fieldOfActivity,
        'language': value.language,
        'currency': value.currency,
        'billing_code': value.billingCode,
        'correspondence_address': value.correspondenceAddress,
        'contact': value.contact,
        'email_for_invocing': value.emailForInvocing,
        'identification_number': value.identificationNumber,
        'vat_number': value.vatNumber,
        'tax_number': value.taxNumber,
        'banking_connection': value.bankingConnection,
        'time_zone': value.timeZone,
        'deleted': value.deleted,
        'language_iso_639_1': value.languageIso6391,
        'is_partner': value.isPartner,
        'styles': value.styles,
        'logo': value.logo,
        'metadata': value.metadata,
        'cost_per_km': value.costPerKm,
        'country': value.country,
        'external_system': value.externalSystem,
        'referrer': value.referrer,
    };
}


