import { Role } from './types';

export function loggedInRoles() {
    return [
        /** driver management read */
        Role.D_R,
        /** driver management write */
        Role.D_W,
        /** driver management import/export */
        Role.D_IE,
        /** vehicle management read */
        Role.V_R,
        /** vehicle management write */
        Role.V_W,
        /** vehicle management import/export */
        Role.V_IE,
        /** poi management read */
        Role.POI_R,
        /** poi management write */
        Role.POI_W,
        /** poi management import/export */
        Role.POI_IE,
        /** user management read */
        Role.USR_R,
        /** user management write */
        Role.USR_W,
        /** user management import/export */
        Role.USR_IE,
        /** live map read */
        Role.LM_R,
        /** live tracking table view  */
        Role.LT_R,
        /** fuel station read */
        Role.FST_R,
        /** telematic dashboard read */
        Role.DASH_R,
        /** telematic dashboard write */
        Role.DASH_W,
        /** transport planer read - used for planing transports */
        Role.PLN_R,
        /** route overview read */
        Role.ROW_R,
        /** eta and rta parameter read - used for controling if eta and rta will be shown in transport */
        Role.ETA_R,
        /**vehicle profile read */
        Role.VP_R,
        /** vehicle profile write - used for editing vehicle profiles */
        Role.VR_W,
        /** dispatcher board */
        Role.BRD_R,
        /** online prices at fuel stations */
        Role.OP_R,
        /** alarms and notifications - Alarm &Notifications(0 nezobrazovat,  1 Zobrazovat) */
        Role.AN_R,
        /** live messaging text messages */
        Role.LMM_R,
        /** live messaging voice messages */
        Role.LMT_R,
        /** instant Alarms (read(default)) */
        Role.IA_R
    ];
}
