import { useRef, useState } from 'react';
import { Button, Dropdown, Menu } from 'components/base/controls';
import { Modal } from 'components/base/layout';
import QRCode from 'qrcode.react';
import { useTranslation } from 'react-i18next';

interface Props {
    shareEtaLink: string;
}

const ShareEtaLink = (props: Props) => {
    const { t } = useTranslation();
    const inputEl = useRef<HTMLInputElement>(null);
    const [linkCopied, setLinkCopied] = useState(false);

    const onCopyClick = () => {
        if (linkCopied) return;

        inputEl.current?.select();
        navigator.clipboard.writeText(props.shareEtaLink);
        setLinkCopied(true);

        setTimeout(() => {
            setLinkCopied(false);
        }, 2000);
    };

    const showQrCode = () => {
        Modal.open({
            width: 500,
            content: (
                <>
                    <p style={{ marginBottom: '40px' }}>{t('ShareEtaLink.qrTitle')}</p>
                    <QRCode value={props.shareEtaLink} size={256} />
                </>
            )
        });
    };

    return (
        <div className="rl-share-eta-link-input-wrapper">
            <input readOnly value={props.shareEtaLink} placeholder={t('ShareEtaLink.inputPlaceholder')} ref={inputEl} />
            <div className="rl-share-eta-link-input-btns">
                <Button type={linkCopied ? 'primary' : 'dark'} onClick={onCopyClick} disabled={!props.shareEtaLink}>
                    {linkCopied ? t('ShareEtaLink.linkCopied') : t('ShareEtaLink.copyLink')}
                </Button>
                <Dropdown
                    overlay={
                        <Menu>
                            <Menu.Item key="2" onClick={showQrCode} disabled={!props.shareEtaLink}>
                                {t('ShareEtaLink.viewQrCode')}
                            </Menu.Item>
                        </Menu>
                    }
                    trigger={['click']}
                    arrow
                    placement="bottom"
                >
                    <Button className="more-btn">
                        <i className="fas fa-ellipsis-h" />
                    </Button>
                </Dropdown>
            </div>
        </div>
    );
};

export default ShareEtaLink;
