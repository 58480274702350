import { useTranslation } from 'react-i18next';
import { Col, Form, Row } from 'antd';
import selectors from 'qa-selectors';
import { Select } from 'components/base/controls';
import { TableViewFilterModel, TableViewFilterSelected } from '../../TableViewModule';
import { useEffect } from 'react';
import { DefaultOptionType } from 'antd/lib/select';

interface Props {
    filter: TableViewFilterModel;
    onFilterChange?: (filter: TableViewFilterSelected) => void;
}

const TableViewFilter = (props: Props) => {
    const { t } = useTranslation();
    const [form] = Form.useForm<TableViewFilterSelected>();

    useEffect(() => {
        form.setFieldsValue(props.filter.selected);
    }, [props.filter.selected]);

    const onValuesChange = (values: TableViewFilterSelected) => {
        props.onFilterChange?.(values);
    };

    const filterOption = (input: string, option?: DefaultOptionType) =>
        (option?.label?.toString() ?? '').toLowerCase().includes(input.toLowerCase());

    return (
        <Form<TableViewFilterSelected>
            form={form}
            id="tableViewFilterForm"
            layout="vertical"
            name="tableViewFilterForm"
            className="rl-form rl-table-view-filter"
            onValuesChange={(_, values) => onValuesChange(values)}
        >
            <Row gutter={[10, 20]}>
                <Col span={24} lg={8}>
                    <Form.Item label={t('RouteOverviewTable.filter.states')} name="states">
                        <Select
                            qa={selectors.dispatcherBoard.table.filter.states}
                            mode="multiple"
                            allowClear
                            className="rl-table-view-filter-select"
                            filterOption={filterOption}
                        >
                            {Object.keys(props.filter.data.states).map((v, i) => (
                                <Select.Option key={i} value={v} label={t(`common.transportStates.${v}`)}>
                                    {t(`common.transportStates.${v}`)}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={24} lg={8}>
                    <Form.Item label={t('RouteOverviewTable.filter.drivers')} name="drivers">
                        <Select
                            qa={selectors.dispatcherBoard.table.filter.drivers}
                            mode="multiple"
                            allowClear
                            className="rl-table-view-filter-select"
                            filterOption={filterOption}
                        >
                            {Object.keys(props.filter.data.drivers).map((v, i) => (
                                <Select.Option key={i} value={v} label={props.filter.data.drivers[v].label}>
                                    {props.filter.data.drivers[v].label}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={24} lg={8}>
                    <Form.Item label={t('RouteOverviewTable.filter.vehicles')} name="vehicles">
                        <Select
                            qa={selectors.dispatcherBoard.table.filter.vehicles}
                            mode="multiple"
                            allowClear
                            className="rl-table-view-filter-select"
                            filterOption={filterOption}
                        >
                            {Object.keys(props.filter.data.vehicles).map((v, i) => (
                                <Select.Option key={i} value={v} label={props.filter.data.vehicles[v].label}>
                                    {props.filter.data.vehicles[v].label}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};

export default TableViewFilter;
