/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WriteOnlyBundleSerializer
 */
export interface WriteOnlyBundleSerializer {
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyBundleSerializer
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyBundleSerializer
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyBundleSerializer
     */
    oneTimeFee?: string;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyBundleSerializer
     */
    monthlyFee?: string;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyBundleSerializer
     */
    description?: string | null;
    /**
     * 
     * @type {number}
     * @memberof WriteOnlyBundleSerializer
     */
    trial?: number;
    /**
     * 
     * @type {number}
     * @memberof WriteOnlyBundleSerializer
     */
    currency: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof WriteOnlyBundleSerializer
     */
    services: Array<string>;
}

export function WriteOnlyBundleSerializerFromJSON(json: any): WriteOnlyBundleSerializer {
    return WriteOnlyBundleSerializerFromJSONTyped(json, false);
}

export function WriteOnlyBundleSerializerFromJSONTyped(json: any, ignoreDiscriminator: boolean): WriteOnlyBundleSerializer {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': json['name'],
        'oneTimeFee': !exists(json, 'one_time_fee') ? undefined : json['one_time_fee'],
        'monthlyFee': !exists(json, 'monthly_fee') ? undefined : json['monthly_fee'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'trial': !exists(json, 'trial') ? undefined : json['trial'],
        'currency': json['currency'],
        'services': json['services'],
    };
}

export function WriteOnlyBundleSerializerToJSON(value?: WriteOnlyBundleSerializer | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'one_time_fee': value.oneTimeFee,
        'monthly_fee': value.monthlyFee,
        'description': value.description,
        'trial': value.trial,
        'currency': value.currency,
        'services': value.services,
    };
}


