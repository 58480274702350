import cn from 'classnames';
import { EditorState } from 'draft-js';
import { MouseEvent } from 'react';

interface Props {
    editorState: EditorState;
    onToggleColor: (value: string) => void;
}

const RichEditorColors = (props: Props) => {
    const currentStyle = props.editorState.getCurrentInlineStyle();

    const colors = [
        { name: 'black', value: '#394556' },
        { name: 'red', value: '#F94242' },
        { name: 'blue', value: '#0D87CC' },
        { name: 'green', value: '#14B710' },
        { name: 'orange', value: '#F8620D' },
        { name: 'violet', value: '#D705FA' }
    ];

    const _onToggleColor = (e: MouseEvent<HTMLSpanElement>, color: string) => {
        e.stopPropagation();
        e.preventDefault();
        props.onToggleColor(color);
    };

    return (
        <div className="rl-rich-editor-colors">
            {colors.map((color, index) => (
                <div
                    className={cn('rl-rich-editor-colors-item', {
                        active: currentStyle.has(color.name)
                    })}
                    key={index}
                    style={{
                        background: color.value,
                        boxShadow: `0 0 0 2px ${color.value}`
                    }}
                    onMouseDown={e => _onToggleColor(e, color.name)}
                />
            ))}
        </div>
    );
};

export default RichEditorColors;
