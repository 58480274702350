/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReadOnlyUserRole
 */
export interface ReadOnlyUserRole {
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyUserRole
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof ReadOnlyUserRole
     */
    readonly deleted?: number;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyUserRole
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyUserRole
     */
    label: string;
    /**
     * 
     * @type {string}
     * @memberof ReadOnlyUserRole
     */
    description: string;
    /**
     * 
     * @type {Date}
     * @memberof ReadOnlyUserRole
     */
    readonly createdAt?: Date;
    /**
     * 
     * @type {number}
     * @memberof ReadOnlyUserRole
     */
    client?: number | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ReadOnlyUserRole
     */
    userRights: Array<string>;
}

export function ReadOnlyUserRoleFromJSON(json: any): ReadOnlyUserRole {
    return ReadOnlyUserRoleFromJSONTyped(json, false);
}

export function ReadOnlyUserRoleFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReadOnlyUserRole {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'deleted': !exists(json, 'deleted') ? undefined : json['deleted'],
        'name': json['name'],
        'label': json['label'],
        'description': json['description'],
        'createdAt': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
        'client': !exists(json, 'client') ? undefined : json['client'],
        'userRights': json['user_rights'],
    };
}

export function ReadOnlyUserRoleToJSON(value?: ReadOnlyUserRole | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'label': value.label,
        'description': value.description,
        'client': value.client,
        'user_rights': value.userRights,
    };
}


