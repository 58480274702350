/* tslint:disable */
/* eslint-disable */
/**
 * Telematics API
 * Telematics services API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MessagePayload
 */
export interface MessagePayload {
    /**
     * 
     * @type {string}
     * @memberof MessagePayload
     */
    type: MessagePayloadTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof MessagePayload
     */
    url: string;
    /**
     * 
     * @type {string}
     * @memberof MessagePayload
     */
    thumb?: string | null;
}

export function MessagePayloadFromJSON(json: any): MessagePayload {
    return MessagePayloadFromJSONTyped(json, false);
}

export function MessagePayloadFromJSONTyped(json: any, ignoreDiscriminator: boolean): MessagePayload {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': json['type'],
        'url': json['url'],
        'thumb': !exists(json, 'thumb') ? undefined : json['thumb'],
    };
}

export function MessagePayloadToJSON(value?: MessagePayload | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'url': value.url,
        'thumb': value.thumb,
    };
}

/**
* @export
* @enum {string}
*/
export enum MessagePayloadTypeEnum {
    Image = 'image',
    File = 'file',
    Audio = 'audio'
}


