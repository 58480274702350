import { Col, Form, Row } from 'antd';
import { Button, Input } from 'components/base/controls';
import { useTranslation } from 'react-i18next';
import selectors from 'qa-selectors';

interface Props {
    loading?: boolean;
    isMobileWidth: boolean;
    onSubmit: (values: LoginFormModel) => void;
    onRedirectLogin: (provider: 'google' | 'facebook') => void;
    onRegisterClick: () => void;
    onForgotPasswordClick: () => void;
}

export interface LoginFormModel {
    email: string;
    password: string;
}

export default function LoginForm(props: Props) {
    const { t } = useTranslation();
    const [form] = Form.useForm<LoginFormModel>();

    function _onFinish(formValues: LoginFormModel) {
        props.onSubmit(formValues);
    }

    return (
        <div className="rl-login-form rl-modal-with-carousel-form">
            <Form<LoginFormModel>
                form={form}
                id="loginForm"
                layout="vertical"
                name="loginForm"
                requiredMark={false}
                className="rl-login-form-content"
                onFinish={_onFinish}
            >
                <p>{t('LoginFormModal.infoText')}</p>

                <Row>
                    <Col span="24">
                        <Form.Item
                            label={t('common.email')}
                            name="email"
                            rules={[{ required: true, type: 'email' }]}
                            validateTrigger="onBlur"
                        >
                            <Input
                                autoComplete={'username'}
                                placeholder={t('LoginFormModal.emailPlaceholder')}
                                data-qa={selectors.loginModal.loginForm.emailInput}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row>
                    <Col span="24">
                        <Form.Item
                            label={
                                <span className="password-label">
                                    <span>{t('common.password')}</span>
                                    <Button type="link" onClick={props.onForgotPasswordClick} tabIndex={-1}>
                                        {t('LoginFormModal.forgotPassword')}
                                    </Button>
                                </span>
                            }
                            name="password"
                            rules={[{ required: true }]}
                        >
                            <Input
                                type="password"
                                placeholder={t('common.password')}
                                autoComplete={'current-password'}
                                data-qa={selectors.loginModal.loginForm.passwordInput}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row>
                    <Col span="24">
                        <Button
                            type="primary"
                            size={props.isMobileWidth ? 'middle' : 'large'}
                            htmlType="submit"
                            loading={props.loading ? { delay: 500 } : false}
                            style={{ width: '100%' }}
                            data-qa={selectors.loginModal.loginForm.signInButton}
                        >
                            {t('common.signIn')}
                        </Button>
                    </Col>
                </Row>

                <div className="or-separator">{t('common.or')}</div>

                <Row style={{ marginBottom: '16px' }}>
                    <Col span="24">
                        <Button
                            className="google-btn"
                            size={props.isMobileWidth ? 'middle' : 'large'}
                            icon={<img className="rl-button-icon" src="img/login-modal/google.svg" alt="Google icon" />}
                            onClick={() => props.onRedirectLogin('google')}
                        >
                            {t('LoginFormModal.googleSignIn')}
                        </Button>
                    </Col>
                </Row>

                <Row>
                    <Col span="24">
                        <Button
                            className="facebook-btn"
                            type="primary"
                            size={props.isMobileWidth ? 'middle' : 'large'}
                            icon={<i className="rl-button-icon fab fa-facebook-f" />}
                            onClick={() => props.onRedirectLogin('facebook')}
                        >
                            {t('LoginFormModal.facebookSignIn')}
                        </Button>
                    </Col>
                </Row>
            </Form>
            <div className="rl-login-form-footer">
                <div className="rl-login-form-footer-title">{t('LoginFormModal.footerTitle')}</div>
                <div className="rl-login-form-footer-text">{t('LoginFormModal.footerText')}</div>
                <Button type="dark" onClick={props.onRegisterClick} size={props.isMobileWidth ? 'middle' : 'large'}>
                    {t('LoginFormModal.footerButton')}
                </Button>
            </div>
        </div>
    );
}
