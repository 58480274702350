import { Modal } from 'components/base/layout';
import { Col, Row } from 'antd';
import { FeaturesCarousel } from 'components/FeaturesCarousel';
import { RegisterForm, RegisterFormModel } from '../RegisterForm';
import selectors from 'qa-selectors';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import { Button } from 'components/base/controls';
import { useTranslation } from 'react-i18next';

interface Props {
    visible?: boolean;
    loading?: boolean;
    recaptchaSiteKey: string;
    onCancel?: () => void;
    onRegisterClick: (values: RegisterFormModel) => void;
    onSignInClick: () => void;
    onRedirectRegister: (provider: 'google' | 'facebook') => void;
}

function RegisterModal(props: Props) {
    const { t } = useTranslation();
    const { isMobileWidth, windowDimensions } = useWindowDimensions();

    return (
        <Modal
            className="rl-modal-with-carousel"
            visible={props.visible}
            mask={!isMobileWidth}
            width={isMobileWidth ? windowDimensions.width : 944}
            destroyOnClose={true}
            onCancel={props.onCancel}
        >
            <div className="rl-register-modal-content" data-qa={selectors.registerModal.registerModal}>
                <Row>
                    <Col span={24} md={12}>
                        <div className="rl-register-modal-content-header">
                            <Button
                                type="action"
                                icon={<i className="rl-icon-chevron-left" />}
                                onClick={props.onCancel}
                            />
                            <h3>{t('RegisterFormModal.title')}</h3>
                        </div>

                        <RegisterForm
                            loading={props.loading}
                            recaptchaSiteKey={props.recaptchaSiteKey}
                            isMobileWidth={isMobileWidth}
                            onSubmit={props.onRegisterClick}
                            onSignInClick={props.onSignInClick}
                            onRedirectRegister={props.onRedirectRegister}
                        />
                    </Col>
                    <Col span={0} md={12}>
                        <div className="rl-modal-with-carousel-features">
                            <FeaturesCarousel />
                        </div>
                    </Col>
                </Row>
            </div>
        </Modal>
    );
}

export default RegisterModal;
