import axios from 'axios';
import axiosRetry, { IAxiosRetryConfig } from 'axios-retry';
import { AuthStore } from 'stores/auth/AuthStore';

interface RequestOptions {
    headers?: { [key: string]: string };
    apiVersion?: string;
    params?: any;
    timeout?: number;
    signal?: AbortSignal;
    'axios-retry'?: IAxiosRetryConfig;
}

class HttpClient {
    constructor(private _auth: AuthStore) {
        axiosRetry(axios, { retries: 2 });

        this._initAuthInterceptor();
    }

    async get<T>(url: string, options?: RequestOptions) {
        return axios
            .get<T>(url, {
                ...options,
                headers: this._buildHeaders(options),
                timeout: options?.timeout
            })
            .then(resp => resp.data);
    }

    async post<T>(url: string, data: any, options?: RequestOptions) {
        return axios
            .post<T>(url, data, {
                ...options,
                headers: this._buildHeaders(options)
            })
            .then(resp => resp.data);
    }

    async put<T>(url: string, data: any, options?: RequestOptions) {
        return axios
            .put<T>(url, data, {
                ...options,
                headers: {
                    'Content-Type': 'application/json-put+json',
                    ...this._buildHeaders(options)
                }
            })
            .then(resp => resp.data);
    }

    async patch<T>(url: string, data: any, options?: RequestOptions) {
        return axios
            .patch<T>(url, data, {
                ...options,
                headers: {
                    'Content-Type': 'application/json-patch+json',
                    ...this._buildHeaders(options)
                }
            })
            .then(resp => resp.data);
    }

    async delete<T>(url: string, data?: any, options?: RequestOptions) {
        return axios
            .delete<T>(url, {
                ...options,
                headers: {
                    'Content-Type': 'application/json-delete+json',
                    ...this._buildHeaders(options)
                },
                data
            })
            .then(resp => resp.data);
    }

    private _buildHeaders(options?: RequestOptions) {
        return {
            Authorization: `Bearer ${this._auth.token}`,
            'Accept-Version': options?.apiVersion || '',
            ...options?.headers
        };
    }

    private _initAuthInterceptor() {
        axios.interceptors.response.use(undefined, async (error: any) => {
            const originalRequest = error.config;

            if (originalRequest && error.response) {
                // 401 response code
                if (error.response.status === 401) {
                    if (!originalRequest._retry) {
                        originalRequest._retry = 1;

                        return this._auth.updateToken().then(token => {
                            originalRequest.headers['Authentication'] = `Bearer ${token}`;
                            return axios.request(originalRequest);
                        });
                    } else if (originalRequest._retry === 1) {
                        // if second attempt failed try to login with client credentials
                        originalRequest._retry = 2;
                        await this._auth.login();
                    }
                }

                // 409 response code
                if (error.response.status === 409) {
                    const errorContent = error.response.data;
                    if (errorContent?.errorCode === 8) {
                        console.warn(`[HTTP Service] User will be logged out cause: ${errorContent?.message}`);
                        await this._auth.login();
                    }
                }

                // 403 response code
                if (error.response.status === 403) {
                    if (!originalRequest.url.includes('/company/my')) {
                        // hack!, we should not logout when 403 returned on company/my
                        await this._auth.login();
                    }
                }
            }

            return Promise.reject(error);
        });
    }
}

export { HttpClient };
