/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InlineResponse20026,
    InlineResponse20026FromJSON,
    InlineResponse20026ToJSON,
    ReadOnlyOrderSerializer,
    ReadOnlyOrderSerializerFromJSON,
    ReadOnlyOrderSerializerToJSON,
    WriteOnlyOrderSerializer,
    WriteOnlyOrderSerializerFromJSON,
    WriteOnlyOrderSerializerToJSON,
} from '../models';

export interface OrderCreateRequest {
    data: WriteOnlyOrderSerializer;
}

export interface OrderDeleteRequest {
    id: string;
}

export interface OrderListRequest {
    createdAtIexact?: string;
    createdAtGte?: string;
    createdAtLte?: string;
    limit?: number;
    offset?: number;
}

export interface OrderPartialUpdateRequest {
    id: string;
    data: WriteOnlyOrderSerializer;
}

export interface OrderReadRequest {
    id: string;
}

export interface OrderUpdateRequest {
    id: string;
    data: WriteOnlyOrderSerializer;
}

/**
 * no description
 */
export class OrderApi extends runtime.BaseAPI {

    /**
     */
    async orderCreateRaw(requestParameters: OrderCreateRequest): Promise<runtime.ApiResponse<ReadOnlyOrderSerializer>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling orderCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/order/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyOrderSerializerToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyOrderSerializerFromJSON(jsonValue));
    }

    /**
     */
    async orderCreate(requestParameters: OrderCreateRequest): Promise<ReadOnlyOrderSerializer> {
        const response = await this.orderCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async orderDeleteRaw(requestParameters: OrderDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling orderDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/order/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async orderDelete(requestParameters: OrderDeleteRequest): Promise<void> {
        await this.orderDeleteRaw(requestParameters);
    }

    /**
     */
    async orderListRaw(requestParameters: OrderListRequest): Promise<runtime.ApiResponse<InlineResponse20026>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.createdAtIexact !== undefined) {
            queryParameters['created_at__iexact'] = requestParameters.createdAtIexact;
        }

        if (requestParameters.createdAtGte !== undefined) {
            queryParameters['created_at__gte'] = requestParameters.createdAtGte;
        }

        if (requestParameters.createdAtLte !== undefined) {
            queryParameters['created_at__lte'] = requestParameters.createdAtLte;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/order/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20026FromJSON(jsonValue));
    }

    /**
     */
    async orderList(requestParameters: OrderListRequest): Promise<InlineResponse20026> {
        const response = await this.orderListRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async orderPartialUpdateRaw(requestParameters: OrderPartialUpdateRequest): Promise<runtime.ApiResponse<ReadOnlyOrderSerializer>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling orderPartialUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling orderPartialUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/order/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyOrderSerializerToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyOrderSerializerFromJSON(jsonValue));
    }

    /**
     */
    async orderPartialUpdate(requestParameters: OrderPartialUpdateRequest): Promise<ReadOnlyOrderSerializer> {
        const response = await this.orderPartialUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async orderReadRaw(requestParameters: OrderReadRequest): Promise<runtime.ApiResponse<ReadOnlyOrderSerializer>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling orderRead.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/order/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyOrderSerializerFromJSON(jsonValue));
    }

    /**
     */
    async orderRead(requestParameters: OrderReadRequest): Promise<ReadOnlyOrderSerializer> {
        const response = await this.orderReadRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async orderUpdateRaw(requestParameters: OrderUpdateRequest): Promise<runtime.ApiResponse<ReadOnlyOrderSerializer>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling orderUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling orderUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/order/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyOrderSerializerToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyOrderSerializerFromJSON(jsonValue));
    }

    /**
     */
    async orderUpdate(requestParameters: OrderUpdateRequest): Promise<ReadOnlyOrderSerializer> {
        const response = await this.orderUpdateRaw(requestParameters);
        return await response.value();
    }

}
