/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WriteOnlyUser
 */
export interface WriteOnlyUser {
    /**
     * 
     * @type {number}
     * @memberof WriteOnlyUser
     */
    clientId?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof WriteOnlyUser
     */
    userGroupIds: Array<number>;
    /**
     * 
     * @type {Array<string>}
     * @memberof WriteOnlyUser
     */
    userRolesIds: Array<string>;
    /**
     * 
     * @type {Date}
     * @memberof WriteOnlyUser
     */
    lastLogin?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyUser
     */
    ssoId?: string | null;
    /**
     * 
     * @type {object}
     * @memberof WriteOnlyUser
     */
    loginCredentials?: object | null;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyUser
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyUser
     */
    surname: string;
    /**
     * 
     * @type {object}
     * @memberof WriteOnlyUser
     */
    contact?: object | null;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyUser
     */
    note?: string | null;
    /**
     * 
     * @type {number}
     * @memberof WriteOnlyUser
     */
    deleted: number;
    /**
     * 
     * @type {number}
     * @memberof WriteOnlyUser
     */
    active: number;
    /**
     * 
     * @type {number}
     * @memberof WriteOnlyUser
     */
    averageSpeed: number;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyUser
     */
    languageIso6391?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyUser
     */
    password?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyUser
     */
    newPassword?: string | null;
    /**
     * Designates whether this user should be treated as active. Unselect this instead of deleting accounts.
     * @type {boolean}
     * @memberof WriteOnlyUser
     */
    isActive?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof WriteOnlyUser
     */
    isContactPerson?: boolean;
    /**
     * 
     * @type {string}
     * @memberof WriteOnlyUser
     */
    binaryRights?: string | null;
    /**
     * 
     * @type {number}
     * @memberof WriteOnlyUser
     */
    monitoredObjectGroup?: number | null;
}

export function WriteOnlyUserFromJSON(json: any): WriteOnlyUser {
    return WriteOnlyUserFromJSONTyped(json, false);
}

export function WriteOnlyUserFromJSONTyped(json: any, ignoreDiscriminator: boolean): WriteOnlyUser {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'clientId': !exists(json, 'client_id') ? undefined : json['client_id'],
        'userGroupIds': json['user_group_ids'],
        'userRolesIds': json['user_roles_ids'],
        'lastLogin': !exists(json, 'last_login') ? undefined : (json['last_login'] === null ? null : new Date(json['last_login'])),
        'ssoId': !exists(json, 'sso_id') ? undefined : json['sso_id'],
        'loginCredentials': !exists(json, 'login_credentials') ? undefined : json['login_credentials'],
        'name': json['name'],
        'surname': json['surname'],
        'contact': !exists(json, 'contact') ? undefined : json['contact'],
        'note': !exists(json, 'note') ? undefined : json['note'],
        'deleted': json['deleted'],
        'active': json['active'],
        'averageSpeed': json['average_speed'],
        'languageIso6391': !exists(json, 'language_iso_639_1') ? undefined : json['language_iso_639_1'],
        'password': !exists(json, 'password') ? undefined : json['password'],
        'newPassword': !exists(json, 'newPassword') ? undefined : json['newPassword'],
        'isActive': !exists(json, 'is_active') ? undefined : json['is_active'],
        'isContactPerson': !exists(json, 'is_contact_person') ? undefined : json['is_contact_person'],
        'binaryRights': !exists(json, 'binary_rights') ? undefined : json['binary_rights'],
        'monitoredObjectGroup': !exists(json, 'monitored_object_group') ? undefined : json['monitored_object_group'],
    };
}

export function WriteOnlyUserToJSON(value?: WriteOnlyUser | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'client_id': value.clientId,
        'user_group_ids': value.userGroupIds,
        'user_roles_ids': value.userRolesIds,
        'last_login': value.lastLogin === undefined ? undefined : (value.lastLogin === null ? null : value.lastLogin.toISOString()),
        'sso_id': value.ssoId,
        'login_credentials': value.loginCredentials,
        'name': value.name,
        'surname': value.surname,
        'contact': value.contact,
        'note': value.note,
        'deleted': value.deleted,
        'active': value.active,
        'average_speed': value.averageSpeed,
        'language_iso_639_1': value.languageIso6391,
        'password': value.password,
        'newPassword': value.newPassword,
        'is_active': value.isActive,
        'is_contact_person': value.isContactPerson,
        'binary_rights': value.binaryRights,
        'monitored_object_group': value.monitoredObjectGroup,
    };
}


