import cn from 'classnames';
import i18n from 'i18next';
import { notification } from 'antd';
import { ArgsProps } from 'antd/lib/notification';
import { Button } from 'components/base/controls';

const defaultArgs: Partial<ArgsProps> = {
    placement: 'bottomRight',
    closeIcon: <img src="/img/icn-rl-delete.svg" alt="close" />,
    duration: 4.5
};

class Notification {
    public static success(args: ArgsProps) {
        notification.success({
            ...defaultArgs,
            className: cn('rl-notification success', { 'with-description': !!args.description }),
            icon: <i className="fas fa-check-circle rl-notification-icon" />,
            ...args
        });
    }

    public static error(args: ArgsProps) {
        notification.error({
            ...defaultArgs,
            className: cn('rl-notification error', { 'with-description': !!args.description }),
            icon: <i className="fas fa-exclamation-circle rl-notification-icon" />,
            ...args
        });
    }

    public static info(args: ArgsProps) {
        notification.info({
            ...defaultArgs,
            className: cn('rl-notification info', { 'with-description': !!args.description }),
            icon: <i className="fas fa-info-circle rl-notification-icon" />,
            ...args
        });
    }

    public static somethingWentWrong() {
        this.error({
            message: <>{i18n.t('common.error')}</>,
            description: (
                <div className="something-wrong">
                    <>{i18n.t('common.somethingWrong')}</>
                    <Button
                        onClick={() => {
                            window.location.reload();
                        }}
                    >
                        <>{i18n.t('common.reloadPage')}</>
                    </Button>
                </div>
            ),
            duration: null
        });
    }
}

export default Notification;
