import { useState } from 'react';
import { Logic } from 'logic/logic';
import { AboutCompanyFormModel, AboutCompanyModal } from './ui/AboutCompanyModal';
import { useStore } from 'stores/RootStore';
import { observer } from 'mobx-react';

interface Props {
    logic: Logic;
    visible: boolean;
    email: string;
    onAfterAboutCompany?: () => void;
}

export const AboutCompanyModule = observer((props: Props) => {
    const [loading, setLoading] = useState(false);
    const { dispatcherStore } = useStore();

    const _onSubmit = async (formValues: AboutCompanyFormModel) => {
        try {
            const { companyName, fleetSize, newslettersAgreement } = formValues;

            await dispatcherStore.updateDispatcher({ newslettersAgreement });

            await props.logic.company().updateCompany([
                { path: 'name', value: companyName },
                { path: 'fleetSize', value: fleetSize }
            ]);
            
            props.logic.userEvents().newsLetterConsentUpdate(newslettersAgreement);

            props.onAfterAboutCompany?.();
        } catch (error) {
            setLoading(false);
            console.error('Register err:', error);
        }
    };

    return <AboutCompanyModal visible={props.visible} loading={loading} onSubmit={_onSubmit} />;
});
