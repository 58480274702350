import { UserEvent } from 'logic/userEventsProviders/GoogleTagManager';
import { Logic } from 'logic/logic';
import { Draggable, Droppable } from '@hello-pangea/dnd';
import { useTranslation } from 'react-i18next';
import { Country } from 'services/api/domains/CountryApi';
import { CalendarViewBar, CalendarViewDragType } from '../../CalendarViewModule';
import CalendarViewTransport from '../CalendarViewTransport/CalendarViewTransport';
import cn from 'classnames';
import { CompanyVehicle, VehicleStateObject } from 'services/api/domains/VehiclesApi';
import { TransportModel } from 'logic/dispatcher-board';
import { Button } from 'components/base/controls';

interface Props {
    logic: Logic;
    countryList: Country[];
    bar: CalendarViewBar;
    otherTransports: TransportModel[];
    rows: CompanyVehicle[];
    startDate: string;
    vehicles: VehicleStateObject[];
    transportDetailId?: string;
    onTransportEditClick: (transportId: string, vehicleId?: string) => void;
    onTransportDetailClick: (transport: TransportModel) => void;
    onAssignDriverToVehicle: (vehicle: CompanyVehicle) => void;
    onOpenDeleteTransportModal: (transport: TransportModel) => void;
    onOpenAssignVehicleModal: (transport: TransportModel) => void;
    onUnassignTransport: (transportId: string) => void;
    onTransportNoteChange: (transportId: string, note: string, cb: VoidFunction) => void;
    onTransportNoteEvent: (event: UserEvent, transportId: string, vehicleId?: number) => void;
    onLocalizeVehicle?: (vehicleId?: string) => void;
}

const CalendarViewGridAssigned = (props: Props) => {
    const { t } = useTranslation();

    return (
        <Droppable key={`vehicles-droppable`} droppableId={`vehicles-droppable`} type={CalendarViewDragType.Vehicles}>
            {provided1 => (
                <div className="rl-calendar-view-grid-assigned" ref={provided1.innerRef} {...provided1.droppableProps}>
                    {props.rows.map((row, index) => {
                        const vehicleState = props.vehicles.filter(
                            v => Number(v.monitoredObjectId) === row.vehicleId
                        )[0];

                        const vehicleTransports = props.logic
                            .dispatcherBoard()
                            .transportBoardModel(
                                props.otherTransports.filter(t => t.vehicleId && Number(t.vehicleId) === row.vehicleId)
                            );

                        return (
                            <div key={index} className="rl-calendar-view-grid-assigned-wrap">
                                <Draggable key={row.vehicleId!} draggableId={row.vehicleId!.toString()} index={index}>
                                    {provided2 => (
                                        <div
                                            className="rl-calendar-view-grid-assigned-transports"
                                            key={row.vehicleId}
                                            ref={provided2.innerRef}
                                            {...provided2.draggableProps}
                                        >
                                            <div className="rl-calendar-view-grid-assigned-vehicle">
                                                <div
                                                    className="rl-calendar-view-grid-assigned-vehicle-drag"
                                                    {...provided2.dragHandleProps}
                                                >
                                                    <i className="rl-icon-drag-handler" />
                                                </div>
                                                <div className="rl-calendar-view-grid-assigned-vehicle-vehiclern">
                                                    <div className="rl-calendar-view-grid-assigned-vehicle-content">
                                                        <i className="rl-icon-truck" />
                                                        {row.registrationNumber}
                                                    </div>
                                                </div>
                                                {/* <div className="rl-calendar-view-grid-assigned-vehicle-trailerrn">
                                                <div className="rl-calendar-view-grid-assigned-vehicle-content">
                                                    <i className="rl-icon-truck" />
                                                    {row.trailersCount}
                                                </div>
                                            </div> */}
                                                <div
                                                    className={cn('rl-calendar-view-grid-assigned-vehicle-driver', {
                                                        assign: !vehicleState?.driver?.name
                                                    })}
                                                >
                                                    <span onClick={() => props.onAssignDriverToVehicle!(row)}>
                                                        {vehicleState?.driver?.name
                                                            ? vehicleState.driver.name
                                                            : t('DispatcherBoardVehicles.assignDriver')}
                                                    </span>

                                                    {vehicleState?.driver?.name && (
                                                        <Button
                                                            type="icon"
                                                            onClick={() =>
                                                                props.onLocalizeVehicle?.(row.vehicleId?.toString())
                                                            }
                                                        >
                                                            <i className="rl-icon-map-pin" />
                                                        </Button>
                                                    )}
                                                </div>
                                            </div>
                                            <Droppable
                                                key={row.vehicleId}
                                                droppableId={`${row.vehicleId}`}
                                                type={CalendarViewDragType.Transports}
                                            >
                                                {(provided3, snapshot3) => (
                                                    <div
                                                        className={cn('rl-calendar-view-grid-assigned-transports-row', {
                                                            'rl-calendar-view-grid-assigned-transports-row-drag':
                                                                snapshot3.isDraggingOver
                                                        })}
                                                        ref={provided3.innerRef}
                                                        {...provided3.droppableProps}
                                                    >
                                                        {vehicleTransports.map((transports, index) => (
                                                            <div
                                                                className="rl-calendar-view-grid-assigned-transports-row-item"
                                                                key={index}
                                                            >
                                                                {transports?.map(transport => (
                                                                    <CalendarViewTransport
                                                                        countryList={props.countryList}
                                                                        key={transport.id}
                                                                        bar={props.bar}
                                                                        startDate={props.startDate}
                                                                        transport={transport}
                                                                        vehicle={row}
                                                                        vehicleState={vehicleState}
                                                                        transportDetailId={props.transportDetailId}
                                                                        firstTransportPopoverVisible={false}
                                                                        onTransportEditClick={
                                                                            props.onTransportEditClick
                                                                        }
                                                                        onOpenDeleteTransportModal={
                                                                            props.onOpenDeleteTransportModal
                                                                        }
                                                                        onOpenAssignVehicleModal={
                                                                            props.onOpenAssignVehicleModal
                                                                        }
                                                                        onUnassignTransport={props.onUnassignTransport}
                                                                        onTransportNoteChange={
                                                                            props.onTransportNoteChange
                                                                        }
                                                                        onTransportNoteEvent={
                                                                            props.onTransportNoteEvent
                                                                        }
                                                                        onTransportDetailClick={
                                                                            props.onTransportDetailClick
                                                                        }
                                                                    />
                                                                ))}
                                                            </div>
                                                        ))}
                                                        {provided3.placeholder}
                                                    </div>
                                                )}
                                            </Droppable>
                                        </div>
                                    )}
                                </Draggable>
                            </div>
                        );
                    })}
                    {provided1.placeholder}
                </div>
            )}
        </Droppable>
    );
};

export default CalendarViewGridAssigned;
