import { Button, Popover } from 'components/base/controls';
import { ReactNode } from 'react';
import { Trans, useTranslation } from 'react-i18next';

interface Props {
    visible: boolean;
    children?: ReactNode;
    onTransportPopoverClose?: () => void;
}

const CalendarViewTransportPopover = (props: Props) => {
    const { t } = useTranslation();

    return (
        <Popover
            content={
                <div className="rl-calendar-view-transport-popover" onClick={e => e.stopPropagation()}>
                    <div className="rl-calendar-view-transport-popover-title">
                        {t('DispatcherBoardTransport.popover.title')}
                        <Button type="icon" onClick={() => props.onTransportPopoverClose?.()}>
                            <i className="rl-icon-close" />
                        </Button>
                    </div>
                    <div className="rl-calendar-view-transport-popover-content">
                        <Trans i18nKey="DispatcherBoardTransport.popover.text">
                            <p>To drag it simply press and hold down the left mouse button.</p>
                            <p>Double click on transport card opens transport detail.</p>
                        </Trans>
                    </div>
                </div>
            }
            destroyTooltipOnHide={true}
            placement={'rightTop'}
            visible={props.visible}
            color={'#9CDBFF'}
        >
            {props.children}
        </Popover>
    );
};

export default CalendarViewTransportPopover;
