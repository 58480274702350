import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Logic } from 'logic/logic';
import { Notification } from 'components/base/layout';
import { RegisterModal } from './ui/RegisterModal';
import { RegisterFormModel } from './ui/RegisterForm';
import { useState } from 'react';
import { useStore } from 'stores';

interface Props {
    logic: Logic;
    visible: boolean;
    onCancel?: () => void;
    afterRegister?: (email?: string) => void;
}

export default function RegisterModule(props: Props) {
    const history = useHistory();
    const { t } = useTranslation();
    const { authStore, appStore, userSettingsStore } = useStore();
    const [loading, setLoading] = useState(false);

    const onRegister = async (formValues: RegisterFormModel) => {
        setLoading(true);

        try {
            const { email, password, recaptchaToken } = formValues;

            await props.logic
                .apiService()
                .users()
                .registerUser(email, password, userSettingsStore.lang, recaptchaToken);
            props.logic.userEvents().registration();

            await authStore?.login(email, password);
            await props.logic.company().fetchCompany();
            appStore?.setIsLoggedIn(true);

            props.afterRegister?.(email);
        } catch (err) {
            console.error('Register err:', err);
            await authStore?.logout();

            const errMessage =
                err.status === 409 || err.response?.status === 409
                    ? t('authorization.user.alreadyExists')
                    : t('authorization.registrationFailed');

            Notification.error({ message: errMessage });
        } finally {
            setLoading(false);
        }
    };

    const registerWithRedirect = async (provider: 'google' | 'facebook') => {
        setLoading(true);

        try {
            const searchParams = new URLSearchParams(history.location.search);
            searchParams.delete('register');

            history.push({ search: searchParams.toString() });

            await authStore?.loginWithRedirect?.(provider);
        } catch (err) {
            console.error('Register err: ', err);
            Notification.error({
                message: err.message
            });
        } finally {
            setLoading(false);
        }
    };

    const openLoginModal = () => {
        history.push({ search: '?login=true' });
    };

    return (
        <RegisterModal
            visible={props.visible}
            loading={loading}
            recaptchaSiteKey={props.logic.conf.monitoring.recaptcha.siteKey}
            onCancel={props.onCancel}
            onRegisterClick={onRegister}
            onSignInClick={openLoginModal}
            onRedirectRegister={registerWithRedirect}
        />
    );
}
