import { ReactNode } from 'react';
import { Select } from 'antd';
import { SelectProps, SelectValue } from 'antd/lib/select';

interface Props<T extends SelectValue = SelectValue> extends SelectProps<T> {
    children: ReactNode;
    qa?: string;
}

function EnhancedSelect<T extends SelectValue = SelectValue>(props: Props<T>) {
    return (
        <Select
            {...props}
            data-qa={props.qa}
            removeIcon={<i className="rl-icon-close" />}
            maxTagCount="responsive"
            className={props.className ? props.className + ' rl-select' : 'rl-select'}
        >
            {props.children}
        </Select>
    );
}

EnhancedSelect.Option = Select.Option;
EnhancedSelect.OptGroup = Select.OptGroup;

export default EnhancedSelect;
