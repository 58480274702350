import { TrackingModel } from 'modules/TrackingModule/TrackingModule';
import { useTranslation } from 'react-i18next';

export enum TrackingStatus {
    NO_TRANSPORT,
    ASSIGNED,
    ACTIVE,
    DELAYED
}

interface Props {
    model?: TrackingModel;
    extended: boolean;
}

export const TransportStatusIndicator = (props: Props) => {
    const { t } = useTranslation();

    if (props.model?.trackingTransport.status === TrackingStatus.ACTIVE) {
        return (
            <span className="rl-transport-status-indicator rl-transport-status-indicator-active">
                <span className="status-dot status-dot-active" />
                {props.extended && t('TrackingTable.transportStatus.active')}
            </span>
        );
    } else if (props.model?.trackingTransport.status === TrackingStatus.ASSIGNED) {
        return (
            <span className="rl-transport-status-indicator rl-transport-status-indicator-assigned">
                <span className="status-dot status-dot-assigned" />
                {props.extended && t('TrackingTable.transportStatus.assigned')}
            </span>
        );
    } else if (props.model?.trackingTransport.status === TrackingStatus.DELAYED) {
        return (
            <span className="rl-transport-status-indicator rl-transport-status-indicator-delayed">
                <span className="status-dot status-dot-delayed" />
                {props.extended && t('TrackingTable.transportStatus.delayed')}
            </span>
        );
    } else {
        return (
            <span className="rl-transport-status-indicator rl-transport-status-indicator-no_assigned">
                <span className="status-dot status-dot-no_assigned" />
                {props.extended && t('TrackingTable.transportStatus.noAssigned')}
            </span>
        );
    }
};
