import { useTranslation } from 'react-i18next';
import {
    EmissionClassType,
    FuelType,
    HazardLoadType,
    TunnelType,
    VehicleProfile
} from 'services/api/domains/VehiclesApi';

interface Props {
    vehicle: VehicleProfile;
}

export function VehicleTooltip(props: Props) {
    const { t } = useTranslation();
    const translateKey = 'routing.configurator.select.vehicleTooltip';
    const vehicle = props.vehicle;

    const coef = 1000;

    return (
        <div className="rl-planner-vehicle-tooltip">
            <div style={{ marginBottom: 8 }}>
                <span>{t(`${translateKey}.routingParams`, { name: vehicle.name })}</span>
            </div>

            <div>
                <span>{t(`${translateKey}.weight`)}</span>: {vehicle.totalWeight / coef}t
            </div>
            <div>
                <span>{t(`${translateKey}.length`)}</span>: {vehicle.totalLength / coef}m
            </div>
            <div>
                <span>{t(`${translateKey}.width`)}</span>: {vehicle.width / coef}m
            </div>
            <div>
                <span>{t(`${translateKey}.heigth`)}</span>: {vehicle.height / coef}m
            </div>
            <div>
                <span>{t(`${translateKey}.axleWeight`)}</span>: {vehicle.axleWeight / coef}t
            </div>

            <div style={{ marginBottom: 8 }}>
                {vehicle.vehicleAxles && (
                    <>
                        <span>{t(`${translateKey}.vehicleAxles`)}</span>: {vehicle.vehicleAxles}
                    </>
                )}
            </div>
            {vehicle.fuelType && (
                <div>
                    <span>{t(`${translateKey}.fuelType`)}</span>: {FuelType[vehicle.fuelType]}
                </div>
            )}
            {vehicle.emissionClass && (
                <div>
                    <span>{t(`${translateKey}.emissionsClass`)}</span>: {EmissionClassType[vehicle.emissionClass]}
                </div>
            )}
            <div>
                <span>{t(`${translateKey}.tunnelCode`)}</span>: {TunnelType[vehicle.tunnelCode]}
            </div>
            {vehicle.loadType && (
                <div>
                    <span>{t(`${translateKey}.hazardousLoadType`)}</span>: {HazardLoadType[vehicle.loadType]}
                </div>
            )}
        </div>
    );
}
