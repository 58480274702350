import { Rate } from 'antd';
import { Button } from 'components/base/controls';
import { PoiModelMapDiscount, PoiModelMapFuelType } from 'logic/map/logic/fuelStations';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FuelPaymentCard, FuelStationBrandModel, PlaceReviews, PlaceType } from 'services/api/domains/PlacesApi';
import { POI_ICON } from 'utils/constants/constants';
import { PoiDetailFuelGroups } from './ui/PoiDetailFuelGroups/PoiDetailFuelGroups';
import { PoiDetailFuelPrices } from './ui/PoiDetailFuelPrices/PoiDetailFuelPrices';
import { PoiDetailRatings } from './ui/PoiDetailRatings/PoiDetailRatings';
import PoiDetailRecently from './ui/PoiDetailRecently/PoiDetailRecently';
import { BRAND_NAME_TO_ICON } from 'utils/constants/fuelcard-icons';

enum ServiceIcons {
    adblue = 'rl-icon-adblue',
    atm = 'rl-icon-atm',
    coffee = 'rl-icon-coffee',
    fuel = 'rl-icon-fuel-station',
    internet = 'rl-icon-wifi',
    'medical help' = 'rl-icon-drugstore',
    'non-stop' = 'rl-icon-24-7',
    'parking' = 'rl-icon-parking',
    'parking free' = 'rl-icon-free-parking',
    'parking guarded' = 'rl-icon-secured-parking',
    'power supply' = 'rl-icon-electricity',
    restaurant = 'rl-icon-restaurant',
    shop = 'rl-icon-shopping',
    shower = 'rl-icon-shower',
    sleep = 'rl-icon-sleeping',
    toilets = 'rl-icon-wc',
    'toll parking' = 'rl-icon-paid-parking',
    'truck friendly' = 'rl-icon-truck-parking',
    'truck wash' = 'rl-icon-truck-wash',
    'washing machine' = 'rl-icon-washing-machine',
    water = 'rl-icon-water'
}

interface Props {
    pois: PoiDetailModel[];
    onRecentPoiClick: (poi: PoiDetailModel) => void;
    onDeleteRecentPoi: (poi: PoiDetailModel) => void;
    onAddToRoute: (poi: PoiDetailModel) => void;
    onAddFavorite: (poi: PoiDetailModel) => void;
    onDeleteFavorite: (poi: PoiDetailModel) => void;
    onClose: () => void;
}

export interface PoiDetailModel {
    id: string;
    name: string;
    rating?: number;
    address: string;
    services: { name: string; code: string }[];
    coords: { lat: number; lng: number };
    discount?: PoiModelMapDiscount;
    fuelTypes?: PoiModelMapFuelType[];
    parkingSize?: number;
    routeIndex?: number;
    category?: PlaceType;
    brand?: FuelStationBrandModel;
    reviews: PlaceReviews;
    isFavorite: boolean;
    fuelPaymentCards?: FuelPaymentCard[];
}

export function PoiDetail(props: Props) {
    const { t } = useTranslation();
    const poiDetail = props.pois?.[0];
    const [showFullInfo, setShowFullInfo] = useState(false);

    useEffect(() => {
        setShowFullInfo(false);
    }, [poiDetail]);

    return (
        !!poiDetail && (
            <div className="rl-poi-detail">
                <div className="rl-poi-detail-section">
                    <div className="rl-poi-detail-header">
                        {poiDetail?.brand ? (
                            <img src={poiDetail.brand.logoUrl} alt="Brand icon" width={48} />
                        ) : (
                            <img src={`/img/poi-detail/${POI_ICON[poiDetail?.category!]}`} alt="poi" />
                        )}

                        <div className="rl-poi-detail-header-right-section">
                            <div className="rl-poi-detail-header-right-section-name">{poiDetail?.name}</div>
                            <Rate
                                allowHalf
                                defaultValue={0}
                                value={poiDetail.rating}
                                style={{ fontSize: 14 }}
                                disabled
                            />
                        </div>
                        <div className="rl-poi-detail-header-close">
                            <Button type="action">
                                <i className="rl-icon-close" onClick={() => props.onClose()} />
                            </Button>
                        </div>
                    </div>

                    <div className="rl-poi-detail-address">
                        <div className="rl-poi-detail-address-name">{poiDetail?.address}</div>
                        <div className="rl-poi-detail-address-coords">{`${poiDetail?.coords.lat}, ${poiDetail?.coords.lng}`}</div>
                    </div>

                    <div className="rl-poi-detail-actions">
                        <Button
                            type="primary"
                            alignIconTop={true}
                            size="middle"
                            title={t('PoiDetail.addToRoute')}
                            icon={<i className="rl-icon-plus-circle" />}
                            onClick={() => props.onAddToRoute(poiDetail)}
                        >
                            {t('PoiDetail.addToRoute')}
                        </Button>

                        {/* <Button
                            type="dark"
                            alignIconTop={true}
                            outline={true}
                            size="middle"
                            title={t('PoiDetail.share')}
                            icon={<i className="rl-icon-share" />}
                            onClick={() => {}}
                        >
                            {t('PoiDetail.share')}
                        </Button> */}

                        {poiDetail?.isFavorite ? (
                            <Button
                                type="danger"
                                alignIconTop={true}
                                outline={true}
                                size="middle"
                                title={t('PoiDetail.favorite')}
                                icon={<i className="rl-icon-heart" />}
                                onClick={() => props.onDeleteFavorite(poiDetail)}
                            >
                                {t('PoiDetail.favorite')}
                            </Button>
                        ) : (
                            <Button
                                type="dark"
                                alignIconTop={true}
                                outline={true}
                                size="middle"
                                title={t('PoiDetail.addToFavorite')}
                                icon={<i className="rl-icon-heart" />}
                                onClick={() => props.onAddFavorite(poiDetail)}
                            >
                                {t('PoiDetail.addToFavorite')}
                            </Button>
                        )}
                    </div>
                </div>

                {poiDetail.category === PlaceType.GAS_STATION && (
                    <>
                        <div className="rl-poi-detail-fuel-prices rl-poi-detail-border-top">
                            <h6>{t('PoiDetail.fuelPrices')}</h6>
                            <div className="rl-poi-detail-fuel-prices-powered">
                                {t('PoiDetail.poweredByFuelio')}{' '}
                                <a
                                    href="https://www.fuel.io/"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="rl-poi-detail-fuel-prices-powered-fuelio"
                                >
                                    fuelio
                                </a>
                            </div>
                        </div>

                        {showFullInfo ? (
                            !!poiDetail?.fuelTypes?.length && (
                                <PoiDetailFuelPrices
                                    fuelTypes={poiDetail?.fuelTypes}
                                    discount={poiDetail.discount}
                                    onHideAllPrices={() => setShowFullInfo(false)}
                                />
                            )
                        ) : (
                            <PoiDetailFuelGroups poiDetail={poiDetail} onShowAllPrices={() => setShowFullInfo(true)} />
                        )}
                    </>
                )}

                {!!poiDetail.fuelPaymentCards?.length && (
                    <div className="rl-poi-detail-section rl-poi-detail-border-top">
                        <h6>{t('PoiDetail.acceptedFuelCards')}</h6>
                        <div className="rl-poi-detail-fuel-card-list">
                            {poiDetail.fuelPaymentCards.map((card, index) => (
                                <img
                                    key={index}
                                    src={`/img/fuel-card-brands/${BRAND_NAME_TO_ICON[card.id]}`}
                                    title={card.name}
                                />
                            ))}
                        </div>
                    </div>
                )}

                {!!poiDetail?.services.length && (
                    <div className="rl-poi-detail-section rl-poi-detail-border-top">
                        <h6>{t('PoiDetail.services')}</h6>
                        <div className="rl-poi-detail-icon-list">
                            {poiDetail?.services.map((icon, index) => (
                                <div className="rl-poi-detail-icon-list-item" key={index}>
                                    <i
                                        className={ServiceIcons[icon.code]}
                                        title={icon.code[0].toUpperCase() + icon.code.slice(1)}
                                    />
                                    <p>{icon.name[0].toUpperCase() + icon.name.slice(1)}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                )}

                {!!poiDetail?.reviews.totalCount && <PoiDetailRatings reviews={poiDetail?.reviews} />}

                {props.pois.length > 1 && (
                    <div className="rl-poi-detail-section rl-poi-detail-section-recently-viewed">
                        <p>{t('PoiDetail.recentlyViewed')}</p>
                        <div className="rl-poi-detail-recently-viewed-list">
                            {props.pois.map(
                                (poi, index) =>
                                    index >= 1 && (
                                        <PoiDetailRecently
                                            key={index}
                                            poi={poi}
                                            index={index}
                                            onRecentPoiClick={props.onRecentPoiClick}
                                            onDeleteRecentPoi={props.onDeleteRecentPoi}
                                        />
                                    )
                            )}
                        </div>
                    </div>
                )}
            </div>
        )
    );
}
