import moment from 'moment';
import { Subject } from 'rxjs';
import { AlarmInDatabaseWithGPSInfo, AlarmType, AlarmInDatabaseWithGPSInfoFromJSON } from 'generated/backend-api';
import { Logic } from './logic';
import { takeUntil } from 'rxjs/operators';
import { client } from 'stores/auth/client';
import { RootStore } from 'stores/RootStore';

export enum ProfileRole {
    Driver = 'Driver',
    Dispatcher = 'Dispatcher'
}

export class AlarmsLogic {
    readonly alarmsUpdates$: Subject<AlarmInDatabaseWithGPSInfo[]>;
    readonly logic: Logic;

    alarms: AlarmInDatabaseWithGPSInfo[];
    private _destroySiganl$ = new Subject<void>();

    constructor(logic: Logic, private _store: RootStore) {
        this.logic = logic;
        this.alarms = [];
        this.alarmsUpdates$ = new Subject<AlarmInDatabaseWithGPSInfo[]>();
    }

    /**
     * Inits alarms, watch for updates and resubscribe on notification api reconnect
     */
    init() {
        this._getAndSubscribeToAlarms();

        this.logic
            .notification()
            .onConnect()
            .pipe(takeUntil(this._destroySiganl$))
            .subscribe(() => {
                this._getAndSubscribeToAlarms();
            });

        this._watchAlarmUpdates();
    }

    /**
     * Returns geolocation alarms if present
     * types: CorridorLeave, PoiArrival, PoiClose, PoiDeparture
     */
    geolocationAlarms() {
        return (this.alarms ?? [])
            .filter(a =>
                [AlarmType.CorridorLeave, AlarmType.PoiArrival, AlarmType.PoiClose, AlarmType.PoiDeparture].includes(
                    a.alarmType
                )
            )
            .filter(a => moment.utc(a.start).isBetween(moment().utc().subtract(1, 'day'), moment.utc()))
            .sort((a, b) => Number(a.acknowledged) - Number(b.acknowledged));
    }

    /**
     * Returns transport alarms if present
     * types: OnTime, Delayed
     */
    transportAlarms() {
        return (this.alarms ?? [])
            .filter(a => [AlarmType.OnTime, AlarmType.Delayed].includes(a.alarmType))
            .filter(a => moment.utc(a.start).isBetween(moment().utc().subtract(1, 'day'), moment.utc()))
            .sort((a, b) => Number(a.acknowledged) - Number(b.acknowledged));
    }

    /**
     * Returns company membership alarms if present
     * types: Joined, Left
     */
    companyMembershipAlarms() {
        return (this.alarms ?? [])
            .filter(a => a.uniqueData?.['profileRole'] === ProfileRole.Driver) // filter driver membership notifications only
            .filter(a => [AlarmType.Joined, AlarmType.Left].includes(a.alarmType))
            .filter(a => moment.utc(a.start).isBetween(moment().utc().subtract(1, 'day'), moment.utc()))
            .sort((a, b) => Number(a.acknowledged) - Number(b.acknowledged));
    }

    /**
     * Returns instant alarms if present
     * types: BatteryLow, ConnectionLoss, CorridorLeave
     */
    instantAlarms() {
        return (this.alarms ?? [])
            .filter(a =>
                [AlarmType.BatteryLow, AlarmType.ConnectionLoss, AlarmType.CorridorLeave].includes(a.alarmType)
            )
            .filter(a => !a.end);
    }

    /**
     * Marks alarms as seen and notifies subscribers on change
     */
    markAlarmsAsSeen(ids: string[]) {
        this.alarms = this.alarms.map(alarm =>
            ids.includes(alarm.alarmId)
                ? {
                      ...alarm,
                      acknowledged: true
                  }
                : alarm
        );
        this.alarmsUpdates$.next(this.alarms);
    }

    /**
     * Watches for alarms updates and merge local date, notifies on change all alarmsUpdates$ subscribers
     */
    private _watchAlarmUpdates() {
        this.logic.notification().on('alarms', async n => {
            if (n.data?.alarms && Array.isArray(n.data.alarms)) {
                const alarmsUpdates$ = (n.data.alarms as any[]).map(a => AlarmInDatabaseWithGPSInfoFromJSON(a));

                if (alarmsUpdates$.length === 0) {
                    return;
                }

                const newAlarms = [...this.alarms];
                alarmsUpdates$.forEach(update => {
                    const index = newAlarms.findIndex(a => a.alarmId === update.alarmId);

                    if (index !== -1) {
                        newAlarms[index] = update;
                    } else {
                        newAlarms.push(update);
                    }
                });

                this.alarms = newAlarms;
                this.alarmsUpdates$.next(this.alarms);
            }
        });
    }

    /**
     * Gets current alarms list and subscribes for updates
     */
    private async _getAndSubscribeToAlarms() {
        let alarms: AlarmInDatabaseWithGPSInfo[] = [];
        try {
            const params = {
                clientId: client()?.id!,
                subscribeDevice: this.logic.notification().device,
                subscribeUser: this._store.auth.user?.id,
                active: false
            };
            alarms = await this.logic.api().backendApi.getAlarmsV1AlarmsMyGet(params);
        } catch (e) {
            alarms = [];
        }

        this.alarms = alarms;
        this.alarmsUpdates$.next(this.alarms);
    }

    destroy() {
        this._destroySiganl$.next();
    }
}
