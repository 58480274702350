import { message } from 'antd';
import cn from 'classnames';
import { RouteNames } from 'router/routes';
import { Modal, Notification } from 'components/base/layout';
import { LatLng } from 'common/model/geo';
import { debounce } from 'utils/helpers/debounce';
import { AddressStructured } from 'generated/backend-api';
import { TollCosts } from 'services/api/domains/TollsApi';
import { GeoJsonPointType, latLngFromGeoJsonPointType } from 'logic/common/geo-utils';
import { Logic } from 'logic/logic';
import { parseTextToPosition } from 'logic/map/utils';
import { DEFAULT_VEHICLE_PROFILE, TOLL_COST_DEFAULT_VALUE } from 'logic/scheduling-route-planner';
import { PoiModel } from 'logic/poi';
import { getPoiGuid } from 'modules/_old/management/modules/poi/utils';
import { Moment } from 'moment';
import { Component } from 'react';
import { Trans, WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import Planner from './ui/Planner/index';
import { AlarmModel } from './ui/PlannerAlarms';
import { CompanyVehicle, VehicleProfile } from '../../services/api/domains/VehiclesApi';
import { VehicleCreateEditModule } from 'modules/VehicleCreateEditModule';
import { AvailableCurrencies } from 'utils/constants/currencies';
import axios from 'axios';
import { Button } from 'components/base/controls';
import { DEFAULT_MAP_PADDING, MOBILE_MAP_PADDING, MOBILE_MAX_WIDTH, TABLET_MAX_WIDTH } from 'utils/constants/constants';
import { PlannerSwitch, PlannerView } from './ui/PlannerSwitch';
import { MapPlaceType } from 'logic/map/map';
import { TransportState } from 'services/api/domains/TransportsApi';
import CreateNewPlaceModule from 'modules/CreateNewPlaceModule/CreateNewPlaceModule';
import { inject, observer } from 'mobx-react';
import { FavoritesStore, UserSettingsStore } from 'stores';
import { Favorite } from 'services/api/domains/FavoritesApi';
import { UserEvent, InitiatedFrom } from 'logic/userEventsProviders/GoogleTagManager';
import { AlertType } from 'logic/UserEvents';

export interface EventState {
    state?: number;
    eventRuleName?: 'transport_arrival' | 'transport_departure' | 'transport_passing' | 'transport_skipping';
}

export interface EventRule {
    channels?: string[];
    type?: 'parking' | 'refueling' | 'loading' | 'unloading';
}

export interface PlacesTaskModel {
    id: string;
    type?: TaskType;
    action?: string;
    additionalTimeSec: number;
}

export interface Avoids {
    [country: string]: {
        [avoidType: string]: boolean;
    };
}

export interface PlacesModel {
    id: string;
    rta?: string;
    rtd?: string;
    eta?: string;
    ata?: string;
    atd?: string;
    name?: string;
    originalName?: string;
    distance?: number;
    duration?: number;
    center: LatLng;
    polygon?: LatLng[][];
    eventStates?: EventState[];
    eventRules?: EventRule[];
    route?: string;
    source?: string;
    type?: MapPlaceType;
    tasks?: PlacesTaskModel[];
    actual?: string;
    addressStructured: AddressStructured[];
    alarms: AlarmModel[];
}

export interface TransportModel {
    id?: string;
    name?: string;
    firstPlaceRta?: string;
    lastPlaceRta?: string;
    places: PlacesModel[];
    profile?: string;
    vehicle?: string;
    client?: string;
    costPerKm?: { currency: AvailableCurrencies; cost: number };
    state?: TransportState;
    possibleAvoids?: Record<string, any>;
    eta?: string;
    routePolyline?: string;
    duration?: number;
    distance?: number;
    avoids?: string;
    note?: string;
}

type RouteParams = {
    editId: string;
    vehicleId: string;
    startDate: string;
    endDate: string;
    dispatcherBoard: string;
    redirectedFrom?: 'calendar' | 'table' | 'tracking';
    transport: string;
};

export enum AvoidsTypes {
    HIGHWAYS = 'highways',
    TOLLS = 'tolls',
    FERRIES = 'ferries',
    UNPAVED = 'unpaved',
    COUNTRY = 'country'
}

export interface CostPerKm {
    currency: AvailableCurrencies;
    cost: number;
}

export enum VehicleProfileType {
    Default,
    Custom
}

export interface PlannerVehicleProfile {
    id: number;
    name?: string;
    type: VehicleProfileType;
    costPerKm?: CostPerKm;
}

interface Props extends WithTranslation, RouteComponentProps<RouteParams> {
    logic: Logic;
    userSettingsStore?: UserSettingsStore;
    favoritesStore?: FavoritesStore;
    isLoggedIn?: boolean;
    currency: AvailableCurrencies;
}

export enum TaskType {
    Loading = 'loading',
    Parking = 'parking',
    Refueling = 'refueling',
    Unloading = 'unloading'
}

export interface PlaceSuggestion {
    id?: string;
    label?: string;
    position?: GeoJsonPointType;
    source?: PlaceSuggestionSource;
}

export enum PlaceSuggestionSource {
    CustomPlaces = 'CUSTOM_PLACES',
    FuelStations = 'FUEL_STATIONS',
    Google = 'GOOGLE',
    History = 'HISTORY',
    NoSource = 'NO_SOURCE',
    ParkingLots = 'PARKING_LOTS',
    Sygic = 'SYGIC'
}

export enum SearchFilter {
    All = 'all',
    Favorites = 'favorites',
    History = 'history'
}

interface TransportQueryParams {
    places?: PlacesModel[];
    vehicle?: PlannerVehicleProfile;
    avoids?: string;
}

interface State {
    placeId?: string;
    task?: PlacesTaskModel;
    autoComplete: {
        text: string;
        displayedSuggestions: PlaceSuggestion[];
        allSuggestions: PlaceSuggestion[];
        isTyping?: boolean;
    };
    transport?: TransportModel;
    vehicles?: CompanyVehicle[];
    profiles?: VehicleProfile[];
    vehicleProfile?: PlannerVehicleProfile;
    backUrl?: string;
    transportNameChanged: boolean;
    filter?: SearchFilter;
    loading?: boolean;
    tollCostsLoading?: boolean;
    alarms?: {
        data: AlarmModel[];
        placeId?: string;
    };
    poiBarVisible: boolean;
    poiModel?: PoiModel;
    includeTollCosts: boolean;
    tollCosts?: TollCosts;
    tollCostsDetailVisible: boolean;
    countries: { [code: string]: { name: string; code: string } };
    searchInProgress: number;
    autocompleteRequestId?: string;
    showRouteAvoids: boolean;
    avoids: Avoids;
    createVehicleModal: boolean;
    isEditTransport: boolean;
    visible: boolean;
    view: PlannerView;
    dimensions: {
        width: number;
        height: number;
    };
    isMobileWidth: boolean;
    isAutocompleteMobileOpen?: boolean;
    createNewPlace: {
        visible: boolean;
        model?: PoiModel;
    };
    transportUpdated?: boolean;
    loadedTransport?: TransportModel;
}

const MAX_TRANSPORT_PLACE_NAME_LENGHT = 56;

export const ROUTING_MAP_PADDING = {
    ...DEFAULT_MAP_PADDING,
    left: 748
};

@inject('favoritesStore', 'userSettingsStore')
@observer
class PlannerModule extends Component<Props, State> {
    private logic: Logic;
    private _destroySignal$ = new Subject<void>();

    constructor(props: Props) {
        super(props);
        this.state = {
            autoComplete: {
                text: '',
                displayedSuggestions: [],
                allSuggestions: []
            },
            searchInProgress: 0,
            transportNameChanged: false,
            loading: false,
            poiBarVisible: false,
            poiModel: undefined,
            tollCostsDetailVisible: true,
            countries: {},
            showRouteAvoids: false,
            avoids: {},
            includeTollCosts: true,
            createVehicleModal: false,
            isEditTransport: false,
            filter: SearchFilter.All,
            visible: false,
            view: PlannerView.ROUTE_PLANNER,
            dimensions: { width: window.innerWidth, height: window.innerHeight },
            isAutocompleteMobileOpen: false,
            isMobileWidth: window.innerWidth <= MOBILE_MAX_WIDTH,
            createNewPlace: {
                visible: false
            },
            transportUpdated: false
        };
        this.logic = this.props.logic;
    }

    async componentDidMount() {
        const { t } = this.props;

        const initRoutePlanner = async () => {
            await this._loadVehiclesProfiles();

            if (this.props.location.pathname === RouteNames.SCHEDULING_PLANNER) {
                this.setState({ visible: true });
                await this.logic.schedulingRoutePlanner().init();
                this._initRoute();
                this._setMapPadding();
                window.addEventListener('resize', this._updateDimensions);
            }

            this.logic.schedulingRoutePlanner().onPlanRouteError(err => {
                if (err.type === 'NO_ROUTE') {
                    const place = this.state.transport?.places.find(p => p.id === err.wayPointId);

                    message.error(t('Planner.error.noRoute', { place: place?.name }));

                    this.logic
                        .schedulingRoutePlanner()
                        .removePlaceFromTransport(err.wayPointId)
                        .then(() => {
                            this._updateTransportName();
                        });
                } else {
                    Modal.info({
                        title: t('NoRouteDialog.title'),
                        content: t('NoRouteDialog.description'),
                        okText: t('NoRouteDialog.submit'),
                        width: 600
                    });

                    this.props.logic.userEvents().alert(AlertType.FAILED_ROUTING, 'No results available');
                }
            });

            this.logic.schedulingRoutePlanner().onTransportLoad(transport => {
                this._setAvoids(transport.avoids);
            });

            this.logic.schedulingRoutePlanner().onTransportChange(transport => {
                if (transport.vehicle || transport.profile) {
                    this.setState({
                        vehicleProfile: {
                            id: Number(transport.vehicle || transport.profile),
                            type: transport.vehicle ? VehicleProfileType.Custom : VehicleProfileType.Default,
                            costPerKm: transport.costPerKm
                        }
                    });
                }

                const routeChanged = this.state.transport?.routePolyline !== transport.routePolyline;
                const firstRouteCompute = !!(!this.state.transport?.routePolyline && transport?.routePolyline);
                const params = new URLSearchParams(this.props.history.location.search);

                this.setState({ transport }, async () => {
                    if (firstRouteCompute) {
                        await this._toggleTollCostsDetail(true);
                        this.setState({ loadedTransport: JSON.parse(JSON.stringify(transport)) });

                        if (!params.get('editId')) {
                            this._routePlannedEvent(UserEvent.ROUTE_PLANNED);
                        }
                    } else if (routeChanged) {
                        if (this.state.includeTollCosts) {
                            await this._updateTollCosts();
                        }

                        if (!params.get('editId')) {
                            this._routePlannedEvent(UserEvent.ROUTE_PLANNED);
                        }

                        if (transport.state === TransportState.Active) {
                            this._showActiveTransportEditModal();
                        }
                    }

                    if (this.state.isEditTransport && this.state.loadedTransport) {
                        this.setState({
                            transportUpdated: JSON.stringify(this.state.loadedTransport) !== JSON.stringify(transport)
                        });
                    }

                    if (!params.get('editId')) {
                        this._updateTransportQueryParams();
                    }
                });
            });

            this.logic.map().onPoiToggle(value => {
                this.setState(state => ({
                    poiBarVisible: value ? value : false,
                    poiModel: value ? state.poiModel : undefined
                }));
            });

            this.logic.map().onControlsOffResetState(() => {
                this.setState(() => ({
                    poiBarVisible: false,
                    poiModel: undefined
                }));
            });

            this.logic
                .schedulingRoutePlanner()
                .loading$.pipe(takeUntil(this._destroySignal$))
                .subscribe((isLoading: boolean) => {
                    this.setState({
                        loading: isLoading
                    });
                });

            this.logic
                .enums()
                .countryList()
                .then(countryList => {
                    const countries = {};
                    countryList.forEach(country => {
                        countries[country.iso3] = { name: country.name, code: country.code };
                        countries[country.code] = { name: country.name, code: country.iso3 };
                    });
                    this.setState({ countries });
                });

            this.logic
                .vehicles()
                .vehiclesChanged$.pipe(takeUntil(this._destroySignal$))
                .subscribe(() => {
                    this._loadVehiclesProfiles();
                });

            this.logic.schedulingRouteOverview().onTransportDelete(transportId => {
                if (this.logic.schedulingRoutePlanner().getTransport()?.id === transportId) {
                    this._onPlannerCancel();
                }
            });
        };

        if (!this.logic.map().mapLoaded) {
            this.logic
                .map()
                .mapLoaded$.pipe(takeUntil(this._destroySignal$))
                .subscribe(() => initRoutePlanner());
        } else {
            initRoutePlanner();
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this._updateDimensions);
        this.logic.schedulingRoutePlanner().destroy();
        this._destroySignal$.next();
    }

    async componentDidUpdate(prevProps: Props) {
        if (this.props.isLoggedIn !== prevProps.isLoggedIn) {
            if (!this.props.isLoggedIn) {
                this._onPlannerCancel();
            }

            this._loadVehiclesProfiles();
        }

        if (this.props.location.pathname !== prevProps.location.pathname) {
            const params = new URLSearchParams(this.props.history.location.search);

            if (this.props.location.pathname === RouteNames.SCHEDULING_PLANNER) {
                this.setState({ visible: true });

                if (params.get('redirectedFrom') === 'tracking') {
                    this._onPlannerCancel();
                    this._initRoute(params);
                } else {
                    this.props.logic.map().routing().init();
                    this._initRoute();
                    this._setMapPadding();
                }
            } else {
                this._destroy();
            }
        }

        if (this.props.currency !== prevProps.currency) {
            const currentProfile = this.state.vehicleProfile!;
            const vehicleProfile = {
                ...currentProfile,
                costPerKm: { ...currentProfile.costPerKm!, currency: this.props.currency }
            };
            await this._onVehicleChange(vehicleProfile);
            await this.logic.schedulingRoutePlanner().reloadRoute();
            this._updateTollCosts();
        }
    }

    render() {
        const { transport, vehicles, profiles, placeId, task, autoComplete, visible } = this.state;

        return (
            <div
                className={cn('rl-planner-module', {
                    'full-height':
                        (this.state.transport?.places?.length &&
                            (this.state.view === PlannerView.ROUTE_PLANNER || !this.state.isMobileWidth)) ||
                        this.state.isAutocompleteMobileOpen
                })}
            >
                {this.props.location.pathname === RouteNames.SCHEDULING_PLANNER && this._plannerSwitchVisible() && (
                    <PlannerSwitch onChangeView={this._onChangeView} view={this.state.view} />
                )}

                {visible && (
                    <Planner
                        autoComplete={{
                            text: autoComplete.text,
                            displayedSuggestions: mapSuggestions(autoComplete.displayedSuggestions),
                            allSuggestions: mapSuggestions(autoComplete.allSuggestions),
                            isTyping: this.state.autoComplete.isTyping
                        }}
                        places={transport?.places ?? []}
                        task={{
                            placeId,
                            task
                        }}
                        vehicleProfiles={{
                            profiles,
                            vehicles
                        }}
                        currency={this.props.userSettingsStore?.currency!}
                        clientName={this.state.transport?.client ?? ''}
                        routeOptions={{
                            avoids: this.state.avoids,
                            showRouteAvoids: this.state.showRouteAvoids
                        }}
                        vehicleProfile={this.state.vehicleProfile}
                        transportName={this.state.transport?.name ?? ''}
                        filter={this.state.filter}
                        loading={this.state.loading}
                        tollCostsLoading={this.state.tollCostsLoading}
                        alarms={this.state.alarms?.data}
                        tollCosts={this.state.tollCosts}
                        isLoggedIn={this.props.isLoggedIn}
                        transport={this.state.transport}
                        searchInProgress={!!this.state.searchInProgress}
                        tollCostsDetailVisible={this.state.tollCostsDetailVisible}
                        countries={this.state.countries}
                        includeTollCosts={this.state.includeTollCosts}
                        isEditTransport={this.state.isEditTransport}
                        isAutocompleteMobileOpen={this.state.isAutocompleteMobileOpen}
                        isMobileWidth={this.state.isMobileWidth}
                        transportUpdated={this.state.transportUpdated}
                        onToggleAutocompleteMobileMenu={this._toggleAutocompleteMobileMenu}
                        onTransportNameChange={this._onTransportNameChange}
                        onTransportClientNameChange={this._onTransportClientNameChange}
                        onPlacesDragAndDrop={this._onPlacesDragAndDrop}
                        onPlaceTaskClick={this._onPlaceTaskClick}
                        onPlacesDeleteClick={this._onPlacesDeleteClick}
                        onPlacesTaskCancel={this._onPlacesTaskCancel}
                        onPlacesTaskSave={this._onPlacesTaskSave}
                        onPlacesRtaChange={this._onPlacesRtaChange}
                        onAutocompleteSubmit={this._onAutocompleteSubmit}
                        onAutocompleteChange={this._onAutocompleteInputChange}
                        onPlannerSave={this._onPlannerSave}
                        onPlannerCancel={this._onPlannerCancel}
                        onPlannerDelete={this._onPlannerDelete}
                        onFilterChange={this._onFilterChange}
                        onAlarmsConfigCancel={this._onAlarmConfigCancel}
                        onAlarmsConfigChange={this._onAlarmConfigChange}
                        onCostPerKmChange={this._onCostPerKmChange}
                        onVehicleChange={this._onVehicleChange}
                        onShowRouteAvoids={this._onShowRouteAvoids}
                        onCreateProfileClick={this._showCreateVehicleModal}
                        onAvoidsChange={this._onAvoidsChange}
                        onTollCostsClick={this._toggleTollCostsDetail}
                        onShowPoiBarVisible={this._showCreateNewPlace}
                        onTransportNoteChange={this._onTransportNoteChange}
                    />
                )}

                <VehicleCreateEditModule
                    logic={this.logic}
                    visible={this.state.createVehicleModal}
                    type={'CREATE'}
                    onCancel={this._cancelCreateVehicleModal}
                    afterSubmit={() => {
                        this._cancelCreateVehicleModal();
                        this._loadVehiclesProfiles();
                    }}
                    afterCreate={this._afterCreateVehicle}
                />

                <CreateNewPlaceModule
                    logic={this.props.logic}
                    visible={this.state.createNewPlace?.visible}
                    coords={this.state.createNewPlace?.model?.center}
                    onClose={this._onCloseCreateNewPlace}
                    onAfterSubmit={this._onCloseCreateNewPlace}
                    mask={false}
                />
            </div>
        );
    }

    private _onTransportNameChange = (name: string) => {
        this.setState(
            {
                transportNameChanged: true
            },
            () => {
                this.logic.schedulingRoutePlanner().changeName(name);
            }
        );
    };

    private _onTransportNoteChange = (note: string) => {
        if (this.state.transport!.note !== note) {
            this.setState(
                state => {
                    const updatedTransport = {
                        ...state.transport!,
                        note: note
                    };

                    return {
                        transport: updatedTransport,
                        transportUpdated: true
                    };
                },
                () => this.logic.schedulingRoutePlanner().setTransportNote(note)
            );
        }
    };

    private _onTransportClientNameChange = (name: string) => {
        this.logic.schedulingRoutePlanner().setTransportClient(name);
    };

    private _onCostPerKmChange = (costPerKm: CostPerKm) => {
        this.setState(
            state => ({
                vehicleProfile: {
                    ...state.vehicleProfile!,
                    costPerKm: costPerKm
                }
            }),
            () => this.logic.schedulingRoutePlanner().setTransportCostPerKM(costPerKm)
        );
    };

    private _onShowRouteAvoids = () => {
        this.setState(state => ({
            showRouteAvoids: !state.showRouteAvoids
        }));
    };

    private _onVehicleChange = async (vehicleProfile: PlannerVehicleProfile) => {
        this.logic.schedulingRoutePlanner().setTransportCostPerKM(vehicleProfile.costPerKm);

        if (vehicleProfile.type === VehicleProfileType.Custom) {
            await this.logic.schedulingRoutePlanner().setVehicle(vehicleProfile.id.toString());
        } else {
            await this.logic.schedulingRoutePlanner().setVehicleProfile(vehicleProfile.id);
        }

        this.setState(
            {
                loading: !!this.state.transport?.places.length,
                vehicleProfile: vehicleProfile
            },
            async () => {
                await this._updateTollCosts();
            }
        );

        this.setState({
            loading: false
        });
    };

    private _onAlarmConfigCancel = () => {
        this.setState({ alarms: undefined });
    };

    private _onPlannerCancel = () => {
        this.setState(
            {
                vehicleProfile: undefined,
                filter: SearchFilter.All,
                transportNameChanged: false,
                tollCostsDetailVisible: false,
                avoids: {},
                showRouteAvoids: false,
                includeTollCosts: false,
                isEditTransport: false,
                loading: false,
                tollCostsLoading: false,
                transport: undefined,
                loadedTransport: undefined,
                transportUpdated: false
            },
            () => this.state.profiles?.length && this._setDefaultVehicleProfile(this.state.profiles)
        );

        this._onPlacesTaskCancel();

        this.logic.apiService().routing().cancelRequests();
        this.logic.apiService().search().cancelRequests();
        this.logic.apiService().tolls().cancelRequests();

        this.logic.schedulingRoutePlanner().reset();
        this._setMapPadding();

        if (this.props.history.location.search) {
            this.props.history.replace({
                search: ''
            });
        }
    };

    private _cleanAutocomplete = (): void => {
        this.setState(state => ({
            autoComplete: {
                ...state.autoComplete,
                displayedSuggestions: [],
                allSuggestions: []
            }
        }));
    };

    private _getSearchHistory(text: string): PlaceSuggestion[] {
        let searchHistory: PlacesModel[] = this.logic.searchHistory().getSearchHistory();

        searchHistory = text
            ? searchHistory?.filter(place => {
                  let searchText = place.name;

                  if (place?.addressStructured[0]) {
                      searchText +=
                          '|' +
                          Object.keys(place.addressStructured[0])
                              .map(key => place.addressStructured[0][key])
                              .join('|');
                  }

                  return searchText?.toLowerCase().includes(text.toLowerCase());
              })
            : searchHistory;

        return (
            searchHistory?.map(
                place =>
                    ({
                        id: place.id,
                        label: place.name,
                        position: {
                            coordinates: [Number(place.center.lng), Number(place.center.lat)]
                        } as GeoJsonPointType,
                        polygon: null,
                        source: PlaceSuggestionSource.History
                    } as PlaceSuggestion)
            ) || ([] as PlaceSuggestion[])
        );
    }

    private async _getFavorites(text: string): Promise<PlaceSuggestion[]> {
        let favorites: Favorite[] = [];

        if (this.props.isLoggedIn) {
            try {
                favorites = await this.props.favoritesStore!.getFavoritesWithAddress();
            } catch (err) {
                console.error(err);
            }
        }

        favorites = text
            ? favorites?.filter(favorite => favorite?.name?.toLowerCase().includes(text.toLowerCase()))
            : favorites;

        return (
            favorites
                ?.map(
                    favorite =>
                        ({
                            id: favorite.id,
                            source: PlaceSuggestionSource.CustomPlaces,
                            position: {
                                coordinates: [Number(favorite.center?.lng), Number(favorite.center?.lat)]
                            } as GeoJsonPointType,
                            label: favorite.name
                        } as PlaceSuggestion)
                )
                ?.slice(0, 5) || ([] as PlaceSuggestion[])
        );
    }

    private _generateRequestId = () =>
        'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, c => {
            const r = (Math.random() * 16) | 0;
            const v = c === 'x' ? r : (r & 0x3) | 0x8;

            return v.toString(16);
        });

    private _getAutocompleteCandidates = async (text: string, addFavoritesAndHistory: boolean = false) => {
        let candidates: PlaceSuggestion[] = [];

        try {
            candidates = await this.logic
                .schedulingRoutePlanner()
                .fetchSuggestions(text, this.state.autocompleteRequestId)
                .then(async data => {
                    const candidates: PlaceSuggestion[] =
                        data?.map(place => ({
                            ...place,
                            source: PlaceSuggestionSource.Sygic
                        })) || [];

                    if (addFavoritesAndHistory) {
                        const favorite = await this._getFavorites(text);
                        const history = this._getSearchHistory(text);

                        if (favorite?.length) {
                            candidates.unshift(favorite.shift()!);
                        }

                        if (history?.length) {
                            candidates.unshift(history.shift()!);
                        }
                    }

                    if (!candidates.length) {
                        this._noSearchResultEvent(text);
                    }

                    return candidates;
                });
        } catch (err) {
            if (axios.isCancel(err)) {
                console.warn(err);
            } else {
                console.error('Autocomplete - cannot get suggestions', err);
            }
        }

        return candidates;
    };

    private _onAutocompleteInputChange = (text: string, focus?: boolean, fetchNewSuggestions?: boolean) => {
        this.setState(
            state => ({
                autoComplete: {
                    ...state.autoComplete,
                    isTyping: true
                }
            }),
            () => {
                this._onAutocompleteChangeDebounced(text, focus, fetchNewSuggestions);
            }
        );
    };

    private _onAutocompleteChangeDebounced = debounce(
        (text: string, focus?: boolean, fetchNewSuggestions?: boolean) => {
            this.setState(
                state => ({
                    autoComplete: {
                        ...state.autoComplete,
                        isTyping: false
                    }
                }),
                () => {
                    this._onAutocompleteChange(text, focus, fetchNewSuggestions);
                }
            );
        }
    );

    private _onAutocompleteChange = async (text: string, focus?: boolean, fetchNewSuggestions = true) => {
        if (focus) {
            this.setState({ autocompleteRequestId: this._generateRequestId() });
        }

        if (this.state.filter === SearchFilter.All) {
            const point = parseTextToPosition(text);

            if (point.isValid) {
                const lat = point.position.lat!;
                const lng = point.position.lng!;

                const rawPosition: PlaceSuggestion = {
                    id: getPoiGuid(lat, lng, 'unknown_location'),
                    source: PlaceSuggestionSource.NoSource,
                    label: point.position.address,
                    position: { coordinates: [lng, lat] }
                };

                this.setState(state => ({
                    autoComplete: {
                        ...state.autoComplete,
                        displayedSuggestions: [rawPosition]
                    }
                }));

                return;
            } else {
                this.setState(state => ({
                    autoComplete: {
                        ...state.autoComplete,
                        displayedSuggestions: state.autoComplete.allSuggestions
                    }
                }));

                if (!text?.trim()) {
                    this._cleanAutocomplete();
                    return;
                }

                if (fetchNewSuggestions) {
                    this._cleanAutocomplete();
                    this.setState(state => ({ searchInProgress: state.searchInProgress + 1 }));

                    const candidates = await this._getAutocompleteCandidates(text, true);

                    this.setState(state => ({
                        searchInProgress: state.searchInProgress - 1,
                        autoComplete: {
                            ...state.autoComplete,
                            displayedSuggestions: candidates,
                            allSuggestions: candidates
                        }
                    }));
                }

                return;
            }
        }

        if (this.state.filter === SearchFilter.History) {
            const tmpAllSuggestions = this.state.autoComplete.allSuggestions;

            this._cleanAutocomplete();
            this.setState(state => ({ searchInProgress: state.searchInProgress + 1 }));

            const history = this._getSearchHistory(text);
            const allSuggestions: PlaceSuggestion[] = !text?.trim()
                ? []
                : fetchNewSuggestions
                ? await this._getAutocompleteCandidates(text, true)
                : tmpAllSuggestions;

            this.setState(state => ({
                searchInProgress: state.searchInProgress - 1,
                autoComplete: {
                    ...state.autoComplete,
                    displayedSuggestions: history,
                    allSuggestions
                }
            }));

            return;
        }

        if (this.state.filter === SearchFilter.Favorites) {
            const tmpAllSuggestions = this.state.autoComplete.allSuggestions;

            this._cleanAutocomplete();
            this.setState(state => ({ searchInProgress: state.searchInProgress + 1 }));

            const favorites = await this._getFavorites(text);
            const allSuggestions: PlaceSuggestion[] = !text?.trim()
                ? []
                : fetchNewSuggestions
                ? await this._getAutocompleteCandidates(text, true)
                : tmpAllSuggestions;

            this.setState(state => ({
                searchInProgress: state.searchInProgress - 1,
                autoComplete: {
                    ...state.autoComplete,
                    displayedSuggestions: favorites,
                    allSuggestions
                }
            }));

            return;
        }
    };

    private _onAutocompleteSubmit = async (value: string) => {
        const { displayedSuggestions } = { ...this.state.autoComplete };

        this.setState({ loading: true });

        let suggestion = displayedSuggestions.find(s => s.id === value);
        if (suggestion?.source === PlaceSuggestionSource.Sygic) {
            try {
                const result = await this.logic.apiService().search().autocompleteGeocodeLocation({
                    locationid: value,
                    lang: this.props.userSettingsStore?.lang,
                    requestid: this.state.autocompleteRequestId
                });

                if (result?.location) {
                    suggestion = {
                        ...suggestion,
                        position: { coordinates: [result.location.lon!, result.location.lat!] }
                    };
                }
            } catch (error) {
                if (axios.isCancel(error)) {
                    console.warn(error);
                    return;
                }

                console.error('Autocomplete - cannot get location', error);
            }
        }

        if (suggestion?.position) {
            this.logic
                .schedulingRoutePlanner()
                .addPlaceToTransport(
                    suggestion.label || 'Point',
                    latLngFromGeoJsonPointType(suggestion.position),
                    suggestion.source === PlaceSuggestionSource.FuelStations
                        ? MapPlaceType.FuelStation
                        : suggestion.source === PlaceSuggestionSource.ParkingLots
                        ? MapPlaceType.ParkingLot
                        : MapPlaceType.Waypoint
                )
                .then(() => {
                    this._updateTransportName();
                    this._onAutocompleteChange('');
                })
                .catch(err => {
                    console.error(err);
                })
                .finally(() => {
                    this.setState({ loading: false });
                });
        } else {
            await this._onAutocompleteChange('');
            this.setState({ loading: false });
        }
    };

    private _onPlacesDragAndDrop = async (oldIndex: number, newIndex: number) => {
        if (oldIndex !== newIndex) {
            this.setState({ loading: true }, () => {
                this.logic
                    .schedulingRoutePlanner()
                    .updatePlaceOrder(oldIndex, newIndex)
                    .then(() => {
                        this._updateTransportName();
                        this.setState({ loading: false });
                    });
            });
        }
    };

    private _onPlacesDeleteClick = (id: string): void => {
        this.setState({ loading: true });
        this.logic
            .schedulingRoutePlanner()
            .removePlaceFromTransport(id)
            .then(() => {
                this._updateTransportName();
                if (this.state.placeId && this.state.placeId === id) {
                    this.setState({
                        task: undefined
                    });
                }

                if ((this.state.transport?.places || []).length < 2) {
                    this.setState({
                        tollCostsDetailVisible: false,
                        avoids: {},
                        showRouteAvoids: false,
                        includeTollCosts: false
                    });
                }

                this.setState({ loading: false });
            });
    };

    private _onPlannerSave = async () => {
        if (!this.props.isLoggedIn) {
            this.props.history.push({ search: '?login=true' });
            return;
        }

        if (!this.state.transport?.name) {
            return;
        }

        this.setState({ loading: true });

        try {
            const result = await this.logic.schedulingRoutePlanner().saveRoute();

            if (result.operation === 'CREATE') {
                this._routePlannedEvent(UserEvent.TRANSPORT_SAVED);

                Notification.success({
                    message: this.props.t('common.saved'),
                    description: (
                        <div>
                            <Trans i18nKey="Planner.saveSuccess">
                                Transport has been saved successfully. You can find it in
                                <Button
                                    type="link"
                                    style={{ padding: 0, fontWeight: 'normal' }}
                                    onClick={() => this.props.history.push(RouteNames.SCHEDULING_DISPATCHER_BOARD)}
                                >
                                    Dispatcher board
                                </Button>
                                .
                            </Trans>
                        </div>
                    )
                });
            } else if (result.operation === 'UPDATE') {
                this._routePlannedEvent(UserEvent.TRANSPORT_UPDATED);

                Notification.success({
                    message: this.props.t('common.updated'),
                    duration: 5,
                    placement: 'bottomRight',
                    description: this.props.t('Planner.updateSuccess')
                });
            }

            this.setState(state => {
                const updatedTransport = {
                    ...state.transport!,
                    id: result.transportId
                };

                return {
                    isEditTransport: true,
                    transport: updatedTransport,
                    loadedTransport: JSON.parse(JSON.stringify(updatedTransport)),
                    transportUpdated: false
                };
            });
        } catch (err) {
            console.error(err);
            Notification.error({
                message: err.message
            });
        } finally {
            this.setState({ loading: false });
        }
    };

    private _onAlarmConfigChange = (config: AlarmModel[]) => {
        if (this.state.alarms?.placeId) {
            this.logic
                .schedulingRoutePlanner()
                .updatePlaceAlarm(this.state.alarms.placeId, config)
                .then(() => {
                    this.setState(state => ({
                        alarms: {
                            ...state.alarms,
                            data: config
                        }
                    }));
                });
        }
    };

    private _onPlaceTaskClick = (id: string) => {
        const place = this.state.transport?.places.find(p => p.id === id);
        if (place && place?.id !== this.state.placeId) {
            const task = place.tasks?.length ? place.tasks[0] : undefined;

            this.setState(state => ({
                ...state,
                placeId: id,
                task: {
                    id: task ? task.id : '',
                    action: task ? task.action : '',
                    type: task ? task.type : undefined,
                    additionalTimeSec: task?.additionalTimeSec || 0
                },
                alarms: {
                    placeId: id,
                    data: place.alarms
                }
            }));
        } else {
            this._onPlacesTaskCancel();
        }
    };

    private _onPlacesTaskCancel = () => {
        this.setState(state => ({
            ...state,
            placeId: undefined,
            task: undefined
        }));
    };

    private _onPlacesTaskSave = (model: PlacesTaskModel, placeId: string, reloadRoute: boolean) => {
        const places = this.state.transport!.places.map(p => {
            if (p.id === placeId) {
                p.tasks = [model];
            }

            return p;
        });

        this.setState(
            state => ({
                ...state,
                task: model,
                transport: {
                    ...state.transport,
                    places
                }
            }),
            () => {
                this.logic.schedulingRoutePlanner().setTaskOnPlace(model, placeId);

                if (reloadRoute) {
                    this.logic.schedulingRoutePlanner().reloadRoute();
                }
            }
        );
    };

    private _onPlacesRtaChange = (id: string, value: Moment) => {
        this.setState({ loading: true });
        this.logic
            .schedulingRoutePlanner()
            .changePlaceRta(id, value)
            .then(() => {
                this.setState({ loading: false });
            });
    };

    private _updateTransportName = () => {
        const { transport, transportNameChanged } = this.state;

        if (!transport?.places) {
            this.logic.schedulingRoutePlanner().changeName();
            return;
        }

        const getTransportName = (transportName: string) => {
            return transportName.length > MAX_TRANSPORT_PLACE_NAME_LENGHT
                ? `${transportName.substring(0, MAX_TRANSPORT_PLACE_NAME_LENGHT)}...`
                : transportName;
        };

        if (!transport?.id && !transportNameChanged) {
            const transportName = transport?.places
                .filter((p, i) => p.name && (i === 0 || i === (this.state.transport?.places?.length ?? 0) - 1))
                .map(p => {
                    const address = p.addressStructured?.[0];
                    const name =
                        address?.postalCode && address?.town && address?.countryCode
                            ? `${address.postalCode} ${address.town} (${address.countryCode.toUpperCase()})`
                            : undefined;

                    return name ? getTransportName(name) : getTransportName(p.name!);
                })
                .join(' - ');

            this.logic.schedulingRoutePlanner().changeName(transportName);
        }
    };

    private _onFilterChange = (filter: SearchFilter, searchText: string) => {
        this.setState({ filter }, () => {
            this._onAutocompleteChange(searchText, false, false);
        });
    };

    private _onAvoidsChange = (countryCode: string, avoidType: string, value: boolean) => {
        this.setState({ loading: true });

        const avoids = { ...this.state.avoids };

        avoids[countryCode] = { ...avoids[countryCode], [avoidType]: value };

        this.setState({ avoids: avoids }, () => {
            this.logic
                .schedulingRoutePlanner()
                .onChangeAvoids(avoids)
                .then(() => {
                    this.setState({ loading: false });
                });
        });
    };

    private async _updateTollCosts() {
        const polyline = this.state.transport?.routePolyline;

        this.setState({
            tollCostsLoading: true
        });

        if (polyline) {
            try {
                // const currency = this.state.transport?.costPerKm?.currency;
                const currency = this.props.currency;

                let profile: CompanyVehicle | VehicleProfile | undefined;
                if (this.state.vehicleProfile?.type === VehicleProfileType.Custom) {
                    profile = this.state.vehicles?.find(vehicle => vehicle.vehicleId === this.state.vehicleProfile?.id);
                } else {
                    profile = this.state.profiles?.find(
                        profile => profile.vehicleProfileId === this.state.vehicleProfile?.id
                    );
                }

                const tollCosts = await this.logic
                    .apiService()
                    .tolls()
                    .getTollCosts(polyline, currency, undefined, profile);

                this.setState({
                    tollCosts,
                    tollCostsLoading: false
                });
            } catch (err) {
                if (axios.isCancel(err)) {
                    console.warn(err);
                    return;
                }

                console.error('Cannot get toll costs', err);
                this.logic.userEvents().alert(AlertType.FAILED_TOLL_COST, 'Cannot get toll costs');

                Notification.error({
                    message: 'Toll Costs Error',
                    description: err.content?.message || err?.message
                });

                this.setState({
                    tollCostsLoading: false
                });
            }
        } else {
            this.setState({
                tollCostsLoading: false
            });
        }
    }

    private _toggleTollCostsDetail = async (checked: boolean) => {
        return new Promise(resolve => {
            if (this.state.transport?.routePolyline) {
                this.setState(
                    {
                        tollCostsDetailVisible: checked,
                        includeTollCosts: checked
                    },
                    () => {
                        if (this.state.tollCostsDetailVisible) {
                            this._updateTollCosts().then(() => {
                                resolve('toll costs updated');
                            });
                        }
                    }
                );
            }
        });
    };

    private _showCreateVehicleModal = () => {
        if (this.props.isLoggedIn) {
            this.setState({ createVehicleModal: true });
        } else {
            this.props.history.push({ search: '?login=true' });
        }
    };

    private _cancelCreateVehicleModal = () => {
        this.setState({ createVehicleModal: false });
    };

    private _setDefaultVehicleProfile(profiles: VehicleProfile[]) {
        const profile = profiles.find(profile =>
            this.state.vehicleProfile && this.state.vehicleProfile.type === VehicleProfileType.Default
                ? profile.name === this.state.vehicleProfile?.name
                : profile.name === DEFAULT_VEHICLE_PROFILE
        );

        if (profile) {
            this.setState(
                {
                    vehicleProfile: {
                        id: profile?.vehicleProfileId!,
                        type: VehicleProfileType.Default,
                        name: profile.name,
                        costPerKm: {
                            cost: profile?.costPerKm || TOLL_COST_DEFAULT_VALUE,
                            currency: this.props.currency
                        }
                    }
                },
                async () => {
                    await this.logic.schedulingRoutePlanner().setVehicleProfile(profile?.vehicleProfileId!);
                    this.logic.schedulingRoutePlanner().setTransportCostPerKM(this.state?.vehicleProfile?.costPerKm);
                }
            );
        }
    }

    private async _loadVehiclesProfiles() {
        const [profiles, vehicles] = await this.props.logic.schedulingRoutePlanner().fetchVehiclesAndProfiles();

        if (!this.props.isLoggedIn) {
            this.setState({ vehicles: [], profiles });
            profiles?.length && this._setDefaultVehicleProfile(profiles);
        } else {
            this.setState({ vehicles, profiles });

            const profileId = this.state.vehicleProfile?.id;

            if (
                !vehicles.some(vehicle => vehicle.vehicleId === profileId) &&
                !profiles.some(profile => profile.vehicleProfileId === profileId)
            ) {
                profiles?.length && this._setDefaultVehicleProfile(profiles);
            }
        }
    }

    private _onPlannerDelete = () => {
        if (this.state.transport?.state === TransportState.Active) {
            this._showActiveTransportEditModal();
            return;
        }

        Modal.confirm({
            title: this.props.t('Planner.deleteHeader'),
            content: this.props.t('Planner.deleteConfirm'),
            onOk: () => this._onDeleteConfirm(this.state.transport?.id?.toString()),
            okText: this.props.t('common.delete')
        });
    };

    private _onDeleteConfirm = (transportId: string | undefined) => {
        if (!transportId) {
            return;
        }

        this.setState({
            loading: true
        });

        this.logic
            .schedulingRouteOverview()
            .removeTransport(transportId)
            .then(() => {
                this.setState(
                    {
                        loading: false
                    },
                    () => {
                        this._onPlannerCancel();

                        Notification.success({
                            message: this.props.t('common.deleted'),
                            description: this.props.t('DispatcherBoardDetail.deleteSuccess')
                        });
                    }
                );

                this.logic.userEvents().deleteTransport(transportId);
            })
            .catch(err => {
                console.error(err);
                Notification.error({
                    message: err.message
                });
            });
    };

    private _setAvoids(transportAvoids?: string) {
        const avoids: Avoids = {};

        if (transportAvoids) {
            transportAvoids.split('|').forEach(transportAvoid => {
                const avoid = transportAvoid.split(':');
                avoids[avoid[0]] = { ...avoids[avoid[0]], [avoid[1]]: true };
            });
        }

        this.setState({ avoids }, () => {
            this.logic.schedulingRoutePlanner().onChangeAvoids(this.state.avoids, false);
        });
    }

    private _routePlannedEvent(event: UserEvent) {
        const stateObj = {
            transport: this.logic.schedulingRoutePlanner().getTransport(),
            toll: this.state.includeTollCosts,
            tollCost: this.state.tollCosts?.totalCost,
            currency: this.props.currency,
            avoids: this.state.avoids,
            euroRate: this.logic.poi().currencies?.find(cur => cur.code === 'EUR')?.latestExchangeRate?.[0].rate
        };

        if (event === UserEvent.ROUTE_PLANNED) {
            this.props.logic.userEvents().plannedTransport(stateObj);
        } else if (event === UserEvent.TRANSPORT_SAVED) {
            this.logic.userEvents().addTransport(stateObj);
        } else {
            this.logic.userEvents().updateTransport(stateObj);
        }
    }

    private _initRoute(urlSearchParams?: URLSearchParams) {
        const params = urlSearchParams ?? new URLSearchParams(this.props.history.location.search);
        const editId = params.get('editId');
        const startDate = params.get('startDate');
        const endDate = params.get('endDate');
        const vehicleId = params.get('vehicleId');
        const transport = params.get('transport');

        if (editId) {
            this.setState({
                isEditTransport: true,
                transportUpdated: false,
                avoids: {},
                backUrl:
                    params.get('redirectedFrom') === 'calendar'
                        ? `${RouteNames.SCHEDULING_DISPATCHER_BOARD_CALENDAR}?selected=${editId}&startDate=${startDate}&endDate=${endDate}`
                        : `${RouteNames.SCHEDULING_DISPATCHER_BOARD_TABLE}?startDate=${startDate}&endDate=${endDate}`
            });

            this.logic.schedulingRoutePlanner().loadTransport(editId);
        } else if (vehicleId) {
            this.setState(() => ({
                loading: true
            }));

            this.logic
                .schedulingRoutePlanner()
                .initTransportForVehicle(vehicleId)
                .then(vehicle => {
                    this.setState({
                        loading: false,
                        isEditTransport: false,
                        backUrl: `${RouteNames.TRACKING}?vehicleId=${vehicleId}`,
                        vehicleProfile: {
                            id: Number(vehicle.vehicleId),
                            type: VehicleProfileType.Custom,
                            name: vehicle.registrationNumber,
                            costPerKm: {
                                cost: vehicle?.costPerKm || TOLL_COST_DEFAULT_VALUE,
                                currency: this.props.currency
                            }
                        }
                    });
                });
        } else if (transport) {
            this._initTransportFromQueryParams(transport);
        } else {
            this.logic.schedulingRoutePlanner().reloadRoute();
        }
    }

    private _afterCreateVehicle = (vehicle: CompanyVehicle) => {
        this.props.logic.userEvents().addVehicle(vehicle, InitiatedFrom.PLANNER_VEHICLE_SELECT_ADD_VEHICLE_BUTTON);
    };

    private _destroy() {
        this.setState({ visible: false, transport: undefined, loadedTransport: undefined });
        this.logic.map().destroyRoute();
        this.logic.map().routePlanningMode(false);
        window.removeEventListener('resize', this._updateDimensions);
    }

    private _noSearchResultEvent = debounce((searchTerm: string) => {
        this.props.logic.userEvents().alert(AlertType.FAILED_SEARCH, 'No results available', { searchTerm });
    }, 5000);

    private _onChangeView = (view: PlannerView) => {
        this.setState({ view, visible: view === PlannerView.ROUTE_PLANNER });
    };

    private _updateDimensions = debounce(() => {
        this.setState({ dimensions: { width: window.innerWidth, height: window.innerHeight } }, () => {
            if (this.state.dimensions.width > TABLET_MAX_WIDTH) {
                this.setState({ visible: this.props.location.pathname === RouteNames.SCHEDULING_PLANNER });
            }

            this._setMapPadding();

            this.setState({ isMobileWidth: this.state.dimensions.width <= MOBILE_MAX_WIDTH });
        });
    }, 300);

    private _setMapPadding() {
        if (this.state.dimensions.width <= TABLET_MAX_WIDTH) {
            this.props.logic.map().setPadding(MOBILE_MAP_PADDING);
            this.props.logic.map().routing().setPadding(MOBILE_MAP_PADDING);
        } else {
            this.props.logic.map().setPadding(DEFAULT_MAP_PADDING);
            this.props.logic.map().routing().setPadding(ROUTING_MAP_PADDING);
        }

        this.logic.map().routing().fitRoute();
    }

    _toggleAutocompleteMobileMenu = (isMenuOpen: boolean) => {
        this.setState({ isAutocompleteMobileOpen: this.state.isMobileWidth && isMenuOpen });
    };

    _plannerSwitchVisible() {
        return !(
            this.state.isAutocompleteMobileOpen ||
            this.state.showRouteAvoids ||
            (this.state.task && this.state.placeId)
        );
    }

    private _showCreateNewPlace = () => {
        if (!this.props.isLoggedIn) {
            this.props.history.push({ search: '?login=true' });
            return;
        }

        this.setState({ createNewPlace: { visible: true } }, () => {
            this.logic.map().sidebarsVisible$.next(false);
        });

        this.logic.map().poiOff();
        this.logic.map().poi().destroy();
        this.logic
            .map()
            .poi()
            .getCoordsWithCrosshair((lat, lng) => {
                this.setState(state => ({
                    createNewPlace: {
                        ...state.createNewPlace,
                        model: {
                            center: { lat: lat, lng: lng }
                        }
                    }
                }));
            });
    };

    private _onCloseCreateNewPlace = () => {
        this.setState({ createNewPlace: { visible: false } });

        this.logic.map().poiOff();
        this.logic.map().poi().destroy();
    };

    private async _updateTransportQueryParams() {
        const transport = this.state.transport;
        const params = new URLSearchParams(this.props.history.location.search);

        if (transport?.places.length) {
            const transportParams = {
                places: transport?.places.map(p => ({
                    name: p.name,
                    center: p.center,
                    type: p.type,
                    tasks: p.tasks?.length ? p.tasks : undefined
                })),
                // vehicle: this.state.vehicleProfile,
                avoids: transport?.avoids || undefined
            };

            params.set('transport', JSON.stringify(transportParams));
        } else {
            params.delete('transport');
        }

        this.props.history.push({
            search: params.toString()
        });
    }

    private async _initTransportFromQueryParams(transport: string) {
        const transportObj: TransportQueryParams = JSON.parse(transport);

        if (!transportObj.places?.length) {
            return;
        }

        for (const p of transportObj.places) {
            const placeId = await this.props.logic
                .schedulingRoutePlanner()
                .addPlaceToTransport(p.name!, p.center, p.type!, undefined, false);

            if (p.tasks) {
                this._onPlacesTaskSave(p.tasks[0], placeId, false);
            }
        }

        transportObj.avoids && this._setAvoids(transportObj.avoids);
        this.logic.schedulingRoutePlanner().reloadRoute();
    }

    private _showActiveTransportEditModal = () => {
        Modal.info({
            title: this.props.t('ActiveTransportEditModal.title'),
            content: this.props.t('ActiveTransportEditModal.content')
        });
    };
}

function mapSuggestions(suggestions: PlaceSuggestion[]) {
    return suggestions?.map(s => ({
        label: s.label || '',
        value: s.id || '',
        source: s.source || PlaceSuggestionSource.Sygic
    }));
}

export default withTranslation()(withRouter(PlannerModule));
