import { Conf, confDefault } from 'conf';
import { FavoritesApi } from './domains/FavoritesApi';
import { HttpClient } from './common/HttpClient';
import { PlacesApi } from './domains/PlacesApi';
import { CompanyApi } from './domains/CompanyApi';
import { VehiclesApi } from './domains/VehiclesApi';
import { TollsApi } from './domains/TollsApi';
import { FuelApi } from './domains/FuelApi';
import { UsersApi } from './domains/UsersApi';
import { FuelCardsApi } from './domains/FuelCardsApi';
import { CountryApi } from './domains/CountryApi';
import { GeocodingApi } from './domains/GeocodingApi';
import { StorageApi } from './domains/StorageApi';
import { SearchApi } from './domains/SearchApi';
import { RoutingApi } from './domains/RoutingApi';
import { authStore } from 'stores/auth/AuthStore';
import { createContext, PropsWithChildren, useContext } from 'react';
import { TransportsApi } from './domains/TransportsApi';
import { ShareEtaApi } from './domains/ShareEtaApi';
import { CONF } from 'config/conf';

const conf: Conf = { ...confDefault, ...(CONF as any) };

class ApiService {
    public readonly httpClient: HttpClient;
    private _favoritesApi?: FavoritesApi;
    private _placesApi?: PlacesApi;
    private _companyApi?: CompanyApi;
    private _vehiclesApi?: VehiclesApi;
    private _tollsApi?: TollsApi;
    private _fuelApi?: FuelApi;
    private _usersApi?: UsersApi;
    private _fuelCardsApi?: FuelCardsApi;
    private _countryApi?: CountryApi;
    private _geocodingApi?: GeocodingApi;
    private _storageApi?: StorageApi;
    private _searchApi?: SearchApi;
    private _routingApi?: RoutingApi;
    private _transportApi?: TransportsApi;
    private _shareEtaApi?: ShareEtaApi;

    constructor(private _conf: Conf['api'], httpClient: HttpClient) {
        this.httpClient = httpClient;
    }

    favorites() {
        if (!this._favoritesApi) {
            this._favoritesApi = new FavoritesApi(this._conf, this.httpClient);
        }
        return this._favoritesApi;
    }

    places() {
        if (!this._placesApi) {
            this._placesApi = new PlacesApi(this._conf, this.httpClient);
        }
        return this._placesApi;
    }

    company() {
        if (!this._companyApi) {
            this._companyApi = new CompanyApi(this._conf, this.httpClient);
        }
        return this._companyApi;
    }

    vehicles() {
        if (!this._vehiclesApi) {
            this._vehiclesApi = new VehiclesApi(this._conf, this.httpClient);
        }
        return this._vehiclesApi;
    }

    tolls() {
        if (!this._tollsApi) {
            this._tollsApi = new TollsApi(this._conf, this.httpClient);
        }
        return this._tollsApi;
    }

    fuel() {
        if (!this._fuelApi) {
            this._fuelApi = new FuelApi(this._conf, this.httpClient);
        }
        return this._fuelApi;
    }

    users() {
        if (!this._usersApi) {
            this._usersApi = new UsersApi(this._conf, this.httpClient);
        }
        return this._usersApi;
    }

    fuelCards() {
        if (!this._fuelCardsApi) {
            this._fuelCardsApi = new FuelCardsApi(this._conf, this.httpClient);
        }
        return this._fuelCardsApi;
    }

    country() {
        if (!this._countryApi) {
            this._countryApi = new CountryApi(this._conf, this.httpClient);
        }
        return this._countryApi;
    }

    geocoding() {
        if (!this._geocodingApi) {
            this._geocodingApi = new GeocodingApi(this._conf, this.httpClient);
        }
        return this._geocodingApi;
    }

    storage() {
        if (!this._storageApi) {
            this._storageApi = new StorageApi(this._conf, this.httpClient);
        }
        return this._storageApi;
    }

    search() {
        if (!this._searchApi) {
            this._searchApi = new SearchApi(this._conf, this.httpClient);
        }
        return this._searchApi;
    }

    routing() {
        if (!this._routingApi) {
            this._routingApi = new RoutingApi(this._conf, this.httpClient);
        }
        return this._routingApi;
    }

    transports() {
        if (!this._transportApi) {
            this._transportApi = new TransportsApi(this._conf, this.httpClient);
        }
        return this._transportApi;
    }

    shareEta() {
        if (!this._shareEtaApi) {
            this._shareEtaApi = new ShareEtaApi(this._conf, this.httpClient);
        }
        return this._shareEtaApi;
    }
}

const apiService = new ApiService(conf.api, new HttpClient(authStore));

const ApiServiceContext = createContext(apiService);

const useApiService = () => useContext(ApiServiceContext);

const ApiServiceProvider = (props: PropsWithChildren<Record<string, unknown>>) => {
    const { children } = props;
    return <ApiServiceContext.Provider value={apiService}>{children}</ApiServiceContext.Provider>;
};

export { ApiService, apiService, useApiService, ApiServiceProvider };
