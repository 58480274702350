/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MonitoredObjectPartialUpdate
 */
export interface MonitoredObjectPartialUpdate {
    /**
     * 
     * @type {number}
     * @memberof MonitoredObjectPartialUpdate
     */
    moid: number;
    /**
     * 
     * @type {object}
     * @memberof MonitoredObjectPartialUpdate
     */
    costPerKm?: object;
    /**
     * 
     * @type {number}
     * @memberof MonitoredObjectPartialUpdate
     */
    monitoringDevice?: number;
}

export function MonitoredObjectPartialUpdateFromJSON(json: any): MonitoredObjectPartialUpdate {
    return MonitoredObjectPartialUpdateFromJSONTyped(json, false);
}

export function MonitoredObjectPartialUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitoredObjectPartialUpdate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'moid': json['moid'],
        'costPerKm': !exists(json, 'cost_per_km') ? undefined : json['cost_per_km'],
        'monitoringDevice': !exists(json, 'monitoring_device') ? undefined : json['monitoring_device'],
    };
}

export function MonitoredObjectPartialUpdateToJSON(value?: MonitoredObjectPartialUpdate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'moid': value.moid,
        'cost_per_km': value.costPerKm,
        'monitoring_device': value.monitoringDevice,
    };
}


