import { Drawer, DrawerProps } from 'antd';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import { ReactNode } from 'react';

interface Props extends DrawerProps {
    children?: ReactNode;
}

function EnhancedDrawer({ children, ...props }: Props) {
    const { isMobileWidth } = useWindowDimensions();

    return (
        <Drawer
            closeIcon={
                isMobileWidth ? (
                    <div className="ant-btn rl-button-action-type">
                        <i className="rl-icon-chevron-left" />
                    </div>
                ) : (
                    <i className="rl-icon-close" />
                )
            }
            {...props}
            className={props.className ? 'rl-drawer ' + props.className : 'rl-drawer'}
        >
            {children}
        </Drawer>
    );
}

export default EnhancedDrawer;
