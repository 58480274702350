/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InlineResponse20036,
    InlineResponse20036FromJSON,
    InlineResponse20036ToJSON,
    ReadOnlyUserTokenNested,
    ReadOnlyUserTokenNestedFromJSON,
    ReadOnlyUserTokenNestedToJSON,
    UserToken,
    UserTokenFromJSON,
    UserTokenToJSON,
    WriteOnlyUserTokenNested,
    WriteOnlyUserTokenNestedFromJSON,
    WriteOnlyUserTokenNestedToJSON,
} from '../models';

export interface UserTokenCreateRequest {
    data: WriteOnlyUserTokenNested;
}

export interface UserTokenDeleteRequest {
    id: number;
}

export interface UserTokenListRequest {
    token?: string;
    userClient?: string;
    limit?: number;
    offset?: number;
}

export interface UserTokenPartialUpdateRequest {
    id: number;
    data: WriteOnlyUserTokenNested;
}

export interface UserTokenReadRequest {
    id: number;
}

export interface UserTokenUpdateRequest {
    id: number;
    data: WriteOnlyUserTokenNested;
}

export interface UserTokenUserTokenMiniListRequest {
    token?: string;
    userClient?: string;
}

/**
 * no description
 */
export class UserTokenApi extends runtime.BaseAPI {

    /**
     */
    async userTokenCreateRaw(requestParameters: UserTokenCreateRequest): Promise<runtime.ApiResponse<ReadOnlyUserTokenNested>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling userTokenCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/user-token/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyUserTokenNestedToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyUserTokenNestedFromJSON(jsonValue));
    }

    /**
     */
    async userTokenCreate(requestParameters: UserTokenCreateRequest): Promise<ReadOnlyUserTokenNested> {
        const response = await this.userTokenCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async userTokenDeleteRaw(requestParameters: UserTokenDeleteRequest): Promise<runtime.ApiResponse<ReadOnlyUserTokenNested>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userTokenDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/user-token/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyUserTokenNestedFromJSON(jsonValue));
    }

    /**
     */
    async userTokenDelete(requestParameters: UserTokenDeleteRequest): Promise<ReadOnlyUserTokenNested> {
        const response = await this.userTokenDeleteRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async userTokenListRaw(requestParameters: UserTokenListRequest): Promise<runtime.ApiResponse<InlineResponse20036>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.token !== undefined) {
            queryParameters['token'] = requestParameters.token;
        }

        if (requestParameters.userClient !== undefined) {
            queryParameters['user__client'] = requestParameters.userClient;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/user-token/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20036FromJSON(jsonValue));
    }

    /**
     */
    async userTokenList(requestParameters: UserTokenListRequest): Promise<InlineResponse20036> {
        const response = await this.userTokenListRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async userTokenPartialUpdateRaw(requestParameters: UserTokenPartialUpdateRequest): Promise<runtime.ApiResponse<ReadOnlyUserTokenNested>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userTokenPartialUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling userTokenPartialUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/user-token/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyUserTokenNestedToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyUserTokenNestedFromJSON(jsonValue));
    }

    /**
     */
    async userTokenPartialUpdate(requestParameters: UserTokenPartialUpdateRequest): Promise<ReadOnlyUserTokenNested> {
        const response = await this.userTokenPartialUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async userTokenReadRaw(requestParameters: UserTokenReadRequest): Promise<runtime.ApiResponse<ReadOnlyUserTokenNested>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userTokenRead.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/user-token/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyUserTokenNestedFromJSON(jsonValue));
    }

    /**
     */
    async userTokenRead(requestParameters: UserTokenReadRequest): Promise<ReadOnlyUserTokenNested> {
        const response = await this.userTokenReadRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async userTokenUpdateRaw(requestParameters: UserTokenUpdateRequest): Promise<runtime.ApiResponse<ReadOnlyUserTokenNested>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userTokenUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling userTokenUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/user-token/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyUserTokenNestedToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyUserTokenNestedFromJSON(jsonValue));
    }

    /**
     */
    async userTokenUpdate(requestParameters: UserTokenUpdateRequest): Promise<ReadOnlyUserTokenNested> {
        const response = await this.userTokenUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async userTokenUserTokenMiniListRaw(requestParameters: UserTokenUserTokenMiniListRequest): Promise<runtime.ApiResponse<Array<UserToken>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.token !== undefined) {
            queryParameters['token'] = requestParameters.token;
        }

        if (requestParameters.userClient !== undefined) {
            queryParameters['user__client'] = requestParameters.userClient;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/user-token/user-token-mini/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserTokenFromJSON));
    }

    /**
     */
    async userTokenUserTokenMiniList(requestParameters: UserTokenUserTokenMiniListRequest): Promise<Array<UserToken>> {
        const response = await this.userTokenUserTokenMiniListRaw(requestParameters);
        return await response.value();
    }

}
