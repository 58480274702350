import { LatLng } from 'common/model/geo';

export interface GeoJsonPointType {
    bbox?: number[];
    coordinates?: number[];
    type?: string;
}

export function toGeoJsonPointTypeInput(position: LatLng) {
    return {
        type: 'Point',
        coordinates: [position.lng, position.lat]
    };
}

export function latLngFromGeoJsonPointType(geoJson: GeoJsonPointType): LatLng {
    return {
        lat: geoJson.coordinates ? geoJson.coordinates[1] : -1,
        lng: geoJson.coordinates ? geoJson.coordinates[0] : -1
    };
}

export function centerToLatLngObject(center: [number, number]): LatLng {
    return {
        lat: center[1],
        lng: center[0]
    };
}

export function centerToLatLngTuple(center: LatLng): [number, number] {
    return [center.lng, center.lat];
}
