import { Col, Form, Row } from 'antd';
import { Button, Input, Select } from 'components/base/controls';
import { Country } from 'services/api/domains/CountryApi';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Prompt } from 'react-router-dom';
import selectors from 'qa-selectors';

interface Props {
    values?: CompanyFormModel;
    countryList: Country[];
    loading?: boolean;
    onSubmit: (values: CompanyFormModel) => Promise<void>;
}

export interface CompanyFormModel {
    name: string;
    taxId?: string;
    fleetSize?: number;
    street?: string;
    streetNumber?: string;
    city?: string;
    postalCode?: string;
    countryCode?: Country['code'];
    phone?: string;
    email?: string;
}

export default function CompanyForm(props: Props) {
    const { t } = useTranslation();
    const [form] = Form.useForm<CompanyFormModel>();
    const [formTouched, setFormTouched] = useState(false);

    function _onFinish(formValues: CompanyFormModel) {
        props.onSubmit(formValues).then(() => setFormTouched(false));
    }

    useEffect(() => {
        form.resetFields();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.values]);

    function _onValuesChange() {
        setFormTouched(form.isFieldsTouched());
    }

    return (
        <div className="rl-settings-company-form">
            <Prompt when={formTouched} message={t('common.leaveConfirm')} />

            <Form<CompanyFormModel>
                form={form}
                id="companyForm"
                layout="vertical"
                name="companyForm"
                initialValues={{
                    ...props.values
                }}
                onFinish={_onFinish}
                onValuesChange={_onValuesChange}
            >
                <section>
                    <Row gutter={[24, 0]}>
                        <Col span={24}>
                            <Form.Item label={t('CompanyForm.name')} name="name" rules={[{ required: true }]}>
                                <Input type="text" data-qa={selectors.companyModule.companyNameInput} />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item label={t('CompanyForm.vatNumber')} name="taxId">
                                <Input type="text" data-qa={selectors.companyModule.vatNumberInput} />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item label={t('CompanyForm.fleetSize')} name="fleetSize" rules={[{ type: 'number' }]}>
                                <Input.Number data-qa={selectors.companyModule.fleetSizeInput} />
                            </Form.Item>
                        </Col>
                    </Row>
                </section>

                <section>
                    <Row>
                        <label className="section-title">{t('CompanyForm.companyAddress')}</label>
                    </Row>

                    <Row gutter={[24, 0]}>
                        <Col span={15} lg={18}>
                            <Form.Item label={t('CompanyForm.street')} name="street">
                                <Input type="text" data-qa={selectors.companyModule.streetAddressInput} />
                            </Form.Item>
                        </Col>

                        <Col span={9} lg={6}>
                            <Form.Item label={t('CompanyForm.streetNo')} name="streetNumber">
                                <Input data-qa={selectors.companyModule.streetNumberInput} />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={[24, 0]}>
                        <Col span={15} lg={18}>
                            <Form.Item label={t('CompanyForm.city')} name="city">
                                <Input type="text" data-qa={selectors.companyModule.cityInput} />
                            </Form.Item>
                        </Col>

                        <Col span={9} lg={6}>
                            <Form.Item label={t('CompanyForm.postalCode')} name="postalCode">
                                <Input data-qa={selectors.companyModule.postalCodeInput} />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row>
                        <Col span={24}>
                            <Form.Item label={t('CompanyForm.country')} name="countryCode">
                                <Select qa={selectors.companyModule.countrySelect}>
                                    {props.countryList.map((v, i) => (
                                        <Select.Option key={i} value={v.code}>
                                            {v.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </section>

                <section>
                    <Row>
                        <label className="section-title">{t('CompanyForm.contact')}</label>
                    </Row>

                    <Row gutter={[24, 0]}>
                        <Col span={12}>
                            <Form.Item label={t('CompanyForm.phone')} name="phone">
                                <Input type="phone" data-qa={selectors.companyModule.telephoneInput} />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item label={t('CompanyForm.email')} name="email">
                                <Input type="email" data-qa={selectors.companyModule.emailInput} />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row>
                        <Button
                            type="primary"
                            size="large"
                            htmlType="submit"
                            loading={props.loading ? { delay: 500 } : false}
                            disabled={!formTouched}
                            data-qa={selectors.companyModule.saveButton}
                        >
                            {t('common.saveChanges')}
                        </Button>
                    </Row>
                </section>
            </Form>
        </div>
    );
}
