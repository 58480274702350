import { CompanyVehicle } from 'services/api/domains/VehiclesApi';

// TODO: JVN find better way to convert units
export function convertVehicleValues(model: CompanyVehicle, coef: number): CompanyVehicle {
    return {
        ...model,
        totalLength: model.totalLength * coef,
        totalWeight: model.totalWeight * coef,
        width: model.width * coef,
        height: model.height * coef,
        axleWeight: model.axleWeight * coef
    };
}
