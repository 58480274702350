import { Col, Row } from 'antd';
import { Button } from 'components/base/controls';
import { Trans, useTranslation } from 'react-i18next';

const DriversBlank = () => {
    const { t } = useTranslation();

    return (
        <div className="rl-drivers-blank">
            <h3>{t('SettingsDriversBlank.title2')}</h3>

            <div className="rl-drivers-blank-features">
                <Row justify="space-between" gutter={48}>
                    <Col lg={6} sm={24}>
                        <div className="rl-settings-blank-feature">
                            <img src="/img/settings/drivers/position.svg" alt="Position icon" />
                            <span className="rl-settings-blank-feature-title">
                                {t('SettingsDriversBlank.positionTitle')}
                            </span>
                            <span className="rl-settings-blank-feature-text">
                                {t('SettingsDriversBlank.positionText')}
                            </span>
                        </div>
                    </Col>
                    <Col lg={6} sm={24}>
                        <div className="rl-settings-blank-feature">
                            <img src="/img/settings/drivers/notes.svg" alt="Notes icon" />
                            <span className="rl-settings-blank-feature-title">
                                {t('SettingsDriversBlank.notesTitle')}
                            </span>
                            <span className="rl-settings-blank-feature-text">
                                {t('SettingsDriversBlank.notesText')}
                            </span>
                        </div>
                    </Col>
                    <Col lg={6} sm={24}>
                        <div className="rl-settings-blank-feature">
                            <img src="/img/settings/drivers/chat.svg" alt="Chat icon" />
                            <span className="rl-settings-blank-feature-title">
                                {t('SettingsDriversBlank.chatTitle')}
                            </span>
                            <span className="rl-settings-blank-feature-text">{t('SettingsDriversBlank.chatText')}</span>
                        </div>
                    </Col>
                    <Col lg={6} sm={24}>
                        <div className="rl-settings-blank-feature">
                            <img src="/img/settings/drivers/routes.svg" alt="Routes icon" />
                            <span className="rl-settings-blank-feature-title">
                                {t('SettingsDriversBlank.routesTitle')}
                            </span>
                            <span className="rl-settings-blank-feature-text">
                                {t('SettingsDriversBlank.routesText')}
                            </span>
                        </div>
                    </Col>
                </Row>
            </div>

            <div className="rl-drivers-blank-footer">
                <div className="rl-drivers-blank-footer-content">
                    <img src="/img/settings/drivers/rl-logo.svg" alt="RoadLords logo" />
                    <span>
                        <Trans i18nKey="SettingsDriversBlank.footerText">
                            These features and much more are available with the{' '}
                            <Button type="link" href="https://roadlords.com/download" target="_blank">
                                RoadLords Truck navigation
                            </Button>{' '}
                            app installed on your drivers’ devices. We are already trusted by 100 000+ of drivers with
                            thousands more installing the app every day ...
                        </Trans>
                    </span>
                </div>
            </div>
        </div>
    );
};

export default DriversBlank;
