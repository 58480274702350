import { Logic } from 'logic/logic';
import { BlankModule, BlankModuleType } from 'modules/BlankModule/BlankModule';
import { Redirect, Route, Switch } from 'react-router';
import { ProtectedRoute } from 'router/ProtectedRoute';
import { RouteNames } from 'router/routes';
import { useStore } from 'stores';
import { isMobileDevice } from 'utils/helpers/navigator';
import CalendarViewModule from './CalendarViewModule/CalendarViewModule';
import TableViewModule from './TableViewModule/TableViewModule';
import { DispatcherBoardNavbarMobile } from './ui/DispatcherBoardNavbarMobile';

interface Props {
    logic: Logic;
}

export const DispatcherBoardModule = (props: Props) => {
    const store = useStore();

    return (
        <div className="rl-module rl-dispatcher-board-module">
            {isMobileDevice() && store.appStore.isLoggedIn && <DispatcherBoardNavbarMobile />}

            <Switch>
                <ProtectedRoute
                    exact
                    path={[RouteNames.SCHEDULING_ORDERS, RouteNames.SCHEDULING_DISPATCHER_BOARD_TABLE]}
                    auth={store.authStore}
                >
                    <TableViewModule logic={props.logic} />
                </ProtectedRoute>

                <Route exact path={RouteNames.SCHEDULING_DISPATCHER_BOARD_CALENDAR}>
                    {store.appStore.isLoggedIn ? (
                        <CalendarViewModule logic={props.logic} />
                    ) : (
                        <BlankModule type={BlankModuleType.DISPATCHER} />
                    )}
                </Route>

                <Redirect to={RouteNames.SCHEDULING_DISPATCHER_BOARD_CALENDAR} />
            </Switch>
        </div>
    );
};
