//http://maps.api.sygic.com/vectorstyles/mnrroadlords/spritesheet.png
//http://maps.api.sygic.com/vectorstyles/mnrroadlords/spritesheet.json
import mapboxgl from 'mapbox-gl';

export class RestrictionLayer {
    public static init(map: mapboxgl.Map, apiKey: string) {
        if (!map.getSource('sygic-road-restrictions-vectors')) {
            map.addSource('sygic-road-restrictions-vectors', {
                type: 'vector',
                tiles: [`https://maps.api.sygic.com/vrestriction/${apiKey}/{z}/{x}/{y}`],
                minzoom: 14,
                maxzoom: 16
            });
        }

        const popup = new mapboxgl.Popup({
            closeButton: false,
            closeOnClick: false,
            className: 'restriction-mapbox-popup',
            offset: { bottom: [0, -15] }
        });

        map.on('mouseenter', 'road-restrictions-icons', function (e) {
            const feature = e?.features?.[0];

            if (!feature) return;

            map.getCanvas().style.cursor = 'pointer';

            map.setFilter('road-restrictions-line-focus', ['==', 'id', feature.properties?.id]);

            if (feature.properties?.name) {
                const name = '<strong>' + feature.properties?.name + '</strong>';
                const coordinates = (feature.geometry as any)?.coordinates.slice();

                popup.setLngLat(coordinates).setHTML(name).addTo(map);
            }
        });

        map.on('mouseleave', 'road-restrictions-icons', function () {
            map.getCanvas().style.cursor = 'initial';
            map.setFilter('road-restrictions-line-focus', ['==', 'id', 'select-nothing']);
            popup.remove();
        });

        this.enableRestrictions(map); // layer visible by default
    }

    public static enableRestrictions(map: mapboxgl.Map) {
        if (!map.getLayer('road-restrictions-lines')) {
            map.addLayer(
                {
                    id: 'road-restrictions-lines',
                    type: 'line',
                    minzoom: 14,
                    source: 'sygic-road-restrictions-vectors',
                    'source-layer': 'restrictions-lines',
                    paint: {
                        'line-color': '#FF0000',
                        'line-opacity': 0.5,
                        'line-width': 3
                    }
                },
                'admin_level_21'
            );

            map.addLayer(
                {
                    id: 'road-restrictions-line-focus',
                    type: 'line',
                    minzoom: 14,
                    source: 'sygic-road-restrictions-vectors',
                    'source-layer': 'restrictions-lines',
                    filter: ['==', 'id', 'select-nothing'],
                    paint: {
                        'line-color': '#FF0000',
                        'line-width': 4
                    }
                },
                'admin_level_21'
            );

            map.addLayer(
                {
                    id: 'road-restrictions-icons',
                    type: 'symbol',
                    minzoom: 14,
                    source: 'sygic-road-restrictions-vectors',
                    'source-layer': 'restrictions-icons',
                    layout: {
                        'icon-allow-overlap': false,
                        'icon-ignore-placement': false,
                        'icon-size': ['number', ['case', ['==', ['get', 'category'], 'NoCar'], 0.75, 1.0]],
                        'icon-image': [
                            'case',
                            ['==', ['get', 'category'], 'NoCar'],
                            'no_car36',
                            ['==', ['get', 'category'], 'NoTruck'],
                            'no_truck28',
                            ['==', ['get', 'category'], 'HazmatGeneral'],
                            'hazmat28',
                            ['==', ['get', 'category'], 'HazmatExplosiveMaterial'],
                            'hazmat28',
                            ['==', ['get', 'category'], 'HazmatHarmfulToWater'],
                            'hazmat_water28',
                            ['==', ['get', 'category'], 'MaxWeightTotal'],
                            'weight28',
                            ['==', ['get', 'category'], 'MaxWeightPerAxle'],
                            'weight_axle28',
                            ['==', ['get', 'category'], 'MaxLength'],
                            'length28',
                            ['==', ['get', 'category'], 'MaxWidth'],
                            'width28',
                            ['==', ['get', 'category'], 'MaxHeight'],
                            'height28',
                            ['==', ['get', 'category'], 'Tunnel'],
                            'tunnel28',
                            'restriction-icon-small-default'
                        ],
                        'symbol-placement': 'point',
                        'symbol-sort-key': [
                            'number',
                            [
                                'case',
                                ['==', ['get', 'category'], 'NoCar'],
                                11,
                                ['==', ['get', 'category'], 'NoTruck'],
                                10,
                                ['==', ['get', 'category'], 'HazmatGeneral'],
                                9,
                                ['==', ['get', 'category'], 'HazmatExplosiveMaterial'],
                                8,
                                ['==', ['get', 'category'], 'HazmatHarmfulToWater'],
                                7,
                                ['==', ['get', 'category'], 'MaxWeightTotal'],
                                6,
                                ['==', ['get', 'category'], 'MaxWeightPerAxle'],
                                5,
                                ['==', ['get', 'category'], 'MaxLength'],
                                4,
                                ['==', ['get', 'category'], 'MaxWidth'],
                                3,
                                ['==', ['get', 'category'], 'MaxHeight'],
                                1,
                                ['==', ['get', 'category'], 'Tunnel'],
                                2,
                                0
                            ]
                        ]
                        // 'text-offset': [0.0, 0.0],
                        // 'text-variable-anchor': ['center'],
                        // 'text-field': ['get', 'name'],
                        // 'text-font': ['Open Sans Regular'],
                        // 'text-size': 11
                    }
                },
                'state_label'
            );
        }
    }

    private static disableRestrictions(map: mapboxgl.Map) {
        map.removeLayer('road-restrictions-lines');
        map.removeLayer('road-restrictions-line-focus');
        map.removeLayer('road-restrictions-icons');
    }

    public static toggleRestrictions(map: mapboxgl.Map, visibile: boolean) {
        if (visibile) {
            this.enableRestrictions(map);
        } else {
            this.disableRestrictions(map);
        }
    }
}
