import { createIcon, pulsingDot } from '../utils';

export enum VehicleMarkerIcon {
    Vehicle = '/img/map-markers/rl-vehicle-marker.svg',
    VehiclesCluster = '/img/map-markers/rl-vehicles-cluster.svg'
}

export enum VehiclesLayers {
    VEHICLES_CLUSTER = 'vehicles-cluster',
    VEHICLES_CLUSTER_COUNT = 'vehicles-cluster-count',
    VEHICLES_CLUSTER_PULSING_DOT = 'vehicles-cluster-pulsing-dot',
    VEHICLES_UNCLUSTERED = 'vehicles-unclustered',
    VEHICLES_UNCLUSTERED_PULSING_DOT = 'vehicles-unclustered-pulsing-dot',
    VEHICLES_UNCLUSTERED_LABEL = 'vehicles-unclustered-label'
}

export class VehiclesLayer {
    public static async init(map: mapboxgl.Map) {
        await Promise.all([
            createIcon('vehicleIcon', VehicleMarkerIcon.Vehicle, map),
            createIcon('vehiclesClusterIcon', VehicleMarkerIcon.VehiclesCluster, map)
        ]);

        map.addImage('pulsing-dot', pulsingDot(map, 150), { pixelRatio: 2 });
        map.addImage('cluster-pulsing-dot', pulsingDot(map, 200), { pixelRatio: 2 });

        map.addSource('vehicles', {
            type: 'geojson',
            data: {
                type: 'FeatureCollection',
                features: []
            },
            cluster: true,
            clusterMaxZoom: 18,
            clusterRadius: 100,
            clusterProperties: {
                hasSelected: ['any', ['boolean', ['get', 'hovered'], false], ['boolean', ['get', 'selected'], false]]
            }
        });

        map.addLayer({
            id: VehiclesLayers.VEHICLES_CLUSTER_PULSING_DOT,
            type: 'symbol',
            source: 'vehicles',
            filter: ['all', ['has', 'point_count'], ['==', ['get', 'hasSelected'], true]],
            layout: {
                'icon-image': 'cluster-pulsing-dot',
                'icon-allow-overlap': true
            }
        });

        map.addLayer({
            id: VehiclesLayers.VEHICLES_CLUSTER,
            type: 'symbol',
            source: 'vehicles',
            filter: ['has', 'point_count'],
            layout: {
                'icon-image': 'vehiclesClusterIcon',
                'icon-allow-overlap': true
            }
        });

        map.addLayer({
            id: VehiclesLayers.VEHICLES_CLUSTER_COUNT,
            type: 'symbol',
            source: 'vehicles',
            filter: ['has', 'point_count'],
            layout: {
                'text-field': '{point_count_abbreviated}',
                'text-font': ['Open Sans Bold'],
                'text-size': 11,
                'text-offset': [1.4, -1.9],
                'text-allow-overlap': true
            },
            paint: {
                'text-color': '#394556'
            }
        });

        map.addLayer({
            id: VehiclesLayers.VEHICLES_UNCLUSTERED_PULSING_DOT,
            type: 'symbol',
            source: 'vehicles',
            filter: [
                'all',
                ['!', ['has', 'point_count']],
                ['any', ['==', ['get', 'hovered'], true], ['==', ['get', 'selected'], true]]
            ],
            layout: {
                'icon-image': 'pulsing-dot',
                'icon-allow-overlap': true
            }
        });

        map.addLayer({
            id: VehiclesLayers.VEHICLES_UNCLUSTERED_LABEL,
            type: 'symbol',
            source: 'vehicles',
            filter: ['!', ['has', 'point_count']],
            layout: {
                'text-field': '{registrationNumber}',
                'text-allow-overlap': false,
                'text-font': ['Open Sans Bold'],
                'text-size': 14,
                'text-offset': [0, -3]
            },
            paint: {
                'text-color': '#394556',
                'text-halo-color': '#ffffff',
                'text-halo-width': 2
            }
        });

        map.addLayer({
            id: VehiclesLayers.VEHICLES_UNCLUSTERED,
            type: 'symbol',
            source: 'vehicles',
            filter: ['!', ['has', 'point_count']],
            layout: {
                'icon-image': 'vehicleIcon',
                'icon-allow-overlap': true,
                'icon-rotate': ['number', ['get', 'angle'], 0]
            }
        });

        map.on('mouseenter', VehiclesLayers.VEHICLES_UNCLUSTERED, () => {
            map.getCanvas().style.cursor = 'pointer';
        });

        map.on('mouseleave', VehiclesLayers.VEHICLES_UNCLUSTERED, () => {
            map.getCanvas().style.cursor = '';
        });
    }
}
