/* tslint:disable */
/* eslint-disable */
/**
 * Main data new API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InlineResponse20018,
    InlineResponse20018FromJSON,
    InlineResponse20018ToJSON,
    InlineResponse20019,
    InlineResponse20019FromJSON,
    InlineResponse20019ToJSON,
    MonitoredObjectNested,
    MonitoredObjectNestedFromJSON,
    MonitoredObjectNestedToJSON,
    MonitoredObjectPartialUpdate,
    MonitoredObjectPartialUpdateFromJSON,
    MonitoredObjectPartialUpdateToJSON,
    ReadOnlyMonitoredObject,
    ReadOnlyMonitoredObjectFromJSON,
    ReadOnlyMonitoredObjectToJSON,
    ReadOnlyMonitoredObjectNested,
    ReadOnlyMonitoredObjectNestedFromJSON,
    ReadOnlyMonitoredObjectNestedToJSON,
    SafeDeleteResponse,
    SafeDeleteResponseFromJSON,
    SafeDeleteResponseToJSON,
    WriteOnlyMonitoredObject,
    WriteOnlyMonitoredObjectFromJSON,
    WriteOnlyMonitoredObjectToJSON,
} from '../models';

export interface MonitoredObjectBulkPartialUpdateRequest {
    data: Array<MonitoredObjectPartialUpdate>;
}

export interface MonitoredObjectCreateRequest {
    data: WriteOnlyMonitoredObject;
}

export interface MonitoredObjectDeleteRequest {
    id: number;
}

export interface MonitoredObjectListRequest {
    client?: string;
    deleted?: number;
    registrationNumberIcontains?: string;
    registrationNumberIexact?: string;
    monitoredObjectTypes?: string;
    limit?: number;
    offset?: number;
}

export interface MonitoredObjectNestedRequest {
    client?: string;
    deleted?: number;
    registrationNumberIcontains?: string;
    registrationNumberIexact?: string;
    monitoredObjectTypes?: string;
    limit?: number;
    offset?: number;
}

export interface MonitoredObjectNestedSingleRequest {
    id: number;
}

export interface MonitoredObjectPartialUpdateRequest {
    id: number;
    data: MonitoredObjectPartialUpdate;
}

export interface MonitoredObjectReadRequest {
    id: number;
}

export interface MonitoredObjectUnpairMonitoringDeviceRequest {
    id: number;
    data: object;
}

export interface MonitoredObjectUpdateRequest {
    id: number;
    data: WriteOnlyMonitoredObject;
}

/**
 * no description
 */
export class MonitoredObjectApi extends runtime.BaseAPI {

    /**
     */
    async monitoredObjectBulkPartialUpdateRaw(requestParameters: MonitoredObjectBulkPartialUpdateRequest): Promise<runtime.ApiResponse<Array<MonitoredObjectNested>>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling monitoredObjectBulkPartialUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/monitored-object/bulk/`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.data.map(MonitoredObjectPartialUpdateToJSON),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MonitoredObjectNestedFromJSON));
    }

    /**
     */
    async monitoredObjectBulkPartialUpdate(requestParameters: MonitoredObjectBulkPartialUpdateRequest): Promise<Array<MonitoredObjectNested>> {
        const response = await this.monitoredObjectBulkPartialUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async monitoredObjectCreateRaw(requestParameters: MonitoredObjectCreateRequest): Promise<runtime.ApiResponse<ReadOnlyMonitoredObject>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling monitoredObjectCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/monitored-object/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyMonitoredObjectToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyMonitoredObjectFromJSON(jsonValue));
    }

    /**
     */
    async monitoredObjectCreate(requestParameters: MonitoredObjectCreateRequest): Promise<ReadOnlyMonitoredObject> {
        const response = await this.monitoredObjectCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async monitoredObjectDeleteRaw(requestParameters: MonitoredObjectDeleteRequest): Promise<runtime.ApiResponse<ReadOnlyMonitoredObject>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling monitoredObjectDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/monitored-object/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyMonitoredObjectFromJSON(jsonValue));
    }

    /**
     */
    async monitoredObjectDelete(requestParameters: MonitoredObjectDeleteRequest): Promise<ReadOnlyMonitoredObject> {
        const response = await this.monitoredObjectDeleteRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async monitoredObjectListRaw(requestParameters: MonitoredObjectListRequest): Promise<runtime.ApiResponse<InlineResponse20018>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.client !== undefined) {
            queryParameters['client'] = requestParameters.client;
        }

        if (requestParameters.deleted !== undefined) {
            queryParameters['deleted'] = requestParameters.deleted;
        }

        if (requestParameters.registrationNumberIcontains !== undefined) {
            queryParameters['registration_number__icontains'] = requestParameters.registrationNumberIcontains;
        }

        if (requestParameters.registrationNumberIexact !== undefined) {
            queryParameters['registration_number__iexact'] = requestParameters.registrationNumberIexact;
        }

        if (requestParameters.monitoredObjectTypes !== undefined) {
            queryParameters['monitored_object_types'] = requestParameters.monitoredObjectTypes;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/monitored-object/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20018FromJSON(jsonValue));
    }

    /**
     */
    async monitoredObjectList(requestParameters: MonitoredObjectListRequest): Promise<InlineResponse20018> {
        const response = await this.monitoredObjectListRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async monitoredObjectNestedRaw(requestParameters: MonitoredObjectNestedRequest): Promise<runtime.ApiResponse<InlineResponse20019>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.client !== undefined) {
            queryParameters['client'] = requestParameters.client;
        }

        if (requestParameters.deleted !== undefined) {
            queryParameters['deleted'] = requestParameters.deleted;
        }

        if (requestParameters.registrationNumberIcontains !== undefined) {
            queryParameters['registration_number__icontains'] = requestParameters.registrationNumberIcontains;
        }

        if (requestParameters.registrationNumberIexact !== undefined) {
            queryParameters['registration_number__iexact'] = requestParameters.registrationNumberIexact;
        }

        if (requestParameters.monitoredObjectTypes !== undefined) {
            queryParameters['monitored_object_types'] = requestParameters.monitoredObjectTypes;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/monitored-object/nested/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse20019FromJSON(jsonValue));
    }

    /**
     */
    async monitoredObjectNested(requestParameters: MonitoredObjectNestedRequest): Promise<InlineResponse20019> {
        const response = await this.monitoredObjectNestedRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async monitoredObjectNestedSingleRaw(requestParameters: MonitoredObjectNestedSingleRequest): Promise<runtime.ApiResponse<ReadOnlyMonitoredObjectNested>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling monitoredObjectNestedSingle.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/monitored-object/{id}/nested/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyMonitoredObjectNestedFromJSON(jsonValue));
    }

    /**
     */
    async monitoredObjectNestedSingle(requestParameters: MonitoredObjectNestedSingleRequest): Promise<ReadOnlyMonitoredObjectNested> {
        const response = await this.monitoredObjectNestedSingleRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async monitoredObjectPartialUpdateRaw(requestParameters: MonitoredObjectPartialUpdateRequest): Promise<runtime.ApiResponse<MonitoredObjectPartialUpdate>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling monitoredObjectPartialUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling monitoredObjectPartialUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/monitored-object/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: MonitoredObjectPartialUpdateToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MonitoredObjectPartialUpdateFromJSON(jsonValue));
    }

    /**
     */
    async monitoredObjectPartialUpdate(requestParameters: MonitoredObjectPartialUpdateRequest): Promise<MonitoredObjectPartialUpdate> {
        const response = await this.monitoredObjectPartialUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async monitoredObjectReadRaw(requestParameters: MonitoredObjectReadRequest): Promise<runtime.ApiResponse<ReadOnlyMonitoredObject>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling monitoredObjectRead.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/monitored-object/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyMonitoredObjectFromJSON(jsonValue));
    }

    /**
     */
    async monitoredObjectRead(requestParameters: MonitoredObjectReadRequest): Promise<ReadOnlyMonitoredObject> {
        const response = await this.monitoredObjectReadRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async monitoredObjectUnpairMonitoringDeviceRaw(requestParameters: MonitoredObjectUnpairMonitoringDeviceRequest): Promise<runtime.ApiResponse<SafeDeleteResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling monitoredObjectUnpairMonitoringDevice.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling monitoredObjectUnpairMonitoringDevice.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/monitored-object/{id}/unpair-monitoring-device/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.data as any,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SafeDeleteResponseFromJSON(jsonValue));
    }

    /**
     */
    async monitoredObjectUnpairMonitoringDevice(requestParameters: MonitoredObjectUnpairMonitoringDeviceRequest): Promise<SafeDeleteResponse> {
        const response = await this.monitoredObjectUnpairMonitoringDeviceRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async monitoredObjectUpdateRaw(requestParameters: MonitoredObjectUpdateRequest): Promise<runtime.ApiResponse<ReadOnlyMonitoredObject>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling monitoredObjectUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling monitoredObjectUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/monitored-object/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: WriteOnlyMonitoredObjectToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyMonitoredObjectFromJSON(jsonValue));
    }

    /**
     */
    async monitoredObjectUpdate(requestParameters: MonitoredObjectUpdateRequest): Promise<ReadOnlyMonitoredObject> {
        const response = await this.monitoredObjectUpdateRaw(requestParameters);
        return await response.value();
    }

}
